import axios from "axios";
import { ROUTE_PATH_SIGN_IN, ROUTE_PATH_V3_SIGN_IN } from "../../consts/routePaths";
import { cleanLocalStorage } from "../constants/general";
import { logAPI } from "edu_lms_v2/utils/axios";
import { HTTP_STATUS } from "consts/general";

class AxiosService {
  cancels = {};

  constructor() {
    const access_token = localStorage.getItem("token");
    const authHeader = {
      headers: {
        token: access_token,
      },
    };
    const instance = axios.create();

    if (process.env.NODE_ENV === "production") {
      logAPI(instance);
    }

    instance.interceptors.request.use(
      (request) => {
        const cancelToken = axios.CancelToken.source();
        request.cancelToken = cancelToken.token;

        const key = `${request.method}::${request.url}`;
        if (this.cancels[key]) {
          this.cancels[key]();
        }
        this.cancels[key] = cancelToken.cancel;

        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (response) => {
        switch (response && response.data) {
          case HTTP_STATUS.UNAUTHENTICATED:
            cleanLocalStorage();
            window.location.href = `${process.env.REACT_APP_DOMAIN}${ROUTE_PATH_V3_SIGN_IN}`;
            break;
          default:
        }
        return response;
      },
      (error) => {
        if (!axios.isCancel(error)) {
          return Promise.reject(error);
        }
      }
    );

    this.instance = instance;

    this.authHeader = access_token ? authHeader : "";
  }

  addParamAppId(url) {
    let newUrl = new URL(url);
    newUrl.searchParams.set("app_id", process.env.REACT_APP_APP_ID);
    return newUrl.href;
  }

  onSuccess(response) {
    return response;
  }

  onError(error) {
    return Promise.reject(error);
  }

  getRequest(url, params) {
    return this.instance.get(this.addParamAppId(url), {
      ...this.authHeader,
      params,
    });
  }

  putRequest(url, data) {
    return this.instance.put(this.addParamAppId(url), data, this.authHeader);
  }

  postRequest(url, data) {
    return this.instance.post(this.addParamAppId(url), data, this.authHeader);
  }
}

export default AxiosService;
export const axiosService = new AxiosService();
