import ReactPaginate from "react-paginate";

export const Pagination = ({ totalBooks, totalPerPage, setCurrentPage }) => {
  const totalPage = Math.ceil(totalBooks / totalPerPage);

  return (
    <ReactPaginate
      previousLabel={""}
      previousClassName={"icon icon-prev"}
      nextLabel={""}
      nextClassName={"icon icon-next"}
      breakLabel={"..."}
      pageCount={totalPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      onPageChange={(data) => setCurrentPage(data.selected + 1)}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      previousLinkClassName="page-link page-link--prev"
      nextLinkClassName="page-link page-link--prev page-link--next"
      renderOnZeroPageCount={null}
      hrefAllControls
      hrefBuilder={() => {
        return "javascript:;";
      }}
    />
  );
};
