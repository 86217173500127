import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cleave from "cleave.js/react";
import { onResultUserInfo } from "edu_lms/modules/selection";
import { useDispatch } from "react-redux";
import { onDispatchShowPopupActivateBook } from "edu_lms/modules/App/actions";

const FromCodeBook = ({
  valueLicence,
  errorLicence,
  isStatus,
  messageError,
  onConfirm,
  onStateValueLicence,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, watch } = useForm();
  const userInfo = onResultUserInfo();

  if (userInfo?.test_book_3_7_10 && !userInfo?.is_user_hoc10) {
    return (
      <div>
        <p className="text-center mt-5 monkey-fz-18 font-weight-bold">
          Tài khoản thẩm định không thể kích hoạt sách
        </p>
        <div className="d-flex justify-content-center mt-5">
          <button
            className="monkey-bg-orange text-white px-5 py-2 rounded m-auto"
            onClick={() => dispatch(onDispatchShowPopupActivateBook(false))}
          >
            Đóng
          </button>
        </div>
      </div>
    );
  }

  return (
    <form className="text-center mt-4" onSubmit={handleSubmit(onConfirm)}>
      <div className="img-wrapper text-center">
        <p className="m-4">Xin vui lòng nhập mã sách để tiếp tục</p>
        <Cleave
          options={{
            blocks: [4, 4, 4],
            delimiter: "-",
            numericOnly: false,
            uppercase: true,
          }}
          placeholder="Nhập mã sách tại đây ..."
          value={valueLicence}
          autoComplete="off"
          onChange={(e) => onStateValueLicence(e.target.value)}
          className="form-control w-75  mx-auto mb-3 input-active-book"
        />
      </div>
      <p className="text-danger mb-3 text-center px-5">
        {errorLicence && "Vui lòng nhập mã kích hoạt"}
        {isStatus && messageError}
      </p>
      <div className="d-flex justify-content-center mt-4">
        <button
          title="Truy cập tủ sách"
          className="btn-pr flex-center btn-access w-25"
          style={{ width: "115px", height: "37px" }}
          type="submit"
        >
          Xác nhận
        </button>
      </div>
    </form>
  );
};

export default FromCodeBook;
