import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { renderDocumentByType } from "./const";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import { Link, useHistory, useParams } from "react-router-dom";
import { getSchoolLevelId } from "edu_lms_v2/modules/ControlDoingExercise/constant";
import * as PATH from "consts/routePaths";
import { useAppStore } from "stores/appStore";

export default function Document({ data, categoryBook, col, background }) {
  const learningLevel = useRef({ gradeId: "", subjectId: "" });
  const [url, setUrl] = useState("");
  const params = useParams();
  const history = useHistory();
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const currentSubject = subjects.find((subject) => subject.id === subjectId);
  const currentGrade = grades.find((grade) => grade.id === gradeId);

  useEffect(() => {
    const worksheet = data.find((item) => item.slug === params.slugvideo);

    if (worksheet) {
      onSetUrl(`https://www.youtube.com/watch?v=${worksheet.url}`);
      learningLevel.current.gradeId = worksheet.grade_id;
      learningLevel.current.subjectId = worksheet.subject_id;
    }
  }, []);

  const onSetUrl = (url) => {
    setUrl(url);
  };

  const closeModal = () => {
    setUrl("");
    //direct page link
    const { gradeId, subjectId } = learningLevel.current;
    if (gradeId && subjectId) {
      const schoolLevel = getSchoolLevelId(gradeId);
      return history.push(
        `/tu-sach/?block=${schoolLevel}&grade=${gradeId}&subject=${subjectId}`
      );
    }
    //go to from website
    const { pathname, search } = history.location;
    let redirectRouter = "/tu-sach/?block=1&grade=4&subject=32";
    if (pathname && search) redirectRouter = `${pathname}${search}`;

    history.push(redirectRouter);
  };

  return (
    <div
      className="result__list"
      style={{
        backgroundColor: `${background}`,
        boxShadow: "5px 5px 10px 1px #ccc",
        padding: "10px 20px",
        marginBottom: "20px",
        borderRadius: "8px",
      }}
    >
      <div className="row">
        <div className="col-md-6 d-flex">
          <BookMark className="fa fa-bookmark" aria-hidden="true"></BookMark>
          <h4>{categoryBook}</h4>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 col-sm-4">
              {/* <Select
                value={typeDocument}
                options={listTypeDocument}
                onChange={(option) => changeTypeDocument(option)}
                placeholder="Tất cả"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
              /> */}
              <Link
                to={`${PATH.ROUTE_PATH_COURSE}${currentSubject.slug}/${currentGrade.slug}`}
                className="activated flex-center btn-activate-book"
              >
                Xem thêm
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Hr />
      <VideoPlayerModal show={Boolean(url)} url={url} onClose={closeModal} />
      <div className="row">
        {data.length > 0 ? (
          data.map((_item, _index) => {
            return (
              <React.Fragment key={_index}>
                {renderDocumentByType(
                  _item.type_document,
                  _item,
                  _index,
                  col,
                  onSetUrl
                )}
              </React.Fragment>
            );
          })
        ) : (
          <div className="text-center w-100">
            <h1>Không tìm thấy tài liệu</h1>
          </div>
        )}
      </div>
    </div>
  );
}

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
  margin-top: 4px;
`;
