import React from "react";
import ReactPlayer from "react-player";
import InstructionActivatedBookWrapper from "edu_lms/modules/InstructionActivatedBook/components";

const url = "https://youtu.be/lw4oJdilC4s";

export default function CheckSeriBook() {
  return (
    <>
      <h4>Hướng dẫn cách kiểm tra sách thật</h4>
      <div className="d-flex justify-content-center mb-4">
        <ReactPlayer pip={true} controls={true} className="mx-auto" url={url} />
      </div>
      <InstructionActivatedBookWrapper />
    </>
  );
}
