import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getLevelSchool,
  getGrades,
  getSubjects,
  getListBookV4,
} from "edu_lms_v2/services/listBook";

const SelectBookForm = ({ bookId, onSelectBookId }) => {
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [books, setBooks] = useState([]);
  const [bookForm, setBookForm] = useState({
    selectedLevel: null,
    selectedGrade: null,
    selectedSubject: null,
  });

  const getSchoolLevels = async () => {
    try {
      const response = await getLevelSchool();
      let schoolLevels = response.data.data;
      schoolLevels = schoolLevels.map((level) => ({
        label: level.name,
        value: level.id,
      }));
      setLevels(schoolLevels);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  const getGradesBySchoolLevel = async (levelId) => {
    try {
      const response = await getGrades(levelId);
      let gradeList = response.data.data;
      gradeList = gradeList.map((grade) => ({
        label: grade.name,
        value: grade.id,
      }));
      setGrades(gradeList);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  const getSubjectsBySchoolLevel = async (levelId) => {
    try {
      const response = await getSubjects(levelId);
      let subjectList = response.data.data.list_subject;
      subjectList = subjectList.map((subject) => ({
        label: subject.title,
        value: subject.id,
      }));
      setSubjects(subjectList);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  const getListBookByGradeAndSubject = async (gradeId, subjectId) => {
    try {
      const response = await getListBookV4(gradeId, subjectId);
      let bookList = response.data.data.list_book;
      bookList = bookList.map((book) => ({
        label: book.title,
        value: book.id,
      }));
      setBooks(bookList);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  const resetBookList = () => {
    onSelectBookId(null);
    setBooks([]);
  };
  const resetSubjectList = () => {
    setSubjects([]);
    resetBookList();
  };
  const resetGradeList = () => {
    setGrades([]);
    resetBookList();
  };

  const handleChangeSchoolLevel = (e) => {
    resetGradeList();
    resetSubjectList();
    const levelId = e ? e.value : null;
    setBookForm({ ...bookForm, selectedLevel: levelId });

    if (levelId) {
      getGradesBySchoolLevel(levelId);
      getSubjectsBySchoolLevel(levelId);
    }
  };

  const handleChangeGrade = (e) => {
    resetBookList();
    const gradeId = e ? e.value : null;
    setBookForm({ ...bookForm, selectedGrade: gradeId });

    if (gradeId && bookForm.selectedSubject) {
      getListBookByGradeAndSubject(gradeId, bookForm.selectedSubject);
    }
  };

  const handleChangeSubject = (e) => {
    resetBookList();
    const subjectId = e ? e.value : null;
    setBookForm({ ...bookForm, selectedSubject: subjectId });

    if (subjectId && bookForm.selectedGrade) {
      getListBookByGradeAndSubject(bookForm.selectedGrade, subjectId);
    }
  };

  const handleChangeBook = (e) => {
    const bookId = e ? e.value : null;
    onSelectBookId(bookId);
  };

  useEffect(() => {
    getSchoolLevels();
  }, []);

  return (
    <>
      <div className="wrapper-item">
        <Form.Group className="item">
          <Form.Label>School level</Form.Label>
          <Select
            isClearable
            className="select-school-level"
            placeholder="Select level"
            options={levels}
            value={
              levels.find((level) => level.value === bookForm.selectedLevel) ||
              ""
            }
            onChange={handleChangeSchoolLevel}
          />
        </Form.Group>
        <Form.Group className="item">
          <Form.Label>Grade</Form.Label>
          <Select
            isClearable
            isDisabled={!bookForm.selectedLevel}
            className="select-grade"
            placeholder="Select grade"
            options={grades}
            value={
              grades.find((grade) => grade.value === bookForm.selectedGrade) ||
              ""
            }
            onChange={handleChangeGrade}
          />
        </Form.Group>
        <Form.Group className="item">
          <Form.Label>Subject</Form.Label>
          <Select
            isClearable
            isDisabled={!bookForm.selectedLevel}
            className="select-subject"
            placeholder="Select subject"
            options={subjects}
            value={
              subjects.find(
                (subject) => subject.value === bookForm.selectedSubject
              ) || ""
            }
            onChange={handleChangeSubject}
          />
        </Form.Group>
      </div>
      <Form.Group className="item">
        <Form.Label>Book</Form.Label>
        <Select
          isClearable
          isDisabled={!bookForm.selectedGrade || !bookForm.selectedSubject}
          className="select-book"
          placeholder="Select book"
          options={books}
          value={books.find((book) => book.value === bookId) || ""}
          onChange={handleChangeBook}
        />
      </Form.Group>
    </>
  );
};

export default SelectBookForm;
