export const dataThumb = [
  { id: 1, url: "../../assets/img/mh_tongthe.svg" },
  { id: 2, url: "../../assets/img/mh3.svg" },
  { id: 3, url: "../../assets/img/mh1.svg" },
  { id: 4, url: "../../assets/img/mh_giaoduccongdan.svg" },
  { id: 5, url: "../../assets/img/mh_tunhienxahoi.svg" },
  { id: 6, url: "../../assets/img/mh6.svg" },
  { id: 7, url: "../../assets/img/mh_lichsuvadialy.svg" },
  { id: 8, url: "../../assets/img/mh8_lichsu.svg" },
  { id: 10, url: "../../assets/img/mh_khoa_hoc.svg" },
  { id: 11, url: "../../assets/img/mh_khoahoctunhien.svg" },
  { id: 12, url: "../../assets/img/mh11.svg" },
  { id: 13, url: "../../assets/img/mh5.svg" },
  { id: 14, url: "../../assets/img/mh_congnghe.svg" },
  { id: 15, url: "../../assets/img/mh10.svg" },
  { id: 16, url: "../../assets/img/mh9.svg" },
  { id: 17, url: "../../assets/img/mh7.svg" },
  { id: 18, url: "../../assets/img/mh_hoatdongtrainhiem.svg" },
  { id: 266, url: "../../assets/img/mh_tinhoc.svg" },
];
