import styled from "styled-components";
import { toast } from "react-toastify";
import { getListExerciseById } from "edu_lms/services/teachingManager";
import { useEffect, useState } from "react";
import * as PATH from "consts/routePaths";
import { useHistory } from "react-router-dom";
import styles from "../../teachingManager.module.scss";
import * as types from "edu_lms/constants/type";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { onDispatchSetCurrentPageClassroom } from "../../../TeachingManager/actions";
import Select from "react-select";
import { getInfoClassroomById } from "edu_lms/services/teachingManager";
import { getListSubject } from "edu_lms/services/app/app";
import Pagination from "edu_lms_v2/components/Pagination";
import { ROUTE_PATH_V3_TEACHER_LIST_EXERCISE } from "consts/routePaths";

export default function ExerciseClassroom({
  classroomId,
  getInfoClassroom,
  studentCounts,
}) {
  const history = useHistory();
  const [listExercise, setListExercise] = useState([]);
  const [totalExercise, setTotalExercise] = useState(0);
  const [gradeId, setGradeId] = useState();
  const [subjectSelect, setSubjectSelect] = useState();
  const [listSubject, setListSubject] = useState([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const dispatch = useDispatch();
  const currentPage = useSelector(
    (state) => state.teachingReducer.currentPageClassroom
  );
  const handleChangePage = (page) => {
    dispatch(onDispatchSetCurrentPageClassroom(page));
  };

  useEffect(() => {
    getInfoClassroomById(classroomId).then((res) => {
      if (res.data.status === "success") {
        setGradeId(res.data.data.grade_id);
      }
    });
  }, []);
  useEffect(() => {
    getListSubject(gradeId).then((res) => {
      if (res.data.status === "success") {
        const list = [];
        res.data.data.list_subject.forEach((value, index) => {
          list.push({
            id: value.id,
            label: value.title,
            value: `file_${value.id}`,
            type: value.id,
          });
        });
        setListSubject(list);
      }
    });
  }, [gradeId]);
  useEffect(() => {
    getListExercise();
    setTotalNumber((currentPage - 1) * types.TOTAL_EXERCISE);
  }, [classroomId, subjectSelect, currentPage, totalNumber]);
  const getListExercise = () => {
    const data = {
      classroomId: classroomId,
      subjectId: subjectSelect?.id || "",
      limit: types.TOTAL_EXERCISE,
      langId: types.TYPE_VIETNAMESE,
      page: currentPage,
    };
    getListExerciseById(data)
      .then((res) => {
        if (res.data.status === "success") {
          setListExercise(res.data.data.list);
          setTotalExercise(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClick = (id) => {
    const url = `${PATH.ROUTE_PATH_CLASSROOM}${classroomId}${PATH.ROUTE_PATH_EXERCISE_CLASSROOL}${id}`;
    history.push(url);
  };
  const goToExercises = (type) => {
    const url =
      type === types.PRACTIVE
        ? PATH.ROUTE_PATH_QUESTION_SET_V2
        : PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST;
    history.push(url);
  };

  return (
    <div
      className="tab-pane fade"
      id="excercise"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="dropright">
        {/* <button
          className={styles.buttonAddClass}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp; Giao bài
          tập mới
        </button> */}
        <Button
          className={styles.buttonAddClass}
          type="button"
          onClick={() => goToExercises(types.PRACTIVE)}
        >
          Luyện tập
        </Button>
        <Button
          className={`${styles.buttonAddClass} ml-3`}
          type="button"
          onClick={() => goToExercises(types.MOCKTEST)}
        >
          Luyện thi
        </Button>
        <div
          className={`dropdown-menu dropdown-menu-right p-0 border-0 ml-2 ${styles.menuSetting}`}
          aria-labelledby="dropdownMenuButton"
        >
          <button
            className="dropdown-item border-bottom"
            onClick={() => history.push(ROUTE_PATH_V3_TEACHER_LIST_EXERCISE)}
          >
            <i
              className={`fa fa-paperclip ${styles.iconOrange}`}
              aria-hidden="true"
            ></i>
            &nbsp; Bộ câu hỏi
          </button>
          <button className="dropdown-item border-bottom">
            <i
              className={`fa fa-book ${styles.iconOrange}`}
              aria-hidden="true"
            ></i>
            &nbsp; Thư viện
          </button>
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        <p className="font-weight-bold monkey-color-back-reading-book hoc10-f-header monkey-fz-20">
          Danh sách bài tập
        </p>
        <div style={{ width: "200px" }}>
          <Select
            options={listSubject}
            placeholder="Chọn môn"
            value={subjectSelect}
            onChange={(e) => setSubjectSelect(e)}
          />
        </div>
      </div>
      <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-5 table-responsive">
        <table className={`table text-center table-hover ${styles.table}`}>
          <thead>
            <tr>
              <th scope="col" className="border-right border-top-0 ">
                STT
              </th>
              <th scope="col" className="border-right border-top-0">
                Bài tập
              </th>
              <th scope="col" className="border-right border-top-0">
                Thể loại
              </th>
              <th scope="col" className="border-right border-top-0">
                Ngày giao
              </th>
              <th scope="col" className="border-right border-top-0">
                Ngày hoàn thành
              </th>
              <th scope="col" className="border-right border-top-0">
                Hoàn thành
              </th>
              <th scope="col" className="border-top-0">
                Báo cáo
              </th>
            </tr>
          </thead>
          <tbody>
            {listExercise?.map((exercise, index) => {
              const dateCreate = dayjs
                .unix(exercise.created_at)
                .format("D/M/YYYY");
              const deadline = dayjs.unix(exercise.deadline).format("D/M/YYYY");
              const type =
                exercise.type === types.PRACTIVE
                  ? "Luyện tập"
                  : exercise.type === types.MOCKTEST
                  ? "Đề kiểm tra"
                  : "Chưa có";
              return (
                <tr>
                  <th scope="row">{totalNumber + index + 1}</th>
                  <td
                    className="cursor"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_URL}hoc-sinh-lam-bai/${exercise.class_room_question_set_id}`
                      );
                      toast.success("Copy link giao bài thành công!");
                    }}
                  >
                    {exercise.title}
                  </td>
                  <td>{type}</td>
                  <td>{dateCreate}</td>
                  <td>{deadline}</td>
                  <td>
                    {exercise.done_count}/{studentCounts} học sinh
                  </td>
                  <td className={styles.report}>
                    <div onClick={() => handleClick(exercise.id)}>
                      <button>
                        <img
                          src="/assets/img/statistics.svg"
                          alt="Báo cáo"
                        ></img>
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mb-4">
        {totalExercise > types.TOTAL_EXERCISE && (
          <Pagination
            total={totalExercise}
            totalPerPage={types.TOTAL_EXERCISE}
            setCurrentPage={handleChangePage}
            currentPage={currentPage}
          />
        )}
      </div>
    </div>
  );
}

const Button = styled.button`
  width: 180px;
  padding-right: 5px;
  padding-left: 5px;
  height: 36px;
  border-radius: 5px;
  background: #ff7707;
  color: #fff;
  @media (max-width: 668px) {
    width: 120px;
  }
`;
