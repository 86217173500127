import { useState } from "react";
import LoadingComponent from "../loading";
import ReactPaginate from "react-paginate";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContentPopupPdf = ({ url }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [scale, setStateScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  function onDocumentLoadError(error) {
   console.log(error)
  }
  // function changePage(offset) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // }
  // function nextPage() {
  //   changePage(1);
  // }
  // function previousPage() {
  //   changePage(-1);
  // }
  function onPageClick(number) {
    setPageNumber((prevPageNumber) => number.selected + 1);
  }
  function onZoomIn() {
    setStateScale(scale + 0.2);
  }
  function onZoomOut() {
    setStateScale(scale - 0.2);
  }
  return (
    <div className="position-relative">
      <ul
        className="list-group list-group-horizontal"
        style={{ position: "absolute", right: "15px", top: "10px" }}
      >
        <li className="list-group-item p-2">
          <a href={url} target="_blank" download>
            <i
              className="fa fa-download monkey-fz-16 cursor monkey-color-blue"
              aria-hidden="true"
            ></i>
          </a>
        </li>
        <li className="list-group-item p-2">
          <i
            onClick={onZoomOut}
            className="monkey-fz-16 cursor fa fa-search-minus"
            aria-hidden="true"
          ></i>
        </li>
        <li className="list-group-item p-2">
          <i
            onClick={onZoomIn}
            className="monkey-fz-16 cursor fa fa-search-plus"
            aria-hidden="true"
          ></i>
        </li>
      </ul>
      <div className="content-popup-pdf d-flex justify-content-center align-items-center">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={<LoadingComponent />}
        >
          <div className="text-right mb-2">
            <ReactPaginate
              previousLabel={
                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
              }
              nextLabel={
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
              }
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={numPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={onPageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
    </div>
  );
};

export default ContentPopupPdf;
