import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import { INNER_WIDTH } from "edu_lms/constants/type";
import HeaderTitle from "./HeaderTitle";
import DrawLineDescription from "./DrawLineDescription";
import InputCoordinateArea from "./InputCoordinateArea";
import GraphShow from "./GraphShow";
import Conclusion from "./Conclusion";
import { get2PointsOnTheStraightLine, getPointInTheInequality } from "../../helpers/SROI_001";
import "./styles.scss";

const SROI_001 = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAnswerProp = null,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const DEFAULT_POINTS = [
    { pointA: { x: 2, y: 0 }, pointB: { x: 0, y: 2 } },
    { pointA: { x: 1, y: 0 }, pointB: { x: 0, y: 1 } },
    { pointA: { x: -1, y: 0 }, pointB: { x: 0, y: -1 } },
    { pointA: { x: -2, y: 0 }, pointB: { x: 0, y: -2 } },
    { pointA: { x: -3, y: 0 }, pointB: { x: 0, y: -3 } },
  ];
  const { coefficients, coor } = gameData;
  const initialValueSelectedCoordinates = [...DEFAULT_POINTS].slice(0, coefficients.length);
  const initialValueInputtedPoints = Array(coefficients.length).fill({ x: "", y: "" });

  const [selectedCoordinates, setSelectedCoordinates] = useState([]);
  const [inputtedPoints, setInputtedPoints] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    if (selectedCoordinates.length === 0) {
      setSelectedCoordinates(initialValueSelectedCoordinates);
    }
    if (inputtedPoints.length === 0) {
      setInputtedPoints(initialValueInputtedPoints);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coefficients]);

  /**-------------------- Show selected answer result --------------------**/
  useEffect(() => {
    if (selectedAnswerProp) {
      const { graphsResult, inputPointsResult } = selectedAnswerProp;
      setSelectedCoordinates(graphsResult);
      setInputtedPoints(inputPointsResult);
    }
  }, [selectedAnswerProp]);

  /**-------------------- Show all correct answer result --------------------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      const graphPoints = [];
      const inputPoints = [];
      coefficients.forEach((coefficient) => {
        const { a, b, c, markGame } = coefficient;

        const [pointA, pointB] = get2PointsOnTheStraightLine(a, b, c);
        graphPoints.push({ pointA, pointB, isCorrect: true });

        const { x, y } = getPointInTheInequality(a, b, c, markGame);
        inputPoints.push({ x: String(x), y: String(y) });
      });

      setSelectedCoordinates(graphPoints);
      setInputtedPoints(inputPoints);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);
    // Check correct result of draw graph
    const graphsResult = selectedCoordinates.map((selectedCoordinate, index) => {
      const { a, b, c } = coefficients[index];
      const { x: xA, y: yA } = selectedCoordinate.pointA;
      const { x: xB, y: yB } = selectedCoordinate.pointB;
      const isCorrectPointA = a * xA + b * yA === c;
      const isCorrectPointB = a * xB + b * yB === c;
      const isNotSamePoint = xA !== xB || yA !== yB;
      const isCorrect = isCorrectPointA && isCorrectPointB && isNotSamePoint;
      return {
        pointA: {
          ...selectedCoordinate.pointA,
          isCorrect: isCorrectPointA,
        },
        pointB: {
          ...selectedCoordinate.pointB,
          isCorrect: isCorrectPointB,
        },
        isCorrect,
      };
    });
    setSelectedCoordinates(graphsResult);
    const isCorrectDrawGraph = graphsResult.every(graph => graph.isCorrect);

    // Check correct result of input points
    const inputPointsResult = inputtedPoints.map((point, index) => {
      const { a, b, c, markGame } = coefficients[index];
      if (!point.x || !point.y) {
        return { ...point, isCorrect: false };
      }
      const x = Number(point.x);
      const y = Number(point.y);
      let isCorrect = false;
      if (markGame === ">" || markGame === "\\ge") {
        isCorrect = a * x + b * y > c;
      }
      if (markGame === "<" || markGame === "\\le") {
        isCorrect = a * x + b * y < c;
      }
      return { ...point, isCorrect };
    });
    setInputtedPoints(inputPointsResult);
    const isCorrectInputPoints = inputPointsResult.every(graph => graph.isCorrect);

    const isCorrect = isCorrectDrawGraph && isCorrectInputPoints;
    const selectedAnswer = {
      graphsResult,
      inputPointsResult,
    };
    onComplete({ isCorrect, selectedAnswer });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);

    setSelectedCoordinates([]);
    setInputtedPoints([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const resetInputPointByLineIndex = (lineIndex) => {
    const shadowInputtedPoints = [...inputtedPoints];
    shadowInputtedPoints[lineIndex] = { x: "", y: "" };
    setInputtedPoints(shadowInputtedPoints);
  };

  const handleStopTouchPoints = (lineIndex) => {
    if (inputtedPoints[lineIndex].x && inputtedPoints[lineIndex].y) {
      resetInputPointByLineIndex(lineIndex);
    }
  };

  const handleChangeInputCoordinate = (lineIndex, value) => {
    let shadowInputtedPoints = [...inputtedPoints];
    if (isCheckedAnswer) {
      handleResetAnswer();
      shadowInputtedPoints = initialValueInputtedPoints;
    }
  
    shadowInputtedPoints[lineIndex] = {
      ...shadowInputtedPoints[lineIndex],
      ...value
    }
    setInputtedPoints(shadowInputtedPoints);

    const isFillAllInputCoordinates = shadowInputtedPoints.every(inputtedPoint => inputtedPoint.x && inputtedPoint.y);
    if(isFillAllInputCoordinates) {
      onPlaying(false);
    }
  };

  const isFillAllInputCoordinates = inputtedPoints.every(inputtedPoint => inputtedPoint.x && inputtedPoint.y);

  return (
    <div
      className={classNames("SROI_001__container position-relative", {
        "pl-5": window.innerWidth > INNER_WIDTH.MOBILE,
      })}
    >
      <HeaderTitle coefficients={coefficients} />
      <div className="content__wrapper">
        {!showCorrectAnswer && (
          <div className="col-left">
            <DrawLineDescription coefficients={coefficients} />
            <InputCoordinateArea
              coefficients={coefficients}
              inputtedCoordinates={inputtedPoints}
              hideResultAnswer={hideResultAnswer}
              isView={isReadOnly || isViewOnly}
              onChangeInput={handleChangeInputCoordinate}
            />
          </div>
        )}
        <div className="col-right">
          <GraphShow
            coefficients={coefficients}
            selectedCoordinates={selectedCoordinates}
            inputtedCoordinates={inputtedPoints}
            coor={coor}
            hideResultAnswer={hideResultAnswer}
            isView={isReadOnly || isViewOnly}
            onStopTouchPoints={handleStopTouchPoints}
          />
        </div>
      </div>
      {(isFillAllInputCoordinates ||
        selectedAnswerProp ||
        showCorrectAnswer) && <Conclusion coefficients={coefficients} />}
    </div>
  );
};

export default forwardRef(SROI_001);
