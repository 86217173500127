import React, { Fragment, useState, useRef } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import Select from "react-select";
import Support from "./PopupSupport.module.scss";

const PopupSupport = () => {
  const data = [
    {
      value: 0,
      label: "Hướng dẫn sử dụng Sách Cánh Diều",
      path: "https://www.youtube.com/embed/ymSpyb8kP4I",
    },
    {
      value: 1,
      label: "Hướng dẫn sử dụng công cụ giảng dạy",
      path: "https://www.youtube.com/embed/OXvPgtuX4A8",
    },
    {
      value: 2,
      label: "Hướng dẫn sử dụng bài tập đáp án mẫu",
      path: "https://www.youtube.com/embed/59Cuwxq460Q",
    },
    {
      value: 3,
      label: "Hướng dẫn sử dụng bài tập sắp xếp thứ tự",
      path: "https://www.youtube.com/embed/76pAvOGVToM",
    },
    {
      value: 4,
      label: "Hướng dẫn sử dụng Bài tập chọn nhiều đáp án",
      path: "https://www.youtube.com/embed/2OIq1g2LRPw",
    },
    {
      value: 5,
      label: "Hướng dẫn sử dụng bài tập điền vào chỗ trống",
      path: "https://www.youtube.com/embed/0HkyCDph3uk",
    },
    {
      value: 6,
      label: "Hướng dẫn sử dụng bài tập kéo thả",
      path: "https://www.youtube.com/embed/1as_mhlPAaA",
    },
    {
      value: 7,
      label: "Hướng dẫn sử dụng bài tập nối",
      path: "https://www.youtube.com/embed/trPnW7aeoJ8",
    },
  ];
  const [valueSelected, setValueSelected] = useState(data[0]);

  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      justifyContent: "center",
    }),
    placeholder: () => ({
      margin: "auto",
    }),
    control: (base) => ({
      ...base,
      height: 44,
      width: 450,
      textAlign: "center",
      borderRadius: 30,
      zIndex: 500,
      backgroundColor: "#fff",
    }),
  };
  const handleChange = (selectedOption) => {
    setValueSelected(selectedOption);
    sliderRef.current.slickGoTo(selectedOption.value);
  };
  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="d-flex justify-content-center pt-3 pb-2">
        <Select
          value={valueSelected}
          onChange={handleChange}
          options={data}
          styles={customStyles}
          isSearchable={true}
        />
      </div>
      <div>
        <Slider className={Support.slickList} ref={sliderRef} {...settings}>
          {data.map((item, index) => (
            <ReactPlayer
              width="100%"
              key={index}
              pip={true}
              controls={true}
              url={item.path}
            />
          ))}
        </Slider>
      </div>
    </>
  );
};
export default PopupSupport;
