import React, { useState } from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";
import ReactAudioPlayer from "react-audio-player";
import CTAIcon from "./CTAIcon";
import CTAMessage from "./CTAMessage";
import {
  AUDIO_ERROR,
  AUDIO_VICTORY,
  ALERT_GAME,
  BOOK_LANGUAGE,
} from "edu_lms/constants/type";

export default function AlertReportRangeOneGame({
  total,
  correctTotal,
  alertType,
  languageBook,
}) {
  const [showAlert, setShowAlert] = useState(true);
  const textBookLanguage = BOOK_LANGUAGE.find((lang) => lang.id === Number(languageBook)) || {};

  return (
    <Alert
      isOpen={showAlert}
      color={alertType === ALERT_GAME.success ? "info" : "danger"}
      toggle={() => setShowAlert(false)}
      className="p-0"
    >
      <ContentAlert className="d-flex justify-content-start align-items-center text-center w-100 pt-1 pb-1">
        <div className="icon-alert">
          <CTAIcon alertType={alertType} />
        </div>
        <div className="align-items-center flex-column">
          <p className="text-center">
            <span className="monkey-color-blue p-2">
              {correctTotal}/{total}
            </span>
            {textBookLanguage.correct_placeholder}
          </p>
          <CTAMessage alertType={alertType} languageBook={languageBook} />
        </div>
      </ContentAlert>
      <ReactAudioPlayer
        src={alertType === ALERT_GAME.success ? AUDIO_VICTORY : AUDIO_ERROR}
        className="d-none"
        autoPlay
        controls
      />
    </Alert>
  );
}

const ContentAlert = styled.div`
  padding-right: 50px;
  padding-left: 15px;
  .icon-alert {
    width: 60px;
  }
`;
