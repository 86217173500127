import React from "react";

export default function FindLessonComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn tìm bài giảng điện tử</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Hoc10 xin kính chào các thầy cô. Để tìm kiếm bài giảng điện tử, thầy
          cô thực hiện theo các bước sau:
        </li>
        <li class="flex-lc">
          <b>Bước 1:</b>&nbsp; Đăng nhập hoặc đăng ký tài khoản trên trang
          website&nbsp;
          <a href="https://hoc10.vn/" target="_blank" style={{ flex: "none" }}>
            https://hoc10.vn/
          </a>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 2:</b>&nbsp; Sau khi đăng nhập thành công, thầy cô bấm chọn
            “Bài giảng” trên thanh công cụ đầu trang.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/step2_find_lesson.PNG`}
              alt="Bấm chọn “Bài giảng” trên thanh công cụ đầu trang."
            />
          </div>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 3:</b>&nbsp; Thầy cô kéo con trỏ chuột xuống dưới đến phần
            “Góc chia sẻ giáo án điện tử”. Tại đây, thầy cô có thể tìm bài
            giảng theo lớp, môn hoặc theo tên bài học.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/Buoc_3_muc_tim_bai_giang.png`}
              alt="Tìm bài giảng theo lớp, môn hoặc theo tên bài học."
            />
          </div>
        </li>
        <li>
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li>Chúc thầy cô thao tác thành công!</li>
      </ul>
    </>
  );
}
