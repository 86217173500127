import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import AuthorSlider from "edu_lms_v2/components/AuthorSlider";
import { plans } from "edu_lms_v2/data/plans";
import { Pagination } from "./Pagination";
import { BOOK_DOCUMENT_PDF, TYPE_POPUP_PDF } from "edu_lms/constants/type";
import { dataThumb } from "./data";
import { searchBookTutorial } from "edu_lms/services/tutorial";
import {
  onDispatchShowPopupPDF,
  onDispatchShowPopupActivateBook,
  onDispatchTypePopup,
} from "edu_lms/modules/App/actions";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import "./stylebook.scss";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";

const HIDDEN_AUTHOR = "false";

export default function BookIntro() {
  const dispatch = useDispatch();
  const { handleSubmit } = useForm();
  const [valueSearch, setValueSearch] = useState("");
  const [url, setUrl] = useState("");
  const [subjects, setSubjects] = useState([]);
  const perPage = 8;
  const [total, setTotal] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showTotal, setShowTotal] = useState(false);

  const showToTal = () => {
    setShowTotal(true);
  };
  useScrollToTop();
  const onShowPopupPdf = (data) => {
    const dataSuccess = {
      title: data.title,
      url: data.url,
    };
    dispatch(onDispatchTypePopup(TYPE_POPUP_PDF));
    dispatch(onDispatchShowPopupActivateBook(true));
    dispatch(onDispatchShowPopupPDF(dataSuccess));
  };
  useEffect(() => {
    onPageClick();
  }, [currentPage]);
  const getData = () => {
    const data = {
      typeDocument: BOOK_DOCUMENT_PDF,
      value: valueSearch,
      page: currentPage,
      limit: perPage,
    };
    searchBookTutorial(data)
      .then((res) => {
        if (res.data.status === "success") {
          setTotal(res.data.data.total);
          setSubjects(res.data.data.data);
        }
        let formatedData = res.data.data.data.map((_item) => {
          return {
            ..._item,
            thumb: dataThumb.filter((__item) => __item.id == _item.id)[0]?.url,
          };
        });
        setSubjects(formatedData);
      })
      .catch((error) => {});
  };
  const onSearch = () => {
    setCurrentPage(0);
    getData();
  };
  const onPageClick = () => {
    getData();
  };

  return (
    <main>
      <SEO data={pages.intro_book} isBreadcumb />
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />
      <div className="hoc10-breadcrumb">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V3_INTRO}>Giới thiệu</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Bộ sách giáo khoa Cánh Diều
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-intro-sgk pr">
        <div className="container pr">
          <div className="header">
            <H1TitleTextBook className="title">
              Bộ sách giáo khoa <span>Cánh Diều</span>
            </H1TitleTextBook>
          </div>
          <div className="video video-ty1 pr">
            <div className="video__thumb">
              <img
                src="/assets/img/banner-sgk-canh-dieu.png"
                alt="Bộ sách giáo khoa Cánh Diều"
              />
            </div>
            {/* <div className="video__play">
              <img
                onClick={() =>
                  setUrl(
                    "https://www.youtube.com/watch?v=ZYdoW9RzPAs&ab_channel=Hoc10"
                  )
                }
                src="/assets/img/icon-play.svg"
                alt=""
              />
            </div> */}
          </div>
          {/*<BuyBook>*/}
          {/*  <Link*/}
          {/*    to={PATH.ROUTE_PUBLISHER_LIST}*/}
          {/*    title="Mua SGK tại đây"*/}
          {/*    className="btn-pr flex-center monkey-fz-20 btn-router-book link-buy-book"*/}
          {/*  >*/}
          {/*    Mua SGK tại đây*/}
          {/*  </Link>*/}
          {/*  <img*/}
          {/*    src="https://hoc10.monkeyuni.net/upload/hoc10/thumb/qr-danhsach-donvi-phathanh.gif"*/}
          {/*    alt="Mua SGK tại đây"*/}
          {/*  />*/}
          {/*</BuyBook>*/}
          <div className="intro-list row pr">
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book5.svg"
                    alt="Bộ sách thứ nhất (đầu tiên)"
                  />
                </div>
                <h4>Bộ sách thứ nhất (đầu tiên)</h4>
                <p>
                  Bộ sách thứ nhất (đầu tiên) góp phần thực hiện chủ trương xã
                  hội hoá sách giáo khoa, xoá bỏ cơ chế độc quyền trong lĩnh vực
                  xuất bản - in - phát hành sách giáo khoa.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book6.svg"
                    alt="Tác giả bộ sách Cánh Diều"
                  />
                </div>
                <h4>Tác giả bộ sách Cánh Diều</h4>
                <p>
                  Tác giả bộ sách Cánh Diều là những nhà giáo, nhà khoa học tâm
                  huyết và giàu kinh nghiệm. Trong đó, có tác giả là Tổng Chủ
                  biên Chương trình Giáo dục phổ thông 2018 và nhiều tác giả là
                  thành viên Ban Phát triển Chương trình tổng thể, Ban Phát
                  triển các chương trình môn học thành lập theo Quyết định của
                  Bộ trưởng Bộ GDĐT.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book8.svg"
                    alt="Phiên bản điện tử"
                  />
                </div>
                <h4>Phiên bản điện tử</h4>
                <p>
                  Bên cạnh sách giấy, bộ sách Cánh Diều còn có phiên bản điện tử
                  và nhiều học liệu điện tử sinh động đi kèm để hỗ trợ quá trình
                  giảng dạy - học tập của Giáo viên và Học sinh.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-feature-sgk pr bg-gradient">
        <div className="container">
          <div className="header">
            <h2 className="title">
              Đặc trưng <span className="inline">SGK điện tử</span> Cánh Diều
            </h2>
          </div>
          <div
            className="video video-ty1 pr"
            data-link=""
            data-toggle="modal"
            data-target="#modal-video"
          >
            <div className="video__thumb">
              <img
                src="/assets/img/thumb-video2.jpg"
                alt="Đặc trưng sách giáo khoa điện tử Cánh Diều"
              />
            </div>
            {/* <div className="video__play">
              <img
                onClick={() =>
                  setUrl(
                    "https://www.youtube.com/watch?v=ZYdoW9RzPAs&ab_channel=Hoc10"
                  )
                }
                src="/assets/img/icon-play.svg"
                alt=""
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="featured">
                <div className="img-wrapper">
                  <img src="/assets/img/icon-sgt1.svg" alt="Học liệu điện tử" />
                </div>
                <h4>Học liệu điện tử</h4>
                <p>
                  Các học liệu điện tử được tích hợp trong phiên bản điện tử SGK
                  Cánh Diều làm phong phú quá trình dạy và học.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="featured">
                <div className="img-wrapper">
                  <img src="/assets/img/icon-sgt2.svg" alt="Mục lục điện tử" />
                </div>
                <h4>Mục lục điện tử</h4>
                <p>
                  Mục lục điện tử giúp người dùng truy cập dễ dàng và nhanh
                  chóng theo chương, bài học cụ thể.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="featured">
                <div className="img-wrapper">
                  <img src="/assets/img/icon-sgt3.svg" alt="Thanh công cụ" />
                </div>
                <h4>Thanh công cụ</h4>
                <p>
                  Thanh công cụ cung cấp những tính năng tiện ích và quan trọng;
                  là công cụ thiết thực và đem lại hiệu quả cao trong quá trình
                  dạy học.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="featured">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/icon-sgt4.svg"
                    alt="Bài tập tương tác"
                  />
                </div>
                <h4>Bài tập tương tác</h4>
                <p>
                  Kho bài tập tương tác đa dạng và phong phú với nhiều hình thức
                  và thể loại khác nhau; luôn được cập nhật hàng tuần.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-type-sgk bg-gr">
        <div className="container pr">
          <div className="header">
            <h2 className="title">Thể loại sách điện tử</h2>
          </div>
          <div className="sgk flex pr">
            <div className="sgk__img">
              <img
                src="/assets/img/img-book2.png"
                alt="Thể loại sách giáo khoa điện tử"
              />
            </div>
            <div className="sgk__type">
              <span>Sách giáo khoa, sách chuyên đề</span>
              <span>
                Sách bổ <br /> trợ
              </span>
              <span>Sách giáo viên</span>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-plan hoc10-plan--cs1 bg-gr">
        <div className="container">
          <div className="plan">
            <div className="header">
              <h2 className="title">
                Kế hoạch phát hành sách theo chương trình GDPT mới 2018
              </h2>
            </div>
            <ul className="timeline flex">
              {plans.map(({ active, time, icon, grades }) => (
                <li className={classNames({ active })}>
                  <div className="content">
                    <time>{time}</time>
                    <div className="icon flex-center">
                      <img src={icon} alt="Ảnh lớp học" />
                    </div>
                    <div className="class flex">
                      {grades.map((grade) => (
                        <span>{grade}</span>
                      ))}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="hoc10-program pr">
        <div className="container">
          <div className="header">
            <h2 className="title">Chương trình môn học</h2>
            <form
              action=""
              method=""
              onSubmit={handleSubmit(() => onSearch())}
              className="hoc10-form"
            >
              <div className="form-group flex-center">
                <input
                  type="text"
                  onChange={(e) => setValueSearch(e.target.value)}
                  name=""
                  placeholder="Tìm kiếm"
                />
                <button
                  type="submit"
                  className="btn-submit flex-center btn-pr"
                  onClick={showToTal}
                >
                  Tìm Kiếm
                </button>
              </div>
              {showTotal ? (
                <p className="totalbook">Tìm được {total} kết quả</p>
              ) : null}
            </form>
          </div>
          <div className="subjects-list flex cursor">
            {subjects.map((subject) => (
              <div onClick={() => onShowPopupPdf(subject)} className="subject">
                <div className="subject__img">
                  <img src={subject.thumb} alt="Chương trình môn học" />
                </div>
                <div className="subject__name">{subject.title}</div>
                <p className="subject__pdf"></p>
              </div>
            ))}
          </div>

          {total > perPage && (
            <Pagination
              totalBooks={total}
              totalPerPage={perPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          )}
        </div>
      </div>
    </main>
  );
}

const H1TitleTextBook = styled.h1`
  font: 36px/48px var(--SVN-GilroyBold) !important;
  color: var(--gray) !important;
  text-align: center !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  span {
    display: inline-block;
    color: var(--orange);
    position: relative;
  }
`;

const BuyBook = styled.div`
  background-image: url("https://hoc10.monkeyuni.net/upload/hoc10/thumb/TrangSGKCD.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  min-height: 150px;
  gap: 32px;

  & > a {
    padding: 5px 10px;
    max-width: 300px;
    height: 48px;
  }
`;
