import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import Slider from "edu_lms_v2/components/Slider";
import "./style.css";

export default function Present(props) {
  return (
    <Modal {...props} dialogClassName="present-modal">
      <Modal.Body>
        <Button onClick={() => props.handleClose()}>
          <Icon className="fa fa-times-circle-o" aria-hidden="true"></Icon>
        </Button>
        <div className="hoc10-slider">
          <Slider
            className="slider-home pr"
            centerMode={false}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            centerPadding={0}
            autoplay={false}
            dots={true}
            arrows={true}
          >
            {props.dataSlide.map((slide, index) => (
              <ImgWrapper>
                <Image
                  key={index}
                  src={`${process.env.REACT_APP_MEDIA_URL_APP}${slide.background}`}
                  alt="Đọc sách"
                />
              </ImgWrapper>
            ))}
          </Slider>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const ImgWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex !important;
  justify-content: center;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Icon = styled.i`
  font-size: 35px;
  color: #ff7707;
`;

const Button = styled.button`
  position: fixed;
  top: 0px;
  right: 10px;
  z-index: 10;
  background: transparent;
  border: none;
`;
