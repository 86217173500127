import { useState, useEffect, useRef } from "react";
import { getDetailArticle } from "edu_lms/services/blog";
import Loading from "edu_lms_v2/components/Loading";
import SEO from "edu_lms/components/Seo";
import styles from "../BlogStyle.module.scss";
import { NotFoundContainer } from "edu_lms/modules/NotFound";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";
import HeadingViewOther from "./HeadingViewOther";
import TitleDetail from "../ViewDetail/TitleDetail";
import { SLUG_KNOWLEDGE } from "../constant";

export default function ViewDetailOther(props) {
  useScrollToTop();
  const refScreen = useRef(null);
  const [detailItem, setDetailItem] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isShowLinkShare, setIsShowLinkShare] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getDataDetail = () => {
    const data = {
      alias: props.match.params.slug,
    };
    getDetailArticle(data).then((res) => {
      setIsLoading(false);
      if (res.data.status === "success") {
        setDetailItem(res.data.data);
      } else {
        setIsNotFound(true);
      }
    });
  };

  useEffect(() => {
    getDataDetail();
  }, [props.match.params]);

  if (isNotFound && !detailItem) {
    return (
      <main>
        <NotFoundContainer />
      </main>
    );
  }

  if (isLoading) {
    return (
      <main>
        <div className={styles.height100}>
          <Loading />
        </div>
      </main>
    );
  }

  const dataSEO = {
    title: detailItem?.blog?.meta_title,
    description: detailItem?.blog?.meta_description_cv,
    url: `${process.env.REACT_APP_URL}${SLUG_KNOWLEDGE.knowledge}/${SLUG_KNOWLEDGE.other}/${props.match.params.slug}/`,
    img: detailItem?.blog?.images[0]?.path,
    robots: detailItem?.blog?.meta_robots,
    keywords: detailItem?.blog?.meta_keyword,
  };

  return (
    <main className={styles.knowledge} ref={refScreen}>
      <SEO data={dataSEO} />
      {detailItem && (
        <div
          className={styles.containerDetail}
          onClick={() => setIsShowLinkShare(false)}
        >
          <ScrollAndFrequentQuestion
            refScreen={refScreen?.current?.offsetHeight}
          />
          <HeadingViewOther params={props.match.params} />
          <div className={styles.viewOtherTilte}>
            <TitleDetail
              detailItem={detailItem}
              params={props.match.params}
              isShowLinkShare={isShowLinkShare}
              setIsShowLinkShare={setIsShowLinkShare}
            />
          </div>
        </div>
      )}
    </main>
  );
}
