import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import PrivacyDetails from "../Privacy/PrivacyDetails";

export default function ModalPrivacy({ show, onHide }) {
  return (
    <Modal
      animation="true"
      show={show}
      onHide={onHide}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
    >
      <div className="hoc10-tab-content">
        <div id="term" className="tab-pane">
          <div className="term h1Seo">
            <div className="hoc10-contact policy h1Seo h4Seo pb-0">
              <Modal.Header closeButton className="border-0 py-0">
                <h1 className="title">Chính sách riêng tư</h1>
              </Modal.Header>
              <Privacy>
                <Modal.Body className="py-0">
                  <PrivacyDetails />
                </Modal.Body>
              </Privacy>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
const Privacy = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;
