import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import MessPhonePw from "./MessPhonePw";
import ResetPw from "./ResetPw";
import SuccessPw from "./SuccessPw";

export default function (props) {
  const [currentInput, setCurrentInput] = useState("");
  const [isActiveMessPhonePw, setActiveMessPhonePw] = useState(false);
  const [dataOTP, setDataOTP] = useState({});
  const [isActiveResetPw, setActiveResetPw] = useState(false);
  const [isActiveSuccessPw, setActiveSuccessPw] = useState(false);
  const [tokenToChangePW, setTokenToChangePW] = useState(false);

  const onActiveMessPhonePw = (data) => {
    setDataOTP(data);
    props.setActiveForgotPw(false);
    setActiveMessPhonePw(true);
  };
  const onActiveRisetPw = () => {
    setActiveMessPhonePw(false);
    setActiveResetPw(true);
  };
  const onActiveSuccessPw = () => {
    setActiveResetPw(false);
    setActiveSuccessPw(true);
  };
  const onActiveLogin = () => {
    setActiveSuccessPw(false);
    if (props.isPopupLogin) {
      props.setLoginModalShow(true);
    }
  };

  return (
    <>
      <ForgotPassword
        show={props.isActiveForgotPw}
        onHide={() => props.setActiveForgotPw(false)}
        setCurrentInput={setCurrentInput}
        onActiveMessPhonePw={onActiveMessPhonePw}
      />
      <MessPhonePw
        show={isActiveMessPhonePw}
        currentInput={currentInput}
        onHide={() => setActiveMessPhonePw(false)}
        data={dataOTP}
        onActiveRisetPw={onActiveRisetPw}
        setToken={(token) => setTokenToChangePW(token)}
      />
      <ResetPw
        data={dataOTP}
        show={isActiveResetPw}
        onHide={() => setActiveResetPw(false)}
        onActiveSuccessPw={onActiveSuccessPw}
        token={tokenToChangePW}
      />
      <SuccessPw
        show={isActiveSuccessPw}
        onHide={() => setActiveSuccessPw(false)}
        onActiveLogin={onActiveLogin}
      />
    </>
  );
}
