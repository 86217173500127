import { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import _ from "lodash";
import { Link } from "react-router-dom";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import * as PATH from "consts/routePaths";
import { data_news } from "edu_lms_v2/data/data_news";
import styles from "../../News/News.module.scss";
import Contact from "../Contact";
import Iframe from "react-iframe";

export default function ViewDetail(props) {
  useScrollToTop();
  const [dataNewsTraining, setDataNewsTraining] = useState([]);
  const [dataManyViews, setDataManyViews] = useState([]);
  useEffect(() => {
    let dataTraining = _.filter(data_news, { categoryId: "tap-huan" });
    let manyViews = _.orderBy(dataTraining, ["viewers"], ["desc"]);
    setDataNewsTraining(dataTraining);
    setDataManyViews(manyViews.slice(0, 4));
  }, []);
  let dataTraining = _.filter(data_news, {
    slug: `${props.match.params.slug}`,
  });

  return (
    <main>
      <div className="hoc10-breadcrumb">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="">Tập huấn</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Chọn sách giáo khoa: Sách Cánh Diều áp đảo
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-news">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="article post">
                {dataTraining.map((item, index) => (
                  <article className="post" key={index}>
                    <h1 className="title">{item?.title}</h1>
                    <div class="flex-between mb-4">
                      <div className="flex-lc">
                        <div className="time flex-lc">
                          <i class="icon icon-time"></i> {item?.time}
                        </div>
                        <div className="view flex-lc">
                          <i class="icon icon-view"></i> {item?.viewers}
                        </div>
                      </div>
                      <Iframe
                        src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&layout=button&size=large&width=87&height=28&appId"
                        width="87"
                        height="28"
                        style={{ boder: "none", overFlow: "hidden" }}
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></Iframe>
                    </div>
                    <img src={item?.img} className="mb-3" />
                    <div className="post__content">
                      <p className="line">{item?.content}</p>
                      <div className="flex-lc">
                        <div className="time flex-lc">
                          <i className="icon icon-time"></i> 22/10/2021
                        </div>
                        <div className="view flex-lc">
                          <i className="icon icon-view"></i> 1.288
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
              <div className="related">
                <h4>Tin liên quan</h4>
                {dataNewsTraining.map((item, index) => (
                  <article className="post post-ty2 flex" key={index}>
                    <Link to="" className="post__thumb">
                      <img src={item?.img} alt="ảnh lỗi" />
                    </Link>
                    <div className="post__content">
                      <h3>
                        <Link to="">{item?.title}</Link>
                      </h3>
                      <p className={`line line2 ${styles.text_hidden}`}>
                        {item?.content}
                      </p>
                      <div className="flex-lc">
                        <div className="time flex-lc">
                          <i className="icon icon-time"></i> {item?.time}
                        </div>
                        <div className="view flex-lc">
                          <i className="icon icon-view"></i> {item?.viewers}
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="sidebar">
                <div className="readmany">
                  <h4>Nhiều người đọc</h4>
                  {dataManyViews.map((item, index) => (
                    <article className="post post-ty3 flex pr" key={index}>
                      <Link to="" className="post__thumb">
                        <img src={item?.img} className="fit" alt="" />
                      </Link>
                      <div className="post__content">
                        <h3 className="line line3">
                          <Link to="">{item?.content}</Link>
                        </h3>
                      </div>
                    </article>
                  ))}
                </div>
                <div className="email-register">
                  <h4>Đăng ký trải nghiệm đầy đủ tính năng Hoc10</h4>
                  <form action="" method="">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Nhập địa chỉ email"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn-submit btn-pr flex-center"
                    >
                      Đăng ký ngay
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </main>
  );
}
