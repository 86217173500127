import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getDetailHistoryTraining } from "edu_lms/services/training";
import * as PATH from "consts/routePaths";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { EMAIL_VERIFIED } from "edu_lms/constants/type";
import { postLogout } from "../../../../edu_lms/services/logout/logout";

export default function DetailHistoryQuiz({
  data,
  tookExam,
  checkInformation,
  setCheckInformation,
  titleSubject,
  totalQuestion,
  quizId,
  ...props
}) {
  useScrollToTop();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    props.setLogin(true);
    cleanLocalStorage();
    postLogout();
    dispatch(onDispatchDataInfo({ avatar: "" }));
    history.push(PATH.ROUTE_PATH_V3_TRAINING);
  };
  const playTraining = () => {
    props.userInfo?.email_verified === EMAIL_VERIFIED &&
      history.push({
        pathname: `${PATH.ROUTE_PATH_LIST_QUIZ_DETAIL}/${data?.grade_id}/${data?.subject_id}/${quizId}`,
        state: {
          subjectName: titleSubject,
          quizId: quizId,
        },
      });
  };
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <div className="content-exam-exercises-wrapper pl-4 pr-4 pb-4">
          <div className="text-center">
            <Title>
              Bài kiểm tra tập huấn môn&nbsp;
              <span className="monkey-color-orange">{titleSubject}</span>
            </Title>
          </div>
          <div className="exam-exercises-body pt-5 pb-5 ">
            <Container>
              <Row>
                <Col md={12}>
                  <div
                    className="w-75 text-center pt-3 pb-3"
                    style={{
                      margin: "0 auto",
                      borderRadius: "5px",
                      border: "1px solid #212529",
                    }}
                  >
                    {props.userInfo && (
                      <>
                        <strong className="monkey-color-orange monkey-fz-20">
                          Thông tin giáo viên làm bài kiểm tra
                        </strong>
                        {props.userInfo?.full_name && (
                          <p className="mt-3">
                            <i
                              className="fa fa-user mr-3 monkey-color-orange"
                              aria-hidden="true"
                            />
                            <strong>Họ tên:</strong> {props.userInfo?.full_name}
                          </p>
                        )}
                        {props.userInfo?.phone && (
                          <p className="mt-3">
                            <i
                              className="fa fa-phone mr-3 monkey-color-orange"
                              aria-hidden="true"
                            />
                            <strong>SĐT:</strong>{" "}
                            <span className="mr-3">
                              {props.userInfo?.phone}
                            </span>
                          </p>
                        )}
                        {props.userInfo?.email && (
                          <p className="mt-3">
                            <i
                              className="fa fa-envelope-o mr-3 monkey-color-orange"
                              aria-hidden="true"
                            />
                            <strong>Email:</strong> {props.userInfo?.email}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </Col>
                <div
                  className="w-75 text-center d-flex"
                  style={{ margin: "0 auto" }}
                >
                  <div className="m-auto">
                    <p className="d-flex align-items-center justify-content-start mt-3 mb-3">
                      <i
                        className="fa fa-question-circle-o monkey-fz-25 mr-3 monkey-color-orange"
                        aria-hidden="true"
                      ></i>
                      <span>{totalQuestion} câu hỏi trắc nghiệm</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-start mb-3">
                      <i
                        className="fa fa-clock-o monkey-fz-25 mr-3 monkey-color-orange"
                        aria-hidden="true"
                      ></i>
                      <span>Không giới hạn thời gian làm bài</span>
                    </p>
                  </div>
                  <div className="ml-2">
                    <p className="d-flex align-items-center justify-content-start mt-3 mb-3">
                      <i
                        className="fa fa-address-card monkey-fz-22 mr-3 monkey-color-orange"
                        aria-hidden="true"
                      ></i>
                      <span>Không giới hạn số lần làm bài</span>
                    </p>
                    <p className="d-flex align-items-center justify-content-start mt-4">
                      <i
                        className="fa fa-desktop monkey-fz-22 mr-3 monkey-color-orange"
                        aria-hidden="true"
                      ></i>
                      <span>Biết ngay kết quả bài kiểm tra</span>
                    </p>
                  </div>
                </div>
                {/* </Col> */}
              </Row>
            </Container>
          </div>
          <div className="exam-exercises-footer d-flex justify-content-between mt-3 mb-3">
            <ButtonExit className="btn btn-pr" onClick={() => handleLogout()}>
              Chuyển tài khoản
            </ButtonExit>
            <button onClick={() => playTraining()} className="btn btn-pr">
              {tookExam ? "Kiểm tra lại" : "Làm bài kiểm tra"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

const ButtonExit = styled.button`
  border-radius: 6px;
  border: 1px solid #ff7707;
  background: #fff;
  color: #ff7707;
`;

const Title = styled.h4`
  font-size: 32px;
`;
