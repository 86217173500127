import * as TYPES from "edu_lms/constants/actionsType";
import { UNREAD_NOTI } from "edu_lms/constants/type";

let initialState = {
  notiUnread: 0,
};

let notiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PA_READING_NOTIFICATION:
      if (action.payload.status === UNREAD_NOTI) {
        return { ...state, notiUnread: state.notiUnread - 1 };
      } else {
        return state;
      }
    case TYPES.PA_SET_COUNT_UNREAD_NOTI:
      return { ...state, notiUnread: action.payload.count };
    default:
      return state;
  }
};

export default notiReducer;
