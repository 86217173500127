import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { Link } from "react-router-dom";
import Pagination from "edu_lms_v2/components/Pagination";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import * as PATH from "consts/routePaths";
import * as types from "edu_lms/constants/type";
import { data_news } from "edu_lms_v2/data/data_news";
import { DATA_NEWS_TRAINING } from "../../News/Constant";
import Contact from "../Contact";
import styles from "../../News/News.module.scss";

export default function ViewAllDetail() {
  useScrollToTop();
  const [dataNewsTraining, setDataNewsTraining] = useState([]);
  const [dataManyViews, setDataManyViews] = useState([]);
  const currentPage = useSelector(
    (state) => state.teachingReducer.currentPageClassroom
  );

  useEffect(() => {
    let dataTraining = _.filter(data_news, { categoryId: DATA_NEWS_TRAINING });
    let manyViews = _.orderBy(dataTraining, ["viewers"], ["desc"]);
    setDataNewsTraining(dataTraining);
    setDataManyViews(manyViews.slice(0, 4));
  }, []);

  // console.log("dataNewsTraining", dataNewsTraining[0].img);

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="#">Hỗ trợ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Tin tức
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-title bg-gr">
        <div className="container">
          <div className="header decor-h1 pr">
            <h1 className="title mb-10">Tin tức</h1>
            <p>Những tin tức cập nhật từ Hoc10</p>
          </div>
        </div>
      </div>
      <div className="hoc10-news">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="post-list">
                <ViewAllName classNameName="">Tên bài viết</ViewAllName>
                <article className="post post--highlight">
                  <Link
                    to={`${PATH.ROUTE_PATH_NEWS_TRAINING}${dataNewsTraining[0]?.slug}`}
                    title=""
                    className="post__thumb"
                  >
                    <img src={dataNewsTraining[0]?.img} />
                  </Link>
                  <div class="post__content">
                    <h3>
                      <Link to="">{dataNewsTraining[0]?.title}</Link>
                    </h3>
                    <div className="line">
                      <p className="line line2">
                        {dataNewsTraining[0]?.content}
                      </p>
                      <Link
                        to={`${PATH.ROUTE_PATH_NEWS_TRAINING}${dataNewsTraining[0]?.slug}`}
                        className="btn-viewmore"
                      >
                        Xem thêm
                      </Link>
                    </div>
                    <div class="flex-lc">
                      <div class="time flex-lc">
                        <i class="icon icon-time"></i> 22/10/2021
                      </div>
                      <div class="view flex-lc">
                        <i class="icon icon-view"></i> 1.288
                      </div>
                    </div>
                  </div>
                </article>
                {dataNewsTraining.map((item, index) => (
                  <article className="post post-ty2 flex" key={index}>
                    <Link
                      to={`${PATH.ROUTE_PATH_NEWS_TRAINING}${item?.slug}`}
                      title=""
                      className="post__thumb"
                    >
                      <img src={item?.img} />
                    </Link>
                    <div className="post__content">
                      <h3>
                        <Link to="">{item?.title}</Link>
                      </h3>
                      <p className={`line line2 ${styles.text_hidden}`}>
                        {item?.content}
                      </p>
                      <div className="flex-lc">
                        <div className="time flex-lc">
                          <i className="icon icon-time"></i> {item?.time}
                        </div>
                        <div className="view flex-lc">
                          <i className="icon icon-view"></i> {item?.viewers}
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
              </div>
              <Pagination
                total={2}
                totalPerPage={types.TOTAL_CLASSROOM_PER_PAGE}
                currentPage={currentPage}
              />
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="sidebar">
                <div className="readmany">
                  <h4>Nhiều người đọc</h4>
                  {dataManyViews.map((item, index) => (
                    <article className="post post-ty3 flex pr" key={index}>
                      <Link to="" className="post__thumb">
                        <img src={item?.img} className="fit" alt="" />
                      </Link>
                      <div className="post__content">
                        <h3 className="line line3">
                          <Link to="">{item?.content}</Link>
                        </h3>
                      </div>
                    </article>
                  ))}
                </div>
                <div className="email-register">
                  <h4>Đăng ký trải nghiệm đầy đủ tính năng Hoc10</h4>
                  <form action="" method="">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Nhập địa chỉ email"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn-submit btn-pr flex-center"
                    >
                      Đăng ký ngay
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </main>
  );
}
const ViewAllName = styled.p`
  font-weight: 700;
  font: 24px/32px var(--SVN-GilroyBold);
  line-height: 22px;
  color: #4e4c50;
`;
