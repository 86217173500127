import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { postAuthVerifyEmail } from "edu_lms/services/training";
import useQuery from "../../modules/TuSach/useQuery";
import * as PATH from "consts/routePaths";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";

export const VerifyEmailWrapper = () => {
  const query = useQuery();
  const data_verify_email = query.get("token");
  const history = useHistory();
  const dataVerifyEmail = {
    data_verify_email,
  };

  const resultUserInfo = onResultUserInfo();
  useEffect(() => {
    postAuthVerifyEmail(dataVerifyEmail).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        const email_verified = 1;
        setItemUserInfo({ ...resultUserInfo, email_verified });

        function sleep(time) {
          return new Promise((resolve) => setTimeout(resolve, time));
        }
        sleep(1000).then(() => {
          history.push(PATH.ROUTE_PATH_V3_TRAINING);
        });
      } else {
        toast.error(res.data.message);
        function sleep(time) {
          return new Promise((resolve) => setTimeout(resolve, time));
        }
        sleep(1000).then(() => {
          history.push(PATH.ROUTE_PATH_V3_TRAINING);
        });
      }
    });
  }, []);
  return <div>Xác thực email thành công</div>;
};
