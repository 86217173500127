import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import danger_img from "edu_lms_v2/assets/img/img-error.svg";
import ModalUpdateInformation from "./UpdateInformation/ModalUpdateInformation";
import { JOB_STUDENT } from "edu_lms/constants/type";

export default function ModalInformation(props) {
  const [popupdateInfor, setpopUpdateInfor] = useState(false);
  const updateInfor = () => {
    props.setShowUpdateInfor(true);
    props.setShowPopup(false);
    setpopUpdateInfor(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        id="modal-success"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal fade modal-login-success"
        animation="true"
        onHide={props.onHide}
      >
        <Modal.Header closeButton className="text-center border-0" />
        <Modal.Body className="text-center border-0 p-1">
          <div className=" img-wrapper mb-2">
            <img src={danger_img} alt="Nội dung" className="d-inline" />
          </div>
          {props?.userInfo?.job_id !== JOB_STUDENT ? (
            <>
              <p className="h5 monkey-color-black">
                Kết quả tập huấn sẽ được gửi về Sở và EMAIL GIÁO VIÊN! Thầy cô
                vui lòng cập nhật thông tin đầy đủ và chính xác.
              </p>
              <div className="justify-content-center mt-5 mb-3">
                <button
                  onClick={() => updateInfor()}
                  className="btn-pr btn-play btn-access p-3 cursor"
                >
                  Cập nhập thông tin
                </button>
              </div>
            </>
          ) : (
            <>
              {props.userInfo?.job_id === JOB_STUDENT && (
                <p className="h4 pb-3 monkey-color-black">
                  Bạn không phải là giáo viên, bạn không có quyền làm bài này.
                </p>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <ModalUpdateInformation
        show={popupdateInfor}
        onHide={() => setpopUpdateInfor(false)}
        onUpdateSuccess={props.onUpdateSuccess}
        getUserInfoApi={props.getUserInfoApi}
      />
    </>
  );
}
