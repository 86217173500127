import { create } from "zustand";
import _ from "lodash";
import { produce } from "immer";
import { useAppStore } from "stores/appStore";
import {
  getGradeSubject,
  getListBookV4,
  getSupplementDocs,
} from "edu_lms_v2/services/listBook";
import {
  BOOK_TYPE_SGK,
  BOOK_TYPE_SGV,
  BOOK_TYPE_SBT,
  BOOK_TYPE_SACH_THAM_DINH,
  BOOK_TYPE_SACH_THAM_KHAO,
  BOOK_TYPE_SACH_GIOI_THIEU,
  BOOK_TYPE_BAN_GOP_Y_XA_HOI,
  BOOK_TYPE_BAN_IN_THU,
  BOOK_TYPE_BAN_MAU,
} from "consts/book";
import { TAB_LIST_BOOK } from "edu_lms/constants/type";

export const useBookStore = create((set, get) => ({
  activateBookModalVisibility: false,
  activateBookSuccessModalVisibility: false,
  activatingBook: {},
  books: {},
  documents: {},
  recentBooks: [],
  currentBook: {},
  pages: [],
  currentPage: null,
  fetchingBooks: false,
  fetchingDocuments: false,
  gradeSubjects: [],

  // state: activateBookModalVisibility
  setActivateBookModalVisibility: (visibility) =>
    set({ activateBookModalVisibility: visibility }),
  openActivateBookModal: () => set({ activateBookModalVisibility: true }),
  closeActivateBookModal: () => set({ activateBookModalVisibility: false }),

  // state: activateBookSuccessModalVisibility
  setActivateBookSuccessModalVisibility: (visibility) =>
    set({ activateBookSuccessModalVisibility: visibility }),
  openActivateBookSuccessModal: () =>
    set({ activateBookSuccessModalVisibility: true }),
  closeActivateBookSuccessModal: () =>
    set({ activateBookSuccessModalVisibility: false }),

  // state: activatingBook
  setActivatingBook: (activatingBook) => set({ activatingBook }),

  // state: books
  setBooks: (books) => set({ books }),
  fetchBooks: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const subjectId = useAppStore.getState().subjectId;

    if (gradeId && subjectId) {
      if (_.isEmpty(_.get(get().books, [gradeId, subjectId]))) {
        set({ fetchingBooks: true });
        const bookTypes = [
          BOOK_TYPE_SGK,
          BOOK_TYPE_SGV,
          BOOK_TYPE_SBT,
          BOOK_TYPE_SACH_THAM_DINH,
          BOOK_TYPE_SACH_THAM_KHAO,
          BOOK_TYPE_SACH_GIOI_THIEU,
          BOOK_TYPE_BAN_GOP_Y_XA_HOI,
          BOOK_TYPE_BAN_IN_THU,
          BOOK_TYPE_BAN_MAU,
        ];

        const res = await getListBookV4(
          gradeId,
          subjectId,
          bookTypes.toString()
        );
        if (res.status === 200) {
          const books = _.isArray(res?.data?.data?.list_book)
            ? res?.data?.data?.list_book
            : [];
          set(
            produce((state) => {
              if (!get().books[gradeId]) {
                state["books"][gradeId] = {};
              }
              state["books"][gradeId][subjectId] = books;
            })
          );
        }
        set({ fetchingBooks: false });
      }
    }
  },

  // state: documents
  setDocuments: (documents) => set({ documents }),
  fetchDocuments: async () => {
    const gradeId = useAppStore.getState().gradeId;
    if (gradeId) {
      if (_.isEmpty(_.get(get().documents, [gradeId]))) {
        set({ fetchingDocuments: true });

        const res = await getSupplementDocs(gradeId);

        if (res.status === 200) {
          const documents = _.isArray(res?.data?.data?.data)
            ? res?.data?.data?.data
            : [];

          set(
            produce((state) => {
              state["documents"][gradeId] = documents;
            })
          );
        }
        set({ fetchingDocuments: false });
      }
    }
  },
  
  fetchUpdateDocuments: async () => {
    const gradeId = useAppStore.getState().gradeId;
    if (gradeId) {
      const res = await getSupplementDocs(gradeId);
      if (res.status === 200) {
        const documents = _.isArray(res?.data?.data?.data)
            ? res?.data?.data?.data
            : [];
    
        set(
            produce((state) => {
              state["documents"][gradeId] = documents;
            })
        );
      }
    }
  },

  // state: gradeSubjects
  setGradeSubjects: (gradeSubjects) => set({ gradeSubjects }),
  fetchGradeSubjects: async () => {
    const gradeId = useAppStore.getState().gradeId;
    const grades = useAppStore.getState().grades;

    const currentGrade = grades.find((grade) => grade.id === gradeId);

    if (currentGrade) {
      const data = {
        level_school: currentGrade.parent_id,
        type_menu: TAB_LIST_BOOK,
      };
      const res = await getGradeSubject(data);

      if (res && res.status === 200) {
        const gradeSubjects = res?.data?.data || {};
        set({ gradeSubjects });
      }
    }
  },
}));
