import React, { useEffect } from "react";
import styled from "styled-components";
import { useCountUp } from "react-countup";
import * as dayjs from "dayjs";

const baseTime = 1693210441;
const basePracticeCount = 10000;
const practiceCountStepUp = 25;
const baseCompleteCount = 9000;
const completeCountStepUp = 24;
const intervalConfig = 60000;
const timerConfig = {
  delay: 0,
  duration: 2.5
}

const getCountNumber = () => {
  const differenceTimeInMinutes = Math.round((dayjs().unix() - baseTime) / 60);
  const _practiceCount =
    basePracticeCount + differenceTimeInMinutes * practiceCountStepUp;
  const _completeCount =
    baseCompleteCount + differenceTimeInMinutes * completeCountStepUp;

  return {
    _practiceCount,
    _completeCount,
  };
};

export default function PracticeCounter({}) {
  const practiceCountRef = React.useRef(null);
  const completeCountRef = React.useRef(null);

  const { _practiceCount, _completeCount } = getCountNumber();

  const { update: updatePracticeCount } = useCountUp({
    ref: practiceCountRef,
    start: _practiceCount / 2,
    end: _practiceCount,
    ...timerConfig
  });
  const { update: updateCompleteCount } = useCountUp({
    ref: completeCountRef,
    start: _completeCount / 2,
    end: _completeCount,
    ...timerConfig
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const { _practiceCount, _completeCount } = getCountNumber();
      updatePracticeCount(_practiceCount);
      updateCompleteCount(_completeCount);
    }, intervalConfig);
    
    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <div>
        <i className="fa fa-edit" aria-hidden="true" />{" "}
        <span ref={practiceCountRef} /> <span>lượt làm bài</span>
      </div>
      <div>
        <i className="fa fa-check" aria-hidden="true" />{" "}
        <span ref={completeCountRef} /> <span>hoàn thành</span>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 180px;
  padding: 0 10px;
`;
