const SPLIT_ANSWER_RESULT = "#";
const OLD_SPLIT_ANSWER_RESULT = "##";
const SPLIT_ANSWER_MANY_RESULT = "&&";
const SPLIT_ANSWER_TEXT = " ";

const FORMAT_WORD = /[&\/\\#,+()$~%.":*?<>{}]/g;

export {
  SPLIT_ANSWER_RESULT,
  OLD_SPLIT_ANSWER_RESULT,
  SPLIT_ANSWER_MANY_RESULT,
  SPLIT_ANSWER_TEXT,
  FORMAT_WORD,
};
