// import * as dayjs from "dayjs";
import styled from "styled-components";
import dayjs from "edu_lms/libs/dayjs";
import Header from "./Header";
import BoxResult from "./BoxResult";
import BoxQuestions from "./BoxQuestions";
import ItemQuestion from "./ItemQuestion";
import { displayDuration } from "edu_lms/constants/type";

export default function ResultQuestion({
  listQuestion,
  result,
  limitedTime,
  originQuestions,
}) {
  const totalQuestion = result?.total_question;
  const totalCorrect = result?.total_correct;
  const timeEnd = dayjs.unix(result.time_end);
  const timeStart = dayjs.unix(result.time_start);
  const durationTime = dayjs.duration(timeEnd.diff(timeStart));

  const resultDetail = [
    {
      id: 1,
      title: "Thời gian kiểm tra",
      result: limitedTime && `${limitedTime} phút`,
    },
    {
      id: 2,
      title: "Tổng số câu hỏi",
      result: totalQuestion,
    },
    {
      id: 3,
      title: "Số câu đúng",
      result: totalCorrect,
    },
    {
      id: 4,
      title: "Độ chính xác",
      result: `${((totalCorrect / totalQuestion) * 100).toFixed(1)}%`,
    },
    {
      id: 5,
      title: "Thời gian làm bài",
      result: `${displayDuration(durationTime)}s`,
    },
    {
      id: 6,
      title: "Tốc độ trung bình",
      result: `${dayjs
        .unix((result?.time_end - result?.time_start) / result?.total_question)
        .format("mm:ss")}s`,
    },
  ];

  return (
    <div className="hoc10-test container ">
      <Header limitedTime={limitedTime} />
      <div className="main">
        <ResultWrapper className=" flex pt-3">
          <ResultDetail className="sidebar col-lg-3 h-100 scrollbar">
            <BoxResult
              numberCorrect={totalCorrect}
              numberWrong={totalQuestion - totalCorrect}
              resultDetail={resultDetail}
              totalQuestion={totalQuestion}
            />
            <BoxQuestions listQuestion={listQuestion} />
          </ResultDetail>
          <ResultDetail className="answer-list col-lg-9 h-100 scrollbar">
            <ItemQuestion
              listQuestion={listQuestion}
              originQuestions={originQuestions}
            />
          </ResultDetail>
        </ResultWrapper>
      </div>
    </div>
  );
}

const ResultWrapper = styled.div`
  @media (min-width: 768px) {
    height: calc(100vh - 58px);
  }
`;
const ResultDetail = styled.div`
  @media (min-width: 768px) {
    overflow-x: hidden;
    &.scrollbar {
      float: left;
      overflow-y: scroll;
    }
    &.scrollbar::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }
    &.scrollbar::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }
    &.scrollbar::-webkit-scrollbar-thumb {
      background-color: #2a404f;
    }
  }
`;
