import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  getDataUrlListProvince,
  getDataUrlListSchoolByProvince,
} from "edu_lms/services/info/info";
import { getListResultTeacherTraining } from "edu_lms/services/teachingManager";
import Loading from "edu_lms_v2/components/Loading";
import Pagination from "../../components/Pagination";
import dayjs from "edu_lms/libs/dayjs";
import {
  DEFAULT_DISTRICT,
  DEFAULT_PROVICE,
  DEFAULT_SCHOOL,
  DEFAULT_WARD,
} from "../../../edu_lms/constants/type";
import styles from "./DashBoardTraining.module.scss";
import calendarIcon from "edu_lms_v2/assets/img/calendar.svg";
import { getReportTraining } from "edu_lms/services/dashboard";

export const InfomationFilter = (props) => {
  const { control, handleSubmit, setValue, getValues } = useForm({
    mode: "onChange",
  });
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [listSchool, setListSchool] = useState([]);
  const [dataReportOverView, setDataReportOverView] = useState([]);
  const [dataReportDay, setDataReportDay] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingReportOverView, setLoadingReportOverView] = useState(false);
  const [loadingReportDay, setLoadingReportDay] = useState(false);

  const startTime = dayjs().startOf("day");
  const endTime = dayjs().endOf("day");

  useEffect(() => {
    const listProvince = formatedValueOption(props.listProvince);
    listProvince.unshift(DEFAULT_PROVICE);
    setListProvince(listProvince);
  }, [props.listProvince]);

  useEffect(() => {
    const dataForm = getValues();
    handleFilter({ ...dataForm, limit: 10, page });
    handleFilterReportDay();
  }, []);

  const formatedValueOption = (list) => {
    const formatedValue = list.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    return formatedValue;
  };

  const handleFilter = (data) => {
    if (data.time_start && data?.time_start >= data?.time_end) {
      return toast.error("Thời gian bắt đầu và kết thúc không hợp lệ!");
    }
    const valueForm = getValues();
    const dataForm = {
      province_id: valueForm.province_id?.value ?? "",
      district_id: valueForm.district_id?.value ?? "",
      ward_id: valueForm.ward_id?.value ?? "",
      school_id: valueForm.school_id?.value ?? "",
      start_time: valueForm.time_start
        ? dayjs(valueForm.time_start).unix()
        : "",
      end_time: valueForm.time_end
        ? dayjs(valueForm.time_end).unix()
        : "",
    };
    setLoadingReportOverView(true);
    getListResultTeacherTraining(dataForm) // api overview
      .then((res) => {
        if (res.data.status === "success") {
          setDataReportOverView(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingReportOverView(false);
      });
  };

  const handleFilterReportDay = (data) => {
    if (data?.time_start && data?.time_start >= data?.time_end) {
      return toast.error("Thời gian bắt đầu và kết thúc không hợp lệ!");
    }
    const dataForm = {
      time_start: data?.time_start
        ? dayjs(data.time_start).unix()
        : startTime.unix(),
      time_end: data?.time_end ? dayjs(data.time_end).unix() : endTime.unix(),
    };
    setLoadingReportDay(true);
    getReportTraining(dataForm)
      .then((res) => {
        if (res.data.status == "success") {
          setDataReportDay(res.data.data);
        } else {
          return toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingReportDay(false);
      });
  };
  const onChangePage = (page) => {
    const dataForm = getValues();
    setPage(page);
    handleFilter({ ...dataForm, limit: 10, page });
  };
  const onChangeProvince = (idProvince) => {
    setPage(1);
    getDataUrlListProvince(idProvince).then((res) =>
      setListDistrict(
        idProvince !== "" ? formatedValueOption(res.data.data) : []
      )
    );
    setListWard([]);
    setListSchool([]);
  };
  const onChangeDistrict = (idDistrict) => {
    setPage(1);
    getDataUrlListProvince(idDistrict).then((res) =>
      setListWard(formatedValueOption(res.data.data))
    );

    setListSchool([]);
  };
  const onChangeWard = (idWard) => {
    setPage(1);

    getDataUrlListSchoolByProvince(idWard, 0).then((res) =>
      setListSchool(formatedValueOption(res.data.data))
    );
  };

  return (
    <>
    <p className="monkey-color-black monkey-fz-20 mb-2">
          1. Báo cáo tổng quan
    </p>
      <Form onSubmit={handleSubmit(handleFilter)}>
        <div className="col-12 col-md-12">
          <div className="row mt-3">
            <div className="col-12 col-sm-4">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Tỉnh, thành phố:</Form.Label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Chọn tỉnh/ thành phố"
                      options={listProvince}
                      maxMenuHeight={200}
                      defaultValue={DEFAULT_PROVICE}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeProvince(e.value);
                        setValue("district_id", "");
                        setValue("ward_id", "");
                        setValue("school_id", "");
                      }}
                    />
                  )}
                  name="province_id"
                  control={control}
                />
              </Form.Group>
            </div>
            <div className="col-12 col-sm-4">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Quận, huyện:</Form.Label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Chọn quận/ huyện/ thị xã"
                      options={listDistrict}
                      maxMenuHeight={200}
                      defaultValue={DEFAULT_DISTRICT}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeDistrict(e.value);
                        setValue("ward_id", "");
                        setValue("school_id", "");
                      }}
                    />
                  )}
                  name="district_id"
                  control={control}
                />
              </Form.Group>
            </div>
            <div className="col-12 col-sm-4">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Xã, phường, thị trấn:</Form.Label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Chọn xã/ phường/ thị trấn"
                      options={listWard}
                      maxMenuHeight={200}
                      defaultValue={DEFAULT_WARD}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeWard(e.value);
                        setValue("school_id", "");
                      }}
                    />
                  )}
                  name="ward_id"
                  control={control}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-12 col-sm-4">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Trường:</Form.Label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Chọn trường"
                      defaultValue={DEFAULT_SCHOOL}
                      options={listSchool}
                      maxMenuHeight={200}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        setPage(1);
                      }}
                    />
                  )}
                  name="school_id"
                  control={control}
                />
              </Form.Group>
            </div>
            <div className={`col-12 col-sm-4 ${styles.InputDate}`}>
              <span>Ngày bắt đầu:</span>
              <div>
                <Controller
                  control={control}
                  name="start_time"
                  render={({ onChange, value, onBlur }) => (
                    <DatePicker
                      className="mt-2"
                      onChange={(e) => {
                        setPage(1);
                        onChange(e);
                      }}
                      selected={value}
                      showTimeSelect
                      onBlur={onBlur}
                      dateFormat="dd/MM/yyyy, h:mm aa"
                      maxDate={new Date()}
                      placeholderText="Chọn ngày"
                    />
                  )}
                />
                {/* <img src={calendarIcon} className="calender" alt="" /> */}
              </div>
            </div>

            <div className={`col-12 col-sm-4 ${styles.InputDate}`}>
              <span>Ngày kết thúc:</span>
              <div>
                <Controller
                  control={control}
                  name="end_time"
                  render={({ onChange, value, onBlur }) => (
                    <DatePicker
                      className="mt-2"
                      onChange={(e) => {
                        setPage(1);
                        onChange(e);
                      }}
                      selected={value}
                      onBlur={onBlur}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy, h:mm aa"
                      maxDate={new Date()}
                      placeholderText="Chọn ngày"
                    />
                  )}
                />
                {/* <img src={calendarIcon} className="calender" alt="" /> */}
              </div>
            </div>

            <div className="col-12 col-sm-4">
              <button className="btn btn-pr" type="submit">
                Tìm kiếm
              </button>
            </div>
          </div>
        </div>
      </Form>
      <>
        {!loadingReportOverView ? (
          <div className="tab-content hoc10-tab-content text-center shadow-lg p-0 mb-2 table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className=" border-right border-top-0 ">
                    Tổng số tài khoản giáo viên
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Đã làm bài
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Đã xác thực
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Chưa xác thực
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{dataReportOverView.total ?? ""}</td>
                  <td>{dataReportOverView.total_finished ?? ""}</td>
                  <td>{dataReportOverView.total_user_email_verified ?? ""}</td>
                  <td>
                    {dataReportOverView.total_user_not_email_verified ?? ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Loading className="mt-2" />
        )}
      </>

      <p className="monkey-color-black monkey-fz-20 mt-4">
            2. Báo cáo theo ngày
      </p>
      <Form onSubmit={handleSubmit(handleFilterReportDay)}>
        <div className="row my-5">
          <div className={`col-12 col-sm-4 ${styles.InputDate}`}>
            <span>Ngày bắt đầu:</span>
            <div>
              <Controller
                control={control}
                name="time_start"
                render={({ onChange, value, onBlur }) => (
                  <DatePicker
                    className="mt-2"
                    onChange={(e) => {
                      setPage(1);
                      onChange(e);
                    }}
                    selected={value}
                    showTimeSelect
                    onBlur={onBlur}
                    dateFormat="dd/MM/yyyy, h:mm aa"
                    maxDate={new Date()}
                    placeholderText="Chọn ngày"
                  />
                )}
              />
              {/* <img src={calendarIcon} className="calender" alt="" /> */}
            </div>
          </div>

          <div className={`col-12 col-sm-4 ${styles.InputDate}`}>
            <span>Ngày kết thúc:</span>
            <div>
              <Controller
                control={control}
                name="time_end"
                render={({ onChange, value, onBlur }) => (
                  <DatePicker
                    className="mt-2"
                    onChange={(e) => {
                      setPage(1);
                      onChange(e);
                    }}
                    selected={value}
                    onBlur={onBlur}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy, h:mm aa"
                    maxDate={new Date()}
                    placeholderText="Chọn ngày"
                  />
                )}
              />
              {/* <img src={calendarIcon} className="calender" alt="" /> */}
            </div>
          </div>

          <div className="col-12 col-sm-4 mt-4">
            <button className="btn btn-pr" type="submit">
              Tìm kiếm
            </button>
          </div>
        </div>
      </Form>

      {!loadingReportDay && props.userInfo.is_user_hoc10 ? (
        <>
          <div className="tab-content hoc10-tab-content text-center shadow-lg p-0 mb-2 table-responsive">
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col" className=" border-right border-top-0 ">
                    Ngày
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Số tài khoản tạo
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Yêu cầu xác thực
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Gửi thành công trên hệ thống
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Gửi thành công trên hệ thống nhưng không đến mail của khách
                    hàng
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Gửi không công trên hệ thống
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Mở mail
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Nhập đúng OTP
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Nhập sai OTP
                  </th>
                  <th scope="col" className=" border-right border-top-0 ">
                    Xác thực thành công
                  </th>
                </tr>
              </thead>
              {dataReportDay.map((data, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td>{data.current_data}</td>
                      <td>{data.count_user}</td>
                      <td>{data.count_request_verify}</td>
                      <td>{data.count_sent_email_success}</td>
                      <td>
                        {data.count_sent_email_success -
                          data.count_delivered_success -
                          data.count_processed_email -
                          data.count_open_email}
                      </td>
                      <td>{data.count_sent_email_fail}</td>
                      <td>{data.count_open_email}</td>
                      <td></td>
                      <td></td>
                      <td>{data.count_user_verify}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </>
      ) : (
        <Loading className="mt-2" />
      )}
    </>
  );
};
