import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { ListCheckbox } from "modules/TuSach/const";

export default function FilterSubject({
  dataSubjects,
  handleFilterSubject,
  subjectsName,
}) {
  return (
    <>
      {dataSubjects.map((item, index) => (
        <ListCheckbox key={index} className="flex-lc pr">
          <Span className="d-flex">
            <input
              onChange={() => handleFilterSubject(item)}
              type="checkbox"
              value={item.slug}
              checked={_.includes(subjectsName, item)}
            />
            <span className="checked align-items-center" />
            <ItemName className="pr-1">{item.name} </ItemName>
          </Span>
        </ListCheckbox>
      ))}
    </>
  );
}

const ItemName = styled.span`
  flex: 1;
`;

const Span = styled.span`
  align-items: flex-start !important;
  @media (max-width: 992px) {
    align-items: center !important;
  }
`;
