import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import { MODE_FILTER_HIDE, TAB_COURSE } from "../../../edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Loading from "edu_lms_v2/components/Loading";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import {
  CHOOSE_GRADE_LECTURE,
  SEARCH_LECTURE,
  CHOOSE_SUBJECT_LECTURE,
} from "edu_lms/constants/eventNameGTM";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import SchoolLevel from "edu_lms_v2/components/SchoolLevel";
import ComboBox from "edu_lms_v2/components/ComboBox";
import { currentSchoolId, renderDocumentByType } from "modules/TuSach/const";
import {
  getGradeSubject,
  getSupplementDocs,
} from "edu_lms_v2/services/listBook";
import {
  DEFAULT_GRADE_ID,
  DEFAULT_SUBJECT_ID,
  SCHOOL_LEVEL_DEFAULT,
} from "../MockTest/constant";
import { Link } from "react-router-dom";
import styled from "styled-components";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import { onUpdateCourseFilter } from "./actions";
import { DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU, DOCUMENT_TYPE_ALL } from "consts/book";

export default function Course() {
  useScrollToTop();
  const { register, handleSubmit } = useForm();

  const { list } = useSelector((state) => state.courseReducers);

  const dispatch = useDispatch();
  const [perPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(list.currentPage);
  const [courses, setCourses] = useState([]);
  const [valueSchool, setValueSchool] = useState(currentSchoolId(list.block));
  const [title, setTitle] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");

  const [grade, setGrade] = useState({
    id: [DEFAULT_GRADE_ID],
    currentGrade: "Lớp 1",
    listGrade: [],
  });

  const [subject, setSubject] = useState({
    id: [DEFAULT_SUBJECT_ID],
    currentSubject: "Tiếng Việt",
    listSubject: [],
  });

  const getWorkSheet = () => {
    setLoading(true);
    getSupplementDocs(
      grade.id.toString(),
      subject.id.toString(),
      DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU,
      DOCUMENT_TYPE_ALL,
      title,
      perPage,
      currentPage
    )
      .then((res) => {
        if (res.data.status === "success") {
          const response = res.data.data;
          const _total = Math.ceil(response.total / perPage);
          setCourses(response.data);
          setTotal(_total);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (dataForm) => {
    setEventGTM({ event: SEARCH_LECTURE, search_key: dataForm.title });
  };

  const onChangeBlock = (valueSchool, gradeId, subjectId) => {
    const data = {
      level_school: valueSchool,
      type_menu: TAB_COURSE,
    };

    getGradeSubject(data).then((res) => {
      // handle show grade
      const initGrade = res.data.data.grades.map((item) => ({
        ...item,
        isChecked: Boolean(item.grade_id == gradeId),
        id: item.grade_id,
        name: item.grade_name,
      }));

      const checkedGrade = initGrade.find(
        ({ isChecked }) => isChecked === true
      );

      if (checkedGrade) {
        setGrade({
          id: [checkedGrade?.id],
          currentGrade: checkedGrade?.name,
          hasSubjectIds: checkedGrade.has_subject_ids,
          listGrade: initGrade,
        });
      }

      // handle show subject
      const initSubject = res.data.data.subjects.map((item) => ({
        ...item,
        isChecked: Boolean(item.subject_id == subjectId),
        id: item.subject_id,
        title: item.subject_name,
      }));

      const checkedSubject = initSubject.find(
        ({ isChecked }) => isChecked === true
      );

      setSubject({
        id: [checkedSubject?.id],
        currentSubject: checkedSubject?.title,
        hasGradeIds: checkedSubject.has_grade_ids,
        listSubject: initSubject,
      });
    });
  };

  const handleChangeValueSchool = (option) => {
    const block = SCHOOL_LEVEL_DEFAULT.find(
      (item) => item.schoolLevel === option.value
    );
    const filter = {
      block: option.value,
      gradeId: block.gradeIdDefault,
      subjectId: block.subjectIdDefault,
      currentPage: 1,
    };

    updateFilter(filter);
    setValueSchool(currentSchoolId(block.schoolLevel));
    onChangeBlock(option.value, block.gradeIdDefault, block.subjectIdDefault);
  };

  const onChangeGrade = (gradeId) => {
    const currentGrade = grade.listGrade.find(({ id }) => id === gradeId);

    updateFilter({ gradeId: gradeId, currentPage: 1 });
    setGrade({
      ...grade,
      id: [currentGrade?.id],
      currentGrade: currentGrade?.name,
      hasSubjectIds: currentGrade.has_subject_ids,
    });
    setEventGTM({
      event: CHOOSE_GRADE_LECTURE,
      grade_name: currentGrade?.name,
    });
  };

  const onChangeSubject = (subjectId) => {
    const currentSubject = subject.listSubject.find(
      ({ id }) => id === subjectId
    );

    updateFilter({ subjectId: subjectId, currentPage: 1 });
    setSubject({
      ...subject,
      id: [currentSubject?.id],
      currentSubject: currentSubject?.title,
      hasGradeIds: currentSubject.has_grade_ids,
    });
    setEventGTM({
      event: CHOOSE_SUBJECT_LECTURE,
      subject_name: currentSubject?.title,
    });
  };

  const updateFilter = (data) => {
    const _data = { ...list, ...data };
    dispatch(onUpdateCourseFilter(_data));
  };

  const handleSortAsc = (data, sortArray, key) => {
    return data.sort((a, b) => {
      if (!sortArray) return [];

      return (
        sortArray.findIndex((id) => id === a[key]) -
        sortArray.findIndex((id) => id === b[key])
      );
    });
  };

  useEffect(() => {
    onChangeBlock(list.block, list.gradeId, list.subjectId);
  }, []);

  useEffect(() => {
    getWorkSheet();
  }, [grade, subject, currentPage]);

  const schoolFilter = (
    <SchoolLevel
      setValueSchool={handleChangeValueSchool}
      valueSchool={valueSchool}
    />
  );

  const handleDisableFilter = (items, itemHasId) => {
    if (!items || !itemHasId) return [];

    const array = items.filter((item) => !itemHasId.includes(item.id));

    return array.map((item) => item.id);
  };

  const gradeFilter = (
    <div className="filter__item">
      <h4>Lớp học</h4>
      <ComboBox
        data={handleSortAsc(grade.listGrade, subject.hasGradeIds, "id")}
        onChange={onChangeGrade}
        checkedItems={grade.id}
        disabledItems={handleDisableFilter(
          grade.listGrade,
          subject.hasGradeIds
        )}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  const subjectFilter = (
    <div className="filter__item">
      <h4>Môn học</h4>
      <ComboBox
        data={handleSortAsc(subject.listSubject, grade.hasSubjectIds, "id")}
        onChange={onChangeSubject}
        checkedItems={subject.id}
        disabledItems={handleDisableFilter(
          subject.listSubject,
          grade.hasSubjectIds
        )}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  return (
    <main>
      <SEO data={pages.course} />
      <div className="hoc10-breadcrumb bg-gr position-relative">
        <Banner>
          <img
            className="banner-desktop"
            src="/assets/img/banner_gift-20-11.png"
            alt="banner-gift-20-11"
          />
          <img
            className="banner-tablet"
            src="/assets/img/banner_gift-20-11-tablet.png"
            alt="banner-gift-20-11"
          />
          <img
            className="banner-mobile"
            src="/assets/img/banner_gift-20-11-mobile.png"
            alt="banner-gift-20-11"
          />
        </Banner>
        <HistoryRouter className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""} className="monkey-fz-18">
                Trang chủ
              </Link>
            </li>
            <li
              className="breadcrumb-item active monkey-fz-18"
              aria-current="page"
            >
              Học liệu
            </li>
          </ul>
        </HistoryRouter>
      </div>
      <BookCase className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title">Học liệu</h1>
          <p className="txt-5 mb-2">Kho học liệu điện tử sách Cánh Diều</p>
        </div>
      </BookCase>

      <div className="hoc10-lesson-library">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="flex-lc justify-content-between col-lg-9 mb-3">
              <div className="monkey-fz-28 col-md-8 font-weight-bold text-dark px-0">
                <BookMark
                  className="fa fa-bookmark pr-2"
                  aria-hidden="true"
                ></BookMark>
                Video minh họa nội dung sách
              </div>

              <form
                action=""
                method=""
                className="search-form pr m-0 col-md-4 px-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group flex pr">
                  <input
                    id="search"
                    type="text"
                    name="title"
                    placeholder="Nhập tên học liệu cần tìm"
                    required=""
                    className="w-100"
                    onChange={({ target }) => setTitle(target.value)}
                    onKeyDown={(e) => e.keyCode === 13 && getWorkSheet()}
                    ref={register({ required: false })}
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="filter">
                {schoolFilter}
                {gradeFilter}
                {subjectFilter}
              </div>
            </div>
            <div className="col-lg-9 col-md-8 result">
              <p className="text-uppercase monkey-color-orange monkey-fz-28 font-weight-bold pb-3">
                {subject.currentSubject} {grade.currentGrade}
              </p>
              <div className="row">
                {!loading ? (
                  courses.map((item, index) =>
                    renderDocumentByType(
                      item.type_document,
                      item,
                      index,
                      "col-6 col-lg-4",
                      (url) => setUrl(url),
                      true
                    )
                  )
                ) : (
                  <div className="d-flex justify-content-center w-100">
                    {" "}
                    <Loading />{" "}
                  </div>
                )}
              </div>

              <VideoPlayerModal
                show={Boolean(url)}
                url={url}
                onClose={() => setUrl("")}
              />

              {total > 1 && (
                <ReactPaginate
                  previousLabel={""}
                  previousClassName={"icon icon-prev"}
                  nextLabel={""}
                  nextClassName={"icon icon-next"}
                  breakLabel={"..."}
                  pageCount={total}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={(data) => {
                    setCurrentPage(data.selected + 1);
                    updateFilter({ currentPage: data.selected + 1 });
                  }}
                  forcePage={list.currentPage - 1 ?? currentPage}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  previousLinkClassName="page-link page-link--prev"
                  nextLinkClassName="page-link page-link--next"
                  hrefAllControls
                  hrefBuilder={() => {
                    return "javascript:;";
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const Banner = styled.div`
  .banner-tablet,
  .banner-mobile {
    display: none;
  }
  z-index: 1;
  position: absolute;
  opacity: 0.7;
  img {
    height: 233px;
  }
  @media (max-width: 700px) {
    img {
      height: 200px;
    }
  }
  @media screen and (max-width: 992px) and (min-width: 576px) {
    .banner-tablet {
      display: block;
    }
    .banner-desktop,
    .banner-mobile {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .banner-mobile {
      display: block;
    }
    .banner-tablet,
    .banner-desktop {
      display: none;
    }
  }
`;

const HistoryRouter = styled.div`
  ul li {
    z-index: 1;
  }
`;

const BookCase = styled.div`
  div {
    z-index: 9;
  }
`;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
`;
