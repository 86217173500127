import { useEffect } from "react";
import Select from "react-select";
import { useAppStore } from "stores/appStore";
import { useBookStore } from "stores/bookStore";
import CheckboxList from "components/CheckboxList";
import {
  ROUTE_PATH_COURSE,
  ROUTE_PATH_TU_SACH,
  ROUTE_PATH_V3_LIST_SLIDE,
} from "consts/routePaths";
import { useGiaoanStore } from "stores/giaoanStore";
import { useHocLieuStore } from "stores/hoclieuStore";

export default function SubjectFilter({
  preSubjects = [],
  page = ROUTE_PATH_TU_SACH,
  onChange,
}) {
  const subjects = useAppStore((state) => state.subjects);
  const subjectId = useAppStore((state) => state.subjectId);
  const gradeId = useAppStore((state) => state.gradeId);
  const setSubjectId = useAppStore((state) => state.setSubjectId);
  const gradeSubjectsOfBook = useBookStore((state) => state.gradeSubjects);
  const gradeSubjectsOfGiaoAn = useGiaoanStore((state) => state.gradeSubjects);
  const gradeSubjectsOfHocLieu = useHocLieuStore((state) => state.gradeSubjects);
  const collection = {
    [ROUTE_PATH_TU_SACH]: gradeSubjectsOfBook,
    [ROUTE_PATH_V3_LIST_SLIDE]: gradeSubjectsOfGiaoAn,
    [ROUTE_PATH_COURSE]: gradeSubjectsOfHocLieu
  };
  const gradeSubjects = collection[page];

  useEffect(()=> {
    if(gradeId == 26){ // 26 la id cua onthiThpt (case order tu Vepic)
       setSubjectId('999');
    }
  },[gradeId])

  const handleChange = (value) => {
    setSubjectId(value);
    if (onChange) {
      onChange(value);
    }
  };

  const currentGrade = gradeSubjects?.grades?.find(
    (grade) => grade.grade_id === gradeId
  );

  const filteredSubjects = subjects.filter((subject) =>
    currentGrade?.has_subject_ids?.includes(subject.id)
  );

  const items = filteredSubjects.map((subject) => ({
    ...subject,
    value: subject.id,
    label: subject.title,
    checked: subject.id === subjectId,
    tag: null,
  }));

  const preItems = preSubjects.map((subject) => ({
    ...subject,
    value: subject.id,
    label: subject.title,
    checked: subject.id === subjectId,
    tag: null,
  }));

  const currentSubject = gradeId == 26 ? {  // vi yeu cau tu Vepic chi hien thi gioi thieu chung khi chon onthiThpt nen fix cung 
    "id": "999",
    "title": "Giới thiệu chung",
    "value": "999",
    "label": "Giới thiệu chung",
    "checked": true,
    "tag": null
} : [...preItems, ...items].find(
    (subject) => subject.id === subjectId
  );

  return (
    <>
      <CheckboxList
        title={gradeId == 26 ? "" : "Môn học"}
        onChange={handleChange}
        items={items}
        preItems={preItems}
      />
      <Select
        className="d-block d-md-none mt-2 pt-0"
        value={currentSubject}
        options={items}
        placeholder="Chọn môn học"
        onChange={({ id }) => handleChange(id)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
      />
    </>
  );
}
