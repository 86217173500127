import React, { Component, Fragment } from "react";

export class GradeContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return <div>GradeContainer</div>;
  }
}

export default GradeContainer;
