import _ from "lodash";
import * as PATH from "consts/routePaths";
import { matchPath } from "react-router-dom";

export const blacklistRoutes = [
  // doc sach
  `${PATH.ROUTE_PATH_READING_BOOKS}/:name/:bookType/:id/:pageId`,
  `${PATH.ROUTE_PATH_READING_BOOKS}/:name/:bookType/:id`,
  // lam bai luyen tap
  `${PATH.ROUTE_PATH_EXERCISE}/:id`,
  `${PATH.ROUTE_PATH_PRACTIVE}/:id`,
  // lam bai luyen thi
  `${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST}:id`,
  // lam bai kiem tra tap huan
  `${PATH.ROUTE_PATH_LIST_QUIZ_DETAIL}/:gradeId/:subjectId/:quizId`,
];

export const isCurrentRouteInBlackList = (currentPath) =>
  !_.isEmpty(
    blacklistRoutes.filter((path) =>
      matchPath(currentPath, {
        path,
        exact: true,
        strict: false,
      })
    )
  );
