import * as TYPES from "edu_lms/constants/actionsType";

let initialState = {
  schoolYearSelect: {
    id: 3,
    value: "2021-2022",
    label: "Năm học 2021-2022",
  },
  searchKeyClassroom: "",
  currentPageClassroom: 1,
};

let teachingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PA_SET_CURRENT_PAGE_CLASSROOM:
      return { ...state, currentPageClassroom: action.data };
    case TYPES.PA_SET_SELECT_OPTION_SCHOOL_YEAR:
      return { ...state, schoolYearSelect: action.data };
    case TYPES.PA_SET_SEARCHKEY_CLASSROOM:
      return { ...state, searchKeyClassroom: action.data };
    default:
      return state;
  }
};

export default teachingReducer;
