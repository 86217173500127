import { Modal } from "react-bootstrap";
import error_img from "edu_lms_v2/assets/img/img-error.svg";

export default function PopupActiveInstruction({ show, setShow, bookName }) {
  return (
    <Modal show={show} onHide={setShow} centered>
      <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
      <Modal.Body className="pt-0">
        <img src={error_img} alt="#" className="w-100" />
        <div className="mb-4 pt-5">
          <p className="text-center monkey-fz-18 font-weight-bold">
            Vui lòng kích hoạt sách{" "}
            <span className="monkey-f-header">{bookName}</span> <br /> để
            xem được tài liệu.
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
