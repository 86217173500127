import { TYPE_VIETNAMESE } from "edu_lms/constants/type";
import { getContentAudioVideoImageTextFromIconActData, getIconsListByLanguage } from ".";
import { TYPE_DATA } from "../constants";
import {
  LABEL_ANSWER_NUMBER,
  LABEL_ANSWER,
  LABEL_ANSWER_VIETNAMESE,
  ORDER_ANSWER_INDEX,
  ORDER_ANSWER_NO_INDEX,
} from "../constants/MTC_003";

// For MTC_003, CKB_001
const formatActivityDataMTC_003 = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_index: typeOrderAnswer,
    type_answer: typeContentAnswer,
    type_text: typeText,
    font_size: fontSize,
    font_size_answer: fontSizeAnswer,
  } = gameConfig;

  const questionAnswerSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      dataGameConfigItem.question
    );
    const question = {
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          typeText,
          fontSize,
          contentText: questionContentIcon.contentText,
        },
      },
    };

    const answerList = dataGameConfigItem.answers.map((answerItem) => {
      const answerContentIcon = getContentAudioVideoImageTextFromIconActData(
        iconsList,
        answerItem.answer_id
      );
      return {
        answerId: answerItem.answer_id,
        isCorrect: answerItem.is_correct,
        contentAnswer: {
          srcAudio: answerContentIcon.srcAudio,
          srcVideo: answerContentIcon.srcVideo,
          srcImage: answerContentIcon.srcImage,
          text: {
            typeText,
            fontSize: fontSizeAnswer,
            contentText: answerContentIcon.contentText,
          },
        },
      };
    });

    const answer = {
      typeOrderAnswer,
      typeContentAnswer: typeContentAnswer || [TYPE_DATA.TEXT],
      numberOfAnswersInRow: Number(dataGameConfigItem.answer_number_in_a_row),
      answers: answerList,
    };

    return { question, answer };
  });

  return questionAnswerSetList;
};

const renderLabelIndex = (typeIndex, index, languageBook) => {
  const typeIndexFocus = typeIndex ? typeIndex[0] : null;
  switch (typeIndexFocus) {
    case "number":
      return LABEL_ANSWER_NUMBER[index];
    case "alphabet":
      if (languageBook === TYPE_VIETNAMESE) {
        return LABEL_ANSWER_VIETNAMESE[index];
      }
      return LABEL_ANSWER[index];
    default:
      return LABEL_ANSWER[index];
  }
};

const checkResultAnswer = (answers = [], selectedAnswer = "") => {
  let result = false;
  answers.forEach((answer) => {
    if (answer.answerId === selectedAnswer && answer.isCorrect) {
      result = true;
    }
  });
  return result;
};

const getStylesOrderAnswerIndex = ({
  isCheckedAnswer,
  hideResultAnswer,
  showCorrectAnswer,
  selectedAnswers,
  answer,
}) => {
  if (showCorrectAnswer) {
    if (answer.isCorrect) return ORDER_ANSWER_INDEX.Correct;
    return ORDER_ANSWER_INDEX.Default;
  }

  // Before check answer result
  if (!isCheckedAnswer) {
    if (selectedAnswers.includes(answer.answerId)) {
      return ORDER_ANSWER_INDEX.Selected;
    }
    return ORDER_ANSWER_INDEX.Default;
  }

  // After check answer result
  if (selectedAnswers.includes(answer.answerId)) {
    if (hideResultAnswer) {
      return ORDER_ANSWER_INDEX.Selected;
    }
    if (answer.isCorrect) {
      return ORDER_ANSWER_INDEX.Correct;
    }
    return ORDER_ANSWER_INDEX.Wrong;
  }

  return ORDER_ANSWER_INDEX.Default;
};

const getStylesOrderAnswerNoIndex = ({
  isCheckedAnswer,
  hideResultAnswer,
  showCorrectAnswer,
  selectedAnswers,
  answer,
}) => {
  if (showCorrectAnswer) {
    if (answer.isCorrect) return ORDER_ANSWER_NO_INDEX.Correct;
    return ORDER_ANSWER_NO_INDEX.Default;
  }

  // Before check answer result
  if (!isCheckedAnswer) {
    if (selectedAnswers.includes(answer.answerId)) {
      return ORDER_ANSWER_NO_INDEX.Selected;
    }
    return ORDER_ANSWER_NO_INDEX.Default;
  }

  // After check answer result
  if (selectedAnswers.includes(answer.answerId)) {
    if (hideResultAnswer) {
      return ORDER_ANSWER_NO_INDEX.Selected;
    }
    if (answer.isCorrect) {
      return ORDER_ANSWER_NO_INDEX.Correct;
    }
    return ORDER_ANSWER_NO_INDEX.Wrong;
  }

  return ORDER_ANSWER_NO_INDEX.Default;
};

export {
  formatActivityDataMTC_003,
  renderLabelIndex,
  checkResultAnswer,
  getStylesOrderAnswerIndex,
  getStylesOrderAnswerNoIndex,
};
