import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import _ from "lodash";
import renderHTML from "react-render-html";
import styles from "../BlogStyle.module.scss";
import Share from "./Share";
import NewPostDetail from "./NewPostDetail";
import RelatedPosts from "./RelatedPosts";
import { timeUpdate } from "../selection";

export default function TitleDetail({
  detailItem,
  params,
  setIsShowLinkShare,
  isShowLinkShare,
}) {
  const refTitleImg = useRef([]);
  const [idTableContent, setIdTableContent] = useState([]);
  const [isShowReferences, setIsShowReferences] = useState(false);
  const srcImg = detailItem?.blog?.images;
  const author = detailItem?.author?.image[0];
  const dataBlog = detailItem?.blog;
  const dataAuthor = detailItem?.author;

  const handleChangeScroll = () => {
    const PADDING_HEADER = 80;
    const distanceAbove = document.documentElement?.scrollTop + PADDING_HEADER;
    const distanceView = document.getElementById("view_detail");
    const listItemTables = idTableContent.map((idTable) => {
      return { idTable, active: false };
    });

    let itemPaddingTop = listItemTables.map((item) => {
      const scrollElement =
        document.getElementById(item?.idTable)?.offsetTop +
        distanceView?.offsetTop;
      return { ...item, distance: distanceAbove - scrollElement };
    });

    if (!_.isEmpty(itemPaddingTop)) {
      let contentSuccess = itemPaddingTop[0].distance;
      for (let i = 0; i < itemPaddingTop.length; i++) {
        if (
          itemPaddingTop[i].distance > 0 &&
          itemPaddingTop[i].distance < contentSuccess
        ) {
          contentSuccess = itemPaddingTop[i].distance;
          itemPaddingTop = [...itemPaddingTop, itemPaddingTop[i].distance];
        }
      }
      itemPaddingTop.forEach((item, index) => {
        const indexAccept = document.getElementById(index);
        if (item.distance === contentSuccess) {
          indexAccept?.classList?.add("success");
        } else {
          indexAccept?.classList?.remove("success");
        }

        return item;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleChangeScroll);
    return () => {
      window.removeEventListener("scroll", handleChangeScroll);
    };
  }, [idTableContent]);

  useEffect(() => {
    const content = document
      .getElementById("blog-hoc10-detail")
      .querySelectorAll("h2");
    let idContent = [];
    for (let i = 0; i < content.length; i++) {
      idContent.push(content[i].innerText);
      content[i].setAttribute("id", content[i].innerText);
    }
    setIdTableContent(idContent);
  }, [detailItem]);

  useEffect(() => {
    const listImgBlog = document
      .getElementById("blog-hoc10-detail")
      .querySelectorAll("img");
    if (!_.isEmpty(listImgBlog)) {
      for (let i = 0; i < listImgBlog.length; i++) {
        if (
          listImgBlog[i].alt &&
          !_.includes(refTitleImg.current, `${listImgBlog[i].alt}${i}`)
        ) {
          refTitleImg.current = [
            ...refTitleImg.current,
            `${listImgBlog[i].alt}${i}`,
          ];
          const createLabel = document.createElement("label");
          createLabel.innerText = listImgBlog[i].alt;
          const labelImg =
            listImgBlog[i].parentElement.appendChild(createLabel);
          labelImg?.classList?.add(`label_img`);
        }
      }
    }
  }, [params, detailItem]);

  return (
    <Div
      isShowReferences={isShowReferences}
      className={`mb-5 ${styles.titleViewDetail}`}
    >
      <div className="row">
        <div className={`col-lg-5 col-md-5 col-sm-6 col-12 ${styles.banner}`}>
          <div className={styles.boxImg}>
            <img
              src={srcImg[0]?.path}
              alt={srcImg[0]?.title || "img-view-detail"}
            />
          </div>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-6 col-12">
          <div className={styles.detailAuthor}>
            <h1 className={`${styles.content} content-detail`}>
              {dataBlog?.title}
            </h1>
            <div className={styles.timeCreate}>
              <span className="d-flex">
                <img
                  className={styles.newPostIcon}
                  src="/assets/img/icon-color.svg"
                  alt="icon-color"
                />
                {timeUpdate(dataBlog?.publish_date)}
                <span className="d-flex">
                  <img
                    className={styles.newPostIcon}
                    src="/assets/img/icon-viewers.svg"
                    alt="icon-viewers"
                  />
                  {dataBlog?.viewcount || ""}
                </span>
              </span>
            </div>
            <div className={`${styles.inforAuthor} container-infor`}>
              <Author>
                <img src={author?.path} alt={author?.title} />
              </Author>
              <span className={styles.authorName}>
                <strong>{dataAuthor?.name || ""}</strong>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.detailAuthor}>
          <hr />
          <div className="row mce-item-table" id="view_detail">
            <div className={`col-lg-3 ${styles.tableContainer}`}>
              <span className={`d-flex ${styles.folderHeader}`}>
                <img src="/assets/img/icon-folder.svg" alt="icon-folder" />
                Mục lục bài viết
              </span>
              <hr />
              {tableOfContent(idTableContent)}
            </div>
            <div
              className={`col-12 col-md-7 col-sm-7 col-lg-6 ${styles.contentDetail}`}
            >
              <div className={styles.contentItem}>
                <div className={styles.contentItemViewDetail}>
                  <div
                    className={styles.contentItemViewDetail}
                    id="blog-hoc10-detail"
                  >
                    <div>{renderHTML(detailItem?.blog?.description || "")}</div>
                    <div>{renderHTML(detailItem?.blog?.content || "")}</div>
                  </div>
                </div>
              </div>
              <hr />
              <div
                className={
                  !isShowReferences && "d-flex justify-content-between"
                }
              >
                <div className={styles.containerReferences}>
                  {detailItem?.blog?.references && (
                    <div className={styles.contentItem}>
                      <p className={styles.description}>
                        <span
                          onClick={() => setIsShowReferences(!isShowReferences)}
                          className="cursor"
                        >
                          <i
                            class={`fa ${
                              isShowReferences ? "fa-minus" : "fa-plus"
                            }`}
                            aria-hidden="true"
                          />{" "}
                          Tài liệu tham khảo
                        </span>
                      </p>
                      {isShowReferences && (
                        <div className={styles.documentRefer}>
                          <div>
                            {renderHTML(detailItem?.blog?.references || "")}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <Share
                    setIsShowLinkShare={setIsShowLinkShare}
                    isShowLinkShare={isShowLinkShare}
                    detailItem={detailItem}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-12 col-md-5 col-sm-5 col-lg-3 pl-5 ${styles.postNews}`}
            >
              <RelatedPosts detailItem={detailItem} />
            </div>
          </div>
          <hr />
          <NewPostDetail dataNewsPost={detailItem?.news_post} />
        </div>
      </div>
    </Div>
  );
}

const tableOfContent = (idTableContent = []) => {
  const handleScroll = (index) => {
    const distanceView = document.getElementById("view_detail");
    let itemPaddingTop = idTableContent.map((item) => {
      const scrollElement =
        document.getElementById(item)?.offsetTop + distanceView?.offsetTop;
      return scrollElement;
    });

    window.scrollTo(0, itemPaddingTop[index] - 72);
  };
  return idTableContent.map((item, index) => (
    <div
      key={index}
      id={index}
      className={`${styles.folderContent}`}
      onClick={() => handleScroll(index)}
    >
      <span className={styles.folder}>{index + 1}</span>
      <p>{item}</p>
    </div>
  ));
};

const Div = styled.div`
  .mce-item-table:not([border]) td {
    border: 1px dashed #bbb;
    ul {
      padding-left: 1rem;
    }
  }
  #blog-hoc10-detail {
    a {
      text-decoration: underline;
      color: rgb(17, 85, 204);
    }
    iframe {
      max-width: 100%;
    }
    blockquote {
      position: relative;
      background-color: #f7f7f7;
      padding: 15px 15px 15px 50px;
      &::before {
        content: url("/assets/img/quote1.svg");
        position: absolute;
        left: 0;
        scale: 0.7;
        top: 0;
      }
      a {
        font-family: system-ui;
        font-weight: bold;
      }
    }
  }
  .label_img {
    text-align: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
  }
  .success {
    span {
      background-color: #ff7707;
      color: #fff;
    }
    p {
      color: #2a404f !important;
    }
  }
  .content-detail {
    margin-bottom: 15px;
  }
  .share-block {
    padding: ${(props) =>
      !props.isShowReferences ? "0 !important" : "20px 0 0px 0"};
  }
  @media (max-width: 992px) {
    .content-detail {
      margin-bottom: 15px;
    }
    .container-infor {
      margin-top: 15px;
    }
    .success {
      p {
        opacity: 1;
      }
    }
  }
`;

const Author = styled.div`
  margin: auto 0;
`;
