import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { getActivitiesTestGame } from "edu_lms_v2/services/testGame";
import { onResultUserInfo } from "edu_lms/modules/selection";
import * as PATH from "consts/routePaths";
import FilterGame from "./FilterGame";
import ViewGame from "./ViewGame";
import { getGameNameByGameId } from "./utils";
import { VIEW_MODES } from "./constants";
import "./styles.scss";

const TestGame = () => {
  const history = useHistory();
  const [modeView, setModeView] = useState(VIEW_MODES[0].value);
  const [listDetailActivities, setListDetailActivities] = useState([]);

  const userInfo = onResultUserInfo();
  const isUserHoc10 = userInfo?.is_user_hoc10;
  if (!isUserHoc10) {
    history.push(PATH.ROUTE_PATH_NOT_FOUND);
  }

  const handleSearchActivities = async (filterGame) => {
    !isEmpty(listDetailActivities) && setListDetailActivities([]);
    const filterGameParams = {
      ...filterGame,
      gameId: filterGame.gameId.map((gameId) => gameId.value).join(","),
      area: Number(filterGame.area),
    };

    try {
      const response = await getActivitiesTestGame(filterGameParams);
      if (response.data.status === "success") {
        setListDetailActivities(response.data.data);
      } else {
        setListDetailActivities([]);
      }
    } catch (error) {
      toast.error(JSON.stringify(error));
      setListDetailActivities([]);
    }
  };

  const handleCopyNameToClipBoard = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };

  return (
    <div className="page-container">
      <div id="hoc10-test-game">
        <p className="title">Test game</p>
        {/* ------------------------------------------------------------------------------- */}
        <FilterGame
          modeView={modeView}
          onChangeModeView={(_modeView) => setModeView(_modeView)}
          onSearchActivities={handleSearchActivities}
        />
        {/* ------------------------------------------------------------------------------- */}
        <div className="view-games">
          {listDetailActivities.map((activityDetail, index) => (
            <div key={index} className={`game-wrapper ${modeView}`}>
              <div className="number-order">
                {`${index + 1}) ${getGameNameByGameId(activityDetail.game_id)}`}
                <span>{activityDetail.activity_name}</span>
                <i
                  className="fa fa-copy copy-icon"
                  aria-hidden="true"
                  onClick={() =>
                    handleCopyNameToClipBoard(activityDetail.activity_name)
                  }
                />
              </div>
              <ViewGame activity={activityDetail} />
            </div>
          ))}
        </div>
        {/* ------------------------------------------------------------------------------- */}
      </div>
    </div>
  );
};

export default TestGame;
