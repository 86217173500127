import { useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import { getListSubject } from "edu_lms/services/app/app";
import { GRADES } from "edu_lms/constants/type";

export default function TeachingInfoField({
  canRemove,
  index,
  control,
  remove,
  grades,
  setValue,
  errors,
}) {
  const [subjects, setSubjects] = useState([]);

  const handleChangeGrade = (id) => {
    let gradeId = GRADES.cap3;
    if (
      [
        GRADES.lop1,
        GRADES.lop2,
        GRADES.lop3,
        GRADES.lop4,
        GRADES.lop5,
      ].includes(+id)
    ) {
      gradeId = GRADES.cap1;
    } else if (
      [GRADES.lop6, GRADES.lop7, GRADES.lop8, GRADES.lop9].includes(+id)
    ) {
      gradeId = GRADES.cap2;
    }
    setSubjects([]);
    getListSubject(gradeId, '80,81,82,83')
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.list_subject.length > 0) {
            setSubjects(
              res.data?.data?.list_subject.map((item) => ({
                value: item.id,
                label: item.title,
              }))
            );
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const NoOptionsMessage = () => {
    return (
      <div className="text-center pt-2 pb-2 monkey-fz-16">
        Vui lòng chọn lớp học trước khi chọn môn học
      </div>
    );
  };

  return (
    <div>
      <div className="form-group">
        <label>
          Lớp học <sup>*</sup>
        </label>
        <div className="box-select box-select--class pr">
          <Controller
            render={({ field, onChange, value }) => (
              <Select
                {...field}
                placeholder="Vui lòng chọn lớp"
                options={grades}
                maxMenuHeight={200}
                value={value}
                onChange={(e) => {
                  setValue(`teaching.${index}.subjects`, []);
                  onChange(e);
                  handleChangeGrade(e.value);
                }}
                className={classNames({
                  "is-invalid": Boolean(
                    errors.teaching && errors.teaching[index]?.grade
                  ),
                })}
              />
            )}
            name={`teaching.${index}.grade`}
            control={control}
            defaultValue=""
          />
          <div className="invalid-feedback">
            {Boolean(errors.teaching && errors.teaching[index]?.grade) &&
              "Yêu cầu chọn lớp"}
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>
          Môn học <sup>*</sup>
        </label>
        <div className="box-select box-select--subject pr">
          <Controller
            render={({ field, onChange, value }) => (
              <Select
                {...field}
                placeholder="Vui lòng chọn môn học"
                components={{ NoOptionsMessage }}
                options={subjects}
                maxMenuHeight={200}
                onChange={onChange}
                value={value}
                isMulti
                className={classNames({
                  "is-invalid": Boolean(
                    errors.teaching && errors.teaching[index]?.subjects
                  ),
                })}
              />
            )}
            name={`teaching.${index}.subjects`}
            control={control}
            defaultValue={[]}
          />
          <div className="invalid-feedback">
            {Boolean(errors.teaching && errors.teaching[index]?.subjects) &&
              "Yêu cầu chọn môn học"}
          </div>
        </div>
      </div>
      {canRemove && (
        <>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => remove(index)}>
              <IStyle className="fa fa-trash-o" />
            </button>
          </div>
          <HrStyle />
        </>
      )}
    </div>
  );
}

const IStyle = styled.i`
  font-size: 20px;
  color: #ff7707;
  margin-bottom: 5px;
`;
const HrStyle = styled.hr`
  background-color: #ff7707;
`;
