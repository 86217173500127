import React from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { POPUP_IN_TRAINING, POPUP_BEFORE_TRAINING } from "edu_lms/constants/type";

const PopupCountDownTraining = () => {
  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const {content, popupId, timeEnd} = JSON.parse(trainingConfig);

  return (
    content ? <AlertStyle className="pl-2 p-2 rounded">
      {[POPUP_IN_TRAINING, POPUP_BEFORE_TRAINING].includes(popupId) && <Countdown
        className="text-center"
        date={new Date(timeEnd * 1000)}
        intervalDelay={1000}
        renderer={(props) => {
          return (
            <span>
              Thời gian {POPUP_BEFORE_TRAINING === popupId ? "đến kỳ kiểm tra sau tập huấn còn" : "còn lại"} &nbsp;
              <CountDownTime>{props.formatted.days}</CountDownTime> ngày&nbsp;
              <CountDownTime>{props.formatted.hours}</CountDownTime> giờ&nbsp;
              <CountDownTime>{props.formatted.minutes}</CountDownTime> phút
            </span>
          );
        }}
      />}
      <div dangerouslySetInnerHTML={{__html: content}}></div>
    </AlertStyle> : ''
  );
};

const CountDownTime = styled.span`
  color: var(--orange);
  font-weight: bold;
`;

const AlertStyle = styled.div`
  background-color: rgba(253, 119, 6, 0.3);
`;

export default PopupCountDownTraining;
