import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { toast } from "react-toastify";
import styles from "../../teachingManager.module.scss";
import { postCreateUserClassroom } from "edu_lms/services/teachingManager";
import { ROLE } from "../../constant";

const schema = yup.object().shape({
  parent_name: yup
    .string()
    .required("Vui lòng nhập tên phụ huynh!")
    .min(4, "Họ và tên phụ huynh quá ngắn"),
  full_name: yup.string().required("Vui lòng nhập tên học sinh!"),
  email: yup.string().required("Vui lòng nhập email phụ huynh!"),
  phone: yup.string().required("Vui lòng nhập số điện thoại!"),
});

export default function PopupAddStudentForEmail({
  show,
  setShow,
  student = {},
  role = ROLE.ADD,
  idClassroom,
  getListClassroom,
  getInfoClassroom,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
  });

  const onHandleSubmit = (dataForm) => {
    setErrorMessage("");
    let data = {
      class_room_id: idClassroom,
      parent_name: dataForm.parent_name,
      full_name: dataForm.full_name,
      phone: dataForm.phone,
      email: dataForm.email,
    };

    if (role === ROLE.UPDATE) {
      data = { ...data, student_id: student.id };
    }

    postCreateUserClassroom(data)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(
            role === ROLE.ADD
              ? "Thêm thành viên thành công!"
              : "Cập nhật thông tin thành công"
          );
          getListClassroom();
          getInfoClassroom && getInfoClassroom();
          onHandleClose();
        } else {
          toast.error(
            role === ROLE.ADD
              ? "Thêm thành viên thất bại!"
              : "Cập nhật thông tin thất bại"
          );
          setErrorMessage(res.data.message);
        }
      })
      .catch((error) => console.log(error));
  };

  const onHandleClose = () => {
    setErrorMessage("");
    setShow(false);
    reset();
  };

  return (
    <Modal
      show={show}
      onHide={onHandleClose}
      centered
      contentClassName={styles.modalDialog}
      backdrop="static"
    >
      <Modal.Header closeButton className="text-center">
        <p className="w-100 text-fsize font-weight-bold">
          {role === ROLE.ADD ? "Thêm học sinh" : "Cập nhật thông tin học sinh"}
        </p>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <Modal.Body className="p-3">
          <div>
            <span className="text-danger"> * </span>
            Nhập thông tin của học sinh.
          </div>
          <div className="form-group mt-2">
            <input
              className="form-control"
              type="text"
              name="parent_name"
              defaultValue={student?.parent_name}
              ref={register}
              placeholder="Họ và tên phụ huynh"
              onChange={() => setErrorMessage(errorMessage)}
            />
            {errors.parent_name && (
              <p className="text-danger">{errors.parent_name?.message}</p>
            )}
          </div>
          <div className="form-group mt-2">
            <input
              className="form-control"
              type="text"
              name="full_name"
              defaultValue={student?.name}
              ref={register}
              placeholder="Tên học sinh"
              onChange={() => setErrorMessage(errorMessage)}
            />
            {errors.full_name && (
              <p className="text-danger">{errors.full_name?.message}</p>
            )}
          </div>
          <div className="form-group ">
            <input
              className="form-control"
              type="email"
              name="email"
              defaultValue={student?.email}
              ref={register}
              placeholder="Email của phụ huynh"
            />
            {errors.email && (
              <p className="text-danger">{errors.email?.message}</p>
            )}
          </div>

          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="phone"
              ref={register}
              defaultValue={student?.phone}
              placeholder="Số điện thoại"
            />
            {errors.phone && (
              <p className="text-danger">{errors.phone?.message}</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center py-3">
          <button
            type="submit"
            // onClick={() => onHandleAddStudent()}
            className={`${styles.buttonOrange} px-5`}
          >
            {role === ROLE.ADD ? "Thêm" : "Cập nhật"}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const Form = styled.form`
  & input {
    height: 40px;
  }
`;
