import _ from "lodash";
import Intro from "./Intro";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { DOCUMENT_CATEGORY_GIOI_THIEU_TAP_HUAN_SGK } from "consts/book";

export default function SectionGioiThieuTapHuanSGK() {
    const documents = useBookStore((state) => state.documents);
    const subjectId = useAppStore((state) => state.subjectId);
    const gradeId = useAppStore((state) => state.gradeId);
    const documentsOfCurrentGrade = _.get(documents, [gradeId], []);
    
    const gtsDocuments = documentsOfCurrentGrade.filter(
        (item) =>
            item.category === DOCUMENT_CATEGORY_GIOI_THIEU_TAP_HUAN_SGK &&
            item.subject_id === subjectId
    );
    
    return (
        !_.isEmpty(gtsDocuments) && (
            <div className="col-md-12 result">
                <Intro
                    data={gtsDocuments}
                    categoryBook="Giới thiệu, tập huấn SGK"
                    col="col-6 col-lg-3"
                    background="#fff"
                />
            </div>
        )
    );
}
