import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import _ from "lodash";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import RecentBook from "./RecentBook";
import Loading from "edu_lms_v2/components/Loading";
import {
  SECTION_GIOI_THIEU_SACH,
  SECTION_HOC_LIEU_DIEN_TU,
  SECTION_GIOI_THIEU_CHUNG,
  SECTION_SBT,
  SECTION_SGK,
  SECTION_SGV,
  SECTION_TAI_LIEU_BO_TRO,
  SECTION_TAP_HUAN,
  SECTION_SACH_GIOI_THIEU,
	SECTION_TAI_LIEU_THAM_KHAO,
	SECTION_GIAO_AN,
	SECTION_TIET_DAY_MINH_HOA,
	SECTION_KE_HOACH_BAI_DAY,
	SECTION_BAI_GIANG_POWER_POINT,
	SECTION_GIOI_THIEU_TAP_HUAN_SGK,
  SECTION_SACH_ON_THI_THPT
} from "consts/book";
import TuSachHeader from "./TuSachHeader";
import GradeFilter from "components/GradeFilter";
import SubjectFilter from "components/SubjectFilter";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import SectionSGK from "./SectionSGK";
import SectionSBT from "./SectionSBT";
import SectionTaiLieuBoTro from "./SectionTaiLieuBoTro";
import SectionTaiLieuThamKhao from "./SectionTaiLieuThamKhao";
import SectionSGV from "./SectionSGV";
import SectionTaiLieuTapHuan from "./SectionTaiLieuTapHuan";
import SectionGioiThieuSach from "./SectionGioiThieuSach";
import SectionGioiThieuChung from "./SectionGioiThieuChung";
import SectionHocLieuDienTu from "./SectionHocLieuDienTu";
import SectionSGT from "./SectionSGT";
import { ROUTE_PATH_TU_SACH } from "consts/routePaths";
import { DEFAULT_SUBJECT_SLUG_BY_GRADE } from "consts/book";
import SectionGiaoAn from "./SectionGiaoAn";
import SectionGioiThieuTapHuanSGK from "./SectionGioiThieuTapHuanSGK";
import SectionBaiGiangPowerPoint from "./SectionBaiGiangPowerPoint";
import SectionKeHoachBaiDay from "./SectionKeHoachBaiDay";
import SectionTietDayMinhHoa from "./SectionTietDayMinhHoa";
import SectionSachOnthiTHPT from "./SectionSachOnthiTHPT";
import { useGiaoanStore } from "../../stores/giaoanStore";

export default function TuSach() {
  const params = useParams();
  const history = useHistory();
  const fetchBooks = useBookStore((state) => state.fetchBooks);
  const fetchingBooks = useBookStore((state) => state.fetchingBooks);
  const fetchDocuments = useBookStore((state) => state.fetchDocuments);
  const fetchGradeSubjects = useBookStore((state) => state.fetchGradeSubjects);
  const books = useBookStore((state) => state.books);
  const gradeSubjects = useBookStore((state) => state.gradeSubjects);
  const documents = useBookStore((state) => state.documents);
  const gradeId = useAppStore((state) => state.gradeId);
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const subjectId = useAppStore((state) => state.subjectId);
  const setSubjectId = useAppStore((state) => state.setSubjectId);

  const currentGrade = grades.find((grade) => grade.id === gradeId);
  const currentSubject = subjects.find((subject) => subject.id === subjectId);
  const booksOfCurrentSubject = _.get(books, [gradeId, subjectId], []);
  const fetchGrades = useAppStore((state) => state.fetchGrades);
  const fetchSubjects = useAppStore((state) => state.fetchSubjects);
	const fetchGiaoAn = useGiaoanStore((state) => state.fetchGiaoAn);
	const setLimit = useGiaoanStore((state) => state.setLimit);
	
	const sections = {
		[SECTION_GIOI_THIEU_CHUNG]: {
			component: SectionGioiThieuChung,
			enabled: subjectId === "999",
		},
		[SECTION_SGK]: {
			component: SectionSGK,
			enabled: true,
		},
		[SECTION_SACH_GIOI_THIEU]: {
			component: SectionSGT,
			enabled: true,
		},
		[SECTION_SBT]: {
			component: SectionSBT,
			enabled: true,
		},
		[SECTION_SGV]: {
			component: SectionSGV,
			enabled: true,
		},
		[SECTION_TIET_DAY_MINH_HOA]: {
			component: SectionTietDayMinhHoa,
			enabled: true,
		},
		[SECTION_KE_HOACH_BAI_DAY]: {
			component: SectionKeHoachBaiDay,
			enabled: true,
		},
		[SECTION_BAI_GIANG_POWER_POINT]: {
			component: SectionBaiGiangPowerPoint,
			enabled: true,
		},
		[SECTION_GIAO_AN]: {
			component: SectionGiaoAn,
			enabled: true,
		},
		[SECTION_HOC_LIEU_DIEN_TU]: {
			component: SectionHocLieuDienTu,
			enabled: true,
		},
		[SECTION_TAI_LIEU_BO_TRO]: {
			component: SectionTaiLieuBoTro,
			enabled: true,
		},
		[SECTION_TAI_LIEU_THAM_KHAO]: {
			component: SectionTaiLieuThamKhao,
			enabled: true,
		},
		[SECTION_GIOI_THIEU_SACH]: {
			component: SectionGioiThieuSach,
			enabled: true,
		},
		[SECTION_TAP_HUAN]: {
			component: SectionTaiLieuTapHuan,
			enabled: true,
		},
		[SECTION_GIOI_THIEU_TAP_HUAN_SGK]: {
			component: SectionGioiThieuTapHuanSGK,
			enabled: true,
		},
		[SECTION_SACH_ON_THI_THPT]: {
			component: SectionSachOnthiTHPT,
			enabled: true,
		},
		
	};
	// console.log(sections)
	useEffect (() => {
		// console.log(12123131)
	  if (!Object.keys (grades).length) {
		  fetchGrades ();
	  }
	  if(!Object.keys(subjects).length) {
		  fetchSubjects();
	  }
  }, []);
  
  useEffect(() => {
    fetchGradeSubjects();
  }, [currentGrade?.parent_id, fetchGradeSubjects]);

  useEffect(() => {
    fetchBooks();
  }, [gradeId, subjectId, fetchBooks]);

  useEffect(() => {
    fetchDocuments();
  }, [gradeId, fetchDocuments]);
	
  useEffect(() => {
	setLimit(3);
	fetchGiaoAn();
  }, [gradeId, fetchGiaoAn, subjectId]);
	
	useEffect(() => {
		if (currentSubject?.slug && currentGrade?.slug) {
			// history.replace(
			// 	`${ROUTE_PATH_TU_SACH}${currentSubject.slug}/${currentGrade.slug}/`
			// );
			window.history.replaceState(null, '', `${ROUTE_PATH_TU_SACH}${currentSubject.slug}/${currentGrade.slug}/`);
		}
		if(subjectId === '999') {
			// history.replace(
			// 	`${ROUTE_PATH_TU_SACH}gioi-thieu-chung/${currentGrade.slug}/`
			// );
			window.history.replaceState(null, '', `${ROUTE_PATH_TU_SACH}gioi-thieu-chung/${currentGrade.slug}/`);
			
		}
	}, [currentGrade?.slug, currentSubject?.slug]);
	
  useEffect(() => {
    const setDefaultSubject = () => {
      // Dang co mot table manage viec display grade, subject nen phai lay o 2 nguon moi du data
      // TODO: toi uu data management cho viec hien thi grade, subject tren caca page
      const _currentGrade = gradeSubjects?.grades?.find(
        (grade) => grade.grade_id === gradeId
      );
      if (_currentGrade) {
        const currentGrade = grades.find((grade) => grade.id === gradeId);
        // 1. Check subject value from store
        if (subjectId) {
          const currentSubjectFromStore = subjects.find(
            (subject) => subject.id === subjectId
          );
          if (
            currentSubjectFromStore &&
            _currentGrade?.has_subject_ids?.includes(currentSubjectFromStore.id)
          ) {
            return;
          }
        }
        // 2. Check subject value from url params
        const urlParamSubject = params?.subject;
        if (urlParamSubject) {
			if(urlParamSubject === 'gioi-thieu-chung') {
				setSubjectId('999');
				return;
			} else {
				
				const currentSubjectFromUrlParams = subjects.find(
					(subject) =>
						subject.slug === urlParamSubject &&
						subject.grade_id === currentGrade?.parent_id
				);
				if (
					currentSubjectFromUrlParams &&
					_currentGrade?.has_subject_ids?.includes(
						currentSubjectFromUrlParams.id
					)
				) {
					setSubjectId(currentSubjectFromUrlParams.id);
					return;
				}
			}
        
        }
        // 3. Check default subject value
        const defaultSubjectSlug =
          DEFAULT_SUBJECT_SLUG_BY_GRADE[currentGrade?.parent_id];
        if (defaultSubjectSlug) {
          const defaultSubject = subjects.find(
            (subject) =>
              subject.slug === defaultSubjectSlug &&
              subject.grade_id === currentGrade?.parent_id
          );
          if (
            defaultSubject &&
            _currentGrade?.has_subject_ids?.includes(defaultSubject.id)
          ) {
            setSubjectId(defaultSubject.id);
            return;
          }
        }
        const filteredSubjects = subjects.filter((subject) =>
          _currentGrade?.has_subject_ids?.includes(subject.id)
        );
        // 4. Check subject value from the first item of subject list
        const firstSubject = _.get(filteredSubjects, "1");
        if (firstSubject) {
          setSubjectId(firstSubject.id);
        }
      }
    };

    setDefaultSubject();
  }, [gradeId, gradeSubjects?.grades, grades, params?.subject]);

  useScrollToTop();

 

  return (
    <main id="bookcase">
      <SEO data={pages.list_book} />
      <RemoveScroll enabled={false} noIsolation>
        <TuSachHeader />
        <RecentBook />
        <div className="container filter filter-horizontal mt-3 mb-3">
          <GradeFilter direction="horizontal" />
        </div>
        <div className="hoc10-search-result">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <div className="filter">
                  <GradeFilter />
                  <SubjectFilter
                    page={ROUTE_PATH_TU_SACH}
                    preSubjects={[
                      {
                        id: "999",
                        title: "Giới thiệu chung",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <div>
                  <Title className="result">
                    {currentSubject?.title} {currentGrade?.name}
                  </Title>
                  {_.isEmpty(booksOfCurrentSubject) && _.isEmpty(documents) && (
                    <TitleNoBook className="result">ĐANG CẬP NHẬT</TitleNoBook>
                  )}
                  {!fetchingBooks ? (
                    <div className="row">
                      {Object.keys(sections).map((sectionName) => {
                        if (
                          sections[sectionName] &&
                          sections[sectionName].enabled
                        ) {
                          const SectionComponent =
                            sections[sectionName].component;
                          return <SectionComponent key={sectionName} />;
                        }

                        return null;
                      })}
                    </div>
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </RemoveScroll>
    </main>
  );
}

const Title = styled.p`
  text-transform: uppercase;
  font-size: 30px;
  padding: 0 0 10px;
  color: #ff7707;
`;

const TitleNoBook = styled.h1`
  color: #ff7707;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  display: block;
  margin-top: 100px;
`;
