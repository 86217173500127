export default function RecentExamItem({
  urlImage,
  subjectName,
  gradeName,
  typeExam,
  countCorrect,
  totalQuestion,
}) {
  return (
    <div className="col-lg-4 col-md-4">
      <article className="practice bg-sh">
        <div className="practice__img flex-center">
          <img src={urlImage} alt="" />
        </div>
        <div className="practice__content">
          <h3>
            {subjectName} - {gradeName}
          </h3>
          <span className="ty">{typeExam}</span>
          <p>
            <span>{countCorrect}</span>/{totalQuestion} câu đúng
          </p>
          <a href="" title="Tiếp tục" className="btn-pr flex-center">
            Làm lại
          </a>
        </div>
      </article>
    </div>
  );
}
