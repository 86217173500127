import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { trackClickLinkEmail } from "edu_lms/services/training";

const convertBase64ToObject = (str) =>
  JSON.parse(decodeURIComponent(window.atob(str)));

const ABSOLUTE_URL_REGEX = /^[a-zA-Z][a-zA-Z\d+\-.]*?:/;
const WINDOWS_PATH_REGEX = /^[a-zA-Z]:\\/;

const isAbsoluteUrl = (url) => {
  if (typeof url !== "string") {
    throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
  }

  if (WINDOWS_PATH_REGEX.test(url)) {
    return false;
  }

  return ABSOLUTE_URL_REGEX.test(url);
};

const TrackingLinkRedirect = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) {
      const {
        email,
        redirect_link: redirectLink,
        type,
      } = convertBase64ToObject(token);
      trackClickLinkEmail({ email, type }).finally(() => {
        if (isAbsoluteUrl(redirectLink)) {
          window.location.replace(redirectLink);
        } else {
          history.push(redirectLink);
        }
      });
    }
  }, [search, history]);

  return <div />;
};

export default TrackingLinkRedirect;
