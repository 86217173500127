import React, { Fragment } from "react";
import Select from "react-select";
import {
  CHOOSE_GRADE_LECTURE,
  CHOOSE_SUBJECT_LECTURE,
} from "../../constants/eventNameGTM";
import { setEventGTM } from "../../constants/googleTagManager";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 6,
    zIndex: 1,
    backgroundColor: "#fff",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1,
  }),
};
export const SelectComponent = (props) => {
  const valueOption = props.listGrade.filter(
    (item) => item.id === props.gradeId
  )[0];
  const optionsDefault = props.listGrade;
  const handleChange = (selectedOption) => {
    props.onGetDataBook(selectedOption.id, props.typeBook);
    selectedOption.type &&
      setEventGTM(CHOOSE_SUBJECT_LECTURE, [
        "subject_name",
        selectedOption.label,
      ]);
    selectedOption.name &&
      setEventGTM(CHOOSE_GRADE_LECTURE, ["grade_name", selectedOption.name]);
  };

  return (
    <div className="dropdown_grade">
      <Select
        value={valueOption}
        placeholder={`${props.placeholder ?? "Tất cả môn"}`}
        onChange={handleChange}
        options={optionsDefault}
        styles={customStyles}
        isSearchable={false}
      />
    </div>
  );
};

export default SelectComponent;
