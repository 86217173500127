import styled from "styled-components";

export default function ShowBook({ data, openDetailExercises }) {
  return (
    data.length > 0 && (
      <div
        className="result__list"
        style={{
          backgroundColor: "#FFFBF7",
          boxShadow: "5px 5px 10px 1px #ccc",
          padding: "10px 20px",
          marginBottom: "20px",
          borderRadius: "8px",
        }}
      >
        <h4>
          <i
            className="fa fa-bookmark mr-3 monkey-color-orange"
            aria-hidden="true"
          ></i>
          Sách giáo khoa
        </h4>
        <Hr />
        <div className="row">
          {data.map((item, index) => {
            return (
              <div key={index} className="col-lg-3 col-6">
                <article
                  className="post bg-sh pr"
                  style={{ boxShadow: "5px 5px 10px 1px #ccc" }}
                >
                  <div className="post__thumb cursor">
                    <img
                      src={`${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${item.thumb}`}
                      alt="Các loại sách"
                      onClick={() => openDetailExercises(item.id)}
                    />
                  </div>

                  <div
                    className="post__content"
                    style={{ padding: "16px 10px" }}
                  >
                    {item.semester ? (
                      <h4 className="pt-2">{`Tập ${item.semester}`}</h4>
                    ) : (
                      <h4 className="pt-2">
                        <br />
                      </h4>
                    )}
                    <div
                      onClick={() => openDetailExercises(item.id)}
                      className="btn-read btn-sub flex-center cursor"
                    >
                      Luyện tập
                    </div>
                  </div>
                </article>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;
