import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cleave from "cleave.js/react";
import ListActiveBook from "./ListActiveBook";
import { postActiveBook } from "edu_lms/services/activeBook/activeBook";
import { getAllListBook } from "edu_lms/services/general/general";
import { onResultUserInfo } from "edu_lms/modules/selection";

const BOOK_ACTIVE_ERROR_STATUS = 301;

export default function ModalActiveBook(props) {
  const { handleSubmit } = useForm();
  const [mess, setMess] = useState("");
  const [showSelectListBook, setShowSelectListBook] = useState(false);
  const [valueLicence, setStateValueLicence] = useState("");
  const [dataBookList, setDataBookList] = useState([]);
  const [messErrorListBook, setMessErrorListBook] = useState("");
  const userInfo = onResultUserInfo();

  useEffect(() => {
    if (!props.show) {
      resetModalActiveBook();
    }
  }, [props.show])

  const handleActiveBook = (data, hasBookId = false) => {
    postActiveBook(data).then((response) => {
      if (response.data.status === "success") {
        props.onActiveBookSuccess(response.data.data);
      } else {
        !hasBookId && setMess(response.data.message);
        hasBookId && setMessErrorListBook(response.data.message);

        if (response.data.code === BOOK_ACTIVE_ERROR_STATUS) {
          setShowSelectListBook(true);
          handleGetListBook(response.data.data);
          return;
        }
      }
    });
  };

  const handleGetListBook = (bookInfo) => {
    const params = {
      gradeId: bookInfo.grade_id,
      bookType: bookInfo.book_type
    }
    getAllListBook(params)
      .then((data) => {
        if (data.data.status === "success") {
          const listBook = data.data.data.list_book.map(book => {
            const value = book.id;
            const label = book.title;
            return { ...book, value, label };
          });
          setDataBookList(listBook);
        }
      })
      .catch((errors) => {
        toast.error(errors)
      });
  };

  const handleActiveBookWithLicence = () => {
    if (valueLicence) {
      const data = {
        book_id: 0,
        licence: valueLicence,
      };
      handleActiveBook(data);
    } else {
      setMess("Vui lòng nhập mã kích hoạt sách");
    }
  };

  const handleActiveBookWithBookIdAndLicence = (data) => {
    data = {
      book_id: data.id,
      licence: valueLicence,
    };
    handleActiveBook(data, true);
  };

  const onStateValueLicence = (value) => {
    setStateValueLicence(value);
    if (value === "") {
      setMess("Vui lòng nhập mã kích hoạt sách");
    } else {
      setMess("");
    }
  };

  const handleClosePopup = () => {
    resetModalActiveBook();
    props.onHide();
  }

  const resetModalActiveBook = () => {
    setMess("");
    setMessErrorListBook("");
    setStateValueLicence("");
    setShowSelectListBook(false);
  }

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal fade modal-success modal-active modal-active-book"
        animation="true"
      >
        <div className="box-success">
          <div className="txt-2 border-bottom text-center pb-4">
            {!showSelectListBook
              ? "Vui lòng nhập mã sách để kích hoạt"
              : "Vui lòng chọn sách bạn muốn kích hoạt"}
          </div>
          <span>
            <button
              type="button"
              className="close close-modal-active"
              aria-label="Close"
              onClick={handleClosePopup}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </span>
          {!(userInfo?.test_book_3_7_10 && !userInfo?.is_user_hoc10) ? (
            <div>
              {showSelectListBook ? (
                <ListActiveBook
                  dataBookList={dataBookList}
                  onActiveBook={handleActiveBookWithBookIdAndLicence}
                  value={valueLicence}
                  messErrorListBook={messErrorListBook}
                />
              ) : (
                <>
                  <form
                    className="text-center mt-4"
                    onSubmit={handleSubmit(handleActiveBookWithLicence)}
                  >
                    <div className="img-wrapper text-center">
                      <Cleave
                        options={{
                          delimiters: ["-", "-"],
                          blocks: [4, 4, 4],
                          uppercase: true,
                        }}
                        type="text"
                        className="form-control w-75  mx-auto mb-3 input-active-book"
                        placeholder="Nhập mã sách tại đây ..."
                        name="licence"
                        value={valueLicence}
                        onChange={(e) => onStateValueLicence(e.target.value)}
                      />
                    </div>
                    <p className="text-danger mb-3 text-center px-5">
                      {mess && mess}
                    </p>
                    <button
                      title="Truy cập tủ sách"
                      className="btn-pr flex-center btn-access w-25"
                      type="submit"
                    >
                      Xác nhận
                    </button>
                  </form>
                </>
              )}
            </div>
          ) : (
            <div>
              <p className="text-center mt-5 monkey-fz-18 font-weight-bold">
                Tài khoản thẩm định không thể kích hoạt sách
              </p>
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="monkey-bg-orange text-white px-5 py-2 rounded m-auto"
                  onClick={props.onHide}
                >
                  Đóng
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
