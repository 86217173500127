import styled from "styled-components";
import classNames from "classnames";
import _ from "lodash"
import CheckboxItem from "./CheckboxItem";
import { DIRECTION_HORIZONTAL, DIRECTION_VERTICAL } from "consts/general";

export default function CheckboxList({
  title,
  items,
  onChange,
  quantityEnabled,
  direction = DIRECTION_VERTICAL,
  renderItem,
  preItems,
}) {
  const customRender = (item, index) =>
    renderItem ? (
      renderItem({ index, item, onChange })
    ) : (
      <CheckboxItem
        key={index}
        value={item.value}
        label={item.label}
        tag={item.tag}
        onChange={onChange}
        checked={item.checked}
        quantity={item.quantity}
        quantityEnabled={quantityEnabled}
      />
    );

  return (
    <div
      className={classNames("filter__item d-none d-md-block", {
        filter__checkboxlist_horizontal: direction === DIRECTION_HORIZONTAL,
      })}
    >
      {direction === DIRECTION_VERTICAL && !_.isEmpty(preItems) && (
        <PreItemList>{preItems.map(customRender)}</PreItemList>
      )}
      <h4
        className={classNames({
          filter__title_horizontal: direction === DIRECTION_HORIZONTAL,
        })}
      >
        {title}
      </h4>
      <div
        className={classNames({
          filter__item__list: direction === DIRECTION_VERTICAL,
          filter__item__list_horizontal: direction === DIRECTION_HORIZONTAL,
        })}
      >
        {items.map(customRender)}
      </div>
    </div>
  );
}

const PreItemList = styled.div`
  border-bottom: 1px solid #eaeced;
  padding-bottom: 8px;
  margin-bottom: 12px;
`;
