import TuSach from "modules/TuSach";
import Layout from "components/Layout/Default";

export default function PageTuSach() {
  return (
    <Layout>
      <TuSach />
    </Layout>
  );
}
