import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import ImgTry from "../../assets/images/img-try.svg";
import { WIDTH_BUTTON } from "../../constants/type";
import * as PATH from "../../../consts/routePaths";
import { removeAccents, styleLine } from "../selection";

const ContentExamExercises = ({
  onDispatchShowPopupActivateBook,
  statusModal,
  data,
}) => {
  const history = useHistory();
  const onClosePopup = () => {
    onDispatchShowPopupActivateBook(!statusModal);
  };
  const onNextQuestion = (data) => {
    const converTitle = removeAccents(data.title);
    history.push(
      `${PATH.ROUTE_PATH_LIST_QUIZ_DETAIL}/${converTitle}/${data.id}`
    );
    onDispatchShowPopupActivateBook(false);
  };

  return (
    <div className="content-exam-exercises-wrapper p-4">
      <div className="text-center">
        <h4>
          Bài kiểm tra tập huấn môn&nbsp;
          <span className="monkey-color-orange">{data.title}</span>
        </h4>
      </div>
      <div className="exam-exercises-body mt-5 pt-5 pb-5 ">
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-3 ml-3">
            <img src={ImgTry} alt="#" style={{ height: "150px" }} />
          </div>
          <div className="mr-3 ml-3">
            <p className="d-flex align-items-center justify-content-start mb-3">
              <i
                className="fa fa-question-circle-o monkey-fz-30 mr-3 monkey-color-orange"
                aria-hidden="true"
              ></i>
              <span>{data.total} câu hỏi trắc nghiệm</span>
            </p>
            <p className="d-flex align-items-center justify-content-start mb-3">
              <i
                className="fa fa-clock-o monkey-fz-30 mr-3 monkey-color-orange"
                aria-hidden="true"
              ></i>
              <span>Không giới hạn thời gian làm bài</span>
            </p>
            <p className="d-flex align-items-center justify-content-start mb-3">
              <i
                className="fa fa-address-card monkey-fz-22 mr-3 monkey-color-orange"
                aria-hidden="true"
              ></i>
              <span>Không giới hạn số lần làm bài</span>
            </p>
            <p className="d-flex align-items-center justify-content-start">
              <i
                className="fa fa-desktop monkey-fz-22 mr-3 monkey-color-orange"
                aria-hidden="true"
              ></i>
              <span>Biết ngay kết quả bài kiểm tra</span>
            </p>
          </div>
        </div>
      </div>
      <div style={{ ...styleLine }}></div>
      <div className="exam-exercises-footer d-flex justify-content-between mt-3 mb-3">
        <button
          onClick={onClosePopup}
          style={{ width: `${WIDTH_BUTTON}` }}
          className="btn monkey-bc-violet hvr-registration-white monkey-color-orange monkey-f-bold text-uppercase"
        >
          Thoát
        </button>
        <button
          onClick={() => onNextQuestion(data)}
          style={{ width: `${WIDTH_BUTTON}` }}
          className="btn btn-pr"
        >
          Làm bài
        </button>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ContentExamExercises);
