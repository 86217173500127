import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {
  verifyOtpPw,
  verifyOtpPwPhone,
  sendOtpVerifyPw,
} from "edu_lms/services/forgotPw/forgotPw";
import { APP_ID_WEB, APP_ID_MOBILE } from "edu_lms/constants/type";

export default function MessPhonePw(props) {
  const [messageError, onSetMessageError] = useState("");
  const { register, handleSubmit, watch, errors } = useForm();
  const onResendTrigger = (value, e) => {
    sendOtpVerifyPw(value)
      .then((res) => {
        if (res.data.status === "fail") {
          onSetMessageError(res.data.message.replace("Ba mẹ", "Bạn"));
        }
        if (res.data.status === "success") {
          onSetMessageError("");
          e.preventDefault();
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const onSubmit = (dataForm, e) => {
    let data = {
      code: dataForm.otp,
      is_web: 1,
    };
    if (props.data.type == 2) {
      data = { ...data, email: props.data.email, app_id: APP_ID_WEB };
    } else {
      data = {
        ...data,
        phone: props.data.phone,
        country_code: "+84",
        app_id: APP_ID_MOBILE,
      };
    }
    if (props.data.type == 2) {
      verifyOtpPw(data)
        .then((res) => {
          if (res.data.status === "fail") {
            onSetMessageError(res.data.message);
          }
          if (res.data.status === "success") {
            onSetMessageError("");
            props.onActiveRisetPw();
            props.setToken(res.data.data.token_to_change_pw);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    } else {
      verifyOtpPwPhone(data)
        .then((res) => {
          if (res.data.status === "fail") {
            onSetMessageError(res.data.message);
          }
          if (res.data.status === "success") {
            onSetMessageError("");
            props.onActiveRisetPw();
            props.setToken(res.data.data.token_to_change_pw);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  };

  useEffect(() => {
    onSetMessageError("");
  }, [props.show]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade modal-success modal-active modal-active-book"
      animation="true"
    >
      <div className="box-success">
        <div className="txt-2 border-bottom text-center pb-4 text-fsize">
          {`Kiểm tra ${
            props.data?.phone ? "tin nhắn điện thoại " : "email"
          } của bạn`}
        </div>
        <span>
          {/* <button
            type="button"
            class="close close-modal-active"
            aria-label="Close"
            onClick={props.onHide}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </span>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="text-center mt-4 form-forgotpw"
        >
          <div className="img-wrapper text-left">
            <p className="m-4 w-100 mb-3 fsize">
              Mật mã xác thực vừa được gửi tới{" "}
              <span className="font-weight-bold">
                {props.currentInput
                  .split("")
                  .map((num, index) => (index >= 4 && index <= 6 ? "*" : num))
                  .join("")}
              </span>
            </p>
            <p className="m-4 mb-3 fsize">
              {props.data?.phone
                ? "Hãy kiểm tra tin nhắn điện thoại của bạn và nhập mã xác thực tại đây."
                : "Hãy kiểm tra email của bạn và nhập mã xác thực tại đây. Nếu không tìm thấy mã, hãy kiểm tra hòm thư Spam."}
            </p>
            <input
              type="text"
              className="form-control w-75  mx-auto mb-5 fsize"
              placeholder="Nhập mã xác thực tại đây"
              name="otp"
              ref={register({ required: true })}
            />
          </div>
          <div className="text-center monkey-color-red mb-3">
            {messageError}
          </div>
          <button
            title=""
            className="btn-pr btn-access w-25 mr-3 bg-white text-orange border-orange fsize"
            onClick={() => {
              onResendTrigger(props.data);
            }}
          >
            Gửi lại
          </button>
          <button
            title=""
            className="btn-pr btn-access w-25 fsize"
            type="submit"
          >
            Xác nhận
          </button>
        </form>
      </div>
    </Modal>
  );
}
