import { TYPE_GAME_MAT_BG } from "../constants/MAT_BG";
import { getIconsListByLanguage } from ".";

const formatActivityDataMAT_BG = (activity) => {
  const {
    background_list,
    game_config: gameConfig,
    icon_list: iconList,
  } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const backgroundValue = background_list.backgroundList[0].value[0];
  const touchAreas = backgroundValue.touch.map((area) => {
    return {
      name: area.name,
      touchVectors: JSON.parse(area.touch_vector),
    };
  });

  let listAudioAnswer = [];
  if (gameConfig.answer?.couple_of_icon) {
    listAudioAnswer = gameConfig.answer?.couple_of_icon.map((answer) => {
      const focusedIcon = iconsList.find(
        (icon) => icon.icon_id === answer.icon_id_answer
      ) || {};
      const focusedTouchArea = touchAreas.find(
        (area) => area.name === `object-${answer.icon_object_id}`
      ) || {}
      const { touchVectors } = focusedTouchArea;
  
      return {
        url: focusedIcon.props[0].audio[0].path,
        left: touchVectors[0].x + (touchVectors[1].x - touchVectors[0].x) / 2,
        top: touchVectors[0].y
      }
    });
  }

  const positionLineStart = {
    left: gameConfig.position_line_start_left && gameConfig.position_line_start_left.split(","),
    right: gameConfig.position_line_start_right && gameConfig.position_line_start_right.split(","),
    top: gameConfig.position_line_start_top && gameConfig.position_line_start_top.split(","),
    bottom: gameConfig.position_line_start_bottom && gameConfig.position_line_start_bottom.split(","),
    center: gameConfig.position_line_start_center && gameConfig.position_line_start_center.split(","), 
  };

  return [
    {
      listAudioAnswer,
      backgroundImage: {
        src: backgroundValue.path,
        width: parseInt(backgroundValue.image_width),
        height: parseInt(backgroundValue.image_height),
      },
      touchAreas,
      typeGame: Number(gameConfig.type_game) || TYPE_GAME_MAT_BG.OneToMany,
      correctAnswers: gameConfig.answer_correct.split(","),
      positionLineStart,
      ignoreLine: gameConfig.ignore_line.split("|"),
      hasAudio: gameConfig.data, // NoAudio: 1, HasAudio: 2
    },
  ];
};

const getMatchCoordinatesPosition = (positionOrder, positionLineStart, touchAreas) => {
  const { left, right, top, bottom, center } = positionLineStart;
  const touchVectorsPosition = (touchAreas.find(touchArea => touchArea.name === `object-${positionOrder}`) || {}).touchVectors;

  if (left && left.includes(positionOrder)) {
    const offsetX = touchVectorsPosition[0].x;
    const offsetY = touchVectorsPosition[0].y + (touchVectorsPosition[3].y - touchVectorsPosition[0].y) / 2;
    return { offsetX, offsetY };
  }
  if (right && right.includes(positionOrder)) {
    const offsetX = touchVectorsPosition[1].x;
    const offsetY = touchVectorsPosition[1].y + (touchVectorsPosition[2].y - touchVectorsPosition[1].y) / 2;
    return { offsetX, offsetY };
  }
  if (top && top.includes(positionOrder)) {
    const offsetX = touchVectorsPosition[0].x + (touchVectorsPosition[1].x - touchVectorsPosition[0].x) / 2;
    const offsetY = touchVectorsPosition[0].y;
    return { offsetX, offsetY };
  }
  if (bottom && bottom.includes(positionOrder)) {
    const offsetX = touchVectorsPosition[3].x + (touchVectorsPosition[2].x - touchVectorsPosition[3].x) / 2;
    const offsetY = touchVectorsPosition[2].y;
    return { offsetX, offsetY };
  }
  if (center && center.includes(positionOrder)) {
    const offsetX = touchVectorsPosition[0].x + (touchVectorsPosition[1].x - touchVectorsPosition[0].x) / 2;
    const offsetY = touchVectorsPosition[0].y + (touchVectorsPosition[2].y - touchVectorsPosition[1].y) / 2;
    return { offsetX, offsetY };
  }
  return { offsetX: 0, offsetY: 0 };
}

const checkResultAnswer = (correctAnswers = [], matchedCoupleAreas = []) => {
  let countCorrectResult = 0;
  const matchedCoupleAreasResult = [...matchedCoupleAreas];

  correctAnswers.forEach((answer) => {
    const correctCoupleAreaIndex = matchedCoupleAreas.findIndex(
      (couple) =>
      `${couple.before.id}_${couple.after.id}` === answer ||
      `${couple.after.id}_${couple.before.id}` === answer
    );
    if (correctCoupleAreaIndex > -1) {
      countCorrectResult++;
      matchedCoupleAreasResult[correctCoupleAreaIndex].isCorrect = true;
    }
  });
  const isCorrect = countCorrectResult === correctAnswers.length;
  return { isCorrect, numberOfCorrectMatched: countCorrectResult, matchedCoupleAreasResult };
};

const isFocusTouchArea = (id, touchAreas = []) => {
  const positionOrders = touchAreas.map((touchArea) =>
    touchArea.name.replace("object-", "")
  );
  return positionOrders.includes(id);
};

export {
  formatActivityDataMAT_BG,
  getMatchCoordinatesPosition,
  checkResultAnswer,
  isFocusTouchArea,
};
