import React from "react";
import ReactPlayer from "react-player";

const pathGuideRegisterHoc10 = "https://drive.google.com/file/d/1rGfJTJv8F3i-JSAhZxN-kSqOWs5JPGuw/view";
const urlVideo = "https://youtu.be/DRjr2-5Uns8";

const pathGuideForgotPw = "https://drive.google.com/file/d/1V4U8UiV6Ys4dvECI1OXIiEOOdCx92zjB/view";

export default function GuideTraining() {
  return (
    <main>
      <div className="container-fluid container-xl pb-5 pt-2 guide-training">
      <p className="txt-2 p-2">
          Hướng dẫn đăng nhập làm bài kiểm tra tập huấn bộ sách giáo khoa Cánh
          Diều cho giáo viên.
        </p>
        <div className="d-flex justify-content-center">
          <ReactPlayer
            pip={true}
            controls={true}
            className="mx-auto"
            url={urlVideo}
            playing={true}
          />
        </div>
        <p className="py-4">
          <span>Bước 1:</span> Truy cập website{" "}
          <a href="https://hoc10.vn/" target="_blank">
            https://hoc10.vn/
          </a>{" "}
          và nhấn vào mục “Đăng nhập” (hoặc “Đăng ký” nếu thầy cô chưa có tài
          khoản với Học 10 - Thầy cô có thể xem hướng dẫn đăng ký{" "}
          <a href={pathGuideRegisterHoc10} target="_blank">
            Tại đây
          </a>
          ).{" "}
        </p>
        <img
          className="py-4"
          src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic1.png"
          alt="img_guide_register_login"
        />
        <p className="py-4">
          <span>Bước 2:</span> Sau khi ấn “Đăng nhập”, thầy cô vui lòng nhập
          thông tin đăng nhập là số điện thoại hoặc email và nhập mật khẩu. Nếu
          thầy cô quên mật khẩu, vui lòng thao tác quên mật khẩu theo hướng dẫn{" "}
          <a href={pathGuideForgotPw} target="_blank">
            Tại đây{" "}
          </a>{" "}
        </p>
        <img
          src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic2.png"
          alt="Bước 2"
        />
        <p className="py-4">
          <span>Bước 3:</span> Cập nhật thông tin cá nhân và trường học.
          <p>
            Ở màn tập huấn, thầy cô lưu ý cập nhật đúng và đầy đủ các thông tin
            cá nhân như email và số điện thoại, kèm thông tin đơn vị công tác.
            Kết quả bài kiểm tra tập huấn sẽ được Hoc10 gửi về Sở và Phòng giáo
            dục theo thông tin mà quý thầy cô khai báo.
          </p>
        </p>
        <img
          className="py-4"
          src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic3.png"
          alt="img_guide_update_information"
        />
        <p>
          Thầy cô cập nhật thông tin cá nhân và thông tin trường học tương ứng
        </p>

        <p className="py-4">
          {" "}
          <span>Bước 4:</span> Xác thực email tập huấn.
          <p>
            Ngoài ra, hoc10 sẽ gửi kết quả bài kiểm tra về địa chỉ email của Quý
            thầy cô, vì vậy thầy cô vui lòng xác thực email của mình để đảm bảo
            nhận được kết quả kiểm tra. Thầy cô vui lòng xác thực email bằng
            cách chọn vào mục "Xác thực ngay".
          </p>
        </p>
        <img
          className="py-4"
          src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic4.png"
          alt="Bước 4_1"
        />
        <p className="py-4">
          Thầy cô nhập mã xác thực gồm 4 ký tự đã được gửi trong email và ấn
          “Xác nhận”.
        </p>
        <img
          src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic5.png"
          alt="Bước 4_2"
        />
        <p className="py-4">
          <span>Bước 5:</span> Thêm môn kiểm tra tập huấn
          <p className="pb-2">
            Để làm bài kiểm tra tập huấn, thầy cô vui lòng chọn "Thêm môn kiểm
            tra tập huấn", chọn lớp và môn muốn làm bài kiểm tra rồi ấn nút "cập
            nhật".
          </p>
          <img
            src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic6.png"
            alt="Bước 5"
          />
          <img
            src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic7.png"
            alt="Bước 5"
          />
        </p>
        <p className="py-4">
          <span>Bước 6:</span> Làm bài kiểm tra tập huấn
          <p>
            Để làm bài với môn đã thêm, thầy cô vui lòng chọn mục “Làm bài kiểm
            tra”, tiến hành làm bài và nộp bài để hệ thống ghi nhận kết quả
            tương ứng. Kết quả tập huấn được ghi nhận ở lần làm bài có số điểm
            cao nhất.
          </p>
          <img
            className="pt-4"
            src="https://hoc10.monkeyuni.net/upload/hoc10/training/Pic8.png"
            alt="Bước 6"
          />
        </p>

        <p className="py-4">
          Cần hỗ trợ thông tin trong quá trình thao tác, thầy cô vui lòng liên
          hệ hotline 024.73098866 (từ 8h00 - 21h30 hàng ngày) để được hỗ trợ.
          <p className="pt-2">Chúc thầy cô đạt kết quả cao!</p>
        </p>
      </div>
    </main>
  );
}
