import React from "react";

const ICON = {
  success: {
    img: "/assets/img/laugh.png",
  },
  medium: {
    img: "/assets/img/fun.png",
  },
  rather: {
    img: "/assets/img/sad.png",
  },
  error: {
    img: "/assets/img/cry.png",
  },
};

export default function CTAIcon({ alertType }) {
  return (
    <div className="text-right w-100">
      <img src={ICON[alertType].img} className="w-100" alt="CTA icon" />
    </div>
  );
}
