import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { onDispatchDataInfo } from "../../modules/SignIn/actions";
import { onDispatchListTeachingInformation } from "../../modules/UpdateInformation/actions";
import * as TEXT from "../../constants/text";
import * as PATH from "../../../consts/routePaths";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import ControllerTeachingInformation from "./ControllerTeachingInformation";
import { onValueOptionTeachingInformation } from "../../modules/UpdateInformation/config";

const FromTeachingInformation = ({
  listGradeAll,
  userInfo,
  dataTeachingInformation = [],
  onUpdateTeachingInformation,
  listTeachingInformation,
  listSubjectAll,
  onDispatchListTeachingInformation,
  onDispatchDataInfo,
  onGetListSubject,
  onSlickNext,
}) => {
  const [type, setType] = useState("");
  const { register, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      teaching: [{ valueClass: "", valueSubjects: "" }],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "teaching",
    }
  );
  const [listGrade, setStateListGrade] = useState([]);

  useEffect(() => {
    if (dataTeachingInformation) {
      if (dataTeachingInformation.length > 0) {
        if (dataTeachingInformation[0]?.grade_id !== "") {
          setType("update");
          const dataForm = onValueOptionTeachingInformation(
            dataTeachingInformation,
            listTeachingInformation,
            listGradeAll,
            listSubjectAll
          );
          setValue("teaching", dataForm);
        }
      } else {
        setType("add");
        const dataDefault = [{ valueClass: "", valueSubjects: "" }];
        setValue("teaching", dataDefault);
      }
    } else {
      const dataDefault = [{ valueClass: "", valueSubjects: "" }];
      setValue("teaching", dataDefault);
    }
  }, [dataTeachingInformation, listSubjectAll, listGradeAll]);

  useEffect(() => {
    setStateListGrade(listGradeAll);
  }, [listGradeAll, dataTeachingInformation]);

  function arrayDiffByKey(key, ...arrays) {
    return [].concat(
      ...arrays.map((arr, i) => {
        const others = arrays.slice(0);
        others.splice(i, 1);
        const unique = [...new Set([].concat(...others))];
        return arr.filter((x) => !unique.some((y) => x[key] === y[key]));
      })
    );
  }

  const onSubmit = () => {
    var result = Object.values(
      fields.reduce((a, c) => {
        (
          a[c.valueClass?.id] ||
          (a[c.valueClass?.id] = {
            valueClass: c?.valueClass,
            valueSubjects: [],
          })
        ).valueSubjects.push(c?.valueSubjects);
        return a;
      }, {})
    );
    console.log(result);
    const dataConvert = result.map((item) => {
      const result = item.valueSubjects.flat();
      return { ...item, valueSubjects: result };
    });
    const dataConfig = dataConvert.map((item) => {
      const grade_id = item?.valueClass?.id;
      let subjects = [];
      let list_subject = [];
      if (item?.valueSubjects.length > 0) {
        item?.valueSubjects.forEach((subjectItem) => {
          if (subjectItem.id) {
            subjects = [...subjects, subjectItem.id];
            list_subject = [...list_subject, { subject_id: subjectItem.id }];
          }
        });
      }
      return {
        grade_id,
        subjects: _.uniq(subjects),
        list_subject: _.uniqBy(list_subject, function (e) {
          return e.subject_id;
        }),
      };
    });
    console.log(dataConfig);
    const newUserInfo = { ...userInfo, list_grade_subject: dataConfig };
    localStorage.setItem("listGradeSubject", JSON.stringify(dataConfig));
    onDispatchDataInfo(newUserInfo);
    if (localStorage.getItem("pathQuiz") !== PATH.ROUTE_PATH_TRAINING_TEST_2) {
      onUpdateTeachingInformation({
        data_update: JSON.stringify(dataConfig),
        type: type,
      });
    }
    onSlickNext();
  };
  const onChangeSelect = (data, type, id) => {
    let newData = [];
    if (type === 1) {
      onGetListSubject(data.id);
      newData = fields.map((item) => {
        if (item.id === id) {
          item.valueClass = data;
          item.valueSubjects = "";
        }
        return { ...item };
      });
    }
    if (type === 2) {
      newData = fields.map((item) => {
        if (item.id === id) {
          item.valueSubjects = data;
        }
        return { ...item };
      });
    }
    setValue("teaching", newData);
  };
  const onRemove = (index, data) => {
    const newData = fields.filter((item) => item.id !== data.id);
    setValue("teaching", newData);
  };
  const onAppend = () => {
    const newData = [...fields, { valueClass: "", valueSubjects: "" }];
    setValue("teaching", newData);
  };
  return (
    <form className="from-info p-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        {fields.map((item, index) => {
          return (
            <ControllerTeachingInformation
              key={item.id}
              data={item}
              index={index}
              nameClass={`teaching[${index}].valueClass`}
              nameSubjects={`teaching[${index}].valueSubjects`}
              listClass={listGrade}
              listTeachingInformation={listTeachingInformation}
              onChangeSelect={onChangeSelect}
              onRemove={onRemove}
              remove={remove}
              control={control}
              required={{
                required: true,
              }}
            />
          );
        })}
        <div className="col-12">
          <input
            style={{ height: "44px" }}
            className="btn monkey-bg-violet monkey-color-white rounded-pill mr-2 hvr-registration-white"
            type="submit"
            disabled={onDisabled(fields)}
            value={
              Number(localStorage.getItem("status")) === 1
                ? TEXT.TEXT_DONE
                : TEXT.TEXT_NEXT
            }
          />
          <button
            style={{ height: "44px" }}
            type="button"
            onClick={onAppend}
            className="btn monkey-bc-violet hvr-registration-white rounded-pill monkey-color-violet monkey-f-bold text-uppercase"
          >
            thêm lớp
          </button>
        </div>
      </div>
    </form>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchDataInfo,
      onDispatchListTeachingInformation,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(FromTeachingInformation);

function onDisabled(data) {
  const result = data.filter(
    (item) => item.valueSubjects === "" || item.valueClass === ""
  );
  return result.length > 0 ? true : false;
}
