import _ from "lodash";
import Intro from "./Intro";
import { useBookStore } from "stores/bookStore";
import { DOCUMENT_CATEGORY_GIOI_THIEU_CHUNG } from "consts/book";
import { useAppStore } from "stores/appStore";

export default function SectionGioiThieuChung() {
  const documents = useBookStore((state) => state.documents);
  const gradeId = useAppStore((state) => state.gradeId);
  const documentsOfCurrentGrade = _.get(documents, [gradeId], []);
  const gtsDocuments = documentsOfCurrentGrade.filter(
    (item) => item.category === DOCUMENT_CATEGORY_GIOI_THIEU_CHUNG
  );

  return (
    !_.isEmpty(gtsDocuments) && (
      <div className="col-md-12 result">
        <Intro
          data={gtsDocuments}
          categoryBook="Giới thiệu chung"
          col="col-6 col-lg-3"
          background="#fff"
        />
      </div>
    )
  );
}
