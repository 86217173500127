import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import classNames from "classnames";
import styled from "styled-components";
import JXG from "jsxgraph";
import { INNER_WIDTH } from "edu_lms/constants/type";
import { TYPE_DATA } from "../../constants";
import {
  GRAPH_TITLE,
  GRAPH_TYPE,
  POINT_EVENT,
  GRAPH_OBJECT,
} from "../../constants/Graph";
import { checkCorrectAnswer } from "../../helpers/Graph001";
import { createBoard, getEquationGraph, convertCoordinatesToInteger, getAnswerColor, getAnswerColorPoint } from "../../helpers/graph";
import { Typography } from "../../components";
import "./styles.scss";

const DEFAULT_POINT_A = [1, 0];
const DEFAULT_POINT_B = [0, 1];

const Graph001 = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAnswerProp = null,
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const {
    graphType,
    coefficient,
    negativeCoorX,
    negativeCoorY,
    positiveCoorX,
    positiveCoorY,
  } = gameData;
  const boardId = `graph001-board-${Math.random()}`;

  const [coorPointA, setCoorPointA] = useState({ value: DEFAULT_POINT_A });
  const [coorPointB, setCoorPointB] = useState({ value: DEFAULT_POINT_B });
  const [isCorrect, setIsCorrect] = useState(null);

  const isCheckedRef = useRef(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  /*-------------------- Draw graph board --------------------*/
  useEffect(() => {
    const board = createBoard({
      boardId,
      boundingBox: [negativeCoorX, positiveCoorY, positiveCoorX, negativeCoorY],
      enabledDrag: true,
    });

    drawGraph({
      board,
      isCorrect: hideResultAnswer ? null : isCorrect,
      coordinatesPointA: coorPointA,
      coordinatesPointB: coorPointB,
    });

    return () => {
      JXG.JSXGraph.freeBoard(board);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [negativeCoorX, positiveCoorY, positiveCoorX, negativeCoorY, coefficient, coorPointA, coorPointB, isCorrect]);

  /*-------------------- Show answer result graph --------------------*/
  useEffect(() => {
    if (selectedAnswerProp) {
      const { xA, yA, xB, yB, isCorrectPointA, isCorrectPointB } = selectedAnswerProp;
      const { isCorrect } = checkCorrectAnswer(graphType, coefficient, selectedAnswerProp);

      setCoorPointA({ value: [xA, yA], isCorrect: isCorrectPointA });
      setCoorPointB({ value: [xB, yB], isCorrect: isCorrectPointB });
      setIsCorrect(isCorrect);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswerProp]);

  /*-------------------- Show correct answer result graph --------------------*/
  useEffect(() => {
    if (showCorrectAnswer) {
      let xA, xB;
      let yA, yB;
      if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
        const { a, b } = coefficient;
        xA = 0;
        xB = 1;
        yA = a * xA + b;
        yB = a * xB + b;
      }
      if (graphType === GRAPH_TYPE.QuadraticEquation) {
        const { a, b, c } = coefficient;
        xA = Math.round(-b / (2 * a));
        xB = xA - 1;
        yA = a * xA * xA + b * xA + c;
        yB = a * xB * xB + b * xB + c;
        const isIncreasingDecreasing = (a > 0 && yA < yB) || (a < 0 && yA > yB);
        if (!isIncreasingDecreasing) {
          xB = xA + 1;
          yB = a * xB * xB + b * xB + c;
        }
      }

      setCoorPointA({ value: [xA, yA], isCorrect: true });
      setCoorPointB({ value: [xB, yB], isCorrect: true });
      setIsCorrect(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  const handlePointEventUp = () => {
    onPlaying(false);
    if (isCheckedRef.current) {
      setIsCorrect(null);
      isCheckedRef.current = false;
    }
  };

  const drawGraph = ({ board, isCorrect, coordinatesPointA, coordinatesPointB }) => {
    const pointA = board.create(GRAPH_OBJECT.Point, coordinatesPointA.value, {
      name: "",
      color: getAnswerColorPoint(hideResultAnswer ? null : coordinatesPointA.isCorrect),
      strokeColor: getAnswerColorPoint(hideResultAnswer ? null : coordinatesPointA.isCorrect),
    });
    const pointB = board.create(GRAPH_OBJECT.Point, coordinatesPointB.value, {
      name: "",
      color: getAnswerColorPoint(hideResultAnswer ? null : coordinatesPointB.isCorrect),
      strokeColor: getAnswerColorPoint(hideResultAnswer ? null : coordinatesPointB.isCorrect),
    });

    pointA.on(POINT_EVENT.Up, () => {
      const newCoorPointA = convertCoordinatesToInteger(pointA);
      pointA.moveTo(newCoorPointA);
      setCoorPointA({ ...coorPointA, value: newCoorPointA });
      handlePointEventUp();
    });

    pointB.on(POINT_EVENT.Up, () => {
      const newCoorPointB = convertCoordinatesToInteger(pointB);
      pointB.moveTo(newCoorPointB);
      setCoorPointB({ ...coorPointB, value: newCoorPointB });
      handlePointEventUp();
    });

    if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
      board.create(GRAPH_OBJECT.Line, [pointA, pointB], {
        strokeColor: getAnswerColor(isCorrect),
        isDraggable: false,
      });
    }
    if (graphType === GRAPH_TYPE.QuadraticEquation) {
       board.create(
        GRAPH_OBJECT.Parabol,
        (x) => {
          const aX = pointA.X();
          const aY = pointA.Y();
          const bX = pointB.X();
          const bY = pointB.Y();
          const a = (bY - aY) / ((bX - aX) * (bX - aX));
          return a * (x - aX) * (x - aX) + aY;
        },
        {
          strokeColor: getAnswerColor(hideResultAnswer ? null : isCorrect),
          isDraggable: false,
        }
      );
    }
  };

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    isCheckedRef.current = true;

    const xA = coorPointA.value[0];
    const yA = coorPointA.value[1];
    const xB = coorPointB.value[0];
    const yB = coorPointB.value[1];
    const selectedAnswer = { xA, yA, xB, yB };
    const { isCorrect, isCorrectPointA, isCorrectPointB } = checkCorrectAnswer(graphType, coefficient, selectedAnswer);
    setIsCorrect(isCorrect);
    setCoorPointA({ ...coorPointA, isCorrect: isCorrectPointA });
    setCoorPointB({ ...coorPointB, isCorrect: isCorrectPointB });
  
    onComplete({
      isCorrect,
      selectedAnswer: { ...selectedAnswer, isCorrectPointA, isCorrectPointB },
    });
  };

  const handleResetAnswer = () => {
    isCheckedRef.current = false;
    setIsViewOnly(false);
    setCoorPointA({ value: DEFAULT_POINT_A });
    setCoorPointB({ value: DEFAULT_POINT_B });
    setIsCorrect(null);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  return (
    <div
      className={classNames("graph-001-container position-relative", {
        "pl-5": window.innerWidth > INNER_WIDTH.MOBILE,
      })}
    >
      <div className="header-title monkey-f-header monkey-fz-20">
        <h2>{GRAPH_TITLE}</h2>
        <Typography
          typeText={[TYPE_DATA.LATEX]}
          text={getEquationGraph(graphType, coefficient)}
        />
      </div>
      <GraphBoard>
        <div id={boardId} className="graph" />
        {(isReadOnly || isViewOnly) && (
          <div className="position-absolute w-100 h-100 overlay" />
        )}
      </GraphBoard>
    </div>
  );
};

export default forwardRef(Graph001);

const GraphBoard = styled.div`
  position: relative;
  width: max-content;
  margin: 0 auto;
  .graph {
    width: ${window.innerWidth > INNER_WIDTH.MOBILE ? "400px" : "100%"};
    height: ${window.innerWidth > INNER_WIDTH.MOBILE
      ? "400px"
      : `${window.innerWidth}px`};
  }
  .overlay {
    background-color: transparent;
    top: 0;
  }
`;
