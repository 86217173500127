import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
} from "react-router-dom";
import _ from "lodash";
import routes from "router/routes";
import Layout from "edu_lms_v2/components/Layout";
import * as PATH from "consts/routePaths";
import AppContainer from "modules/AppContainer";
import { useAuthStore } from "stores/authStore";

const refactoredPages = [
  PATH.ROUTE_PATH_TU_SACH,
  `${PATH.ROUTE_PATH_TU_SACH}:subject/:grade`,
  PATH.ROUTE_PATH_V3_LIST_SLIDE,
  `${PATH.ROUTE_PATH_V3_LIST_SLIDE}:subjectSlug/:gradeSlug`,
  PATH.ROUTE_PATH_COURSE,
  `${PATH.ROUTE_PATH_COURSE}:subject/:grade`,
];

export function PrivateRoute({ component: Page, ...rest }) {
  const location = useLocation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const isRefactoredPages = (currentPath) =>
    !_.isEmpty(
      refactoredPages.filter((path) =>
        matchPath(currentPath, {
          path,
          exact: true,
          strict: false,
        })
      )
    );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn && rest.isPrivate) {
          <Redirect
            to={{
              pathname: PATH.ROUTE_PATH_V3_SIGN_IN,
              state: { from: rest.location.pathname },
            }}
          />;
        }

        if (isRefactoredPages(location.pathname)) {
          return (
            <AppContainer>
              <Page {...props} />
            </AppContainer>
          );
        }

        return (
          <AppContainer>
            <Layout
              {...props}
              isIdleTimer={rest.isPrivate}
              showHeader={rest.showHeader}
              showFooter={rest.showFooter}
            >
              <Page {...props} data={rest.data} />
            </Layout>
          </AppContainer>
        );
      }}
    />
  );
}

export default function RouteList() {
  return (
    <Switch>
      {routes.map((route, index) => (
        <PrivateRoute
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.main}
          isPrivate={route.isPrivate}
          showHeader={route.showHeader}
          showFooter={route.showFooter}
          data={route.routes}
          accept={route.accept}
          sensitive
        />
      ))}
    </Switch>
  );
}
