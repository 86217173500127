import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import renderHTML from "react-render-html";
import { getListNoti, postReadNotification } from "edu_lms/services/app/app";
import Loading from "../../components/Loading";
import notification from "./notification.module.css";
import { onDispatchRedingNoti } from "./actions";
import Pagination from "../../components/Pagination";
import * as TYPES from "edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import styled from "styled-components";

export default function Notification() {
  const dateNow = dayjs.unix(Date.now() / 1000).format("D/M/YYYY");
  const [currentPage, setCurrentPage] = useState(TYPES.PAGE_DEFAULT);
  const [dataNoti, setDataNoti] = useState([]);
  const [totalNoti, setTotalNoti] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useScrollToTop();

  useEffect(() => {
    setLoading(true);
    // getListNoti({ limit: TYPES.LIMIT_NOTI, page: currentPage }).then((res) => {
    //   if (res?.data?.status === "success") {
    //     setDataNoti(res.data?.data?.list);
    //     setTotalNoti(res.data?.data?.total);
    //     setLoading(false);
    //   }
    // });
  }, [currentPage]);

  const onReadNoti = (noti) => {
    postReadNotification(noti._id);
    if (noti.read === TYPES.UNREAD_NOTI) {
      const newDataNoti = dataNoti.map((notiItem) =>
        notiItem._id === noti._id
          ? { ...notiItem, read: TYPES.READ_NOTI }
          : notiItem
      );
      setDataNoti(newDataNoti);
      dispatch(onDispatchRedingNoti(noti.read));
    }
  };

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gray">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Thông báo
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-information bg-gray">
        <div className="container">
          <div className="tab-content hoc10-tab-content shadow-lg p-0">
            <h4 className="title pt-4 pl-4 pb-3 font-weight-bold h4">
              Thông báo
            </h4>
            <Accordion>
              {!loading ? (
                dataNoti?.map((noti) => {
                  const day = dayjs
                    .unix(noti.time_published)
                    .format("D/M/YYYY");
                  const time = dayjs.unix(noti.time_published).format("HH:mm");
                  return (
                    <Card key={noti._id} bsPrefix={notification.card}>
                      <Accordion.Toggle
                        eventKey={noti._id}
                        onClick={() => onReadNoti(noti)}
                      >
                        <div
                          className={
                            "d-flex justify-content-between cursor list-group-item-action p-2 " +
                            (noti.read === TYPES.UNREAD_NOTI &&
                              notification.bGRNotSeen)
                          }
                        >
                          <div className={`icon ml-2 ${notification.fs20}`}>
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <div
                            className={`title ml-3 text-left d-flex align-items-center ${notification.widthTitle}`}
                          >
                            <h6
                              className={
                                "mb-1 text-muted " +
                                (noti.read !== TYPES.READ_NOTI &&
                                  "font-weight-bold")
                              }
                            >
                              {noti.title}
                            </h6>
                          </div>
                          <div
                            className={`time d-flex flex-column justify-content-center align-content-center text-center w-25 ${notification.widthTime}`}
                          >
                            <p>{`${time} ${
                              day !== dateNow ? day : "Hôm nay"
                            }`}</p>
                          </div>
                          <div
                            className={`d-flex justify-content-center align-content-center flex-wrap ${notification.seenIcon}`}
                          >
                            {noti.read === TYPES.UNREAD_NOTI && (
                              <i
                                className="fa fa-circle"
                                aria-hidden="true"
                              ></i>
                            )}
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={noti._id}>
                        <ContentNoti
                          className={`border-top ${notification.contentNoti}`}
                        >
                          {renderHTML(noti.content ? noti.content : "")}
                        </ContentNoti>
                      </Accordion.Collapse>
                    </Card>
                  );
                })
              ) : (
                <Loading />
              )}
            </Accordion>
          </div>
        </div>
        {totalNoti > TYPES.LIMIT_NOTI && (
          <Pagination
            total={totalNoti}
            totalPerPage={TYPES.LIMIT_NOTI}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </main>
  );
}

const ContentNoti = styled.div`
  img {
    display: inline-block;
  }
`;
