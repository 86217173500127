import ModalRequireLogin from "edu_lms_v2/modules/ActiveBook/ModalRequireLogin";
import LoginWrapper from "edu_lms_v2/modules/Login/LoginWrapper";
import LoginSuccessModal from "edu_lms_v2/modules/Login/LoginSuccessModal";
import ModalActiveBook from "edu_lms_v2/modules/ActiveBook/ModalActiveBook";
import ModalActiveBookSuccess from "edu_lms_v2/modules/ActiveBook/ModalActiveBookSuccess";

export default function CheckModal(props) {
  const {
    typeBook,
    isRequireLogin,
    loginModalShow,
    modalLoginSuccess,
    isActiveModalBook,
    activeBookSuccess,
    setIsRequireLogin,
    setLoginModalShow,
    setModalLoginSuccess,
    setIsActiveModalBook,
    setActiveBookSuccess,
    onRequireLogin,
    onLoginSuccess,
    onActiveBookSuccess,
  } = props;
  return (
    <>
      <ModalRequireLogin
        show={isRequireLogin}
        onHide={() => setIsRequireLogin(false)}
        onRequireLogin={onRequireLogin}
      />
      <LoginWrapper
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onLoginSuccess={onLoginSuccess}
      />
      <LoginSuccessModal
        show={modalLoginSuccess}
        onHide={() => {
          setModalLoginSuccess(false);
        }}
      />
      <ModalActiveBook
        show={isActiveModalBook}
        onHide={() => setIsActiveModalBook(false)}
        onActiveBook={onLoginSuccess}
        onActiveBookSuccess={onActiveBookSuccess}
      />
      <ModalActiveBookSuccess
        show={activeBookSuccess}
        onHide={() => setActiveBookSuccess(false)}
        data={typeBook}
      />
    </>
  );
}
