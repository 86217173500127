import Select from "react-select";
import { useAppStore } from "stores/appStore";
import { useEffect } from "react";
import { onResultUserInfo } from "../edu_lms/modules/selection";

export default function StudyWeekFilter({ onChange }) {
  const studyWeekId = useAppStore((state) => state.studyWeekId);
  const setStudyWeekId = useAppStore((state) => state.setStudyWeekId);
  const studyWeeks = useAppStore((state) => state.studyWeeks);
    const fetchStudyWeeks = useAppStore((state) => state.fetchStudyWeeks);
  const items = studyWeeks.map((item) => ({
    ...item,
    value: item.id,
    label: item.title,
    checked: item.id === studyWeekId,
  }));

  const currentStudyWeek = items.find((item) => item.id === studyWeekId);
  useEffect(() => {
      if(!Object.keys(studyWeeks).length) {
          fetchStudyWeeks();
      }}, []);
  
  return (
    <Select
      value={currentStudyWeek}
      placeholder="Chọn tuần"
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
      onChange={(item) => {
        setStudyWeekId(item?.id);
        if (onChange) {
          onChange(item);
        }
      }}
      options={items}
      isClearable
    />
  );
}
