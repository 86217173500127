import {
  getIconsListByLanguage,
  getContentAudioVideoImageTextFromIconActData,
} from ".";
import { TYPE_DATA } from "../constants";
import { SPLIT_CHARS_ANSWER, SPLIT_ANSWER_RESULT } from "../constants/FIB";

const formatActivityDataFIBMath = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    type_text: typeText,
    font_size: fontSize,
  } = gameConfig;

  const dataGameSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.question;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );
    const question = {
      questionId,
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          fontSize,
          typeText: typeText || [TYPE_DATA.LATEX],
          contentText: questionContentIcon.contentText,
        },
      },
    };

    return {
      question,
      answer: {
        typeAnswer,
        answers: dataGameConfigItem.answers.map((answerLine) => ({
          answerId: answerLine.answer_id,
          answerItems: answerLine.answer_text ? answerLine.answer_text.split(SPLIT_CHARS_ANSWER) : [],
          answerResult: answerLine.answer_result ? answerLine.answer_result.split(SPLIT_ANSWER_RESULT) : null,
        })),
        fontSize,
        typeText: typeText || [TYPE_DATA.LATEX],
        numberOfAnswersInRow: Number(dataGameConfigItem.answer_number_in_a_row),
      },
    };
  });

  return dataGameSetList;
};

export { formatActivityDataFIBMath };
