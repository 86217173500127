import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React from "react";
import styled from "styled-components";

const AudioComponent = ({ src }) => {
  return (
    <AudioWrapper>
      <AudioPlayer src={src} autoPlay={false} />
    </AudioWrapper>
  );
};
export default AudioComponent;

const AudioWrapper = styled.div`
  display: flex;
  justify-content: center;
  .rhap_progress-section {
    display: none;
  }
  .rhap_additional-controls {
    display: none;
  }
  .rhap_volume-controls {
    display: none;
  }
  .rhap_forward-button {
    display: none;
  }
  .rhap_rewind-button {
    display: none;
  }
  .rhap_container {
    width: 50px;
    box-shadow: none;
    background: none;
  }
`;
