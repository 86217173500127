import React from "react";

export default function TermDetails() {
  return (
    <article>
      <h4>1. Chấp thuận các Điều kiện sử dụng:</h4>
      <p>
        Hoc10.vn là website cho phép khách hàng sau khi mua bộ sách giáo khoa
        Cánh Diều có thể truy cập vào website này để tải sách bản PDF cũng như
        tham khảo, tải các hình ảnh của bộ sách để phục vụ cho mục đích nghiên
        cứu và giảng dạy (đối với đối tượng giáo viên) và học tập (đối với đối
        tượng học sinh). Để sử dụng tính năng này, Quý khách hàng có thể tham
        khảo tại mục “ Hướng dẫn kích hoạt” được được hướng dẫn các bước sử
        dụng.
      </p>
      <p>
        Khi sử dụng Website của Hoc10.vn, Bạn đã mặc nhiên chấp thuận các điều
        khoản và điều kiện sử dụng được quy định dưới đây. Để biết được các sửa
        đổi mới nhất, Bạn nên thường xuyên kiểm tra lại “Điều kiện Sử dụng”.
        Hoc10.vn có quyền thay đổi, điều chỉnh, thêm hay bớt các nội dung của
        “Điều kiện Sử dụng” tại bất kỳ thời điểm nào. Nếu Bạn vẫn tiếp tục sử
        dụng Website sau khi có các thay đổi như vậy thì có nghĩa là Bạn đã chấp
        thuận các thay đổi đó.
      </p>
      <h4>2. Tính chất hiển thị thông tin:</h4>
      <p>
        a) Trang web Hoc10.vn thuộc sở hữu hợp pháp của CÔNG TY CỔ PHẦN ONE
        PERCENT
      </p>
      <p>
        b) Khách hàng tham gia kích hoạt sách tại website Hoc10.vn tự do thỏa
        thuận trên cơ sở tôn trọng quyền và lợi ích hợp pháp của các bên tham
        gia thực hiện đúng với quy chế và các quy định của pháp luật liên quan.
      </p>
      <p>
        c) Hoạt động kích hoạt sách tại website Hoc10.vn phải được thực hiện
        công khai, minh bạch, đảm bảo quyền lợi của người sử dụng dịch vụ
      </p>
      <p>
        - Bản quy chế này áp dụng cho tất cả các người dùng tham gia kích hoạt
        sách trên Hoc10.vn
      </p>
      <p>
        - Tất cả các nội dung trong bản quy chế này phải tuân thủ theo hệ thống
        pháp luật hiện hành của Việt Nam.
      </p>
      <h4>3. Quy định về quyền lợi và nghĩa vụ của khách hàng:</h4>
      <h5>
        3.1. Quy định về tài khoản và quyền lợi bảo mật thông tin của người dùng
      </h5>
      <p>
        <strong>3.1.1. Quy định về tài khoản</strong>
      </p>
      <p>
        Khi sử dụng dịch vụ của Hoc10.vn, người dùng sẽ cung cấp cho chúng tôi
        thông tin về địa chỉ email, mật khẩu và thông tin cá nhân để có được 1
        tài khoản tại website nhằm kích hoạt quyền sử dụng sách. Việc sử dụng và
        bảo mật thông tin tài khoản là trách nhiệm và quyền lợi của người dùng
        khi sử dụng Hoc10.vn. Ngoài ra, những thông tin khác trong tài khoản như
        Họ tên, địa chỉ, điện thoại.... là những thông tin của người dùng giúp
        cho Hoc10.vn phục vụ người dùng tốt nhất.
      </p>
      <p>
        Trong trường hợp thông tin do người dùng cung cấp không đầy đủ hoặc sai
        dẫn đến việc chúng tôi không đủ cơ sở để thực hiện kích hoạt quyền sử
        dụng sách cho người dùng, chúng tôi có quyền từ chối cung cấp dịch vụ mà
        không phải chịu bất cứ trách nhiệm nào đối với người dùng . Khi có những
        thay đổi thông tin cá nhân của người dùng , Bạn vui lòng đăng nhập hệ
        thống sau đó cập nhật lại thông tin trong tài khoản tại Hoc10.vn. Người
        dùng phải giữ kín mật khẩu và tài khoản, người dùng cũng phải hoàn toàn
        chịu trách nhiệm đối với tất cả các hoạt động diễn ra thông qua việc sử
        dụng mật khẩu hoặc tài khoản của mình. Người dùng nên đảm bảo thoát khỏi
        tài khoản tại Hoc10.vn sau mỗi lần sử dụng để bảo mật thông tin của mình
        an toàn hơn. Trong trường hợp nghi ngờ tài khoản của mình bị truy cập
        trái phép, Bạn hàng phải nhanh chóng thông báo cho chúng tôi để ngăn
        chặn kịp thời. Chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực tiếp
        hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do Bạn không
        tuân thủ quy định.
      </p>
      <p>
        <strong>3.1.2. Quyền lợi bảo mật thông tin của người dùng</strong>
      </p>
      <p>
        Bạn hoàn toàn có thể yên tâm về các thông tin của mình trên Hoc10.vn bởi
        chúng tôi ưu tiên hàng đầu trong việc cung cấp dịch vụ tại website. Khi
        sử dụng dịch vụ của chúng tôi tại website Hoc10.vn., người dùng được đảm
        bảo những thông tin cá nhân mà người dùng cung cấp cho chúng tôi sẽ chỉ
        được dùng để nâng cao chất lượng dịch vụ chăm sóc người dùng của
        Hoc10.vn và sẽ không được chuyển giao cho một bên thứ ba nào khác vì bất
        kỳ mục đích nào. Thông tin của người dùng tại Hoc10.vn sẽ được chúng tôi
        bảo mật và chỉ trong trường hợp pháp luật yêu cầu, chúng tôi mới cung
        cấp những thông tin này cho các cơ quan pháp luật. Bạn vui lòng tham
        khảo thêm quyền lợi bảo mật thông tin của người dùng chi tiết tại mục
        Chính sách bảo mật.
      </p>
      <h4>
        3.2. Quyền và nghĩa vụ của người dùng khi sử dụng dịch vụ trên Hoc10.vn.
      </h4>
      <p>a) Quyền của người dùng</p>
      <p>- Được tiếp cận, lựa chọn các kích hoạt các loại sách website.</p>
      <p>
        - Được hưởng các hỗ trợ của website và các bộ phận hỗ trợ khác để việc
        tìm hiểu thông tin được thuận lợi.
      </p>
      <p>- Khiếu nại nếu có sai sót trong việc sử dụng dịch vụ</p>

      <p>b) Nghĩa vụ của người dùng</p>
      <p>
        a. Cung cấp đầy đủ thông tin cá nhân có liên quan khi đăng ký là thành
        viên như: Họ và tên, địa chỉ liên lạc, email, số chứng minh nhân dân,
        điện thoại, số tài khoản, số thẻ thanh toán..., và chịu trách nhiệm về
        tính pháp lý của những thông tin trên. Ban quản lý website không chịu
        trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến
        quyền lợi của thành viên đó nếu xét thấy tất cả thông tin cá nhân của
        thành viên đó cung cấp khi đăng ký ban đầu là không chính xác.
      </p>
      <p>
        b. Người dùng tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay
        hình thức nào khác để can thiệp vào hệ thống hay làm thay đổi cấu trúc
        dữ liệu. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt
        động nào nhằm can thiệp, phá hoại hay xâm của hệ thống website. Mọi vi
        phạm sẽ bị xử lý theo Quy chế và quy định của pháp luật.
      </p>
      <p>
        c. Người dùng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ và mọi hoạt
        động sử dụng dịch vụ dưới tên đăng ký, mật khẩu của mình. Người dùng có
        thể sửa dữ liệu cá nhân của mình trên hệ thống Website. Thành viên có
        trách nhiệm thông báo kịp thời cho Website về những hành vi sử dụng trái
        phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của
        mình để hai bên cùng hợp tác xử lý.
      </p>
      <p>
        d. Người dùng cam kết những thông tin cung cấp cho Website và những
        thông tin đang tải lên Website là chính xác và hoàn chỉnh (đặc biệt
        thông tin có liên quan đến cá nhân của thành viên). Thành viên đồng ý
        giữ và thay đổi các thông tin trên Website là cập nhật, chính xác và
        hoàn chỉnh.
      </p>
      <p>
        e. Người dùng cam kết, đồng ý không sử dụng dịch vụ của Website vào
        những mục đích bất hợp pháp, không hợp lý, lừa đảo, đe doạ, thăm dò
        thông tin bất hợp pháp, phá hoại, tạo ra và phát tán virus gây hư hại
        tới hệ thống, cấu hình, truyền tải thông tin của Website hay sử dụng
        dịch vụ của mình vào mục đích phá hoại, tạo những cuộc hẹn giả nhằm mục
        đích xấu. Trong trường hợp vi phạm thì thành viên phải chịu trách nhiệm
        về các hành vi của mình trước pháp luật.
      </p>
      <p>
        f. Người dùng cam kết không được thay đổi, chỉnh sửa, gán ghép, copy,
        truyền bá, phân phối, cung cấp và tạo những công cụ tương tự của dịch vụ
        do Website cung cấp cho một bên thứ ba nếu không được sự đồng ý của
        Website trong bản Điều khoản này.
      </p>
      <p>
        g. Người dùng không được hành động gây mất uy tín của Website dưới mọi
        hình thức như gây mất đoàn kết giữa các thành viên bằng cách sử dụng tên
        đăng ký thứ hai, thông qua một bên thứ ba hoặc tuyên truyền, phổ biến
        những thông tin không có lợi cho uy tín của Website.
      </p>
      <h4>3.3 Quyền và nghĩa vụ của Hoc10.vn</h4>
      <p>a) Quyền của Ban quản lý Website Hoc10.vn</p>
      <p>
        - Chúng tôi được phép đăng tải các thông tin giới thiệu và xúc tiến nhằm
        đạt được mục tiêu chung của dự án
      </p>
      <p>
        - Đồng thời chúng tôi không cho phép các tổ chức, cá nhân khác được phép
        đăng tải, quảng bá sản phẩm tại Hoc10.vn
      </p>
      <p>
        - Chúng tôi có quyền thay đổi Điều khoản sử dụng, chính sách bảo mật và
        các chính sách khác...Trước khi thay đổi chúng tôi sẽ thông báo tới
        người sử dụng trước ít nhất 07 ngày.
      </p>
      <p>b) Nghĩa vụ của Ban quản lý Website Hoc10.vn</p>
      <p>- Đảm bảo sự ổn định của website.</p>
      <p>
        - Cập nhật thông tin đầy đủ và kịp thời về các quy định, chính sách của
        website, các gói dịch vụ trên website.
      </p>
      <p>
        - Đảm bảo thông tin đăng tải trên website phù hợp với quy định pháp
        luật, thuần phong mỹ tục; đảm bảo các dịch vụ không vi phạm các quy định
        của pháp luật.
      </p>
      <p>
        - Có các quy định, hướng dẫn chi tiết cho người dùng về cách thức tham
        gia khóa học.
      </p>
      <h4>4. Quy định về Miễn trừ trách nhiệm và Quyền sở hữu trí tuệ:</h4>
      <h5>4.1. Các quy định về việc miễn trừ trách nhiệm</h5>
      <p>
        Thông tin hiển thị tại website này không đi kèm bất kỳ đảm bảo hay cam
        kết trách nhiệm từ phía Hoc10.vn về sự phù hợp của các cuốn sách người
        dùng đã mua
      </p>
      <p>
        Hoc10.vn từ chối trách nhiệm hay đưa ra đảm bảo rằng website sẽ không có
        lỗi vận hành, an toàn, không bị gián đoạn hay tính chính xác, đầy đủ và
        đúng hạn của các thông tin hiển thị
      </p>
      <p>
        Khi truy cập vào website này, Bạn mặc nhiên đồng ý rằng các nhà cung cấp
        khác cùng với đối tác liên kết, nhân viên của họ không chịu bất cứ trách
        nhiệm nào liên quan đến thương tật, mất mát, khiếu kiện, thiệt hại trực
        tiếp hoặc gián tiếp do không lường trước hoặc do hậu quả để lại dưới bất
        cứ hình thức nào phát sinh từ việc:
      </p>
      <p>(1) Sử dụng các thông tin trên website này;</p>
      <p>(2) Các truy cập kết nối từ website này;</p>
      <p>(3) Đăng ký thành viên, sử dụng kích hoạt sách tại website này</p>
      <p>
        Các điều kiện và hạn chế nêu trên chỉ có hiệu lực trong khuôn khổ pháp
        luật hiện hành.
      </p>
      <h5>4.2. Quyền sở hữu trí tuệ</h5>
      <p>
        Website này và mọi nội dung xếp đặt, hiển thị đều thuộc sở hữu và là tài
        sản độc quyền khai thác của Hoc10.vn. và các nhà cung cấp có liên quan
        khác. Mọi sử dụng, trích dẫn phải không gây thiệt hại cho Hoc10.vn và
        đều phải tuân thủ các điều kiện sau:
      </p>
      <p>(1) Chỉ sử dụng cho mục đích cá nhân, phi thương mại;</p>
      <p>
        (2) Các sao chép hoặc trích dẫn đều phải giữ nguyên dấu hiệu bản quyền
        hoặc các yết thị về quyền sở hữu trí tuệ như đã thể hiện trong phiên bản
        gốc; và tất cả các nội dung được cung cấp tại website này không được
        phép nhân bản, hiển thị, công bố, phổ biến, đưa tin tức hay lưu hành cho
        bất cứ ai, dưới bất kỳ hình thức nào, kể cả trên các website độc lập
        khác mà không được sự chấp thuận của Hoc10.vn
      </p>
      <h4>5. Điều chỉnh và sửa đổi:</h4>
      <p>
        Hoc10.vn bảo lưu quyền thay đổi, chỉnh sửa hoặc chấm dứt hoạt động của
        Website này vào bất cứ thời điểm nào.
      </p>
      <h4>6. Giải quyết khiếu nại, tranh chấp:</h4>
      <p>
        - Tranh chấp giữa website với người dùng trong quá trình thực hiện hợp
        đồng phải được giải quyết trên cơ sở các điều khoản của hợp đồng công bố
        tại website vào thời điểm giao kết hợp đồng và quy định của pháp luật có
        liên quan.
      </p>
      <p>
        - Việc giải quyết tranh chấp phải thông qua thương lượng giữa các bên,
        hòa giải, trọng tài hoặc tòa án theo các thủ tục, quy định hiện hành về
        giải quyết tranh chấp.
      </p>
      <p>- Giải quyết khiếu nại, tranh chấp trên website:</p>
      <p>
        Người dùng cần phải thường xuyên đọc và tuân theo các Chính sách và Quy
        định đang được đăng trên web để có thể hiểu và thực hiện được các Chính
        sách và Quy định tại thời điểm đó.
      </p>
      <p>
        Trong quá trình kích hoạt sách, nếu có bất kì sự cố nào trong quá trình
        sử dụng dịch vụ, xác nhận thông tin thanh toán. Người dùng sẽ liên hệ
        trực tiếp tới bộ phận hỗ trợ người dùng để được giải quyết một cách
        nhanh chóng và kịp thời.
      </p>
      <p>
        Khi có bất kì tranh chấp nào khác phát sinh trong quá trình giao dịch
        trên website và các bên có liên quan như thương nhân cung cấp dịch vụ
        ngân hàng cung cấp dịch vụ thanh toán và người dùng sử dụng dịch vụ sẽ
        cử đại diện tham gia vào quá trình giải quyết.
      </p>
      <p>
        - Khi có bất kỳ khiếu nại nào của người dùng, vui lòng thông báo cho
        website. Website sẽ tiến hành xác minh và giải quyết các khiếu nại trong
        thời gian 07 ngày.
      </p>
      <p>
        Các bên phải thực hiện đúng trách nhiệm, quyền và nghĩa vụ của mình
        trong quá trình giải quyết tranh chấp. Nếu có thiệt hại xảy ra, các bên
        sẽ thỏa thuận cùng nhau trên tinh thần hợp tác để tìm ra biện pháp giải
        quyết hợp lý nhất. Nếu không đi đến được một sự thống nhất chung về ý
        chí trong quá trình giải quyết tranh chấp, thì sẽ đưa vụ việc tranh chấp
        ra cơ quan có thẩm quyền giải quyết.
      </p>
      <h4>Mọi thông tin cần thiết vui lòng liên hệ:</h4>
      <p>CÔNG TY CỔ PHẦN ONE PERCENT</p>
      <p>
        Địa chỉ: Tầng 2, tòa nhà HH-N01, dự án Gold Season, Số 47 đường Nguyễn
        Tuân, Phường Thanh Xuân Trung, Quận Thanh Xuân, Tp. Hà Nội
      </p>
      <p>
        Mã số thuế: 0109621809 do Sở Kế hoạch và Đầu tư TP Hà Nội, cấp ngày
        06/05/2021
      </p>

      <p>Người chịu trách nhiệm chính: Ông Đào Xuân Hoàng</p>

      <p>
        Hotline: <a href="tel:02473098866">02473098866</a>
      </p>

      <p>
        Email: <a href="mailto:contact@hoc10.vn">contact@hoc10.vn</a>
      </p>

      <p>Xin chân thành cảm ơn!</p>
    </article>
  );
}
