import Modal from "react-bootstrap/Modal";
import imageSuccess from "edu_lms_v2/assets/img/img-success.svg";

export default function ModalActiveBookSuccess(props) {
  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src={imageSuccess} alt="" />
        </div>
        <div className="text mb-2">Kích hoạt thành công</div>
        <button
          title="Tiếp tục đọc sách"
          className="btn-pr flex-center btn-access btn-access-active mt-5"
          onClick={props.onHide}
        >
          Tiếp tục
        </button>
      </div>
    </Modal>
  );
}
