import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";
import { onDispatchDataInfo } from "../../modules/SignIn/actions";
import * as TEXT from "../../constants/text";
import {
  postUpdateUserEmail,
  getCreateAccountTeacher,
} from "../../services/info/info";
import LabelInput from "../LabelInput";
import {
  onDispatchTogglePopupSuccess,
  onDispatchShowLoading,
  onDispatchTypePopup,
  onDispatchShowPopupActivateBook,
} from "../../modules/App/actions";
import * as PATH from "../../../consts/routePaths";
import * as TYPE from "../../constants/type";
import { REG_EXP_EMAIL, APP_ID } from "../../constants/type";
import { setItemUserInfo } from "../../modules/selection";
import { sendOtpVerifyPw } from "../../services/forgotPw/forgotPw";
import { setEventGTM } from "../../constants/googleTagManager";
import { UPDATE_USER_INFORMATION } from "../../constants/eventNameGTM";

const FromCommunications = (props) => {
  const dataFrom = JSON.parse(localStorage.getItem("dataForm"));
  const phone = dataFrom ? dataFrom?.phone : props.userInfo.phone || "";
  const email = dataFrom ? dataFrom?.email : props.userInfo.email || "";
  const { handleSubmit, register, errors } = useForm();
  const [phoneError, setStatePhoneError] = useState(false);
  const [emailError, setStateEmailError] = useState(false);
  const [numberPhone, setStateNumberPhone] = useState(phone);
  const [valueEmail, setStateValueEmail] = useState(email);
  const [isFocusEmail, setStateFocusEmail] = useState(false);
  const history = useHistory();
  const isShowButton = localStorage.getItem("status") === 1 ? true : false;
  const [messageError, onSetMessageError] = useState();
  const [type, setType] = useState("");

  useEffect(() => {
    setStateNumberPhone(phone);
  }, [phone]);
  useEffect(() => {
    setStateValueEmail(email);
    email ? setType("update") : setType("add");
  }, [email]);

  const onValidateEmail = (value) => {
    return REG_EXP_EMAIL.test(value);
  };
  const onValueEmail = (value) => {
    const resultValueEmail = onValidateEmail(value);
    setStateEmailError(!resultValueEmail);
    setStateValueEmail(value);
  };
  const onNumberPhone = (phone) => {
    setStateNumberPhone(phone);
  };
  const onConfirm = (data) => {
    props.onDispatchShowLoading(true);
    const userInfo = {
      email: valueEmail,
      phone: numberPhone,
      is_web: 1,
      app_id: APP_ID,
    };
    const dataUserInfo = {
      ...props.userInfo,
      email: valueEmail,
      phone: numberPhone,
    };

    if (isShowButton) {
      setStatePhoneError(false);
      postUpdateUserEmail(userInfo).then((res) => {
        props.onDispatchShowLoading(false);
        if (res.data.status === "success") {
          props.onDispatchDataInfo(userInfo);
          setItemUserInfo(dataUserInfo);
          setStatePhoneError("");
        } else {
          setStatePhoneError(res.data.message);
        }
      });
    }
    if (!isShowButton) {
      if (
        localStorage.getItem("pathQuiz") === PATH.ROUTE_PATH_TRAINING_TEST_2
      ) {
        const dataUserInfo = {
          ...props.userInfo,
          email: valueEmail,
          phone: numberPhone,
        };
        const newUserInfo = {
          ...props.userInfo,
          email: valueEmail,
          phone: numberPhone,
          list_grade_subject: JSON.parse(
            localStorage.getItem("listGradeSubject")
          ),
        };
        props.onDispatchDataInfo(dataUserInfo);
        setItemUserInfo(dataUserInfo);
        props.onDispatchShowLoading(true);
        getCreateAccountTeacher(newUserInfo).then((res) => {
          props.onDispatchShowLoading(false);
          if (res.data.status === "success") {
            localStorage.setItem("token", res.data.data.access_token);
            localStorage.setItem("user_id", res.data.data.user_id);
            setStatePhoneError("");
            props.onDispatchTypePopup(TYPE.TYPE_POPUP_CHANGE_PW);
            props.onDispatchShowPopupActivateBook(true);
            localStorage.setItem("isEventUpdateInfo", false);
          }
          if (res.data.code === 302) {
            localStorage.setItem("result_data", JSON.stringify(res.data.data));
            let dataPost = { type: "", is_web: 1 };
            if (Number(res.data.data.type) === 1) {
              dataPost = {
                ...dataPost,
                type: TYPE.SEND_OTP_PW_BY_EMAIL,
                email: valueEmail,
                app_id: TYPE.APP_ID,
              };
            }
            if (Number(res.data.data.type) === 2) {
              dataPost = {
                ...dataPost,
                type: TYPE.SEND_OTP_PW_BY_PHONE,
                phone: numberPhone,
                app_id: TYPE.APP_ID,
              };
            }
            sendOtpVerifyPw(dataPost)
              .then((res) => {
                props.onDispatchShowLoading(false);
                if (res.data.status === "fail") {
                  onSetMessageError(res.data.message);
                }
                if (res.data.status === "success") {
                  props.onDispatchTypePopup(TYPE.TYPE_POPUP_OTP);
                  props.onDispatchShowPopupActivateBook(true);
                }
              })
              .catch((errors) => {
                console.log(errors);
              });
          }
          if (res.data.status === "fail") {
            setStatePhoneError(res.data.message);
          }
        });
      } else {
        setStatePhoneError(false);
        props.onDispatchShowLoading(true);
        postUpdateUserEmail(userInfo).then((res) => {
          props.onDispatchShowLoading(false);
          if (res.data.status === "success") {
            props.onDispatchDataInfo(userInfo);
            setItemUserInfo(dataUserInfo);
            setStatePhoneError("");
            let dataSuccessConfig;
            if (localStorage.getItem("isEventUpdateInfo")) {
              dataSuccessConfig = {
                status: true,
                title: [
                  TEXT.TEXT_FINISH_UPDATE_INFO_1,
                  TEXT.TEXT_FINISH_UPDATE_INFO_3,
                ],
                url: PATH.ROUTE_PATH_LIST_QUIZ,
                labelButton: TEXT.TEXT_VALUE_BUTTON_FINISH_UPDATE_INFO_2,
              };
            } else {
              dataSuccessConfig = {
                status: true,
                title: [
                  TEXT.TEXT_FINISH_UPDATE_INFO_1,
                  TEXT.TEXT_FINISH_UPDATE_INFO_2,
                ],
                url: PATH.ROUTE_PATH_GENERAL,
                labelButton: TEXT.TEXT_VALUE_BUTTON_FINISH_UPDATE_INFO,
              };
            }
            props.onDispatchTogglePopupSuccess(dataSuccessConfig);
            localStorage.setItem("isEventUpdateInfo", false);
          }
          if (res.data.status === "fail") {
            setStatePhoneError(res.data.message);
            props.onDispatchShowLoading(false);
          }
          setEventGTM({
            event: UPDATE_USER_INFORMATION,
            type: type,
            update_detail: "contact_info",
            successful: Boolean(localStorage.getItem("user_id")),
          });
        });
      }
    }
  };
  return (
    <form className="from-info p-3" onSubmit={handleSubmit(onConfirm)}>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="form-group position-relative">
            <i
              style={{ top: "2.5rem", position: "absolute", left: "12px" }}
              className={`${
                isFocusEmail ? "monkey-color-violet" : ""
              } fa fa-envelope`}
              aria-hidden="true"
            ></i>
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_EMAIL_UPDATE_INFORMATION}
              status={true}
            />
            <input
              style={{ height: "44px", paddingLeft: "40px" }}
              className="form-control rounded-pill"
              type="text"
              name="email"
              placeholder="contact@hoc10.com"
              autoComplete="off"
              value={valueEmail}
              ref={register({ required: true })}
              onChange={(e) => onValueEmail(e.target.value)}
              onFocus={() => setStateFocusEmail(true)}
              onBlur={() => setStateFocusEmail(false)}
            />
            <p className="monkey-color-red monkey-mt-15">
              {!errors.email && emailError && "Email không đúng định dạng"}
              {errors.email && "Vui lòng nhập Email"}
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_PHONE}
              status={false}
            />
            <PhoneInput
              country={"vn"}
              value={numberPhone}
              onChange={(phone) => onNumberPhone(phone)}
              placeholder="Nhập số điện thoại"
              className="form-control rounded-pill"
              onlyCountries={["vn"]}
              defaultCountry="vn"
              disableAreaCodes={true}
              disableCountryCode={true}
              disableDropdown={true}
            />
          </div>
          <p className="monkey-color-red monkey-mt-15">
            {!messageError && phoneError && phoneError}
            {messageError && messageError}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <input
            style={{ height: "44px" }}
            className="btn monkey-bg-violet monkey-color-white rounded-pill mr-2 hvr-registration-white"
            type="submit"
            disabled={_.isEmpty(valueEmail) || emailError}
            value={isShowButton ? TEXT.TEXT_DONE : TEXT.TEXT_DONE_2}
          />
        </div>
      </div>
    </form>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchDataInfo,
      onDispatchTogglePopupSuccess,
      onDispatchShowLoading,
      onDispatchTypePopup,
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(FromCommunications);
