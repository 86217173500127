import { useState, useEffect } from "react";
import PersonalInformation from "./PersonalInformation";
import SchoolInformation from "./SchoolInformation";
import ClassInformation from "./ClassInformation";
import Avatar from "./Avatar";
import ChangePassword from "./ChangePassword";
import { getUserInfo } from "edu_lms/services/signIn/signIn";
import { getDataUrlListProvince } from "edu_lms/services/info/info";
import { LIST_PROVINCE_ALL, VERIFY_EMAIL } from "edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { onResultUserInfo } from "edu_lms/modules/selection";
import SEO from "../../../edu_lms/components/Seo";
import { pages } from "../../../edu_lms/components/Seo/meta";

const tabs = [
  {
    title: "Cá nhân",
    icon: "icon icon-user",
    href: "#user",
    selected: true,
  },
  {
    title: "Trường học",
    icon: "icon icon-school",
    href: "#school",
    selected: false,
  },
  {
    title: "Lớp học",
    icon: "icon icon-class",
    href: "#class",
    selected: false,
  },
  {
    title: "Ảnh đại diện",
    icon: "icon icon-avt",
    href: "#avt",
    selected: false,
  },
  {
    title: "Đổi mật khẩu",
    icon: "icon icon-password",
    href: "#password",
    selected: false,
  },
];
export default function UpdateInformation() {
  useScrollToTop();

  const [nameTab, setNameTab] = useState(tabs[0].title);
  const [dataUser, setDataUser] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const userInfo = onResultUserInfo();
  const isVerifyEmail = dataUser?.email_verified === VERIFY_EMAIL;

  useEffect(() => {
    getDataUrlListProvince(LIST_PROVINCE_ALL).then((res) => {
      if (res.data.status === "success") {
        setListProvince(res.data.data);
        getUserInfoApi();
      }
    });
  }, []);

  const getUserInfoApi = () => {
    if (localStorage.getItem("token")) {
      getUserInfo().then((res) => {
        if (res.data.status === "success") {
          setDataUser(res.data.data);
        }
      });
    }
  };

  return (
    <main>
      <SEO data={pages.update_infomation} />
      <div className="hoc10-breadcrumb bg-gray">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Cập nhật thông tin</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {nameTab}
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-information bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-3">
              <ul className="tab nav hoc10-tab">
                {tabs.map((tab, index) => (
                  <li key={index}>
                    <a
                      href={tab.href}
                      title={tab.title}
                      data-toggle="pill"
                      role="tab"
                      aria-selected={tab.selected}
                      className={tab.selected ? "active" : ""}
                      onClick={() => setNameTab(tab.title)}
                    >
                      <i className={tab.icon}></i>
                      <span>{tab.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-9 col-9">
              <div className="tab-content hoc10-tab-content">
                <PersonalInformation dataUser={dataUser} />
                <SchoolInformation
                  dataUser={dataUser}
                  listProvince={listProvince}
                  resetForm={getUserInfoApi}
                />
                <ClassInformation
                  dataUser={dataUser}
                  isVerifyEmail={isVerifyEmail}
                />
                <Avatar avatar={dataUser.avatar} />
                {userInfo?.show_change_pw && <ChangePassword />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
