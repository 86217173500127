import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import * as PATH from "consts/routePaths";
import imgPopup from "edu_lms_v2/assets/img/banner_luyen_thi.png";

const AlertNewExercise = ({ show, onHide }) => {
  const history = useHistory();
  const dontShowAgain = sessionStorage.getItem("dontShowAlertEvents");

  const handleClick = () => {
    sessionStorage.setItem("dontShowAlertEvents", true);
    history.push(PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST);
  };
  return (
    <>
      {!JSON.parse(dontShowAgain) && (
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          className="custom-modal"
        >
          <i
            class="fa fa-times position-absolute cursor"
            aria-hidden="true"
            style={{ zIndex: 10, top: "2%", right: "2%", fontSize: "22px" }}
            onClick = {() => {
                sessionStorage.setItem("dontShowAlertEvents", true);
                onHide();
              }}
          />
          <Modal.Body className="p-0">
            <img src={imgPopup} />
            <ButtonPractice
              className="btn-pr flex-center position-absolute"
              variant="outline-none"
              onClick={() => handleClick()}
            >
              Ôn luyện ngay
            </ButtonPractice>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

const ButtonPractice = styled(Button)`
  background: #ff7707;
  top: 85%;
  left: 40%;
  font-size: 18px;

  @media screen and (max-width: 820px) {
    top: 80%;
    left: 35%;
    font-size: 14px;
  }
`;

export default AlertNewExercise;
