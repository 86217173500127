import React from "react";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";

export default function HeadingViewDetail({
  listAllSubjects = [],
  params = {},
}) {
  const subject = listAllSubjects[params.subjectType];
  const level = listAllSubjects[params.levelType];
  const classRoom = listAllSubjects[params.classType];

  return (
    <ul className={`breadcrumb ${styles.heading}`}>
      <li className="breadcrumb-item">
        <Link to="">Trang chủ</Link>
      </li>
      <li className="breadcrumb-item" aria-current="page">
        <Link to={PATH.ROUTE_BLOG_KNOWLEDGE}> Kiến thức </Link>
      </li>
      <li className="breadcrumb-item" aria-current="page">
        <Link to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${level.slug}/`}>
          {level.name}
        </Link>
      </li>
      <li className="breadcrumb-item" aria-current="page">
        <Link
          to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${level.slug}/${classRoom.slug}/`}
        >
          {classRoom.name}
        </Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        {subject.name}
      </li>
    </ul>
  );
}
