import { useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";

import * as PATH from "consts/routePaths";
import * as TEXT from "edu_lms/constants/text";
import { cleanLocalStorage } from "edu_lms/constants/general";
import { TIME_OUT_LOGOUT } from "edu_lms/constants/type";
import {
  onDispatchShowPopupActivateBook,
  onDispatchTogglePopupSuccess,
} from "edu_lms/modules/App/actions";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import Header from "./Header";
import Footer from "./Footer";
import SEO from "../../../edu_lms/components/Seo";

export default function Hoc10Layout({ children, showHeader, showFooter }) {
  const dispatch = useDispatch();
  const idleTimerRef = useRef();
  const [openActiveBook, setOpenActiveBook] = useState(false);
  const clickActiveBook = (event) => {
    event?.preventDefault();
    setOpenActiveBook(true);
  };

  const onCloseActiveBook = () => {
    setOpenActiveBook(false);
  };

  const onIdle = () => {
    const isToken = localStorage.getItem("token");
    if (isToken) {
      cleanLocalStorage();
      dispatch(onDispatchShowPopupActivateBook(false));
      dispatch(
        onDispatchDataInfo({
          job_id: "",
          full_name: "",
          birth_day: "",
          gender_id: "",
          email: "",
          phone: "",
          province_id: "",
          district_id: "",
          ward_id: "",
          school_id: "",
          list_grade_subject: [],
          show_change_pw: false,
          notification: 1,
          role_id: 0,
        })
      );
      dispatch(
        onDispatchTogglePopupSuccess({
          type: 1,
          status: true,
          title: [TEXT.TITLE_POPUP_NOT_SEEN_ANY_ACTIVITY],
          url: PATH.ROUTE_PATH_SIGN_IN,
          labelButton: TEXT.BUTTON_PLEASE_RE_LOGIN,
        })
      );
    }
  };
  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={TIME_OUT_LOGOUT}
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
	    <SEO></SEO>
      {showHeader && (
        <Header
          openActiveBook={openActiveBook}
          onCloseActiveBook={onCloseActiveBook}
        />
      )}
      {children}
      {showFooter && <Footer clickActiveBook={clickActiveBook} />}
    </>
  );
}
