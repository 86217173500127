import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import {
  getAllClassroom,
  getQuestionAndList,
  onPostMatchUserHoc10,
} from "edu_lms/services/teachingManager";
import * as PATH from "consts/routePaths";
import PopupMatchUser from "./PopupMatchUser";
import { onResultUserInfo } from "edu_lms/modules/selection";
import { DRAFT, JOB_TEACHER, PLAY_MODE } from "edu_lms/constants/type";
import { APP_ID as HOC10_APP_ID } from "edu_lms/constants/type";
import PopupConfirmSelectedUser from "./PopupConfirmSelectedUser";

const PUBLIC_QUESTION = 1;

export const ListStudentDoingHomeWork = () => {
  const history = useHistory();
  const [historyAssignEx] = useState(
    localStorage.getItem("student_info") || "[]"
  );
  const [showPopupMatchUser, setShowPopupMatchUser] = useState(false);
  const [showPopupMatchUserSuccess, setShowPopupMatchUserSuccess] =
    useState(false);
  const [userInClassroom, setUserInClassroom] = useState({});
  const [showConfirmSelected, setShowConfirmSelected] = useState(false);
  const { id } = useParams();
  const [listStudent, setListStudent] = useState([]);
  const [question, setQuestion] = useState({});
  const token = localStorage.getItem("token");
  const userInfo = onResultUserInfo();
  const getListStudent = () => {
    const payloadStudent = {
      classRoomQuestionSetId: id,
    };
    getQuestionAndList(payloadStudent)
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.data;
          setListStudent(data.list_user);
          setQuestion(data.question_set);
          onCheckMatched(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmPopupEx = (dataStudents) => {
    const parseAssignEx = JSON.parse(historyAssignEx);
    const checkHasStudent = dataStudents.find(
      (student) => student.id === parseAssignEx?.id
    );

    if (checkHasStudent) {
      setUserInClassroom(checkHasStudent);
      setShowConfirmSelected(true);
    }
  };

  const onCheckMatched = (data) => {
    if (token) {
      getAllClassroom()
        .then((res) => {
          if (res.data.status === "success") {
            const checkMatched = res.data.data.find(
              (item) => item.class_room_id === data.class_room_id
            );

            if (checkMatched?.role_id === JOB_TEACHER) return;
            if (checkMatched) {
              saveDataToLocal(userInClassroom);
              redirectExercise(data.question_set.id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (!token) {
      handleConfirmPopupEx(data.list_user);
    }
  };

  useEffect(() => {
    getListStudent();
  }, []);

  const doingHomeWork = (student) => {
    saveDataToLocal(student);
    if (token) {
      return setShowPopupMatchUser(true); // show popup match user Hoc10
    }

    setShowConfirmSelected(true);
  };

  const saveDataToLocal = (student) => {
    const rawStudent = JSON.stringify(student);
    localStorage.setItem("student_info", rawStudent);
  };

  const handleConfirmSelected = () => {
    saveDataToLocal(userInClassroom);
    redirectExercise(question.id);
  };

  const redirectExercise = (id = question.id) => {
    if (question.type === 1) {
      return history.push(`${PATH.ROUTE_PATH_EXERCISE}/${id}`);
    }

    return history.push(`${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST}${id}`);
  };

  const syncUser = () => {
    const data = {
      user_class_room_id: userInClassroom.id,
    };
    onPostMatchUserHoc10(data)
      .then((res) => {
        if (res.data.status === "success") {
          setShowPopupMatchUserSuccess(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const handleTitle = (question) => {
    let questionSetTitle;
    if (question.type === PLAY_MODE.PRACTICE) {
      questionSetTitle =
        question.status === PUBLIC_QUESTION
          ? question?.title
          : `${DRAFT} ${question?.title}`;
    }

    if (question.type === PLAY_MODE.EXAM) {
      questionSetTitle =
        question?.public_title ?? `${DRAFT} ${question?.title}`;
    }

    return questionSetTitle;
  };

  const handleShowTitle = () => {
    const product = Number(process.env.REACT_APP_APP_ID);
    if (product === HOC10_APP_ID)
      return (
        <div className="text-center mt-3 mb-5 monkey-fz-28 font-weight-bold monkey-f-header">
          Tên bài tập: &nbsp;
          <span className="monkey-color-orange">{handleTitle(question)}</span>
        </div>
      );
  };

  return (
    <main>
      <div>
        {handleShowTitle()}
        <div className="text-center monkey-fz-18 font-weight-bold monkey-color-orange monkey-f-header">
          Vui lòng chọn đúng tên mình để làm bài kiểm tra
        </div>
        <div className="pb-5 pt-3">
          <div className="container">
            <div className="row list-class mb-4">
              {listStudent.map((student, i) => {
                return (
                  <div key={i} className="col-md-6 col-lg-3 mb-2 mb-md-4">
                    <Student
                      className="cursor d-flex align-items-center p-2 monkey-f-header"
                      onClick={() => {
                        doingHomeWork(student);
                        setUserInClassroom(student);
                      }}
                    >
                      <AvatarWrapper className="mr-3">
                        <Avatar
                          src={student.avatar || "/assets/img/avt2.png"}
                          className="fit"
                          alt="Ảnh đại diện"
                        />
                      </AvatarWrapper>
                      <p className="font-weight-bold">{student.name}</p>
                    </Student>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <PopupMatchUser
        show={showPopupMatchUser}
        setShow={setShowPopupMatchUser}
        username={userInfo?.full_name}
        userInClassroom={userInClassroom}
        syncUser={syncUser}
        showPopupMatchUserSuccess={showPopupMatchUserSuccess}
        setShowPopupMatchUserSuccess={setShowPopupMatchUserSuccess}
        redirectExercise={redirectExercise}
      />

      <PopupConfirmSelectedUser
        userInClassroom={userInClassroom}
        show={showConfirmSelected}
        setShow={setShowConfirmSelected}
        handleConfirmSelected={handleConfirmSelected}
      />
    </main>
  );
};

const Student = styled.div`
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 1px #ccc;

  &:hover {
    background-color: #ff7707;
    color: #fff;
  }

  &.disabled {
    background-color: #ccc;
    pointer-events: none;
  }
`;
const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
`;
const Avatar = styled.img`
  border-radius: 90px;
`;
const HeaderStyle = styled.div`
  margin: 0 auto;
  max-width: 1150px;
  .monkey-logo {
    left: 20px;
    img {
      width: 100px;
    }
  }
`;
