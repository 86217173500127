import RecentExamItem from "./RecentExamItem";

export default function RecentExamQuestions({ title, data }) {
  return (
    <div className="hoc10-practice-recently">
      <div className="container">
        <div className="header">
          <h2 className="title">{title}</h2>
        </div>
        <div className="practice-list row">
          {data?.map((item) => (
            <RecentExamItem
              key={item.id}
              urlImage={item.urlImage}
              subjectName={item.subjectName}
              gradeName={item.gradeName}
              typeExam={item.typeExam}
              countCorrect={item.countCorrect}
              totalQuestion={item.totalQuestion}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
