import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import _ from "lodash";
import HeaderQuestion from "./HeaderQuestion";
import ContentAnswer from "./ContentAnswer";
import { styleWrapper } from "../../constants/styles";
import { transformSequencedAnswers, compareAnswerOrder } from "../../helpers/SQC_001";
import "./styles.scss";
import { TYPE_DISPLAY } from "../../constants/SQC_001";

const SQC_001 = (
  {
    gameData,
    hideResultAnswer = false,
    sequencedAnswersProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    theme,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;
  const transformAnswers = transformSequencedAnswers(answer.answers, answer.typeDisplay);
  const [sequencedAnswers, setSequencedAnswers] = useState([]);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    if (sequencedAnswers.length === 0) {
      setSequencedAnswers(transformAnswers);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);

   /**---------- Show sequenced answers result ----------**/
   useEffect(() => {
    if (sequencedAnswersProp.length > 0) {
      setSequencedAnswers(sequencedAnswersProp);
    }
  }, [sequencedAnswersProp, answer]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      const sortedOrderAnswers = answer.answers
        .sort(compareAnswerOrder)
        .map((sequencedAnswer) => ({ ...sequencedAnswer, isCorrect: true }));
      if (answer.typeDisplay !== TYPE_DISPLAY.Both) {
        setSequencedAnswers([sortedOrderAnswers]);
      } else {
        const sequencedAnswersLeft = sortedOrderAnswers.filter((_, index) => index % 2 === 0);
        const sequencedAnswersRight = sortedOrderAnswers.filter((_, index) => index % 2 === 1);
        setSequencedAnswers([sequencedAnswersLeft, sequencedAnswersRight])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer, answer])

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    let numberOfCorrectAnswers = 0, isCorrect = false, resultSequencedAnswers = null;

    if (answer.typeDisplay !== TYPE_DISPLAY.Both) {
      const resultSequencedAnswersItem = sequencedAnswers[0].map(
        (sequencedAnswer, index) => {
          const resultOrder = sequencedAnswer.index;
          const isCorrectItem = resultOrder === index + 1;
          if (isCorrectItem) {
            numberOfCorrectAnswers++;
          }
          return {
            ...sequencedAnswer,
            isCorrect: isCorrectItem,
          };
        }
      );

      resultSequencedAnswers = [resultSequencedAnswersItem]
      isCorrect = numberOfCorrectAnswers === sequencedAnswers[0].length;
    } else {
      const sequencedAnswersLeft = sequencedAnswers[0];
      const sequencedAnswersRight = sequencedAnswers[1];
      const maxLengthSequenceCol = Math.max(sequencedAnswersLeft.length, sequencedAnswersRight.length);
      let transformSequenceAnswers = [];
      for (let i = 0; i < maxLengthSequenceCol; i++) {
        transformSequenceAnswers.push(sequencedAnswersLeft[i]);
        transformSequenceAnswers.push(sequencedAnswersRight[i]);
      }
      transformSequenceAnswers = _.compact(transformSequenceAnswers);

      const resultSequencedAnswers1Dimension = transformSequenceAnswers.map(
        (sequencedAnswer, index) => {
          const resultOrder = sequencedAnswer.index;
          const isCorrectItem = resultOrder === index + 1;
          if (isCorrectItem) {
            numberOfCorrectAnswers++;
          }
          return {
            ...sequencedAnswer,
            isCorrect: isCorrectItem,
          };
        }
      );

      const resultSequencedAnswers2Dimension = sequencedAnswers.map((sequencedAnswersItem) => {
        return sequencedAnswersItem.map((answerItem) => {
          const answerItemIsCorrect = (resultSequencedAnswers1Dimension.find(resultAnswer => resultAnswer.index === answerItem.index) || {}).isCorrect;
          return {
            ...answerItem,
            isCorrect: answerItemIsCorrect
          }
        })
      });
      
      resultSequencedAnswers = resultSequencedAnswers2Dimension;
      isCorrect = numberOfCorrectAnswers === sequencedAnswersLeft.length + sequencedAnswersRight.length;
    }

    setSequencedAnswers(resultSequencedAnswers);
    onComplete({ isCorrect, sequencedAnswers: resultSequencedAnswers });
  };

  const handleResetAnswer = () => {
    setIsViewOnly(false);
    setSequencedAnswers([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleDragAnswersEnd = (sortedAnswers) => {
    let shadowSortedAnswers = [...sortedAnswers];
  
    shadowSortedAnswers = shadowSortedAnswers.map((sortedAnswers) =>
      sortedAnswers.map((answer) => ({ ...answer, isCorrect: null }))
    );

    setSequencedAnswers(shadowSortedAnswers);
    onPlaying(false);
  };

  return (
    <div className="SQC_001__wrapper" style={styleWrapper}>
      <HeaderQuestion question={question} theme={theme} />
      <ContentAnswer
        answer={answer}
        sequencedAnswers={sequencedAnswers}
        isViewOnly={isReadOnly || isViewOnly}
        hideResultAnswer={hideResultAnswer}
        onDragAnswersEnd={handleDragAnswersEnd}
      />
    </div>
  );
};

export default forwardRef(SQC_001);
