import _ from "lodash";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { DOCUMENT_CATEGORY_TIET_DAY_MINH_HOA } from "consts/book";
import Intro from "./Intro";

export default function SectionTietDayMinhHoa() {
    const documents = useBookStore((state) => state.documents);
    const subjectId = useAppStore((state) => state.subjectId);
    const gradeId = useAppStore((state) => state.gradeId);
    const documentsOfCurrentGrade = _.get(documents, [gradeId], []);
    
    const gtsDocuments = documentsOfCurrentGrade.filter(
        (item) =>
            item.category === DOCUMENT_CATEGORY_TIET_DAY_MINH_HOA &&
            item.subject_id === subjectId
    );
    
    return (
        !_.isEmpty(gtsDocuments) && (
            <div className="col-md-12 result">
                <Intro
                    data={gtsDocuments}
                    categoryBook="Tiết dạy minh họa"
                    col="col-6 col-lg-3"
                    background="#fff"
                />
            </div>
        )
    );
}
