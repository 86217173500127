import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ReactAudioPlayer from "react-audio-player";
import styled from "styled-components";
import { URL_AUDIO } from "edu_lms/constants/type";

export const AudioType = {
  Primary: "primary",
  Secondary: "secondary",
};

const Audio = ({ variant, isFullSrc, src, ...props }) => {
  if (!src) return null;

  if (variant === AudioType.Primary) {
    return (
      <AudioWrapper>
        <AudioPlayer
          src={isFullSrc ? src : `${URL_AUDIO}${src}`}
          autoPlay={false}
          {...props}
        />
      </AudioWrapper>
    );
  }

  return (
    <ReactAudioPlayer
      src={isFullSrc ? src : `${URL_AUDIO}${src}`}
      autoPlay={false}
      controls
      {...props}
    />
  );
};
export default Audio;

const AudioWrapper = styled.div`
  display: flex;
  justify-content: center;
  .rhap_progress-section {
    display: none;
  }
  .rhap_additional-controls {
    display: none;
  }
  .rhap_volume-controls {
    display: none;
  }
  .rhap_forward-button {
    display: none;
  }
  .rhap_rewind-button {
    display: none;
  }
  .rhap_container {
    width: 50px;
    box-shadow: none;
    background: none;
  }
`;
