import dayjs from "edu_lms/libs/dayjs";
import { DEFAULT_TOTAL_PAGE } from "./constant";

export const timeUpdate = (times) => {
  return dayjs.unix(times).format("DD/MM/YYYY");
};

export const getTotalPage = (width) => {
  let totalPage = DEFAULT_TOTAL_PAGE;
  if (width < 992) {
    totalPage = 6;
  }
  return totalPage;
};
