import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import avatarDefault from "../../assets/img/avt2.png";
import { postUpdateUserInformation } from "edu_lms/services/info/info";
import { postUploadImageQuestion } from "edu_lms/services/question";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { UPDATE_USER_INFORMATION } from "edu_lms/constants/eventNameGTM";

export default function Avatar(props) {
  const dispatch = useDispatch();
  const userInfo = onResultUserInfo();
  const [data, setData] = useState();
  const [imgData, setImgData] = useState();
  const [img, setImg] = useState();
  const [isFormatTrue, setIsFormatTrue] = useState(true);
  const { register, handleSubmit, errors, reset } = useForm();
  const typeUpdate = props.avatar ? "update" : "add";

  useEffect(() => {
    setData(props.avatar);
  }, [props.avatar]);
  useEffect(() => {
    setImg(data);
  }, [data]);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      if (
        (e.target.files[0].size < 5242880 &&
          e.target.files[0].type === "image/jpeg") ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/png"
      ) {
        setImgData(e.target.files[0]);
        setImg(URL.createObjectURL(e.target.files[0]));
        setIsFormatTrue(true);
      } else {
        setIsFormatTrue(false);
        reset();
      }
    }
  };

  const onUpdateAvt = () => {
    if (imgData) {
      const data = new FormData();
      data.append("file", imgData);
      data.append("folder_path", "upload/demo");
      data.append("description", "upload image question");
      data.append("auto_file_name", 1);
      postUploadImageQuestion(data).then((res) => {
        const newDataUserInfo = { ...userInfo, avatar: res.data.data.link };
        setData(res.data.data.link);
        dispatch(onDispatchDataInfo({ avatar: res.data.data.link }));
        setItemUserInfo(newDataUserInfo);
        postUpdateUserInformation({ avatar: res.data.data.link }).then(
          (res) => {
            let resetSuccessful = false;
            if (res.data.status === "success") {
              resetSuccessful = true;
              toast.success("Cập nhật avatar thành công!");
            }
            if (res.data.status === "fail") {
              toast.error("Cập nhật avatar không thành công!");
            }
            reset();
            setEventGTM({
              event: UPDATE_USER_INFORMATION,
              type: typeUpdate,
              update_detail: "avatar",
              successful: resetSuccessful,
            });
          }
        );
      });
    }
  };

  const onCancel = () => {
    setImgData();
    setImg(data);
  };

  return (
    <div id="avt" className="tab-pane fade" role="tabpanel">
      <h2 className="title">Ảnh đại diện</h2>
      <div className="box-upload pr">
        <div id="image-holder" className="image-holder flex">
          <img
            src={img && img !== "null" ? img : avatarDefault}
            className="fit"
            alt="Ảnh đại diện"
          />
        </div>
        <form onSubmit={handleSubmit(onUpdateAvt)}>
          <div className="upload pr">
            <input
              type="file"
              name="upload_avt"
              onChange={onChangePicture}
              ref={register({ required: true })}
              className="input-upload-avatar cursor"
            />
            <button type="button">Chọn ảnh mới</button>
          </div>
          <p className="text-danger">
            {errors.upload_avt?.type === "required" &&
              !data &&
              "Vui lòng chọn một ảnh"}
            {isFormatTrue === false && "Không thể chọn tệp tin này"}
          </p>
          <div className="note">
            <p>Định dạng: .*png, .*jpeg, .*jpg</p>
            <p>Dung lượng tối đa: 5 MB</p>
          </div>
          <div className="box-btn flex">
            <button
              type="submit"
              title="Cập nhật"
              className="btn-update btn-pr flex-center"
              style={{
                width: "120px",
                height: "48px",
                margin: "0 0 20px 12px",
              }}
            >
              Cập nhật
            </button>
            <button
              onClick={onCancel}
              type="reset"
              title="Hủy"
              className="btn-cancel btn-sub flex-center btn-avt"
              style={{
                width: "120px",
                height: "48px",
              }}
            >
              Hủy
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
