import { onResultUserInfo } from "edu_lms/modules/selection";
import StudentExercises from "edu_lms_v2/modules/StudentExercises";
import ClassRoomDetail from "edu_lms_v2/modules/TeachingManager/ClassroomManager/ClassroomDetail";

export default function ClassroomDetail(props) {
  const classroomId = props.match.params.id;
  const userInfo = onResultUserInfo();
  switch (userInfo?.job_id) {
    case 1:
      return <ClassRoomDetail classroomId={classroomId} />;
    case 2:
      return <StudentExercises classroomId={classroomId} />;
    default:
      return <div></div>;
  }
}
