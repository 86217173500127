import React from "react";
import { Audio, AudioType, Typography, Image, Video } from "../../components";
import { TYPE_DATA } from "../../constants";

const HeaderQuestion = ({ question }) => {
  const { typeQuestion, contentQuestion } = question;

  return (
    <div className="pl-4">
      <div className="audio-title__wrapper">
        {contentQuestion.srcAudio && (
          <div className="question-audio__wrapper">
            <Audio variant={AudioType.Primary} src={contentQuestion.srcAudio} />
          </div>
        )}
        {typeQuestion.includes(TYPE_DATA.TEXT) &&
          contentQuestion.text.contentText && (
            <Typography
              text={contentQuestion.text.contentText}
              fontSize={contentQuestion.text.fontSize}
              className="quicksand-medium"
            />
          )}
      </div>
      {typeQuestion.includes(TYPE_DATA.IMAGE) && contentQuestion.srcImage && (
        <div className="text-center mb-3">
          <Image
            src={contentQuestion.srcImage}
            alt="word-finding__question__image"
          />
        </div>
      )}
      {typeQuestion.includes(TYPE_DATA.VIDEO) && (
        <Video src={contentQuestion.srcVideo} />
      )}
    </div>
  );
};

export default HeaderQuestion;
