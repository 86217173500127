import _ from "lodash";
import { getContentAudioVideoImageTextFromIconActData, getIconsListByLanguage } from ".";
import {
  SPLIT_ANSWER_RESULT,
  OLD_SPLIT_ANSWER_RESULT,
  SPLIT_ANSWER_MANY_RESULT,
  SPLIT_ANSWER_TEXT,
  FORMAT_WORD,
} from "../constants/WordFinding";

const formatActivityDataWordFinding = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    font_size: fontSize,
  } = gameConfig;

  const questionAnswerSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      dataGameConfigItem.question
    );
    const question = {
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          contentText: questionContentIcon.contentText,
          fontSize
        },
      },
    };

    const answersList = dataGameConfigItem.answers.map((answer) => {
      const answerResults = answer.answer_result
        ? answer.answer_result
            .replaceAll(OLD_SPLIT_ANSWER_RESULT, SPLIT_ANSWER_RESULT)
            .split(SPLIT_ANSWER_MANY_RESULT)
            .map((answerResultItem) =>
              answerResultItem.split(SPLIT_ANSWER_RESULT)
            )
        : [];
      return {
        answerId: answer.answer_id,
        answerResults,
        answerText: answer.answer_text.split(SPLIT_ANSWER_TEXT),
      };
    });

    const answer = {
      typeAnswer,
      fontSize,
      answers: answersList,
    };

    return { question, answer };
  });

  return questionAnswerSetList;
};

// Format answer text with case same correct text in a sentence
const formatAnswerText = (text) => {
  return text
    .replace("|1|", "")
    .replace("|2|", "")
    .replace("|3|", "")
    .replace("|4|", "")
    .replace("|5|", "")
    .replace("|6|", "")
    .replace("|7|", "")
    .replace("|8|", "")
    .replace("|9|", "")
    .replace("|10|", "");
};

// Format word with some special symbols
const formatWord = (word) => {
  return word.replace(FORMAT_WORD, "");
};

const getAllCorrectAnswerTexts = (answers = []) => {
  const correctAnswerTexts = [];
  answers.forEach(answerLine => {
    answerLine.answerText.forEach((text, textIndex) => {
      const isCorrect = answerLine.answerResults.some(answerResult => answerResult.includes(formatWord(text)));
      if (isCorrect) {
        correctAnswerTexts.push({ answerId: answerLine.answerId, textIndex, text, isCorrect: true });
      }
    });
  });
  return correctAnswerTexts;
};

const removeDuplicateCorrectAnswers = (correctAnswers = []) => {
  const newCorrectAnswers = [];
  correctAnswers.forEach((answer) => {
    if (
      newCorrectAnswers.some(
        (ans) =>
          ans.answerId === answer.answerId && ans.textIndex === answer.textIndex
      )
    )
      return;
    newCorrectAnswers.push(answer);
  });
  return newCorrectAnswers;
};

const getAllCorrectAnswerTextsBySelectedTexts = (
  answers = [],
  selectedTexts = []
) => {
  let correctAnswerTexts = [];
  answers.forEach((answerLine) => {
    const { answerText, answerResults, answerId } = answerLine;
    const hasSelectedTextsInLine = selectedTexts.some((selectedText) =>
      answerResults.some((answerResult) =>
        answerResult.includes(formatWord(selectedText.text)) && answerId === selectedText.answerId
      )
    );

    if (answerResults.length === 1 || !hasSelectedTextsInLine) {
      const answerTextShadow = [...answerText]; // Variable to handle same correct text in a sentence
      answerResults.forEach((answerResult, _ansRlIdx) => {
        answerResult.forEach((answerResultItem, _ansRlItmIdx) => {
          const textIndex = answerTextShadow.findIndex(text => formatWord(text) === formatWord(answerResultItem));
          const hasSameCorrectAnswerText = correctAnswerTexts.some(
            (correctText) =>
              correctText.answerId === answerId &&
              correctText.textIndex === textIndex
          );
          if (textIndex > -1 && !hasSameCorrectAnswerText) {
            correctAnswerTexts.push({ answerId, textIndex, text: answerText[textIndex] });
            answerTextShadow[textIndex] = "";
          }
        })
      });
      return;
    }

    const correctAnswerLineText = [];
    answerResults.forEach((answerResult, ansRlIdx) => {
      correctAnswerLineText[ansRlIdx] = [];
      const answerTextShadow = [...answerText]; // Variable to handle same correct text in a sentence
      answerResult.forEach((answerResultItem, _ansRlItmIdx) => {
        const textIndex = answerTextShadow.findIndex(text => formatWord(text) === answerResultItem);
        if (textIndex > -1) {
          answerTextShadow[textIndex] = "";
          correctAnswerLineText[ansRlIdx].push({ answerId, textIndex, text: answerText[textIndex] });
        }
      })
    });

    let isSelectCorrectLine = false;
    const tempCorrectAnswerText = [...correctAnswerTexts];
    const selectTextsInLine = selectedTexts.filter(
      (selectedText) =>
        selectedText.answerId === answerId &&
        answerResults.some(answerResult => answerResult.includes(formatWord(selectedText.text)))
    );
    answerResults.forEach((answerResult, ansRlIdx) => {
      if (selectTextsInLine.length > 0 && !isSelectCorrectLine) {
        correctAnswerTexts = [...correctAnswerTexts, ...correctAnswerLineText[ansRlIdx]];
      }
      if (selectTextsInLine.length === answerResult.length && !isSelectCorrectLine) {
        correctAnswerTexts = [...tempCorrectAnswerText, ...correctAnswerLineText[ansRlIdx]];
        isSelectCorrectLine = true;
      }
    })

  });

  return removeDuplicateCorrectAnswers(correctAnswerTexts);
};

export {
  formatActivityDataWordFinding,
  formatAnswerText,
  formatWord,
  getAllCorrectAnswerTexts,
  getAllCorrectAnswerTextsBySelectedTexts,
};
