import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { postFeedback } from "edu_lms/services/feedback";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";

export default function Contacts() {
  useScrollToTop();
  const { register, errors, handleSubmit, reset } = useForm();
  const onSubmit = (dataForm) => {
    postFeedback(dataForm).then((res) => {
      if (res.data.status === "success") {
        toast.success("Gửi chia sẻ thành công");
        reset();
      }
      if (res.data.status === "fail") {
        toast.error("Gửi chia sẻ không thành công");
      }
    });
  };
  return (
    <main>
      <SEO data={pages.contact} />
      <div className="hoc10-breadcrumb bg-gray">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Hỗ trợ
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Liên hệ
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-contact bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-3">
              <ul className="tab nav hoc10-tab">
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_PRIVACY_POLICY}>
                    <i className="icon icon-policy"></i>
                    <span>Chính sách bảo mật</span>
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_TERM_OF_USE}>
                    <i className="icon icon-term"></i>
                    <span>Điều khoản sử dụng</span>
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_CONTACT} className="active">
                    <i className="icon icon-phone"></i>
                    <span>Liên hệ</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-9 col-9">
              <div className="tab-content hoc10-tab-content">
                <div id="contact" className="tab-pane active h1Seo">
                  <h1 className="title">Liên hệ với chúng tôi</h1>
                  <div className="flex-lc">
                    <a
                      href="tel:(+84) 0247309886"
                      title=""
                      className="phone flex-lc"
                    >
                      <i className="icon icon-phone"></i> (+84) 02473098866
                    </a>
                    <a
                      href="mailto:contact@hoc10.vn"
                      title=""
                      className="email flex-lc"
                    >
                      <i className="icon icon-email"></i>contact@hoc10.vn
                    </a>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="form-h10">
                    <div className="form-group">
                      <label>
                        Họ và tên <sup>*</sup>
                      </label>
                      <input
                        ref={register({ required: "Vui lòng nhập họ tên" })}
                        type="text"
                        name="user_name"
                        placeholder="Nhập họ và tên"
                      />
                      <p className="monkey-color-red text-danger monkey-mt-15">
                        {errors.user_name?.message}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>
                        Số điện thoại <sup>*</sup>
                      </label>
                      <input
                        name="phone_number"
                        maxLength="12"
                        placeholder="Nhập số điện thoại"
                        ref={register({
                          required: "Vui lòng nhập số điện thoại",
                          pattern: {
                            value:
                              /([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/,
                            message: "Số điện thoại không hợp lệ",
                          },
                        })}
                      />
                      <p className="monkey-color-red text-danger monkey-mt-15">
                        {errors.phone_number?.message}
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Email </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Nhập email"
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Nội dung chia sẻ <sup>*</sup>
                      </label>
                      <textarea
                        ref={register({ required: true })}
                        type="text"
                        name="content"
                        placeholder="Nhập nội dung chia sẻ"
                      ></textarea>
                      <p className="monkey-color-red text-danger monkey-mt-15">
                        {errors.content && "Vui lòng nhập nội dung chia sẻ"}
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="btn-submit flex-center btn-pr"
                    >
                      Gửi
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-bookaccess hoc10-bookaccess--cs1">
        <div className="container">
          <div className="content">
            <h3>Đăng ký ngay để trải nghiệm SGK Cánh Diều phiên bản điện tử</h3>

            <Link
              title="Truy cập vào tủ sách"
              className="btn-access flex-center"
              to={PATH.ROUTE_PATH_TU_SACH}
            >
              Truy cập vào tủ sách
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
