import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { FIBHeaderQuestion } from "../../components";
import ContentAnswer from "./ContentAnswer";
import { getNumberOfInputs } from "../../helpers/FIB";
import { INPUT_CHARS_REPLACE, SPLIT_ANSWER_RESULT } from '../../constants/FIB'
import "./styles.scss";

const FIB_001 = (
  {
    gameData,
    hideResultAnswer = false,
    inputtedAreasProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
    theme
  },
  ref
) => {
  const { question, answer } = gameData;

  const [inputAreas, setInputAreas] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  /**---------- Show inputted answers result ----------**/
  useEffect(() => {
    if (inputtedAreasProp.length > 0) {
      setInputAreas(inputtedAreasProp);
    }
  }, [inputtedAreasProp]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      let correctInputtedAnswers = [];
      answer.answers.forEach((answerLine) => {
        if (!answerLine.answerResultItems) return;

        const answerInputsLine = [];
        answerLine.answerItems.forEach((answerItem, answerIndex) => {
          if (answerItem === INPUT_CHARS_REPLACE) {
            answerInputsLine.push({ lineAnswerId: answerLine.answerId, answerIndex })
          }
        });

        answerLine.answerResultItems.forEach((answerItemResult, index) => {
          answerInputsLine[index].value = answerItemResult.split(SPLIT_ANSWER_RESULT)[0];
          answerInputsLine[index].isCorrect = true;
        });

        correctInputtedAnswers = [...correctInputtedAnswers, ...answerInputsLine];
      });
      setInputAreas(correctInputtedAnswers);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer])

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const correctAnswerItems = [];
    const answerLineHasResults = answer.answers.filter(answerLine => answerLine.answerResultItems);
    answerLineHasResults.forEach((answerLine, lineIndex) => {
      const inputAreasLine = inputAreas
        .filter((inputArea) => inputArea.lineAnswerId === answerLine.answerId)
        .sort((a, b) => a.answerIndex - b.answerIndex);

      answerLine.answerResultItems.forEach((answerResultItem, index) => {
        const answerItemsResult = answerResultItem.split(SPLIT_ANSWER_RESULT);
        inputAreasLine[index].value = inputAreasLine[index].value.trim();
        inputAreasLine[index].value = inputAreasLine[index].value.replace(/\s+/g, ' ').toString();
        if (inputAreasLine[index] && answerItemsResult.includes(inputAreasLine[index].value)) {
          correctAnswerItems.push(inputAreasLine[index]);
        }
      });
    });

    const inputtedAreas = inputAreas.map((inputArea) => {
      const isCorrectInputArea = correctAnswerItems.some(
        (correctItem) =>
          correctItem.lineAnswerId === inputArea.lineAnswerId &&
          correctItem.answerIndex === inputArea.answerIndex
      );
      return {
        ...inputArea,
        isCorrect: isCorrectInputArea,
      };
    });
    setInputAreas(inputtedAreas);

    const isCorrect = correctAnswerItems.length === getNumberOfInputs(answer.answers);
    onComplete({ isCorrect, inputtedAreas, totalCorrectAnswer: correctAnswerItems.length });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setInputAreas([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleChangeInput = ({ lineAnswerId, answerIndex, value }) => {
    let shadowInputAreas = [...inputAreas];
    if (isCheckedAnswer) {
      shadowInputAreas = shadowInputAreas.map(input => ({ ...input, isCorrect: null }));
    }
    
    const arrIndexInputFocused = inputAreas.findIndex(
      (inputArea) =>
        inputArea.lineAnswerId === lineAnswerId &&
        inputArea.answerIndex === answerIndex
    );
    if (arrIndexInputFocused > -1) {
      shadowInputAreas.splice(arrIndexInputFocused, 1);
    }
    shadowInputAreas.push({ lineAnswerId, answerIndex, value });
    setInputAreas(shadowInputAreas);

    const isFillAllInputs = shadowInputAreas.length === getNumberOfInputs(answer.answers);
    onPlaying(isFillAllInputs);
  }

  return (
    <div className="FIB_001__wrapper">
      <FIBHeaderQuestion question={question} theme={theme} />
      <ContentAnswer
        answer={answer}
        inputAreas={inputAreas}
        isView={isReadOnly || isViewOnly}
        hideResultAnswer={hideResultAnswer}
        onChangeInput={handleChangeInput}
      />
    </div>
  );
};

export default forwardRef(FIB_001);
