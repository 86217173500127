/* ----- NEW ----- */

// Tủ sách
export const ROUTE_PATH_TU_SACH = "/tu-sach/";


/* ----- OLD ----- */


export const PARAMETER_BOOK_USED = "kho-sach-cua-toi";
export const PARAMETER_LIST_BOOK = "bo-sach-giao-khoa";
export const ROUTE_PATH_HOME = "/v1";
export const ROUTE_PATH_SIGN_IN_V1 = "/v1-dang-nhap.html";
export const ROUTE_PATH_FORGOT_PASSWORD = "/quen-mat-khau.html";
export const ROUTE_PATH_VERIFY_OTP_PW = "/xac-nhan-otp.html";
export const ROUTE_PATH_CHANGE_PW = "/xac-nhan-thay-doi-mat-khau.html";
export const ROUTE_PATH_UPDATE_PASSWORD = "/thay-doi-mat-khau.html";
export const ROUTE_PATH_SIGN_UP = "/v1-dang-ky.html";
export const ROUTE_PATH_ACTIVATE_BOOK = "/kich-hoat-sach.html";
export const ROUTE_PATH_GENERAL = "/bo-sach-canh-dieu.html";
export const ROUTE_PATH_GENERAL_ID = "/list-book.html";
export const ROUTE_PATH_BOOK_USED = "/kho-sach-cua-toi.html";
export const ROUTE_PATH_LIST_BOOK = "/sach-giao-khoa-canh-dieu.html";
export const ROUTE_PATH_LIST_BOOK_TEACHER = "/sach-giao-vien.html";
export const ROUTE_PATH_LIST_BOOK_TEST_3710 = "/sach-tham-dinh.html";
export const ROUTE_PATH_LIST_BOOK_DOCUMENT = "/tai-lieu-bo-tro.html";
export const ROUTE_PATH_LIST_WORK_BOOK = "/sach-bo-tro.html";
export const ROUTE_PATH_BOOK_DETAIL = "/chi-tiet";
export const ROUTE_PATH_TUTORIAL = "/tai-lieu-huong-dan.html";
export const ROUTE_PATH_NOT_FOUND = "/not-found/";
export const ROUTE_INSTRUCTION_ACTIVATED_BOOK = "/huong-dan";
export const ROUTE_UPDATE_INFORMATION = "/v1-cap-nhat-thong-tin.html";
export const ROUTE_PATH_PROGRAMME_GDPT = "/chuong-trinh-gdpt.html";
export const ROUTE_PATH_INTRODUCE = "/gioi-thieu-sach.html";
export const ROUTE_PATH_FORM_JOB = "/nghe-nghiep.html";
export const ROUTE_PATH_EDUCATION_PROGRAM = "/chuong-trinh-cac-mon-hoc.html";
export const ROUTE_PATH_CONTACT = "/v1-lien-he.html";
export const ROUTE_PATH_TERMS_OF_USE = "/v1-dieu-khoan-su-dung.html";
export const ROUTE_PATH_PRIVACY_POLICY = "/v1-chinh-sach-bao-mat.html";
export const ROUTE_PATH_ELECTRONIC_COURSEWARE = "/hoc-lieu-dien-tu.html";
export const ROUTE_PATH_ELECTRONIC_COURSEWARE_V2 = "/hoc-lieu-dien-tu/";
export const ROUTER_PATH_LEARNING = `${process.env.REACT_APP_MEDIA_URL_APP}E-learning/1._CT_tong_the.pdf`;
export const ROUTE_PATH_READING_BOOKS = "/doc-sach";
export const ROUTE_PATH_READING_SLIDE_LIBRARY = "/v1-bai-giang";
export const ROUTE_PATH_EDUCATION_TEACHER = "/tai-lieu-tap-huan.html";
export const ROUTE_PATH_SLIDE_LIBRARY = "/thu-vien-bai-giang.html";
export const ROUTE_PATH_QUESTION_SET = "/ngan-hang-cau-hoi.html";
export const ROUTE_PATH_QUESTION_SET_V2 = "/bai-tap-ve-nha/";
export const ROUTE_PATH_QUESTION = "/cau-hoi";
export const ROUTE_PATH_EXERCISE = "/bai-tap-ve-nha";
export const ROUTE_PATH_PRACTIVE = "/luyen-tap";
export const ROUTE_PATH_BOOK_INSTRUCTION = "/gioi-thieu-sach";
export const ROUTE_PATH_TRAINING_TEST = "/bai-kiem-tra-tap-huan.html";
export const ROUTE_PATH_TRAINING_TEST_2 = "/th2021";
// export const ROUTE_PATH_LIST_QUIZ_2 = "/danh-sach-tap-huan-2021.html"
export const ROUTE_PATH_LIST_QUIZ = "/danh-sach-tap-huan.html";
export const ROUTE_PATH_DOING_EXERCISE = "/lam-bai-tap.html";
export const ROUTE_PATH_LIST_QUIZ_DETAIL = "/bai-kiem-tra";
export const ROUTE_PATH_DOWNLOAD = "/download/tinhoc10";
export const ROUTE_PATH_DASHBOARD = "/dashboard1";
export const ROUTE_PATH_DASHBOARD_RESULT_QUIZ_PROVINCE =
  "/dashboard/ket-qua-tap-huan-so";
export const ROUTE_PATH_DASHBOARD_RESULT_QUIZ_DISTRICT =
  "/dashboard/ket-qua-tap-huan-phong";
export const ROUTE_PATH_DASHBOARD_RESULT_QUIZ_SCHOOL =
  "/dashboard/ket-qua-tap-huan-truong";
export const ROUTE_PATH_DASHBOARD_RESULT_QUIZ_TEACHER =
  "/dashboard/ket-qua-tap-huan-giao-vien";

export const ROUTE_PATH_DASHBOARD_ADMIN_GRADE = "/dashboard/admin-lop-hoc";
export const ROUTE_PATH_DASHBOARD_ADMIN_TEACHER = "/dashboard/admin-giao-vien";
export const ROUTE_PATH_DASHBOARD_ADMIN_STUDENT = "/dashboard/admin-hoc-sinh";
export const ROUTE_PATH_DASHBOARD_ADMIN_DEPARTMENT_EDUCATION =
  "/dashboard/admin-phong";
export const ROUTE_PATH_DASHBOARD_ADMIN_SCHOOL = "/dashboard/admin-truong";

export const ROUTE_PATH_V2_HOME = "/";
export const ROUTE_PATH_V2_UPDATEINFORMATION = "/cap-nhat-thong-tin.html";
export const ROUTE_PATH_V3_UPDATEINFORMATION = "/cap-nhat-thong-tin/";
export const ROUTE_PATH_V3_TRAINING_UPDATEINFORMATION =
  "/tap-huan/cap-nhat-thong-tin/";

export const ROUTE_PATH_V2_CONTACT = "/lien-he.html";
export const ROUTE_PATH_V3_CONTACT = "/lien-he/";

export const ROUTE_PATH_V2_SETTING = "/cai-dat.html";
export const ROUTE_PATH_V2_LIST_BOOK = "/tu-sach.html";
export const ROUTE_PATH_V2_LIST_BOOK_GRADE3 = "/tu-sach/lop3.html";
export const ROUTE_PATH_V2_LIST_BOOK_GRADE7 = "/tu-sach/lop7.html";
export const ROUTE_PATH_V2_LIST_BOOK_GRADE10 = "/tu-sach/lop10.html";
export const ROUTE_PATH_V2_REGISTER = "/dang-ky.html";
export const ROUTE_PATH_V3_REGISTER = "/dang-ky/";
export const ROUTE_PATH_V2_INTRO = "/gioi-thieu.html";
export const ROUTE_PATH_V3_INTRO = "/gioi-thieu/";
export const ROUTE_PATH_V2_ONEPERCENT_INTRO = "/ve-chung-toi.html";
export const ROUTE_PATH_V3_ONEPERCENT_INTRO = "/ve-chung-toi/";
export const ROUTE_PATH_V2_AUTHOR_INTRO = "/doi-ngu-chuyen-gia.html";
export const ROUTE_PATH_V3_AUTHOR_INTRO = "/doi-ngu-chuyen-gia/";
export const ROUTE_PATH_V2_AUTHOR_DEATAIL = "/chi-tiet.html";
export const ROUTE_PATH_V3_AUTHOR_DEATAIL = "/chi-tiet/";
export const ROUTE_PATH_V2_BOOK_INTRO = "/bo-sgk-canh-dieu.html";
export const ROUTE_PATH_V3_BOOK_INTRO = "/bo-sgk-canh-dieu/";
export const ROUTE_PATH_V2_STUDENT = "/hoc-sinh.html";
export const ROUTE_PATH_V3_STUDENT = "/hoc-sinh/";
export const ROUTE_PATH_V2_TEACHER = "/giao-vien.html";
export const ROUTE_PATH_V3_TEACHER = "/giao-vien/";
export const ROUTE_PATH_V2_LIST_SLIDE = "/bai-giang.html";
export const ROUTE_PATH_V3_LIST_SLIDE = "/bai-giang/";
export const ROUTE_PATH_V3_LIST_SLIDE_OLD = "/giao-an/";
export const ROUTE_PATH_V2_READING_SLIDE = "/bai-giang";
export const ROUTE_PATH_V2_FREQUENT_QUESTION = "/cau-hoi-thuong-gap.html";
export const ROUTE_PATH_V3_FREQUENT_QUESTION = "/cau-hoi-thuong-gap/";
export const ROUTE_PATH_SIGN_IN = "/dang-nhap.html";
export const ROUTE_PATH_V3_SIGN_IN = "/dang-nhap/";
export const ROUTE_PATH_V2_TERM_OF_USE = "/dieu-khoan-su-dung.html";
export const ROUTE_PATH_V3_TERM_OF_USE = "/dieu-khoan-su-dung/";
export const ROUTE_PATH_V2_PRIVACY_POLICY = "/chinh-sach-bao-mat.html";
export const ROUTE_PATH_V3_PRIVACY_POLICY = "/chinh-sach-bao-mat/";
export const ROUTE_PATH_V2_NOTIFICATION = "/thong-bao";
export const ROUTE_PATH_V3_TRAINING = "/taphuan";
export const ROUTE_PATH_V3_STUDENT_EXERCISES = "/hoc-sinh/lop-hoc/";
export const ROUTE_PATH_V3_TEACHER_LIST_EXERCISE_COMPLETE =
  "/giao-vien/lop-hoc/bai-tap/";
export const ROUTE_PATH_V3_TEACHERMANAGER = "/giao-vien/lop-hoc/";
export const ROUTE_PATH_LIST_STUDENT_DOING_HOMEWORK = "/hoc-sinh-lam-bai/";
export const ROUTE_PATH_V3_TEACHER_LIST_EXERCISE =
  "/giao-vien/lop-hoc/giao-bai-tap/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE = "/hoc-sinh/luyen-tap/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE_2 = "/hoc-sinh/luyen-tap-2/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE_3 = "/hoc-sinh/luyen-tap-3/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE_4 = "/hoc-sinh/luyen-tap-4/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE_5 = "/hoc-sinh/luyen-tap-5/";
export const ROUTE_PATH_V3_STUDENT_DOING_EXERCISE_6 = "/hoc-sinh/luyen-tap-6/";
export const ROUTE_PATH_V3_STUDENT_MOCK_TEST_5 = "/hoc-sinh/thi-thu-5/";
export const ROUTE_PATH_V3_STUDENT_MOCK_TEST_2 = "/hoc-sinh/thi-thu-2/";
export const ROUTE_PATH_V3_STUDENT_MOCK_TEST_OLD = "/luyen-thi/";
export const ROUTE_PATH_V3_STUDENT_MOCK_TEST = "/de-kiem-tra/";
export const ROUTE_PATH_V3_HISTORY_EXAM = "/lich-su-lam-bai/";
export const ROUTE_PATH_NEWS = "/tin-tuc/";
export const ROUTE_PATH_NEWS_TRAINING = "/tin-tuc/tap-huan/";
export const ROUTE_PATH_CLASSROOM = "/lop-hoc/";
export const ROUTE_PATH_EXERCISE_CLASSROOL = "/bai-tap/";
export const ROUTE_PATH_LIST_HISTORY_QUIZ = "/danh-sach-lich-su-tap-huan/";
export const ROUTE_PATH_MY_STUDY = "/hoc-lieu-cua-toi/";
export const ROUTE_PATH_COURSE = "/hoc-lieu/";
export const ROUTE_PATH_VERIFY_EMAIL = "/auth/verify-email";
export const ROUTE_PATH_TRACKING_LINK_REDIRECT = "/tracking-link-redirect";
export const ROUTE_TEST_GAME = "/hoc10-test-game";
export const ROUTE_TUTORING_HOME = "/";
export const ROUTE_DASHBOARD_TRAINING = "/dashboard/taphuan";
export const ROUTE_TUTORING_HOMEWORK = "/bai-tap-ve-nha/";
export const ROUTE_PUBLISHER_LIST = "/danh-sach-don-vi-phat-hanh/";
export const ROUTE_BLOG_KNOWLEDGE = "/kien-thuc/";
export const ROUTE_BLOG_KNOWLEDGE_OTHER = "/kien-thuc/kien-thuc-khac/";
export const ROUTE_BLOG_KNOWLEDGE_NEWS_POST = "/kien-thuc-bai-viet-moi/";
export const ROUTE_DASHBOARD_EVENT = "/dashboard/event";

export const ROUTE_REDIRECT_LINK_GRADE_4 = "/lop4/";
export const ROUTE_REDIRECT_LINK_GRADE_8 = "/lop8/";
export const ROUTE_REDIRECT_LINK_GRADE_11 = "/lop11/";
export const ROUTE_REDIRECT_LINK_GRADE_5 = "/lop5/";
export const ROUTE_REDIRECT_LINK_GRADE_9 = "/lop9/";
export const ROUTE_REDIRECT_LINK_GRADE_12 = "/lop12/";
export const ROUTE_BOOK_ACTIVATION_INSTRUCTIONS = "/huong-dan-kich-hoat-sach.html";

export const ROUTE_PATH_V3_LIST_BOOK_DOING_EXERCISE = "/tu-sach/luyen-tap";
export const ROUTE_BOOK_APPRAISAL = "/nop-tham-dinh-quoc-gia-2023/";
export const ROUTE_GUIDE_TRAINING = "/huong-dan-tap-huan";
