import _ from "lodash";
import ResultBook from "./ResultBook";
import { useAppStore } from "stores/appStore";
import { useBookStore } from "stores/bookStore";
import { BOOK_TYPE_SBT } from "consts/book";
import { isMobile } from "react-device-detect";

export default function SectionSBT() {
  const grades = useAppStore((state) => state.grades);
  const books = useBookStore((state) => state.books);
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const booksOfCurrentSubject = _.get(books, [gradeId, subjectId], []);

  const sbtBooks = booksOfCurrentSubject.filter(
    (item) => item.book_type_id === BOOK_TYPE_SBT
  );
  const currentGrade = grades.find((grade) => grade.id === gradeId);
  const isTH = currentGrade?.parent_id === 1;

  return (
    !_.isEmpty(sbtBooks) && (
      <div className={"result col-" + (isMobile ? (sbtBooks.length > 1 ? 12 : 6) : (sbtBooks.length * 3))}>
        <ResultBook
          data={sbtBooks}
          bookTypeId={BOOK_TYPE_SBT}
          categoryBook={`${isTH ? "Vở" : "Sách"} bài tập`}
          col={"col-" + (isMobile ? (sbtBooks.length > 1 ? 6 : 12)  : (12/(sbtBooks.length))) }
          background="#fff"
        />
      </div>
    )
  );
}
