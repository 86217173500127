import React from "react";

export default function ForgotPwComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn lấy lại mật khẩu</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Vì một vài lí do, thầy cô có thể quên mật khẩu đăng nhập vào tài khoản
          của website Hoc10. Thầy cô có thể tham khảo bài viết sau để có thể lấy
          lại mật khẩu và đặt lại mật khẩu mới thầy cô nhé!
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 1:</b>&nbsp; Bấm đăng nhập và bấm vào “Quên mật khẩu” sau đó
            nhập số điện thoại/email đã đăng ký tài khoản. Thầy cô lưu ý nhập
            đúng số điện thoại/email đã đăng ký để nhận được mã OTP được gửi về
            số điện thoại hoặc email.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/fw_step1.png`}
              alt="Bước 1: Bấm đăng nhập và bấm vào “Quên mật khẩu”"
            />
          </div>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 2:</b>&nbsp; Nhập số điện thoại/email đã đăng ký tài khoản.
            Thầy cô lưu ý nhập đúng số điện thoại/email đã đăng ký để nhận được
            mã OTP được gửi về số điện thoại hoặc email. Sau đó bấm “Gửi mã”
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/fw_step2.PNG`}
              alt="Bước 2: Nhập số điện thoại/email đã đăng ký tài khoản."
            />
          </div>
          <p className="font-italic mt-3">
            Lưu ý: Thầy cô bấm nhận mã 1 lần và đợi hệ thống gửi mã về máy,
            tránh trường hợp bấm nhận mã nhiều lần khiến mã gửi về máy bị sai.
          </p>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 3:</b>&nbsp; Thầy cô nhập mã do hệ thống gửi đến qua số điện
            thoại/email, sau đó tiến hành đặt lại mật khẩu mới.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/fw_step3.png`}
              alt="Bước 3: Thầy cô nhập mã do hệ thống gửi đến qua số điện thoại/email"
            />
          </div>
        </li>
        <li class="flex-lc">
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li class="flex-lc">Chúc thầy cô thao tác thành công!</li>
      </ul>
    </>
  );
}
