export const NO_ACTIVE_META_ROBOTS = 0;
export const ACTIVE_META_ROBOTS = 1;

export const pages = {
	home              : {
		title       : "Hoc10 - Bám sát chương trình GDPT mới 2018",
		description :
			"Truy cập Hoc10 ngay để đọc trọn bộ sách giáo khoa Cánh Diều và những tài liệu học tập, giảng dạy hữu ích.",
		img         : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url         : `/`,
		robots		: ACTIVE_META_ROBOTS
	},
	intro             : {
		title          : "Giới thiệu về Hoc10",
		description    :
			"Hoc10 mang sứ mệnh xây dựng hệ sinh thái giáo dục, kết nối nhà trường, học sinh và phụ huynh, tạo môi trường dạy và học hiệu quả.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/gioi-thieu/`,
		titleBreadcumb : "Giới thiệu",
		robots	       : ACTIVE_META_ROBOTS
	},
	intro_one_percent : {
		title          : "Về chúng tôi | Hoc10",
		description    :
			"Công ty One Percent đi đầu về lĩnh vực công nghệ trong giáo dục, tạo môi truờng dạy và học trực tuyến chất lượng, sáng tạo.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/ve-chung-toi/`,
		titleBreadcumb : "Về chúng tôi",
		robots	       : ACTIVE_META_ROBOTS
	},
	intro_book        : {
		title          : "Bộ sách cánh diều | Hoc10",
		description    :
			"Bộ sách giáo khoa điện tử Cánh Diều trên Hoc10 tích hợp nhiều tính năng: Học liệu điện tử, mục lục điện tử, bài tập tương tác,...",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/bo-sgk-canh-dieu/`,
		titleBreadcumb : "Bộ sách giáo khoa cánh diều",
		robots		   : ACTIVE_META_ROBOTS
	},
	intro_author      : {
		title          : "Đội ngũ chuyên gia | Hoc10",
		description    :
			"Đội ngũ chuyên gia Hoc10 là những người giàu kinh nghiệm viết SGK, giàu kinh nghiệm về GDPT, giáo dục tiểu học.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/doi-ngu-chuyen-gia/`,
		titleBreadcumb : "Đội ngũ chuyên gia",
		robots		   : NO_ACTIVE_META_ROBOTS
	},
	list_book         : {
		title          : "Tủ sách | Hoc10",
		description    :
			"Tủ sách điện tử tương tác từ lớp 1 đến lớp 12, bao gồm tất cả các môn học dành cho giáo viên, học sinh và phụ huynh tham khảo.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/tu-sach/`,
		titleBreadcumb : "Tủ sách",
		robots		   : ACTIVE_META_ROBOTS
	},
	questions         : {
		title          : "Câu hỏi thường gặp | Hoc10",
		description    :
			"Hoc10 hỗ trợ nhanh chóng các vấn đề đăng nhập, đăng ký, quản lý tài khoản, hướng dẫn sử dụng,... cho giáo viên, học sinh và phụ huynh.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/cau-hoi-thuong-gap/`,
		titleBreadcumb : "Câu hỏi thường gặp",
		robots		   : ACTIVE_META_ROBOTS
	},
	contact           : {
		title          : "Liên hệ | Hoc10",
		description    :
			"Hoc10 cung cấp các kênh thông tin giúp giáo viên, học sinh và phụ huynh gửi nội dung chia sẻ và nhận tư vấn miễn phí.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/lien-he/`,
		titleBreadcumb : "Liên hệ",
		robots		   : ACTIVE_META_ROBOTS
	},
	policy            : {
		title          : "Chính sách bảo mật | Hoc10",
		description    :
			"Hoc10 cam kết sử dụng thông tin khách hàng hợp lý, có cân nhắc và đúng theo quy định của pháp luật Việt Nam.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/chinh-sach-bao-mat/`,
		titleBreadcumb : "Chính sách bảo mật",
		robots		   : ACTIVE_META_ROBOTS
	},
	term              : {
		title          : "Điều khoản sử dụng | Hoc10",
		description    :
			"Cung cấp những quy định chung, quyền và trách nhiệm của Hoc10 với người sử dụng.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/dieu-khoan-su-dung/`,
		titleBreadcumb : "Điều khoản sử dụng",
		robots		   : ACTIVE_META_ROBOTS
	},
	slide_library     : {
		title          : "Bài giảng | Hoc10",
		description    :
			"Nơi đăng tải các giáo án được xây dựng bởi một đội ngũ giáo viên giỏi, dày dặn kinh nghiệm.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/bai-giang/`,
		titleBreadcumb : "Giáo án",
	},
	course     		   : {
		title          : "Học liệu | Hoc10",
		description    :
			"Nơi đăng tải các học liệu được xây dựng bởi một đội ngũ giáo viên giỏi, dày dặn kinh nghiệm.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/hoc-lieu/`,
		titleBreadcumb : "Học liệu",
		robots		   : ACTIVE_META_ROBOTS
	},
	register          : {
		title          : "Đăng ký | Hoc10",
		description    :
			"Đăng ký tài khoản nhanh chóng chỉ với 3 bước. Truy cập tủ sách học MIỄN PHÍ.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/dang-ky/`,
		titleBreadcumb : "Đăng ký",
		robots		   : ACTIVE_META_ROBOTS
	},
	update_infomation : {
		title          : "Cập nhật thông tin cá nhân | Hoc10",
		description    :
			"Cập nhật thông tin cá nhân: Tên, ngày tháng năm sinh, số điện thoại, email... dễ dàng.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/cap-nhat-thong-tin/`,
		titleBreadcumb : "Cập nhật thông tin",
		robots		   : ACTIVE_META_ROBOTS
	},
	student           : {
		title          : "Học sinh | Hoc10",
		description    :
			"900+ bài luyện tập, 500+ đề thi thử, 10.000+ học sinh đang học với Hoc10. Dễ dàng học tập ngay tại nhà.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/hoc-sinh/`,
		titleBreadcumb : "Học sinh",
	},
	teacher           : {
		title          : "Giáo viên | Hoc10",
		description    :
			"100+ đầu sách, 300+ bài giảng đã thiết kế được  trên 5.000 giáo viên tin dùng. ",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/giao-vien/`,
		titleBreadcumb : "Giáo viên",
	},
	mock_test         : {
		title          : "Đề kiểm tra | Hoc10",
		description    :
			"900+ bài luyện tập, 500+ đề thi thử, 10.000+ học sinh đang học với Hoc10. Dễ dàng học tập ngay tại nhà.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/luyen-thi/`,
		titleBreadcumb : "Đề kiểm tra",
		robots		   : ACTIVE_META_ROBOTS
	},
	training          : {
		title          : "Tập huấn | Hoc10",
		description    :
			"Truy cập Hoc10 ngay để đọc trọn bộ sách giáo khoa Cánh Diều và những tài liệu học tập, giảng dạy hữu ích.",
		img            : `${ process.env.REACT_APP_MEDIA_URL_APP }E_Learning/thumb/thumb_hoc10_2022.png`,
		url            : `/taphuan`,
		titleBreadcumb : "Tập huấn",
	},
	"/bai-giang/"                                                            : {
		"title"       : "Thư viện giáo án - bài giảng điện tử sách Cánh Diều | Hoc10",
		"description" : "Giáo án - bài giảng điện tử lớp 1 đến lớp 12 của bộ sách Cánh Diều được xây dựng bởi đội ngũ giáo viên giỏi, giàu kinh nghiệm.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/bai-giang/",
		"robots"	  : ACTIVE_META_ROBOTS
	},
	"/bai-tap-ve-nha/"                                                       : {
		"title"       : null,
		"description" : null,
		"img"         : null,
		"url"         : "/bai-tap-ve-nha/"
	},
	"/bo-sgk-canh-dieu/"                                                     : {
		"title"       : "Bộ sách Cánh Diều | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Giới thiệu chung về bộ sách giáo khoa Cánh Diều và đặc trưng của sách giáo khoa điện tử Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/bo-sgk-canh-dieu/"
	},
	"/cap-nhat-thong-tin/"                                                   : {
		"title"       : "Cập nhật thông tin cá nhân | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Cập nhật thông tin cá nhân giúp bạn có những trải nghiệm tốt hơn.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/cap-nhat-thong-tin/"
	},
	"/cau-hoi-thuong-gap/"                                                   : {
		"title"       : "Câu hỏi thường gặp | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Nơi tập hợp những hỗ trợ của Hoc10 về các vấn đề, như: Đăng nhập, đăng ký, quản lý tài khoản, hướng dẫn sử dụng, tính năng mới...cho giáo viên, học sinh và phụ huynh.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/cau-hoi-thuong-gap/"
	},
	"/chinh-sach-bao-mat/"                                                   : {
		"title"       : "Chính sách bảo mật | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoc10 cam kết sử dụng thông tin khách hàng hợp lý và đúng theo quy định của pháp luật Việt Nam.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/chinh-sach-bao-mat/"
	},
	"/dang-ky/"                                                              : {
		"title"       : "Đăng ký tài khoản | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đăng ký ngay tài khoản Hoc10 nhanh chóng chỉ với 3 bước để truy cập tủ sách điện tử Cánh Diều MIỄN PHÍ.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/dang-ky/"
	},
	"/dang-nhap/"                                                            : {
		"title"       : "Đăng nhập | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đăng nhập tài khoản Hoc10 để khám phá ngay tủ sách điện tử Cánh Diều cùng với nhiều tính năng hữu ích.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/dang-nhap/"
	},
	"/danh-sach-don-vi-phat-hanh/"                                           : {
		"title"       : "Danh sách đơn vị phát hành bộ sách Cánh Diều | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Nơi tập hợp danh sách đơn vị phát hành và các đơn vị phân phối bộ sách Cánh Diều mới nhất (được cập nhật thường xuyên).",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/danh-sach-don-vi-phat-hanh/"
	},
	"/danh-sach-lich-su-tap-huan/"                                           : {
		"title"       : null,
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : null,
		"img"         : null,
		"url"         : "/danh-sach-lich-su-tap-huan/"
	},
	"/dieu-khoan-su-dung/"                                                   : {
		"title"       : "Điều khoản sử dụng | Hoc10",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Cung cấp thông tin về những quy định chung, quyền và trách nhiệm của Hoc10 với người sử dụng.",
		"img"         : "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png",
		"url"         : "/dieu-khoan-su-dung/"
	},
	"/doc-sach/am-nhac-1/1/6/"                                               : {
		"title"       : "Âm nhạc lớp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/1-am-nhac-1-1-6-0.png",
		"url"         : "/doc-sach/am-nhac-1/1/6/"
	},
	"/doc-sach/am-nhac-10/1/161/"                                            : {
		"title"       : "Âm nhạc lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/2-am-nhac-10-1-161-0.png",
		"url"         : "/doc-sach/am-nhac-10/1/161/"
	},
	"/doc-sach/am-nhac-2/1/17/"                                              : {
		"title"       : "Âm nhạc lớp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/3-am-nhac-2-1-17-0.png",
		"url"         : "/doc-sach/am-nhac-2/1/17/"
	},
	"/doc-sach/am-nhac-3/1/131/"                                             : {
		"title"       : "Âm nhạc lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 3 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/4-am-nhac-3-1-131-0.png",
		"url"         : "/doc-sach/am-nhac-3/1/131/"
	},
	"/doc-sach/am-nhac-6/1/31/"                                              : {
		"title"       : "Âm nhạc lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 6 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/5-am-nhac-6-1-31-0.png",
		"url"         : "/doc-sach/am-nhac-6/1/31/"
	},
	"/doc-sach/am-nhac-7/1/148/"                                             : {
		"title"       : "Âm nhạc lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Âm nhạc lớp 7 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/6-am-nhac-7-1-148-0.png",
		"url"         : "/doc-sach/am-nhac-7/1/148/"
	},
	"/doc-sach/ban-in-thu-tieng-anh-8/10/474/"                               : {
		"title"       : "Tiếng Anh lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tiếng Anh lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/7-ban-in-thu-tieng-anh-8-10-474-0.png",
		"url"         : "/doc-sach/ban-in-thu-tieng-anh-8/10/474/"
	},
	"/doc-sach/ban-in-thu-tieng-anh-4/10/459/"                               : {
		"title"       : "Tiếng Anh lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tiếng Anh lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/8-ban-in-thu-tieng-anh-4-10-459-0.png",
		"url"         : "/doc-sach/ban-in-thu-tieng-anh-4/10/459/"
	},
	"/doc-sach/ban-mau-am-nhac-8/11/539/"                                    : {
		"title"       : "Âm nhạc lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Âm nhạc lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/9-ban-mau-am-nhac-8-11-539-0.png",
		"url"         : "/doc-sach/ban-mau-am-nhac-8/11/539/"
	},
	"/doc-sach/ban-mau-cong-nghe-8/11/536/"                                  : {
		"title"       : "Công nghệ lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Công nghệ lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/10-ban-mau-cong-nghe-8-11-536-0.png",
		"url"         : "/doc-sach/ban-mau-cong-nghe-8/11/536/"
	},
	"/doc-sach/ban-mau-giao-duc-cong-dan-8/11/533/"                          : {
		"title"       : "Giáo dục công dân lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục công dân lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/11-ban-mau-giao-duc-cong-dan-8-11-533-0.png",
		"url"         : "/doc-sach/ban-mau-giao-duc-cong-dan-8/11/533/"
	},
	"/doc-sach/ban-mau-giao-duc-the-chat-8/11/538/"                          : {
		"title"       : "Giáo dục thể chất lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/12-ban-mau-giao-duc-the-chat-8-11-538-0.png",
		"url"         : "/doc-sach/ban-mau-giao-duc-the-chat-8/11/538/"
	},
	"/doc-sach/ban-mau-hoat-dong-trai-nghiem-huong-nghiep-8/11/541/"         : {
		"title"       : "Hoạt động trải nghiệm, hướng nghiệp lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Hoạt động trải nghiệm, hướng nghiệp lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/13-ban-mau-hoat-dong-trai-nghiem-huong-nghiep-8-11-541-0.png",
		"url"         : "/doc-sach/ban-mau-hoat-dong-trai-nghiem-huong-nghiep-8/11/541/"
	},
	"/doc-sach/ban-mau-khoa-hoc-tu-nhien-8/11/535/"                          : {
		"title"       : "Khoa học tự nhiên lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Khoa học tự nhiên lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/14-ban-mau-khoa-hoc-tu-nhien-8-11-535-0.png",
		"url"         : "/doc-sach/ban-mau-khoa-hoc-tu-nhien-8/11/535/"
	},
	"/doc-sach/ban-mau-mi-thuat-8/11/540/"                                   : {
		"title"       : "Mỹ thuật lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Mỹ thuật lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/15-ban-mau-mi-thuat-8-11-540-0.png",
		"url"         : "/doc-sach/ban-mau-mi-thuat-8/11/540/"
	},
	"/doc-sach/ban-mau-ngu-van-8-tap-1/11/528/"                              : {
		"title"       : "Ngữ văn lớp 8 tập 1 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Ngữ văn lớp 8 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16-ban-mau-ngu-van-8-tap-1-11-528-0.png",
		"url"         : "/doc-sach/ban-mau-ngu-van-8-tap-1/11/528/"
	},
	"/doc-sach/ban-mau-ngu-van-8-tap-2/11/529/"                              : {
		"title"       : "Ngữ văn lớp 8 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Ngữ văn lớp 8 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17-ban-mau-ngu-van-8-tap-2-11-529-0.png",
		"url"         : "/doc-sach/ban-mau-ngu-van-8-tap-2/11/529/"
	},
	"/doc-sach/ban-mau-tin-hoc-8/11/537/"                                    : {
		"title"       : "Tin học lớp 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tin học lớp 8 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18-ban-mau-tin-hoc-8-11-537-0.png",
		"url"         : "/doc-sach/ban-mau-tin-hoc-8/11/537/"
	},
	"/doc-sach/ban-mau-toan-8-tap-1/11/530/"                                 : {
		"title"       : "Toán lớp 8 tập 1 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 8 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19-ban-mau-toan-8-tap-1-11-530-0.png",
		"url"         : "/doc-sach/ban-mau-toan-8-tap-1/11/530/"
	},
	"/doc-sach/ban-mau-toan-8-tap-2/11/531/"                                 : {
		"title"       : "Toán lớp 8 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 8 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/20-ban-mau-toan-8-tap-2-11-531-0.png",
		"url"         : "/doc-sach/ban-mau-toan-8-tap-2/11/531/"
	},
	"/doc-sach/ban-mau-am-nhac-4/11/525/"                                    : {
		"title"       : "Âm nhạc lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Âm nhạc lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/21-ban-mau-am-nhac-4-11-525-0.png",
		"url"         : "/doc-sach/ban-mau-am-nhac-4/11/525/"
	},
	"/doc-sach/ban-mau-cd-cong-nghe-11-chan-nuoi/11/567/"                    : {
		"title"       : "Chuyên đề Công nghệ lớp 11: Chăn nuôi (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Công nghệ lớp 11: Chăn nuôi (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/22-ban-mau-cd-cong-nghe-11-chan-nuoi-11-567-0.png",
		"url"         : "/doc-sach/ban-mau-cd-cong-nghe-11-chan-nuoi/11/567/"
	},
	"/doc-sach/ban-mau-cd-cong-nghe-11-cong-nghe-co-khi/11/568/"             : {
		"title"       : "Chuyên đề Công nghệ lớp 11: Công nghệ cơ khí (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Công nghệ lớp 11: Công nghệ cơ khí (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/23-ban-mau-cd-cong-nghe-11-cong-nghe-co-khi-11-568-0.png",
		"url"         : "/doc-sach/ban-mau-cd-cong-nghe-11-cong-nghe-co-khi/11/568/"
	},
	"/doc-sach/ban-mau-cd-giao-duc-kinh-te-va-phap-luat-11/11/566/"          : {
		"title"       : "Chuyên đề Giáo dục kinh tế và pháp luật lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Giáo dục kinh tế và pháp luật lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/24-ban-mau-cd-giao-duc-kinh-te-va-phap-luat-11-11-566-0.png",
		"url"         : "/doc-sach/ban-mau-cd-giao-duc-kinh-te-va-phap-luat-11/11/566/"
	},
	"/doc-sach/ban-mau-cd-hoa-hoc-11/11/564/"                                : {
		"title"       : "Chuyên đề Hóa học lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Hóa học lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/25-ban-mau-cd-hoa-hoc-11-11-564-0.png",
		"url"         : "/doc-sach/ban-mau-cd-hoa-hoc-11/11/564/"
	},
	"/doc-sach/ban-mau-cd-lich-su-11/11/571/"                                : {
		"title"       : "Chuyên đề Lịch sử lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Lịch sử lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/26-ban-mau-cd-lich-su-11-11-571-8.png",
		"url"         : "/doc-sach/ban-mau-cd-lich-su-11/11/571/"
	},
	"/doc-sach/ban-mau-cd-ngu-van-11/11/561/"                                : {
		"title"       : "Chuyên đề Ngữ văn lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Ngữ văn lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/27-ban-mau-cd-ngu-van-11-11-561-0.png",
		"url"         : "/doc-sach/ban-mau-cd-ngu-van-11/11/561/"
	},
	"/doc-sach/ban-mau-cd-sinh-hoc-11/11/565/"                               : {
		"title"       : "Chuyên đề Sinh học lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Sinh học lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/28-ban-mau-cd-sinh-hoc-11-11-565-0.png",
		"url"         : "/doc-sach/ban-mau-cd-sinh-hoc-11/11/565/"
	},
	"/doc-sach/ban-mau-cd-tin-hoc-11-khoa-hoc-may-tinh/11/570/"              : {
		"title"       : "Chuyên đề Tin học lớp 11: Khoa học máy tính (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Tin học lớp 11: Khoa học máy tính (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/29-ban-mau-cd-tin-hoc-11-khoa-hoc-may-tinh-11-570-0.png",
		"url"         : "/doc-sach/ban-mau-cd-tin-hoc-11-khoa-hoc-may-tinh/11/570/"
	},
	"/doc-sach/ban-mau-cd-tin-hoc-11-tin-hoc-ung-dung/11/569/"               : {
		"title"       : "Chuyên đề Tin học lớp 11: Tin học ứng dụng (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Tin học lớp 11: Tin học ứng dụng (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/30-ban-mau-cd-tin-hoc-11-tin-hoc-ung-dung-11-569-0.png",
		"url"         : "/doc-sach/ban-mau-cd-tin-hoc-11-tin-hoc-ung-dung/11/569/"
	},
	"/doc-sach/ban-mau-cd-toan-11/11/562/"                                   : {
		"title"       : "Chuyên đề Toán lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Toán lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/31-ban-mau-cd-toan-11-11-562-0.png",
		"url"         : "/doc-sach/ban-mau-cd-toan-11/11/562/"
	},
	"/doc-sach/ban-mau-cd-vat-li-11/11/563/"                                 : {
		"title"       : "Chuyên đề Vật lý lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Chuyên đề Vật lý lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/32-ban-mau-cd-vat-li-11-11-563-0.png",
		"url"         : "/doc-sach/ban-mau-cd-vat-li-11/11/563/"
	},
	"/doc-sach/ban-mau-cong-nghe-11-chan-nuoi/11/557/"                       : {
		"title"       : "Công nghệ lớp 11: Chăn nuôi (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Công nghệ lớp 11: Chăn nuôi (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/33-ban-mau-cong-nghe-11-chan-nuoi-11-557-0.png",
		"url"         : "/doc-sach/ban-mau-cong-nghe-11-chan-nuoi/11/557/"
	},
	"/doc-sach/ban-mau-cong-nghe-11-cong-nghe-co-khi/11/558/"                : {
		"title"       : "Công nghệ lớp 11: Công nghệ cơ khí (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Công nghệ lớp 11: Công nghệ cơ khí (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/34-ban-mau-cong-nghe-11-cong-nghe-co-khi-11-558-0.png",
		"url"         : "/doc-sach/ban-mau-cong-nghe-11-cong-nghe-co-khi/11/558/"
	},
	"/doc-sach/ban-mau-cong-nghe-4/11/522/"                                  : {
		"title"       : "Công nghệ lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Công nghệ lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/35-ban-mau-cong-nghe-4-11-522-0.png",
		"url"         : "/doc-sach/ban-mau-cong-nghe-4/11/522/"
	},
	"/doc-sach/ban-mau-dao-duc-4/11/518/"                                    : {
		"title"       : "Đạo đức lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Đạo đức lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/36-ban-mau-dao-duc-4-11-518-0.png",
		"url"         : "/doc-sach/ban-mau-dao-duc-4/11/518/"
	},
	"/doc-sach/ban-mau-gdtc-11-bong-da-/11/551/"                             : {
		"title"       : "Giáo dục thể chất lớp 11: Bóng đá (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 11: Bóng đá (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/37-ban-mau-gdtc-11-bong-da-11-551-0.png",
		"url"         : "/doc-sach/ban-mau-gdtc-11-bong-da-/11/551/"
	},
	"/doc-sach/ban-mau-gdtc-11-bong-ro/11/552/"                              : {
		"title"       : "Giáo dục thể chất lớp 11: Bóng rổ (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 11: Bóng rổ (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/38-ban-mau-gdtc-11-bong-ro-11-552-0.png",
		"url"         : "/doc-sach/ban-mau-gdtc-11-bong-ro/11/552/"
	},
	"/doc-sach/ban-mau-gdtc-11-cau-long/11/554/"                             : {
		"title"       : "Giáo dục thể chất lớp 11: Cầu lông (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 11: Cầu lông (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/39-ban-mau-gdtc-11-cau-long-11-554-0.png",
		"url"         : "/doc-sach/ban-mau-gdtc-11-cau-long/11/554/"
	},
	"/doc-sach/ban-mau-gdtc-11-da-cau/11/553/"                               : {
		"title"       : "Giáo dục thể chất lớp 11: Đá cầu (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 11: Đá cầu (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/40-ban-mau-gdtc-11-da-cau-11-553-0.png",
		"url"         : "/doc-sach/ban-mau-gdtc-11-da-cau/11/553/"
	},
	"/doc-sach/ban-mau-giao-duc-kinh-te-va-phap-luat-11/11/555/"             : {
		"title"       : "Giáo dục kinh tế và pháp luật lớp 11 (Bản Mẫu) | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục kinh tế và pháp luật lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/41-ban-mau-giao-duc-kinh-te-va-phap-luat-11-11-555-0.png",
		"url"         : "/doc-sach/ban-mau-giao-duc-kinh-te-va-phap-luat-11/11/555/"
	},
	"/doc-sach/ban-mau-giao-duc-the-chat-4/11/524/"                          : {
		"title"       : "Giáo dục thể chất lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục thể chất lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/42-ban-mau-giao-duc-the-chat-4-11-524-0.png",
		"url"         : "/doc-sach/ban-mau-giao-duc-the-chat-4/11/524/"
	},
	"/doc-sach/ban-mau-hoa-hoc-11/11/547/"                                   : {
		"title"       : "Hóa học lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Hóa học lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/43-ban-mau-hoa-hoc-11-11-547-0.png",
		"url"         : "/doc-sach/ban-mau-hoa-hoc-11/11/547/"
	},
	"/doc-sach/ban-mau-hoat-dong-trai-nghiem-huong-nghiep-11/11/556/"        : {
		"title"       : "Hoạt động trải nghiệm, hướng nghiệp lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Hoạt động trải nghiệm, hướng nghiệp lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/44-ban-mau-hoat-dong-trai-nghiem-huong-nghiep-11-11-556-0.png",
		"url"         : "/doc-sach/ban-mau-hoat-dong-trai-nghiem-huong-nghiep-11/11/556/"
	},
	"/doc-sach/ban-mau-hoat-dong-trai-nghiem-4/11/527/"                      : {
		"title"       : "Hoạt động trải nghiệm lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Hoạt động trải nghiệm lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/45-ban-mau-hoat-dong-trai-nghiem-4-11-527-0.png",
		"url"         : "/doc-sach/ban-mau-hoat-dong-trai-nghiem-4/11/527/"
	},
	"/doc-sach/ban-mau-khoa-hoc-4/11/520/"                                   : {
		"title"       : "Khoa học lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Khoa học lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/46-ban-mau-khoa-hoc-4-11-520-0.png",
		"url"         : "/doc-sach/ban-mau-khoa-hoc-4/11/520/"
	},
	"/doc-sach/ban-mau-lich-su-11/11/550/"                                   : {
		"title"       : "Lịch sử lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Lịch sử lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/47-ban-mau-lich-su-11-11-550-0.png",
		"url"         : "/doc-sach/ban-mau-lich-su-11/11/550/"
	},
	"/doc-sach/ban-mau-lich-su-va-dia-li-4/11/521/"                          : {
		"title"       : "Lịch sử và Địa lý lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Lịch sử và Địa lý lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/48-ban-mau-lich-su-va-dia-li-4-11-521-0.png",
		"url"         : "/doc-sach/ban-mau-lich-su-va-dia-li-4/11/521/"
	},
	"/doc-sach/ban-mau-mi-thuat-4/11/526/"                                   : {
		"title"       : "Mỹ thuật lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Mỹ thuật lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/49-ban-mau-mi-thuat-4-11-526-0.png",
		"url"         : "/doc-sach/ban-mau-mi-thuat-4/11/526/"
	},
	"/doc-sach/ban-mau-ngu-van-11-tap-1/11/542/"                             : {
		"title"       : "Ngữ văn lớp 11 tập 1 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Ngữ văn lớp 11 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/50-ban-mau-ngu-van-11-tap-1-11-542-0.png",
		"url"         : "/doc-sach/ban-mau-ngu-van-11-tap-1/11/542/"
	},
	"/doc-sach/ban-mau-ngu-van-11-tap-2/11/543/"                             : {
		"title"       : "Ngữ văn lớp 11 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Ngữ văn lớp 11 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/51-ban-mau-ngu-van-11-tap-2-11-543-0.png",
		"url"         : "/doc-sach/ban-mau-ngu-van-11-tap-2/11/543/"
	},
	"/doc-sach/ban-mau-sinh-hoc-11/11/548/"                                  : {
		"title"       : "Sinh học lớp 11 (Bản Mẫu) | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Sinh học lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/52-ban-mau-sinh-hoc-11-11-548-0.png",
		"url"         : "/doc-sach/ban-mau-sinh-hoc-11/11/548/"
	},
	"/doc-sach/ban-mau-tieng-anh-11/11/549/"                                 : {
		"title"       : "Tiếng Anh lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tiếng Anh lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/53-ban-mau-tieng-anh-11-11-549-0.png",
		"url"         : "/doc-sach/ban-mau-tieng-anh-11/11/549/"
	},
	"/doc-sach/ban-mau-tieng-viet-4-tap-1/11/514/"                           : {
		"title"       : "Tiếng Việt lớp 4 tập 1 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tiếng Việt lớp 4 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/54-ban-mau-tieng-viet-4-tap-1-11-514-0.png",
		"url"         : "/doc-sach/ban-mau-tieng-viet-4-tap-1/11/514/"
	},
	"/doc-sach/ban-mau-tieng-viet-4-tap-2/11/515/"                           : {
		"title"       : "Tiếng Việt lớp 4 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tiếng Việt lớp 4 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/55-ban-mau-tieng-viet-4-tap-2-11-515-0.png",
		"url"         : "/doc-sach/ban-mau-tieng-viet-4-tap-2/11/515/"
	},
	"/doc-sach/ban-mau-tin-hoc-11-khoa-hoc-may-tinh/11/560/"                 : {
		"title"       : "Tin học lớp 11: Khoa học máy tính (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tin học lớp 11: Khoa học máy tính (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/56-ban-mau-tin-hoc-11-khoa-hoc-may-tinh-11-560-0.png",
		"url"         : "/doc-sach/ban-mau-tin-hoc-11-khoa-hoc-may-tinh/11/560/"
	},
	"/doc-sach/ban-mau-tin-hoc-11-tin-hoc-ung-dung/11/559/"                  : {
		"title"       : "Tin học lớp 11: Tin học ứng dụng (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tin học lớp 11: Tin học ứng dụng (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/57-ban-mau-tin-hoc-11-tin-hoc-ung-dung-11-559-0.png",
		"url"         : "/doc-sach/ban-mau-tin-hoc-11-tin-hoc-ung-dung/11/559/"
	},
	"/doc-sach/ban-mau-tin-hoc-4/11/523/"                                    : {
		"title"       : "Tin học lớp 4 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Tin học lớp 4 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/58-ban-mau-tin-hoc-4-11-523-0.png",
		"url"         : "/doc-sach/ban-mau-tin-hoc-4/11/523/"
	},
	"/doc-sach/ban-mau-toan-11-tap-1/11/544/"                                : {
		"title"       : "Toán lớp 11 tập 1 (Bản Mẫu) | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 11 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/59-ban-mau-toan-11-tap-1-11-544-0.png",
		"url"         : "/doc-sach/ban-mau-toan-11-tap-1/11/544/"
	},
	"/doc-sach/ban-mau-toan-11-tap-2/11/545/"                                : {
		"title"       : "Toán lớp 11 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 11 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/60-ban-mau-toan-11-tap-2-11-545-0.png",
		"url"         : "/doc-sach/ban-mau-toan-11-tap-2/11/545/"
	},
	"/doc-sach/ban-mau-toan-4-tap-1/11/516/"                                 : {
		"title"       : "Toán lớp 4 tập 1 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 4 tập 1 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/1-ban-mau-toan-4-tap-1-11-516-0.png",
		"url"         : "/doc-sach/ban-mau-toan-4-tap-1/11/516/"
	},
	"/doc-sach/ban-mau-toan-4-tap-2/11/517/"                                 : {
		"title"       : "Toán lớp 4 tập 2 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Toán lớp 4 tập 2 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/2-ban-mau-toan-4-tap-2-11-517-0.png",
		"url"         : "/doc-sach/ban-mau-toan-4-tap-2/11/517/"
	},
	"/doc-sach/ban-mau-vat-li-11/11/546/"                                    : {
		"title"       : "Vật lý lớp 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Vật lý lớp 11 (Bản Mẫu) thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/3-ban-mau-vat-li-11-11-546-0.png",
		"url"         : "/doc-sach/ban-mau-vat-li-11/11/546/"
	},
	"/doc-sach/chuyen-de-hoc-tap-am-nhac-10/1/194/"                          : {
		"title"       : "Chuyên đề Âm nhạc lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Âm nhạc lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/4-chuyen-de-hoc-tap-am-nhac-10-1-194-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-am-nhac-10/1/194/"
	},
	"/doc-sach/chuyen-de-hoc-tap-cong-nghe-10-cong-nghe-trong-trot-/1/203/"  : {
		"title"       : "Chuyên đề Công nghệ lớp 10: Công nghệ trồng trọt | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Công nghệ lớp 10: Công nghệ trồng trọt thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/5-chuyen-de-hoc-tap-cong-nghe-10-cong-nghe-trong-trot-1-203-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-cong-nghe-10-cong-nghe-trong-trot-/1/203/"
	},
	"/doc-sach/chuyen-de-hoc-tap-cong-nghe-10-thiet-ke-va-cong-nghe-/1/204/" : {
		"title"       : "Chuyên đề Công nghệ lớp 10: Thiết kế và công nghệ | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Công nghệ lớp 10: Thiết kế và công nghệ thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/6-chuyen-de-hoc-tap-cong-nghe-10-thiet-ke-va-cong-nghe-1-204-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-cong-nghe-10-thiet-ke-va-cong-nghe-/1/204/"
	},
	"/doc-sach/chuyen-de-hoc-tap-dia-li-10/1/197/"                           : {
		"title"       : "Chuyên đề Địa lý lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Địa lý lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/7-chuyen-de-hoc-tap-dia-li-10-1-197-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-dia-li-10/1/197/"
	},
	"/doc-sach/chuyen-de-hoc-tap-giao-duc-kinh-te-va-phap-luat-10/1/202/"    : {
		"title"       : "Chuyên đề Giáo dục kinh tế và pháp luật lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Giáo dục kinh tế và pháp luật lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/8-chuyen-de-hoc-tap-giao-duc-kinh-te-va-phap-luat-10-1-202-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-giao-duc-kinh-te-va-phap-luat-10/1/202/"
	},
	"/doc-sach/chuyen-de-hoc-tap-hoa-hoc-10/1/195/"                          : {
		"title"       : "Chuyên đề Hóa học lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Hóa học lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/9-chuyen-de-hoc-tap-hoa-hoc-10-1-195-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-hoa-hoc-10/1/195/"
	},
	"/doc-sach/chuyen-de-hoc-tap-lich-su-10/1/196/"                          : {
		"title"       : "Chuyên đề Lịch sử lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Lịch sử lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/10-chuyen-de-hoc-tap-lich-su-10-1-196-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-lich-su-10/1/196/"
	},
	"/doc-sach/chuyen-de-hoc-tap-ngu-van-10/1/199/"                          : {
		"title"       : "Chuyên đề Ngữ văn lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Ngữ văn lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/11-chuyen-de-hoc-tap-ngu-van-10-1-199-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-ngu-van-10/1/199/"
	},
	"/doc-sach/chuyen-de-hoc-tap-sinh-hoc-10/1/198/"                         : {
		"title"       : "Chuyên đề Sinh học lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Sinh học lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/12-chuyen-de-hoc-tap-sinh-hoc-10-1-198-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-sinh-hoc-10/1/198/"
	},
	"/doc-sach/chuyen-de-hoc-tap-tin-hoc-10-khoa-hoc-may-tinh-/1/206/"       : {
		"title"       : "Chuyên đề Tin học lớp 10: Khoa học máy tính | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Tin học lớp 10: Khoa học máy tính thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/13-chuyen-de-hoc-tap-tin-hoc-10-khoa-hoc-may-tinh-1-206-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-tin-hoc-10-khoa-hoc-may-tinh-/1/206/"
	},
	"/doc-sach/chuyen-de-hoc-tap-tin-hoc-10-tin-hoc-ung-dung-/1/205/"        : {
		"title"       : "Chuyên đề Tin học lớp 10: Tin học ứng dụng | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Tin học lớp 10: Tin học ứng dụng thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/14-chuyen-de-hoc-tap-tin-hoc-10-tin-hoc-ung-dung-1-205-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-tin-hoc-10-tin-hoc-ung-dung-/1/205/"
	},
	"/doc-sach/chuyen-de-hoc-tap-toan-10/1/200/"                             : {
		"title"       : "Chuyên đề Toán lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Toán lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/15-chuyen-de-hoc-tap-toan-10-1-200-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-toan-10/1/200/"
	},
	"/doc-sach/chuyen-de-hoc-tap-vat-li-10/1/201/"                           : {
		"title"       : "Chuyên đề Vật lý lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Chuyên đề Vật lý lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16-chuyen-de-hoc-tap-vat-li-10-1-201-0.png",
		"url"         : "/doc-sach/chuyen-de-hoc-tap-vat-li-10/1/201/"
	},
	"/doc-sach/cong-nghe-10-cong-nghe-trong-trot/1/162/"                     : {
		"title"       : "Công nghệ lớp 10: Công nghệ trồng trọt | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Công nghệ lớp 10: Công nghệ trồng trọt thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17-cong-nghe-10-cong-nghe-trong-trot-1-162-0.png",
		"url"         : "/doc-sach/cong-nghe-10-cong-nghe-trong-trot/1/162/"
	},
	"/doc-sach/cong-nghe-10-thiet-ke-va-cong-nghe/1/163/"                    : {
		"title"       : "Công nghệ lớp 10: Thiết kế và Công nghệ | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Công nghệ lớp 10: Thiết kế và Công nghệ thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18-cong-nghe-10-thiet-ke-va-cong-nghe-1-163-0.png",
		"url"         : "/doc-sach/cong-nghe-10-thiet-ke-va-cong-nghe/1/163/"
	},
	"/doc-sach/cong-nghe-3/1/130/"                                           : {
		"title"       : "Công nghệ lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Công nghệ lớp 3 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19-cong-nghe-3-1-130-0.png",
		"url"         : "/doc-sach/cong-nghe-3/1/130/"
	},
	"/doc-sach/cong-nghe-6/1/29/"                                            : {
		"title"       : "Công nghệ lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Công nghệ lớp 6 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/20-cong-nghe-6-1-29-0.png",
		"url"         : "/doc-sach/cong-nghe-6/1/29/"
	},
	"/doc-sach/cong-nghe-7/1/150/"                                           : {
		"title"       : "Công nghệ 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Cong-nghe-7-1-150-0.jpg",
		"url"         : "/doc-sach/cong-nghe-7/1/150/"
	},
	"/doc-sach/dao-duc-1/1/4/"                                               : {
		"title"       : "Đạo đức 1 | Sánh Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Cuốn sách Đạo đức 1 giúp các bạn nhỏ học tập, rèn luyện theo các chuẩn mực đạo đức xã hội và kỹ năng sống cần thiết.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Dao-duc-1-4-0.jpg",
		"url"         : "/doc-sach/dao-duc-1/1/4/"
	},
	"/doc-sach/dao-duc-2/1/15/"                                              : {
		"title"       : "Đạo đức 2 | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đạo đức 2 gồm 8 chủ đề, tương ứng 13 bài học. Mỗi bài học gồm 4 phần giúp các bạn nhỏ rèn luyện và phát triển các năng lực. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Dao-duc-2-1-15-0.jpg",
		"url"         : "/doc-sach/dao-duc-2/1/15/"
	},
	"/doc-sach/dao-duc-3/1/127/"                                             : {
		"title"       : "Đạo đức 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đạo đức 3 được biên soạn nhằm giúp học sinh học tập, rèn luyện theo các chuẩn mực đạo đức, pháp luật, kỹ nắng sống cần thiết, phù hợp với lứa tuổi. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Dao-duc-3-1-127-0.jpg",
		"url"         : "/doc-sach/dao-duc-3/1/127/"
	},
	"/doc-sach/dia-li-10/1/158/"                                             : {
		"title"       : "SGK Địa lí 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Địa lí 10 (Cánh Diều) cung cấp kiến thức cơ bản về địa lí đại cương, phát triển phẩm chất, năng lực địa lí cho học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Dia-li-10-1-158-0.jpg",
		"url"         : "/doc-sach/dia-li-10/1/158/"
	},
	"/doc-sach/giao-duc-cong-dan-6/1/27/"                                    : {
		"title"       : "SGK Giáo dục công dân 6 | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục công dân 6 (Cánh Diều), thể hiện đầy đủ nội dung có trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-cong-dan-6-1-27-0.jpg",
		"url"         : "/doc-sach/giao-duc-cong-dan-6/1/27/"
	},
	"/doc-sach/giao-duc-cong-dan-7/1/145/"                                   : {
		"title"       : "SGK Giáo dục công dân 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục công dân 7 (Cánh Diều), thể hiện đầy đủ nội dung có trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-cong-dan-7-1-145-0.jpg",
		"url"         : "/doc-sach/giao-duc-cong-dan-7/1/145/"
	},
	"/doc-sach/giao-duc-kinh-te-va-phap-luat-10/1/167/"                      : {
		"title"       : "SGK Giáo dục Kinh tế và Pháp luật 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Giáo dục Kinh tế và Pháp luật 10 được biên soạn trên cơ sở chương trình môn Giáo dục Công dân giai đoạn định hướng nghề nghiệp. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-kinh-te-va-phap-luat-10-1-167-0.jpg",
		"url"         : "/doc-sach/giao-duc-kinh-te-va-phap-luat-10/1/167/"
	},
	"/doc-sach/giao-duc-quoc-phong-va-an-ninh-10/1/182/"                     : {
		"title"       : "SGK Giáo dục Quốc phòng và an ninh 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "SGK Giáo dục Quốc phòng và an ninh 10 góp phần hình thành, phát triển năng lực nhận thức một số vấn đề về quốc phòng an ninh. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-quoc-phong-va-an-ninh-10-1-182-0.jpg",
		"url"         : "/doc-sach/giao-duc-quoc-phong-va-an-ninh-10/1/182/"
	},
	"/doc-sach/giao-duc-the-chat-1/1/8/"                                     : {
		"title"       : "SGK Giáo dục thể chất 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục thể chất 1 (Cánh Diều), thể hiện đầy đủ nội dung có trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-1-1-8-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-1/1/8/"
	},
	"/doc-sach/giao-duc-the-chat-10-bong-da/1/169/"                          : {
		"title"       : "SGK Giáo dục thể chất 10 - Bóng đá | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục thể chất 10 - Bóng đá (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-10-Bong-da-1-169-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-10-bong-da/1/169/"
	},
	"/doc-sach/giao-duc-the-chat-10-bong-ro/1/171/"                          : {
		"title"       : "SGK Giáo dục thể chất 10 - Bóng rổ | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục thể chất 10 - Bóng rổ (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-10-Bong-ro-1-171-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-10-bong-ro/1/171/"
	},
	"/doc-sach/giao-duc-the-chat-10-cau-long/1/168/"                         : {
		"title"       : "SGK Giáo dục thể chất 10 - Cầu lông | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục thể chất 10 - Cầu lông (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-10-Cau-long-1-168-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-10-cau-long/1/168/"
	},
	"/doc-sach/giao-duc-the-chat-10-da-cau/1/170/"                           : {
		"title"       : "SGK Giáo dục thể chất 10 - Đá cầu | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách Giáo dục thể chất 10 - Đá cầu (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-10-Da-cau-1-170-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-10-da-cau/1/170/"
	},
	"/doc-sach/giao-duc-the-chat-2/1/19/"                                    : {
		"title"       : "SGK Giáo dục thể chất 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách giáo khoa Giáo dục thể chất 2 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-2-1-19-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-2/1/19/"
	},
	"/doc-sach/giao-duc-the-chat-3/1/128/"                                   : {
		"title"       : "SGK Giáo dục thể chất 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách giáo khoa Giáo dục thể chất 3 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-3-1-128-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-3/1/128/"
	},
	"/doc-sach/giao-duc-the-chat-6/1/33/"                                    : {
		"title"       : "SGK Giáo dục thể chất 6 | Sách Cánh Diều ",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách giáo khoa Giáo dục thể chất 6 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-6-1-33-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-6/1/33/"
	},
	"/doc-sach/giao-duc-the-chat-7/1/146/"                                   : {
		"title"       : "SGK Giáo dục thể chất 7 | Sách Cánh Diều ",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu số hóa từ sách giáo khoa Giáo dục thể chất 7 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Giao-duc-the-chat-7-1-146-0.jpg",
		"url"         : "/doc-sach/giao-duc-the-chat-7/1/146/"
	},
	"/doc-sach/gioi-thieu-sach-chuyen-de-am-nhac-11/11/580/"                 : {
		"title"       : "SGK Chuyên đề học tập Âm nhạc 11 (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Chuyên đề học tập Âm nhạc 11 (Cánh Diều), trang bị kiến thức về hòa âm, phần đệm, đặt hợp âm...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-SGK-Am-nhac-11-11-579-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-sach-chuyen-de-am-nhac-11/11/580/"
	},
	"/doc-sach/gioi-thieu-sach-chuyen-de-dia-li-11/11/582/"                  : {
		"title"       : "SGK Chuyên đề học tập Địa lí 11 (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Chuyên đề học tập Địa lí 11 (Cánh Diều) đề cập: Vấn đề khu vực ĐNA. du lịch thế giới, cách mạng công nghiệp 4.0.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-Sach-chuyen-de-Dia-li-11-11-582-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-sach-chuyen-de-dia-li-11/11/582/"
	},
	"/doc-sach/gioi-thieu-sgk-am-nhac-11/11/579/"                            : {
		"title"       : "SGK Âm nhạc 11 (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Âm nhạc 11 (Cánh Diều) giúp học sinh khám phá kiến thức, kỹ năng âm nhạc, hình thành định hướng nghề nghiệp rõ ràng. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-Sach-chuyen-de-Am-nhac-11-11-580-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-sgk-am-nhac-11/11/579/"
	},
	"/doc-sach/gioi-thieu-sgk-dia-li-11/11/581/"                             : {
		"title"       : "SGK Địa lí 11 (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Địa lí 11 (Cánh Diều) giúp học sinh có được những kiến thức về một số vấn đề kinh tế xã hội thế giới...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-SGK-Dia-li-11-11-581-0_(1).jpg",
		"url"         : "/doc-sach/gioi-thieu-sgk-dia-li-11/11/581/"
	},
	"/doc-sach/gioi-thieu-sgk-lich-su-10/11/578/"                            : {
		"title"       : "SGK Lịch sử 10 (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Lịch sử 10 (Cánh Diều) gồm các chuyên đề định hướng nghề nghiệp lĩnh vực khoa học xã hội nhân văn.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-SGK-Lich-su-10-11-578-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-sgk-lich-su-10/11/578/"
	},
	"/doc-sach/gioi-thieu-tieng-anh-10/1/210/"                               : {
		"title"       : "SGK Tiếng Anh 10 - Explore New Worlds (bản mẫu) | Sách Cánh Diều ",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách giới thiệu Tiếng Anh 10 Explore New Words (Cánh Diều) được số hóa dạng sách điện tử.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-Tieng-Anh-10-1-210-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-tieng-anh-10/1/210/"
	},
	"/doc-sach/gioi-thieu-tieng-anh-3/1/208/"                                : {
		"title"       : "SGK Tiếng Anh 3 - Explore Our World (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Tiếng Anh 3 - Explore Our World giúp học sinh phát triển ngôn ngữ, kỹ năng, kiến thức cần thiết hiểu về thế giới xung quanh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-Tieng-Anh-3-1-208-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-tieng-anh-3/1/208/"
	},
	"/doc-sach/gioi-thieu-tieng-anh-7/1/207/"                                : {
		"title"       : "SGK Tiếng Anh 7 - Explore English (bản mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Tiếng Anh 7 - Explore English hỗ trợ học sinh phát huy tính chủ động, sáng tạo trong học Tiếng Anh theo Chương trình GDPT 2018.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Gioi-thieu-Tieng-Anh-7-1-207-0.jpg",
		"url"         : "/doc-sach/gioi-thieu-tieng-anh-7/1/207/"
	},
	"/doc-sach/hoa-hoc-10/1/166/"                                            : {
		"title"       : "SGK Hóa học 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hóa học 10 tập trung vào cơ sở hóa học chung. Học sinh cần nắm vững các quy luật, bản chất hóa học để hiểu và vận dụng nó. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoa-hoc-10-1-160-0.jpg",
		"url"         : "/doc-sach/hoa-hoc-10/1/166/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-10/1/172/"                 : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoạt động trải nghiệm hướng nghiệp 10 gồm 9 chủ đề với 4 mạch nội dung: Hướng vào bản thân, xã hội, tự nhiên và hướng nghiệp.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-huong-nghiep-10-1-172-0.jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-10/1/172/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-6/1/34/"                   : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoạt động trải nghiệm hướng nghiệp 6 giúp học sinh khám phá bản thân, thế giới xung quanh, phát triển đời sống tâm hồn...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-huong-nghiep-6-1-34-0.jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-6/1/34/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-7/1/151/"                  : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoạt động trải nghiệm hướng nghiệp 7 bao quát 4 nội dung: Hướng vào bản thân, xã hội, tự nhiên và hướng nghiệp. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-huong-nghiep-7-1-151-0.jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-huong-nghiep-7/1/151/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-1/1/10/"                                : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoạt động trải nghiệm hướng nghiệp 1 được thể hiện trong tiết sinh hoạt dưới cờ, hoạt động giáo dục theo chủ đề và sinh hoạt lớp.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-huong-nghiep-10-1-172-0_(1).jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-1/1/10/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-2/1/21/"                                : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Hoạt động trải nghiệm hướng nghiệp 2 giúp học sinh biết chăm sóc, bảo vệ bản thân, yêu thương chia sẻ công việc với người thân...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-2-1-21-0.jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-2/1/21/"
	},
	"/doc-sach/hoat-dong-trai-nghiem-3/1/136/"                               : {
		"title"       : "SGK Hoạt động trải nghiệm hướng nghiệp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Hoạt động trải nghiệm hướng nghiệp 3 (Cánh Diều), thể hiện đầy đủ chương trình học chính thức.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoat-dong-trai-nghiem-3-1-136-0_(1).jpg",
		"url"         : "/doc-sach/hoat-dong-trai-nghiem-3/1/136/"
	},
	"/doc-sach/khoa-hoc-tu-nhien-6/1/25/"                                    : {
		"title"       : "SGK Khoa học tự nhiên 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Khoa học tự nhiên 6 (Cánh Diều) giới thiệu về khoa học tự nhiên và các phép đo, chất và sự biến đổi chất, vật sống...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Khoa-hoc-tu-nhien-6-1-25-0.jpg",
		"url"         : "/doc-sach/khoa-hoc-tu-nhien-6/1/25/"
	},
	"/doc-sach/khoa-hoc-tu-nhien-7/1/143/"                                   : {
		"title"       : "SGK Khoa học tự nhiên 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Khoa học tự nhiên 7 (Cánh Diều) cung cấp kiến thức và kĩ năng cốt lõi của khoa học tự nhiên, nâng cao khả năng khám phá của học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Khoa-hoc-tu-nhien-7-1-143-0.jpg",
		"url"         : "/doc-sach/khoa-hoc-tu-nhien-7/1/143/"
	},
	"/doc-sach/lich-su-10/1/159/"                                            : {
		"title"       : "SGK Lịch sử 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Lịch sử 10 (Cánh Diều) hướng học sinh tìm hiểu sâu hơn các lĩnh vực: Lịch sử sử học, vai trò sử học,nền văn minh thế giới...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Lich-su-10-1-159-0.jpg",
		"url"         : "/doc-sach/lich-su-10/1/159/"
	},
	"/doc-sach/lich-su-va-dia-li-6/1/26/"                                    : {
		"title"       : "SGK Lịch sử và Địa lí 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Lịch sử và Địa lí 6 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Lich-su-va-Dia-li-6-1-26-0.jpg",
		"url"         : "/doc-sach/lich-su-va-dia-li-6/1/26/"
	},
	"/doc-sach/lich-su-va-dia-li-7/1/144/"                                   : {
		"title"       : "SGK Lịch sử và Địa lí 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Lịch sử và Địa lí 7 (Cánh Diều), thể hiện đầy đủ nội dung chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Lich-su-va-Dia-li-7-1-144-0.jpg",
		"url"         : "/doc-sach/lich-su-va-dia-li-7/1/144/"
	},
	"/doc-sach/mi-thuat-1/1/7/"                                              : {
		"title"       : "SGK Mĩ thuật 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Mĩ thuật 1 (Cánh Diều) giúp học sinh làm quen với các hoạt động vẽ tranh, tạo hình bằng đất nặn, trang trí đồ vật, làm thủ công...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Mi-thuat-1-1-7-0.jpg",
		"url"         : "/doc-sach/mi-thuat-1/1/7/"
	},
	"/doc-sach/mi-thuat-2/1/18/"                                             : {
		"title"       : "SGK Mĩ thuật 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Mĩ thuật 2 (Cánh Diều) có nhiều hoạt động:Vẽ, in tranh, tạo hình với đất nặn, làm thủ công, trang trí đồ vật...",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Mi-thuat-2-1-18-0.jpg",
		"url"         : "/doc-sach/mi-thuat-2/1/18/"
	},
	"/doc-sach/mi-thuat-3/1/126/"                                            : {
		"title"       : "SGK Mĩ thuật 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Mĩ thuật 3 (Cánh Diều), thể hiện đầy đủ nội dung trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Mi-thuat-3-1-126-0.jpg",
		"url"         : "/doc-sach/mi-thuat-3/1/126/"
	},
	"/doc-sach/mi-thuat-6/1/32/"                                             : {
		"title"       : "SGK Mĩ thuật 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Mĩ thuật 6 (Cánh Diều), thể hiện đầy đủ nội dung trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Mi-thuat-6-1-32-0.jpg",
		"url"         : "/doc-sach/mi-thuat-6/1/32/"
	},
	"/doc-sach/mi-thuat-7/1/149/"                                            : {
		"title"       : "SGK Mĩ thuật 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là tài liệu được số hóa từ SGK Mĩ thuật 7 (Cánh Diều), thể hiện đầy đủ nội dung trong chương trình học chính thức. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Mi-thuat-7-1-149-0.jpg",
		"url"         : "/doc-sach/mi-thuat-7/1/149/"
	},
	"/doc-sach/ngu-van-10-tap-1/1/153/"                                      : {
		"title"       : "SGK Ngữ văn 10 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "SGK Ngữ văn 10 tập 1 (Cánh Diều) vừa thừa kế mô hình sách Ngữ văn THCS vừa có những thay đổi phù hợp với đối tượng học sinh. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-10-tap-1-1-153-0.jpg",
		"url"         : "/doc-sach/ngu-van-10-tap-1/1/153/"
	},
	"/doc-sach/ngu-van-10-tap-2/1/154/"                                      : {
		"title"       : "SGK Ngữ văn 10 tập 2 | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "SGK Ngữ văn 10 tập 2 (Cánh Diều) vừa thừa kế mô hình sách Ngữ văn THCS vừa có những thay đổi phù hợp với đối tượng học sinh. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-10-tap-2-1-154-0.jpg",
		"url"         : "/doc-sach/ngu-van-10-tap-2/1/154/"
	},
	"/doc-sach/ngu-van-6-1/1/22/"                                            : {
		"title"       : "SGK Ngữ văn 6 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "SGK Ngữ văn 6 biên soạn theo Chương trình GDPT 2018. Các bài học chính trong sách thiết kế theo hướng tích hợp. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-6-1-1-22-0.jpg",
		"url"         : "/doc-sach/ngu-van-6-1/1/22/"
	},
	"/doc-sach/ngu-van-6-2/1/23/"                                            : {
		"title"       : "SGK Ngữ văn 6 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "SGK Ngữ văn 6 biên soạn theo Chương trình GDPT 2018. Các bài học chính trong sách thiết kế theo hướng tích hợp. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-6-2-1-23-0.jpg",
		"url"         : "/doc-sach/ngu-van-6-2/1/23/"
	},
	"/doc-sach/ngu-van-7-tap-1/1/139/"                                       : {
		"title"       : "Ngữ văn 7 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Ngữ văn 7 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-7-tap-139-0.png",
		"url"         : "/doc-sach/ngu-van-7-tap-1/1/139/"
	},
	"/doc-sach/ngu-van-7-tap-2/1/140/"                                       : {
		"title"       : "Ngữ văn 7 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Ngữ văn 7 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Ngu-van-7-tap-2-140-0.png",
		"url"         : "/doc-sach/ngu-van-7-tap-2/1/140/"
	},
	"/doc-sach/sbt-cong-nghe-6/5/84/"                                        : {
		"title"       : "Bài tập Công nghệ 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Công nghệ 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Cong-nghe-6-5-84-0.png",
		"url"         : "/doc-sach/sbt-cong-nghe-6/5/84/"
	},
	"/doc-sach/sbt-cong-nghe-7/5/304/"                                       : {
		"title"       : "Bài tập Công nghệ 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Công nghệ 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Cong-nghe-7-5-304-0.png",
		"url"         : "/doc-sach/sbt-cong-nghe-7/5/304/"
	},
	"/doc-sach/sbt-dia-li-10/5/300/"                                         : {
		"title"       : "Bài tập Địa lí 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Địa lý 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Dia-li-10-5-300-0.png",
		"url"         : "/doc-sach/sbt-dia-li-10/5/300/"
	},
	"/doc-sach/sbt-giao-duc-cong-dan-6/5/85/"                                : {
		"title"       : "Bài tập Giáo dục công dân 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Giáo dục công dân 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Giao-duc-cong-dan-6-5-85-0.png",
		"url"         : "/doc-sach/sbt-giao-duc-cong-dan-6/5/85/"
	},
	"/doc-sach/sbt-giao-duc-cong-dan-7/5/303/"                               : {
		"title"       : "Bài tập Giáo dục công dân 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Giáo dục công dân 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Giao-duc-cong-dan-7-5-303-0.png",
		"url"         : "/doc-sach/sbt-giao-duc-cong-dan-7/5/303/"
	},
	"/doc-sach/sbt-giao-duc-kinh-te-va-phap-luat/5/302/"                     : {
		"title"       : "Bài tập Giáo dục Kinh tế và Pháp luật 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Giáo dục Kinh tế và Pháp luật 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Giao-duc-Kinh-te-va-Phap-luat-5-302-0.png",
		"url"         : "/doc-sach/sbt-giao-duc-kinh-te-va-phap-luat/5/302/"
	},
	"/doc-sach/sbt-giao-duc-quoc-phong-va-anh-ninh-10/5/298/"                : {
		"title"       : "Bài tập Giáo dục Quốc phòng và An ninh 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Giáo dục Quốc phòng và An ninh 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Giao-duc-Quoc-phong-va-Anh-ninh-10-5-298-0.png",
		"url"         : "/doc-sach/sbt-giao-duc-quoc-phong-va-anh-ninh-10/5/298/"
	},
	"/doc-sach/sbt-hoa-hoc-10/5/317/"                                        : {
		"title"       : "Bài tập Hóa học 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Hóa học 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Hoa-hoc-10-5-317-0.png",
		"url"         : "/doc-sach/sbt-hoa-hoc-10/5/317/"
	},
	"/doc-sach/sbt-khoa-hoc-tu-nhien-6/5/55/"                                : {
		"title"       : "Bài tập Khoa học tự nhiên 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Khoa học tự nhiên 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Khoa-hoc-tu-nhien-6-5-55-0.png",
		"url"         : "/doc-sach/sbt-khoa-hoc-tu-nhien-6/5/55/"
	},
	"/doc-sach/sbt-khoa-hoc-tu-nhien-7/5/316/"                               : {
		"title"       : "Bài tập Khoa học tự nhiên 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Khoa học tự nhiên 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Khoa-hoc-tu-nhien-7-5-316-0.png",
		"url"         : "/doc-sach/sbt-khoa-hoc-tu-nhien-7/5/316/"
	},
	"/doc-sach/sbt-lich-su-10/5/307/"                                        : {
		"title"       : "Bài tập Lịch sử 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Lịch sử 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Lich-su-10-5-307-0.png",
		"url"         : "/doc-sach/sbt-lich-su-10/5/307/"
	},
	"/doc-sach/sbt-lich-su-va-dia-li-6/5/56/"                                : {
		"title"       : "Bài tập Lịch sử và Địa lí 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Lịch sử và Địa lí 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Lich-su-va-Dia-li-6-5-56-0.png",
		"url"         : "/doc-sach/sbt-lich-su-va-dia-li-6/5/56/"
	},
	"/doc-sach/sbt-lich-su-va-dia-li-7/5/296/"                               : {
		"title"       : "Bài tập Lịch sử và Địa lí 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Lịch sử và Địa lí 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Lich-su-va-Dia-li-7-5-296-0.png",
		"url"         : "/doc-sach/sbt-lich-su-va-dia-li-7/5/296/"
	},
	"/doc-sach/sbt-ngu-van-10-tap-1/5/288/"                                  : {
		"title"       : "Bài tập Ngữ Văn 10 tập 1 | Sách Cánh Diều",
 "robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 10 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-van-10-tap-5-288-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-10-tap-1/5/288/"
	},
	"/doc-sach/sbt-ngu-van-10-tap-2/5/289/"                                  : {
		"title"       : "Bài tập Ngữ Văn 10 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 10 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-Van-10-tap-2-5-289-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-10-tap-2/5/289/"
	},
	"/doc-sach/sbt-ngu-van-6-tap-1/5/50/"                                    : {
		"title"       : "Bài tập Ngữ Văn 6 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 6 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-Van-6-tap-5-50-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-6-tap-1/5/50/"
	},
	"/doc-sach/sbt-ngu-van-6-tap-2/5/174/"                                   : {
		"title"       : "Bài tập Ngữ Văn 6 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 6 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-Van-6-tap-2-5-174-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-6-tap-2/5/174/"
	},
	"/doc-sach/sbt-ngu-van-7-tap-1/5/282/"                                   : {
		"title"       : "Bài tập Ngữ Văn 7 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 7 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-Van-7-tap-5-282-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-7-tap-1/5/282/"
	},
	"/doc-sach/sbt-ngu-van-7-tap-2/5/283/"                                   : {
		"title"       : "Bài tập Ngữ Văn 7 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Ngữ Văn 7 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Ngu-Van-7-tap-2-5-283-0.png",
		"url"         : "/doc-sach/sbt-ngu-van-7-tap-2/5/283/"
	},
	"/doc-sach/sbt-sinh-hoc-10/5/308/"                                       : {
		"title"       : "Bài tập Sinh học 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Sinh học 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Sinh-hoc-10-5-308-0.png",
		"url"         : "/doc-sach/sbt-sinh-hoc-10/5/308/"
	},
	"/doc-sach/sbt-tieng-anh-1/5/80/"                                        : {
		"title"       : "Sách bài tập Tiếng Anh 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-5-80-0.png",
		"url"         : "/doc-sach/sbt-tieng-anh-1/5/80/"
	},
	"/doc-sach/sbt-tieng-anh-10/5/212/"                                      : {
		"title"       : "Sách bài tập Tiếng Anh 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-10-5-212-0.png",
		"url"         : "/doc-sach/sbt-tieng-anh-10/5/212/"
	},
	"/doc-sach/sbt-tieng-anh-2/5/81/"                                        : {
		"title"       : "Sách bài tập Tiếng Anh 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-2-5-80.png",
		"url"         : "/doc-sach/sbt-tieng-anh-2/5/81/"
	},
	"/doc-sach/sbt-tieng-anh-3/5/273/"                                       : {
		"title"       : "Sách bài tập Tiếng Anh 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 3 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-3-5-273-0.png",
		"url"         : "/doc-sach/sbt-tieng-anh-3/5/273/"
	},
	"/doc-sach/sbt-tieng-anh-6/5/82/"                                        : {
		"title"       : "Sách bài tập Tiếng Anh 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-6-5-82-0.png",
		"url"         : "/doc-sach/sbt-tieng-anh-6/5/82/"
	},
	"/doc-sach/sbt-tieng-anh-7/5/275/"                                       : {
		"title"       : "Sách bài tập Tiếng Anh 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tiếng Anh 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tieng-Anh-7-5-275-0.png",
		"url"         : "/doc-sach/sbt-tieng-anh-7/5/275/"
	},
	"/doc-sach/sbt-tin-hoc-10/5/306/"                                        : {
		"title"       : "Bài tập Tin học 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tin học 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tin-hoc-10-5-306-0.png",
		"url"         : "/doc-sach/sbt-tin-hoc-10/5/306/"
	},
	"/doc-sach/sbt-tin-hoc-3/5/297/"                                         : {
		"title"       : "Bài tập Tin học 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tin học 3 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tin-hoc-3-5-297-0.png",
		"url"         : "/doc-sach/sbt-tin-hoc-3/5/297/"
	},
	"/doc-sach/sbt-tin-hoc-6/5/54/"                                          : {
		"title"       : "Bài tập Tin học 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tin học 6 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tin-hoc-6-5-54-0.png",
		"url"         : "/doc-sach/sbt-tin-hoc-6/5/54/"
	},
	"/doc-sach/sbt-tin-hoc-7/5/299/"                                         : {
		"title"       : "Bài tập Tin học 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Tin học 7 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Tin-hoc-7-5-299-0.png",
		"url"         : "/doc-sach/sbt-tin-hoc-7/5/299/"
	},
	"/doc-sach/sbt-toan-10-tap-1/5/286/"                                     : {
		"title"       : "Bài tập Toán 10 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 10 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-10-tap-5-286-0.png",
		"url"         : "/doc-sach/sbt-toan-10-tap-1/5/286/"
	},
	"/doc-sach/sbt-toan-10-tap-2/5/287/"                                     : {
		"title"       : "Bài tập Toán 10 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 10 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-10-tap-2-5-287-0.png",
		"url"         : "/doc-sach/sbt-toan-10-tap-2/5/287/"
	},
	"/doc-sach/sbt-toan-6-tap-1/5/52/"                                       : {
		"title"       : "Bài tập Toán 6 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 6 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-6-tap-5-52-0.png",
		"url"         : "/doc-sach/sbt-toan-6-tap-1/5/52/"
	},
	"/doc-sach/sbt-toan-6-tap-2/5/178/"                                      : {
		"title"       : "Bài tập Toán 6 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 6 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-6-tap-2-5-178-0.png",
		"url"         : "/doc-sach/sbt-toan-6-tap-2/5/178/"
	},
	"/doc-sach/sbt-toan-7-tap-1/5/284/"                                      : {
		"title"       : "Bài tập Toán 7 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 7 tập 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-7-tap-5-284-0.png",
		"url"         : "/doc-sach/sbt-toan-7-tap-1/5/284/"
	},
	"/doc-sach/sbt-toan-7-tap-2/5/285/"                                      : {
		"title"       : "Bài tập Toán 7 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Toán 7 tập 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Toan-7-tap-2-5-285-0.png",
		"url"         : "/doc-sach/sbt-toan-7-tap-2/5/285/"
	},
	"/doc-sach/sbt-vat-li-10/5/305/"                                         : {
		"title"       : "Bài tập Vật lí 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn sách bài tập Vật lí 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SBT-Vat-li-10-5-305-0.png",
		"url"         : "/doc-sach/sbt-vat-li-10/5/305/"
	},
	"/doc-sach/sgt-giao-duc-quoc-phong-va-an-ninh-11/9/583/"                 : {
		"title"       : "SGK Giáo dục quốc phòng và an ninh 11 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Giáo dục quốc phòng và an ninh 11 (Bản Mẫu) thuộc Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGT-Giao-duc-quoc-phong-va-an-ninh-19-583-0.png",
		"url"         : "/doc-sach/sgt-giao-duc-quoc-phong-va-an-ninh-11/9/583/"
	},
	"/doc-sach/sgt-lich-su-va-dia-li-8/11/340/"                              : {
		"title"       : "SGK Lịch sử và Địa lí 8 (Bản Mẫu) | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản giới thiệu sách Lịch sử và Địa lí 8 (Bản Mẫu) thuộc Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGT-Lich-su-va-Dia-li-8-1340-0.png",
		"url"         : "/doc-sach/sgt-lich-su-va-dia-li-8/11/340/"
	},
	"/doc-sach/sgv-am-nhac-1/3/113/"                                         : {
		"title"       : "Sách giáo viên Âm nhạc 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn Sách giáo viên Âm nhạc 1 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGV-Am-nhac-3-113-0.png",
		"url"         : "/doc-sach/sgv-am-nhac-1/3/113/"
	},
	"/doc-sach/sgv-am-nhac-10/3/250/"                                        : {
		"title"       : "Sách giáo viên Âm nhạc 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn Sách giáo viên Âm nhạc 10 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGV-Am-nhac-10-3-250-0.png",
		"url"         : "/doc-sach/sgv-am-nhac-10/3/250/"
	},
	"/doc-sach/sgv-am-nhac-2/3/57/"                                          : {
		"title"       : "Sách giáo viên Âm nhạc 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn Sách giáo viên Âm nhạc 2 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGV-Am-nhac-2-3-57-0.png",
		"url"         : "/doc-sach/sgv-am-nhac-2/3/57/"
	},
	"/doc-sach/sgv-am-nhac-3/3/290/"                                         : {
		"title"       : "Sách giáo viên Âm nhạc 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn Sách giáo viên Âm nhạc 3 thuộc bộ sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/SGV-Am-nhac-3-3-290-0.png",
		"url"         : "/doc-sach/sgv-am-nhac-3/3/290/"
	},
	"/doc-sach/sgv-am-nhac-6/3/58/"                                          : {
		"title"       : "Sách Giáo viên - Âm nhạc lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách giáo viên Âm nhạc lớp 6 thuộc bộ sách Cánh Diều giúp hỗ trợ quý thầy cô định hướng bài giảng cho học sinh hiệu quả hơn.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16462-SGV-Am-nhac-6-3-58-0.jpg",
		"url"         : "/doc-sach/sgv-am-nhac-6/3/58/"
	},
	"/doc-sach/sgv-am-nhac-7/3/291/"                                         : {
		"title"       : "Sách Giáo viên - Âm nhạc lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách giáo viên Âm nhạc lớp 7 thuộc bộ sách Cánh Diều được biên soạn nhằm hỗ trợ thầy cô có thêm tư liệu để dạy tốt sách giáo khoa Âm nhạc 7 cho học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16560-SGV-Am-nhac-7-3-291-0.jpg",
		"url"         : "/doc-sach/sgv-am-nhac-7/3/291/"
	},
	"/doc-sach/sgv-cong-nghe-10-cong-nghe-trong-trot/3/259/"                 : {
		"title"       : "Sách Giáo viên Công nghệ 10 - Công nghệ trồng trọt | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Công nghệ 10 - Công nghệ trồng trọt - Cánh Diều được biên soạn nhằm giúp giáo viên xây dựng, thực hiện bài giảng môn Công nghệ tốt nhất.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16651-SGV-Cong-nghe-10-Cong-nghe-trong-trot-3-259-0.jpg",
		"url"         : "/doc-sach/sgv-cong-nghe-10-cong-nghe-trong-trot/3/259/"
	},
	"/doc-sach/sgv-cong-nghe-10-thiet-ke-va-cong-nghe/3/257/"                : {
		"title"       : "Sách Giáo viên Công nghệ 10 - Thiết kế và công nghệ | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Công nghệ 10 - Thiết kế và công nghệ - Cánh Diều là tài liệu hướng dẫn thầy cô tổ chức dạy và kiểm tra chất lượng học tập theo SGK Công nghệ 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16651-SGV-Cong-nghe-10-Thiet-ke-va-cong-nghe-3-257-0.jpg",
		"url"         : "/doc-sach/sgv-cong-nghe-10-thiet-ke-va-cong-nghe/3/257/"
	},
	"/doc-sach/sgv-cong-nghe-3/3/260/"                                       : {
		"title"       : "Sách Giáo viên Công nghệ lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Công nghệ lớp 3 - Cánh Diều là tài liệu giúp quý thầy cô cấp tiểu học về việc dạy học môn Công nghệ theo chương trình GDPT.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16682-SGV-Cong-nghe-3-3-260-0.jpg",
		"url"         : "/doc-sach/sgv-cong-nghe-3/3/260/"
	},
	"/doc-sach/sgv-cong-nghe-6/3/59/"                                        : {
		"title"       : "Sách Giáo viên Công nghệ lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Công nghệ lớp 6 - Cánh Diều được biên soạn giúp giáo viên tổ chức việc giảng dạy, kiểm tra đánh giá năng lực của học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16708-SGV-Cong-nghe-6-3-59-0.jpg",
		"url"         : "/doc-sach/sgv-cong-nghe-6/3/59/"
	},
	"/doc-sach/sgv-cong-nghe-7/3/253/"                                       : {
		"title"       : "Sách Giáo viên Công nghệ lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Công nghệ 7 - Cánh Diều là tài liệu hướng dẫn, gợi ý cho giáo viên triển khai kế hoạch dạy học nhằm phát triển phẩm chất và năng lực của học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16846-SGV-Cong-nghe-7-3-253-0.jpg",
		"url"         : "/doc-sach/sgv-cong-nghe-7/3/253/"
	},
	"/doc-sach/sgv-dao-duc-1/3/120/"                                         : {
		"title"       : "Sách Giáo viên Đạo đức lớp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Đạo đức lớp 1 - Cánh Diều là tài liệu hỗ trợ thầy cô triển khai các bài dạy hiệu quả, giúp học sinh nhận biết và tiếp thu về các chuẩn mực đạo đức.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/16896-SGV-Dao-duc-1-3-120-0.jpg",
		"url"         : "/doc-sach/sgv-dao-duc-1/3/120/"
	},
	"/doc-sach/sgv-dao-duc-2/3/60/"                                          : {
		"title"       : "Sách Giáo viên Đạo đức lớp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Đạo đức lớp 1 - Cánh Diều là tài liệu hữu ích giúp thầy cô tổ chức dạy học hiệu quả các bài trong SGK đạo đức 2 (Cánh Diều).",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17014-SGV-Dao-duc-2-3-60-0.jpg",
		"url"         : "/doc-sach/sgv-dao-duc-2/3/60/"
	},
	"/doc-sach/sgv-dao-duc-3/3/261/"                                         : {
		"title"       : "Sách Giáo viên - Đạo đức lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Đạo đức lớp 3 - Cánh Diều được biên soạn nhằm hướng dẫn, hỗ trợ thầy cô dạy học theo SGK Đạo Đức 3 dễ dàng và hiệu quả nhất.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17136-SGV-Dao-duc-3-3-261-0.jpg",
		"url"         : "/doc-sach/sgv-dao-duc-3/3/261/"
	},
	"/doc-sach/sgv-dia-li-10/3/248/"                                         : {
		"title"       : "Sách Giáo viên - Địa lí lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Địa lí lớp 10 - Cánh Diều được biên soạn với mục đích gợi mở, tư vấn thiết kế các hoạt động trong tổ chức dạy học cho giáo viên.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17188-SGV-Dia-li-10-3-248-0.jpg",
		"url"         : "/doc-sach/sgv-dia-li-10/3/248/"
	},
	"/doc-sach/sgv-gdtc-1/3/118/"                                            : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 1 - Cánh Diều là tài liệu hướng dẫn thầy cô dạy học theo SGK, đồng thời kiểm tra mức độ tiếp thu kiến thức, hình thành kỹ năng của học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17233-SGV-GDTC-1-3-118-0.jpg",
		"url"         : "/doc-sach/sgv-gdtc-1/3/118/"
	},
	"/doc-sach/sgv-giao-duc-cong-dan-6/3/61/"                                : {
		"title"       : "Sách Giáo viên - Giáo dục công dân lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục công dân lớp 6 - Cánh Diều là tài liệu giúp thầy cô triển khai các bài giảng, giúp học sinh rèn luyện đạo đức, kỹ năng sống, kinh tế và pháp luật.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17291-SGV-Giao-duc-cong-dan-6-3-61-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-cong-dan-6/3/61/"
	},
	"/doc-sach/sgv-giao-duc-cong-dan-7/3/271/"                               : {
		"title"       : "Sách Giáo viên - Giáo dục công dân lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục công dân lớp 7 thuộc bộ sách Cánh Diều là tài liệu dành cho giáo viên dạy học môn Giáo dục công dân lớp 7 theo chương trình GDPT 2018.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17397-SGV-Giao-duc-cong-dan-7-3-271-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-cong-dan-7/3/271/"
	},
	"/doc-sach/sgv-giao-duc-kinh-te-va-phap-luat-10/3/272/"                  : {
		"title"       : "Sách Giáo viên - Giáo dục kinh tế và pháp luật lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục kinh tế và pháp luật lớp 10 thuộc bộ sách Cánh Diều được biên soạn hỗ trợ thầy cô giảng dạy môn Giáo dục kinh tế và pháp luật lớp 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17453-SGV-Giao-duc-kinh-te-va-phap-luat-10-3-272-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-kinh-te-va-phap-luat-10/3/272/"
	},
	"/doc-sach/sgv-giao-duc-quoc-phong-va-an-ninh-10/3/246/"                 : {
		"title"       : "Sách Giáo viên - Giáo dục quốc phòng an ninh lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục quốc phòng an ninh lớp 10 - Cánh Diều là tài liệu hỗ trợ thầy cô dạy học nhằm giúp học sinh phát triển các phẩm chất, năng lực chung về quốc phòng an ninh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17520-SGV-Giao-duc-quoc-phong-va-an-ninh-10-3-246-2.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-quoc-phong-va-an-ninh-10/3/246/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-10-bong-da/3/244/"                      : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 10 - Bóng đá | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 10 - Bóng đá - Cánh Diều được biên soạn nhằm giúp thầy cô hoàn thành tốt công tác dạy học môn Giáo dục thể chất 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17547-SGV-Giao-duc-the-chat-10-Bong-da-3-244-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-10-bong-da/3/244/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-10-bong-ro/3/243/"                      : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 10 - Bóng rổ | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 10 - Bóng đá - Cánh Diều được biên soạn nhằm giúp thầy cô sử dụng linh hoạt và hiệu quả SGK Giáo dục thể chất bóng đá lớp 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17576-SGV-Giao-duc-the-chat-10-Bong-ro-3-243-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-10-bong-ro/3/243/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-10-cau-long/3/242/"                     : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 10 - Cầu lông | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 10 - Cầu lông - Cánh Diều được biên soạn nhằm giúp thầy cô hoàn thành tốt công tác dạy học môn Giáo dục thể chất 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17607-SGV-Giao-duc-the-chat-10-Cau-long-3-242-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-10-cau-long/3/242/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-10-da-cau/3/241/"                       : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 10 - Đá cầu | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 10 - Đá cầu thuộc bộ Cánh Diều là tài liệu hướng dẫn thầy cô dạy học môn Giáo dục thể chất lớp 10 theo SGK hiệu quả nhất.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17635-SGV-Giao-duc-the-chat-10-Da-cau-3-241-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-10-da-cau/3/241/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-2/3/62/"                                : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 2 thuộc bộ sách Cánh Diều là tài liệu hướng dẫn giáo viên dạy học theo SGK Giáo dục thể chất 2.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17662-SGV-Giao-duc-the-chat-2-3-62-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-2/3/62/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-3/3/256/"                               : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 3 - Cánh Diều là tài liệu hỗ trợ giáo viên sử dụng linh hoạt và hiệu quả SGK Giáo dục thể chất lớp 3.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17780-SGV-Giao-duc-the-chat-3-3-256-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-3/3/256/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-6/3/63/"                                : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 6 - Cánh Diều là tài liệu được biên soạn nhằm hỗ trợ giáo viên sử dụng linh hoạt và hiệu quả SGK Giáo dục thể chất lớp 6.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17802-SGV-Giao-duc-the-chat-6-3-63-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-6/3/63/"
	},
	"/doc-sach/sgv-giao-duc-the-chat-7/3/270/"                               : {
		"title"       : "Sách Giáo viên - Giáo dục thể chất lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Giáo dục thể chất lớp 7 - Cánh Diều được biên soạn nhằm giúp thầy cô hoàn thành tốt công tác dạy học môn Giáo dục thể chất 7.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17948-SGV-Giao-duc-the-chat-7-3-270-0.jpg",
		"url"         : "/doc-sach/sgv-giao-duc-the-chat-7/3/270/"
	},
	"/doc-sach/sgv-hdtn-1/3/117/"                                            : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm lớp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Hoạt động trải nghiệm lớp 1 thuộc bộ sách Cánh Diều là tài liệu giúp thầy cô tổ chức hoạt động trải nghiệm cho học sinh theo chương trình GDPT mới.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/17985-SGV-HDTN-1-3-117-0.jpg",
		"url"         : "/doc-sach/sgv-hdtn-1/3/117/"
	},
	"/doc-sach/sgv-hdtn-6/3/65/"                                             : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm hướng nghiệp lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Hoạt động trải nghiệm, hướng nghiệp lớp 6 Sách Cánh Diều đóng vai trò hỗ trợ giáo viên tổ chức các hoạt động trải nghiệm trong nhà trường.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18107-SGV-HDTN-6-3-65-0.jpg",
		"url"         : "/doc-sach/sgv-hdtn-6/3/65/"
	},
	"/doc-sach/sgv-hoa-hoc-10/3/239/"                                        : {
		"title"       : "Sách Giáo viên - Hóa học lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Hóa học lớp 10 thuộc bộ Sách Cánh Diều giúp giáo viên có định hướng về việc dạy học hiệu quả nhằm phát triển phẩm chất, năng lực của học sinh. ",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18265-SGV-Hoa-hoc-10-3-239-0.jpg",
		"url"         : "/doc-sach/sgv-hoa-hoc-10/3/239/"
	},
	"/doc-sach/sgv-hoat-dong-trai-nghiem-huong-nghiep-7/3/269/"              : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm hướng nghiệp lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Hoạt động trải nghiệm hướng nghiệp lớp 7 Sách Cánh Diều có vai trò hướng dẫn giáo viên tổ chức hoạt động trải nghiệm trong nhà trường.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18325-SGV-Hoat-dong-trai-nghiem-huong-nghiep-7-3-269-0.jpg",
		"url"         : "/doc-sach/sgv-hoat-dong-trai-nghiem-huong-nghiep-7/3/269/"
	},
	"/doc-sach/sgv-hoat-dong-trai-nghiem-10/3/240/"                          : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm hướng nghiệp lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Hoạt động trải nghiệm hướng nghiệp lớp 10 Sách Cánh Diều được biên soạn nhằm hướng dẫn giáo viên tổ chức hoạt động trải nghiệm trong nhà trường.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18356-SGV-Hoat-dong-trai-nghiem-10-3-240-0.jpg",
		"url"         : "/doc-sach/sgv-hoat-dong-trai-nghiem-10/3/240/"
	},
	"/doc-sach/sgv-hoat-dong-trai-nghiem-2/3/64/"                            : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm lớp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Hoạt động trải nghiệm lớp 2 Sách Cánh Diều có vai trò giúp giáo viên thiết kế và tổ chức hoạt động giáo dục theo hướng phát triển năng lực cho học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18384-SGV-Hoat-dong-trai-nghiem-2-3-64-0.jpg",
		"url"         : "/doc-sach/sgv-hoat-dong-trai-nghiem-2/3/64/"
	},
	"/doc-sach/sgv-hoat-dong-trai-nghiem-3/3/255/"                           : {
		"title"       : "Sách Giáo viên - Hoạt động trải nghiệm lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Hoạt động trải nghiệm lớp 2 Sách Cánh Diều được thiết kế nhằm hỗ trợ thầy cô thiết kế bài giảng và dạy học hiệu quả hơn.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18498-SGV-Hoat-dong-trai-nghiem-3-3-255-0.jpg",
		"url"         : "/doc-sach/sgv-hoat-dong-trai-nghiem-3/3/255/"
	},
	"/doc-sach/sgv-khoa-hoc-tu-nhien-7/3/268/"                               : {
		"title"       : "Sách Giáo viên - Khoa học tự nhiên lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Khoa học tự nhiên lớp 7 Sách Cánh Diều được biên soạn nhằm hỗ trợ giáo viên dạy học đáp ứng yêu cầu phát triển phẩm chất, năng lực học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18527-SGV-Khoa-hoc-tu-nhien-7-3-268-0.jpg",
		"url"         : "/doc-sach/sgv-khoa-hoc-tu-nhien-7/3/268/"
	},
	"/doc-sach/sgv-khtn-6/3/66/"                                             : {
		"title"       : "Sách Giáo viên - Khoa học tự nhiên lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên - Khoa học tự nhiên lớp 6 thuộc bộ sách Cánh Diều được biên soạn nhằm hỗ trợ thầy cô có thêm tư liệu để dạy tốt môn học này cho học sinh.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18605-SGV-KHTN-6-3-66-0.jpg",
		"url"         : "/doc-sach/sgv-khtn-6/3/66/"
	},
	"/doc-sach/sgv-lich-su-dia-li-6/3/67/"                                   : {
		"title"       : "Sách Giáo viên - Lịch sử và địa lý lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Lịch sử và địa lý lớp 6 của bộ sách Cánh Diều là tài liệu hướng dẫn thầy cô tổ chức dạy và kiểm tra chất lượng học tập của học sinh theo SGK Lịch sử và địa lý 6.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/18847-SGV-Lich-su-Dia-li-6-3-67-0.jpg",
		"url"         : "/doc-sach/sgv-lich-su-dia-li-6/3/67/"
	},
	"/doc-sach/sgv-lich-su-10/3/238/"                                        : {
		"title"       : "Sách Giáo viên - Lịch sử lớp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Lịch sử lớp 10 Cánh Diều là tài liệu giúp các thầy cô tổ chức các hoạt động dạy học bám sát nội dung SGK Lịch sử 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19061-SGV-Lich-su-10-3-238-0.jpg",
		"url"         : "/doc-sach/sgv-lich-su-10/3/238/"
	},
	"/doc-sach/sgv-lich-su-va-dia-li-7/3/267/"                               : {
		"title"       : "Sách Giáo viên - Lịch sử và địa lí lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Lịch sử và địa lí lớp 7 của Sách Cánh Diều là tài liệu giúp gợi mở, tư vấn thiết kế các hoạt động trong giảng dạy SGK Lịch sử và địa lí 7.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19095-SGV-Lich-su-va-Dia-li-7-3-267-0.jpg",
		"url"         : "/doc-sach/sgv-lich-su-va-dia-li-7/3/267/"
	},
	"/doc-sach/sgv-mi-thuat-1/3/116/"                                        : {
		"title"       : "Sách Giáo viên - Mĩ thuật lớp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Mĩ thuật lớp 1 thuộc bộ Sách Cánh Diều được biên soạn nhằm tạo thuận lợi cho giáo viên trong tổ chức dạy học SGK Mĩ thuật lớp 1.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19145-SGV-Mi-thuat-1-3-116-0.jpg",
		"url"         : "/doc-sach/sgv-mi-thuat-1/3/116/"
	},
	"/doc-sach/sgv-mi-thuat-2/3/68/"                                         : {
		"title"       : "Sách Giáo viên - Mĩ thuật lớp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Mĩ thuật lớp 2 Cánh Diều là tài liệu hỗ trợ cho giáo viên trong tổ chức dạy học SGK Mĩ thuật lớp 1.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19275-SGV-Mi-thuat-2-3-68-0.jpg",
		"url"         : "/doc-sach/sgv-mi-thuat-2/3/68/"
	},
	"/doc-sach/sgv-mi-thuat-3/3/252/"                                        : {
		"title"       : "Sách Giáo viên - Mĩ thuật lớp 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Mĩ thuật lớp 3 thuộc bộ Sách Cánh Diều được biên soạn nhằm giúp giáo viên xây dựng, thực hiện bài giảng môn Mĩ thuật 3 tốt nhất.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19437-SGV-Mi-thuat-3-3-252-0.jpg",
		"url"         : "/doc-sach/sgv-mi-thuat-3/3/252/"
	},
	"/doc-sach/sgv-mi-thuat-6/3/69/"                                         : {
		"title"       : "Sách Giáo viên - Mĩ thuật lớp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Mĩ thuật lớp 6 thuộc bộ Sách Cánh Diều là tài liệu dành cho giáo viên và quản lý giáo dục trong việc thực hiện dạy học môn Mĩ thuật 6.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19478-SGV-Mi-thuat-6-3-69-0.jpg",
		"url"         : "/doc-sach/sgv-mi-thuat-6/3/69/"
	},
	"/doc-sach/sgv-mi-thuat-7/3/266/"                                        : {
		"title"       : "Sách Giáo viên - Mĩ thuật lớp 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Mĩ thuật lớp 7 thuộc bộ Sách Cánh Diều là tài liệu hỗ trợ cho giáo viên trong tổ chức dạy học SGK Mĩ thuật lớp 7.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19624-SGV-Mi-thuat-7-3-266-0.jpg",
		"url"         : "/doc-sach/sgv-mi-thuat-7/3/266/"
	},
	"/doc-sach/sgv-ngu-van-10-tap-1/3/237/"                                  : {
		"title"       : "Sách Giáo viên - Ngữ văn lớp 10 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Ngữ văn lớp 10 tập 1 của bộ Sách Cánh Diều được biên soạn nhằm giúp các thầy giáo, cô giáo có thêm tư liệu để dạy tốt môn Ngữ Văn 10.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19664-SGV-Ngu-van-10-tap-1-3-237-0.jpg",
		"url"         : "/doc-sach/sgv-ngu-van-10-tap-1/3/237/"
	},
	"/doc-sach/sgv-ngu-van-6-tap-1/3/70/"                                    : {
		"title"       : "Sách Giáo viên - Ngữ văn lớp 6 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Ngữ văn lớp 6 tập 1 của bộ Sách Cánh Diều được biên soạn nhằm giúp các thầy giáo, cô giáo có thêm tư liệu để dạy tốt SGK Ngữ Văn 6.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19708-SGV-Ngu-van-6-tap-1-3-70-0.jpg",
		"url"         : "/doc-sach/sgv-ngu-van-6-tap-1/3/70/"
	},
	"/doc-sach/sgv-ngu-van-6-tap-2/3/71/"                                    : {
		"title"       : "Sách Giáo viên - Ngữ văn lớp 6 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Ngữ văn lớp 6 tập 2 của bộ Sách Cánh Diều là tài liệu hướng dẫn, gợi ý cho giáo viên triển khai kế hoạch dạy học SGK Ngữ văn 6.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19834-SGV-Ngu-van-6-tap-2-3-71-0.jpg",
		"url"         : "/doc-sach/sgv-ngu-van-6-tap-2/3/71/"
	},
	"/doc-sach/sgv-ngu-van-7-tap-1/3/264/"                                   : {
		"title"       : "Sách Giáo viên - Ngữ văn lớp 7 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Ngữ văn lớp 7 tập 1 của bộ Sách Cánh Diều là nguồn tư liệu giúp các thầy cô dạy tốt SGK Ngữ Văn 7 tập 1.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19867-SGV-Ngu-van-7-Tap-1-3-264-0.jpg",
		"url"         : "/doc-sach/sgv-ngu-van-7-tap-1/3/264/"
	},
	"/doc-sach/sgv-ngu-van-7-tap-2/3/265/"                                   : {
		"title"       : "Sách Giáo viên - Ngữ văn lớp 7 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Sách Giáo viên Ngữ văn lớp 7 tập 2 - Cánh Diều được biên soạn nhằm giúp giáo viên xây dựng, thực hiện bài giảng môn Ngữ văn tốt nhất.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/19912-SGV-Ngu-van-7-Tap-2-3-265-0.jpg",
		"url"         : "/doc-sach/sgv-ngu-van-7-tap-2/3/265/"
	},
	"/doc-sach/sgv-sinh-hoc-10/3/236/"                                       : {
		"title"       : "Sách giáo viên - Sinh học lớp 10 - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Sinh Học lớp 10 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-sinh-hoc-10-3-236-0.jpg",
		"url"         : "/doc-sach/sgv-sinh-hoc-10/3/236/"
	},
	"/doc-sach/sgv-tieng-anh-1/3/110/"                                       : {
		"title"       : "Sách giáo viên tiếng Anh lớp 1 Explore Our World - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 1 Explore Our World thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-1-3-110.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-1/3/110/"
	},
	"/doc-sach/sgv-tieng-anh-10/3/215/"                                      : {
		"title"       : "Sách giáo viên tiếng Anh lớp 10 Explore Our World - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 10 Explore Our World thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-10-3-215.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-10/3/215/"
	},
	"/doc-sach/sgv-tieng-anh-2/3/78/"                                        : {
		"title"       : "Sách giáo viên tiếng Anh lớp 2 Explore Our World - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 2 Explore Our World thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-2-3-78.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-2/3/78/"
	},
	"/doc-sach/sgv-tieng-anh-3/3/274/"                                       : {
		"title"       : "Sách giáo viên tiếng Anh lớp 3 Explore Our World - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 3 Explore Our World thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-3-3-274.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-3/3/274/"
	},
	"/doc-sach/sgv-tieng-anh-6/3/79/"                                        : {
		"title"       : "Sách giáo viên tiếng Anh lớp 6 Explore English - Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 6 Explore English thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-6-3-79.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-6/3/79/"
	},
	"/doc-sach/sgv-tieng-anh-7/3/276/"                                       : {
		"title"       : "Sách giáo viên tiếng Anh lớp 7 Explore English - Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Anh lớp 7 Explore English thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-anh-lop-7-3-276.jpg",
		"url"         : "/doc-sach/sgv-tieng-anh-7/3/276/"
	},
	"/doc-sach/sgv-tieng-viet-1-tap-1/3/115/"                                : {
		"title"       : "Sách giáo viên tiếng Việt lớp 1 - Tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Việt lớp 1 Tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-viet-lop-1-tap-1-3-115.jpg",
		"url"         : "/doc-sach/sgv-tieng-viet-1-tap-1/3/115/"
	},
	"/doc-sach/sgv-tieng-viet-2-tap-1/3/72/"                                 : {
		"title"       : "Sách giáo viên tiếng Việt lớp 2 - Tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Việt lớp 2 Tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-viet-lop-2-tap-1-3-72.jpg",
		"url"         : "/doc-sach/sgv-tieng-viet-2-tap-1/3/72/"
	},
	"/doc-sach/sgv-tieng-viet-2-tap-2/3/73/"                                 : {
		"title"       : "Sách giáo viên tiếng Việt lớp 2 - Tập 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Việt lớp 2 Tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-viet-lop-2-tap-2-3-73.jpg",
		"url"         : "/doc-sach/sgv-tieng-viet-2-tap-2/3/73/"
	},
	"/doc-sach/sgv-tieng-viet-3-tap-1/3/249/"                                : {
		"title"       : "Sách giáo viên tiếng Việt lớp 3 - Tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Việt lớp 3 Tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-viet-lop-3-tap-1-3-249.jpg",
		"url"         : "/doc-sach/sgv-tieng-viet-3-tap-1/3/249/"
	},
	"/doc-sach/sgv-tieng-viet-3-tap-2/3/251/"                                : {
		"title"       : "Sách giáo viên tiếng Việt lớp 3 - Tập 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tiếng Việt lớp 3 Tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tieng-viet-lop-3-tap-2-3-251.jpg",
		"url"         : "/doc-sach/sgv-tieng-viet-3-tap-2/3/251/"
	},
	"/doc-sach/sgv-tin-hoc-10/3/235/"                                        : {
		"title"       : "Sách giáo viên Tin Học lớp 10 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Tin Học lớp 10 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tin-hoc-lop-10-3-235.jpg",
		"url"         : "/doc-sach/sgv-tin-hoc-10/3/235/"
	},
	"/doc-sach/sgv-tin-hoc-3/3/247/"                                         : {
		"title"       : "Sách giáo viên Tin Học lớp 3 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Tin Học lớp 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tin-hoc-lop-3-3-247.jpg",
		"url"         : "/doc-sach/sgv-tin-hoc-3/3/247/"
	},
	"/doc-sach/sgv-tin-hoc-6/3/74/"                                          : {
		"title"       : "Sách giáo viên Tin Học lớp 6 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Tin Học lớp 6 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tin-hoc-lop-6-3-74.jpg",
		"url"         : "/doc-sach/sgv-tin-hoc-6/3/74/"
	},
	"/doc-sach/sgv-tin-hoc-7/3/262/"                                         : {
		"title"       : "Sách giáo viên Tin Học lớp 7 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Tin Học lớp 7 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tin-hoc-lop-7-3-262.jpg",
		"url"         : "/doc-sach/sgv-tin-hoc-7/3/262/"
	},
	"/doc-sach/sgv-tnxh-1/3/114/"                                            : {
		"title"       : "Sách giáo viên Tự nhiên và Xã hội lớp 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Tự nhiên và Xã hội lớp 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tu-nhien-va-xa-hoi-lop-1-3-114.jpg",
		"url"         : "/doc-sach/sgv-tnxh-1/3/114/"
	},
	"/doc-sach/sgv-toan-1/3/121/"                                            : {
		"title"       : "Sách giáo viên toán lớp 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-1-3-121.jpg",
		"url"         : "/doc-sach/sgv-toan-1/3/121/"
	},
	"/doc-sach/sgv-toan-10/3/234/"                                           : {
		"title"       : "Sách giáo viên toán lớp 10 - Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 10 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-10-3-234.jpg",
		"url"         : "/doc-sach/sgv-toan-10/3/234/"
	},
	"/doc-sach/sgv-toan-2/3/76/"                                             : {
		"title"       : "Sách giáo viên Toán lớp 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-2-3-76.jpg",
		"url"         : "/doc-sach/sgv-toan-2/3/76/"
	},
	"/doc-sach/sgv-toan-3/3/232/"                                            : {
		"title"       : "Sách giáo viên Toán lớp 3 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-3-2-232.jpg",
		"url"         : "/doc-sach/sgv-toan-3/3/232/"
	},
	"/doc-sach/sgv-toan-6/3/77/"                                             : {
		"title"       : "Sách giáo viên Toán lớp 6 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 6 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-6-3-77.jpg",
		"url"         : "/doc-sach/sgv-toan-6/3/77/"
	},
	"/doc-sach/sgv-toan-7/3/263/"                                            : {
		"title"       : "Sách giáo viên Toán lớp 7 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Toán lớp 7 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-toan-lop-7-3-263.jpg",
		"url"         : "/doc-sach/sgv-toan-7/3/263/"
	},
	"/doc-sach/sgv-tu-nhien-va-xa-hoi-3/3/245/"                              : {
		"title"       : "Sách giáo viên tự nhiên và xã hội lớp 3 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tự nhiên và xã hội lớp 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tu-nhien-va-xa-hoi-lop-33-3-245.jpg",
		"url"         : "/doc-sach/sgv-tu-nhien-va-xa-hoi-3/3/245/"
	},
	"/doc-sach/sgv-tu-nhien-xa-hoi-2/3/75/"                                  : {
		"title"       : "Sách giáo viên tự nhiên và xã hội lớp 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV tự nhiên và xã hội lớp 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-tu-nhien-va-xa-hoi-lop-2-3-75.jpg",
		"url"         : "/doc-sach/sgv-tu-nhien-xa-hoi-2/3/75/"
	},
	"/doc-sach/sgv-vat-li-10/3/233/"                                         : {
		"title"       : "Sách giáo viên Vật lý lớp 10 - Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại SGV Vật lý lớp 10 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-vien-vat-ly-lop-10-3-233.jpg",
		"url"         : "/doc-sach/sgv-vat-li-10/3/233/"
	},
	"/doc-sach/sinh-hoc-10/1/165/"                                           : {
		"title"       : "Sách giáo khoa Sinh học lớp 10 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Sinh học lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-giao-khoa-sinh-hoc-10-1-165.jpg",
		"url"         : "/doc-sach/sinh-hoc-10/1/165/"
	},
	"/doc-sach/thuc-hanh-hoat-dong-trai-nghiem-huong-nghiep-7/5/301/"        : {
		"title"       : "Sách Thực hành hoạt động trải nghiệm, hướng nghiệp lớp 7 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Thực hành hoạt động trải nghiệm, hướng nghiệp lớp 7 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-thuc-hanh-hoat-dong-trai-nghiem-huong-nghiep-lop-7-5-301.jpg",
		"url"         : "/doc-sach/thuc-hanh-hoat-dong-trai-nghiem-huong-nghiep-7/5/301/"
	},
	"/doc-sach/tieng-anh-1/1/9/"                                             : {
		"title"       : "SGK tiếng Anh lớp 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-1-1-9.jpg",
		"url"         : "/doc-sach/tieng-anh-1/1/9/"
	},
	"/doc-sach/tieng-anh-10/1/173/"                                          : {
		"title"       : "SGK tiếng Anh lớp 10 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-10-1-173.jpg",
		"url"         : "/doc-sach/tieng-anh-10/1/173/"
	},
	"/doc-sach/tieng-anh-2/1/20/"                                            : {
		"title"       : "SGK tiếng Anh lớp 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-2-1-20.jpg",
		"url"         : "/doc-sach/tieng-anh-2/1/20/"
	},
	"/doc-sach/tieng-anh-3/1/138/"                                           : {
		"title"       : "SGK tiếng Anh lớp 3 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 3 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-3-1-138.jpg",
		"url"         : "/doc-sach/tieng-anh-3/1/138/"
	},
	"/doc-sach/tieng-anh-6/1/30/"                                            : {
		"title"       : "Sách giáo khoa tiếng Anh lớp 6 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 6 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-6-1-30.jpg",
		"url"         : "/doc-sach/tieng-anh-6/1/30/"
	},
	"/doc-sach/tieng-anh-7/1/152/"                                           : {
		"title"       : "Sách giáo khoa tiếng Anh lớp 7 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Anh lớp 7 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-anh-lop-7-1-152.jpg",
		"url"         : "/doc-sach/tieng-anh-7/1/152/"
	},
	"/doc-sach/tieng-viet-1-1/1/1/"                                          : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 1 tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Thực hành hoạt động trải nghiệm, hướng nghiệp lớp 7 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-1-tap-1-1-1.jpg",
		"url"         : "/doc-sach/tieng-viet-1-1/1/1/"
	},
	"/doc-sach/tieng-viet-1-2/1/2/"                                          : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 1 tập 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Việt lớp 1 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-1-tap-2-1-2.jpg",
		"url"         : "/doc-sach/tieng-viet-1-2/1/2/"
	},
	"/doc-sach/tieng-viet-2-1/1/11/"                                         : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 2 tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Việt lớp 2 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-2-tap-1-1-11.jpg",
		"url"         : "/doc-sach/tieng-viet-2-1/1/11/"
	},
	"/doc-sach/tieng-viet-2-2/1/12/"                                         : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 2 tập 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Việt lớp 2 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-2-tap-2-1-12.jpg",
		"url"         : "/doc-sach/tieng-viet-2-2/1/12/"
	},
	"/doc-sach/tieng-viet-3-tap-1/1/134/"                                    : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 3 tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Việt lớp 3 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-3-tap-1-1-134.jpg",
		"url"         : "/doc-sach/tieng-viet-3-tap-1/1/134/"
	},
	"/doc-sach/tieng-viet-3-tap-2/1/135/"                                    : {
		"title"       : "Sách giáo khoa tiếng Việt lớp 3 tập 2 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa tiếng Việt lớp 3 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tieng-viet-lop-3-tap-2-1-135.jpg",
		"url"         : "/doc-sach/tieng-viet-3-tap-2/1/135/"
	},
	"/doc-sach/tin-hoc-10/1/164/"                                            : {
		"title"       : "Sách giáo khoa Tin học lớp 10 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tin học lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tin-hoc-lop-10-1-164.jpg",
		"url"         : "/doc-sach/tin-hoc-10/1/164/"
	},
	"/doc-sach/tin-hoc-3/1/129/"                                             : {
		"title"       : "Sách giáo khoa Tin học lớp 3 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tin học lớp 3 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tin-hoc-lop-3-1-129.jpg",
		"url"         : "/doc-sach/tin-hoc-3/1/129/"
	},
	"/doc-sach/tin-hoc-6/1/28/"                                              : {
		"title"       : "Sách giáo khoa Tin học lớp 6 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tin học lớp 6 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tin-hoc-lop-6-1-28.jpg",
		"url"         : "/doc-sach/tin-hoc-6/1/28/"
	},
	"/doc-sach/tin-hoc-7/1/147/"                                             : {
		"title"       : "Sách giáo khoa Tin học lớp 7 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tin học lớp 7 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-tin-hoc-lop-7-1-147.jpg",
		"url"         : "/doc-sach/tin-hoc-7/1/147/"
	},
	"/doc-sach/toan-1/1/3/"                                                  : {
		"title"       : "Sách giáo khoa Toán lớp 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-toan-lop-1-1-3.jpg",
		"url"         : "/doc-sach/toan-1/1/3/"
	},
	"/doc-sach/toan-10-tap-1/1/155/"                                         : {
		"title"       : "Sách giáo khoa Toán lớp 10 tập 1 - Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 10 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sach-toan-lop-10-tap-1-1-155.jpg",
		"url"         : "/doc-sach/toan-10-tap-1/1/155/"
	},
	"/doc-sach/toan-10-tap-2/1/156/"                                         : {
		"title"       : "SGK Toán 10 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán 10 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-10-tap-2.jpg",
		"url"         : "/doc-sach/toan-10-tap-2/1/156/"
	},
	"/doc-sach/toan-2-1/1/13/"                                               : {
		"title"       : "SGK Toán 2 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 2 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-2-tap-1.jpg",
		"url"         : "/doc-sach/toan-2-1/1/13/"
	},
	"/doc-sach/toan-2-2/1/14/"                                               : {
		"title"       : "SGK Toán 2 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 2 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-2-tap-2.jpg",
		"url"         : "/doc-sach/toan-2-2/1/14/"
	},
	"/doc-sach/toan-3-tap-1/1/132/"                                          : {
		"title"       : "SGK Toán 3 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 3 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-3-tap-1.jpg",
		"url"         : "/doc-sach/toan-3-tap-1/1/132/"
	},
	"/doc-sach/toan-3-tap-2/1/133/"                                          : {
		"title"       : "SGK Toán 3 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 3 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-3-tap-2.jpg",
		"url"         : "/doc-sach/toan-3-tap-2/1/133/"
	},
	"/doc-sach/toan-6-1/1/24/"                                               : {
		"title"       : "SGK Toán 6 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 6 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-6-tap-1.jpg",
		"url"         : "/doc-sach/toan-6-1/1/24/"
	},
	"/doc-sach/toan-6-2/1/35/"                                               : {
		"title"       : "SGK Toán 6 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 6 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-6-tap-2.jpg",
		"url"         : "/doc-sach/toan-6-2/1/35/"
	},
	"/doc-sach/toan-7-tap-1/1/141/"                                          : {
		"title"       : "SGK Toán 7 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 7 tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-7-tap-1.jpg",
		"url"         : "/doc-sach/toan-7-tap-1/1/141/"
	},
	"/doc-sach/toan-7-tap-2/1/142/"                                          : {
		"title"       : "SGK Toán 7 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Toán lớp 7 tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-toan-7-tap-2.jpg",
		"url"         : "/doc-sach/toan-7-tap-2/1/142/"
	},
	"/doc-sach/tu-nhien-va-xa-hoi-1/1/5/"                                    : {
		"title"       : "SGK Tự nhiên và Xã hội tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tự nhiên và Xã hội tập 1 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tu-nhien-va-xa-hoi-1.jpg",
		"url"         : "/doc-sach/tu-nhien-va-xa-hoi-1/1/5/"
	},
	"/doc-sach/tu-nhien-va-xa-hoi-2/1/16/"                                   : {
		"title"       : "SGK Tự nhiên và Xã hội tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tự nhiên và Xã hội tập 2 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-tu-nhien-va-xa-hoi-2.jpg",
		"url"         : "/doc-sach/tu-nhien-va-xa-hoi-2/1/16/"
	},
	"/doc-sach/tu-nhien-va-xa-hoi-3/1/137/"                                  : {
		"title"       : "SGK Tự nhiên và Xã hội tập 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Tự nhiên và Xã hội tập 3 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tu-nhien-va-xa-hoi-3.jpg",
		"url"         : "/doc-sach/tu-nhien-va-xa-hoi-3/1/137/"
	},
	"/doc-sach/vat-li-10/1/157/"                                             : {
		"title"       : "SGK Vật lí 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là bản sách điện tử - có các bài tập tương tác, file nghe, video minh họa nội dung của cuốn sách giáo khoa Vật lí lớp 10 thuộc bộ sách Cánh Diều thể hiện đầy đủ các nội dung có trong chương trình học.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-vat-li-10.jpg",
		"url"         : "/doc-sach/vat-li-10/1/157/"
	},
	"/doc-sach/vbt-cong-nghe-3/5/312/"                                       : {
		"title"       : "VBT Công nghệ 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Công nghệ 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-cong-nghe-3.jpg",
		"url"         : "/doc-sach/vbt-cong-nghe-3/5/312/"
	},
	"/doc-sach/vbt-dao-duc-1/5/88/"                                          : {
		"title"       : "VBT Đạo Đức 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Đạo Đức 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-dao-duc-1.jpg",
		"url"         : "/doc-sach/vbt-dao-duc-1/5/88/"
	},
	"/doc-sach/vbt-dao-duc-2/5/48/"                                          : {
		"title"       : "VBT Đạo Đức 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Đạo Đức 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-dao-duc-2.jpg",
		"url"         : "/doc-sach/vbt-dao-duc-2/5/48/"
	},
	"/doc-sach/vbt-dao-duc-3/5/313/"                                         : {
		"title"       : "VBT Đạo Đức 3 | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Đạo Đức 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-dao-duc-3.jpg",
		"url"         : "/doc-sach/vbt-dao-duc-3/5/313/"
	},
	"/doc-sach/vbt-mi-thuat-1/5/93/"                                         : {
		"title"       : "VTH Mĩ thuật 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Mĩ thuật 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-mi-thuat-1.jpg",
		"url"         : "/doc-sach/vbt-mi-thuat-1/5/93/"
	},
	"/doc-sach/vbt-mi-thuat-3/5/315/"                                        : {
		"title"       : "VTH Mĩ thuật 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Mĩ thuật 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-mi-thuat-3.jpg",
		"url"         : "/doc-sach/vbt-mi-thuat-3/5/315/"
	},
	"/doc-sach/vbt-tieng-viet-3-tap-1/5/280/"                                : {
		"title"       : "VBT Tiếng Việt 3 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 3 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-3-tap-1.jpg",
		"url"         : "/doc-sach/vbt-tieng-viet-3-tap-1/5/280/"
	},
	"/doc-sach/vbt-tieng-viet-3-tap-2/5/281/"                                : {
		"title"       : "VBT Tiếng Việt 3 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 3 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-3-tap-2.jpg",
		"url"         : "/doc-sach/vbt-tieng-viet-3-tap-2/5/281/"
	},
	"/doc-sach/vbt-tnxh-2/5/47/"                                             : {
		"title"       : "VBT Tự nhiên và Xã hội 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tự nhiên và Xã hội 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tu-nhien-va-xa-hoi-2.jpg",
		"url"         : "/doc-sach/vbt-tnxh-2/5/47/"
	},
	"/doc-sach/vbt-toan-3-tap-1/5/278/"                                      : {
		"title"       : "VBT Toán 3 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 3 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-3-tap-1.jpg",
		"url"         : "/doc-sach/vbt-toan-3-tap-1/5/278/"
	},
	"/doc-sach/vbt-toan-3-tap-2/5/279/"                                      : {
		"title"       : "VBT Toán 3 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 3 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-3-tap-2.jpg",
		"url"         : "/doc-sach/vbt-toan-3-tap-2/5/279/"
	},
	"/doc-sach/vbt-tu-nhien-va-xa-hoi-3/5/294/"                              : {
		"title"       : "VBT Tự nhiên và Xã hội 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tự nhiên và Xã hội 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tu-nhien-va-xa-hoi-3.jpg",
		"url"         : "/doc-sach/vbt-tu-nhien-va-xa-hoi-3/5/294/"
	},
	"/doc-sach/vo-bai-tap-tieng-viet-1-tap-1/5/36/"                          : {
		"title"       : "VBT Tiếng Việt 1 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 1 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-1-tap-1.jpg",
		"url"         : "/doc-sach/vo-bai-tap-tieng-viet-1-tap-1/5/36/"
	},
	"/doc-sach/vo-bai-tap-tieng-viet-1-tap-2/5/175/"                         : {
		"title"       : "VBT Tiếng Việt 1 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 1 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-1-tap-2.jpg",
		"url"         : "/doc-sach/vo-bai-tap-tieng-viet-1-tap-2/5/175/"
	},
	"/doc-sach/vo-bai-tap-tieng-viet-2-tap-1/5/43/"                          : {
		"title"       : "VBT Tiếng Việt 2 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 2 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-2-tap-1.jpg",
		"url"         : "/doc-sach/vo-bai-tap-tieng-viet-2-tap-1/5/43/"
	},
	"/doc-sach/vo-bai-tap-tieng-viet-2-tap-2/5/176/"                         : {
		"title"       : "VBT Tiếng Việt 2 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tiếng Việt 2 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tieng-viet-2-tap-2.jpg",
		"url"         : "/doc-sach/vo-bai-tap-tieng-viet-2-tap-2/5/176/"
	},
	"/doc-sach/vo-bai-tap-tnxh-1/5/40/"                                      : {
		"title"       : "VBT Tự nhiên và Xã hội 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Tự nhiên và Xã hội 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-tu-nhien-va-xa-hoi-1.jpg",
		"url"         : "/doc-sach/vo-bai-tap-tnxh-1/5/40/"
	},
	"/doc-sach/vo-bai-tap-toan-1-tap-1/5/38/"                                : {
		"title"       : "VBT Toán 1 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 1 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-1.jpg",
		"url"         : "/doc-sach/vo-bai-tap-toan-1-tap-1/5/38/"
	},
	"/doc-sach/vo-bai-tap-toan-1-tap-2/5/39/"                                : {
		"title"       : "VBT Toán 1 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 1 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-1-tap-2.jpg",
		"url"         : "/doc-sach/vo-bai-tap-toan-1-tap-2/5/39/"
	},
	"/doc-sach/vo-bai-tap-toan-2-tap-1/5/45/"                                : {
		"title"       : "VBT Toán 2 tập 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 2 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-2-tap-1.jpg",
		"url"         : "/doc-sach/vo-bai-tap-toan-2-tap-1/5/45/"
	},
	"/doc-sach/vo-bai-tap-toan-2-tap-2/5/177/"                               : {
		"title"       : "VBT Toán 2 tập 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VBT Toán 2 tập 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vbt-toan-2-tap-2.jpg",
		"url"         : "/doc-sach/vo-bai-tap-toan-2-tap-2/5/177/"
	},
	"/doc-sach/vo-thuc-hanh-am-nhac-1/5/42/"                                 : {
		"title"       : "VTH Âm nhạc 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Âm nhạc 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-am-nhac-1.jpg",
		"url"         : "/doc-sach/vo-thuc-hanh-am-nhac-1/5/42/"
	},
	"/doc-sach/vo-thuc-hanh-mi-thuat-7/5/293/"                               : {
		"title"       : "VTH Mĩ thuật 7 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Mĩ thuật 7 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-mi-thuat-7.jpg",
		"url"         : "/doc-sach/vo-thuc-hanh-mi-thuat-7/5/293/"
	},
	"/doc-sach/vth-am-nhac-2/5/49/"                                          : {
		"title"       : "VTH Âm nhạc 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Âm nhạc 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-am-nhac-2.jpg",
		"url"         : "/doc-sach/vth-am-nhac-2/5/49/"
	},
	"/doc-sach/vth-am-nhac-3/5/314/"                                         : {
		"title"       : "VTH Âm nhạc 3 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Âm nhạc 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-am-nhac-3.jpg",
		"url"         : "/doc-sach/vth-am-nhac-3/5/314/"
	},
	"/doc-sach/vth-hoat-dong-trai-nghiem-huong-nghiep-10/5/309/"             : {
		"title"       : "VTH Hoạt động trải nghiệm hướng nghiệp 10 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Hoạt động trải nghiệm hướng nghiệp 10 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/thuc-hanh-hoat-dong-trai-nghiem-huong-nghiep-10.jpg",
		"url"         : "/doc-sach/vth-hoat-dong-trai-nghiem-huong-nghiep-10/5/309/"
	},
	"/doc-sach/vth-hoat-dong-trai-nghiem-1/5/106/"                           : {
		"title"       : "VTH Hoạt động trải nghiệm hướng nghiệp 1 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Hoạt động trải nghiệm hướng nghiệp 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-hoat-dong-trai-nghiem-1.jpg",
		"url"         : "/doc-sach/vth-hoat-dong-trai-nghiem-1/5/106/"
	},
	"/doc-sach/vth-hoat-dong-trai-nghiem-2/5/105/"                           : {
		"title"       : "VTH Hoạt động trải nghiệm hướng nghiệp 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Hoạt động trải nghiệm hướng nghiệp 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-hoat-dong-trai-nghiem-2.jpg",
		"url"         : "/doc-sach/vth-hoat-dong-trai-nghiem-2/5/105/"
	},
	"/doc-sach/vth-hoat-dong-trai-nghiem-3/5/295/"                           : {
		"title"       : "VTH Hoạt động trải nghiệm 3 | Sách Cánh Diều",
"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Hoạt động trải nghiệm 3 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://vnmedia.monkeyuni.net/upload/hoc10/thumb/vth-hoat-dong-trai-nghiem-3.jpg",
		"url"         : "/doc-sach/vth-hoat-dong-trai-nghiem-3/5/295/"
	},
	"/doc-sach/vth-hoat-dong-trai-nghiem-6/5/101/"                           : {
		"title"       : "VTH Hoạt động trải nghiệm hướng nghiệp 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Hoạt động trải nghiệm hướng nghiệp 6 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/sgk-hoat-dong-trai-nghiem-huong-nghiep-6.jpg",
		"url"         : "/doc-sach/vth-hoat-dong-trai-nghiem-6/5/101/"
	},
	"/doc-sach/vth-mi-thuat-2/5/104/"                                        : {
		"title"       : "VTH Mĩ thuật 2 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Mĩ thuật 2 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-mi-thuat-2.jpg",
		"url"         : "/doc-sach/vth-mi-thuat-2/5/104/"
	},
	"/doc-sach/vth-mi-thuat-6/5/102/"                                        : {
		"title"       : "VTH Mĩ thuật 6 | Sách Cánh Diều",
		"robots"	  : ACTIVE_META_ROBOTS,
		"description" : "Đây là sách bản sách mềm của cuốn loại VTH Mĩ thuật 6 tập 1 thuộc bộ Sách Cánh Diều.",
		"img"         : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/vth-mi-thuat-6.jpg",
		"url"         : "/doc-sach/vth-mi-thuat-6/5/102/"
	}
	
};
