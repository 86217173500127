import { useState } from "react";
import styled from "styled-components";
import {
  DOCUMENT_TYPE_AUDIO,
  DOCUMENT_TYPE_PPT,
  DOCUMENT_TYPE_WORD,
  DOCUMENT_TYPE_ZIP,
  DOCUMENT_TYPE_PDF,
  DOCUMENT_TYPE_VIDEO,
} from "consts/book";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import Pdf from "./Document/View";
import Video from "./Document/Video";
import DownloadFile from "./Document/DownloadFile";

export default function Intro({ data, categoryBook, col, background }) {
  const [url, setUrl] = useState("");

  const renderDocumentByType = (type, item, index) => {
    switch (type) {
      case DOCUMENT_TYPE_PDF:
        return <Pdf item={item} col={col} index={index} />;
      case DOCUMENT_TYPE_VIDEO:
        return (
          <Video item={item} col={col} onSetUrl={onSetUrl} index={index} />
        );
      case DOCUMENT_TYPE_AUDIO:
      case DOCUMENT_TYPE_WORD:
      case DOCUMENT_TYPE_PPT:
      case DOCUMENT_TYPE_ZIP:
        return <DownloadFile item={item} col={col} index={index} />;
      default:
        return <Pdf item={item} col={col} index={index} />;
    }
  };

  const onSetUrl = (url) => {
    setUrl(url);
  };

  return (
    <div
      className="result__list"
      style={{
        backgroundColor: `${background}`,
        boxShadow: "5px 5px 10px 1px #ccc",
        padding: "10px 20px",
        marginBottom: "20px",
        borderRadius: "8px",
      }}
    >
      <div className="row">
        <div className="col-md-6 d-flex">
          <BookMark className="fa fa-bookmark" aria-hidden="true"></BookMark>
          <h4>{categoryBook}</h4>
        </div>
      </div>

      <Hr />
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />
      <div className="row">
        {data.length > 0 ? (
          data.map((_item, _index) => {
            return renderDocumentByType(_item.type_document, _item, _index);
          })
        ) : (
          <div className="text-center justify-content-center">
            <h1>Không tìm thấy tài liệu</h1>
          </div>
        )}
      </div>
    </div>
  );
}

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
  margin-top: 4px;
`;
