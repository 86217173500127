import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import popupBuyBook from "./ContentPopupBuyBook.module.css";
import { ROUTE_PUBLISHER_LIST } from "consts/routePaths";
import { setEventGTM } from "../../constants/googleTagManager";
import { HOC10_CLICK_BTN_BUY_BOOK } from "../../constants/eventNameGTM";
import { Wrapper } from "../Game/Match/components/styles";

export const Div = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Close = styled.p`
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const ContentPopupBuyBook = (props) => {
  const history = useHistory();
  const onToggle = (url) => {
    props.onDispatchShowPopupActivateBook(false);
    if (url) {
      history.push(url);
    }
  };

  return (
    <div
      className={`content_popup_success monkey-pt-35 ${popupBuyBook.textLeftv1}`}
    >
      <div style={{ color: "#2A404F" }}>
        <div className="position-relative">
          <p className={`monkey-f-header ${popupBuyBook.titlePopup}`}>
            Thông báo
          </p>
          <Close
            className="position-absolute monkey-f-header monkey-fz-20 cursor"
            onClick={onToggle}
          >
            x
          </Close>
        </div>
        <hr />
        <p className="mt-3 mb-3 text-center">
          Truy cập shop online của Sách Cánh Diều
        </p>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Wrapper
            className="btn-pr px-3 py-2 cursor"
            onClick={() => {
              setEventGTM({
                event: HOC10_CLICK_BTN_BUY_BOOK,
              });
              window.open('https://sachcanhdieu.vn/', "_blank");
            }}
        >
          Mua sách online
        </Wrapper>
        
        {/*<a*/}
        {/*  href="https://sachcanhdieu.vn/'"*/}
        {/*  className="btn-pr px-3 py-2 cursor"*/}
        {/*  target="_blank"*/}
        {/*>*/}
        {/*  Mua sách online*/}
        {/*</a>*/}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ContentPopupBuyBook);
