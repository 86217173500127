import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";

export default function PopupMatchUserSuccess({
  show,
  setShow,
  redirectExercise,
}) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success"
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img
            src="/assets/img/img-success.svg"
            alt="Đồng bộ tài khoản thành công"
          />
        </div>
        <div className="text">Đồng bộ thành công</div>
        <p
          onClick={() => redirectExercise()}
          className="btn-pr flex-center btn-access"
        >
          Truy cập trang làm bài
        </p>
      </div>
    </Modal>
  );
}
