import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";
import { timeUpdate } from "../selection";

export default function PostDetail({ dataPost }) {
  const routerDetailItem = dataPost.grade.name
    ? `${PATH.ROUTE_BLOG_KNOWLEDGE}${dataPost?.level?.slug}/${dataPost?.grade?.slug}/${dataPost?.subject?.slug}/${dataPost?.alias}/`
    : `${PATH.ROUTE_BLOG_KNOWLEDGE_OTHER}${dataPost?.alias}/`;

  return (
    <div className={`${styles.newPostDetail} post-item`}>
      <Link to={routerDetailItem} className="post__thumb">
        <div className={styles.img}>
          <img src={dataPost?.images_cv} alt="images-new-post" />
        </div>
      </Link>
      <div className={styles.inforMation}>
        <div className={styles.inforGrade}>
          {dataPost.grade.name && (
            <Link
              to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${dataPost?.level?.slug}/${dataPost?.grade?.slug}/`}
              className={styles.class}
            >
              {dataPost.grade.name}
            </Link>
          )}
          {dataPost.subject.name && (
            <span className={styles.subject}>{dataPost.subject.name}</span>
          )}
        </div>
        <Link to={routerDetailItem} className="post__thumb">
          <h2 className={`${styles.titleContent} monkey-fz-18`}>
            {dataPost?.title || ""}
          </h2>
        </Link>
        <Link to={routerDetailItem} className="post__thumb">
          <p className={styles.description}>
            {renderHTML(dataPost?.description_cv || "")}
          </p>
        </Link>

        <div className={styles.timeCreate}>
          <span className="d-flex">
            <img
              className={styles.newPostIcon}
              src="/assets/img/icon-color.svg"
              alt="icon-color"
            />
            <span>{timeUpdate(dataPost?.publish_date)}</span>
            <span className="d-flex pl-5">
              <img
                className={styles.newPostIcon}
                src="/assets/img/icon-viewers.svg"
                alt="icon-viewers"
              />
              {dataPost?.viewcount || ""}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
