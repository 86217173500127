const THEMES = {
  MultipleChoice: {
    Default: "Default",
    Outline: "Outline",
  },
  FIB: {
    Default: "Default",
    Primary: "Primary",
  },
  Sequence: {
    Default: "Default",
    Primary: "Primary",
  },
};

export default THEMES;
