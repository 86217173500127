import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import _ from "lodash";
import * as TEXT from "../../constants/text";
import * as TYPE_JOB from "../../constants/type";
import {
  onDispatchListDistrict,
  onDispatchListWard,
  onDispatchListSchool,
} from "../../modules/UpdateInformation/actions";
import { onDispatchDataInfo } from "../../modules/SignIn/actions";
import {
  getDataUrlListProvince,
  getDataUrlListSchoolByProvince,
} from "../../services/info/info";
import { onDispatchShowLoading } from "../../modules/App/actions";
import {
  onValueOptionDefault,
  onLevelSchool,
} from "../../modules/UpdateInformation/config";
import LabelInput from "../LabelInput";
import { TYPE_LEVEL } from "../../constants/type";
import { TypeSchool } from "../../modules/UpdateInformation/config";
import * as PATH from "../../../consts/routePaths";

const FromSchoolInfo = (props) => {
  const { handleSubmit } = useForm();
  const [valueProvinceDefault, setStateProvince] = useState();
  const [valueDistrictDefault, setStateDistrict] = useState();
  const [valueWardDefault, setStateWard] = useState();
  const [valueClass, setStateClass] = useState();
  const [valueSchool, setStateSchool] = useState();
  const [valueTypeSchool, setStateValueTypeSchool] = useState("");
  const [newSchoolName, setStateNewSchoolName] = useState("");
  const [isNewSchool, setStateNewSchool] = useState(false);
  const [type, setType] = useState("");
  useEffect(() => {
    props.valueProvinceDefault ? setType("update") : setType("add");
    setStateProvince(props.valueProvinceDefault);
  }, [props.valueProvinceDefault]);

  useEffect(() => {
    const valueGrade = TYPE_LEVEL.filter(
      (item) => item.id === props.valueGradeDefault
    )[0];
    setStateClass(valueGrade);
  }, [props.valueGradeDefault]);

  useEffect(() => {
    setStateDistrict(props.valueDistrictDefault);
  }, [props.valueDistrictDefault]);

  useEffect(() => {
    setStateWard(props.valueWardDefault);
  }, [props.valueWardDefault]);

  useEffect(() => {
    setStateSchool(props.valueSchool);
  }, [props.valueSchool]);

  useEffect(() => {
    setStateValueTypeSchool(props.valueTypeSchool);
  }, [props.valueTypeSchool]);

  useEffect(() => {
    if (props.statusSchool === 0) {
      setStateNewSchool(true);
      const data = _.split(props.valueSchoolName, ",");
      setStateNewSchoolName(data[1]);
      const resultValue = TypeSchool.filter(
        (item) => item.label === data[0]
      )[0];
      setStateValueTypeSchool(resultValue);
      const resultDefault = {
        id: -1,
        name: "Khác",
        value: "school_3",
        label: "Khác",
      };
      setStateSchool(resultDefault);
    }
    if (props.statusSchool === 1) {
      setStateNewSchool(false);
    }
    if (props.statusSchool === null) {
      setStateNewSchoolName("");
    }
  }, [props.valueSchoolName, props.statusSchool]);

  const handleChangeProvince = (data) => {
    const valueOption = onValueOptionDefault(props.listProvince, data.id);
    setStateProvince(valueOption);
    if (valueOption) {
      props.onDispatchShowLoading(true);
      getDataUrlListProvince(valueOption.id)
        .then((data) => {
          if (data.data.status === "success") {
            props.onDispatchShowLoading(false);
            props.onDispatchListDistrict(data.data.data);
          }
        })
        .catch((errors) => {
          console.log(errors);
          props.onDispatchShowLoading(false);
        });
    }
  };

  const handleChangeDistrict = (data) => {
    const valueOption = onValueOptionDefault(props.listDistrict, data.id);
    setStateDistrict(valueOption);
    if (valueOption) {
      props.onDispatchShowLoading(true);
      getDataUrlListProvince(valueOption.id)
        .then((data) => {
          if (data.data.status === "success") {
            props.onDispatchShowLoading(false);
            props.onDispatchListWard(data.data.data);
          }
        })
        .catch((errors) => {
          console.log(errors);
          props.onDispatchShowLoading(false);
        });
    }
  };
  const handleChangeWard = (ward) => {
    props.onDispatchShowLoading(true);
    const valueOption = onValueOptionDefault(props.listWard, ward.id);
    setStateWard(valueOption);
    if (valueClass.id) {
      // const levelSchool = onLevelSchool(valueClass.id);
      getDataUrlListSchoolByProvince(ward.id, 0)
        .then((data) => {
          if (data.data.status === "success") {
            props.onDispatchShowLoading(false);
            props.onDispatchListSchool(data.data.data);
          }
        })
        .catch((errors) => {
          console.log(errors);
          props.onDispatchShowLoading(false);
        });
    }
  };
  const handleChangeSchool = (data) => {
    if (data.id === -1) {
      setStateNewSchool(true);
      setStateValueTypeSchool("");
    } else {
      setStateNewSchool(false);
      setStateNewSchoolName("");
      setStateValueTypeSchool("");
    }
    const valueOption = onValueOptionDefault(props.listSchool, data.id);
    setStateSchool(valueOption);
  };
  const handleChangeTypeSchool = (data) => {
    const valueOption = onValueOptionDefault(TypeSchool, data.id);
    setStateValueTypeSchool(valueOption);
  };

  const onConfirm = () => {
    let userInfo = {
      ...props.userInfo,
      province_id: valueProvinceDefault?.id,
      district_id: valueDistrictDefault?.id,
      ward_id: valueWardDefault?.id,
      school_id: valueSchool?.id,
      app_id: TYPE_JOB.APP_ID,
    };
    if (isNewSchool) {
      userInfo = {
        ...userInfo,
        school_id: "",
        type_school: 1,
        school_name: `${valueTypeSchool.label},${newSchoolName}`,
        type: type,
        updateDetail: "school_info",
      };
    }
    props.onDispatchDataInfo(userInfo);
    if (localStorage.getItem("pathQuiz") !== PATH.ROUTE_PATH_TRAINING_TEST_2) {
      props.onUpdateInfo(userInfo);
    }
    props.onSlickNext();
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 44,
      borderRadius: 30,
    }),
  };

  return (
    <form className="from-info p-3" onSubmit={handleSubmit(onConfirm)}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_PROVINCE}
              status={true}
            />
            <Select
              value={valueProvinceDefault}
              onChange={handleChangeProvince}
              options={props.listProvince}
              maxMenuHeight={180}
              styles={customStyles}
              isDisabled={onDisableProvince(props.userInfo.role_id)}
              placeholder="Tỉnh/Thành phố"
            />
          </div>
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_DISTRICT}
              status={true}
            />
            <Select
              value={valueDistrictDefault}
              onChange={handleChangeDistrict}
              options={props.listDistrict}
              maxMenuHeight={180}
              styles={customStyles}
              isDisabled={onDisableProvince(props.userInfo.role_id)}
              placeholder="Quận/Huyện/Thị xã"
            />
          </div>
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_WARD}
              status={true}
            />
            <Select
              value={valueWardDefault}
              onChange={handleChangeWard}
              options={props.listWard}
              menuPlacement="top"
              maxMenuHeight={180}
              styles={customStyles}
              placeholder="Xã phường/Thị trấn"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value={TEXT.TEXT_SCHOOL}
              status={true}
            />
            <Select
              value={valueSchool}
              onChange={handleChangeSchool}
              options={props.listSchool}
              menuPlacement="bottom"
              maxMenuHeight={180}
              styles={customStyles}
              placeholder="Trường"
            />
          </div>
          {isNewSchool && (
            <Fragment>
              <div className="form-group">
                <LabelInput
                  className="monkey-color-violet"
                  value={TEXT.TEXT_TYPE_SCHOOL}
                  status={true}
                />
                <Select
                  value={valueTypeSchool}
                  onChange={handleChangeTypeSchool}
                  options={TypeSchool}
                  maxMenuHeight={180}
                  menuPlacement="bottom"
                  placeholder="Loại Trường"
                  styles={customStyles}
                />
              </div>
              <div className="form-group">
                <LabelInput
                  className="monkey-color-violet"
                  value={TEXT.TEXT_NEW_SCHOOL_NAME}
                  status={true}
                />
                <input
                  style={{ height: "44px" }}
                  className="form-control rounded-pill"
                  type="text"
                  name="newSchoolName"
                  placeholder="Tên trường mới"
                  autoComplete="off"
                  value={newSchoolName}
                  onChange={(e) => setStateNewSchoolName(e.target.value)}
                />
              </div>
            </Fragment>
          )}
        </div>
        <div className="col-12">
          <input
            style={{ height: "44px" }}
            className="btn monkey-bg-violet monkey-color-white rounded-pill mr-2 hvr-registration-white"
            type="submit"
            disabled={onDisable(
              valueSchool,
              isNewSchool,
              newSchoolName,
              valueTypeSchool
            )}
            value={
              Number(localStorage.getItem("status")) === 1
                ? TEXT.TEXT_DONE
                : TEXT.TEXT_NEXT
            }
          />
        </div>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowLoading,
      onDispatchListDistrict,
      onDispatchListWard,
      onDispatchListSchool,
      onDispatchDataInfo,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(FromSchoolInfo);

function onDisable(valueSchool, isNewSchool, newSchoolName, valueTypeSchool) {
  let result = true;
  if (newSchoolName && valueTypeSchool) {
    result = false;
  }
  if (!isNewSchool && valueSchool) {
    result = false;
  }

  return result;
}
function onDisableProvince(roleId) {
  switch (Number(roleId)) {
    case 1:
    case 2:
    case 3:
      return true;
      break;
    default:
      return false;
  }
}
