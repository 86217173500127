import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { isCurrentRouteInBlackList } from "edu_lms_v2/utils/fb";
import { CLICK_CHATBOX } from "edu_lms/constants/eventNameGTM";
import { setEventGTM } from "edu_lms/constants/googleTagManager";

export default function MessengerLink() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [visibility, setVisibility] = useState(true);
  
  useEffect(() => {
    setVisibility(!isCurrentRouteInBlackList(currentPath));
  }, [currentPath]);

  return (
    visibility && (
      <Wrapper
        onClick={() => {
            setEventGTM({
                event: CLICK_CHATBOX,
            });
          window.open('https://m.me/106859728447188', "_blank");
        }}
      >
          <img style={{width:"100px"}} src="https://vnmedia2.monkeyuni.net/upload/hoc10/thumb/messenger-support-now.png" alt="Logo Hoc10" />
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  position: fixed;
  right: 12px;
  bottom: 125px;
  width: 100px;
  height: 100px;
  // background-color: #ff7707;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
  z-index: 1000;
`;

