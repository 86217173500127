import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import classNames from "classnames";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import { verifiedOtp } from "edu_lms/services/training";
import Loading from "edu_lms_v2/components/Loading";

const schema = yup.object().shape({
  otp: yup.string().required("Vui lòng nhập mã xác thực"),
});

export default function PopupResendEmail({
  show,
  onHide,
  userInfo,
  onSubmit,
  seconds,
  setSeconds,
  minutes,
  setMinutes,
  onUpdateSuccess,
  setSendOTPSuccess
}) {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const resultUserInfo = onResultUserInfo();

  const onClose = (isSuccess= false) => {
    setSendOTPSuccess(isSuccess);
    isSuccess
      ? localStorage.setItem(
          "timeSendEmail",
          JSON.stringify({ seconds: 0, minutes: 0 })
        )
      : localStorage.setItem(
          "timeSendEmail",
          JSON.stringify({ seconds: seconds, minutes: minutes })
        );
    reset();
    onHide();
  };

  const handleSubmitOtp = (data) => {
    const params = {
      opt: data?.otp,
    };
    setLoading(true);
    verifiedOtp(params)
      .then((res) => {
        if (res.data.status === "success") {
          toast.success(res.data.message);
          const email_verified = 1;
          setItemUserInfo({ ...resultUserInfo, email_verified });
          onUpdateSuccess({ ...resultUserInfo, email_verified });
          setSendOTPSuccess(true);
          onClose(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally((e) => setLoading(false));
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom-modal"
        onHide={onClose}
      >
        <Modal.Header closeButton className="">
          <Modal.Title
            className="modal-title quicksand-bold w-100 text-center"
            style={{ color: "#2A404F" }}
          >
            <p className="d-flex justify-content-center">
              Kiểm tra hòm thư của thầy/cô
            </p>
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleSubmitOtp)}>
          <Modal.Body className="">
            <div className="p-4 monkey-fz-20">
              <p>
                Một mã xác thực vừa được gửi tới <Span>{userInfo?.email}</Span>
              </p>
              <p className="pt-2">
                Thầy/cô vui lòng kiểm tra hòm thư và làm theo hướng dẫn để hoàn
                tất bước xác thực. Nếu không tìm thấy email, hãy kiểm tra{" "}
                <Span>hòm thư Spam</Span> hoặc bấm <Span>Gửi lại.</Span>
              </p>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <input
                type="text"
                className="form-control w-75  mx-auto"
                placeholder="Nhập mã xác thực tại đây"
                name="otp"
                ref={register}
              />
            </div>
            <div className="text-center monkey-color-red mt-1">
              {errors.otp?.message}
            </div>
            <div className="d-flex justify-content-center mt-3">
              <button
                type="submit"
                className={classNames(
                  "btn-sub mr-3 flex-center pl-3 pr-3 cursor"
                )}
                disabled={!(minutes === 0 && seconds === 0)}
                onClick={() => seconds === 0 && minutes === 0 && onSubmit()}
              >
                {seconds === 0 && minutes === 0 ? (
                  "Gửi lại"
                ) : (
                  <h1>
                    Gửi lại ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
                  </h1>
                )}
              </button>

              <div className="d-flex justify-content-start">
                <ButtonTimer
                  className="btn btn-pr"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Loading /> : "Xác nhận"}
                </ButtonTimer>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
}

const Span = styled.span`
  color: #2a404f;
  font-weight: 600;
`;

const ButtonTimer = styled.button`
  &.disabled {
    background-color: #ccc;
  }
  width: 100px;
  height: 50px;
`;
