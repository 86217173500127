import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { renderLabelIndex } from "edu_lms_v2/libraries/hoc10Game/helpers/MTC_003";

const OrderAnswerIndex = ({ typeIndex, index, styles }) => {
  const { color, background } = styles;
  const languageBook = useSelector(
    (state) => state.readingBookReducers.languageBook
  );

  return (
    <Label color={color} background={background}>
      {renderLabelIndex(typeIndex, index, languageBook)}
    </Label>
  );
};
export default OrderAnswerIndex;

const Label = styled.span`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
`;
