import React, { useEffect, useState } from 'react';
import './landing.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const posts = [
	{
		// marginTop: "735px",
		marginTop: "72%",
		title: "SGK Cánh Diều <span style='color: #FF3300'> Lớp 5</span>",
		date_complete: '29/06/2023',
		endTime: 'Jun 29 2023 14:00:00 GMT+0700'
	},
	{
		marginTop: "8%",
		title: "SGK Cánh Diều <span style='color: #FF3300'> Lớp 9</span>",
		date_complete: '30/06/2023',
		endTime: 'Jun 30 2023 09:00:00 GMT+0700'
	},
	{
		marginTop: "9%",
		title: "SGK Cánh Diều <span style='color: #FF3300'> Lớp 12</span>",
		date_complete: '07/07/2023',
		endTime: 'Jul 07 2023 08:30:00 GMT+0700'
	}
];

function CountdownTimer({ endTime, marginTop }) {
	const [days, setDays] = React.useState(null);
	const [hours, setHours] = React.useState(null);
	const [minutes, setMinutes] = React.useState(null);
	const [seconds, setSeconds] = React.useState(null);
	
	useEffect(() => {
		function getRemainingTime(endTime) {
			const total = Date.parse(endTime) - Date.parse(new Date());
			const seconds = Math.floor((total / 1000) % 60);
			const minutes = Math.floor((total / 1000 / 60) % 60);
			const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
			const days = Math.floor(total / (1000 * 60 * 60 * 24));
			return { total, days, hours, minutes, seconds };
		}
		const timeinterval = setInterval(updateClock, 1000);
		function updateClock() {
			const t = getRemainingTime(endTime);
			if (t.total <= 0) {
				clearInterval(timeinterval);
				setDays(0);
				setHours(0);
				setMinutes(0);
				setSeconds(0);
				return;
			}
			setDays(('0' + t.days).slice(-2));
			setHours(('0' + t.hours).slice(-2));
			setMinutes(('0' + t.minutes).slice(-2));
			setSeconds(('0' + t.seconds).slice(-2));
		}
		
		updateClock();
		return () => clearInterval(timeinterval);
	}, [endTime]);
	
	return (
		<div style={{paddingTop: marginTop}}>
			<div className="di item-time" style={{paddingLeft:"15%", letterSpacing: "16px"}}>{days}<br /></div>
			<div className="ho item-time" style={{paddingLeft:"21%", letterSpacing: "16px"}}>{hours}<br /></div>
			<div className="mi item-time" style={{paddingLeft:"20%", letterSpacing: "16px"}}>{minutes}<br /></div>
			<div className="se item-time" style={{paddingLeft:"21.5%", letterSpacing: "16px"}}>{seconds}<br /></div>
		</div>
	);
}

function CountdownItem({ title, dateComplete, endTime, marginTop }) {
	return (
		
				<div className="" style={{margin: "auto" }} data-end-time={endTime}>
					<div className="">
						<CountdownTimer endTime={endTime} marginTop={marginTop} />
					</div>
				</div>
	
	);
}

function RealtimeClock() {
	const [time, setTime] = useState(new Date());
	
	useEffect(() => {
		const intervalID = setInterval(() => {
			setTime(new Date());
		}, 1000);
		return () => clearInterval(intervalID);
	}, []);
	
	return (
		<div className="text-right">
			<span>{time.getDate()}/{time.getMonth()}/{time.getFullYear()} {time.toLocaleTimeString()}</span>
		</div>
	);
}

export default function BookAppraisal() {
	return (
		<div>
			<img style={{position:"absolute"}} src={"https://hoc10.monkeyuni.net/upload/hoc10/thumb/final_final_22_5.png"}/>
			<div style={{position:"relative"}} className="countdown styled">
				{posts.map((post) => (
				<CountdownItem marginTop={post.marginTop} key={post.title} title={post.title} dateComplete={post.date_complete} endTime={post.endTime} />
				))}
			</div>
		</div>
	);
}
