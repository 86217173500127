import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { getDataUrlListProvince } from "edu_lms/services/info/info";
import { LIST_PROVINCE_ALL } from "edu_lms/constants/type";
import { InfomationFilter } from "./InfomationFilter";
import { onResultUserInfo } from "edu_lms/modules/selection";
import * as PATH from "../../../consts/routePaths";
import { useHistory } from "react-router-dom";

export default function DashboardTraining() {
  useScrollToTop();
  const userInfo = onResultUserInfo();
  const [listProvince, setListProvince] = useState([]);
	
  const history = useHistory();
      if (!userInfo?.is_user_hoc10) {
	  history.push(PATH.ROUTE_PATH_NOT_FOUND);
  }

  useEffect(() => {
    getDataUrlListProvince(LIST_PROVINCE_ALL).then((res) => {
      if (res.data.status === "success") {
        setListProvince(res.data.data);
      }
    });
  }, []);
	
  return (
    <>
      <Wrapper className="text-center monkey-fz-24 monkey-color-black">
        DASHBOARD EVENT
      </Wrapper>
        <div className="container-fluid container-xl mb-5">
          <div className="introduce-header monkey-pb-20 monkey-mb-30 mt-4 pb-3">
            <InfomationFilter listProvince={listProvince} userInfo={userInfo} />
          </div>
        </div>
    </>
  );
}

const Wrapper = styled.div`
  margin-top: 100px;
`;
