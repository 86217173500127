import _ from "lodash";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { BOOK_TYPE_SGK } from "consts/book";
import ResultBook from "./ResultBook";
import { isMobile } from "react-device-detect";

export default function SectionSGK() {
  const books = useBookStore((state) => state.books);
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const booksOfCurrentSubject = _.get(books, [gradeId, subjectId], []);
  const sgkBooks = booksOfCurrentSubject.filter((item) => item.book_type_id === BOOK_TYPE_SGK);

  return (
    !_.isEmpty(sgkBooks) && (
      <div className={"result col-" + (isMobile ? (sgkBooks.length > 1 ? 12 : 6) : (sgkBooks.length * 3))}>
        <ResultBook
          data={sgkBooks}
          bookTypeId={BOOK_TYPE_SGK}
          categoryBook="Sách giáo khoa"
          col={"col-" + (isMobile ? (sgkBooks.length > 1 ? 6 : 12)  : (12/(sgkBooks.length))) }
          background="#FFFBF7"
        />
      </div>
    )
  );
}
