import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cleave from "cleave.js/react";
import {
  onDispatchShowPopupActivateBook,
  onDispatchTogglePopupSuccess,
} from "../../modules/App/actions";

import {
  onDispatchDataBook,
  onDispatchChangeTotal,
  onDispatchChangeIsActive,
  onDispatchChangeIsLicence,
} from "../../modules/ReadingBooks/actions";

import { getListBookDetail } from "../../services/readingBook";
import { getListBook } from "../../services/general/general";
import _ from "lodash";
import * as TEXT from "../../constants/text";
import * as PATH from "../../../consts/routePaths";
import { postActiveBook } from "../../services/activeBook/activeBook";
import {
  onDispatchGradeIdBooksInUse,
  onDispatchBooksTeacher,
  onDispatchBooksTest3710,
  onDispatchBooksInUse,
} from "../../modules/General/actions";
import { TYPE_TEXT_BOOKS_USED } from "../../constants/general";
import { getListBookUse } from "../../services/general/general";
import { onDispatchShowLoading } from "../../modules/App/actions";
import ListActiveBook from "./ListActiveBook";
import FromCodeBook from "./FromCodeBook";
import { removeAccents } from "../selection";
import ModalActiveBookSuccess from "./ModalActiveBookSuccess";

const FromActivateBook = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isStatus, onSetStatus] = useState(false);
  const [messageError, onSetMessageError] = useState("");
  const [valueLicence, setStateValueLicence] = useState("");
  const [errorLicence, setStateErrorLicence] = useState(false);
  const [showFormListBook, setStateListBook] = useState(false);
  const onHide = () => {
    setIsSuccess(false);
    props.onDispatchShowPopupActivateBook(false);
  };
  const onPostActiveBook = (data) => {
    postActiveBook(data)
      .then((data) => {
        if (data.data.status === "fail") {
          onSetStatus(true);
          onSetMessageError(data.data.message);
          props.onDispatchShowLoading(false);
          if (data.data.code === 301) {
            setStateListBook(true);
            onSetMessageError("");
          }
        }
        if (data.data.status === "success") {
          const dataConfig = {
            book_id: props.paramaterBookId,
            page: 0,
            limit: 0,
            status: process.env.REACT_APP_ENVIROMENT === "production" ? 1 : "",
          };

          onGetListBook(dataConfig);

          props.onDispatchGradeIdBooksInUse(data.data.data.grade_id);
          const parameterTextBooks = {
            typeBook: TYPE_TEXT_BOOKS_USED,
            gradeId: data.data.data.grade_id,
            limit: 10,
          };
          props.onDispatchShowLoading(false);
          if (parameterTextBooks.typeBook != undefined) {
            getListBookUse(parameterTextBooks)
              .then((data) => {
                if (data.data.status === "success") {
                  props.onDispatchBooksInUse(data.data.data);
                }
              })
              .catch((errors) => {
                console.log(errors);
              });
          }

          const paramterListTeacher = {
            gradeId: data.data.data.grade_id,
            typeBook: 3,
          };
          if (paramterListTeacher.typeBook !== undefined) {
            getListBook(paramterListTeacher).then((res) => {
              if (res.data.status === "success") {
                props.onDispatchBooksTeacher(res.data.data);
              }
            });
          }
          const paramterListTest3710 = {
            gradeId: data.data.data.grade_id,
            typeBook: 6,
          };
          if (paramterListTeacher.typeBook !== undefined) {
            getListBook(paramterListTest3710).then((res) => {
              if (res.data.status === "success") {
                props.onDispatchBooksTest3710(res.data.data);
              }
            });
          }

          //check active success
          let pageId = 0;
          const textAlertActiveSuccess = (
            TEXT.TITLE_POPUP_ACTIVE_BOOK_SUCCESS +
            " " +
            data.data.data.book_name
          ).toUpperCase();
          const converTitle = removeAccents(data.data.data.book_name);
          const typeBook = data.data.data.book_type;
          const id = data.data.data.book_id;
          const dataSuccess = {
            status: true,
            title: [textAlertActiveSuccess],
            close: true,
            url: `${PATH.ROUTE_PATH_READING_BOOKS}/${converTitle}/${typeBook}/${id}/${pageId}/`,
            labelButton: TEXT.BUTTON_READ_NOW,
          };
          // props.onDispatchShowPopupActivateBook(false);
          // props.onDispatchTogglePopupSuccess(dataSuccess);
          setIsSuccess(true);
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const onConfirm = (data) => {
    if (valueLicence) {
      setStateErrorLicence(false);
      data = {
        licence: valueLicence,
        book_id: props.bookId,
      };
      props.onDispatchShowLoading(true);
      onPostActiveBook(data);
    } else {
      setStateErrorLicence(true);
    }
  };

  const onGetListBook = (data) => {
    props.onDispatchShowLoading(true);
    if (data.book_id !== undefined) {
      getListBookDetail(data)
        .then((res) => {
          props.onDispatchShowLoading(false);
          if (res.data.status === "success") {
            const data = _.concat(res.data.data.list_page);
            props.onDispatchDataBook(data);
            props.onDispatchChangeTotal(res.data.data.total);
            props.onDispatchChangeIsActive(res.data.data.is_active);
            props.onDispatchChangeIsLicence(res.data.data.is_licence);
          }
        })
        .catch((error) => {
          console.log(error);
          props.onDispatchShowLoading(false);
        });
    }
  };

  const onActiveBook = (data) => {
    data = {
      licence: valueLicence,
      book_id: data.id,
    };
    onPostActiveBook(data);
  };
  const onStateValueLicence = (value) => {
    setStateValueLicence(value);
  };
  return (
    <Fragment>
      {!showFormListBook && (
        <FromCodeBook
          valueLicence={valueLicence}
          errorLicence={errorLicence}
          isStatus={isStatus}
          messageError={messageError}
          onConfirm={onConfirm}
          onStateValueLicence={onStateValueLicence}
        />
      )}
      {showFormListBook && (
        <ListActiveBook
          messageError={messageError}
          onDispatchShowLoading={props.onDispatchShowLoading}
          onActiveBook={onActiveBook}
        />
      )}
      {isSuccess && <ModalActiveBookSuccess onHide={() => onHide()} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { paramaterBookId } = state.readingBookReducers;
  const { bookId } = state.generalReducer;
  return {
    paramaterBookId,
    bookId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
      onDispatchTogglePopupSuccess,
      onDispatchGradeIdBooksInUse,
      onDispatchShowLoading,
      onDispatchBooksInUse,
      onDispatchDataBook,
      onDispatchChangeTotal,
      onDispatchChangeIsActive,
      onDispatchChangeIsLicence,
      onDispatchBooksTeacher,
      onDispatchBooksTest3710,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FromActivateBook);
