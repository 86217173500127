import React from "react";
import _ from "lodash";
import { Audio, AudioType, Typography, Video, ImageWithFullScreen } from "../../components";
import { TYPE_DATA } from "../../constants";
import THEMES from "edu_lms_v2/libraries/hoc10Game/constants/themes";
import "./styles.scss";

// For MTC_003, CKB_001
const HeaderQuestion = ({ question, theme }) => {
  const { typeQuestion, contentQuestion } = question;

  return (
    <div className="mt-2 mb-3 quicksand-medium">
      <div className="d-flex justify-content-start">
        {_.includes(typeQuestion, TYPE_DATA.AUDIO) && (
          <div className="ml-2 d-flex justify-content-start header__audio-wrapper">
            <Audio variant={AudioType.Primary} src={contentQuestion.srcAudio} />
          </div>
        )}
        {_.includes(typeQuestion, TYPE_DATA.TEXT) && (
          <div className="header__text-wrapper">
            <Typography
              fontSize={contentQuestion.text.fontSize}
              typeText={contentQuestion.text.typeText ?? "latex"}
              text={contentQuestion.text.contentText}
            />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center">
        {_.includes(typeQuestion, TYPE_DATA.VIDEO) && (
          <Video src={contentQuestion.srcVideo} />
        )}
        {_.includes(typeQuestion, TYPE_DATA.IMAGE) && (
          <ImageWithFullScreen
            src={contentQuestion.srcImage}
            alt="select answer image"
            hasFullScreen={theme === THEMES.MultipleChoice.Outline}
          />
        )}
      </div>
    </div>
  );
};

export default HeaderQuestion;
