import Slider from "edu_lms_v2/components/Slider";
import { feelings } from "edu_lms_v2/data/feelings";

export default function FeedbackSlider() {
  return (
    <Slider
      className="feedback-list"
      centerMode={false}
      slidesToShow={3}
      slidesToScroll={1}
      infinite={true}
      centerPadding={0}
      autoplay={false}
      dots={true}
      arrows={true}
      responsive={[
        {
          breakpoint: 830,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ]}
    >
      {feelings.map((data) => (
        <div key={data.name} className="feedback">
          <div className="feedback__content">
            <div className="text">{data.text}</div>
            <div className="author flex">
              <div className="author__img">
                <img src={data.img} className="fit" alt="Ảnh tác giả" />
              </div>
              <div className="author__info">
                <h5>{data.name}</h5>
                <p>{data.info}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}
