import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import {
  onDispatchShowPopupPDF,
  onDispatchShowPopupActivateBook,
  onDispatchTypePopup,
} from "edu_lms/modules/App/actions";
import { TYPE_POPUP_PDF } from "edu_lms/constants/type";

export default function Footer({ clickActiveBook }) {
  const dispatch = useDispatch();
  const onShowPopupPdf = (data) => {
    // window.open (data.url, '_blank');
    const dataSuccess = {
      title: data.title,
      url: data.url,
    };
    dispatch(onDispatchTypePopup(TYPE_POPUP_PDF));
    dispatch(onDispatchShowPopupActivateBook(true));
    dispatch(onDispatchShowPopupPDF(dataSuccess));
  };
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP_COMMON}E_Learning/web_site`;
  const license = {
    title: "Giấy xác nhận xuất bản phẩm điện tử của Bộ TTTT",
    url: `${urlImg}/giay_xac_nhan_xuat_ban.pdf`,
  };

  const ButtonStyle = styled.button`
    color: #ff8203;
  `;

  return (
    <footer>
      <div className="container footer">
        <div className="flex info">
          <div className="col-left">
            <h2>
              Công ty cổ phần <span>One Percent</span>
            </h2>
            <div className="hotline flex-lc">
              <i className="icon icon-phone"></i>
              <a href="tel:024.73098866">024.73098866</a>
            </div>
          </div>
          <div className="col-right">
            <p>
              Giấy phép ĐKKD số <span>0109621809</span> do sở Kế hoạch và Đầu tư
              TP. Hà Nội cấp. Ngày cấp <span>06.05.2021</span>{" "}
              <p>
                Giấy xác nhận xuất bản phẩm điện tử của Bộ TTTT
                <ButtonPopupPdf
                  className="btn_Information"
                  onClick={() => onShowPopupPdf(license)}
                >
                  &ensp;( Xem giấy phép )
                </ButtonPopupPdf>
              </p>
            </p>
            <p>
              Trụ sở chính:{" "}
              <span>
                Tầng 2, Tòa nhà HH-NO1, dự án Gold Season, số 47 đường Nguyễn
                Tuân, Phường Thanh Xuân Trung, Quận Thanh Xuân, Thành phố Hà Nội
                , Việt Nam
              </span>
            </p>
            <p>
              Người đại diện pháp luật:{" "}
              <span>Ông Đào Xuân Hoàng - Tổng giám đốc</span>
            </p>
          </div>
        </div>
        <div className="logo-white">
          <Link to={PATH.ROUTE_PATH_V2_HOME}>
            <img src="/assets/img/updated_logo1.png" alt="Logo Hoc10" />
          </Link>
        </div>
        <div className="middle flex pr">
          <div className="col col1">
            <h3>VỀ HOC10</h3>
            <ul className="menu">
              <li>
                <Link to={PATH.ROUTE_PATH_V3_BOOK_INTRO}>
                  Bộ sách <span>Cánh Diều</span>
                </Link>
              </li>
              <li>
                <Link to={PATH.ROUTE_PATH_V3_CONTACT}>Liên hệ</Link>
              </li>
              <li>
                <Link to={PATH.ROUTE_PATH_V3_PRIVACY_POLICY}>
                  Chính sách bảo mật
                </Link>
              </li>
              <li>
                <Link to={PATH.ROUTE_PATH_V3_TERM_OF_USE}>
                  Điều khoản sử dụng
                </Link>
              </li>
            </ul>
          </div>
          <div className="col col2">
            <div className="col-group">
              <h3>GIÁO VIÊN</h3>
              <ul className="menu">
                <li>
                  <Link
                    to={`${PATH.ROUTE_PATH_TU_SACH}?block=1&grade=4&subject=32`}
                  >
                    Sách giáo viên
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_LIST_SLIDE}>Bài giảng</Link>
                </li>
              </ul>
            </div>
            <div className="col-group">
              <h3>HỌC SINH</h3>
              <ul className="menu">
                <li>
                  <Link to={PATH.ROUTE_PATH_TU_SACH}>Tủ sách</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col3">
            <div className="col-group">
              <h3>HỖ TRỢ</h3>
              <ul className="menu">
                <li>
                  <StyleBookActivation onClick={(e) => clickActiveBook(e)}>
                    Kích hoạt sách
                  </StyleBookActivation>
                </li>
                {/* <li>
                <a href="" title="">
                  Hướng dẫn học
                </a>
              </li> */}
                {/* <li>
                <a href="" title="">
                  Hỗ trợ khách hàng
                </a>
              </li> */}
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}>
                    Câu hỏi thường gặp
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PUBLISHER_LIST}>Địa chỉ mua sách</Link>
                </li>
                <li>
                  <Link
                    to={`${PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}?q=huong-dan-kiem-tra-sach-that`}
                  >
                    Hướng dẫn kiểm tra sách thật
                  </Link>
                </li>
                {/* <li>
                <a href="" title="">
                  Cộng đồng
                </a>
              </li> */}
              </ul>
              <div className="col-group pt-3">
                <h3>Liên Kết</h3>
                <ul className="menu">
                  <li>
                    <a
                      href="https://monkey.edu.vn/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Monkey Việt Nam{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col col4">
            <div className="service">
              <p>Hotline & Dịch Vụ Khách Hàng</p>
              <div className="flex box-btn">
                <a href="tel:024.73098866">
                  <i className="icon icon-phone"></i> 024.73098866
                </a>
                <a href="mailto:contact@hoc10.vn">
                  <i className="icon icon-mail"></i> contact@hoc10.vn
                </a>
              </div>
              <span>8h00 - 21h30 các ngày trong tuần</span>
            </div>
            <div className="social">
              <p>
                Kết nối với <span>Hoc10</span>
              </p>
              <div className="social__list flex">
                <a
                  href="https://www.facebook.com/Hoc10-H%E1%BB%8Dc-1-bi%E1%BA%BFt-10-106859728447188/"
                  target="_blank"
                  title=""
                  className="facebook flex-center"
                >
                  <img
                    src="/assets/img/icon-facebook.svg"
                    alt="Logo facebook"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCtmc2xq9J4n2_kuOAue7dCQ"
                  target="_blank"
                  title=""
                  className="youtube flex-center"
                >
                  <img src="/assets/img/icon-youtube.svg" alt="Logo youtube" />
                </a>
              </div>
            </div>
	          <div className="fs-ftr">
		          <a href="//www.dmca.com/Protection/Status.aspx?ID=5d7dee04-b7ca-4a0a-9e06-f0daaab3093a" title="DMCA.com Protection Status" className="dmca-badge fs-ftr-dmca"> <img
			          src="https://images.dmca.com/Badges/dmca_protected_16_120.png?ID=5d7dee04-b7ca-4a0a-9e06-f0daaab3093a" alt="DMCA.com Protection Status"/></a>
		          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
		          {/*<a*/ }
		          {/*    href="http://online.gov.vn/Home/WebDetails/86940?AspxAutoDetectCookieSupport=1"*/ }
		          {/*    target="_blank"*/ }
		          {/*    className="fs-ftr-cthuong"*/ }
		          {/*>*/ }
		          {/*    */ }
		          {/*    Đã thông báo*/ }
		          {/*</a>*/ }
		          <a href="http://online.gov.vn/Home/WebDetails/86940?AspxAutoDetectCookieSupport=1" target="_blank" style={{marginLeft:'15px'}}> <img
			          src={`${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/bocongthuong.png`}
			          alt="bo-cong-thuong"/></a>
	          </div>
          
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="container flex">
          <div className="copyright">
            © 2021 - Bản quyền của công ty cổ phần <span>One Percent</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
const StyleBookActivation = styled.div`
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  color: rgba(212, 229, 247, 0.7);
  cursor: pointer;
`;
const ButtonPopupPdf = styled.button`
  cursor: pointer;
  color: rgba(212, 229, 247, 0.7);
  &:hover {
    color: #fff !important;
  }
`;
