import _ from "lodash";
import { getContentAudioVideoImageTextFromIconActData, getIconsListByLanguage } from ".";
import { SPACE_AREA, BOX_SIZE_WRAPPER, TYPE_DISPLAY, TYPE_QUESTION_3_COLUMNS, BOX_TYPE } from "../constants/MAT_001";
import { COLOR } from "../constants/styles";

const formatActivityDataMAT_001 = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    answer,
    number,
    title_question: titleQuestion,
    font_size_title: fontSizeTitle,
    type_question: typeText, // Because CMS reversed key type_text, type_question
    order_answer: answers,
    question: questions,
    type_display: typeDisplay,
    type_game: typeGame,
    type_text: typeQuestion, // Because CMS reversed key type_text, type_question
    font_size_content: fontSizeContent,
    column_title_left: columnTitleLeft,
    column_title_right: columnTitleRight,
  } = gameConfig;

  const titleQuestionId = titleQuestion ? titleQuestion.icon_id : _.get(gameConfig, "back_ground.icon_id", "")
  const titleQuestionContent = getContentAudioVideoImageTextFromIconActData(iconsList, titleQuestionId);

  const questionsMatch = questions.map((qus) => {
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      qus.icon_id
    );
    return {
      id: qus.icon_id,
      srcAudio: questionContentIcon.srcAudio,
      srcImage: questionContentIcon.srcImage,
      contentText: questionContentIcon.contentText,
    };
  });

  const answersMatch = answers.map((ans) => {
    const answerContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      ans.icon_id
    );
    return {
      id: ans.icon_id,
      srcAudio: answerContentIcon.srcAudio,
      srcImage: answerContentIcon.srcImage,
      contentText: answerContentIcon.contentText,
    };
  });

  const couplesAnswerQuestion = answer.couple_of_icon.map((couple) => ({
    questionId: couple.icon_id_question,
    answerId: couple.icon_id_answer,
  }));

  return [
    {
      typeDisplay: Number(typeDisplay),
      typeGame: Number(typeGame),
      answersMatch,
      questionsMatch,
      couplesAnswerQuestion,
      numberOfItemInRowOrCol: number,
      fontSizeContent,
      typeText,
      columnTitleLeft,
      columnTitleRight,
      titleQuestion: {
        fontSizeTitle,
        typeQuestion,
        contentText: titleQuestionContent.contentText
      }
    },
  ];
};

const getNumberOffSetOfQuestionTarget = (target, typeDisplay, typeQuestion3Columns = null) => {
  let numberOffSetX, numberOffSetY = 0;
  switch (typeDisplay) {
    case TYPE_DISPLAY.Horizontal:
      numberOffSetX = target.offsetLeft + target.offsetWidth / 2;
      numberOffSetY = target.offsetTop + target.offsetHeight;
      break;
    case TYPE_DISPLAY.Vertical:
      numberOffSetX = target.offsetLeft + target.offsetWidth;
      numberOffSetY = target.offsetTop + target.offsetHeight / 2;
      break;
    case TYPE_DISPLAY.Vertical3Columns:
      if (typeQuestion3Columns === TYPE_QUESTION_3_COLUMNS.Left) {
        numberOffSetX = target.offsetLeft + target.offsetWidth;
      } else {
        numberOffSetX = target.offsetLeft + target.offsetWidth + 2 * SPACE_AREA.WidthThreeColumns + BOX_SIZE_WRAPPER.Vertical3Columns.Width;
      }
      numberOffSetY = target.offsetTop + target.offsetHeight / 2;
      break;
    default:
      break;
  }
  return { numberOffSetX, numberOffSetY };
};

const getNumberOffSetOfAnswerTarget = (target, typeDisplay, typeQuestion3Columns = null) => {
  let numberOffSetX, numberOffSetY = 0;
  switch (typeDisplay) {
    case TYPE_DISPLAY.Horizontal:
      numberOffSetX = target.offsetLeft + target.offsetWidth / 2;
      numberOffSetY = target.offsetTop + SPACE_AREA.Height + target.offsetHeight;
      break;
    case TYPE_DISPLAY.Vertical:
      numberOffSetX = target.offsetLeft + SPACE_AREA.Width + target.offsetWidth;
      numberOffSetY = target.offsetTop + target.offsetHeight / 2;
      break;
    case TYPE_DISPLAY.Vertical3Columns:
      if (typeQuestion3Columns === TYPE_QUESTION_3_COLUMNS.Left) {
        numberOffSetX = target.offsetLeft + BOX_SIZE_WRAPPER.Default + SPACE_AREA.WidthThreeColumns;
      } else {
        numberOffSetX = target.offsetLeft + BOX_SIZE_WRAPPER.Default + SPACE_AREA.WidthThreeColumns + target.offsetWidth;
      }
      numberOffSetY = target.offsetTop + target.offsetHeight / 2;  
      break;
    default:
      break;
  }
  return { numberOffSetX, numberOffSetY };
};

const getClassNameItemsMatchWrapper = (typeDisplay) => {
  if (typeDisplay === TYPE_DISPLAY.Horizontal) return "flex-row";
  if (
    typeDisplay === TYPE_DISPLAY.Vertical ||
    typeDisplay === TYPE_DISPLAY.Vertical3Columns
  ) {
    return "flex-column";
  }
  return "";
};

const getClassNameCoverCanvas = (typeDisplay) => {
  if (typeDisplay === TYPE_DISPLAY.Horizontal) return "d-flex flex-column";
  if (
    typeDisplay === TYPE_DISPLAY.Vertical ||
    typeDisplay === TYPE_DISPLAY.Vertical3Columns
  ) {
    return "d-flex flex-row cover-canvas-vertical";
  }
  return "";
};

const getBoxSizeWidth = (typeDisplay, boxType = null) => {
  if (typeDisplay === TYPE_DISPLAY.Horizontal) return BOX_SIZE_WRAPPER.Default;
  if (typeDisplay === TYPE_DISPLAY.Vertical) return BOX_SIZE_WRAPPER.Vertical.Width;
  if (typeDisplay === TYPE_DISPLAY.Vertical3Columns) {
    return boxType === BOX_TYPE.Question ? BOX_SIZE_WRAPPER.Default : BOX_SIZE_WRAPPER.Vertical3Columns.Width;
  }
  return 0;
};

const checkResultAnswer = (matchedCouplePoints = [], couplesAnswerQuestion = []) => {
  let countCorrectResult = 0;
  const matchedCouplePointsResult = [...matchedCouplePoints];

  couplesAnswerQuestion.forEach((couple) => {
    const correctCouplePointIndex = matchedCouplePoints.findIndex(
      (matchedCouple) =>
        matchedCouple.questionId === couple.questionId &&
        matchedCouple.answerId === couple.answerId
    );
    if (correctCouplePointIndex > -1) {
      countCorrectResult++;
      matchedCouplePointsResult[correctCouplePointIndex].isCorrect = true;
    }
  });

  const isCorrect = countCorrectResult === couplesAnswerQuestion.length;
  return { isCorrect, numberOfCorrectMatched: countCorrectResult, matchedCouplePointsResult };
};

const getColorBackground = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return COLOR.Success;
    case false:
      return COLOR.Error;
    default:
      return COLOR.Orange;
  }
};

export {
  formatActivityDataMAT_001,
  getNumberOffSetOfQuestionTarget,
  getNumberOffSetOfAnswerTarget,
  getClassNameItemsMatchWrapper,
  getClassNameCoverCanvas,
  checkResultAnswer,
  getBoxSizeWidth,
  getColorBackground,
};
