import React, { forwardRef } from "react";
import { GAME_VERSION } from "../../constants";
import FIB_BG_V1 from "./FIB_BG_V1";
import FIB_BG_V0 from "./FIB_BG_V0";
import "./styles.scss";

const FIB_BG = (props, ref) => {
  const { version } = props.gameData;

  if (version === GAME_VERSION.V1) {
    return <FIB_BG_V1 ref={ref} {...props} />;
  }

  return <FIB_BG_V0 ref={ref} {...props} />;
};

export default forwardRef(FIB_BG);
