import React, { useState, Fragment } from "react";
import { Audio, AudioType } from "../../components";
import { TYPE_GAME_MAT_001, QUESTION_TITLE_HEIGHT, TYPE_DISPLAY } from "../../constants/MAT_001";
import { getBoxSizeWidth } from "../../helpers/MAT_001";

const QuestionHeader = ({ questionsMatch, typeGame }) => {
  const [srcAudio, setSrcAudio] = useState("");

  const widthBox = getBoxSizeWidth(TYPE_DISPLAY.Horizontal);

  return (
    <Fragment>
      <div className="d-flex justify-content-center question-header position-relative quicksand-medium">
        {questionsMatch.map((question, index) => {
          return (
            <div
              key={index}
              className="text-center ml-3 mr-3"
              style={{ height: QUESTION_TITLE_HEIGHT, width: widthBox }}
            >
              {question.srcAudio && (
                <i
                  className="monkey-fz-16 fa fa-volume-up cursor hvr-registration"
                  aria-hidden="true"
                  onClick={() => setSrcAudio(question.srcAudio)}
                />
              )}
              {typeGame === TYPE_GAME_MAT_001.ImageText_Text && (
                <span>{question.contentText}</span>
              )}
            </div>
          );
        })}
      </div>
      {srcAudio && (
        <Audio
          className="d-none"
          variant={AudioType.Secondary}
          src={srcAudio}
          autoPlay
          onEnded={() => setSrcAudio("")}
        />
      )}
    </Fragment>
  );
};

export default QuestionHeader;
