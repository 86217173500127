import { React, useState } from "react";
import styled from "styled-components";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Tooltip } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { URL_IMAGE_QUESTION } from "../../constants/type";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { TYPE_VIETNAMESE } from "./../../constants/type";
import { TypeGame } from "./../../components/configGame";
import { onDispatchClickDownImg } from "../../modules/General/actions";
import { onDispatchUpdateBookToolGame } from "edu_lms/modules/ReadingBooks/actions";
import classNames from "classnames";

const Toolbox = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: calc(100vw - 60px);
  margin: 10px 0;
  padding: 5px;
  button {
    margin-left: 10px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ButtonToolTip = styled.button``;
function onShowButton(data) {
  let result = false;
  if (_.includes(data, data?.answer)) {
    if (data.answer?.length > 0 || data.answer?.couple_of_icon?.length > 0) {
      result = true;
    }
  }
  if (_.includes(data, data?.data)) {
    if (data.data?.length > 0 || data.data?.table?.length > 0) {
      result = true;
    }
  }

  return result;
}

const IntroduceWrapper = ({
  dataIntroduce,
  languageBook,
  onChangeShowQuestion,
  onDispatchClickDownImg,
}) => {
  const dispatch = useDispatch();
  const bookTool = useSelector(
    (state) => state.readingBookReducers.bookTool.game
  );

  const [tooltipZoomOut, setTooltipZoomOut] = useState(false);
  const toggleZoomOut = () => setTooltipZoomOut(!tooltipZoomOut);

  const [tooltipZoomIn, setTooltipZoomIn] = useState(false);
  const toggleZoomIn = () => setTooltipZoomIn(!tooltipZoomIn);

  const [tooltipReset, setTooltipReset] = useState(false);
  const toggleReset = () => setTooltipReset(!tooltipReset);

  const [tooltipDownload, setTooltipDownload] = useState(false);
  const [tooltipToolbar, setTooltipToolbar] = useState(false);
  const toggleDownload = () => setTooltipDownload(!tooltipDownload);
  const toggleToolbar = () => setTooltipToolbar(!tooltipToolbar);

  const showToolbar = () => {
    dispatch(
      onDispatchUpdateBookToolGame({
        ...bookTool,
        show: !bookTool.show,
        control: false,
      })
    );
  };
  let srcImage = null;

  if (dataIntroduce?.icon_list) {
    srcImage = dataIntroduce?.icon_list[0]?.icons.filter(
      (item) =>
        String(item?.icon_id).replace(/\s+/g, "") ===
        String(dataIntroduce?.game_config.back_ground?.icon_id).replace(
          /\s+/g,
          ""
        )
    );
  }

  const isShowButton = onShowButton(dataIntroduce?.game_config);
  const download = (e) => {
    onDispatchClickDownImg(true);
    fetch(`${URL_IMAGE_QUESTION}${srcImage[0]?.path}`, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        defaultPositionX={200}
        defaultPositionY={100}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <Toolbox
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div style={{ display: "flex" }}>
                <button
                  id="TooltipZoomOut"
                  className="btn d-flex justify-content-center align-items-center monkey-bg-blue monkey-color-white"
                  onClick={() => zoomIn(0.1)}
                >
                  <i className="fa fa-search-plus" aria-hidden="true"></i>
                </button>
                <Tooltip
                  isOpen={tooltipZoomOut}
                  target="TooltipZoomOut"
                  toggle={toggleZoomOut}
                >
                  Phóng to
                </Tooltip>
                <button
                  id="TooltipZoomIn"
                  className="btn d-flex justify-content-center align-items-center monkey-bg-blue monkey-color-white"
                  onClick={() => zoomOut(0.1)}
                >
                  <i className="fa fa-search-minus" aria-hidden="true"></i>
                </button>
                <Tooltip
                  isOpen={tooltipZoomIn}
                  target="TooltipZoomIn"
                  toggle={toggleZoomIn}
                >
                  Thu nhỏ
                </Tooltip>
                <button
                  id="TooltipReset"
                  className="btn d-flex justify-content-center align-items-center monkey-bg-blue monkey-color-white"
                  onClick={() => resetTransform()}
                >
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
                <Tooltip
                  isOpen={tooltipReset}
                  target="TooltipReset"
                  toggle={toggleReset}
                >
                  100%
                </Tooltip>
                <button
                  id="TooltipDownload"
                  className="btn d-flex justify-content-center align-items-center monkey-bg-blue monkey-color-white"
                  onClick={() => download()}
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
                <Tooltip
                  isOpen={tooltipDownload}
                  target="TooltipDownload"
                  toggle={toggleDownload}
                >
                  Tải xuống
                </Tooltip>
                <ButtonToolTip
                  id="TooltipToolbar"
                  className={classNames(
                    "btn d-flex justify-content-center align-items-center monkey-color-white",
                    { "monkey-bg-blue": !bookTool.show },
                    {
                      "monkey-bg-orange": bookTool.show,
                    }
                  )}
                  onClick={() => showToolbar()}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                </ButtonToolTip>
                <Tooltip
                  isOpen={tooltipToolbar}
                  target="TooltipToolbar"
                  toggle={toggleToolbar}
                >
                  Thanh công cụ
                </Tooltip>
              </div>
              {isShowButton && (
                <div>
                  <button
                    onClick={onChangeShowQuestion}
                    className="btn monkey-bg-blue monkey-color-white pr-3 pl-3"
                  >
                    {languageBook == TYPE_VIETNAMESE
                      ? dataIntroduce?.game_id ==
                        TypeGame.PRONUNCIATION_EXERCISES
                        ? "Xem tiếp"
                        : "Bắt đầu"
                      : dataIntroduce?.game_id ==
                        TypeGame.PRONUNCIATION_EXERCISES
                      ? "See more"
                      : "Start"}
                  </button>
                </div>
              )}
            </Toolbox>
            <TransformComponent
              wrapperStyle={{ width: "100%" }}
              contentStyle={{ width: "100%" }}
            >
              <ImageWrapper>
                {srcImage[0] && (
                  <Image
                    src={`${URL_IMAGE_QUESTION}${srcImage[0]?.path}`}
                    alt="image-question-book"
                  />
                )}
              </ImageWrapper>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchClickDownImg,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(IntroduceWrapper);
