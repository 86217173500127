import { feelings } from "edu_lms_v2/data/feelings";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "../../../edu_lms/components/Seo";
import { pages } from "../../../edu_lms/components/Seo/meta";
import FeedbackSlider from "../Home/FeedbackSlider";

export default function Teacher() {
  useScrollToTop();

  return (
    <main>
      <SEO data={pages.teacher} />
      <div class="hoc10-breadcrumb bg-gr">
        <div class="container">
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Giáo viên
            </li>
          </ul>
        </div>
      </div>
      <div class="hoc10-title bg-gr">
        <div class="container">
          <div class="header decor-h1 pr">
            <h1 class="title">Giáo viên</h1>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--t1">
        <div class="container">
          <ul class="feature-list flex">
            <li>
              <div class="icon">
                <img
                  src="/assets/img/icon-hs1.svg"
                  alt="Học bằng công nghệ AI"
                />
              </div>
              <h4>Cá nhân hóa lộ trình học bằng công nghệ AI</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs2.svg" alt="Ôn luyện kiến thức" />
              </div>
              <h4>Ôn luyện kiến thức mọi lúc, mọi nơi</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs3.svg" alt="Báo cáo học tập" />
              </div>
              <h4>Báo cáo học tập thường xuyên</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs4.svg" alt="Kiểm tra năng lực" />
              </div>
              <h4>Kiểm tra đánh giá năng lực chính xác</h4>
            </li>
          </ul>
          <div
            class="video video-ty1 pr"
            data-link=""
            data-bs-toggle="modal"
            data-bs-target="#modal-video"
          >
            <div class="video__thumb">
              <img src="/assets/img/thumb-video7.jpg" alt="Thumb" />
            </div>
            <div class="video__play">
              <img src="/assets/img/icon-play.svg" alt="Thumb" />
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--t2 bg-gr">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title">Tủ sách điện tử</h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-book3.svg" alt="sách" />
                    </div>
                    <div class="text txt-1">
                      Phiên bản sách điện tử bao gồm nhiều học liệu phong phú
                      (video, audio...)
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-list.svg" alt="Menu" />
                    </div>
                    <div class="text txt-1">
                      Thanh công cụ giúp giáo viên tương tác dễ dàng trên trang
                      sách
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-bk4.svg" alt="bài tập" />
                    </div>
                    <div class="text txt-1">
                      Bài tập tương tác đa dạng, sinh động
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="img-wrapper bg-sh pr">
                <img src="/assets/img/img-sc3.jpg" alt="Đọc sách dễ dàng" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--t3">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-7">
              <div class="img-wrapper bg-sh pr">
                <img src="/assets/img/img-sc4.jpg" alt="Bài giảng điện tử" />
              </div>
            </div>
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title">Bài giảng điện tử</h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-book3.svg"
                        alt="Kho bài giảng"
                      />
                    </div>
                    <div class="text txt-1">
                      Kho bài giảng sinh động hoàn toàn miễn phí
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-bk4.svg"
                        alt="Bám sát chương trình"
                      />
                    </div>
                    <div class="text txt-1">
                      Bám sát chương trinh SGK Cánh Diều
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/tick.svg" alt="Cập nhật" />
                    </div>
                    <div class="text txt-1">
                      Cập nhật mới hàng tuần theo tiến độ giảng dạy
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--4 bg-gr">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title flex-lc">
                  Lớp học <span>Sắp ra mắt</span>
                </h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-laptop.svg" alt="laptop" />
                    </div>
                    <div class="text txt-1">Tham gia lớp học dễ dàng</div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-check.svg" alt="Quản lí" />
                    </div>
                    <div class="text txt-1">Quản lý bài tập và tiến độ học</div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-chart.svg" alt="Theo dõi" />
                    </div>
                    <div class="text txt-1">
                      Theo dõi báo cáo học tập thường xuyên
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-connect.svg" alt="Kết nối" />
                    </div>
                    <div class="text txt-1">
                      Kết nối phụ huynh với thao tác đơn giản
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="box-video pr">
                <div
                  class="video video-ty1 pr"
                  data-link=""
                  data-bs-toggle="modal"
                  data-bs-target="#modal-video"
                >
                  <div class="video__thumb">
                    <img src="/assets/img/thumb-video8.jpg" alt="Stop" />
                  </div>
                  <div class="video__play">
                    <img src="/assets/img/icon-play.svg" alt="Play" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="statistical flex">
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-book1.svg" alt="Sách" />
              </div>
              <div class="content">
                <div class="number">100</div>
                <div class="text">Đầu sách</div>
              </div>
            </div>
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-pdf2.svg" alt="Giáo án" />
              </div>
              <div class="content">
                <div class="number">300</div>
                <div class="text">Bài giảng đã có</div>
              </div>
            </div>
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-edu.svg" alt="Giáo viên" />
              </div>
              <div class="content">
                <div class="number">5.000</div>
                <div class="text">Giáo viên tin dùng</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-feedback hoc10-feedback--st bg-gray">
        <div class="container">
          <div class="header">
            <h2 class="title">
              Cảm nghĩ giáo viên<span class="block">về Hoc10</span>
            </h2>
          </div>
          <FeedbackSlider feelings={feelings} />
        </div>
      </div>
      <div class="hoc10-banner-register  pr">
        <div class="container pr">
          <div class="content">
            <h3>
              <span>Đăng ký ngay để sử dụng đầy đủ tính năng Học sinh</span>
            </h3>
            <a href="/dang-ky/" title="" class="flex-center btn-access txt-1">
              Đăng ký ngay
            </a>
          </div>
          <div class="img-decor img-decor--cs1">
            <img src="/assets/img/img-decor4.svg" alt="Decor" />
          </div>
        </div>
      </div>
    </main>
  );
}
