const NUMBER_OF_ACTIVITIES = [
  { label: "1", value: 1 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
];

const ENV_MODES = {
  Live: "Live",
  Dev: "Dev",
};

const FILTER_AREAS = [
  { label: "Tất cả", value: "All" },
  { label: "Sách", value: "Book" },
  { label: "Ngân hàng câu hỏi", value: "QuestionBank" },
];

const VIEW_MODES = [
  {
    label: "Sách",
    value: "book",
  },
  {
    label: "Bộ đề",
    value: "question-set",
  },
];

export { NUMBER_OF_ACTIVITIES, ENV_MODES, FILTER_AREAS, VIEW_MODES };
