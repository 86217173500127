import { toast } from "react-toastify";
import styles from "../BlogStyle.module.scss";
import {
  FacebookShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

export default function Share({
  setIsShowLinkShare,
  isShowLinkShare,
  detailItem,
}) {
  const currentURL = window.location.href;
  const copyLink = () => {
    navigator.clipboard.writeText(currentURL);
    toast.success("Sao chép link thành công");
  };
  const handleShowLinkShare = (e) => {
    setIsShowLinkShare(!isShowLinkShare);
    e.stopPropagation();
  };

  const titleShare = detailItem?.blog?.meta_title || "";

  return (
    <div className={`${styles.shareBlock} share-block`}>
      <p className={styles.headerText}>
        <div className="d-flex" onClick={handleShowLinkShare}>
          Chia sẻ bài viết{" "}
          <span>
            <i class="fa fa-caret-down" aria-hidden="true" />
          </span>
        </div>
      </p>
      {isShowLinkShare && (
        <div className={styles.shareContent}>
          <div className={styles.triangle} />
          <div className="share-social justify-content-between align-items-center">
            <span className="justify-content-between">
              Chia sẻ
              <div className="flex-center">
                <FacebookShareButton url={currentURL} title={titleShare}>
                  <FacebookIcon size={32} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={currentURL}
                  title={titleShare}
                  className="pl-2"
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
                <i class="zb-logo-zalo" />
                <WhatsappShareButton
                  url={currentURL}
                  title={titleShare}
                  className="pl-2"
                >
                  <WhatsappIcon size={32} />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={currentURL}
                  title={titleShare}
                  className="pl-2"
                >
                  <LinkedinIcon size={32} />
                </LinkedinShareButton>
              </div>
            </span>

            <hr />
          </div>
          <p>Sao chép đường dẫn</p>
          <div className={styles.copyText}>
            <input type="text" value={currentURL} />
            <button onClick={copyLink}>
              <i class="fa fa-clone" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
