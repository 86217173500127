import React from "react";
export default function DefaultComponent() {
  return (
    <div class="support__content">
      <div class="tab-content">
        <div id="s1" class="tab-pane fade show active">
          <h4>Vui lòng chọn nội dung bạn cần hướng dẫn.</h4>
        </div>
        <div id="s2" class="tab-pane fade">
          <h4>Hướng dẫn sử dụng chung</h4>
          <ul class="guide-list">
            <li class="flex-lc">
              <span>Tài khoản</span>
              <a href="" title="">
                Hướng dẫn kích hoạt sách từ menu
              </a>
            </li>
            <li class="flex-lc">
              <span>Tài khoản</span>
              <a href="" title="">
                Hướng dẫn sử dụng các nút chức năng trên màn hình bài học
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
