import { Link } from "react-router-dom";
import styled from "styled-components";
import Slider from "edu_lms_v2/components/Slider";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";
import { timeUpdate } from "../selection";
import renderHTML from "react-render-html";

export default function NewPostDetail({ dataNewsPost }) {
  return (
    <div className={styles.newPostContainer}>
      <p className={`${styles.title} pt-4 justify-content-center`}>
        Bài viết mới nhất
      </p>
      <SlickSlider className="m-0">
        <Slider
          className="team-list"
          centerMode={false}
          slidesToShow={3}
          slidesToScroll={3}
          centerPadding={0}
          autoplay={false}
          dots={false}
          arrows={true}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {dataNewsPost.map((dataPost, index) => {
            const isOtherDetail =
              dataPost?.grade?.name && dataPost?.subject?.name;
            const routerPostDetail = isOtherDetail
              ? `${PATH.ROUTE_BLOG_KNOWLEDGE}${dataPost?.level?.slug}/${dataPost?.grade?.slug}/${dataPost?.subject?.slug}/${dataPost?.alias}/`
              : `${PATH.ROUTE_BLOG_KNOWLEDGE_OTHER}${dataPost?.alias}/`;

            return (
              <div key={index} className="container-slider d-flex">
                <div className={`${styles.newPostDetail} item-detail`}>
                  <Link to={routerPostDetail} title="" className="post__thumb">
                    <div className={styles.img}>
                      <img src={dataPost?.images_cv} alt="images-new-post" />
                    </div>
                  </Link>
                  <div className={styles.inforMation}>
                    {dataPost?.grade?.name && (
                      <Link
                        to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${dataPost?.level?.slug}/${dataPost?.grade?.slug}/`}
                        className={styles.class}
                      >
                        {dataPost?.grade?.name || ""}
                      </Link>
                    )}
                    <span className={styles.subject}>
                      {dataPost?.subject?.name || ""}
                    </span>

                    <Link to={routerPostDetail} className="post__thumb">
                      <p className={styles.titleContent}>
                        {dataPost?.title || ""}
                      </p>
                      <p className={styles.description}>
                        {renderHTML(dataPost?.description_cv || "")}
                      </p>
                    </Link>
                    <div className={styles.timeCreate}>
                      <span className="d-flex">
                        <img
                          className={styles.newPostIcon}
                          src="/assets/img/icon-color.svg"
                          alt="icon-color"
                        />
                        {timeUpdate(dataPost?.publish_date)}
                        <span className="d-flex">
                          <img
                            className={styles.newPostIcon}
                            src="/assets/img/icon-viewers.svg"
                            alt="icon-viewers"
                          />
                          {dataPost?.viewcount || ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </SlickSlider>
    </div>
  );
}

const SlickSlider = styled.div`
  .item-detail {
    width: 90% !important;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  .slick-prev {
    left: 40%;
    top: 95%;
  }
  .slick-next {
    right: 40%;
    top: 95%;
  }
  .slick-prev:hover,
  .slick-next:hover {
    box-shadow: 0 0 40px #ff7707;
  }
  .slick-track {
    margin-left: inherit;
  }

  @media screen and (max-width: 500px) {
    .slick-prev {
      left: 35%;
      top: 95%;
    }
    .slick-next {
      right: 35%;
      top: 95%;
    }
  }

  @media (min-width: 1400px) {
    .slick-prev {
      left: -50px;
      top: 40%;
    }
    .slick-next {
      right: -50px;
      top: 40%;
    }
  }
`;
