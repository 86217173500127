import AxiosService from "edu_lms/services/axiosService";

const GET_ACTIVITIES_TEST_GAME = "get-activities-test-game";
const REACT_APP_MEDIA_URL_CMS = {
  Live: "https://cms.monkeyuni.net/",
  Dev: "https://api.dev.daybehoc.com/",
};

export const getActivitiesTestGame = (data) => {
  const { gameId, numberOfActs, name, isPublic, envMode = "Live" } = data;
  const apiURLPrefix = REACT_APP_MEDIA_URL_CMS[envMode];

  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${apiURLPrefix}api/${GET_ACTIVITIES_TEST_GAME}`,
    {
      game_id: gameId,
      number_of_acts: numberOfActs,
      name,
      is_public: isPublic ? 1 : 0,
    }
  );
};
