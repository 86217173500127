import React, { useState } from "react";
import styled from "styled-components";
import { GameButton } from ".";
import { TYPE_BUTTON_GAME } from "../constants";
import { COLOR } from "../constants/styles";
import "./styles.scss";

const getButtonType = (isCheckedAnswer, numberOfQuestions) => {
  if (!isCheckedAnswer) return TYPE_BUTTON_GAME.Check;
  if (numberOfQuestions > 1) return TYPE_BUTTON_GAME.Continue;
  return TYPE_BUTTON_GAME.Refresh;
};

const getBorderColor = (result) => {
  if (result === null) return COLOR.Default;
  if (result) return COLOR.Success;
  return COLOR.Error;
};

const getBackgroundColor = (result) => {
  if (result === null) return COLOR.White;
  if (result) return COLOR.Success;
  return COLOR.Error;
};

const FooterQuestion = ({
  resultAnswers,
  indexQuestion,
  disabledButton,
  onCheckAnswer,
  onNextQuestion,
  onResetAnswer,
}) => {
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);

  const typeButton = getButtonType(isCheckedAnswer, resultAnswers.length);

  const handleClickButton = () => {
    switch (typeButton) {
      case TYPE_BUTTON_GAME.Check:
        setIsCheckedAnswer(true);
        onCheckAnswer();
        break;
      case TYPE_BUTTON_GAME.Continue:
        setIsCheckedAnswer(false);
        onNextQuestion();
        break;
      case TYPE_BUTTON_GAME.Refresh:
        setIsCheckedAnswer(false);
        onResetAnswer();
        break;
      default:
        break;
    }
  };

  return (
    <FooterWrapper className="d-flex align-items-center justify-content-end">
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      <div className="d-flex align-items-center justify-content-center ml-3 mr-3">
        {resultAnswers.length > 1 &&
          resultAnswers.map((result, index) => {
            const isFocused = indexQuestion === index;
            return (
              <div
                key={index}
                style={{
                  width: `${isFocused ? "16px" : "12px"}`,
                  height: `${isFocused ? "16px" : "12px"}`,
                  margin: "0 5px",
                  borderRadius: "50%",
                  border: `2px solid ${getBorderColor(result)}`,
                  backgroundColor: getBackgroundColor(result),
                }}
              />
            );
          })}
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      <GameButton
        type={typeButton}
        disabled={disabledButton}
        className="z-index-game-button"
        onClick={handleClickButton}
      />
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
    </FooterWrapper>
  );
};

export default FooterQuestion;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px;
`;
