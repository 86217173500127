import React, { useEffect, useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { getDataUrlListProvince } from "edu_lms/services/info/info";
import { LIST_PROVINCE_ALL } from "edu_lms/constants/type";
import { InfomationFilter } from "./InfomationFilter";
import { onResultUserInfo } from "edu_lms/modules/selection";

export default function DashboardTraining() {
  useScrollToTop();
  const token = localStorage.getItem("token");
  const userInfo = onResultUserInfo();
  const [listProvince, setListProvince] = useState([]);

  useEffect(() => {
    getDataUrlListProvince(LIST_PROVINCE_ALL).then((res) => {
      if (res.data.status === "success") {
        setListProvince(res.data.data);
      }
    });
  }, []);

  return (
    <>
      <Wrapper className="text-center monkey-fz-24 monkey-color-black">
        DASHBOARD TẬP HUẤN
      </Wrapper>
      {token ? (
        <div className="container-fluid container-xl mb-5">
          <div className="introduce-header monkey-pb-20 monkey-mb-30 mt-4 pb-3">
            <InfomationFilter listProvince={listProvince} userInfo={userInfo} />
          </div>
        </div>
      ) : (
        <Wrapper className="text-center monkey-fz-24 mb-5 monkey-color-black">
          Bạn không có quyền!
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  margin-top: 100px;
`;
