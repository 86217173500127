import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import Select from "react-select";
import { onDispatchDataInfo } from "../../modules/SignIn/actions";
import * as TEXT from "../../constants/text";
import * as PATH from "../../../consts/routePaths";
import LabelInput from "../LabelInput";
import { MALE, FEMALE, APP_ID } from "../../constants/type";
import * as TYPE_JOB from "../../constants/type";

const FromUpdateInformation = (props) => {
  const [startDate, setStartDate] = useState("");
  const [fullName, setStartFullName] = useState("");
  const [valueGender, setStateGender] = useState("");
  const [valueJob, setStateJob] = useState();
  const [errorDOB, setStartErrorDOB] = useState(false);
  const [errorFullName, setStartErrorFullName] = useState(false);
  const [type, setType] = useState("");
  useEffect(() => {
    props.userInfo.full_name ? setType("update") : setType("add");
    setStartFullName(props.userInfo.full_name || "");
    setStateGender(props.userInfo.gender_id || 1);
    setStartDate(props.userInfo.birth_day || "");
    let dataJob;
    if (props.userInfo.job_id) {
      dataJob = TYPE_JOB.TYPE_LIST_JOB.filter(
        (item) => item.id === props.userInfo.job_id
      )[0];
      setStateJob(dataJob);
    } else {
      dataJob = TYPE_JOB.TYPE_LIST_JOB.filter(
        (item) => item.id === props.valueJob
      )[0];
    }
    setStateJob(dataJob);
  }, [props.userInfo, props.valueJob]);

  const handleChangeJob = (job) => {
    setStateJob(job);
  };

  const onConfirm = (data) => {
    if (!startDate) {
      setStartErrorDOB(true);
    }
    if (!fullName) {
      setStartErrorFullName(true);
    }
    if (startDate && fullName) {
      const userInfo = {
        ...props.userInfo,
        full_name: fullName,
        birth_day: startDate,
        gender_id: valueGender,
        job_id: valueJob.id,
        app_id: APP_ID,
        type: type,
        updateDetail: "personal_info",
      };
      props.onDispatchDataInfo(userInfo);
      if (
        localStorage.getItem("pathQuiz") !== PATH.ROUTE_PATH_TRAINING_TEST_2
      ) {
        props.onUpdateInfo(userInfo);
      }
      setStartErrorDOB(false);
      setStartErrorFullName(false);
      props.onSlickNext();
    }
  };
  return (
    <form className="from-info p-3">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value="Họ tên"
              status={true}
            />
            <input
              style={{ height: "44px" }}
              className="form-control rounded-pill"
              type="text"
              placeholder="Nguyễn Văn A"
              autoComplete="off"
              onChange={(e) => setStartFullName(e.target.value)}
              value={fullName}
            />
            <p className="monkey-color-red monkey-mt-15">
              {errorFullName && "Bạn chưa nhập họ tên"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value="Ngày sinh"
              status={true}
            />
            <DatePicker
              className="form-control rounded-pill"
              selected={startDate * 1000}
              value={startDate * 1000}
              dateFormat="dd/MM/yyyy"
              maxDate={addDays(new Date(), 0)}
              onChange={(date) => setStartDate(Date.parse(date) / 1000)}
              placeholderText="22/12/2000"
            />
            <p className="monkey-color-red monkey-mt-15">
              {errorDOB && "Bạn chưa nhập ngày sinh"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label className="monkey-color-violet">Giới tính</label>
            <div className="d-flex">
              <div className="form-check mr-3">
                <label className="form-check-label cursor">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    checked={valueGender === MALE}
                    value={MALE}
                    onChange={() => setStateGender(MALE)}
                  />
                  Nam
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label cursor">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="gender"
                    checked={valueGender === FEMALE}
                    value={FEMALE}
                    onChange={() => setStateGender(FEMALE)}
                  />
                  Nữ
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
            <LabelInput
              className="monkey-color-violet"
              value="Bạn là"
              status={false}
            />
            <Select
              value={valueJob}
              onChange={handleChangeJob}
              options={TYPE_JOB.TYPE_LIST_JOB}
              maxMenuHeight={180}
              styles={customStyles}
            />
          </div>
        </div>
        <div className="col-12">
          <input
            style={{ height: "44px" }}
            className="btn monkey-bg-violet monkey-color-white rounded-pill mr-2 hvr-registration-white"
            type="button"
            onClick={onConfirm}
            disabled={startDate && fullName && valueJob ? false : true}
            value={
              Number(localStorage.getItem("status")) === 1
                ? TEXT.TEXT_DONE
                : TEXT.TEXT_NEXT
            }
          />
        </div>
      </div>
    </form>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchDataInfo,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(FromUpdateInformation);

const customStyles = {
  control: (base) => ({
    ...base,
    height: 44,
    borderRadius: 30,
  }),
};
