import { useState, useEffect } from "react";
import * as types from "edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Select from "react-select";
import Pagination from "edu_lms_v2/components/Pagination";
import iconBack from "edu_lms_v2/assets/img/back1.svg";
import approveImg from "edu_lms_v2/assets/img/approve1.svg";
import {
  getListClassroomById,
  getListExerciseByTeacher,
  postAssignHomeWork,
} from "edu_lms/services/teachingManager";
import { getListSubject } from "edu_lms/services/app/app";

import styles from "../../teachingManager.module.scss";
import PopUpAssignHomeWork from "./PopUpAssignHomeWork";
import PopUpAssignSuccess from "./PopUpAssignSuccess";
import dayjs from "dayjs";

const tabs = [
  { title: "Bài luyện tập của tôi", href: "#practice", selected: true },
  { title: "Được chia sẻ", href: "#share", selected: false },
];

export default function TeacherExerciseList() {
  useScrollToTop();
  const [tabSelect, setTabSelect] = useState("#practice");
  const [listExercise, setListExercise] = useState([]);
  const [totalExercise, setTotalExercise] = useState(0);
  const [gradeSelect, setGradeSelect] = useState();
  const [subjectSelect, setSubjectSelect] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [subjectOption, setSubjectOption] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [exerciseId, setExerciseId] = useState();
  const [listClassroom, setListClassroom] = useState([]);
  const [showModalAssignSuccess, setShowModalAssignSuccess] = useState(false);
  const [classRoom, setClassRoom] = useState([]);
  const [question, setQuestion] = useState("");
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    getListExercise();
  }, [gradeSelect, subjectSelect, currentPage]);

  const getListExercise = () => {
    const dataPayload = {
      limit: types.SHOW_PAGINATE_TOPICS_QUESTION_WAREHOUSE,
      subjectId: subjectSelect?.id || "",
      gradeId: gradeSelect?.id || "",
      page: currentPage,
    };
    getListExerciseByTeacher(dataPayload)
      .then((res) => {
        if ((res.data.status = "success")) {
          setListExercise(res.data.data.list);
          setTotalExercise(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangeGrade = (grade) => {
    setGradeSelect(grade);
    setSubjectSelect("");
    getListSubject(grade.id).then((res) => {
      if (res.data.status === "success") {
        const list = [];
        res.data.data.list_subject.forEach((value, index) => {
          list.push({
            id: value.id,
            label: value.title,
            value: `file_${value.id}`,
            type: value.id,
          });
        });
        setSubjectOption(list);
      }
    });
  };
  const showGradeName = (gradeId) => {
    const gradeName = types.gradeOptions.find((item) => item.value === gradeId);
    return gradeName?.name;
  };
  const getListClassroom = () => {
    const data = {
      title: "",
      roleId: `${types.ROLE_CREATED_ROOM},${types.ROLE_TEACHER}`,
      langId: types.TYPE_VIETNAMESE,
      appId: types.APP_ID,
    };
    getListClassroomById(data)
      .then((res) => {
        if (res.data.status === "success") {
          const list = res.data.data.list;
          const nodes = list.map((item) => ({
            value: item.class_room_id,
            label: showGradeName(item.grade_id)
              ? `${showGradeName(item.grade_id)}${item.title}`
              : item.title,
          }));
          setListClassroom(nodes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    const dataForm = {
      deadline: dayjs(data.time_finish).unix(),
      question_set_id: exerciseId,
      class_room_ids: checked.toString(),
    };
    postAssignHomeWork(dataForm)
      .then((res) => {
        if (res.data.status === "success") {
          setClassRoom(res.data.data.class_room);
          setQuestion(res.data.data.question_set);
          setShowModalAssignSuccess(true);
        }
      })
      .finally(() => {
        setShowPopup(false);
        setChecked([]);
      });
  };
  useEffect(() => {
    getListClassroom();
  }, []);
  const handleClickAssign = (id) => {
    setExerciseId(id);
    setShowPopup(true);
    getListClassroom();
  };

  return (
    <>
      <main>
        <div className="hoc10-breadcrumb bg-gr pb-5 pt-5">
          <div className="container position-relative pt-3 pb-5">
            <div className="row">
              <div className={`position-absolute ${styles.backButton}`}>
                <button>
                  <img src={iconBack} />
                </button>
              </div>
              <div className="col-12 col-lg-12">
                <div className={`w-100 text-center ${styles.tabExercise}`}>
                  <ul
                    className="nav nav-pills d-flex justify-content-center"
                    id="pills-tab"
                    role="tablist"
                  >
                    {tabs.map((tab, index) => (
                      <li key={index} className="">
                        <a
                          href={tab.href}
                          title={tab.title}
                          data-toggle="pill"
                          role="tab"
                          aria-selected={tab.selected}
                          className={
                            tab.href === tabSelect ? styles.activeTab : ""
                          }
                          onClick={() => setTabSelect(tab.href)}
                        >
                          <span className="">{tab.title}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <button className={`mr-3 d-flex ${styles.btnAddExercise}`}>
                  <img src="/assets/img/Vectoraddfile.svg" alt="" />
                  <span>Tạo mới</span>
                </button>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-end">
                <div className={`mb-2 mr-3 ${styles.optionSelect}`}>
                  <div className="box-select">
                    <Select
                      value={gradeSelect}
                      placeholder="Chọn lớp"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 5 }),
                      }}
                      onChange={(e) => handleChangeGrade(e)}
                      options={types.OPTIONS_GRADE_IN_INTRODUCE}
                    />
                  </div>
                </div>
                <div className={`mb-2 mr-3 ${styles.optionSelect}`}>
                  <div className="box-select">
                    <Select
                      placeholder="Chọn môn"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 5 }),
                      }}
                      onChange={(e) => setSubjectSelect(e)}
                      options={subjectOption}
                      value={subjectSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-12 pt-5">
                {listExercise?.length > 0 ? (
                  listExercise?.map((exercise, index) => (
                    <div
                      className={`pt-3 mt-3 ${styles.excerciesItem}`}
                      key={index}
                    >
                      <p className="pl-3">
                        <span>{exercise.grade_name}</span>
                        <span>Bài tập</span>
                        <span>Chương 1</span>
                      </p>
                      <hr />
                      <div>
                        <div className="row pl-3 pb-3">
                          <div className="col-8 col-lg-8 d-flex">
                            <p
                              className={`pl-3 monkey-fz-20 font-weight-bold ${styles.mainContent}`}
                            >
                              {exercise.title}
                            </p>
                          </div>
                          <div className="col-4 col-lg-4 d-flex justify-content-end">
                            <button
                              className={`d-flex ${styles.btnAssignment}`}
                              onClick={() => handleClickAssign(exercise.id)}
                            >
                              <img src={approveImg} alt="approve" />
                              <span>Giao bài</span>
                            </button>
                            <button className={`mr-3 ${styles.btnShare}`}>
                              <i
                                className="fa fa-share-alt"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center h3">Không tìm thấy bài tập</div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-4">
            {totalExercise > types.SHOW_PAGINATE_TOPICS_QUESTION_WAREHOUSE && (
              <Pagination
                total={totalExercise}
                totalPerPage={types.SHOW_PAGINATE_TOPICS_QUESTION_WAREHOUSE}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </main>

      {showPopup && (
        <PopUpAssignHomeWork
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          exerciseId={exerciseId}
          listClassroom={listClassroom}
          onSubmit={onSubmit}
          checked={checked}
          setChecked={setChecked}
        />
      )}

      {showModalAssignSuccess && (
        <PopUpAssignSuccess
          question={question}
          classRoom={classRoom}
          showGradeName={showGradeName}
          open={showModalAssignSuccess}
          onClose={() => setShowModalAssignSuccess(false)}
        />
      )}
    </>
  );
}
