import React, { useState } from "react";
import { useStateMachine } from "little-state-machine";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { updateState, resetState } from "./store";
import TeachingInfoField from "./TeachingInfoField";
import * as PATH from "consts/routePaths";
import { grades } from "edu_lms/constants/type";
import RegisterSuccessModal from "./RegisterSuccessModal";
import Step3Validation from "./Step3Validation";
import ModalTerms from "./ModalTerms";
import ModalPrivacy from "./ModalPrivacy";

const defaultValue = { grade: {}, subjects: [] };

export default function Step3({ goToPrevStep, onSubmit, show, checkJob }) {
  const [showPopupTerm, setShowPopupTerm] = useState(false);
  const [showPopupPrivacy, setShowPopupPrivacy] = useState(false);

  const { state, actions } = useStateMachine({ updateState, resetState });
  const { control, handleSubmit, reset, setValue, getValues, errors } = useForm(
    {
      mode: "onChange",
      defaultValues: {
        teaching: [defaultValue],
      },
      resolver: yupResolver(Step3Validation),
    }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: "teaching",
  });

  const selectedGradeIds = fields.map(({ grade }) => grade?.value);

  return (
    <>
      <div className="container">
        <h1 className="title mb-64">Thông tin lớp học</h1>
        <Form
          className="form-h10 mw-535"
          onSubmit={handleSubmit((data) => onSubmit(data, actions, state))}
          autoComplete="off"
        >
          {fields.map((item, index) => (
            <TeachingInfoField
              key={item.id}
              name="teaching"
              index={index}
              control={control}
              canRemove={fields.length > 1}
              remove={remove}
              setValue={setValue}
              getValues={getValues}
              reset={reset}
              errors={errors}
              grades={grades.filter(
                ({ value }) =>
                  value === item.grade?.value ||
                  !selectedGradeIds.includes(value)
              )}
            />
          ))}
          {fields.length < 5 && (
            <ButtonStyle
              type="button"
              className="flex-center txt-4"
              onClick={() => append(defaultValue)}
            >
              <IStyle className="fa fa-plus-circle" />
              Chọn thêm lớp
            </ButtonStyle>
          )}

          <div className="box-btn flex mt-100">
            <button
              type="button"
              className="btn-back flex-center txt-4"
              onClick={goToPrevStep}
            >
              Quay lại
            </button>
            <button type="submit" className="btn-next btn-pr flex-center txt-4">
              Hoàn thành
            </button>
          </div>

          <div className="text mw-100">
            Bằng việc đăng ký, bạn đã đồng ý với các&nbsp;
            <ClickshowModal
              className="cursor"
              onClick={() => setShowPopupTerm(true)}
            >
              Điều khoản dịch vụ
            </ClickshowModal>
            &nbsp;và các&nbsp;
            <ClickshowModal
              className="cursor"
              onClick={() => setShowPopupPrivacy(true)}
            >
              Chính sách riêng tư
            </ClickshowModal>
            &nbsp; của chúng tôi.
          </div>
        </Form>
      </div>
      <RegisterSuccessModal show={show} checkJob={checkJob} />
      <ModalTerms show={showPopupTerm} onHide={() => setShowPopupTerm(false)} />
      <ModalPrivacy
        show={showPopupPrivacy}
        onHide={() => setShowPopupPrivacy(false)}
      />
    </>
  );
}

const Form = styled.form`
  & input {
    height: 36px;
  }
`;
const ButtonStyle = styled.button`
  color: #ff7707;
  margin-top: 5px;
`;
const IStyle = styled.i`
  margin-right: 5px;
`;
const ClickshowModal = styled.span`
  border-bottom: 1px solid #151515;
`;
