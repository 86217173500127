import styled from "styled-components";
import moi from "edu_lms_v2/assets/img/moi.svg";
import { TAG_COMING_SOON, TAG_NEW } from "edu_lms/constants/type";
import { COLOR_PRIMARY } from "consts/config";

export default function CheckboxItem({
  value,
  label,
  checked,
  onChange,
  tag,
  quantity = 0,
  quantityEnabled,
}) {
  return (
    <Checkbox className="flex-lc pr">
      <input
        id={`checkbox_${label}_${value}`}
        value={value}
        type="checkbox"
        name="class"
        onChange={() => {
          if (onChange) {
            onChange(value);
          }
        }}
        checked={checked}
      />
      <span className="checked"></span>
      <p>
        {label}
        {quantityEnabled && <Quantity> ({quantity || 0})</Quantity>}
      </p>
      {tag === TAG_NEW && (
        <span>
          <IconNew src={moi} alt="" />
        </span>
      )}
      {tag === TAG_COMING_SOON && (
        <span>
          <IconNew
            className="w-64"
            src="https://hoc10.monkeyuni.net/E_Learning/thumb/2._Bold.png"
            alt=""
          />
        </span>
      )}
    </Checkbox>
  );
}

const IconNew = styled.img`
  &.w-64 {
    width: 64px;
  }
  width: 30px;
  margin-left: 5px;
`;

const Checkbox = styled.label`
  cursor: pointer;
  &.disable {
    pointer-events: none;
    color: #eee;
  }
`;

const Quantity = styled.span`
  color: ${COLOR_PRIMARY};
`;
