import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";

export default function Heading() {
  return (
    <Wrapper class="hoc10-breadcrumb bg-gr ">
      <div className="container">
        <ul class="breadcrumb">
          <li className="breadcrumb-item">
            <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            Hỗ trợ
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Câu hỏi thường gặp
          </li>
        </ul>
      </div>
      <div className="hoc10-intro hoc10-intro--2 ">
        <div className="container">
          <div className="text-intro decor-h3 pr">
            <h2 className="title-list-book pt-3">
              Danh sách các đơn vị phát hành bộ sách
              <span className="monkey-color-yellow"> Cánh Diều</span>
            </h2>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .title-list-book {
    font: 36px/48px SVN-GilroyBold, sans-serif;
    color: #2a404f;
  }
`;
