import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import styled from "styled-components";
import { confirmPw } from "edu_lms/services/forgotPw/forgotPw";
import { APP_ID_MOBILE } from "edu_lms/constants/type";

const ChangePwSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Vui lòng nhập mật khẩu hiện tại")
    .min(8, "Mật khẩu phải từ 8 ký tự trở lên"),
  newPassword: yup
    .string()
    .required("Vui lòng nhập mật khẩu mới")
    .min(8, "Mật khẩu phải từ 8 ký tự trở lên"),
  reNewPassword: yup
    .string()
    .required("Vui lòng nhập xác nhận lại mật khẩu mới")
    .min(8, "Mật khẩu phải từ 8 ký tự trở lên")
    .oneOf([yup.ref("newPassword"), null], "Mật khẩu không trùng khớp"),
});

export default function ChangePassword() {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(ChangePwSchema) });

  const onChangePassword = (dataForm) => {
    const currentPassword = dataForm.currentPassword;
    const newPassword = dataForm.newPassword;

    const data = {
      old_password: currentPassword,
      new_password: newPassword,
      app_id: APP_ID_MOBILE,
      is_web: 1,
    };

    confirmPw(data).then((res) => {
      if (res.data.status === "success") {
        toast.success("Thay đổi mật khẩu thành công!");
      } else if (res.data.status === "fail") {
        toast.error(res.data.message || "Thay đổi mật khẩu không thành công!");
      }
      reset();
    });
  };

  return (
    <div id="password" className="tab-pane fade" role="tabpanel">
      <h2 className="title">Đổi mật khẩu</h2>
      <form onSubmit={handleSubmit(onChangePassword)} className="form-h10">
        <div className="form-group">
          <label>
            Mật khẩu hiện tại <sup>*</sup>
          </label>
          <div className="input-group mb-3">
            <input
              type={isShowPassword ? "text" : "password"}
              name="currentPassword"
              placeholder="Nhập mật khẩu"
              ref={register}
            />
            <IStyle
              onClick={() => setIsShowPassword(!isShowPassword)}
              className={`fa cursor ${
                isShowPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
              }`}
              aria-hidden="true"
            />
            <p className="text-danger">{errors.currentPassword?.message}</p>
          </div>
        </div>
        <div className="form-group">
          <label>
            Mật khẩu mới <sup>*</sup>
          </label>
          <div className="input-group mb-3">
            <input
              type={isShowPassword ? "text" : "password"}
              name="newPassword"
              placeholder="Nhập mật khẩu mới"
              ref={register}
            />
            <IStyle
              onClick={() => setIsShowPassword(!isShowPassword)}
              className={`fa cursor ${
                isShowPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
              }`}
              aria-hidden="true"
            />
            <p className="text-danger">{errors.newPassword?.message}</p>
          </div>
        </div>
        <div className="form-group">
          <label>
            Xác nhận mật khẩu mới <sup>*</sup>
          </label>
          <div className="input-group mb-3">
            <input
              type={isShowPassword ? "text" : "password"}
              name="reNewPassword"
              placeholder="Xác nhận mật khẩu mới"
              ref={register}
            />
            <IStyle
              onClick={() => setIsShowPassword(!isShowPassword)}
              className={`fa cursor ${
                isShowPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
              }`}
              aria-hidden="true"
            />
            <p className="text-danger">{errors.reNewPassword?.message}</p>
          </div>
        </div>
        <div className="box-btn">
          <button type="submit" className="btn-submit w-100 flex-center btn-pr">
            Cập nhật
          </button>
        </div>
      </form>
    </div>
  );
}

const IStyle = styled.i`
  position: absolute;
  z-index: 5;
  right: 30px;
  top: 16px;
  font-size: 15px;
`;
