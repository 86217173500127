import React, { useState } from "react";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";

export default function Question({ questions }) {
  const [url, setUrl] = useState("");
  return (
    <>
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />

      <div class="hoc10-question">
        <div class="container">
          <div class="header">
            <h2 class="title">Câu hỏi thường gặp</h2>
          </div>
          <div class="question-list flex-between pr">
            {questions?.map((_item, _index) => {
              return (
                <div class="item bg-sh">
                  <h4>{_item.title}</h4>
                  <button
                    type="button"
                    class="btn-video video flex-lc"
                    data-toggle="modal"
                    data-target="#modal-video"
                    onClick={() => setUrl(_item.url)}
                  >
                    Video hướng dẫn
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
