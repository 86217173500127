import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";
import { ICON_GRADE } from "../constant";

const CLASS_LEVEL_3 = 3;
export default function GradeChild({ dataCateByParent, slugKnowledge }) {
  return (
    <div className={styles.containerGradeChild}>
      <div className={styles.GradeChildTitle}>
        <p className={`${styles.title} justify-content-center`}>
          {slugKnowledge ? "Lớp học của con" : "Cấp học của con"}
        </p>
      </div>
      <div className="container">
        <div
          className={`row ${
            dataCateByParent.length === CLASS_LEVEL_3 &&
            "justify-content-center"
          }`}
        >
          {dataCateByParent.map((item, index) => {
            const src = ICON_GRADE[item.slug] || ICON_GRADE[slugKnowledge];
            const router = !slugKnowledge
              ? `${PATH.ROUTE_BLOG_KNOWLEDGE}${item?.slug}/`
              : `${PATH.ROUTE_BLOG_KNOWLEDGE}${slugKnowledge}/${item?.slug}/`;
            return (
              <div
                key={index}
                className={`col-lg-3 col-md-3 col-6 ${styles.containerSchoolLevel}`}
              >
                <div className={styles.schoolLevel}>
                  <Link to={router}>
                    <img src={src?.srcImg} alt={src?.altImg} />
                    <p>{item.name}</p>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
