import React from "react";
import styled from "styled-components";
import ContentEditable from "react-contenteditable";
import { INPUT_CHARS_REPLACE } from "../../constants/FIB";
import { htmlDecode } from "../../helpers";
import { getBorderColor } from "../../helpers/FIB";
import { Typography } from "../../components";

const ContentAnswer = ({
  answer,
  inputAreas = [],
  isView = false,
  hideResultAnswer = false,
  onChangeInput = () => {},
}) => {
  const { answers, fontSize, typeText, numberOfAnswersInRow } = answer;

  const handleChangeInput = (e, lineAnswerId, answerIndex) => {
    const { value } = e.target;
    onChangeInput({ lineAnswerId, answerIndex, value: htmlDecode(value) });
  };

  const getPropertiesInput = (lineAnswerId, answerIndex) => {
    const input = inputAreas.find(
      (inputArea) =>
        inputArea.lineAnswerId === lineAnswerId &&
        inputArea.answerIndex === answerIndex
    );
    if (!input) {
      return { isCorrect: null, value: "" };
    }
    return input;
  };

  return (
    <ContentAnswerWrapper columns={numberOfAnswersInRow} className="quicksand-medium monkey-color-black">
      {answers.map((answerLine, lineIndex) => {
        return (
          <div key={lineIndex} className="answer-items__wrapper">
            {answerLine.answerItems.map((answerItem, index) => {
              if (answerItem === INPUT_CHARS_REPLACE) {
                const focusInput = getPropertiesInput(answerLine.answerId, index);

                return (
                  <ContentEditable
                    key={index}
                    disabled={isView}
                    autoComplete="off"
                    style={{
                      fontSize: fontSize || "24px",
                      borderColor: getBorderColor(
                        hideResultAnswer ? null : focusInput.isCorrect
                      ),
                    }}
                    className="form-control content-editable"
                    html={focusInput.value}
                    placeholder="?"
                    onChange={(e) =>
                      handleChangeInput(e, answerLine.answerId, index)
                    }
                  />
                );
              }

              return (
                <Typography
                  key={index}
                  text={answerItem}
                  typeText={typeText}
                  fontSize={fontSize}
                />
              );
            })}
          </div>
        );
      })}
    </ContentAnswerWrapper>
  );
};

export default ContentAnswer;

const ContentAnswerWrapper = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);
  grid-gap: 1rem;
`;
