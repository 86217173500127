const TYPE_GAME = {
  MAT_001: 429,
  MAT_BG: 463,
  DAD_Image: 477,
  DAD_Text: 476,
  DAD_BG: 10004,
  DAD_BG_TV: 10005,
  WordFinding: 449,
  SentenceFinding: 450,
  FindCorrectMistakes: 475,
  SQC_001: 438,
  IMG_002: 446,
  REV_001: 448,
  WordSearch: 1483,
  Karaoke: 467,
  FillColor: 462,
  MathInput: 1482,
  FIB_Math: 471,
  FIB_001: 431,
  FIB_003: 458,
  FIB_BG: 461,
  MTC_003: 439,
  CKB_001: 436,
  MTC_BG: 464,
  Graph_001: 10006,
  Graph_002: 10007,
  SROI_001: 1000013,
};

const TYPE_BUTTON_GAME = {
  Check: "Check",
  Continue: "Continue",
  Refresh: "Refresh",
  Close: "Close",
};

const TYPE_DATA = {
  IMAGE: "image",
  AUDIO: "audio",
  VIDEO: "video",
  TEXT: "text",
  LATEX: "latex",
  INPUT: "input",
}

const ANSWER_REPORT_RANGE = {
  weak: 50,
  average: 80,
  pretty: 99,
  good: 100,
};

const CTA_POPUP_TYPE = {
  yesNo: 1,
  rangeOneGame: 2,
  rangeMultipleGame: 3,
  finalReport: 4,
};

const LANGUAGE = {
  VI: "vi",
};

const  GAME_VERSION = {
  V1: "v1",
};

export {
  TYPE_GAME,
  TYPE_BUTTON_GAME,
  TYPE_DATA,
  ANSWER_REPORT_RANGE,
  CTA_POPUP_TYPE,
  LANGUAGE,
  GAME_VERSION,
};
