import {
  getIconsListByLanguage,
  getContentAudioVideoImageTextFromIconActData,
} from ".";
import { TYPE_DATA } from "../constants";

const formatActivityDataFIB_003 = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    type_text: typeText,
    font_size: fontSize,
    font_size_answer: fontSizeAnswer,
  } = gameConfig;

  const dataGameSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.title;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );
    const question = {
      questionId,
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          fontSize,
          typeText: typeText || [TYPE_DATA.LATEX],
          contentText: questionContentIcon.contentText,
        },
      },
    };

    const listSentences = dataGameConfigItem.list_sentences.map(sentence => {
      const sentenceId = sentence.sentence;
      const sentenceContentIcon = getContentAudioVideoImageTextFromIconActData(
        iconsList,
        sentenceId
      );

      const blankAnswers = sentence.blank_answer.map((blankAnswer) =>
        blankAnswer.map((answer) => {
          const answerId = answer.answer;
          const answerContentIcon = getContentAudioVideoImageTextFromIconActData(iconsList, answerId);

          return {
            answerId,
            answerText: answerContentIcon.contentText,
            isCorrect: answer.is_correct,
          };
        })
      );

      return {
        sentenceId,
        blankAnswers,
        contentSentence: {
          srcAudio: sentenceContentIcon.srcAudio,
          srcVideo: sentenceContentIcon.srcVideo,
          srcImage: sentenceContentIcon.srcImage,
          text: {
            fontSize: fontSizeAnswer,
            typeText: typeText || [TYPE_DATA.LATEX],
            contentText: sentenceContentIcon.contentText,
          },
        }
      }
    })

    return {
      question,
      answer: {
        typeAnswer,
        listSentences,
        numberOfAnswersInRow: Number(dataGameConfigItem.answer_number_in_a_row),
      },
    };
  });

  return dataGameSetList;
};

export { formatActivityDataFIB_003 };
