import Modal from "react-bootstrap/Modal";
import { NavLink, useHistory } from "react-router-dom";
import * as PATH from "consts/routePaths";
import { JOB_TEACHER } from "edu_lms/constants/type";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import styled from "styled-components";

import * as TYPE from "../../../edu_lms/constants/type";
import { yupResolver } from "@hookform/resolvers/yup";
import { postUpdateUserSchool } from "edu_lms/services/info/info";

const schema = yup
  .object()
  .shape({
    job_id: yup.string().required(),
  })
  .required();
export default function PopupCheckJobGoogle(props) {
  const history = useHistory();

  const userInfo = onResultUserInfo();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const watchJob = watch("job_id");
  const onSubmit = (data) => {
    setItemUserInfo({ ...userInfo, job_id: data?.job_id });
    postUpdateUserSchool({
      job_id: data?.job_id,
      full_name: userInfo?.full_name,
      email: userInfo?.email,
    });
    props.onHide();
    history.push(PATH.ROUTE_PATH_TU_SACH);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="container">
        <h2 className="title">Bạn là ai?</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="box-choose flex-center mt-2">
            <div
              style={{ height: "140px" }}
              className={classNames("object bg-sh", {
                active: watchJob == TYPE.JOB_TEACHER,
              })}
            >
              <label>
                <span className="object__avt">
                  <img
                    src="/assets/img/avt-gv.svg"
                    alt="Giáo viên"
                    style={{ width: "96px" }}
                  />
                </span>
                <span className="object__name m-3">Giáo viên</span>
                <InputFirst
                  type="radio"
                  value={TYPE.JOB_TEACHER}
                  name="job_id"
                  ref={register}
                />
                <span className="checked"></span>
              </label>
            </div>
            <div
              style={{ height: "140px" }}
              className={classNames("object bg-sh ml-4", {
                active: watchJob == TYPE.JOB_STUDENT,
              })}
            >
              <label className="active">
                <span className="object__avt">
                  <img src="/assets/img/avt-student.svg" alt="Học sinh" />
                </span>
                <span className="object__name m-3">Học sinh</span>
                <InputSecond
                  type="radio"
                  value={TYPE.JOB_STUDENT}
                  name="job_id"
                  ref={register}
                />
                <span className="checked"></span>
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              style={{ height: "40px", width: "100px" }}
              type="submit"
              className={classNames("btn-next btn-pr", {
                active: isValid,
              })}
              disabled={!isValid}
            >
              Tiếp theo
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

const InputFirst = styled.input`
  position: absolute;
  bottom: 120px;
  left: 38%;
`;

const InputSecond = styled.input`
  position: absolute;
  bottom: 120px;
  right: 38%;
`;
