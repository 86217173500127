import * as types from "edu_lms/constants/actionsType";

export const onDispatchRedingNoti = (status) => {
  return {
    type: types.PA_READING_NOTIFICATION,
    payload: { status },
  };
};

export const onDispatchCountUnreadNoti = (count) => {
  return {
    type: types.PA_SET_COUNT_UNREAD_NOTI,
    payload: { count },
  };
};
