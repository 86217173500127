export const PA_LIST_BOOK_TYPE = "PA_LIST_BOOK_TYPE";
export const PA_LIST_GRADE = "PA_LIST_GRADE";
export const PA_SHOW_POPUP_ACTIVATE_BOOK = "PA_SHOW_POPUP_ACTIVATE_BOOK";
export const PA_SHOW_POPUP_SUCCESS = "PA_SHOW_POPUP_SUCCESS";
export const PA_DATA_CAROUSEL_LIST_BOOKS_IN_USE =
  "PA_DATA_CAROUSEL_LIST_BOOKS_IN_USE";
export const PA_DATA_CAROUSEL_BOOKS_IN_USE = "PA_DATA_CAROUSEL_BOOKS_IN_USE";
export const PA_DATA_CAROUSEL_LIST_TEXTBOOKS =
  "PA_DATA_CAROUSEL_LIST_TEXTBOOKS";
export const PA_DATA_CAROUSEL_LIST_BOOKS_TEACHER =
  "PA_DATA_CAROUSEL_LIST_BOOKS_TEACHER";
export const PA_DATA_CAROUSEL_LIST_BOOKS_TEST_3710 =
  "PA_DATA_CAROUSEL_LIST_BOOKS_TEST_3710";
export const PA_DATA_CAROUSEL_LIST_BOOKS_DOCUMENT =
  "PA_DATA_CAROUSEL_LIST_BOOKS_DOCUMENT";
export const PA_DATA_CAROUSEL_LIST_REFERENCE_BOOKS =
  "PA_DATA_CAROUSEL_LIST_REFERENCE_BOOKS";
export const PA_DATA_CAROUSEL_LIST_WORK_BOOK =
  "PA_DATA_CAROUSEL_LIST_WORK_BOOK";
export const PA_DATA_GRADE_ID_TEXT_BOOK = "PA_DATA_GRADE_ID_TEXT_BOOK";
export const PA_DATA_GRADE_ID_BOOK_TEACHER = "PA_DATA_GRADE_ID_BOOK_TEACHER";
export const PA_DATA_GRADE_ID_BOOK_TEST_3710 =
  "PA_DATA_GRADE_ID_BOOK_TEST_3710";
export const PA_DATA_SHOW_BOOK_TEST_3710 = "PA_DATA_SHOW_BOOK_TEST_3710";
export const PA_DATA_GRADE_ID_BOOK_DOCUMENT = "PA_DATA_GRADE_ID_BOOK_DOCUMENT";
export const PA_DATA_SUBJECT_ID_BOOK_DOCUMENT =
  "PA_DATA_SUBJECT_ID_BOOK_DOCUMENT";
export const PA_DATA_GRADE_ID_WORK_BOOK = "PA_DATA_GRADE_ID_WORK_BOOK";
export const PA_DATA_LIST_BOOKS_IN_USE = "PA_DATA_LIST_BOOKS_IN_USE";
export const PA_DATA_BOOKS_IN_USE = "PA_DATA_BOOKS_IN_USE";
export const PA_DATA_LIST_TEXTBOOKS = "PA_DATA_LIST_TEXTBOOKS";
export const PA_DATA_LIST_REFERENCE_BOOKS = "PA_DATA_LIST_REFERENCE_BOOKS";
export const PA_DATA_LIST_SUBJECT_ACTIVE = "PA_DATA_LIST_SUBJECT_ACTIVE";
export const PA_DATA_INFO = "PA_DATA_INFO";
export const PA_DATA_EMAIL = "PA_DATA_EMAIL";
export const PA_DATA_PHONE = "PA_DATA_PHONE";
export const PA_DATA_PROFILE = "PA_DATA_PROFILE";
export const PA_ADD_BOOK_ID = "PA_ADD_BOOK_ID";
export const PA_DATA_GRADE_ID_BOOK_IN_USE = "PA_DATA_GRADE_ID_BOOK_IN_USE";
export const PA_SHOW_POPUP_LOADING = "PA_SHOW_POPUP_LOADING";
export const PA_IS_BOOK_ENGLISH = "PA_IS_BOOK_ENGLISH";
export const PA_DATA_PROVINCE = "PA_DATA_PROVINCE";
export const PA_DATA_PISTRICT = "PA_DATA_PISTRICT";
export const PA_LIST_GRADE_ALL = "PA_LIST_GRADE_ALL";
export const PA_DATA_WARD = "PA_DATA_WARD";
export const PA_DATA_SCHOOL = "PA_DATA_SCHOOL";
export const PA_DATA_SCHOOL_BY_PROVINCE = "PA_DATA_SCHOOL_BY_PROVINCE";
export const PA_DATA_FORGOT_PASS_WORD = "PA_DATA_FORGOT_PASS_WORD";
export const PA_DATA_VERIFY_OTP = "PA_DATA_VERIFY_OTP";
export const PA_DATA_INTRODUCE_GRADE_ID = "PA_DATA_INTRODUCE_GRADE_ID";
export const PA_DATA_TUTORIAL_ID = "PA_DATA_TUTORIAL_ID";
export const PA_DATA_EDUCATION_ID = "PA_DATA_EDUCATION_ID";
export const PA_DATA_LIST_BOOK_TUTORIAL = "PA_DATA_LIST_BOOK_TUTORIAL";
export const PA_DATA_LIST_BOOK_INTRODUCE_ID = "PA_DATA_LIST_BOOK_INTRODUCE_ID";
export const PA_DATA_LIST_BOOK_EDUCATION = "PA_DATA_LIST_BOOK_EDUCATION";
export const PA_DATA_ACTIVE_SLIDER_UPDATE_INFO =
  "PA_DATA_ACTIVE_SLIDER_UPDATE_INFO";
export const PA_DATA_ACTIVE_UPDATE_SETTING_EMAIL =
  "PA_DATA_ACTIVE_UPDATE_SETTING_EMAIL";
export const PA_DATA_FILE_ID = "PA_DATA_FILE_ID";
export const PA_DATA_LIST_BOOK_COURSEWARE = "PA_DATA_LIST_BOOK_COURSEWARE";
export const PA_DATA_LIST_SLIDE = "PA_DATA_LIST_BOOK_SLIDE";
export const PA_DATA_LIST_QUESTION_SET = "PA_DATA_LIST_QUESTION_SET";
export const PA_DATA_LIST_QUESTION_ = "PA_DATA_LIST_QUESTION_";
export const PA_DATA_LIST_ANSWER_QUESTION = "PA_DATA_LIST_ANSWER_QUESTION";
export const PA_DATA_LIST_QUESTION_WARE_HOUSE =
  "PA_DATA_LIST_QUESTION_WARE_HOUSE";
export const PA_DATA_LIST_SUBJECT_COURSEWARE =
  "PA_DATA_LIST_SUBJECT_COURSEWARE";
export const PA_DATA_LIST_SUBJECT_SLIDE = "PA_DATA_LIST_SUBJECT_SLIDE";
export const PA_DATA_LIST_SUBJECT_QUESTION_SET =
  "PA_DATA_LIST_SUBJECT_QUESTION_SET";
export const PA_DATA_TITLE_GRADE_NAME = "PA_DATA_TITLE_GRADE_NAME";
export const PA_DATA_TITLE_SUBJECT_NAME = "PA_DATA_TITLE_SUBJECT_NAME";
export const PA_DATA_TITLE_QUESTION_SET = "PA_DATA_TITLE_QUESTION_SET";
export const PA_DATA_TITLE_ANSWER_QUESTION = "PA_DATA_TITLE_ANSWER_QUESTION";
export const PA_DATA_LIST_SUBJECT_QUESTION_WARE_HOUSE =
  "PA_DATA_LIST_SUBJECT_QUESTION_WARE_HOUSE";
export const PA_DATA_SHOW_POPUP_PDF = "PA_DATA_SHOW_POPUP_PDF";
export const PA_DATA_TYPE_POPUP = "PA_DATA_TYPE_POPUP";
export const PA_DATA_REACT_TOUR = "PA_DATA_REACT_TOUR";
export const PA_DATA_COURSEWARE_GRADE_ID = "PA_DATA_COURSEWARE_GRADE_ID";
export const PA_UPDATE_DATA_PARAMTER_INTRODUCE =
  "PA_UPDATE_DATA_PARAMTER_INTRODUCE";
export const PA_DATA_QUESTION_POPUP = "PA_DATA_QUESTION_POPUP";
export const PA_DATA_NUMBER_PAGE_BOOK = "PA_DATA_NUMBER_PAGE_BOOK";
export const PA_DATA_QUESTION = "PA_DATA_QUESTION";
export const PA_TYPE_GAME_QUESTION = "PA_TYPE_GAME_QUESTION";
export const PA_DATA_LIST_GAME = "PA_DATA_LIST_GAME";
export const PA_DATA_CONFIG_GAME = "PA_DATA_CONFIG_GAME";
export const PA_DATA_LIST_QUESTION = "PA_DATA_LIST_QUESTION";
export const PA_DATA_LIST_ANSWER = "PA_DATA_LIST_ANSWER";
export const PA_INDEX_QUESTION = "PA_INDEX_QUESTION";
export const PA_DATA_BOOK = "PA_DATA_BOOK";
export const PA_DATA_SLIDE = "PA_DATA_SLIDE";
export const PA_DATA_TITLE_SLIDE = "PA_DATA_TITLE_SLIDE";
export const PA_DATA_URL_SLIDE = "PA_DATA_URL_SLIDE";
export const PA_MENU_BOOK = "PA_MENU_BOOK";
export const PA_DATA_ALERT = "PA_DATA_ALERT";
export const PA_IS_BUTTON_NEXT_QUESTION = "PA_IS_BUTTON_NEXT_QUESTION";
export const PA_TYPE_PARAMTER_PAGE = "PA_TYPE_PARAMTER_PAGE";
export const PA_TYPE_ID_BOOK = "PA_TYPE_ID_BOOK";
export const PA_TYPE_LANGUAGE_BOOK = "PA_TYPE_LANGUAGE_BOOK";
export const PA_TYPE_CHANGE_TOTAL = "PA_TYPE_CHANGE_TOTAL";
export const PA_TYPE_CHANGE_IS_ACTIVE = "PA_TYPE_CHANGE_IS_ACTIVE";
export const PA_TYPE_CHANGE_IS_LICENCE = "PA_TYPE_CHANGE_IS_LICENCE";
export const PA_TYPE_PARAMTER_BOOK_ID = "PA_TYPE_PARAMTER_BOOK_ID";
export const PA_TITLE_QUESTION = "PA_TITLE_QUESTION";
export const PA_DATA_LIST_EDUCATION_TEACHER = "PA_DATA_LIST_EDUCATION_TEACHER";
export const PA_DATA_LIST_SUBJECT_TEACHER = "PA_DATA_LIST_SUBJECT_TEACHER";
export const PA_UPDATE_DATA_PARAMTER_EDUCATION_TEACHER =
  "PA_UPDATE_DATA_PARAMTER_EDUCATION_TEACHER";
export const PA_UPDATE_DATA_PARAMTER_COURSEWARE =
  "PA_UPDATE_DATA_PARAMTER_COURSEWARE";
export const PA_UPDATE_DATA_PARAMTER_SLIDE = "PA_UPDATE_DATA_PARAMTER_SLIDE";
export const PA_UPDATE_DATA_PARAMTER_QUESTION_SET =
  "PA_UPDATE_DATA_PARAMTER_QUESTION_SET";
export const PA_UPDATE_DATA_PARAMTER_QUESTION_WARE_HOUSE =
  "PA_UPDATE_DATA_PARAMTER_QUESTION_WARE_HOUSE";
export const PA_DATA_LIST_QUIZ = "PA_DATA_LIST_QUIZ";
export const PA_DATA_DATA_QUIZ = "PA_DATA_DATA_QUIZ";
export const PA_DATA_ACTIVE_TEACHING_INFORMATION =
  "PA_DATA_ACTIVE_TEACHING_INFORMATION";
export const PA_DATA_DATA_QUIZ_DETAIL = "PA_DATA_DATA_QUIZ_DETAIL";
export const PA_DATA_DATA_RESULT_QUESTION = "PA_DATA_DATA_RESULT_QUESTION";
export const PA_DATA_ACTIVE_LIST_SUBJECT_ALL =
  " PA_DATA_ACTIVE_LIST_SUBJECT_ALL";
export const PA_DATA_NUMBER_INDEX = "PA_DATA_NUMBER_INDEX";
export const PA_DATA_LIST_ACCOUNT_PROVINCE = "PA_DATA_LIST_ACCOUNT_PROVINCE";
export const PA_DATA_LIST_ACCOUNT_SCHOOL = "PA_DATA_LIST_ACCOUNT_SCHOOL";
export const PA_DATA_LIST_ACCOUNT_TEACHER = "PA_DATA_LIST_ACCOUNT_TEACHER";
export const PA_DATA_LIST_RESULT_QUIZ = "PA_DATA_LIST_RESULT_QUIZ";
export const PA_DATA_RESET_QUESTION = "PA_DATA_RESET_QUESTION";
export const PA_READING_BOOK_UPDATE_BOOKTOOL_PAGE =
  "PA_READING_BOOK_UPDATE_BOOKTOOL_PAGE";
export const PA_READING_BOOK_UPDATE_BOOKTOOL_GAME =
  "PA_READING_BOOK_UPDATE_BOOKTOOL_GAME";
export const PA_READING_BOOK_UPDATE_PAGE_ID = "PA_READING_BOOK_UPDATE_PAGE_ID";
export const PA_READING_BOOK_UPDATE_PAGE_DRAW =
  "PA_READING_BOOK_UPDATE_PAGE_DRAW";
export const PA_READING_BOOK_UPDATE = "PA_READING_BOOK_UPDATE";
export const SET_INITIAL_PAGE = "SET_INITIAL_PAGE";
export const SET_INITIAL_PAGE_EXERCISE = "SET_INITIAL_PAGE_EXERCISE";
export const PA_CURRENT_STEP = "PA_CURRENT_STEP";
export const PA_BOOK_INFO = "PA_BOOK_INFO";
export const PA_SEND_OTP_FORGOT_PASSWORD = "PA_SEND_OTP_FORGOT_PASSWORD";
export const PA_IS_CLICK_START = "PA_IS_CLICK_START";
export const PA_IS_CLICK_SUBMIT_ANSWER = "PA_IS_CLICK_SUBMIT_ANSWER";
export const PA_IS_CLICK_REFRESH = "PA_IS_CLICK_REFRESH";
export const PA_CLICK_DOWN_IMG = "PA_CLICK_DOWN_IMG";
export const PA_PLAY_BACKGROUND_MUSIC = "PA_PLAY_BACKGROUND_MUSIC";
export const PA_CHANGE_FULL_NAME = "PA_CHANGE_FULL_NAME";
export const PA_CHANGE_AVATAR = "PA_CHANGE_AVATAR";
export const PA_INCREASE_BOOK_TOOL = "PA_INCREASE_BOOK_TOOL";
export const PA_READING_NOTIFICATION = "PA_READING_NOTIFICATION";
export const PA_SET_COUNT_UNREAD_NOTI = "PA_SET_COUNT_UNREAD_NOTI";
export const PA_SET_CURRENT_PAGE_CLASSROOM = "PA_SET_CURRENT_PAGE_CLASSROOM";
export const PA_SET_SELECT_OPTION_SCHOOL_YEAR =
  "PA_SET_SELECT_OPTION_SCHOOL_YEAR";
export const PA_SET_SEARCHKEY_CLASSROOM = "PA_SET_SEARCHKEY_CLASSROOM";
export const PA_SET_SRC_AUDIO = "PA_SET_SRC_AUDIO";
export const PA_UPDATE_MOCK_TEST_FILTER = "PA_UPDATE_MOCK_TEST_FILTER";
export const PA_UPDATE_COURSE_FILTER = "PA_UPDATE_COURSE_FILTER";
export const PA_SET_IP_ADDRESS = "PA_SET_IP_ADDRESS";
export const PA_UPDATE_TRAINING_CONFIG = "PA_UPDATE_TRAINING_CONFIG";
export const PA_RESET_APP = "PA_RESET_APP";
export const PA_SET_USER_LOCATION = "PA_SET_USER_LOCATION";
export const PA_SET_CURRENT_MENU = "PA_SET_CURRENT_MENU";
export const PA_SET_MATRIX_BOOK_CONTENT = "PA_SET_MATRIX_BOOK_CONTENT";
export const PA_SET_STATE_MAX_QUESTION = "PA_SET_STATE_MAX_QUESTION";
export const PA_SET_STATE_DOING_INFO = "PA_SET_STATE_DOING_INFO";
export const PA_SET_STATE_CLICK_PRACTICE = "PA_SET_STATE_CLICK_PRACTICE";
export const PA_UPDATE_SLIDE_FILTER = "PA_UPDATE_SLIDE_FILTER";
export const PA_UPDATE_TITLE_SEARCH_SLIDE = "PA_UPDATE_TITLE_SEARCH_SLIDE";
export const PA_UPDATE_SUBJECT_WEEK_SEARCH_SLIDE = "PA_UPDATE_SUBJECT_WEEK_SEARCH_SLIDE";