const TYPE_GAME_MTC_BG = {
  ManyAnswers: "ckb",
  OneAnswer: "mtc",
};

const TYPE_CHECK = {
  Border: "border",
  Tick: "v",
};

export { TYPE_GAME_MTC_BG, TYPE_CHECK };
