import ModalRequireLogin from "edu_lms_v2/modules/ActiveBook/ModalRequireLogin";
import ForgotPassword from "edu_lms_v2/modules/ForgotPassword";
import LoginSuccessModal from "edu_lms_v2/modules/Login/LoginSuccessModal";
import LoginWrapper from "edu_lms_v2/modules/Login/LoginWrapper";
import { useHistory } from "react-router-dom";
import { useAuthStore } from "stores/authStore";

export default function AuthProvider({ children }) {
  const history = useHistory();

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);

  const loginModalVisibility = useAuthStore(
    (state) => state.loginModalVisibility
  );
  const setLoginModalVisibility = useAuthStore(
    (state) => state.setLoginModalVisibility
  );
  const openLoginModal = useAuthStore((state) => state.openLoginModal);
  const closeLoginModal = useAuthStore((state) => state.closeLoginModal);

  const loginSuccessModalVisibility = useAuthStore(
    (state) => state.loginSuccessModalVisibility
  );
  const openLoginSuccessModal = useAuthStore(
    (state) => state.openLoginSuccessModal
  );
  const closeLoginSuccessModal = useAuthStore(
    (state) => state.closeLoginSuccessModal
  );

  const forgotPasswordModalVisibility = useAuthStore(
    (state) => state.forgotPasswordModalVisibility
  );
  const setForgotPasswordModalVisibility = useAuthStore(
    (state) => state.setForgotPasswordModalVisibility
  );
  const openForgotPasswordModal = useAuthStore(
    (state) => state.openForgotPasswordModal
  );

  const requiredLoginModalVisibility = useAuthStore(
    (state) => state.requiredLoginModalVisibility
  );
  const closeRequiredLoginModal = useAuthStore(
    (state) => state.closeRequiredLoginModal
  );

  return (
    <>
      <LoginWrapper
        show={loginModalVisibility}
        onHide={closeLoginModal}
        onLoginSuccess={() => {
          setIsLoggedIn(true);
          closeLoginModal();
          openLoginSuccessModal();
        }}
        onActiveForgotPw={() => {
          closeLoginModal();
          openForgotPasswordModal();
        }}
      />
      <LoginSuccessModal
        show={loginSuccessModalVisibility}
        checkJob={{}}
        onHide={closeLoginSuccessModal}
        params={history.location.search || ""}
      />
      <ForgotPassword
        isActiveForgotPw={forgotPasswordModalVisibility}
        setActiveForgotPw={setForgotPasswordModalVisibility}
        setLoginModalShow={setLoginModalVisibility}
        isPopupLogin={true}
      />
      <ModalRequireLogin
        show={requiredLoginModalVisibility}
        onHide={closeRequiredLoginModal}
        onRequireLogin={() => {
          closeRequiredLoginModal();
          openLoginModal();
        }}
      />
      {/* <PopupCheckJobGoogle
        show={showCheckJobGoogle}
        onHide={() => setShowCheckJobGoogle(false)}
      /> */}
      {children}
    </>
  );
}
