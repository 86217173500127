import React from "react";
import ReactPlayer from "react-player";
import { URL_VIDEO } from "edu_lms/constants/type";

const Video = ({ isFullSrc, src, ...props }) => {
  if (!src) return null;

  return (
    <ReactPlayer
      width="60%"
      pip
      controls
      className="mx-auto"
      url={isFullSrc ? src : `${URL_VIDEO}${src}`}
      {...props}
    />
  );
};

export default Video;
