import {
  getIconsListByLanguage,
  getContentAudioVideoImageTextFromIconActData,
} from ".";
import { SPLIT_CHARS_ANSWER } from "../constants/FIB";
import { TYPE_DATA } from "../constants";

const formatActivityDataFIB_001 = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    type_text: typeText,
    font_size: fontSize,
  } = gameConfig;

  const dataGameSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.question;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );
    const question = {
      questionId,
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          fontSize,
          typeText: typeText || [TYPE_DATA.LATEX],
          contentText: questionContentIcon.contentText,
        },
      },
    };

    const answersList = dataGameConfigItem.answers.map((answerLine) => {
      const answerId = answerLine.answer_id;
      const answerContentIcon = getContentAudioVideoImageTextFromIconActData(
        iconsList,
        answerId
      );
      return {
        answerId,
        answerItems: answerLine.answer_text ? answerLine.answer_text.split(SPLIT_CHARS_ANSWER) : [],
        answerResultItems: answerLine.answer_result ? String(answerLine.answer_result).split(SPLIT_CHARS_ANSWER) : null,
        contentAnswer: {
          srcAudio: answerContentIcon.srcAudio,
          srcVideo: answerContentIcon.srcVideo,
          srcImage: answerContentIcon.srcImage,
        },
      };
    }); 

    return {
      question,
      answer: {
        typeAnswer,
        answers: answersList,
        fontSize,
        typeText: typeText || [TYPE_DATA.LATEX],
        numberOfAnswersInRow: Number(dataGameConfigItem.answer_number_in_a_row),
      },
    };
  });

  return dataGameSetList;
};

export { formatActivityDataFIB_001 };
