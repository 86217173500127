import styled from "styled-components";

const P = styled.p`
  margin-bottom: 20px;
  font-size: 24px;
  color: red;
  text-align: center;
`;

export { P };
