import * as TYPES from "../../constants/actionsType";
import {} from "../../constants/text";

let initialState = {
  // listQuestion: [],
  configGame: {},
  indexQuestion: 0,
  alert: {
    color: "",
    visible: false,
    text: "",
    srcAudio: "",
  },
  isButtonNextQuestion: false,
  titleQuestion: "",
  isClickStart: false,
};

let listQuestion = (state = initialState, action) => {
  switch (action.type) {
    // case TYPES.PA_DATA_LIST_QUESTION:
    //   return { ...state, listQuestion: action.data };
    case TYPES.PA_DATA_CONFIG_GAME:
      return { ...state, configGame: action.data };
    case TYPES.PA_INDEX_QUESTION:
      return { ...state, indexQuestion: action.data };
    case TYPES.PA_DATA_ALERT:
      return { ...state, alert: action.data };
    case TYPES.PA_IS_BUTTON_NEXT_QUESTION:
      return { ...state, isButtonNextQuestion: action.data };
    case TYPES.PA_TITLE_QUESTION:
      return { ...state, titleQuestion: action.title };
    case TYPES.PA_IS_CLICK_START:
      return { ...state, isClickStart: action.data };
    default:
      return state;
  }
};
export default listQuestion;
