import { useHistory } from "react-router-dom";
import styled from "styled-components";

const RollBack = styled.div`
  top: 50%;
  transform: translateY(-50%);
  i {
    background-color: rgba(42, 64, 79, 0.3);
  }
`;

export default function Header({ limitedTime }) {
  const history = useHistory();

  return (
    <div className="monkey-bg-gray1 mt-5 mt-lg-3 p-3 rounded position-relative">
      <RollBack className="position-absolute">
        <i
          className="fa fa-long-arrow-left monkey-color-back-reading-book monkey-fz-28 p-2 rounded cursor"
          aria-hidden="true"
          onClick={() => history.goBack()}
        />
      </RollBack>
      <div className="text-center">
        <h1 className="monkey-color-back-reading-book monkey-fz-28 font-weight-bold monkey-f-header">
          Bạn đã hoàn thành!!!
        </h1>
        {limitedTime && <p>Kiểm tra {limitedTime} phút</p>}
      </div>
    </div>
  );
}
