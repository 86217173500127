import React, { useState, useEffect, Fragment } from "react";
import BoxMatch from "./BoxMatch";
import { TYPE_DISPLAY, BOX_TYPE } from "../../constants/MAT_001";
import { getClassNameItemsMatchWrapper } from "../../helpers/MAT_001";
import { P } from "./styles";

const AnswerContent = ({
  answersMatch,
  typeDisplay,
  typeGame,
  typeText,
  fontSizeContent,
  columnTitleRight,
  matchedCouplePoints = [],
  isCheckedAnswer = false,
  hideResultAnswer = false,
  isReadOnly = false,
  onSelectAnswer = () => {},
}) => {
  const [activeAnswer, setActiveAnswer] = useState(null);

  const handleClickBox = (e, answerId) => {
    setActiveAnswer(answerId);
    onSelectAnswer(e, answerId);
  };

  useEffect(() => {
    if (isCheckedAnswer) {
      setActiveAnswer(null);
    }
  }, [isCheckedAnswer]);

  const correctMatchedCouplePoints = matchedCouplePoints.filter(couple => couple.isCorrect);

  return (
    <div className={`d-flex justify-content-center answer-wrapper quicksand-medium ${getClassNameItemsMatchWrapper(typeDisplay)}`}>
      {answersMatch.map((answer, index) => {
        const isCorrect = isCheckedAnswer ? correctMatchedCouplePoints.some(couple => couple.answerId === answer.id) : null;
        return (
          <Fragment key={index}>
            {typeDisplay === TYPE_DISPLAY.Vertical && index === 0 && (
              <P>{columnTitleRight}</P>
            )}
            <BoxMatch
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              boxType={BOX_TYPE.Answer}
              boxContent={answer}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              isActive={activeAnswer === answer.id}
              isCorrect={hideResultAnswer ? null : isCorrect}
              disabled={isReadOnly}
              onClickBox={(e) => handleClickBox(e, answer.id)}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default AnswerContent;
