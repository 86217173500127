import React from "react";
import styled from "styled-components";
import { UseSound, Typography } from "../../components";
import { URL_AUDIO } from "edu_lms/constants/type";

const HeaderQuestion = ({ titleQuestion }) => {
  return (
    <HeaderQuestionWrapper>
      {titleQuestion.srcAudio && (
        <UseSound src={`${URL_AUDIO}${titleQuestion.srcAudio}`} />
      )}
      {titleQuestion.text.contentText && (
        <Typography
          text={titleQuestion.text.contentText}
          typeText={titleQuestion.text.typeText}
          fontSize={titleQuestion.text.fontSize}
          className="quicksand-medium"
        />
      )}
    </HeaderQuestionWrapper>
  );
};

export default HeaderQuestion;

const HeaderQuestionWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  margin-right: 1rem;
  margin-left: 0.6rem;
`;
