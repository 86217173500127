import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FIBHeaderQuestion } from "../../components";
import ContentAnswer from "./ContentAnswer";
import { getNumberOfSelections } from "../../helpers/FIB";
import { INPUT_CHARS_REPLACE, SPLIT_CHARS_ANSWER } from "../../constants/FIB";
import "./styles.scss";

const FIB_003 = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAreasProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    theme,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;

  const [selectionAreas, setSelectionAreas] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  /**---------- Show selected answers result ----------**/
  useEffect(() => {
    if (selectedAreasProp.length > 0) {
      setSelectionAreas(selectedAreasProp);
      setIsCheckedAnswer(true);
    }
  }, [selectedAreasProp]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      const correctSelectionAnswers = [];
      answer.listSentences.forEach(sentence => {
        const sentenceItems = sentence.contentSentence.text.contentText.split(SPLIT_CHARS_ANSWER);
        let selectionIndex = 0;

        sentenceItems.forEach(sentenceItem => {
          if (sentenceItem === INPUT_CHARS_REPLACE) {
            const correctSelectionAnswer = sentence.blankAnswers[selectionIndex].find(selectArea => selectArea.isCorrect);
            correctSelectionAnswers.push({
              ...correctSelectionAnswer,
              label: correctSelectionAnswer.answerText,
              selectIndex: selectionIndex,
              sentenceId: sentence.sentenceId,
            });
            selectionIndex ++;
          }
        })
      });

      setSelectionAreas(correctSelectionAnswers);
      setIsCheckedAnswer(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const totalCorrectAnswer = selectionAreas.filter(selectionArea => selectionArea.isCorrect).length;
    const isCorrect = totalCorrectAnswer === getNumberOfSelections(answer.listSentences);
    onComplete({ isCorrect, selectedAreas: selectionAreas, totalCorrectAnswer });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setSelectionAreas([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleSelectAnswer = (answerSelection) => {
    if (isCheckedAnswer) {
      setIsCheckedAnswer(false);
    }

    const shadowSelectionAreas = [...selectionAreas];
    const arrIndexSelectionFocused = selectionAreas.findIndex(
      (selectionArea) =>
        selectionArea.sentenceId === answerSelection.sentenceId &&
        selectionArea.selectIndex === answerSelection.selectIndex
    );

    if (arrIndexSelectionFocused > -1) {
      shadowSelectionAreas.splice(arrIndexSelectionFocused, 1);
    }

    shadowSelectionAreas.push(answerSelection);
    setSelectionAreas(shadowSelectionAreas);

    const isSelectedAllAreas = shadowSelectionAreas.length === getNumberOfSelections(answer.listSentences);
    onPlaying(isSelectedAllAreas);
  };

  return (
    <div
      className={`FIB_003__wrapper ${
        isReadOnly || isViewOnly ? "is-view" : ""
      }`}
    >
      <FIBHeaderQuestion question={question} theme={theme} />
      <ContentAnswer
        answer={answer}
        selectionAreas={selectionAreas}
        isCheckedAnswer={isCheckedAnswer}
        hideResultAnswer={hideResultAnswer}
        onSelectAnswer={handleSelectAnswer}
      />
    </div>
  );
};

export default forwardRef(FIB_003);
