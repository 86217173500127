import React, { useEffect} from "react";
import styled from "styled-components";
import { Link} from "react-router-dom";
import * as PATH from "consts/routePaths";
import { useAppStore } from "stores/appStore";
import {useGiaoanStore} from "../../stores/giaoanStore";
import SlideGiaoAn from "./SlideGiaoAn";

import classNames from "classnames";
export default function LessonPlan({categoryBook, background }) {
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const currentSubject = subjects.find((subject) => subject.id === subjectId);
  const currentGrade = grades.find((grade) => grade.id === gradeId);
  const listGiaoAn = useGiaoanStore((state) => state.listGiaoAn);
  const setLimit = useGiaoanStore((state) => state.setLimit);

  useEffect(() => {
    setLimit(3);
  }, []);

  return (
      (listGiaoAn.length > 0 && currentSubject !== undefined && <div
      className=""
      style={{
        backgroundColor: `${background}`,
        boxShadow: "5px 5px 10px 1px #ccc",
        padding: "10px 20px",
        marginBottom: "20px",
        borderRadius: "8px",
      }}
    >
      <div className="row">
        <div className="col-md-6 d-flex result__list">
          <BookMark className="fa fa-bookmark" aria-hidden="true"></BookMark>
          <h4>{categoryBook}</h4>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 col-sm-4">
              {/* <Select
                value={typeDocument}
                options={listTypeDocument}
                onChange={(option) => changeTypeDocument(option)}
                placeholder="Tất cả"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
              /> */}
              <Link
                to={`${PATH.ROUTE_PATH_V3_LIST_SLIDE}${currentSubject.slug}/${currentGrade.slug}`}
                className="activated flex-center btn-activate-book"
              >
                Xem thêm
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Hr />

      <div className="hoc10-lesson-library">
        <div className="container">
          <div className="row">
                <>
                  <div className="col-lg-12 col-md-12">
                    <div
                        className={classNames("row", {
                          "justify-content-center": listGiaoAn.length == 0,
                        })}
                    >
                      {listGiaoAn.length > 0 ? (
                          listGiaoAn.map((slide, index) => (
                              <SlideGiaoAn key={index} slide={slide} />
                          ))
                      ) : (
                          <div className="monkey-fz-24">Sắp ra mắt</div>
                      )}
                    </div>
                  </div>
                </>
          </div>
        </div>
      </div>

      {/*<div className="col-md-12">*/}
      {/*  <>*/}
      {/*    /!*<div className="col-lg-9 col-md-8">*!/*/}
      {/*    <div*/}
      {/*        className={classNames("row", {*/}
      {/*          "justify-content-center": listGiaoAn.length == 0,*/}
      {/*        })}*/}
      {/*    >*/}
      {/*      {listGiaoAn.length > 0 ? (*/}
      {/*          listGiaoAn.map((slide, index) => (*/}
      {/*              <SlideGiaoAn key={index} slide={slide} />*/}
      {/*          ))*/}
      {/*      ) : (*/}
      {/*          <div className="monkey-fz-24">Sắp ra mắt</div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*    /!*</div>*!/*/}
      {/*  </>*/}
      {/*</div>*/}
    </div>
	  )
  );
}

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
  margin-top: 4px;
`;
