import { useState } from "react";
import Classroom from "./Classroom";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
const tabs = [
  {
    title: "Lớp học của tôi",
    icon: "fa fa-users",
    href: "#user",
    selected: true,
  },
  {
    title: "Thư viện",
    icon: "fa fa-bookmark",
    href: "#user",
    selected: false,
  },
  {
    title: "Báo cáo",
    icon: "fa fa-bar-chart",
    href: "#user",
    selected: false,
  },
  {
    title: "Thời khóa biểu",
    icon: "fa fa-table",
    href: "#user",
    selected: false,
  },
  {
    title: "Inbox",
    icon: "fa fa-comments",
    href: "#user",
    selected: false,
  },
];

export default function StudentListClass() {
  useScrollToTop();
  const [nameTab, setNameTab] = useState(tabs[0].title);

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Học sinh</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {nameTab}
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-gr">
        <div className="container">
          <Classroom />
          {/* <div className="row">
            <div className="col-lg-3 col-3">
              <ul className="tab nav hoc10-tab">
                {tabs.map((tab, index) => (
                  <li key={index}>
                    <a
                      href={tab.href}
                      title={tab.title}
                      data-toggle="pill"
                      role="tab"
                      aria-selected={tab.selected}
                      className={tab.selected ? "active" : ""}
                      onClick={() => setNameTab(tab.title)}
                    >
                      <i className={`mx-auto ${tab.icon}`}></i>&ensp;
                      <span>{tab.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-9 col-9">
              <Classroom />
            </div>
          </div>*/}
        </div>
      </div>
    </main>
  );
}
