import Select from "react-select";
import { useEffect, useState } from "react";
import { getLevelSchool } from "edu_lms_v2/services/listBook";

export default function SchoolLevel({ setValueSchool, valueSchool }) {
  // const [optionSchool, setOptionSchool] = useState([]);

  const optionSchool = [
      {value: 1, label: "Tiểu học"},
      {value: 2, label: "Trung học cơ sở"},
      {value: 3, label: "Trung học phổ thông"}
  ];
  // useEffect(() => {
  //   getLevelSchool()
  //     .then((res) => {
  //       if (res.data.status === "success") {
  //         const newData = res.data.data.map((item) => ({
  //           value: item.id,
  //           label: item.name,
  //         }));
  //
  //         setOptionSchool(newData);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }, []);

  return (
    <div className="filter__item">
      <h4>Cấp học</h4>
      <div className="filter__item__list filter__item__list--select">
        <Select
          value={valueSchool}
          options={optionSchool}
          onChange={(option) => setValueSchool(option)}
          styles={{ control: (base) => ({ ...base, marginTop: "15px" }) }}
        />
      </div>
    </div>
  );
}
