import { useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IdleTimer from "react-idle-timer";
import { onDispatchDataInfo } from "../../modules/SignIn/actions";
import {
  onDispatchTogglePopupSuccess,
  onDispatchShowPopupActivateBook,
} from "../../modules/App/actions";
import * as PATH from "../../../consts/routePaths";
import * as TEXT from "../../constants/text";
import { cleanLocalStorage } from "../../constants/general";
import { TIME_OUT_LOGOUT } from "../../constants/type";
import HeaderComponent from "../../components/Header";
import FooterComponent from "../../components/Footer";
import ButtonGoTop from "../ButtonGoTop";

const ComponentWrapper = (props) => {
  const idleTimerRef = useRef();

  const onIdle = () => {
    const isToken = localStorage.getItem("token");
    if (isToken) {
      cleanLocalStorage();
      props.onDispatchShowPopupActivateBook(false);
      props.onDispatchDataInfo({
        job_id: "",
        full_name: "",
        birth_day: "",
        gender_id: "",
        email: "",
        phone: "",
        province_id: "",
        district_id: "",
        ward_id: "",
        school_id: "",
        list_grade_subject: [],
        show_change_pw: false,
        notification: 1,
        role_id: 0,
      });
      props.onDispatchTogglePopupSuccess({
        status: true,
        title: [TEXT.TITLE_POPUP_NOT_SEEN_ANY_ACTIVITY],
        url: PATH.ROUTE_PATH_SIGN_IN,
        labelButton: TEXT.BUTTON_PLEASE_RE_LOGIN,
      });
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={TIME_OUT_LOGOUT}
      />
      {props.showMenu && <HeaderComponent />}
      {props.children}
      {props.showMenu && (
        <>
          <FooterComponent />
          <ButtonGoTop />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchDataInfo,
      onDispatchTogglePopupSuccess,
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ComponentWrapper);
