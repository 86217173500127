import Modal from "react-bootstrap/Modal";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export default function DocsViewComponent({show, setShow, infoWorksheet}) {
    const docs = [{uri: infoWorksheet.uri}];
  return (
    <Modal
      show={show}
      size="xl"
      centered
      animation="true"
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <p>{infoWorksheet.title}</p>
      </Modal.Header>

      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
        className="remove-scroll-docs-view rounded"
        style={{ height: "80vh" }}
      />
    </Modal>
  );
}
