import classNames from "classnames";
import { MODE_FILTER_DISABLED, MODE_FILTER_HIDE, TAG_COMING_SOON, TAG_NEW } from "edu_lms/constants/type";
import styled from "styled-components";
import moi from "edu_lms_v2/assets/img/moi.svg";

const ListCheckbox = styled.label`
  &.disable {
    pointer-events: none;
    color: #eee;
  }
`;

export default function ComboBox({
  data,
  onChange,
  checkedItems,
  disabledItems = [],
  modeDisabled = MODE_FILTER_DISABLED,
}) {
  return (
    <div className="filter__item__list">
      {modeDisabled === MODE_FILTER_DISABLED &&
        data.map((item, index) => {
          return (
            <ListCheckbox
              key={index}
              className={classNames("flex-lc pr", {
                disable: disabledItems.includes(item.id),
              })}
            >
              <input
                id={item.id}
                type="radio"
                onChange={() => onChange(item.id)}
                checked={checkedItems.includes(item.id)}
              />
              <span className="checked"></span>
              <p>{item.name ?? item.title}
              {item.type_tag === TAG_NEW && (
                <span>
                  <IconNew src={moi} alt="" />
                </span>
              )}
              {item.type_tag === TAG_COMING_SOON && (
                <span>
                  <IconNew src="https://hoc10.monkeyuni.net/E_Learning/thumb/2._Bold.png" alt="" />
                </span>
              )}
              </p>
            </ListCheckbox>
          );
        })}

      {modeDisabled === MODE_FILTER_HIDE &&
        data
          .filter((item) => !disabledItems.includes(item.id))
          .map((item, index) => {
            return (
              <ListCheckbox key={index} className="flex-lc pr">
                <input
                  id={item.id}
                  type="radio"
                  onChange={() => onChange(item.id)}
                  checked={checkedItems.includes(item.id)}
                />
                <span className="checked"></span>
                <p>{item.name ?? item.title}
                {item.type_tag === TAG_NEW && (
                <span>
                  <IconNew src={moi} alt="" />
                </span>
                )}
                {item.type_tag === TAG_COMING_SOON && (
                  <span>
                    <IconNew className="w-64" src="https://hoc10.monkeyuni.net/E_Learning/thumb/2._Bold.png" alt="" />
                  </span>
                )}
                </p>
              </ListCheckbox>
            );
          })}
    </div>
  );
}

const IconNew = styled.img`
  &.w-64 {
    width: 64px;
  }
  display: inline-block;
  width: 30px;
  margin-left: 5px;
`;
