import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as PATH from "consts/routePaths";

const REDIRECT_LINK = {
  [PATH.ROUTE_REDIRECT_LINK_GRADE_4]:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTRI0Qpnv0GejMzcPjtPi_SvCCQNhSbZh-pTqtDFusNnaXPnl6VjOv0Y9BDp0Rq5BhcbCqlbDzSwniB/pubhtml",
  [PATH.ROUTE_REDIRECT_LINK_GRADE_8]:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vRzZ0x6yyOgnsc8PB4NUREzDTEME43s30iGGyfLMDqyn5mqx7YLy9zyKNk9YwjofofUAmjvHwIRryqY/pubhtml",
  [PATH.ROUTE_REDIRECT_LINK_GRADE_11]:
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSsb9fORA8PiRE17sEmZxnEDxzrB64kYCIaqGHC4M21MygJUMj6gG54vrwiXYKTqfb-KlR0Xx0-RGHK/pubhtml",
  [PATH.ROUTE_REDIRECT_LINK_GRADE_5]:
    "https://docs.google.com/spreadsheets/d/1R1mFn6ecoilH2VJHWAY8WgQqMaSKCtKj8MaZuh_I_L8/edit#gid=0",
  [PATH.ROUTE_REDIRECT_LINK_GRADE_9]:
    "https://docs.google.com/spreadsheets/d/1dFTrtmmosAuPO6mhbDdTdrnbdHEYvlYz1epv3uoqheA/edit#gid=0",
  [PATH.ROUTE_REDIRECT_LINK_GRADE_12]:
    "https://docs.google.com/spreadsheets/d/1Iq7YILxOPEDP9j0xEhBNB8jy632QxGBdIeoUST0ugZU/edit#gid=0",
};

const RedirectLink = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if(REDIRECT_LINK[pathname]) {
      window.location.replace(REDIRECT_LINK[pathname]);
      return;
    }
    if (pathname.includes(`${PATH.ROUTE_PATH_V3_LIST_SLIDE_OLD}`)) {
      let urlRedirect = pathname.replace(`${PATH.ROUTE_PATH_V3_LIST_SLIDE_OLD}`, `${PATH.ROUTE_PATH_V3_LIST_SLIDE}`);
      window.location.replace(urlRedirect);
      return;
    }
    if (pathname.includes(`${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST_OLD}`)) {
      let urlRedirect = pathname.replace(`${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST_OLD}`, `${PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST}`);
      window.location.replace(urlRedirect);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

export default RedirectLink;
