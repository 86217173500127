import React from "react";

export default function UpdateInfoComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn cập nhật thông tin tài khoản</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Kính chào quí thầy cô, sau đây Hoc10 xin hướng thầy cô cách cập nhật
          thông tin tài khoản trên website Hoc10
        </li>
        <li class="flex-lc">
          <b>Bước 1:</b>&nbsp; Đăng nhập hoặc đăng ký tài khoản qua đường
          link&nbsp;
          <a href="https://hoc10.vn/" target="_blank" style={{ flex: "none" }}>
            https://hoc10.vn/
          </a>
        </li>
        <li class="flex-lc">
          <b>Bước 2:</b>&nbsp; Di con trỏ chuột đến phần họ tên và bấm chọn mục
          “Cập nhật thông tin”
          <div className="mt-3">
            <img
              src={`${urlImg}/update_step2.png`}
              alt="Bước 2: Di con trỏ chuột đến phần họ tên và bấm chọn mục “Cập nhật thông tin”"
            />
          </div>
        </li>
        <li class="flex-lc">
          <b>Bước 3:</b>&nbsp; Tại đây, thầy cô có thể cập nhật các thông tin
          hiển thị trên màn hình như:
          <p className="mt-3">Thông tin cá nhân</p>
          <div className="mt-3">
            <img src={`${urlImg}/update_person.png`} alt="Thông tin cá nhân" />
          </div>
          <p className="mt-3">Trường học</p>
          <div className="mt-3">
            <img
              src={`${urlImg}/update_school.png`}
              alt="Thông tin trường học"
            />
          </div>
          <p className="mt-3">Lớp học</p>
          <div className="mt-3">
            <img src={`${urlImg}/update_class.png`} alt="Thông tin lớp học" />
          </div>
          <p className="mt-3">Ảnh đại diện</p>
          <div className="mt-3">
            <img src={`${urlImg}/update_avt.png`} alt="Ảnh đại diện" />
          </div>
          <p className="mt-3">Đổi mật khẩu</p>
          <div className="mt-3">
            <img
              src={`${urlImg}/update_change_password.png`}
              alt="Đổi mật khẩu"
            />
          </div>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 4:</b>&nbsp; Bấm “Gửi”, khi màn hình hiện “Cập nhật thông
            tin thành công“ là thầy cô đã hoàn tất quá trình này.
          </p>
        </li>
        <li class="flex-lc">
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li class="flex-lc">Chúc thầy cô đăng nhập và sử dụng thành công!</li>
      </ul>
    </>
  );
}
