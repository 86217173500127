import { INPUT_CHARS_REPLACE } from "../constants/FIB";
import { COLOR } from "../constants/styles";

const getNumberOfInputs = (answers = []) => {
  let numberOfInputs = 0;
  answers.forEach((answerLine) => {
    numberOfInputs += answerLine.answerItems.filter(
      (answerItem) => answerItem === INPUT_CHARS_REPLACE
    ).length;
  });
  return numberOfInputs;
};

const getNumberOfSelections = (sentences = []) => {
  let numberOfSelections = 0;
  sentences.forEach((sentence) => {
    numberOfSelections += sentence.blankAnswers.length;
  });

  return numberOfSelections;
};

const getBorderColor = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return COLOR.Success;
    case false:
      return COLOR.Error;
    default:
      return COLOR.Default;
  }
};

export { getNumberOfInputs, getNumberOfSelections, getBorderColor };
