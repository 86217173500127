import GiaoAn from "modules/GiaoAn";
import Layout from "components/Layout/Default";

export default function PageGiaoAn() {
  return (
    <Layout>
      <GiaoAn />
    </Layout>
  );
}
