import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import {
  onDispatchTogglePopupSuccess,
  onDispatchShowLoading,
  onDispatchShowPopupActivateBook,
} from "../../modules/App/actions";
import * as PATH from "../../../consts/routePaths";
import * as TEXT from "../../constants/text";
import { onPopupChangePw } from "../../services/forgotPw/forgotPw";

const ContentPopupChangePw = (props) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isDirty, isValid },
  } = useForm();
  const [isStatus, onSetStatus] = useState(false);
  const [messageError, onSetMessageError] = useState("");
  const [isPassword, setStatePassword] = useState(false);
  const [isConfirmPassword, setStateConfirmPassword] = useState(false);
  const iconShowPassword = watch("password");
  const iconConfirmPassword = watch("confirm_password");
  const [isFocusPassword, setStateFocusPassword] = useState(false);
  const [isFocusConfirmPassword, setStateFocusConfirmPassword] =
    useState(false);
  const history = useHistory();
  const isDisabled = !isDirty || !isValid;

  const onChangePw = (dataForm) => {
    if (dataForm.password != dataForm.confirm_password) {
      onSetStatus(true);
      onSetMessageError("Mật khẩu không trùng khớp");
    } else {
      const data = {
        password: dataForm.password,
      };
      props.onDispatchShowLoading(true);
      onPopupChangePw(data)
        .then((res) => {
          props.onDispatchShowLoading(false);
          if (res.data.status === "fail") {
            props.alert.error(res.data.message);
          }
          if (res.data.status === "success") {
            props.onDispatchShowPopupActivateBook(false);
            const dataSuccessConfig = {
              status: true,
              title: [
                TEXT.TEXT_FINISH_UPDATE_INFO_1,
                TEXT.TEXT_FINISH_UPDATE_INFO_3,
              ],
              url: PATH.ROUTE_PATH_LIST_QUIZ,
              labelButton: TEXT.TEXT_VALUE_BUTTON_FINISH_UPDATE_INFO_2,
            };
            setTimeout(function () {
              props.onDispatchTogglePopupSuccess(dataSuccessConfig);
              localStorage.setItem("isEventUpdateInfo", false);
              localStorage.setItem("pathQuiz", "");
            }, 500);
          }
        })
        .catch((errors) => {
          props.onDispatchShowLoading(false);
        });
    }
  };
  return (
    <div className="sign_in_form text-center">
      <h5 className="monkey-f-bold mb-5 monkey-color-violet">
        Đặt lại mật khẩu
      </h5>
      <form onSubmit={handleSubmit(onChangePw)}>
        <div className="form-group position-relative">
          <i
            className={`${
              isFocusPassword ? "monkey-color-violet" : ""
            } fa fa-lock`}
            aria-hidden="true"
          ></i>
          <input
            style={{
              height: "44px",
              paddingLeft: "34px",
              paddingRight: "34px",
            }}
            className="form-control rounded-pill"
            type={isPassword ? "text" : "password"}
            placeholder="Nhập mật khẩu"
            autoComplete="off"
            name="password"
            ref={register({ required: true, minLength: 8 })}
            onFocus={() => setStateFocusPassword(true)}
            onBlur={() => setStateFocusPassword(false)}
          />
          {iconShowPassword && (
            <i
              style={{
                position: "absolute",
                top: "14px",
                right: "15px",
                zIndex: "20",
              }}
              onClick={() => setStatePassword(!isPassword)}
              className={`fa cursor ${
                isConfirmPassword
                  ? "fa-eye monkey-color-violet"
                  : "fa-eye-slash"
              }`}
              aria-hidden="true"
            ></i>
          )}
          <p className="monkey-color-red monkey-mt-15">
            {errors.password && "Vui lòng nhập mật khẩu"}
            {errors.password?.type === "minLength" &&
              "Mật khẩu phải từ 8 ký tự trở lên"}
          </p>
        </div>
        <div className="form-group position-relative">
          <i
            className={`${
              isFocusConfirmPassword ? "monkey-color-violet" : ""
            } fa fa-lock`}
            aria-hidden="true"
          ></i>
          <input
            style={{
              height: "44px",
              paddingLeft: "34px",
              paddingRight: "34px",
            }}
            className="form-control rounded-pill"
            type={isConfirmPassword ? "text" : "password"}
            placeholder="Nhập lại mật khẩu"
            name="confirm_password"
            autoComplete="off"
            ref={register({ required: true, minLength: 8 })}
            onFocus={() => setStateFocusConfirmPassword(true)}
            onBlur={() => setStateFocusConfirmPassword(false)}
          />
          {iconConfirmPassword && (
            <i
              style={{
                position: "absolute",
                top: "14px",
                right: "15px",
                zIndex: "20",
              }}
              onClick={() => setStateConfirmPassword(!isConfirmPassword)}
              className={`fa cursor ${
                isConfirmPassword
                  ? "fa-eye monkey-color-violet"
                  : "fa-eye-slash"
              }`}
              aria-hidden="true"
            ></i>
          )}
          <p className="monkey-color-red monkey-mt-15">
            {errors.confirm_password && "Vui lòng nhập lại mật khẩu"}
            {errors.confirm_password?.type === "minLength" &&
              "Mật khẩu phải từ 8 ký tự trở lên"}
          </p>

          {isStatus && (
            <div className="text-center monkey-color-red mb-3">
              {messageError}
            </div>
          )}
        </div>
        <input
          style={{ height: "44px" }}
          value="Lưu"
          className="btn monkey-bg-violet monkey-color-white rounded-pill monkey-pr-45 monkey-pl-45 hvr-registration-white"
          type="submit"
        />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { tokenToChangePw } = state.verifyOtpReducers;
  const { userName } = state.forgotPasswordReducers;
  return { tokenToChangePw, userName };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchTogglePopupSuccess,
      onDispatchShowLoading,
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};
export default compose(
  withAlert(),
  connect(mapStateToProps, mapDispatchToProps)
)(ContentPopupChangePw);
