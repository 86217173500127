import { LOCAL_STORAGE_KEY_USER_INFO } from "edu_lms/constants/type";
import { onCryptoData, onDecryptedData } from "edu_lms/modules/selection";

const getResponseTimingData = (response) => {
  const timingStart = response.config.timingStart;
  const timingEnd = Date.now();
  const elapsedTime = calcElapsedTime(timingStart, timingEnd);

  return {
    timingStart,
    timingEnd,
    elapsedTime,
  };
};

const calcElapsedTime = (timingStart, timingEnd) => {
  if (timingStart && timingEnd) {
    return Math.round(timingEnd - timingStart);
  }

  return;
};

export const logAPI = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (request) => {
      request.timingStart = Date.now();

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      response.timing = getResponseTimingData(response);

      saveAxiosLogs(generateLogData(response));

      return response;
    },
    (error) => {
      if (error.response) {
        error.response.timing = getResponseTimingData(error.response);
      }
    }
  );
};

export const getAxiosLogs = () => {
  let axiosLogs = [];

  try {
    const log = localStorage.getItem("axiosLogs");
    if (log) {
      axiosLogs = onDecryptedData(log, LOCAL_STORAGE_KEY_USER_INFO);
    }
  } catch {
    axiosLogs = [];
  }

  return axiosLogs;
};

const saveAxiosLogs = (data) => {
  const axiosLogs = getAxiosLogs();

  localStorage.setItem(
    "axiosLogs",
    onCryptoData([...axiosLogs, data], LOCAL_STORAGE_KEY_USER_INFO)
  );
};

const generateLogData = (response) => {
  const guestId = localStorage.getItem("guest_id");
  const userId = localStorage.getItem("user_id");

  let location = null;
  const locationLog = localStorage.getItem("location");

  if (locationLog) {
    location = onDecryptedData(locationLog, LOCAL_STORAGE_KEY_USER_INFO);
  }

  return {
    overall: {
      url: window.location.href,
      guest_id: guestId,
      user_id: userId,
      timestamp: response?.timing?.timingStart,
    },
    navigator: {
      user_agent: navigator.userAgent,
      user_agent_data: navigator.userAgentData,
      device_memory: navigator.deviceMemory,
      connection: navigator.connection,
    },
    api_call: {
      api_url: response?.config?.url,
      params: response?.config?.params,
      status: response?.status,
      elapsed_time: response?.timing?.elapsedTime,
      timing_start: response?.timing?.timingStart,
      timing_end: response?.timing?.timingEnd,
    },
    location,
  };
};
