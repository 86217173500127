import { useState } from "react";
import ExpertsSlider from "edu_lms_v2/components/ExpertsSlider";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import styles from "./Intro.module.scss";

export default function Intro() {
  const [url, setUrl] = useState("");

  return (
    <main>
      <SEO data={pages.intro} />
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Giới thiệu
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-intro hoc10-intro--1 bg-gr">
        <div className="container">
          <div className="text-intro decor-h2 pr">
            <h1 className="title">Giới thiệu chung</h1>
            <p>
              Hoc10 mang sứ mệnh xây dựng một hệ sinh thái giáo dục, kết nối nhà
              trường với học sinh và phụ huynh, nhằm tạo nên môi trường giảng
              dạy và học tập hoàn thiện, hiệu quả.
            </p>
          </div>
        </div>
      </div>
      <div className="hoc10-intro-feature pr">
        <div className="container">
          <ul className="feature-list">
            <li>
              <div className="img-wrapper">
                <img src="/assets/img/img-book1.svg" alt="Sách điện tử" />
              </div>
              <div className="text">Sách điện tử tương tác hấp dẫn</div>
            </li>
            <li>
              <div className="img-wrapper">
                <img
                  src="/assets/img/img-book2.svg"
                  alt="Kho bài giảng điện tử"
                />
              </div>
              <div className="text">Kho bài giảng điện tử hữu ích</div>
            </li>
            <li>
              <div className="status">Sắp ra mắt</div>
              <div className="img-wrapper">
                <img src="/assets/img/img-book3.svg" alt="Thư viện bài tập" />
              </div>
              <div className="text">Thư viện bài tập phong phú</div>
            </li>
            <li>
              <div className="status">Sắp ra mắt</div>
              <div className="img-wrapper">
                <img src="/assets/img/img-book4.svg" alt="Lớp học trực tuyến" />
              </div>
              <div className="text">Lớp học trực truyến tiện lợi</div>
            </li>
          </ul>
          <div className="logo-h10 flex-center">
            <img src="/assets/img/updated_logo2.svg" alt="Logo Hoc10" />
          </div>
        </div>
      </div>
      <div className="hoc10-object pr">
        <div className="container">
          <div className="header">
            <h2 className="title">
              <span>Đối tượng</span> sử dụng
            </h2>
          </div>
          <div className="object pr">
            <div className="object__item pr">
              <span className="ob">Giáo viên</span>
              <ul>
                <li>
                  <span>Giảng dạy trực tuyến</span>
                </li>
                <li>
                  <span>Quản lý học sinh</span>
                </li>
                <li>
                  <span>Tải xuống bài giảng điện tử</span>
                </li>
              </ul>
            </div>
            <div className="object__item pr">
              <span className="ob">Phụ huynh</span>
              <ul>
                <li>
                  <span>Đồng hành cùng con học trực tuyến</span>
                </li>
                <li>
                  <span>Theo dõi kết quả học tập</span>
                </li>
              </ul>
            </div>
            <div className="object__item pr">
              <span className="ob">Nhà quản lý giáo dục</span>
              <ul>
                <li>
                  <span>
                    Theo dõi tiến trình dạy và học của giáo viên và học sinh
                  </span>
                </li>
                <li>
                  <span>
                    Có những điều chỉnh kịp thời nhằm nâng cao chất lượng dạy và
                    học
                  </span>
                </li>
              </ul>
            </div>
            <div className="object__item pr">
              <span className="ob">Học sinh</span>
              <ul>
                <li>
                  <span>Học trực tuyến</span>
                </li>
                <li>
                  <span>Ôn tập kiến thức và thi thử</span>
                </li>
              </ul>
            </div>
            <div className="object__avt pr">
              <div className="avt">
                <img
                  src="/assets/img/avt-gv.svg"
                  alt="Giáo viên sử dụng hoc10"
                />
              </div>
              <div className="avt">
                <img
                  src="/assets/img/avt-ph1.svg"
                  alt="Phụ huynh sử dụng hoc10"
                />
              </div>
              <div className="avt">
                <img
                  src="/assets/img/avt-house.svg"
                  alt="Nhà trường sử dụng hoc10"
                />
              </div>
              <div className="avt">
                <img
                  src="/assets/img/avt-student.svg"
                  alt="Học sinh sử dụng hoc10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-vepic">
        <div className="container">
          <div className="header">
            <h2 className="title">
              <span className="inline line8">Hoc10</span> - phiên bản điện tử
              độc quyền của bộ SGK Cánh Diều
            </h2>
          </div>
          <div
            className="video pr"
            data-link=""
            data-toggle="modal"
            data-target="#modal-video"
          >
            <div className="video__thumb">
              <img
                src="/assets/img/banner-canh-dieu-2.png"
                alt="Bộ sách giáo khoa Cánh diều"
              />
            </div>
            {/* <div className="video__play">
              <img
                onClick={() =>
                  setUrl(
                    "https://www.youtube.com/watch?v=ZYdoW9RzPAs&ab_channel=Hoc10"
                  )
                }
                src="/assets/img/icon-play.svg"
                alt=""
              />
            </div> */}
          </div>
          <div className="intro-list row">
            <div className="col-lg-4 col-md-6">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book5.svg"
                    alt="Bộ sách thứ nhất (đầu tiên)"
                  />
                </div>
                <h4>Bộ sách thứ nhất (đầu tiên)</h4>
                <p>
                  Bộ sách thứ nhất (đầu tiên) góp phần thực hiện chủ trương xã
                  hội hoá sách giáo khoa, xoá bỏ cơ chế độc quyền trong lĩnh vực
                  xuất bản - in - phát hành sách giáo khoa.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book6.svg"
                    alt="Tác giả bộ sách Cánh Diều"
                  />
                </div>
                <h4>Tác giả bộ sách Cánh Diều</h4>
                <p>
                  Tác giả bộ sách Cánh Diều là những nhà giáo, nhà khoa học tâm
                  huyết và giàu kinh nghiệm. Trong đó, có tác giả là Tổng Chủ
                  biên Chương trình Giáo dục phổ thông 2018 và nhiều tác giả là
                  thành viên Ban Phát triển Chương trình tổng thể, Ban Phát
                  triển các chương trình môn học thành lập theo Quyết định của
                  Bộ trưởng Bộ GDĐT.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book8.svg"
                    alt="Phiên bản điện tử"
                  />
                </div>
                <h4>Phiên bản điện tử</h4>
                <p>
                  Bên cạnh sách giấy, bộ sách Cánh Diều còn có phiên bản điện tử
                  và nhiều học liệu điện tử sinh động đi kèm để hỗ trợ quá trình
                  giảng dạy - học tập của Giáo viên và Học sinh.
                </p>
              </div>
            </div>
          </div>
          <Link
            to={PATH.ROUTE_PATH_V3_BOOK_INTRO}
            title="Xem thêm"
            className="btn-sub btn-viewmore flex-center"
          >
            Xem thêm
          </Link>
        </div>
      </div>
      <div className="hoc10-onepercent pr">
        <div className="container pr">
          <div className="row">
            <div className="col-lg-4">
              <div className="intro">
                <h2 className="title">
                  Về <span>One Percent</span>
                </h2>
                <p>
                  Là doanh nghiệp đi đầu về lĩnh vực công nghệ trong giáo dục,
                  One Percent mong muốn xây dựng nền tảng giáo dục bằng cách tạo
                  nên một môi trường dạy và học trực tuyến chất lượng cao, đầy
                  nhiệt huyết, hứng thú và sáng tạo.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team">
                <h2 className="title title-pc">
                  <span>Đội ngũ One Percent</span> đã phát triển
                </h2>
                <h2 className="title title-mobile">
                  Sản phẩm của <span>One Percent</span>
                </h2>
                <div className="project flex">
                  <div
                    className={`${styles.Intro} project__monkey project__monkey--pc flex-center`}
                  >
                    <p>
                      <img
                        src="/assets/img/monkey-junior.svg"
                        alt="Monkey Junior"
                      />
                    </p>
                    <p>
                      <img
                        src="/assets/img/monkey-stories.svg"
                        alt="Monkey Stories"
                      />
                    </p>
                    <p>
                      <img
                        src="/assets/img/monkey-math.svg"
                        alt="Monkey Math"
                      />
                    </p>
                    <p>
                      <img src="/assets/img/vmonkey.svg" alt="VMonkey" />
                    </p>
                  </div>
                  {/* <div className="project__monkey project__monkey--mobile flex-center">
                    <a href="" title="">
                      <img
                        src="/assets/img/logo-monkey1.svg"
                        alt="Logo Monkey"
                      />
                    </a>
                  </div> */}
                  <div className="project__hoc10 flex-center">
                    <img src="/assets/img/updated_logo2.svg" alt="Logo Hoc10" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to={PATH.ROUTE_PATH_V3_ONEPERCENT_INTRO}
            href=""
            title="Xem thêm"
            className="btn-sub btn-viewmore flex-center"
          >
            Xem thêm
          </Link>
        </div>
      </div>
    </main>
  );
}
