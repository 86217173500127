import Header from "edu_lms_v2/components/Layout/Header";
import Footer from "edu_lms_v2/components/Layout/Footer";
import { useState } from "react";

export default function Layout({ children }) {
    const [openActiveBook, setOpenActiveBook] = useState(false);
    const clickActiveBook = (event) => {
        event?.preventDefault();
        setOpenActiveBook(true);
    };
    
    const onCloseActiveBook = () => {
        setOpenActiveBook(false);
    };
    
  return (
    <>
        <Header
            openActiveBook={openActiveBook}
            onCloseActiveBook={onCloseActiveBook}
        />
        
      {children}
        <Footer clickActiveBook={clickActiveBook}/>
    </>
  );
}
