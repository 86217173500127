import React from "react";
import styled from "styled-components";
import { COLOR } from "../../constants/styles";
import { renderDescriptionDisequations, renderDescriptionSystemOfInequalities } from '../../helpers/SROI_001';
import styles from "./styles.module.scss";

const InputCoordinateArea = ({
  coefficients,
  inputtedCoordinates,
  hideResultAnswer = false,
  isView = false,
  onChangeInput = () => {},
}) => {
  const handleChange = (e, lineIndex) => {
    const { name, value } = e.target;
    onChangeInput(lineIndex, { [name]: value });
  };

  return (
    <>
      <p className={styles["input-points__description"]}>
        <span className="font-weight-bold pr-3">Bước 2</span> (
        {coefficients.length === 1
          ? renderDescriptionDisequations()
          : renderDescriptionSystemOfInequalities(coefficients.length)}
        )
      </p>

      {inputtedCoordinates.map((inputtedCoordinate, index) => {
        return (
          <PointWrapper
            key={index}
            className={styles.point}
            isView={isView}
            isCorrect={hideResultAnswer ? null : inputtedCoordinate.isCorrect}
          >
            <label>
              M{coefficients.length === 1 ? null : <sub>{index + 1}</sub>}
            </label>
            <span className={styles.symbol}>(</span>
            <input
              type="number"
              name="x"
              value={inputtedCoordinate.x}
              onChange={(e) => handleChange(e, index)}
            />
            <span className={styles.symbol}>;</span>
            <input
              type="number"
              name="y"
              value={inputtedCoordinate.y}
              onChange={(e) => handleChange(e, index)}
            />
            <span className={styles.symbol}>)</span>
          </PointWrapper>
        );
      })}
    </>
  );
};

export default InputCoordinateArea;

const PointWrapper = styled.div`
  pointer-events: ${props => props.isView ? "none" : "initial"};
  label {
    color: ${(props => getColorAnswer(props.isCorrect))};
  }
  span {
    color: ${(props => getColorAnswer(props.isCorrect))};
  }
  input {
    border: 1px solid ${(props => getColorAnswer(props.isCorrect))};
    color: ${(props => getColorAnswer(props.isCorrect))};
  }
`;

const getColorAnswer = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return COLOR.Success;
    case false:
      return COLOR.Error;
    default:
      return "";
  }
}