import classNames from "classnames";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { URL_IMAGE } from "edu_lms/constants/type";
import { ROUTE_PATH_READING_BOOKS } from "consts/routePaths";
import { updateViewCourse } from "edu_lms_v2/services/listBook";
import { PAGE_TRAINING } from "../const";
import { onDispatchUpdateReadingBookData } from "edu_lms/modules/ReadingBooks/actions";
import { READING_BOOK_ENTRY_SOURCE } from "edu_lms/constants/book";
import { DOCUMENT_TYPE_VIDEO_DRIVE } from "consts/book";

const TYPE_VIEW_COURSE = 1;
const TYPE_VIEW_BOOK = 2;

export default function Video({
  item,
  col,
  onSetUrl,
  index,
  page,
  isCourse = false,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const setUrl = () => {
    updateView(item.id, TYPE_VIEW_COURSE);

    if (item.type_document === DOCUMENT_TYPE_VIDEO_DRIVE)
      return onSetUrl(item.url, item.type_document);

    return onSetUrl(
      `https://www.youtube.com/watch?v=${item.url}`,
      item.type_document
    );
  };

  const getThumb = () => {
    if (item.type_document === DOCUMENT_TYPE_VIDEO_DRIVE)
      return `https://drive.google.com/thumbnail?id=${item.url}`;

    return `http://i3.ytimg.com/vi/${item.url}/hqdefault.jpg`;
  };

  const updateView = (id, type) => {
    const data = { id, type };

    updateViewCourse(data)
      .then()
      .catch((e) => console.log(e));
  };

  return (
    <div key={index} className={col}>
      <WrapArticle
        className="post bg-sh pr"
        style={{
          boxShadow: "5px 5px 10px 1px #ccc",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <>
          <div
            className={classNames("postion-relative", {
              "py-3 monkey-bg-black":
                item.type_document === DOCUMENT_TYPE_VIDEO_DRIVE,
            })}
          >
            <img className="w-100" src={getThumb()} alt="" />
            <ImgIconPlay
              isThumb={item.is_thumb}
              className="position-absolute card-img-top icon-play cursor"
              onClick={setUrl}
              alt="#"
              src={`${URL_IMAGE}upload/web/background-web/icon-play.svg`}
            />
          </div>
        </>
        <div
          className="post__content d-flex justify-content-between"
          style={{
            padding: "16px 10px",
            borderTop: "1px solid #FF7707",
            flex: "1",
          }}
        >
          <div
            className={
              isCourse
                ? "d-flex flex-column flex-sm-row justify-content-between w-100"
                : ""
            }
          >
            <TitleStyle className={isCourse ? "text" : ""}>
              {page === PAGE_TRAINING ? (
                <Semester
                  title={`${item.worksheet_title} - Tiết dạy minh họa`}
                >{`${item.worksheet_title} - Tiết dạy minh họa`}</Semester>
              ) : (
                <Semester
                  title={`${item.worksheet_title}`}
                >{`${item.worksheet_title}`}</Semester>
              )}
            </TitleStyle>

            {isCourse && (
              <div>
                <button
                  className="btn-sub monkey-fz-12 p-2"
                  onClick={() => {
                    updateView(item.id, TYPE_VIEW_BOOK);
                    dispatch(
                      onDispatchUpdateReadingBookData({
                        entrySource: READING_BOOK_ENTRY_SOURCE.LIST_BOOK,
                      })
                    );
                    history.push(
                      `${ROUTE_PATH_READING_BOOKS}/${item.link_book}`
                    );
                  }}
                >
                  Xem sách
                </button>
              </div>
            )}
          </div>
        </div>
      </WrapArticle>
    </div>
  );
}

const Semester = styled.h4`
  text-transform: none !important;
`;

const ImgIconPlay = styled.img`
  position: absolute;
  top: ${(props) =>
    props.isThumb !== "null" && props.isThumb !== undefined ? "31%" : "17%"};
  width: 30% !important;
  left: 35%;
`;

const WrapArticle = styled.article`
  box-shadow: "5px 5px 10px 1px #ccc";
  border-radius: "10px";
  overflow: "hidden";
`;

const TitleStyle = styled.div`
  &.text {
    width: 60%;

    @media (max-width: 575px) {
      width: 100%;
    }
  }
`;
