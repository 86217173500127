import { useEffect, useState } from "react";
import { getHistoryExam } from "edu_lms/services/readingBook";
import ResultQuestion from "edu_lms_v2/modules/MockTest/ResultQuestion";
import Loading from "edu_lms_v2/components/Loading";

export default function HistoryExam({ match }) {
  const userId = match.params.userId;
  const questionSetId = match.params.questionSetId;
  const [isLoading, setIsLoading] = useState(true);
  const [errorTitle, setErrorTitle] = useState("");

  const [historyQuestions, setHistoryQuestions] = useState([]);

  useEffect(() => {
    const data = { user_class_room_id: userId, question_set_id: questionSetId };
    getHistoryExam(data)
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.data;
          if (data[0]?.result_detail) {
            setHistoryQuestions(data[0]);
          } else {
            setErrorTitle("Không tìm thấy kết quả làm bài");
          }
          setIsLoading(false);
        }
      })
      .catch(() => {
        setErrorTitle("Không tìm thấy kết quả làm bài");
        setIsLoading(false);
      });
  }, [userId, questionSetId]);

  const listQuestionHistory = historyQuestions?.result_detail
    ? JSON.parse(historyQuestions.result_detail)
    : [];

  return (
    <>
      {isLoading && (
        <div className="mt-5">
          <Loading />
        </div>
      )}
      {!errorTitle && !isLoading && (
        <ResultQuestion
          listQuestion={listQuestionHistory}
          result={historyQuestions}
        />
      )}
      {errorTitle && (
        <p className="mt-5 pt-5 text-center monkey-fz-28 font-weight-bold">
          {errorTitle}
        </p>
      )}
    </>
  );
}
