import { MathpixMarkdown, MathpixLoader } from "mathpix-markdown-it";
import renderHTML from "react-render-html";
import _ from "lodash";
import { TYPE_TEXT } from "../../constants/type";
import textComponentStyle from "./style.module.scss";

const TextComponent = ({ data, typeText, fontWeight, fontSize }) => {
  return (
    <>
      {_.includes(typeText, TYPE_TEXT) ? (
        renderHTML(data)
      ) : (
        <div
          style={{
            fontSize: fontSize || "24px",
            fontWeight: `${fontWeight ? fontWeight : "bold"}`,
          }}
          className={textComponentStyle["text-component__latex-wrapper"]}
        >
          <MathpixLoader>
            <MathpixMarkdown text={String(data) ? String(data) : ""} />
          </MathpixLoader>
        </div>
      )}
    </>
  );
};
export default TextComponent;
