const TYPE_DISPLAY = {
  Horizontal: 1,
  Vertical: 2,
  Vertical3Columns: 3,
};

const TYPE_GAME_MAT_001 = {
  Image_Image: 1,
  ImageText_Text: 2,
  ImageText_ImageText: 3,
  Text_Text: 4,
  Image_Text: 5,
};

const QUESTION_TITLE_HEIGHT = 20;
const BOX_SIZE_WRAPPER = {
  Default: 180,
  Vertical: {
    Width: 300,
    Height: 120,
    HeightWithImage: 140,
  },
  Vertical3Columns: {
    Width: 200,
    Height: 50
  }
};
const SPACE_AREA = {
  Height: 100,
  Width: 240,
  WidthThreeColumns: 140,
};

const TYPE_QUESTION_3_COLUMNS = {
  Left: "left",
  Right: "right",
};

const BOX_TYPE = {
  Question: "question",
  Answer: "answer"
};

export {
  TYPE_DISPLAY,
  TYPE_GAME_MAT_001,
  QUESTION_TITLE_HEIGHT,
  BOX_SIZE_WRAPPER,
  SPACE_AREA,
  TYPE_QUESTION_3_COLUMNS,
  BOX_TYPE,
};
