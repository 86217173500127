import { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import styles from "../../teachingManager.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as types from "edu_lms/constants/type";
import { postCreateClassroom } from "edu_lms/services/teachingManager";
import { STATUS, ROLE } from "../../constant";
import { getListGrade } from "edu_lms/services/app/app";

const schema = yup
  .object()
  .shape({
    class_name: yup
      .string()
      .required("Vui lòng nhập tên lớp")
      .max(10, "Tên lớp tối đa 10 ký tự"),
    grade: yup.object().required("Vui lòng chọn khối lớp"),
  })
  .required();

export default function FormAddClassroom({
  role,
  setRole,
  listClassroom,
  idClassroom,
  setStatus,
  setIdClassroom,
  getListClassroom,
}) {
  const [allClassroom, setAllClassroom] = useState([]);
  const defaultGrade = listClassroom.find((item) => item.id === idClassroom);
  const block = types.gradeOptions.find(
    (item) => item.value === defaultGrade?.grade_id
  );
  const [gradeName, setGradeName] = useState(
    role === ROLE.UPDATE ? block?.name : ""
  );
  const [className, setClassName] = useState(
    role === ROLE.UPDATE ? defaultGrade?.title : ""
  );
  const [invalidClassname, setInvalidClassname] = useState(false);
  const { handleSubmit, control, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getListGrade()
      .then((res) => {
        if (res.data.status === "success") {
          const newData = res.data.data.map((grade) => {
            return {
              value: grade.id,
              label: grade.name,
            };
          });

          setAllClassroom(newData);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const onHandleSubmit = (dataForm) => {
    setInvalidClassname(false);
    const class_name = className.trim();
    if (class_name) {
      const data = {
        grade_id: dataForm.grade.value,
        title: class_name,
      };

      if (role === ROLE.UPDATE) data.id = defaultGrade.id;

      setStatus(STATUS.LOADING);
      postCreateClassroom(data).then((res) => {
        if (res.data.status === "success") {
          setIdClassroom(res.data?.data);
          setStatus(STATUS.SUCCESS);
          getListClassroom();
        } else {
          setStatus(STATUS.START);
          setRole(ROLE.ADD);
          toast.error("Tạo lớp học thất bại!");
        }
      });
    } else {
      setInvalidClassname(true);
      reset();
    }
  };

  const onChangeGrade = (e) => {
    setGradeName(e.label);
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>
      <div className="row my-3">
        <div className="col-md-4 mb-3">
          <p className="font-weight-bold">
            Khối lớp<span className="text-danger">*</span>
          </p>
          <Controller
            render={({ onChange, value }) => (
              <Select
                value={value}
                placeholder="Chọn khối"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 2000 }),
                }}
                onChange={(e) => {
                  onChange(e);
                  onChangeGrade(e);
                }}
                options={allClassroom}
                filterOption={createFilter({
                  matchFrom: "any",
                  stringify: (option) => `${option.label}`,
                })}
              />
            )}
            defaultValue={block}
            name="grade"
            control={control}
          />
          {errors.grade && (
            <p className="text-danger">{errors.grade.message}</p>
          )}
        </div>
        <div className="col-md-8">
          <p className="font-weight-bold">
            Tên lớp<span className="text-danger">*</span>
          </p>
          <div className="d-flex">
            <InputGroup className={`mb-3 ${styles.width65}`}>
              <FormControl
                type="text"
                value={gradeName}
                disabled
                className={styles.input}
              />
              <Controller
                render={({ onChange, value }) => (
                  <FormControl
                    type="text"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setClassName(e.target.value);
                    }}
                  />
                )}
                defaultValue={className}
                name="class_name"
                control={control}
              />
            </InputGroup>
            <p className={`p-2 text-center overflow-hidden ${styles.width35}`}>
              {gradeName}
              {className}
            </p>
          </div>
          {errors.class_name && (
            <p className="text-danger">{errors.class_name.message}</p>
          )}
          {invalidClassname && (
            <p className="text-danger">Tên lớp không hợp lệ</p>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center my-3">
        <button type="submit" className={styles.buttonAddClass}>
          {role === ROLE.ADD ? "Tạo lớp" : "Chỉnh sửa"}
        </button>
      </div>
    </form>
  );
}
