import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import styled from "styled-components";
import icon from "edu_lms_v2/assets/img/icon-nguvan.svg";
import { getListSubject } from "edu_lms/services/app/app";
import { GRADES } from "edu_lms/constants/type";

const dataSubject = [
  {
    id: "lop1",
    showDefault: true,
    listSubject: [
      {
        id: 1,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 2,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 3,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 4,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 5,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 6,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 7,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 8,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
      {
        id: 9,
        title: "Ngữ văn",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
    ],
  },
  {
    id: "lop2",
    showDefault: false,
    listSubject: [
      {
        id: 1,
        title: "Toán",
        urlImage: "",
        countExamDone: 10,
        totalExam: 20,
        averageResult: "75%",
      },
    ],
  },
];

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function TabContent({ gradeId }) {
  const [listSubject, setListSubject] = useState([]);
  const onGetListSubject = (gradeId) => {
    let id = GRADES.cap1;
    if (
      [GRADES.lop6, GRADES.lop7, GRADES.lop8, GRADES.lop9].includes(gradeId)
    ) {
      id = GRADES.cap2;
    } else if ([GRADES.lop10, GRADES.lop11, GRADES.lop12].includes(gradeId)) {
      id = GRADES.cap3;
    }
    getListSubject(id).then((res) => {
      if (res.data.status) {
        setListSubject(res.data.data.list_subject);
      }
    });
  };
  useEffect(() => {
    onGetListSubject(gradeId);
  }, [gradeId]);

  return (
    <div
      id={`lop${gradeId}`}
      className={`tab-pane fade ${gradeId === 4 ? "show active" : ""}`}
    >
      <div className="library-list text-center">
        <Slider {...sliderSettings} className="tab-content slider-home pr">
          {listSubject?.map((item) => (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  id={`tooltip-${item.id}`}
                  bsPrefix="monkey-bg-white text-center"
                >
                  <InfoExamStudent className="mt-2">
                    <h5 className="txt-2">{item.title}</h5>
                    <p>
                      Đã làm:&nbsp;
                      <span className="monkey-color-orange">
                        {item.countExamDone}/{item.totalExam} Đề
                      </span>
                    </p>
                    <p>
                      Kết quả trung bình:{" "}
                      <span className="monkey-color-orange">
                        {item.averageResult}
                      </span>
                    </p>
                  </InfoExamStudent>
                </Tooltip>
              }
            >
              <a href="" title="" key={item.id}>
                <span className="icon flex-center">
                  <img src={icon} alt="" />
                </span>
                <span className="name txt-5">{item.title}</span>
              </a>
            </OverlayTrigger>
          ))}
        </Slider>
      </div>
    </div>
  );
}

const InfoExamStudent = styled.div`
  width: 250px;
  height: 100px;
`;
