import React from "react";
import { TITLE } from "../../constants/SROI_001";
import { Typography } from "../../components";
import { TYPE_DATA } from "../../constants";
import { getEquationGraphSROI } from "../../helpers/SROI_001";

const HeaderTitle = ({ coefficients }) => {
  return (
    <div className="d-flex monkey-f-header mb-2">
      <h2 className="pt-2 pr-3">
        {coefficients.length === 1
          ? TITLE.Disequations
          : TITLE.SystemOfInequalities}
      </h2>
      <Typography
        text={getEquationGraphSROI(coefficients)}
        typeText={[TYPE_DATA.LATEX]}
        fontSize={24}
      />
    </div>
  );
};

export default HeaderTitle;
