import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Heading from "./Heading";
import Table from "./Table";
import Filter from "./Filter";
import { SHOPS, CITIES, REGIONS, OPTION_ALL } from "./data";

export default function PublisherList() {
  useScrollToTop();
  const userLocation = useSelector((state) => state.app.userLocation);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRegion, setSelectedRegion] = useState(OPTION_ALL);
  const [selectedCity, setSelectedCity] = useState(OPTION_ALL);

  useEffect(() => {
    if (!_.isEmpty(userLocation)) {
      const userCityName = userLocation.city_name;
      const userCity = CITIES.find((city) => city.value === userCityName);
      const userRegion = REGIONS.find(
        (region) => region.value === userCity?.region
      );
      if (userRegion) {
        setSelectedRegion(userRegion);
      }
      if (userCity) {
        setSelectedCity(userCity);
      }
    }
  }, [userLocation]);

  const handleChangeRegion = (region) => {
    setSelectedRegion(region);
    setSelectedCity(OPTION_ALL);
    setCurrentPage(1);
  };

  const handleChangeCity = (city) => {
    setSelectedCity(city);
    setCurrentPage(1);
  };

  const _cities = CITIES.filter(
    (city) => !selectedRegion.value || city.region === selectedRegion.value
  );

  let _shops = SHOPS;

  if (selectedCity.value) {
    _shops = SHOPS.filter(({ cityName }) => cityName === selectedCity.value);
  } else if (selectedRegion.value) {
    _shops = SHOPS.filter(({ region }) => region === selectedRegion.value);
  }

  return (
    <main>
      <Heading />
      <div className="container mb-5">
        <OnlineAddress>
          <p className="h3 pt-2">Địa chỉ online</p>
          <p className="h4 text-buybook">Shop online chính thức:{" "}
            <a className="h4 text-buybook" href="https://sachcanhdieu.vn" target="_blank">
              https://sachcanhdieu.vn
            </a>
          </p>
          <div>
            <div className="text-buybook">
              Tìm mua trên các trang thương mại điện tử như Tiki, Fahasa:
              Gõ từ khoá: “Sách Cánh Diều”, “Cánh Diều”
            </div>
            <div>
              <p className="text-buybook">Đặt mua trên nền tảng mạng xã hội:</p>
                <p className="text-buybook">
                  Fanpage Facebook Cánh Diều – Miền Nam:{" "}
                  <a className="text-buybook" href="https://bit.ly/3axkm1R" target="_blank">
                    https://bit.ly/3axkm1R
                  </a>
                </p>
                <p className="text-buybook">
                  Mrs. Hồng Ý – <a className="text-buybook" href="tel:093 8489 768">093 8489 768</a>
                </p>
            </div>
          </div>
        </OnlineAddress>
        <div className="row header pr offline-address pt-4">
          <div className="container-fluid container-xl">
            <p className="h3">Địa chỉ các đơn vị phân phối</p>
            <Filter
              selectedRegion={selectedRegion}
              onChangeRegion={handleChangeRegion}
              selectedCity={selectedCity}
              onChangeCity={handleChangeCity}
              optionRegions={[OPTION_ALL, ...REGIONS]}
              optionCities={[OPTION_ALL, ..._cities]}
            />
            <Table
              shops={_shops}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
const OnlineAddress = styled.div`
  li {
    list-style: none;
  }
  line-height: 1.5;
`;
