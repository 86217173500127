import React from "react";
import _ from "lodash";
import { Audio, AudioType, Typography, Image } from "../../../components";
import { TYPE_DATA } from "../../../constants";
import "./styles.scss";

const HeaderQuestion = ({ question }) => {
  const { typeQuestion, contentQuestion } = question;

  return (
    <div className="row math-input__header-question">
      {_.includes(typeQuestion, TYPE_DATA.AUDIO) && (
        <Audio variant={AudioType.Primary} src={contentQuestion.srcAudio} />
      )}

      {_.includes(typeQuestion, TYPE_DATA.TEXT) && (
        <Typography
          fontSize={contentQuestion.text.fontSize}
          typeText={[TYPE_DATA.LATEX, TYPE_DATA.TEXT]}
          text={contentQuestion.text.contentText}
          className="quicksand-medium mathPix"
        />
      )}

      {_.includes(typeQuestion, TYPE_DATA.IMAGE) && (
        <div className="d-flex justify-content-center">
          <Image src={contentQuestion.srcImage} />
        </div>
      )}
    </div>
  );
};

export default HeaderQuestion;
