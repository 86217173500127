export const REGIONS = [
  {
    label: "Miền Bắc",
    value: "Mien Bac",
  },
  {
    label: "Miền Trung - Tây Nguyên",
    value: "Mien Trung Tay Nguyen",
  },
  {
    label: "Miền Nam",
    value: "Mien Nam",
  },
];

export const CITIES = [
  //Mien Bac
  {
    region: "Mien Bac",
    value: "Hanoi",
    label: "Hà Nội",
  },
  {
    region: "Mien Bac",
    value: "Bac Giang",
    label: "Bắc Giang",
  },
  {
    region: "Mien Bac",
    value: "Bac Kan",
    label: "Bắc Kạn",
  },
  {
    region: "Mien Bac",
    value: "Bac Ninh",
    label: "Bắc Ninh",
  },
  {
    region: "Mien Bac",
    value: "Cao Bang",
    label: "Cao Bằng",
  },
  {
    region: "Mien Bac",
    value: "Dien Bien Phu",
    label: "Điện Biên",
  },
  {
    region: "Mien Bac",
    value: "Ha Giang",
    label: "Hà Giang",
  },
  {
    region: "Mien Bac",
    value: "Ha Tinh",
    label: "Hà Tĩnh",
  },
  {
    region: "Mien Bac",
    value: "Hai Duong",
    label: "Hải Dương",
  },
  {
    region: "Mien Bac",
    value: "Haiphong",
    label: "Hải Phòng",
  },
  {
    region: "Mien Bac",
    value: "Hoa Binh",
    label: "Hòa Bình",
  },
  {
    region: "Mien Bac",
    value: "Hung Yen",
    label: "Hưng Yên",
  },
  {
    region: "Mien Bac",
    value: "Lang Son",
    label: "Lạng Sơn",
  },
  {
    region: "Mien Bac",
    value: "Lao Cai",
    label: "Lào cai",
  },
  {
    region: "Mien Bac",
    value: "Nam Dinh",
    label: "Nam Định",
  },
  {
    region: "Mien Bac",
    value: "Vinh",
    label: "Nghệ An",
  },
  {
    region: "Mien Bac",
    value: "Ninh Binh",
    label: "Ninh bình",
  },
  {
    region: "Mien Bac",
    value: "Phu Tho",
    label: "Phú Thọ",
  },
  {
    region: "Mien Bac",
    value: "Cam Pha Mines",
    label: "Quảng Ninh",
  },
  {
    region: "Mien Bac",
    value: "Son La",
    label: "Sơn La",
  },
  {
    region: "Mien Bac",
    value: "Thai Binh",
    label: "Thái Bình",
  },
  {
    region: "Mien Bac",
    value: "Thai Nguyen",
    label: "Thái Nguyên",
  },
  {
    region: "Mien Bac",
    value: "Thanh Hoa",
    label: "Thanh Hóa",
  },
  {
    region: "Mien Bac",
    value: "Tuyen Quang",
    label: "Tuyên Quang",
  },
  {
    region: "Mien Bac",
    value: "Vinh Yen",
    label: "Vĩnh Phúc",
  },
  {
    region: "Mien Bac",
    value: "Yen Bai",
    label: "Yên bái",
  },
  {
    region: "Mien Bac",
    value: "Ha Nam",
    label: "Hà Nam",
  },
  {
    region: "Mien Bac",
    value: "Lai Chau",
    label: "Lai Châu",
  },

  //Miền nam
  {
    region: "Mien Nam",
    label: "Hồ Chí Minh",
    value: "Ho Chi Minh City",
  },
  {
    region: "Mien Nam",
    value: "Thu Dau Mot",
    label: "Bình Dương",
  },
  {
    region: "Mien Nam",
    value: "Baria Vung Tau",
    label: "Bà Rịa - Vũng Tàu",
  },
  {
    region: "Mien Nam",
    value: "Phu Khuong",
    label: "Tây Ninh",
  },
  {
    region: "Mien Nam",
    value: "Bien Hoa",
    label: "Đồng Nai",
  },
  {
    region: "Mien Nam",
    value: "Can Duoc",
    label: "Long An",
  },
  {
    region: "Mien Nam",
    value: "My Tho",
    label: "Tiền Giang",
  },
  {
    region: "Mien Nam",
    value: "Sa Dec",
    label: "Đồng Tháp",
  },
  {
    region: "Mien Nam",
    value: "Chau Doc",
    label: "An Giang",
  },
  {
    region: "Mien Nam",
    value: "Vinh Long",
    label: "Vĩnh Long",
  },
  {
    region: "Mien Nam",
    value: "Ben Tre",
    label: "Bến Tre",
  },
  {
    region: "Mien Nam",
    value: "Tra Vinh",
    label: "Trà Vinh",
  },
  {
    region: "Mien Nam",
    value: "Soc Trang",
    label: "Sóc Trăng",
  },
  {
    region: "Mien Nam",
    value: "Kien Giang",
    label: "Kiên Giang",
  },
  {
    region: "Mien Nam",
    value: "Can Tho",
    label: "Cần Thơ",
  },

  //Miền trung - tây nguyên
  {
    region: "Mien Trung Tay Nguyen",
    value: "Da Nang",
    label: "Đà Nẵng",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Dong Hoi",
    label: "Quảng Bình",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Quang Tri",
    label: "Quảng Trị",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Hue",
    label: "TT-Huế",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Quy Nhon",
    label: "Bình Định",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Gia Lai",
    label: "Gia Lai",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Kon Tum",
    label: "Kon Tum",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Phu Yen",
    label: "Phú Yên",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Lam Dong",
    label: "Lâm Đồng",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Dak Lak",
    label: "Đắk Lắk",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Dak Nong",
    label: "Đắk Nông",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Nha Trang",
    label: "Khánh Hòa",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Ninh Thuan",
    label: "Ninh Thuận",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Phan Thiet",
    label: "Bình Thuận",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Quang Nam",
    label: "Quảng Nam",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Quang Ngai",
    label: "Quảng Ngãi",
  },
  {
    region: "Mien Trung Tay Nguyen",
    value: "Binh Dinh",
    label: "Bình Định",
  },
];

export const SHOPS = [
  //Mien Bac
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty CP Đầu Tư Xuất Bản - Thiết Bị Giáo Dục Việt Nam (VEPIC)",
    address:
      "Tầng 5, tòa nhà Lâm Viên Complex, 107A Nguyễn Phong Sắc, Phường Dịch Vọng Hậu, Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam.",
    phone: "024.36330316",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty CP Giáo Dục Cánh Diều",
    address: "Số 50 Trung Kính, P. Trung Hòa, Q. Cầu Giấy, TP. Hà Nội",
    phone: "024.36656969",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Hà Nội",
    address:
      "Số 45B Lý Thường Kiệt, Phường Trần Hưng Đạo, Quận Hoàn Kiếm, Thành phố Hà Nội.",
    phone: "0243.9360231",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty TNHH Thương Mại Sách Nhân Dân",
    address:
      "Số nhà 868, Đường Phúc Diễn,  Phường Tây Mỗ,  Quận Nam Từ Liêm, Thành Phố Hà Nội, Việt Nam",
    phone: "0243.7960263",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Hà Tây",
    address:
      "Số 72 đường Bà Triệu, Phường Nguyễn Trãi, Quận Hà Đông, Thành phố Hà Nội.",
    phone: "024.33824603",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty Cổ Phần Sách và Văn Hóa Phẩm Quảng Lợi",
    address:
      "Số 3 phố Hàng Tre, Phường Lý Thái Tổ, Quận Hoàn Kiếm, Thành phố Hà Nội, Việt Nam.",
    phone: "024.39264199",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty TNHH Sách - Dịch Vụ Văn Hóa Trình Dậu",
    address:
      "Số 98 phố Lê Thanh Nghị - Phường Bách Khoa - Quận Hai Bà Trưng - Hà Nội.",
    phone: "0243.8680092",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty Cổ Phần Mĩ Thuật Và Truyền Thông",
    address: "187B Giảng Võ - Cát Linh - Đống Đa - Hà Nội.",
    phone: "024.73068789",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Công ty Cổ Phần Phát Hành Sách Giáo Dục",
    address:
      "Tầng M, Tòa 21B6-Green Stars- số 234 Phạm Văn Đồng, P. Cổ Nhuế, Q. Bắc Từ Liêm, TP Hà Nội",
    phone: "024.62657745",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Nhà sách giáo dục Bình Thủy",
    address:
      "Số 65 + 67 Nguyễn Khoái, Phường Bạch Đằng, Quận Hai Bà Trưng, Hà Nội",
    phone: "024.39844785",
  },
  {
    region: "Mien Bac",
    cityName: "Hanoi",
    name: "Nhà sách FAHASA Hà Nội",
    address: "Số 338 Xã Đàn, P. Phương Liên, Q. Đống Đa, TP. Hà Nội",
    phone: "024.39844785",
  },
  {
    region: "Mien Bac",
    cityName: "Bac Giang",
    name: "Công ty CP Sách Giáo Khoa Thiết Bị Trường Học Bắc Giang",
    address:
      "Số 50 đường Nguyễn Thị Lưu, Phường Ngô Quyền, Thành phố Bắc Giang, Tỉnh Bắc Giang, Việt Nam",
    phone: "0204.3858093",
  },
  {
    region: "Mien Bac",
    cityName: "Bac Kan",
    name: "Công ty CP Sách - Thiết Bị Trường Học Bắc Kạn",
    address: "Tổ 9B, Phường Đức Xuân, Thành phố Bắc Kạn, Tỉnh Bắc Kạn.",
    phone: "0209.3870457",
  },
  {
    region: "Mien Bac",
    cityName: "Bac Ninh",
    name: "Công ty CP Sách và Thiết Bị Trường Học Bắc Ninh",
    address:
      "Số 14 đường Nguyễn Đăng Đạo, Phường Tiền An, Thành phố Bắc Ninh, Tỉnh Bắc Ninh, Việt Nam",
    phone: "0222.3821614",
  },
  {
    region: "Mien Bac",
    cityName: "Cao Bang",
    name: "Công ty Cổ Phần Sách Và Thiết Bị Trường Học Cao Bằng",
    address: "Số 95 phố Xuân Trường, P. Hợp Giang, TP Cao Bằng, Tỉnh Cao Bằng.",
    phone: "0816660786",
  },
  {
    region: "Mien Bac",
    cityName: "Dien Bien Phu",
    name: "Công ty Cổ Phần Sách Và Thiết Bị Trường Học Điện Biên",
    address:
      "Số nhà 859, đường Võ Nguyên Giáp, Tổ dân phố 8, P. Mường Thanh, TP. Điện Biên Phủ, Điện Biên",
    phone: "0215.3824917",
  },
  {
    region: "Mien Bac",
    cityName: "Ha Giang",
    name: "Công ty Trách Nghiệm Hữu Hạn Một Thành Viên An Vinh",
    address:
      "Số 167B, đường Nguyễn Trãi, tổ 15 phường Nguyễn Trãi. TP Hà Giang, tỉnh Hà Giang, Việt Nam",
    phone: "024.37857948",
  },
  {
    region: "Mien Bac",
    cityName: "Ha Tinh",
    name: "Công ty Cổ Phần Sách - Thiết Bị Trường Học Hà Tĩnh",
    address:
      "Số 58, đường Phan Đình Phùng, Phường Nam Hà, Thành phố Hà Tĩnh, Hà Tĩnh",
    phone: "023.93859708",
  },
  {
    region: "Mien Bac",
    cityName: "Hai Duong",
    name: "Công ty Cổ Phần Sách và Thiết Bị Giáo Dục Hải Dương",
    address:
      "Số nhà 27, phố Minh Khai, Phường Trần Hưng Đạo, Thành phố Hải Dương, Tỉnh Hải Dương.",
    phone: "0220.3852392",
  },
  {
    region: "Mien Bac",
    cityName: "Haiphong",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Nam Cường",
    address:
      "Số 31 Phan Bội Châu - Phường Hoàng Văn Thụ - Quận Hồng Bàng - Hải Phòng.",
    phone: "022.53521999",
  },
  {
    region: "Mien Bac",
    cityName: "Hoa Binh",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Hòa Bình",
    address:
      "Đường Lê Thánh Tông - Phường Tân Thịnh - Thành phố Hoà Bình - Hòa Bình.",
    phone: "0218.221117",
  },
  {
    region: "Mien Bac",
    cityName: "Hung Yen",
    name: "Công ty Cổ Phần PHS và Thiết Bị Trường Học Hưng Yên",
    address:
      "Số 601, Đường Nguyễn Văn Linh, Phường An Tảo, Thành phố Hưng yên, Tỉnh Hưng Yên.",
    phone: "0221.3863892",
  },
  {
    region: "Mien Bac",
    cityName: "Lang Son",
    name: "Công ty TNHH MTV Phát Triểng Giáo Dục Lạng Sơn",
    address:
      "Số 04 Đinh Công Tráng, KĐT Phú Lộc 4, Phường Vĩnh Trại, Thành phố Lạng Sơn,  Tỉnh Lạng Sơn.",
    phone: "0845036338",
  },
  {
    region: "Mien Bac",
    cityName: "Lao Cai",
    name: "Công ty Cổ Phần Sách - Thiết Bị Trường Học Lào Cai",
    address:
      "Số nhà 435, đường Hoàng Liên và đường Nguyễn Du - Phường Kim Tân - TP Lào Cai - Lào Cai.",
    phone: "0214.3822155",
  },
  {
    region: "Mien Bac",
    cityName: "Nam Dinh",
    name: "Công ty Cổ Phần Sách và Thiết Bị Giáo Dục Nam Định",
    address: "Số 13 Minh Khai, Phường Nguyễn Du, Thành phố Nam Định, Nam Định",
    phone: "0228.3849498",
  },
  {
    region: "Mien Bac",
    cityName: "Nam Dinh",
    name: "Công ty CP Sách và Thiết Bị Giáo Dục Thiên Đường",
    address: "240 Lương Thế Vinh, P. Cửa Bắc, TP. Nam Định, tỉnh Nam Định",
    phone: "0228.3835327",
  },
  {
    region: "Mien Bac",
    cityName: "Vinh",
    name: "Công ty CP Sách và Phát Triển Giáo Dục Nghệ An",
    address:
      "Số nhà 257, đường Lê Duẩn, khối 13 - Phường Trung Đô - Thành phố Vinh - Nghệ An",
    phone: "0963555373",
  },
  {
    region: "Mien Bac",
    cityName: "Ninh Binh",
    name: "Công ty CP Sách và Thiết Bị Trường Học Ninh Bình",
    address:
      "39 đường Phạm Hồng Thái, phường Vân Giang, thành phố Ninh Bình, tỉnh Ninh Bình",
    phone: "0229.3871392",
  },
  {
    region: "Mien Bac",
    cityName: "Phu Tho",
    name: "Công ty Cổ Phần Sách và Thiết Bị Giáo Dục Phú Thọ",
    address:
      "Số 944, đường Hùng Vương, Phường Thọ Sơn, Thành phố Việt Trì, Tỉnh Phú Thọ",
    phone: "0210.3910424",
  },
  {
    region: "Mien Bac",
    cityName: "Cam Pha Mines",
    name: "Công ty Cổ Phần Sách Và Thiết Bị Trường Học Quảng Ninh",
    address:
      "Số 10, phố Long Tiên - Phường Bạch Đằng - Thành phố Hạ Long - Quảng Ninh.",
    phone: "0203.3816879",
  },
  {
    region: "Mien Bac",
    cityName: "Son La",
    name: "Công ty Cổ Phần Dịch Vụ Thương Mại Sách và Thiết Bị Tây Bắc",
    address:
      "Số nhà 03, đường Bản Hẹo, Tổ 03, Phường Tô Hiệu, Thành phố Sơn La, Tỉnh Sơn La, Việt Nam",
    phone: "0911216558",
  },
  {
    region: "Mien Bac",
    cityName: "Thai Binh",
    name: "Công ty Cổ Phần Sách Thiết Bị Trường Học Tỉnh Thái Bình",
    address:
      "Đường Đốc Nhưỡng - Phường Đề Thám - Thành phố Thái Bình - Thái Bình.",
    phone: "0227.3831546",
  },
  {
    region: "Mien Bac",
    cityName: "Thai Binh",
    name: "Công ty TNHH Thiết Bị Trường Học Ngọc Anh",
    address:
      "Số nhà 65, đường số 9, tổ 11, phường Trần Hưng Đạo, TP. Thái Bình",
    phone: "0227.3601678",
  },
  {
    region: "Mien Bac",
    cityName: "Thai Nguyen",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Thái Nguyên",
    address:
      "Số 136 Hoàng Văn Thụ, P.Hoàng Văn Thụ, TP.Thái Nguyên, Thái Nguyên.",
    phone: "0280.3858383",
  },
  {
    region: "Mien Bac",
    cityName: "Thanh Hoa",
    name: "Công ty Cổ Phần Sách và Thiết Bị Bông Sen",
    address:
      "SN 40/93 đường Lê Chủ, Phố Trung Sơn, Phường An Hưng, Thành phố Thanh Hóa, Thanh Hóa",
    phone: "02373.752.034",
  },
  {
    region: "Mien Bac",
    cityName: "Thanh Hoa",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Thanh Hóa",
    address:
      "Số 51 Đại Lộ Lê Lợi - Phường Tân Sơn - Thành phố Thanh Hoá - Tỉnh Thanh Hoá",
    phone: "0948839887",
  },
  {
    region: "Mien Bac",
    cityName: "Tuyen Quang",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Tuyên Quang",
    address:
      "Số nhà 74A, đường 17/8, tổ 10, Phường Minh Xuân, Thành Phố Tuyên Quang, Tỉnh Tuyên Quang.",
    phone: "0207.382611",
  },
  {
    region: "Mien Bac",
    cityName: "Vinh Yen",
    name: "Công ty Cổ Phần Sách - Thiết Bị Trường Học Vĩnh Phúc",
    address:
      "Số nhà 418, Đường Mê Linh - Phường Khai Quang - Thành phố Vĩnh Yên - Vĩnh Phúc",
    phone: "034.2882288",
  },
  {
    region: "Mien Bac",
    cityName: "Yen Bai",
    name: "Công ty Cổ Phần Sách - Thiết Bị Trường Học Yên Bái",
    address:
      "Số nhà 440, đường Điện Biên, Phường Yên Ninh, Thành phố Yên Bái, Tỉnh Yên Bái.",
    phone: "0216.3852473",
  },
  {
    region: "Mien Bac",
    cityName: "Ha Nam",
    name: "Công ty Cổ Phần Sách - Thiết Bị Trường Học Hà Nam",
    address: "Số 73 Đường Lê Lợi, Minh Khai, Phủ Lý, Hà Nam",
    phone: "0226 3852 850",
  },
  {
    region: "Mien Bac",
    cityName: "Lai Chau",
    name: "Công ty Cổ Phần Sách và Thiết Bị Trường Học Lai Châu",
    address:
      "Tổ 19, Phường Tân Phong, Thành phố Lai Châu, Tỉnh Lai Châu, Việt Nam",
    phone: "0213 3877 165",
  },

  // Mien Nam
  {
    region: "Mien Nam",
    cityName: "Ho Chi Minh City",
    name: "Công ty CP Đầu tư Xuất bản - Thiết bị Giáo dục Việt Nam Chi nhánh tại Thành phố Hồ Chí Minh",
    address: "Lầu 2, 79 Nguyễn Đình Chiểu, Phường Võ Thị Sáu, Quận 3, Tp HCM",
    phone: " 028 3636 9907",
  },
  {
    region: "Mien Nam",
    cityName: "Ho Chi Minh City",
    name: "Công ty Cổ Phần Phát Hành Sách Tp HCM – FAHASA",
    address: "Hệ thống nhà sách Fahasa khu vực Tp HCM. ",
    phone: "",
  },
  {
    region: "Mien Nam",
    cityName: "Ho Chi Minh City",
    name: "Công ty TNHH Thương mại & Dịch vụ Văn Hóa Hương Trang",
    address: "416 Nguyễn Thị Minh Khai, P.5, Q.3, Tp HCM",
    phone: "Chị Loan – 091 5816 809",
  },
  {
    region: "Mien Nam",
    cityName: "Ho Chi Minh City",
    name: "Công ty Cổ Phần Sách và Thiết bị Trường học TP. Hồ Chí Minh",
    address: "223 Nguyễn Tri Phương, Phường 9, Quận 5, Thành phố Hồ Chí Minh",
    phone: "(028) 3 8 554 645",
  },
  {
    region: "Mien Nam",
    cityName: "Ho Chi Minh City",
    name: "Công ty Cổ phần Sách Giáo Dục Bình Minh",
    address: "Số 73 Hoàng Trọng Mậu, P. Tân Hưng, Q. 7, Tp HCM",
    phone: "Bà Phan Thị Hoa –  090 3147 970",
  },
  {
    region: "Mien Nam",
    cityName: "Thu Dau Mot",
    name: "Công ty Cổ phần Sách - Thiết bị Giáo dục Bình Dương",
    address:
      "Số 88 đường Trần Bình Trọng, Phường Phú Thọ, Thành phố Thủ Dầu Một, tỉnh Bình Dương",
    phone: "(0274) 3 686 979",
  },
  {
    region: "Mien Nam",
    cityName: "Baria Vung Tau",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Bà Rịa - Vũng Tàu",
    address:
      "237 Lê Lợi, Phường Thắng Nhì, Thành Phố Vũng Tàu, Tỉnh Bà Rịa - Vũng Tàu",
    phone: "(0254) 3 856 161",
  },
  {
    region: "Mien Nam",
    cityName: "Phu Khuong",
    name: "Công ty TNHH MTV Hỗ trợ và Phát triển Giáo dục Tây Ninh",
    address: "54/7 Khu phố 4, Phường Long Hoa, Thị xã Hòa Thành, Tỉnh Tây Ninh",
    phone: "(0276) 3 533 353",
  },
  {
    region: "Mien Nam",
    cityName: "Bien Hoa",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Đồng Nai",
    address:
      "10 Võ Thị Sáu, Phường Quyết Thắng, Thành phố Biên Hòa, tỉnh Đồng Nai",
    phone: "(0251) 3 840 606",
  },
  {
    region: "Mien Nam",
    cityName: "Can Duoc",
    name: "Công ty Cổ phần Sách và Thiết bị Trường học Long An",
    address: "39 Hai Bà Trưng - Phường 1 - TP.Tân An - Long An",
    phone: "(0272) 3 825 094",
  },
  {
    region: "Mien Nam",
    cityName: "My Tho",
    name: "Công Ty Cổ Phần Sách và Thiết Bị Trường Học Tiền Giang",
    address:
      "04 Lê Văn Duyệt, Phường 1, Thành phố Mỹ Tho, Tỉnh Tiền Giang, Việt Nam",
    phone: "(0273) 3 898 989",
  },
  {
    region: "Mien Nam",
    cityName: "Sa Dec",
    name: "Công ty Cổ phần Sách và Thiết bị Đồng Tháp",
    address:
      "375A Nguyễn Sinh Sắc, Khóm 2, Phường 2, Thành Phố Sa Đéc, Tỉnh Đồng Tháp",
    phone: "(0277) 3 861 802",
  },
  {
    region: "Mien Nam",
    cityName: "Chau Doc",
    name: "Công ty Cổ Phần Sách-Thiết Bị Giáo Dục An Giang",
    address: "21 Đoàn Văn Phối, TP. Long Xuyên, An Giang",
    phone: "(0296) 3 944 206",
  },
  {
    region: "Mien Nam",
    cityName: "Vinh Long",
    name: "Công ty cổ phần Sách-Thiết bị Vĩnh Long",
    address: "Số 23 Lê Văn Tám, Phường 1, Thành phố Vĩnh Long, Tỉnh Vĩnh Long",
    phone: "(0270) 3 822 373",
  },
  {
    region: "Mien Nam",
    cityName: "Ben Tre",
    name: "Công ty Cổ phần Sách - Thiết bị Bến Tre",
    address:
      "450E Ấp Hữu Nhơn, Xã Hữu Định, Huyện Châu Thành, Tỉnh Bến Tre, Việt Nam",
    phone: "(0275) 3 812 863",
  },
  {
    region: "Mien Nam",
    cityName: "Tra Vinh",
    name: "Công ty Cổ Phần Sách-Thiết Bị Trường Học Trà Vinh",
    address: "03A Trưng Nữ Vương, Phường 1, TP Trà Vinh",
    phone: "(0294) 3 862 278",
  },
  {
    region: "Mien Nam",
    cityName: "Soc Trang",
    name: "Công ty Cổ Phần Sách và Thiết Bị Sóc Trăng",
    address: "49 Lê Duẩn, Khóm 4, Phường 3, TP Sóc Trăng",
    phone: "(0299) 3 820 093",
  },
  {
    region: "Mien Nam",
    cityName: "Kien Giang",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Kiên Giang",
    address:
      "Lô E 16, Số 30-31-32 Đường 3/2, P. Vĩnh Lạc, TP. Rạch Gía, tỉnh Kiên Giang",
    phone: "(0297) 3 866 547",
  },
  {
    region: "Mien Nam",
    cityName: "Can Tho",
    name: "Công ty TNHH hỗ trợ phát triển giáo dục Cửu Long",
    address: "79/2C Khu vực 7, phường An Bình, quận Ninh Kiều, TP.Cần Thơ",
    phone: "0989 764 748",
  },
  {
    region: "Mien Nam",
    cityName: "Can Tho",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Thành phố Cần Thơ",
    address:
      "179B/7 Võ Văn Kiệt, KV Bình Nhựt, P.Long Hòa, Q.Bình Thủy, TP.Cần Thơ",
    phone: "(0292) 3 839 074",
  },

  // Miền trung tây nguyên
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Da Nang",
    name: "Công ty CP Đầu tư Xuất bản - Thiết bị Giáo dục Việt Nam (VEPIC) - Chi nhánh tại Đà Nẵng",
    address: "43 - 45 Nguyễn Văn Linh, Quận Hải Châu, Tp. Đà Nẵng",
    phone: "0985000306 hoặc 0935731567",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Da Nang",
    name: "Công ty CP Sách và Thiết bị Trường học Phúc An",
    address: "46/2 Lê Đình Dương, Quận Hải Châu, Tp. Đà Nẵng",
    phone: "0905887575",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Da Nang",
    name: "Công ty CP Sách và Thiết bị Trường học Đà Nẵng",
    address: "78 Bạch Đằng - Quận Hải Châu - TP Đà Nẵng",
    phone: "0903540044",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Dong Hoi",
    name: "Công ty CP Sách và Thiết bị Giáo dục Quảng Bình",
    address: "139 Hùng Vương, TT Hoàn Lão, Bố Trạch, Quảng Bình",
    phone: "0944230691",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Quang Tri",
    name: "Công ty CP Sách và Thiết bị Trường học Quảng Trị",
    address: "51 Lê Lợi, Phường 5, TP.Đông Hà, Tỉnh Quảng Trị",
    phone: "0888 308 345",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Hue",
    name: "Công ty CP Sách và Thiết bị Trường học Thừa Thiên Huế",
    address:
      "49 Phạm Văn Đồng, Phường Vỹ Dạ, TP. Huế, Tỉnh Thừa Thiên Huế 01 Phùng Hưng, Phường Thuận Hòa, TP. Huế",
    phone: "0945 945 595",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Hue",
    name: "Công ty TNHH Sách và Thiết bị Giáo dục Phước Lộc",
    address: "Thôn 3, Xã Quảng Công, Huyện Quang Điền, Tỉnh Thừa Thiên Huế",
    phone: "0935 068 388",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Gia Lai",
    name: "Công ty CP Sách và Thiết bị Trường học Gia Lai",
    address: "40B Hùng Vương, Phường IaKring, Tp. Pleiku, Tỉnh Gia Lai",
    phone: "0945 945 595",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Kon Tum",
    name: "Công ty CP Sách và Thiết bị Trường học Kon Tum",
    address: "706 Phan Đình Phùng, P. Quang Trung, TP. Kon Tum, Tỉnh Kon Tum",
    phone: "0931 600 279",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Binh Dinh",
    name: "CN Công ty CP Văn Hóa Giáo Dục Gia Lai tại Bình Định",
    address: "7 Lê Duẩn, phường Lý Thường Kiệt, TP Quy Nhơn, Tỉnh Bình Định",
    phone: "0905 467 222",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Quang Ngai",
    name: "Công ty CP Sách - Thiết bị Quảng Ngãi",
    address: "939 Quang Trung, Phường Nghĩa Chánh, TP Quảng Ngãi, Tỉnh Quảng Ngãi",
    phone: "0963 455 399",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Quang Nam",
    name: "Công ty TNHH Song Tỵ",
    address: "Tổ 7, KP Long Xuyên 2, TT Nam Phước , huyện Duy Xuyên, tỉnh Quảng Nam",
    phone: "0913 191 857",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Phu Yen",
    name: "Công ty Cổ phần Sách và Thiết bị Trường học Phú Yên",
    address: "14 Trần Phú, Phường 7, Thành Phố Tuy Hòa- tỉnh Phú yên",
    phone: "(0257) 3 841 403",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Lam Dong",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Lâm Đồng",
    address: "18 Nguyễn Văn Cừ, Phường1, Thành phố Đà Lạt, Tỉnh Lâm Đồng",
    phone: "(0263) 3 822 085",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Dak Lak",
    name: "Công ty Cổ phần Sách - Thiết bị Trường học Đắk Lắk",
    address:
      "19 Trường Chinh, Phường Tân Lợi. Thành phố Buôn Ma Thuột, tỉnh Đắc Lắc",
    phone: "(0262) 3 850 306",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Dak Nong",
    name: "Công ty Cổ phần Sách và Thiết bị Trường học Đắk Nông",
    address:
      "Số 58, đường Quang Trung, tổ dân phố 2, Phường Nghĩa Tân, Thị xã Gia Nghĩa, Tỉnh Đắk Nông, Việt Nam",
    phone: "(0261) 3 555 779",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Nha Trang",
    name: "Công ty Cổ phần Sách và Thiết bị Trường học Khánh Hòa",
    address: "105 Thống Nhất, Phường Vạn Thắng, TP. Nha Trang, Khánh Hòa.",
    phone: "(0258) 3 813 579",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Ninh Thuan",
    name: "Công ty Cổ phần Sách và Thiết bị Trường học Ninh Thuận",
    address:
      "Số 251A Thống Nhất, Phường Thanh Sơn, TP. Phan Rang Tháp Chàm, Tỉnh Ninh Thuận",
    phone: "(0259) 3 831 363",
  },
  {
    region: "Mien Trung Tay Nguyen",
    cityName: "Phan Thiet",
    name: "Công ty Cổ phần Sách - Thiết bị Bình Thuận",
    address:
      "70 Nguyễn Văn Trỗi, P.Đức Thắng, TP.Phan Thiết, Tỉnh Bình Thuận, Việt Nam",
    phone: "(0252) 3 816 091",
  },
];

export const ONLINE_SHOPS = [
  {
    cityName: "Internet",
    name: "Tìm mua trên các trang thương mại điện tử như Tiki, Fahasa.",
    address: "Internet",
    contact: "Gõ từ khoá: “Sách Cánh Diều”, “Cánh Diều”",
  },
  {
    cityName: "Mạng xã hội",
    name: "Đặt mua trên nền tảng mạng xã hội",
    address: "Fanpage Facebook Cánh Diều – Miền Nam: https://bit.ly/3axkm1R",
    contact: "Chị Hồng Ý – 093 8489 768",
  },
];

export const OPTION_ALL = { label: "Tất cả", value: "" };
