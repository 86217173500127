import React, { useState, memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import AlertNewPractice from "edu_lms_v2/components/AlertNewPractice";
import { READING_BOOK_ENTRY_SOURCE } from "edu_lms/constants/book";

function ExerciseFooter({
  titleBook = "",
  enough_data,
  page_primary,
  title,
  doingExercise,
  hasPracticeHistory,
}) {
  const alertNewPracticeActive = useRef(true);
  const entrySource = useSelector(
    (state) => state.readingBookReducers.entrySource
  );
  const [openAlertNewPractice, setOpenAlertNewPractice] = useState(false);

  useEffect(() => {
    setOpenAlertNewPractice(
      Boolean(
        enough_data && entrySource === READING_BOOK_ENTRY_SOURCE.RECENT_BOOK
      )
    );
  }, [entrySource, enough_data, titleBook]);

  let buttonLabel = "Đang cập nhật";
  if (enough_data) {
    buttonLabel = "Luyện tập ngay";
  }
  if (hasPracticeHistory) {
    buttonLabel = "Luyện tập thêm";
  }

  return (
    <div className="exercise">
      {page_primary && (
        <>
          {/* <div
            className={classNames("btn-exercises-wrapper", {
              isAnimation: enough_data,
            })}
          >
            <button
              onClick={doingExercise}
              className="btn-pr btn-exercises w-100 text-center"
              disabled={!enough_data}
            >
              {buttonLabel}
            </button>
            {enough_data && (
              <img
                src="/assets/img/icon-new.svg"
                alt="img-new"
                className="position-absolute icon-new"
              />
            )}
          </div> */}
          {/*<AlertNewPractice*/}
          {/*  show={openAlertNewPractice && alertNewPracticeActive.current}*/}
          {/*  onHide={() => {*/}
          {/*    setOpenAlertNewPractice(false);*/}
          {/*    alertNewPracticeActive.current = false;*/}
          {/*  }}*/}
          {/*  onClick={() => {*/}
          {/*    doingExercise();*/}
          {/*    setOpenAlertNewPractice(false);*/}
          {/*  }}*/}
          {/*  buttonLabel={buttonLabel}*/}
          {/*/>*/}
        </>
      )}
      <div className="exercise-container position-relative">
        <img
          src="/assets/img/icon-reading-book-1.svg"
          alt="icon-reading-book-1"
          className="icon-1 position-absolute"
        />
        <img
          src="/assets/img/icon-reading-book-2.svg"
          alt="icon-reading-book-2"
          className="icon-2 position-absolute"
        />
        {titleBook && (
          <p className="pt-5 monkey-fz-16 text-white subject-name m-1">
            {titleBook}
          </p>
        )}
        {title && <p className="titleExercise text-white">{title}</p>}
        <div className="exercise-bg" />
      </div>
    </div>
  );
}

export default memo(ExerciseFooter);
