import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import * as yup from "yup";
import styles from "../../teachingManager.module.scss";
import styled from "styled-components";
import { toast } from "react-toastify";
import { postCreateUserClassroom } from "edu_lms/services/teachingManager";

const schema = yup
  .object()
  .shape({
    email_teacher: yup.string().required("Vui lòng nhập email"),
  })
  .required();
const schemaFormStudent = yup
  .object()
  .shape({
    email_student: yup.string().required("Vui lòng nhập email"),
  })
  .required();

const Form = styled.form`
  & input {
    height: 36px;
  }
`;

export default function PopupAddMember({
  show,
  setShow,
  idClassroom,
  getListClassroom,
}) {
  const [arrEmailTeacher, setArrEmailTeacher] = useState([]);
  const [arrEmailStudent, setArrEmailStudent] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const [isEmailTeacherDuplicated, setIsEmailTeacherDuplicated] =
    useState(false);
  const [isEmailStudentDuplicated, setIsEmailStudentDuplicated] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    register: registerStudent,
    handleSubmit: handleSubmitStudent,
    errors: errorsStudent,
    reset: resetStudent,
  } = useForm({
    resolver: yupResolver(schemaFormStudent),
  });

  const onHandleSubmitTeacher = (dataForm) => {
    setErrorMessage("");
    setIsEmailTeacherDuplicated(false);
    const email = dataForm.email_teacher;
    if (arrEmailTeacher.includes(email)) {
      setIsEmailTeacherDuplicated(true);
    } else {
      setArrEmailTeacher((arr) => [...arr, email]);
      reset();
    }
  };

  const onHandleSubmitStudent = (dataForm) => {
    setErrorMessage("");
    setIsEmailStudentDuplicated(false);
    const email = dataForm.email_student;
    if (arrEmailStudent.includes(email)) {
      setIsEmailStudentDuplicated(true);
    } else {
      setArrEmailStudent((arr) => [...arr, email]);
      resetStudent();
    }
  };

  const onRemoveEmailTeacher = (index) => {
    setArrEmailTeacher((arr) => arr.filter((_, i) => i !== index));
  };

  const onRemoveEmailStudent = (index) => {
    setArrEmailStudent((arr) => arr.filter((_, i) => i !== index));
  };

  const onHandleAddMember = () => {
    const data = {
      class_room_id: idClassroom,
      list_teacher: JSON.stringify(arrEmailTeacher),
      list_student: JSON.stringify(arrEmailStudent),
    };
    if (arrEmailTeacher.length > 0 || arrEmailStudent.length > 0) {
      postCreateUserClassroom(data).then((res) => {
        if (res.data.status === "success") {
          toast.success("Thêm thành viên thành công!");
          onHandleClose();
          getListClassroom();
        } else {
          toast.error("Thêm thành viên thất bại!");
          setErrorMessage(res.data.message);
        }
      });
    } else {
      setErrorMessage("Vui lòng thêm ít nhất một người dùng");
    }
  };

  const onHandleClose = () => {
    setArrEmailStudent([]);
    setArrEmailTeacher([]);
    setErrorMessage("");
    setShow(false);
    setIsEmailTeacherDuplicated(false);
    setIsEmailStudentDuplicated(false);
    reset();
    resetStudent();
  };

  return (
    <Modal
      show={show}
      onHide={onHandleClose}
      centered
      contentClassName={styles.modalDialog}
      backdrop="static"
    >
      <Modal.Header closeButton className="text-center">
        <p className="w-100 text-fsize font-weight-bold">Thêm thành viên</p>
      </Modal.Header>

      <Modal.Body className="p-4">
        <p className="text-center">
          Nhập email của giáo viên và học sinh trong lớp để lập tức thêm thành
          viên, hoặc chọn Bỏ qua để đến Bảng tin lớp học
        </p>
        <div className="row mt-3">
          {/* <div className="col-6">
            <Form onSubmit={handleSubmit(onHandleSubmitTeacher)}>
              <div className="form-group">
                <label className={`font-weight-bold ${styles.colorOrange}`}>
                  Email của giáo viên ({arrEmailTeacher.length})
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email_teacher"
                  ref={register}
                  placeholder=""
                  onChange={() => setIsEmailTeacherDuplicated(false)}
                />
                {(errors.email_teacher && (
                  <p className="text-danger">{errors.email_teacher?.message}</p>
                )) ||
                  (isEmailTeacherDuplicated && (
                    <p className="text-danger">Email đã tồn tại</p>
                  ))}
              </div>
            </Form>
            <div
              className={`d-flex justify-content-between flex-wrap overflow-auto ${styles.listEmail}`}
            >
              {arrEmailTeacher.map((email, index) => (
                <div
                  className={`d-flex justify-content-between ${styles.emailItem}`}
                  key={index}
                >
                  <p className="text-truncate">{email}</p>
                  <button onClick={() => onRemoveEmailTeacher(index)}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          </div> */}
          <div className="col-6">
            <Form onSubmit={handleSubmitStudent(onHandleSubmitStudent)}>
              <div className="form-group">
                <label className={`font-weight-bold ${styles.colorOrange}`}>
                  Email của học sinh ({arrEmailStudent.length})
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email_student"
                  ref={registerStudent}
                  placeholder=""
                  onChange={() => setIsEmailStudentDuplicated(false)}
                />
                {(errorsStudent.email_student && (
                  <p className="text-danger">
                    {errorsStudent.email_student?.message}
                  </p>
                )) ||
                  (isEmailStudentDuplicated && (
                    <p className="text-danger">Email đã tồn tại</p>
                  ))}
              </div>
            </Form>
            <div
              className={`d-flex justify-content-between flex-wrap overflow-auto ${styles.listEmail}`}
            >
              {arrEmailStudent.map((email, index) => (
                <div
                  className={`d-flex justify-content-between mb-2 ${styles.emailItem}`}
                  key={index}
                >
                  <p className="text-truncate">{email}</p>
                  <button onClick={() => onRemoveEmailStudent(index)}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="text-center text-danger">{errorMessage}</div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between py-3">
        <a href="#" className="monkey-color-orange">
          Bỏ qua
        </a>
        <button
          type="button"
          onClick={() => onHandleAddMember()}
          className={styles.buttonOrange}
        >
          Thêm
        </button>
      </Modal.Footer>
      {/* </Form> */}
    </Modal>
  );
}
