import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";
import { SLUG_KNOWLEDGE } from "../constant";

export default function RelatedPosts({ detailItem }) {
  const isPostNewsReference = !_.isEmpty(detailItem?.reference_post);
  return (
    <div className={styles.realtedContainer}>
      {isPostNewsReference && (
        <>
          <p className={styles.postNewsTitle}>Bài viết Liên quan</p>
          <hr />
          <div className={styles.postNewsTitle}>
            {detailItem?.reference_post.map((item, index) => {
              const routerItem =
                !item.slug === SLUG_KNOWLEDGE.other
                  ? `${PATH.ROUTE_BLOG_KNOWLEDGE}${item?.level?.slug}/${item?.grade?.slug}/${item?.alias}}/`
                  : `${PATH.ROUTE_BLOG_KNOWLEDGE_OTHER}${item.alias}/`;
              return (
                <Link key={index} to={routerItem}>
                  <p className={`${styles.relatedPostsTile} cursor`}>
                    {item?.title}
                  </p>
                  <hr />
                </Link>
              );
            })}
          </div>
        </>
      )}
      <div className={styles.register}>
        <img
          src="/assets/img/bg-register-block.svg"
          alt="img-bg"
          className={styles.registerBackground}
        />
        <div className={`${styles.header} ${!isPostNewsReference && "pt-0"}`}>
          <p className={styles.headerPostNews}>
            Đăng ký trải nghiệm đầy đủ tính năng Hoc10
            <img
              src="/assets/img/bg-register-block-1.svg"
              alt="icon-bg-block"
            />
          </p>
          <input placeholder="Nhập địa chỉ email" />
          <button type="submit" className={styles.buttonSubmit}>
            Đăng ký ngay
          </button>
        </div>
      </div>
    </div>
  );
}
