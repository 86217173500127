import React, { useState } from "react";
import { Modal, Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OPTIONS_GRADE, COLOR_WHITE } from "edu_lms/constants/type";
import { getSchoolLevelId } from "./constant";
import { getSubjects } from "edu_lms_v2/services/listBook";

const schema = yup
  .object()
  .shape({
    name_question_set: yup
      .string()
      .required("Vui lòng nhập tên bộ câu hỏi")
      .max(100, "Tên bộ câu hỏi không quá 100 ký tự"),
    selectGrade: yup.object().required("Vui lòng chọn lớp học"),
    selectSubject: yup.object().required("Vui lòng chọn môn học").nullable(),
  })
  .required();

export default function CreateQuestion(props) {
  const [messageError] = useState("");

  const [optionsGrade] = useState(OPTIONS_GRADE);
  const [optionsSubject, setStateOptionsSubject] = useState([]);
  const [isErrorTitle, setIsErrorTitle] = useState(false);
  const [gradeId, setGradeId] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const title = data.name_question_set.trim();
    if (title) {
      const dataCreateQuestionSet = {
        title: title,
        grade_id: data.selectGrade.id,
        subject_id: data.selectSubject.id,
      };

      props.onSaveQuestionSet(dataCreateQuestionSet);
    } else {
      setIsErrorTitle(true);
      setValue("name_question_set", "");
    }
  };

  const handleChangeGrade = (selectedOptionGrade) => {
    setStateOptionsSubject([]);
    setValue("selectSubject", "");
    const schoolId = getSchoolLevelId(selectedOptionGrade.id);
    setGradeId(selectedOptionGrade.id);

    getSubjects(schoolId)
      .then((res) => {
        if (res.data.code === 200) {
          const listSubject = res.data.data.list_subject.map((subject) => {
            return {
              id: subject.id,
              label: subject.title,
              value: `file_${subject.id}`,
              type: subject.id,
            };
          });

          setStateOptionsSubject(listSubject);
        }
      })
      .catch((error) => console.log(error));
  };

  const onClosePopup = () => {
    props.onHide();
    setStateOptionsSubject([]);
  };

  return (
    <>
      <Modal {...props} animation={false} onHide={onClosePopup}>
        <Modal.Header closeButton className="">
          <Modal.Title className="text-center w-100 monkey-fz-18 monkey-f-header">
            Tạo bộ câu hỏi mới
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="p-3 pr-4">
            <Form.Group as={Row}>
              <Col sm="12" className="d-flex mb-3">
                <label className="p-2 col-2">
                  Tên<span className="text-danger">*</span>
                </label>
                <div className="col-10">
                  <input
                    id="name_question_set"
                    name="name_question_set"
                    type="text"
                    ref={register}
                    className="form-control w-100"
                    placeholder="Nhập tên bộ câu hỏi"
                    onChange={() => setIsErrorTitle(false)}
                  />
                  {(errors.name_question_set && (
                    <p className="text-danger mt-1 p-1">
                      {errors.name_question_set.message}
                    </p>
                  )) ||
                    (isErrorTitle && (
                      <p className="text-danger mt-1 p-1">
                        Tên bộ câu hỏi không hợp lệ
                      </p>
                    ))}
                </div>
              </Col>
              <Col sm="12" className="mb-3">
                <div className="d-flex bd-highlight">
                  <label htmlFor="" className="p-2 col-2">
                    Lớp<span className="text-danger">*</span>
                  </label>
                  <div className="flex-grow-1 col-10">
                    <Controller
                      render={({ onChange, value }) => (
                        <Select
                          placeholder="Chọn lớp"
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            handleChangeGrade(e);
                          }}
                          options={optionsGrade}
                        />
                      )}
                      name="selectGrade"
                      control={control}
                    />
                    {errors.selectGrade && (
                      <p className="text-danger p-1">
                        {errors.selectGrade.message}
                      </p>
                    )}
                  </div>
                </div>
              </Col>

              <Col sm="12" className=" mb-3">
                <div className="d-flex bd-hightlight">
                  <label htmlFor="" className="p-2 col-2">
                    Môn<span className="text-danger">*</span>
                  </label>
                  <div className="col-10">
                    <Controller
                      render={({ onChange, value }) => (
                        <Select
                          placeholder="Chọn môn"
                          value={value}
                          onChange={(e) => onChange(e)}
                          options={optionsSubject}
                        />
                      )}
                      name="selectSubject"
                      control={control}
                    />
                    {errors.selectSubject && (
                      <p className="text-danger p-1">
                        {errors.selectSubject.message}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              {messageError && (
                <PError className="quicksand-medium">{messageError}</PError>
              )}
              <Col sm="12">
                <Button
                  type="submit"
                  className="monkey-bg-orange text-white p-2 w-100 font-weight-bold monkey-fz-18"
                >
                  Tạo
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Button = styled.button`
  border-radius: 8px;
  &:hover {
    opacity: 0.8;
  }
`;

const PError = styled.p`
  text-align: center;
  color: red;
  font-size: 18px;
`;
