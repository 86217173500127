import React from "react";
import styled from "styled-components";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import * as TEXT from "../../constants/text";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import popupBuyBook from "./ContentPopupBuyBook.module.css";
import error_img from "edu_lms_v2/assets/img/img-error.svg";
import { ROUTE_PATH_SIGN_IN } from "../../../consts/routePaths";

export const Div = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentPopupBuyBook = (props) => {
  const isBookEnglish = useSelector((state) => state.app.isBookEnglish);
  const history = useHistory();
  const onToggle = (url) => {
    props.onDispatchShowPopupActivateBook(false);
    if (url) {
      history.push(url);
    }
  };

  return (
    <div
      className={`content_popup_success monkey-pt-35 text-center ${popupBuyBook.textLeftv1}`}
    >
      <div className={popupBuyBook.buttonClose}>
        <button
          className="monkey-color-black rounded-pill"
          onClick={() => onToggle()}
        >
          <span aria-hidden="true" style={{ fontSize: "2.5rem" }}>
            ×
          </span>
        </button>
      </div>
      <div className="img-wrapper">
        <img src={error_img} alt="" style={{ margin: "40px auto" }} />
      </div>
      <div className={popupBuyBook.textWarning}>
        {!isBookEnglish
          ? "Vui lòng đăng nhập để xem phiên bản đầy đủ của sách điện tử Cánh Diều"
          : "Vui lòng đăng nhập và kích hoạt sách để xem phiên bản đầy đủ của sách điện tử Cánh Diều."}
      </div>
      <button
        onClick={() => onToggle(ROUTE_PATH_SIGN_IN)}
        className={`btn-pr flex-center btn-access mr-2 ml-2 ${popupBuyBook.buttonLogin}`}
      >
        Đăng nhập
      </button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ContentPopupBuyBook);
