import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import imgPopup from "edu_lms_v2/assets/img/banner_luyen_tap.png";

const AlertNewPractice = ({ show, onHide, onClick, buttonLabel }) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleHideModal = () => {
    setIsClosed(true);
    onHide();
  };

  return (
    <ModalView
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`custom-modal ${isClosed ? "closed" : ""}`}
      onHide={handleHideModal}
    >
      <i
        class="fa fa-times position-absolute cursor"
        aria-hidden="true"
        style={{ zIndex: 10, top: "2%", right: "2%", fontSize: "22px" }}
        onClick={handleHideModal}
      />
      <Modal.Body className="p-0">
        <img src={imgPopup} />
        {/*<ButtonPractice*/}
        {/*  className="btn-pr flex-center position-absolute"*/}
        {/*  variant="outline-none"*/}
        {/*  onClick={onClick}*/}
        {/*>*/}
        {/*  {buttonLabel}*/}
        {/*</ButtonPractice>*/}
      </Modal.Body>
    </ModalView>
  );
};

const ButtonPractice = styled(Button)`
  background: #ff7707;
  top: 85%;
  left: 40%;
  font-size: 18px;

  @media screen and (max-width: 820px) {
    top: 80%;
    left: 35%;
    font-size: 14px;
  }
`;

const ModalView = styled(Modal)`
  transition: all 2s ease;
  transform-origin: bottom left;
  // transform: scale(1);

  &.closed {
    transform: scale(0.1) translate(-1000px, -1000px);
    opacity: 0;
    pointer-events: none;
  }
`;

export default AlertNewPractice;
