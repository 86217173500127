import _ from "lodash";
import ResultBook from "./ResultBook";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY } from "consts/book";

export default function SectionKeHoachBaiDay() {
  const documents = useBookStore((state) => state.documents);
  const subjectId = useAppStore((state) => state.subjectId);
  const gradeId = useAppStore((state) => state.gradeId);
  const documentsOfCurrentGrade = _.get(documents, [gradeId], []);

  const tlbtDocuments = documentsOfCurrentGrade.filter(
    (item) =>
      item.category === DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY &&
      item.subject_id === subjectId
  );

  return (
    !_.isEmpty(tlbtDocuments) && (
      <div className="col-md-12 result">
        <ResultBook
          data={tlbtDocuments}
          bookTypeId={DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY}
          categoryBook="Kế hoạch bài dạy (CV 5512)"
          col="col-6 col-lg-3"
          background="#fff"
        />
      </div>
    )
  );
}
