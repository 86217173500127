import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import { TYPE_GAME } from "edu_lms_v2/libraries/hoc10Game/constants";
import { NUMBER_OF_ACTIVITIES, ENV_MODES, FILTER_AREAS, VIEW_MODES } from "./constants";
import SelectBookForm from "./components/SelectBookForm";

const FilterGame = ({ modeView, onChangeModeView, onSearchActivities }) => {
  const [filterGame, setFilterGame] = useState({
    gameId: [],
    numberOfActs: 1,
    name: "",
    isPublic: false,
    envMode: ENV_MODES.Live,
    area: FILTER_AREAS[0].value,
    bookId: null,
  });

  const handleChangeGame = (e) => {
    setFilterGame({ ...filterGame, gameId: e });
  };

  const handleChangeAmountActs = (e) => {
    setFilterGame({ ...filterGame, numberOfActs: e.value });
  };

  const handleChangeCheckPublicAct = (e) => {
    setFilterGame({ ...filterGame, isPublic: e.target.checked });
  };

  const handleChangeInputName = (e) => {
    setFilterGame({ ...filterGame, name: e.target.value });
  };

  const handleChangeCheckEnvMode = (e) => {
    setFilterGame({ ...filterGame, envMode: e.target.id });
  };

  const handleChangeFilterArea = (e) => {
    setFilterGame({ ...filterGame, area: e.target.id });
  };

  const handleSubmitFilterGame = (e) => {
    e.preventDefault();
    onSearchActivities(filterGame);
  };

  const handleChangeModeView = (e) => {
    onChangeModeView(e.target.id);
  };

  const GAMES = Object.keys(TYPE_GAME).map((gameName) => ({
    label: gameName,
    value: TYPE_GAME[gameName],
  }));

  return (
    <Form onSubmit={handleSubmitFilterGame} className="filter-game">
      {/* ------------------------------------------------------------------------------------ */}
      <div className="wrapper-item">
        <Form.Group className="item">
          <Form.Label>Type game</Form.Label>
          <Select
            isClearable
            isMulti
            className="select-game"
            placeholder="Select game"
            options={GAMES}
            value={filterGame.gameId}
            onChange={handleChangeGame}
          />
        </Form.Group>
        <Form.Group className="item">
          <Form.Label>Number of activities</Form.Label>
          <Select
            className="select-amount-acts"
            defaultValue={NUMBER_OF_ACTIVITIES[0]}
            options={NUMBER_OF_ACTIVITIES}
            value={NUMBER_OF_ACTIVITIES.find(
              (number) => number.value === filterGame.numberOfActs
            )}
            onChange={handleChangeAmountActs}
          />
        </Form.Group>
      </div>
      {/* ------------------------------------------------------------------------------------ */}
      <div className="wrapper-item">
        <Form.Group className="item">
          <Form.Label>Name activities</Form.Label>
          <Form.Control
            className="input-name"
            placeholder="Input name of activities"
            onChange={handleChangeInputName}
          />
        </Form.Group>
        <Form.Group className="item">
          <Form.Check
            className="is-public"
            type="checkbox"
            label="Public activities"
            id="public"
            checked={filterGame.isPublic}
            onChange={handleChangeCheckPublicAct}
          />
        </Form.Group>
        <Form.Group className="item">
          {Object.values(ENV_MODES).map((envMode) => (
            <Form.Check
              inline
              key={envMode}
              label={envMode}
              id={envMode}
              checked={filterGame.envMode === envMode}
              name="envMode"
              type="radio"
              onChange={handleChangeCheckEnvMode}
            />
          ))}
        </Form.Group>
      </div>
      {/* ------------------------------------------------------------------------------------ */}
      <Form.Group className="item">
        {FILTER_AREAS.map((area) => (
          <Form.Check
            inline
            key={area.value}
            label={area.label}
            id={area.value}
            checked={filterGame.area === area.value}
            name="filterArea"
            type="radio"
            onChange={handleChangeFilterArea}
          />
        ))}
      </Form.Group>
      {/* ------------------------------------------------------------------------------------ */}
      {filterGame.area === "Book" && (
        <SelectBookForm
          bookId={filterGame.bookId}
          onSelectBookId={(bookId) => {
            setFilterGame({ ...filterGame, bookId });
          }}
        />
      )}
      {/* ------------------------------------------------------------------------------------ */}
      <Button variant="primary" type="submit">
        Search
      </Button>
      {/* ------------------------------------------------------------------------------------ */}
      <Form.Group className="item">
        <Form.Label>Mode view</Form.Label>
        {VIEW_MODES.map((mode) => (
          <Form.Check
            inline
            key={mode.value}
            label={mode.label}
            id={mode.value}
            checked={modeView === mode.value}
            name="modeView"
            type="radio"
            onChange={handleChangeModeView}
          />
        ))}
        <span className="mode-view__note">(Chỉ thay đổi độ rộng của phần hiển thị game)</span>
      </Form.Group>
      {/* ------------------------------------------------------------------------------------ */}
    </Form>
  );
};

export default FilterGame;
