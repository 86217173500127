import Modal from "react-bootstrap/Modal";
import danger_img from "edu_lms_v2/assets/img/img-error.svg";
import styled from "styled-components";

export default function PopupInformationRequired({ show, onHide }) {
  const handleClosePopup = () => {
    onHide();
  };
  return (
    <>
      <Modal
        show={show}
        size="md"
        id="modal-success"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal fade modal-login-success"
        animation="true"
        onHide={onHide}
      >
        <Modal.Header
          closeButton
          className="text-center border-0"
        ></Modal.Header>
        <Modal.Body className="text-center">
          <div className="img-wrapper my-3">
            <img src={danger_img} alt="Nội dung" className="d-inline" />
          </div>
          <>
            <p className="h4 font-weight-bold">
              Bạn cần cập nhật đầy đủ thông tin trước khi vào làm bài thi tập
              huấn.
            </p>
            <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
              <button className="btn-pr btn-play p-3 cursor mr-4">
                Cập nhập ngay
              </button>
              <CancelButton
                onClick={() => handleClosePopup()}
                className="btn-pr p-3 cursor"
              >
                Hủy
              </CancelButton>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

const CancelButton = styled.div`
  width: 80px;
`;
