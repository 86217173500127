import { APP_ID } from "edu_lms/constants/type";
import AxiosService from "edu_lms/services/axiosService";

const uriGetIp = "v1/get-ip?";

export const getUserLocation = () => {
  const axiosService = new AxiosService();

  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL_APP}${uriGetIp}app_id=${APP_ID}&is_web=1`
  );
};
