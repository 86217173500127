import _ from "lodash";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { BOOK_TYPE_SGV } from "consts/book";
import ResultBook from "./ResultBook";
import { isMobile } from "react-device-detect";
export default function SectionSGV() {
  const books = useBookStore((state) => state.books);
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const booksOfCurrentSubject = _.get(books, [gradeId, subjectId], []);
  const teacherBooks = booksOfCurrentSubject.filter((item) => item.book_type_id === BOOK_TYPE_SGV);

  return (
    !_.isEmpty(teacherBooks) && (
      <div
        className={"result col-" + (isMobile ? (teacherBooks.length > 1 ? 12 : 6) : (teacherBooks.length * 3))}
      >
        <ResultBook
          data={teacherBooks}
          bookTypeId={BOOK_TYPE_SGV}
          categoryBook="Sách giáo viên "
          col={"col-" + (isMobile ? (teacherBooks.length > 1 ? 6 : 12)  : (12/(teacherBooks.length))) }
          background="#fff"
        />
      </div>
    )
  );
}
