import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FB_GROUP_HOC10 } from "consts/config";
import { isCurrentRouteInBlackList } from "edu_lms_v2/utils/fb";
import { HOC10_CLICK_FB_GROUP_BTN } from "edu_lms/constants/eventNameGTM";
import { setEventGTM } from "edu_lms/constants/googleTagManager";

export default function FBGroupLink() {
  const location = useLocation();
  const currentPath = location.pathname;

  const [loading, setLoading] = useState(true);
  const [visibility, setVisibility] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  useEffect(() => {
    setVisibility(!isCurrentRouteInBlackList(currentPath));
  }, [currentPath]);

  return (
    !loading &&
    visibility && (
      <Wrapper
        onClick={() => {
          setEventGTM({
            event: HOC10_CLICK_FB_GROUP_BTN,
          });
          window.open(FB_GROUP_HOC10, "_blank");
        }}
      >
        <Icon className="fa fa-facebook-square" aria-hidden="true" size="lg" />
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  position: fixed;
  right: 12px;
  bottom: 60px;
  width: 60px;
  height: 60px;
  background-color: #ff7707;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  cursor: pointer;
  margin: 0 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px;
  z-index: 1000;
`;

const Icon = styled.i`
  font-size: 36px;
  color: #fff;
`;
