export default function BoxResult({
  numberCorrect,
  numberWrong,
  resultDetail,
  totalQuestion,
}) {
  const total = (numberCorrect / totalQuestion) * 100;

  return (
    <div className="box-result">
      <h3 className="txt-2">Kết quả</h3>
      <div className="result-bar flex">
        <span
          className="true p-0 d-flex justify-content-center"
          style={{ width: `${total}%`, height: "30px" }}
        >
          <span className="pl-0">{numberCorrect}</span>
        </span>
        <span
          className="false p-0 d-flex justify-content-center"
          style={{ width: `${100 - total}%`, height: "30px" }}
        >
          <span className="pl-0">{numberWrong}</span>
        </span>
      </div>
      <div className="data">
        {resultDetail.map((item) => (
          <div key={item.id} className="item flex">
            <p>{item.title}</p>
            <span>{item.result}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
