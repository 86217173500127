import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ROUTE_PATH_TU_SACH } from "consts/routePaths";

export default function ModalActiveBookSuccess(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img
            src="/assets/img/img-success.svg"
            alt="Kích hoạt sách thành công"
          />
        </div>
        <div className="text mb-2">Kích hoạt thành công</div>
        <h2 className=" txt-2 text-center mb-2 text-success">
          {props.data.book_name}
        </h2>
        <Link
          to={ROUTE_PATH_TU_SACH}
          title="Truy cập tủ sách"
          className="btn-pr flex-center btn-access btn-access-active"
          onClick={props.onHide}
        >
          Tiếp tục
        </Link>
      </div>
    </Modal>
  );
}
