import React from "react";
import { IMAGE_WIDTH } from "../../../constants/FIB";
import { getDefaultWidthInputByFontSize } from "edu_lms_v2/libraries/hoc10Game/helpers/FIB_BG";
import styles from "./styles.module.scss";

const InputPositionV1 = React.forwardRef(
  ({ widthForm, fontSize, color, position, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        type="text"
        className={`monkey-fz-20 quicksand-medium ${styles["input-position-v1"]}`}
        style={{
          fontSize: `${fontSize * (widthForm / IMAGE_WIDTH.WebView)}px`,
          width: `${getDefaultWidthInputByFontSize(fontSize)}px`,
          color: color,
          top: ((widthForm || IMAGE_WIDTH.WebView) / IMAGE_WIDTH.CMSView) * position.vertical_axis_px,
          left: ((widthForm || IMAGE_WIDTH.WebView) / IMAGE_WIDTH.CMSView) * position.horizontal_axis_px,
        }}
        placeholder="?"
        autoComplete="off"
      />
    );
  }
);

export default InputPositionV1;
