import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import styled from "styled-components";
import { addStyles, StaticMathField } from "react-mathquill";
import { Typography } from "../../components";
import CalculatorModal from "./components/CalculatorModal";
import HeaderQuestion from "./components/HeaderQuestion";
import { TYPE_DATA } from "../../constants";
import { getDataInputAnswers, getStatusInputArea, getBorderColor } from "../../helpers/MathInput";
import "./styles.scss";
addStyles();

const MathInput = (
  {
    gameData,
    hideResultAnswer = false,
    inputtedAreasProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;
  const [showCalculatorModal, setShowCalculatorModal] = useState(false);

  const defaultInputAreas = getDataInputAnswers(answer.answers);
  const [inputAreas, setInputAreas] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  //*---------- Show inputted answers result ----------*//
  useEffect(() => {
    if (inputtedAreasProp.length > 0) {
      setInputAreas(inputtedAreasProp);
    }
  }, [inputtedAreasProp]);

  //*---------- Show all correct answers result ----------*//
  useEffect(() => {
    if (showCorrectAnswer) {
      const correctInputtedAnswers = defaultInputAreas.map(area => ({
        index: area.index,
        value: area.answerText.split("|")[0],
        isCorrect: true
      }))
      setInputAreas(correctInputtedAnswers);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer])

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    let totalCorrectAnswer = 0;
    const swapInputAreaWithIndex = {};
    const correctInputs = { noSwap: [], hasSwap: [] };

    const inputAreasValue = defaultInputAreas.map((inputArea) => {
      const value = (inputAreas.find((area) => area.index === inputArea.index) || {}).value;
      return {...inputArea, value };
    });
    inputAreasValue.forEach((inputArea) => {
      if (!inputArea.answerIndex) {
        // Case input in game don't have answerIndex (MathInput - input no swap)
        if (inputArea.answerText.split("|").includes(inputArea.value)) {
          totalCorrectAnswer++;
          correctInputs.noSwap.push({ index: inputArea.index });
        }
      } else {
        // Case input in game have answerIndex (MathInput - input has swap)
        const swapIndex = inputArea.answerIndex;
        if (!Object.keys(swapInputAreaWithIndex).includes(swapIndex)) {
          swapInputAreaWithIndex[swapIndex] = {
            results: [{ text: inputArea.answerText, index: inputArea.index }],
            values: [{ text: inputArea.value, index: inputArea.index }],
          };
        } else {
          swapInputAreaWithIndex[swapIndex] = {
            results: [
              ...swapInputAreaWithIndex[swapIndex].results,
              { text: inputArea.answerText, index: inputArea.index },
            ],
            values: [
              ...swapInputAreaWithIndex[swapIndex].values,
              { text: inputArea.value, index: inputArea.index },
            ],
          };
        }
      }
    });

    // Check MathInput has swap results, values ---> calculate totalAnswer, correctInputs<<hasSwap>>
    Object.values(swapInputAreaWithIndex).forEach((inputArea) => {
      const resultsTemp = inputArea.results;
      inputArea.values.forEach((value) => {
        const indexResultCompare = resultsTemp.findIndex((result) =>
          result.text.split("|").includes(value.text)
        );
        if (indexResultCompare > -1) {
          totalCorrectAnswer++;
          correctInputs.hasSwap.push({ value: value.text });
          resultsTemp.splice(indexResultCompare, 1);
        }
      });
    });

    const inputAreasHasStatus = inputAreas.map(inputArea => ({
      ...inputArea,
      isCorrect: getStatusInputArea(inputArea, inputAreasValue, correctInputs)
    }));
    setInputAreas(inputAreasHasStatus);

    const isCorrect = totalCorrectAnswer === defaultInputAreas.length;
    onComplete({ isCorrect, totalCorrectAnswer, inputtedAreas: inputAreasHasStatus });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setInputAreas([]);
    setFocusedIndex(null);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleClickAnswerInput = (answerItem) => {
    setFocusedIndex(answerItem.index);
    setShowCalculatorModal(true);
  };

  const handleInsertMathCharacter = (latex) => {
    const shadowInputAreas = [...inputAreas];
    const arrIndexFocused = inputAreas.findIndex(
      (inputArea) => inputArea.index === focusedIndex
    );
    if (arrIndexFocused > -1) {
      shadowInputAreas.splice(arrIndexFocused, 1);
    }
    const questionId = question.questionId;
    shadowInputAreas.push({ index: focusedIndex, value: latex, questionId });
    setInputAreas(shadowInputAreas);
    setShowCalculatorModal(false);

    const isFilledAllInputs =
      shadowInputAreas.filter((area) => area.questionId === questionId)
        .length === defaultInputAreas.length;
    onPlaying(isFilledAllInputs);
  };

  return (
    <>
      {showCalculatorModal && (
        <CalculatorModal
          show={showCalculatorModal}
          textInput={(inputAreas.find((inputArea) => inputArea.index === focusedIndex) || {}).value}
          onHide={() => setShowCalculatorModal(false)}
          onInsertMathCharacter={handleInsertMathCharacter}
        />
      )}

      <div className={`math-input__container${(isReadOnly || isViewOnly) ? " read-only" : ""}`}>
        <HeaderQuestion question={question} />

        <div className="answer__wrapper row ml-3 mt-5">
          {answer.answers.map((answerLine, alIndex) => (
            <div
              key={alIndex}
              className={`col-md-${12 / answer.numberInARow} mb-3 list-answer-item__wrapper`}
              style={{ fontSize: answer.fontSizeAnswer }}
            >
              {answerLine.map((answerItem, index) => {
                if (answerItem.type === TYPE_DATA.INPUT) {
                  const currentInputArea = inputAreas.find((inputArea) => inputArea.index === answerItem.index) || {};
                  return (
                    <InputAnswer
                      key={index}
                      className="answer-input"
                      isCorrect={hideResultAnswer ? null : currentInputArea.isCorrect}
                      onClick={() => handleClickAnswerInput(answerItem)}
                    >
                      <StaticMathField>
                        {currentInputArea.value}
                      </StaticMathField>
                    </InputAnswer>
                  );
                }

                return (
                  <Typography
                    key={index}
                    text={answerItem.answerText}
                    typeText={[TYPE_DATA.LATEX]}
                    fontSize={answer.fontSizeAnswer}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default forwardRef(MathInput);

const InputAnswer = styled.div`
  border: 2px solid ${props => getBorderColor(props.isCorrect)}
`;
