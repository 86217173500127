import React, { useState, useEffect } from "react";
import styles from "../../teachingManager.module.scss";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as types from "edu_lms/constants/type";
import {
  getListUserClassroomById,
  postDeleteUserClassroom,
} from "edu_lms/services/teachingManager";
import PopupAddStudentByEmail from "./PopupAddStudentByEmail";
import Loading from "edu_lms_v2/components/Loading";
import PopupImportUserExcel from "./PopupImportUserExcel";
import PopupConfirmDelete from "../PopupConfirmDelete";
import { ROLE } from "../../constant";

export default function ListMemberClassroom({ classroomId, getInfoClassroom }) {
  const product = Number(process.env.REACT_APP_APP_ID);
  const [showPopupAddStudentForEmail, setShowPopupAddStudentForEmail] =
    useState(false);
  const [showPopupAddStudentExcel, setShowPopupAddStudentExcel] =
    useState(false);
  const [role, setRole] = useState(ROLE.ADD);
  const [isLoadingTeacher, setIsLoadingTeacher] = useState(true);
  const [isLoadingStudent, setIsLoadingStudent] = useState(true);
  const [searchKeyTeacher, setSearchKeyTeacher] = useState("");
  const [searchKeyStudent, setSearchKeyStudent] = useState("");
  const [listStudent, setListStudent] = useState([]);
  const [student, setStudent] = useState({});
  const [teacherInfo, setTeacherInfo] = useState({});
  const [typeImportExcel, setTypeImportExcel] = useState();
  const [idUserClassroom, setIdUserClassroom] = useState();
  const [fullNameDelete, setFullNameDelete] = useState();
  const [showPopupDeleteStudent, setShowPopupDeleteStudent] = useState(false);

  const { register, handleSubmit } = useForm();

  const onSearch = (data) => {
    setSearchKeyTeacher(data.search_teacher);
    setSearchKeyStudent(data.search_student);
  };

  const getListTeacher = () => {
    const payloadTeacher = {
      classroomId: classroomId,
      roleId: types.ROLE_TEACHER,
    };
    setIsLoadingTeacher(true);
    getListUserClassroomById(payloadTeacher)
      .then((res) => {
        if (res.data.status === "success") {
          setTeacherInfo(res.data.data.list[0]);
          // setIsLoadingTeacher(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListStudent = () => {
    const payloadStudent = {
      classroomId: classroomId,
      roleId: types.ROLE_STUDENT,
      name: searchKeyStudent,
    };
    setIsLoadingStudent(true);
    getListUserClassroomById(payloadStudent)
      .then((res) => {
        if (res.data.status === "success") {
          setListStudent(res.data.data.list);
          setIsLoadingStudent(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onDeleteUserClassroom = (id, name) => {
    setIdUserClassroom(id);
    setFullNameDelete(name);
    setShowPopupDeleteStudent(true);
  };
  const submitDeleteUserClassroom = (id) => {
    postDeleteUserClassroom({ user_class_room_id: id }).then((res) => {
      if (res.data.status === "success") {
        toast.success("Xoá học sinh thành công!");
        getListStudent();
      } else {
        toast.error("Xoá học sinh thất bại!");
      }
      setShowPopupDeleteStudent(false);
    });
  };

  const importStudentFileExcel = () => {
    setShowPopupAddStudentExcel(true);
    setTypeImportExcel(types.ROLE_STUDENT);
  };

  useEffect(() => {
    getListTeacher();
  }, [searchKeyTeacher]);

  useEffect(() => {
    getListStudent();
  }, [searchKeyStudent]);

  const getLinkExercise = ({ id, question_set_id }) => {
    const encodeStudentId = window.btoa(id);

    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}luyen-thi/${question_set_id}/${encodeStudentId}`
    );
    toast.success("Copy link giao bài thành công!");
  };

  const handleUpdateStudent = (studentInfo) => {
    setRole(ROLE.UPDATE);
    setShowPopupAddStudentForEmail(true);
    setStudent(studentInfo);
  };
  return (
    <div
      className="tab-pane fade show active"
      id="member"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      {/* <div>
        <div className="d-flex mb-2">
          <p
            className={`font-weight-bold mr-auto monkey-color-black ${styles.fontSize18}`}
          >
            Giáo viên ({listTeacher.length})
          </p>
          <div className="pt-2">
            <button
              className="mr-3"
              id="dropdownAddTeacher"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                className={`fa fa-user-plus pr-2 ${styles.colorOrange}`}
                aria-hidden="true"
              ></i>
              Thêm giáo viên
            </button>
            <div
              className={`dropdown-menu ${styles.menuSetting}`}
              aria-labelledby="dropdownAddTeacher"
            >
              <button className="dropdown-item">Thêm giáo viên có email</button>
              <button className="dropdown-item" href="#">
                Thêm giáo viên không có email
              </button>
            </div>
          </div>
          <div className="mb-2 position-relative">
            <form onSubmit={handleSubmit(onSearch)} name="search_teacher">
              <i
                className={`fa fa-search position-absolute ${styles.iconSearch}`}
                aria-hidden="true"
              ></i>
              <input
                name="search_teacher"
                type="text"
                className="form-control rounded-pill pl-4"
                placeholder="Tìm giáo viên"
                ref={register({ required: false, maxLength: 80 })}
              />
            </form>
          </div>
        </div>
        <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-5 table-responsive">
          <table className={`table text-center table-hover ${styles.table}`}>
            <thead>
              <tr>
                <th scope="col" className="border-right border-top-0 ">
                  STT
                </th>
                <th scope="col" className="border-right border-top-0">
                  Họ và tên
                </th>
                <th scope="col" className="border-right border-top-0">
                  Email
                </th>
                <th scope="col" className="border-right border-top-0">
                  Số điện thoại
                </th>
                <th scope="col" className="border-right border-top-0">
                  Môn phụ trách
                  <button className={`ml-2 ${styles.colorOrange}`}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </th>
                <th scope="col" className="border-right border-top-0">
                  Trạng thái
                </th>
                <th scope="col" className="border-top-0">
                  Quyền&nbsp;
                  <button className={`ml-2 ${styles.colorOrange}`}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </th>
                <th scope="col" className="border-top-0 pl-0"></th>
              </tr>
            </thead>
            <tbody>
              {!isLoadingTeacher ? (
                listTeacher.map((teacher, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{teacher?.name}</td>
                    <td>{teacher?.email || "Chưa có email"}</td>
                    <td>{teacher?.phone || "Chưa có số"}</td>
                    <td>{teacher?.subject || "Chưa có"}</td>
                    <td>{teacher?.status ? "Xác nhận" : "Chưa xác nhận"}</td>
                    <td>
                      {types.roles.find((i) => i.id === teacher?.role_id)?.name}
                    </td>
                    <td className={`pl-0 ${styles.tdDelete}`}>
                      <button className={styles.btnDelete}>
                        <i className={`fa fa-trash`} aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="8">
                    <Loading />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div> */}
      <div>
        <div className="d-flex mb-2">
          <p
            className={`font-weight-bold mr-auto monkey-color-black ${styles.fontSize18}`}
          >
            Học sinh ({listStudent.length})
          </p>
          <div className="pt-2">
            <button
              className="mr-3"
              id="dropdownAddStudent"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i
                className={`fa fa-user-plus pr-2 ${styles.colorOrange}`}
                aria-hidden="true"
              ></i>
              Thêm học sinh
            </button>
            <div
              className={`dropdown-menu ${styles.menuSetting}`}
              aria-labelledby="dropdownAddStudent"
            >
              {/* <button className="dropdown-item">
                Thêm học sinh bằng mã QR/ mã lớp học
              </button> */}
              <button
                className="dropdown-item"
                onClick={() => {
                  setRole(ROLE.ADD);
                  setStudent({});
                  setShowPopupAddStudentForEmail(true);
                }}
              >
                Thêm học sinh
              </button>
              {/* <button className="dropdown-item">
                Thêm học sinh không có email
              </button> */}
              <button
                className="dropdown-item"
                onClick={() => importStudentFileExcel()}
              >
                Thêm học sinh bằng file excel
              </button>
            </div>
          </div>
          <div className="mb-2 position-relative">
            <form onSubmit={handleSubmit(onSearch)} name="searchStudent">
              <i
                className={`fa fa-search position-absolute ${styles.iconSearch}`}
                aria-hidden="true"
              ></i>
              <input
                name="search_student"
                type="text"
                className="form-control rounded-pill pl-4"
                placeholder="Tìm học sinh"
                ref={register({ required: false, maxLength: 80 })}
              />
            </form>
          </div>
        </div>
        <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-5 table-responsive">
          <table className={`table text-center table-hover ${styles.table}`}>
            <thead>
              <tr>
                <th scope="col" className="border-right border-top-0">
                  STT
                </th>
                <th scope="col" className="border-right border-top-0">
                  Họ và tên
                </th>
                <th scope="col" className="border-right border-top-0">
                  Email
                </th>
                <th scope="col" className="border-right border-top-0">
                  Số điện thoại
                </th>

                {/* <th scope="col" className="border-right border-top-0">
                  Tên đăng nhập
                </th>
                <th scope="col" className="border-right border-top-0">
                  Trạng thái
                </th>
                <th scope="col" className="border-top-0">
                  Phụ huynh&nbsp;
                  <button
                    className={`ml-2 ${styles.colorOrange}`}
                    id="dropdownAddParents"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <div
                    className={`dropdown-menu ${styles.menuSetting}`}
                    aria-labelledby="dropdownAddParents"
                  >
                    <button className="dropdown-item">Thêm thủ công</button>
                    <button className="dropdown-item">Tải file excel</button>
                  </div>
                </th>
                <th scope="col" className="border-top-0 pl-0"></th> */}
              </tr>
            </thead>
            <tbody>
              {!isLoadingStudent ? (
                listStudent.map((student, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td
                      className="cursor"
                      onClick={() => getLinkExercise(student)}
                    >
                      {student.name}
                    </td>
                    <td>{student.email}</td>
                    <td>{student.phone}</td>
                    <td className={`px-0`}>
                      <button
                        className="mr-3 monkey-color-orange"
                        onClick={() => handleUpdateStudent(student)}
                      >
                        <i className={`fa fa-edit`} aria-hidden="true" />
                      </button>
                      <button
                        className={styles.btnDelete}
                        onClick={() =>
                          onDeleteUserClassroom(student.id, student.name)
                        }
                      >
                        <i className={`fa fa-trash`} aria-hidden="true" />
                      </button>
                    </td>
                    {/* <td>{student?.username || "Chưa có"}</td>
                    <td>{student?.status ? "Xác nhận" : "Chưa xác nhận"}</td>
                    <td>{student?.parent ? student.parent : "Chưa có"}</td>
                    <td className={`pl-0 ${styles.tdDelete}`}>
                      <button className={styles.btnDelete}>
                        <i className={`fa fa-trash`} aria-hidden="true"></i>
                      </button>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="7">
                    <Loading />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <PopupAddStudentByEmail
        show={showPopupAddStudentForEmail}
        setShow={setShowPopupAddStudentForEmail}
        role={role}
        student={student}
        idClassroom={classroomId}
        getListClassroom={getListStudent}
        getInfoClassroom={getInfoClassroom}
      />
      <PopupImportUserExcel
        show={showPopupAddStudentExcel}
        setShow={setShowPopupAddStudentExcel}
        idClassroom={classroomId}
        getListStudent={getListStudent}
        getInfoClassroom={getInfoClassroom}
        type={typeImportExcel}
      />
      <PopupConfirmDelete
        show={showPopupDeleteStudent}
        setShow={setShowPopupDeleteStudent}
        id={idUserClassroom}
        title={`Bạn có chắc chắn muốn xoá ${fullNameDelete} ra khỏi lớp?`}
        onDelete={submitDeleteUserClassroom}
      />
    </div>
  );
}
