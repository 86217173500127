import { useContext, useEffect, useState } from "react";
import { AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import styled from "styled-components";
import _ from "lodash";
import {
  getHistoryExam,
  getQuestionSetByContentId,
} from "edu_lms/services/readingBook";
import updating_img from "edu_lms_v2/assets/img/updating.svg";
import updating_white from "edu_lms_v2/assets/img/updating-white.svg";
import { onResultUserInfo } from "edu_lms/modules/selection";
import {
  getListClassroomById,
  postAssignHomeWork,
} from "edu_lms/services/teachingManager";
import * as types from "edu_lms/constants/type";

import dayjs from "dayjs";
import classNames from "classnames";
import PopupShowHistoryExam from "../QuestionLibraryV2/PopupShowHistoryExam";
import PopUpAssignHomeWork from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignHomeWork";
import PopUpAssignSuccess from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignSuccess";

const IS_UPDATING = 1;
const Li = styled.li`
  list-style: none;
  &:hover {
    background-color: #eee;
  }
`;

const ChildrenQuestion = styled.p`
  &:hover {
    color: #fff;
    background-color: #ff7707;
  }
`;

const Ribbon = styled.div`
  .ribbon {
    display: inline-flex;
    overflow: hidden;
  }
  span {
    width: 24px;
  }
  .comming {
    width: 48px;
  }
`;

export default function QuestionLibraryDetail({
  questionDetail,
  handleShowExam,
  listGrade,
  listSubject,
}) {
  const [showHistoryExam, setShowHistoryExam] = useState(false);
  const { list } = useSelector((state) => state.mockTestReducers);
  const [currentGrade, setCurrentGrade] = useState({});
  const [currentSubject, setCurrentSubject] = useState({});
  const [listClassroom, setListClassroom] = useState([]);
  const [showModalAssignSuccess, setShowModalAssignSuccess] = useState(false);
  const [classRoom, setClassRoom] = useState([]);
  const [question, setQuestion] = useState("");
  const [checked, setChecked] = useState([]);
  const [questionSetId, setQuestionSetId] = useState();
  const [questionSets, setQuestionSets] = useState([]);
  const [keyPopup, setKeyPopup] = useState({
    currentGrade,
    currentSubject,
    title: "",
  });

  useEffect(() => {
    setCurrentGrade(listGrade.find((grade) => grade.id === list.gradeId));
    setCurrentSubject(listSubject.find((s) => s.id === list.subjectId));
  }, [list, listGrade, listSubject]);

  useEffect(() => {
    setKeyPopup({
      ...keyPopup,
      currentGrade,
      currentSubject,
    });
  }, [currentGrade, currentSubject]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const guest_id = localStorage.getItem("guest_id");
    if (!token && !guest_id) {
      localStorage.setItem("guest_id", uuidv4());
    }
  }, []);
  const accountWebsite = onResultUserInfo();
  const defineAccount = accountWebsite?.job_id;

  const showGradeName = (gradeId) => {
    const gradeName = types.gradeOptions.find((item) => item.value === gradeId);
    return gradeName?.name;
  };

  const handleShowPopupHistoryExam = (title, id) => {
    setShowHistoryExam(true);
    setQuestionSetId(id);
    const data = {
      question_set_id: id,
      guest_id: localStorage.getItem("guest_id"),
    };
    getHistoryExam(data)
      .then((res) => {
        const dataHistory = res.data.data;
        setKeyPopup({ ...keyPopup, title, dataHistory, id });
      })
      .catch((e) => console.log(e));
  };

  const getListClassroom = () => {
    const data = {
      title: "",
      roleId: `${types.ROLE_CREATED_ROOM},${types.ROLE_TEACHER}`,
      langId: types.TYPE_VIETNAMESE,
      appId: types.APP_ID,
    };
    getListClassroomById(data)
      .then((res) => {
        if (res.data.status === "success") {
          const list = res.data.data.list;
          const nodes = list.map((item) => ({
            value: item.class_room_id,
            label: showGradeName(item.grade_id)
              ? `${showGradeName(item.grade_id)}${item.title}`
              : item.title,
          }));
          setListClassroom(nodes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    const dataForm = {
      deadline: dayjs(data.time_finish).unix(),
      question_set_id: questionSetId,
      class_room_ids: checked.toString(),
    };
    postAssignHomeWork(dataForm)
      .then((res) => {
        if (res.data.status === "success") {
          setClassRoom(res.data.data.class_room);
          setQuestion(res.data.data.question_set);
          setShowModalAssignSuccess(true);
        }
      })
      .finally(() => {
        setShowHistoryExam(false);
        setChecked([]);
      });
  };
  const examTypes = !_.isEmpty(questionDetail) ? questionDetail : [];
  const hasChildrenExamType = examTypes.length > 0;
  const COMING_SOON = 2;

  const showQuestionSet = (content_id) => {
    setQuestionSets([]);
    getQuestionSetByContentId({ content_id })
      .then((res) => {
        if (res.data.status === "success") {
          setQuestionSets(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="col-lg-9 col-md-8">
        {!hasChildrenExamType && (
          <div className="monkey-color-orange monkey-f-header h3 text-uppercase text-center pt-5">
            Không có bộ đề
          </div>
        )}

        {examTypes.map((examType) => {
          const subExamTypes = examType.children || [];
          const hasSubExamType = subExamTypes.length > 0;
          const questions = examType.questions;
          const hasQuestion = questions.length > 0;

          return (
            <div className="chapter bg-sh">
              <Accordion>
                <Accordion.Toggle eventKey={examType.id} className="w-100">
                  <div className="header flex justify-content-between">
                    <Ribbon>
                      <div className="ribbon">
                        <h3> {examType.title}</h3>
                        <span
                          className={classNames("ml-2 d-flex", {
                            comming: examType.coming_soon === COMING_SOON,
                          })}
                        ></span>
                      </div>
                    </Ribbon>
                    {(hasSubExamType || hasQuestion) && (
                      <ContextAwareToggle eventKey={examType.id} />
                    )}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={examType.id} className="cursor">
                  <div className="question">
                    <Accordion className="flex flex-column pr-3">
                      {subExamTypes.map((subExamType) => {
                        const subQuestions =
                          subExamType.questions.length > 0
                            ? subExamType.questions
                            : subExamType.children;

                        const subQuestionSets =
                          subQuestions.length > 0 ? subQuestions : questionSets;

                        const hasSubQuestions = subQuestions.length > 0;
                        return (
                          <Card className="border-0">
                            <Card.Header className="p-0 bg-white border-0">
                              <Accordion.Toggle
                                eventKey={subExamType.id}
                                className="w-100"
                                onClick={() => {
                                  if (subExamType.children.length > 0) return;
                                  showQuestionSet(subExamType.id);
                                }}
                              >
                                <Li className="w-100 rounded">
                                  <div className="text-left d-flex justify-content-between">
                                    <div className="d-flex align-items-center monkey-fz-18">
                                      <i className="icon-doc mr-2" />
                                      {subExamType.title}
                                    </div>
                                    {hasSubQuestions && (
                                      <ContextAwareToggle
                                        eventKey={subExamType.id}
                                      />
                                    )}
                                  </div>
                                </Li>
                              </Accordion.Toggle>
                            </Card.Header>

                            {subExamType.children.length > 0 && (
                              <Accordion.Collapse
                                eventKey={subExamType.id}
                                className="cursor monkey-fz-18 question-item"
                              >
                                <Accordion className="flex flex-column pr-3 pl-4">
                                  {subQuestionSets.length > 0 &&
                                    subQuestionSets.map(
                                      (subQuestion, _index) => {
                                        const subQuestions =
                                          subQuestion.questions?.length > 0
                                            ? subQuestion.questions
                                            : subQuestion.children;

                                        const hasSubQuestions =
                                          subQuestions?.length > 0;
                                        return (
                                          <Card className="border-0">
                                            <Card.Header className="p-0 bg-white border-0">
                                              <Accordion.Toggle
                                                eventKey={subQuestion.id}
                                                className="w-100"
                                                onClick={() =>
                                                  showQuestionSet(
                                                    subQuestion.id
                                                  )
                                                }
                                              >
                                                <Li className="w-100 rounded">
                                                  <div className="text-left d-flex justify-content-between">
                                                    <div className="d-flex align-items-center monkey-fz-18">
                                                      <i className="icon-doc mr-2" />
                                                      {subQuestion.title}
                                                    </div>
                                                    {hasSubQuestions && (
                                                      <ContextAwareToggle
                                                        eventKey={
                                                          subQuestion.id
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </Li>
                                              </Accordion.Toggle>
                                            </Card.Header>
                                            {questionSets.length > 0 &&
                                              questionSets.map(
                                                (_subQuestion, _index) => (
                                                  <Accordion.Collapse
                                                    eventKey={subQuestion.id}
                                                    className="cursor monkey-fz-18 question-item"
                                                  >
                                                    <ChildrenQuestion
                                                      className="pl-4 rounded"
                                                      onClick={() =>
                                                        handleShowPopupHistoryExam(
                                                          _subQuestion.public_title ??
                                                            `[${types.DRAFT}] ${
                                                              _subQuestion.title ??
                                                              `Bài tập ${
                                                                _index + 1
                                                              }`
                                                            }`,
                                                          _subQuestion.id
                                                        )
                                                      }
                                                    >
                                                      <div className="pt-2 pb-2 d-flex align-items-center">
                                                        <i className="icon-pen1 mr-2" />
                                                        <span>
                                                          {_subQuestion.public_title ||
                                                            `[${types.DRAFT}] ${
                                                              _subQuestion.title ??
                                                              `Bài tập ${
                                                                _index + 1
                                                              }`
                                                            }`}
                                                        </span>
                                                        {_subQuestion.is_maintain ===
                                                          IS_UPDATING && (
                                                          <span className="ml-2">
                                                            <img
                                                              src={updating_img}
                                                              alt="updating"
                                                              width="60"
                                                              className="img-orange"
                                                            />
                                                            <img
                                                              src={
                                                                updating_white
                                                              }
                                                              alt="updating"
                                                              width="60"
                                                              className="img-white"
                                                            />
                                                          </span>
                                                        )}
                                                      </div>
                                                    </ChildrenQuestion>
                                                  </Accordion.Collapse>
                                                )
                                              )}
                                          </Card>
                                        );
                                      }
                                    )}
                                </Accordion>
                              </Accordion.Collapse>
                            )}
                            {subExamType.children.length === 0 &&
                              questionSets.length > 0 &&
                              questionSets.map((subQuestion, _index) => (
                                <Accordion.Collapse
                                  eventKey={subExamType.id}
                                  className="cursor monkey-fz-18 question-item"
                                >
                                  <ChildrenQuestion
                                    className="pl-4 rounded"
                                    onClick={() =>
                                      handleShowPopupHistoryExam(
                                        subQuestion.public_title ??
                                          `[${types.DRAFT}] ${
                                            subQuestion.title ??
                                            `Bài tập ${_index + 1}`
                                          }`,
                                        subQuestion.id
                                      )
                                    }
                                  >
                                    <div className="pt-2 pb-2 d-flex align-items-center">
                                      <i className="icon-pen1 mr-2" />
                                      <span>
                                        {subQuestion.public_title ||
                                          `[${types.DRAFT}] ${
                                            subQuestion.title ??
                                            `Bài tập ${_index + 1}`
                                          }`}
                                      </span>
                                      {subQuestion.is_maintain ===
                                        IS_UPDATING && (
                                        <span className="ml-2">
                                          <img
                                            src={updating_img}
                                            alt="updating"
                                            width="60"
                                            className="img-orange"
                                          />
                                          <img
                                            src={updating_white}
                                            alt="updating"
                                            width="60"
                                            className="img-white"
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </ChildrenQuestion>
                                </Accordion.Collapse>
                              ))}
                          </Card>
                        );
                      })}
                    </Accordion>
                    {questions.map((question, _index) => (
                      <ChildrenQuestion
                        className="rounded mr-3 monkey-fz-18 question-item"
                        // onClick={() => handleShowExam(question.id)}
                        onClick={() =>
                          handleShowPopupHistoryExam(
                            question.public_title ??
                              `[${types.DRAFT}] ${
                                question.title ?? `Bài tập ${_index + 1}`
                              }`,
                            question.id
                          )
                        }
                      >
                        <div className="pt-2 pb-2 d-flex align-items-center">
                          <i className="icon-pen1 mr-2" />
                          <span>
                            {question.public_title ||
                              `[${types.DRAFT}] ${
                                question.title ?? `Bài tập ${_index + 1}`
                              }`}
                          </span>
                          {question.is_maintain === IS_UPDATING && (
                            <span className="ml-2">
                              <img
                                src={updating_img}
                                alt="updating"
                                width="60"
                                className="img-orange"
                              />
                              <img
                                src={updating_white}
                                alt="updating"
                                width="60"
                                className="img-white"
                              />
                            </span>
                          )}
                        </div>
                      </ChildrenQuestion>
                    ))}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          );
        })}
      </div>
      {showHistoryExam && (
        <PopupShowHistoryExam
          showHistoryExam={showHistoryExam}
          setShowHistoryExam={setShowHistoryExam}
          keyPopup={keyPopup}
          handleShowExam={handleShowExam}
          defineAccount={defineAccount}
          getListClassroom={getListClassroom}
          listClassroom={listClassroom}
          checked={checked}
          setChecked={setChecked}
          onSubmit={onSubmit}
        />
      )}

      <PopUpAssignHomeWork
        listClassroom={listClassroom}
        onSubmit={onSubmit}
        checked={checked}
        setChecked={setChecked}
        showHistoryExam={showHistoryExam}
      />

      <PopUpAssignSuccess
        question={question}
        classRoom={classRoom}
        showGradeName={showGradeName}
        open={showModalAssignSuccess}
        onClose={() => setShowModalAssignSuccess(false)}
        setShowHistoryExam={setShowHistoryExam}
      />
    </>
  );
}

function ContextAwareToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <i
      className={`fa pr-2 monkey-fz-20 ${
        isCurrentEventKey ? "fa-angle-up" : "fa-angle-down"
      }`}
      aria-hidden="true"
      onClick={decoratedOnClick}
    />
  );
}
