const GRAPH_TYPE = {
  FirstDegreeEquation: 1,
  QuadraticEquation: 2,
};

const GRAPH_TITLE = "Vẽ đồ thị hàm số sau: ";

const GRAPH_ANSWER = {
  [GRAPH_TYPE.FirstDegreeEquation]: "2 điểm thuộc đồ thị hàm số là:",
  [GRAPH_TYPE.QuadraticEquation]: "3 điểm thuộc đồ thị hàm số là:",
};

const POINT_EVENT = {
  MouseDrag: "mousedrag",
  MouseOut: "mouseout",
  Up: "up",
};

const GRAPH_OBJECT = {
  Point: "point",
  Line: "line",
  Parabol: "functiongraph",
  Comb: "comb",
};

export { GRAPH_TITLE, GRAPH_ANSWER, GRAPH_TYPE, POINT_EVENT, GRAPH_OBJECT };
