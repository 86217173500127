import styled from "styled-components";
import { useForm } from "react-hook-form";
import _ from "lodash";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { SEARCH_LECTURE } from "edu_lms/constants/eventNameGTM";
import { useGiaoanStore } from "stores/giaoanStore";
import { useQueryState } from "hooks/useQueryState";

export default function SearchForm() {
  useScrollToTop();
  const { register, handleSubmit } = useForm();

  const fetchGiaoAn = useGiaoanStore((state) => state.fetchGiaoAn);
  const textSearch = useGiaoanStore((state) => state.textSearch);
  const setTextSearch = useGiaoanStore((state) => state.setTextSearch);
  const setPage = useGiaoanStore((state) => state.setPage);

  const [queryTextSearch, setQueryTextSearch] = useQueryState("tieude");

  const onSubmit = (dataForm) => {
    setEventGTM({ event: SEARCH_LECTURE, search_key: dataForm.title });
    setTextSearch(dataForm.title);
    setPage(0);
    fetchGiaoAn();
  };

  return (
    <form
      action=""
      method=""
      className="search-form pr m-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex pr">
        <StyledInput className="form-group">
          <input
            id="search"
            type="text"
            name="title"
            placeholder="Nhập tên bài giảng cần tìm"
            required=""
            ref={register({ required: false })}
            value={textSearch}
            onChange={(e) => {
              setTextSearch(e.target.value);
              setQueryTextSearch(e.target.value);
            }}
          />
          {textSearch && (
            <ClearButton
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => {
                setTextSearch("");
                setQueryTextSearch("");
                setPage(0);
                fetchGiaoAn();
              }}
            />
          )}
        </StyledInput>
        <button type="submit" className="btn_submit btn-pr flex-center">
          Tìm Kiếm
        </button>
      </div>
    </form>
  );
}

const ClearButton = styled.i`
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: hsl(0, 0%, 80%);

  &:hover {
    color: hsl(0, 0%, 50%);
  }
`;

const StyledInput = styled.div`
  position: relative;
  width: calc(100% - 140px);

  input {
    width: 100%;
  }
`;
