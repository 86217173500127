export default function Setting() {
  return (
    <main>
      <div className="hoc10-setting bg-gray">
        <div className="setting">
          <h1 className="title">Cài đặt</h1>
          <div className="setting__email">
            <h4>Email thông báo</h4>
            <p className="txt-5">
              Bạn sẽ nhận được thông báo khi có bản cập nhật về tính năng trên
              Hoc10.vn
            </p>
            <div className="flex-lc">
              <div className="text txt-5">
                Nhận email thông báo từ https://hoc10.vn
              </div>
              <label className="switch">
                <input type="checkbox" checked />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="setting__password">
            <h4>Đổi mật khẩu</h4>
            <a
              href=""
              title="Đổi mật khẩu"
              className="btn-change btn-pr flex-center"
            >
              Đổi mật khẩu
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
