import useScrollToTop from "edu_lms_v2/services/useScrollToTop";

export default function StudentDoingExercise5() {
  useScrollToTop();

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li className="breadcrumb-item">
              <a href="">Luyện tập</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Toán Lớp 6 - Sách gíao khoa
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-title bg-gr">
        <div className="container pr">
          <div className="subject flex-lc">
            <div className="subject__icon flex-center">
              <img src="./assets/img/icon-toan1.svg" alt="" />
            </div>
            <div className="subject__info">
              <h5 className="txt-2">Luyện tập</h5>
              <h1 className="title">
                Toán lớp 6 - <span>SGK</span>
              </h1>
              <p>Ôn tập kiến thức cùng kho bài tập phong phú</p>
              <a href="" title="Thay đổi" className="btn-sub flex-center">
                Thay đổi
              </a>
            </div>
          </div>
          <div className="img-wrapper">
            <img src="./assets/img/img-dc5.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="hoc10-menu">
        <div className="container">
          <ul className="tab nav">
            <li>
              <a href="#tab1" data-bs-toggle="tab" className="active">
                Tập 1
              </a>
            </li>
            <li>
              <a href="#tab2" data-bs-toggle="tab">
                Tập 2
              </a>
            </li>
          </ul>
          <div className="tab-content pr">
            <div id="tab1" className="tab-pane fade show active">
              <div className="table-content">
                <div className="item active bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương I: Số tự nhiên</h4>
                    <span>2/5 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 2:</span> Luyện tập với số tự nhiên
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                    <li>
                      <p className="txt-5">
                        <span>Bài 3:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                    <li>
                      <p className="txt-5">
                        <span>Bài 4:</span> Luyện tập với số tự nhiên
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                    <li>
                      <p className="txt-5">
                        <span>Bài 3:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Số tự nhiên là gì
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">
                      Chương II: Luyện tập với số tự nhiên
                    </h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương III: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương IV: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương V: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div id="tab2" className="tab-pane fade">
              <div className="table-content">
                <div className="item active bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">
                      Chương II: Luyện tập với số tự nhiên
                    </h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương III: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương IV: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item bg-sh">
                  <div className="header flex-lc">
                    <h4 className="txt-2">Chương V: Số tự nhiên</h4>
                    <span>5/7 bài</span>
                    <button type="button" className="btn-collapse"></button>
                  </div>
                  <ul className="list">
                    <li className="active">
                      <p className="txt-5">
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                      <a href="" title="">
                        Luyện tập
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
