import AxiosService from "../axiosService";

const saveReportError = "create-report-error";
const saveReportErrorLesson = 'create-report-error-lesson'

export const postSaveReportError = (data) => {
  const axiosService = new AxiosService();

  return axiosService.postRequest(
    `${process.env.REACT_APP_API_URL}${saveReportError}`,
    data
  );
};

export const postSaveReportErrorLesson = (data) => {
  const axiosService = new AxiosService();

  return axiosService.postRequest(
      `${process.env.REACT_APP_API_URL}${saveReportErrorLesson}`,data
  )
}
