import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import ListQuiz from "./ListQuiz";
import { getHistoryTraining, sendVerifiedOtp } from "edu_lms/services/training";
import { onResultUserInfo } from "edu_lms/modules/selection";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import ModalUpdateInformation from "../TrainingTest/UpdateInformation/ModalUpdateInformation";
import LoginWrapper from "edu_lms_v2/modules/Login/LoginWrapper";
import * as PATH from "consts/routePaths";
import { getUserInfo } from "edu_lms/services/signIn/signIn";
import ForgotPassword from "edu_lms_v2/modules/ForgotPassword/ForgotPassword";
import PopupLoginSuccess from "edu_lms_v2/modules/Login/PopupLoginSuccess";
import PopupVerificationEmail from "../TrainingTest/UpdateInformation/components/PopupVerificationEmail";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import { EMAIL_VERIFIED } from "edu_lms/constants/type";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OPEN_TRAINING_INSTRUCTIONS } from "edu_lms/constants/eventNameGTM";
import PopupCountDownTraining from "./PopupCountDownTraining";
import PopupResendEmail from "../TrainingTest/UpdateInformation/components/PopupResendEmail";
import { toast } from "react-toastify";
import imgGuideTraining from "edu_lms_v2/assets/img/guideTraining.png";

const pathGuideTraining =
  "https://drive.google.com/file/d/1IPws2r3XQLrDmXYKPnBzEBbkhHbPZsq5/view";

export const HistoryQuiz = () => {
  const history = useHistory();
  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const { isOpenTraining } = JSON.parse(trainingConfig);
  const timeSendEmail = JSON.parse(localStorage.getItem("timeSendEmail"));
  const token = localStorage.getItem("token");
  useScrollToTop();

  const [dataHistory, setDataHistory] = useState([]);
  const [showUpdateInfor, setShowUpdateInfor] = useState(false);
  const [checkUpdateInfor, setCheckUpdateInfor] = useState("");
  const [login, setLogin] = useState(false);
  const [showPopupForgotPw, setShowPopupForgotPw] = useState(false);
  const [showLoginTrainingSuccess, setShowTrainingSuccess] = useState(false);
  const [userInfo, setUserInfo] = useState(onResultUserInfo());
  const [checkInformation, setCheckInformation] = useState(0);
  const [dataToken, setDataToken] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [showPopupVerifyEmail, setShowPopupVerifyEmail] = useState(false);
  const [showPopupResendEmail, setShowPopupResendEmail] = useState(false);
  const [isSendOTPSuccess, setSendOTPSuccess] = useState(false);
  const [seconds, setSeconds] = useState(timeSendEmail?.seconds ?? 0);
  const [minutes, setMinutes] = useState(timeSendEmail?.minutes ?? 0);
  const [showWarningUpdateSchool, setShowWarningUpdateSchool] = useState(false);
  
  const isUserTestBook = userInfo?.test_book_3_7_10;
  
  useEffect(() => {
    if (isUserTestBook) {
      history.push(PATH.ROUTE_PATH_V2_HOME);
    }
  }, []);
  
  const onGetDataHistory = () => {
    getHistoryTraining()
      .then((res) => {
        if (res.data.status === "success") {
          setDataHistory(res.data.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (token) {
      onGetDataHistory();
      getUserInfoApi();
    }
  }, [token, userInfo]);
  
  useEffect(() => {
    if(userInfo && !userInfo.school_id || (firstLoad && userInfo &&!userInfo.status_school)) {
      setShowWarningUpdateSchool(true);
      setFirstLoad(false);
    } else {
      setShowWarningUpdateSchool(false)
    }
  }, [userInfo]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    isSendOTPSuccess
      ? localStorage.setItem(
          "timeSendEmail",
          JSON.stringify({ seconds: 0, minutes: 0 })
        )
      : localStorage.setItem(
          "timeSendEmail",
          JSON.stringify({ seconds: seconds, minutes: minutes })
        );
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  const showUpdatePersonal = () => {
    setShowUpdateInfor(true);
    setCheckUpdateInfor("personal");
    setCheckInformation(0);
  };
  const showUpdateSchool = () => {
    setShowUpdateInfor(true);
    setCheckUpdateInfor("school");
    setCheckInformation(0);
  };
  const showUpdateClass = () => {
    setShowUpdateInfor(true);
    setCheckUpdateInfor("class");
    setCheckInformation(0);
  };
  const handleLogin = () => {
    setLogin(true);
  };
  const onLoginSuccess = (data) => {
    setLogin(false);
    setShowTrainingSuccess(true);
    setUserInfo(data);
  };
  const onActiveForgotPw = () => {
    setLogin(false);
    setShowPopupForgotPw(true);
  };
  const onUpdateSuccess = (data) => {
    setUserInfo((userInfo) => ({ ...userInfo, ...data }));
  };
  const handleRegister = () => {
    history.push(PATH.ROUTE_PATH_V3_REGISTER);
  };

  const getUserInfoApi = () => {
    if (localStorage.getItem("token")) {
      getUserInfo().then((res) => {
        if (res.data.status === "success") {
          setDataToken(res.data.data);
        }
      });
    }
  };
  const handleShowPopupVerifyEmail = () => {
    setShowPopupVerifyEmail(
      timeSendEmail?.seconds === 0 && timeSendEmail?.minutes === 0
    );
    setShowPopupResendEmail(
      timeSendEmail?.seconds !== 0 || timeSendEmail?.minutes !== 0
    );
  };

  const handleOpenGuideTraining = () => {
    setEventGTM({
      event: OPEN_TRAINING_INSTRUCTIONS,
    });
    window.open(PATH.ROUTE_GUIDE_TRAINING);
  };

  const onSubmit = () => {
    const data = {
      token: token,
    };
    sendVerifiedOtp(data).then((res) => {
      if (res.data.status === "success") {
        setShowPopupVerifyEmail(false);
        setShowPopupResendEmail(true);
        setSeconds(59);
        setMinutes(4);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  return (
    <>
      <SEO data={pages.training} />
      <div className="exam_exercises_wrapper monkey-bg-light-gray mt-5 pt-5">
        <ModalUpdateInformation
          show={showUpdateInfor}
          checkUpdateInfor={checkUpdateInfor}
          setShowUpdateInfor={setShowUpdateInfor}
          onHide={() => setShowUpdateInfor(false)}
          onGetDataHistory={onGetDataHistory}
          onUpdateSuccess={onUpdateSuccess}
          setCheckInformation={setCheckInformation}
          checkInformation={checkInformation}
        />

        <div className="container-fluid container-xl">
        <PopupCountDownTraining />
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="mt-2 mb-5">
                <h2 className="title monkey-color-orange">
                  Tập huấn 2024
                </h2>
                <div className="mb-2">
                  <p className="pl-2">
                    Để làm bài tập huấn, thầy cô vui lòng thao tác đầy đủ các
                    bước sau:
                  </p>
                  <img src={imgGuideTraining} alt="Hướng dẫn" />
                  <div className="pl-2 pt-2">
                    Thầy cô xem hướng dẫn chi tiết{" "}
                    <GuideTraining onClick={handleOpenGuideTraining}>"Tại đây"</GuideTraining>
                  </div>
                </div>
                {token ? (
                  <div className=" onepercent-office p-2">
                    <div className="address">
                      <Infor>
                        <span>
                          THÔNG TIN SỞ VÀ GIÁO VIÊN ĐỂ NHẬN KẾT QUẢ KIỂM TRA TẬP
                          HUẤN{" "}
                        </span>
                      </Infor>
                    </div>
                    <div className="row">
                      <Title className="col-lg-8 col-md-9 col-sm-9">
                        <div>
                          <ul>
                            <li>
                              <span>Họ và tên:</span> {dataToken?.full_name}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>Số điện thoại:</span> {userInfo?.phone}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>Email:</span> {userInfo?.email}{" "}
                              {userInfo?.email &&
                              userInfo?.email_verified === EMAIL_VERIFIED ? (
                                <span className="monkey-color-verified">
                                  (Đã xác thực)
                                </span>
                              ) : (
                                <>
                                  {userInfo?.email && (
                                    <>
                                      <span className="monkey-color-red">
                                        (Chưa xác thực)
                                      </span>
                                      <p className="d-flex align-items-center mt-2">
                                        Bạn cần xác thực email trước khi vào làm
                                        bài tập huấn.
                                        {/* {isOpenTraining && ( */}
                                          <button
                                            className="ml-3 btn btn-pr"
                                            onClick={handleShowPopupVerifyEmail}
                                          >
                                            Xác thực ngay
                                          </button>
                                        {/* )} */}
                                      </p>
                                    </>
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </Title>
                      <div className="col-lg-4 col-md-3 col-sm-3">
                        <UpdateInfoButton
                          // disabled={!isOpenTraining}
                          className="btn btn-pr px-4 py-2"
                          type="button"
                          onClick={() => showUpdatePersonal()}
                        >
                          Cập nhật
                        </UpdateInfoButton>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <Title className="col-lg-8 col-md-9 col-sm-9">
                        <div>
                          <ul>
                            <li>
                              <span>Sở GD:</span> {userInfo?.province_name}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>Phòng GD:</span> {userInfo?.district_name}
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>Trường:</span> {userInfo?.school_name}
                              {
                                  showWarningUpdateSchool && <BlinkingIcon  onClick={() => showUpdateSchool()} className="fa fa-pencil monkey-color-orange " style={{fontSize: "20px", marginLeft: "10px"}}  />
                              }
                            </li>
                          </ul>
                        </div>
                      </Title>
                      <div className="col-lg-4 col-md-3 col-sm-3">
                        <UpdateInfoButton
                          // disabled={!isOpenTraining}
                          className="btn btn-pr px-4 py-2"
                          type="button"
                          onClick={() => showUpdateSchool()}
                        >
                          Cập nhật
                        </UpdateInfoButton>
                      </div>
                    </div>
                    
                    <div className="onepercent-office py-2">
                      <Note className="pt-3 pb-4">
                        <h6 className="text-danger">Thầy cô lưu ý:</h6>
                        <ul>
                          <li>
                            Kết quả tập huấn được ghi nhận ở lần làm bài có số
                            điểm cao nhất. Kết thúc thời gian tập huấn, chúng
                            tôi sẽ gửi kết quả về Email cá nhân và đơn vị công
                            tác nơi thầy cô đã đăng kí theo thông tin trên.
                          </li>
                          {
                            showWarningUpdateSchool ? <li className="pt-1 text-danger">
                                Thầy cô vui lòng kiểm tra lại thông tin trường học để đảm bảo quyền lợi của mình.
                           
                                {/*<BlinkingIcon className="fa fa-pencil monkey-color-orange " style={{fontSize: "20px", marginLeft: "10px"}}   onClick={() => showUpdateSchool()}/>*/}
    
                              </li> :
                                <li className="pt-1">
                                  Thầy cô vui lòng cập nhật ĐÚNG và ĐẦY ĐỦ thông tin
                                  để đảm bảo quyền lợi của mình.
                                </li>
                          }
                       
                        </ul>
                      </Note>
                      <div className="address mb-2">
                        <Infor>
                          <span>KẾT QUẢ TẬP HUẤN</span>
                        </Infor>
                        <button
                          // disabled={!isOpenTraining}
                          id="ketqua"
                          className="btn btn-pr px-4 py-2 mt-2"
                          type="button"
                          onClick={() => showUpdateClass()}
                        >
                          Thêm môn kiểm tra tập huấn
                        </button>
                      </div>
                    </div>
                    <ListQuiz
                      data={dataHistory}
                      setShowUpdateInfor={setShowUpdateInfor}
                      showUpdateInfor={showUpdateInfor}
                      userInfo={userInfo}
                      checkInformation={checkInformation}
                      setCheckInformation={setCheckInformation}
                      onUpdateSuccess={onUpdateSuccess}
                      setLogin={setLogin}
                      dataToken={dataToken}
                      setDataToken={setDataToken}
                      getUserInfoApi={getUserInfoApi}
                      token={token}
                      isOpenTraining={isOpenTraining}
                      handleShowPopupVerifyEmail={handleShowPopupVerifyEmail}
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-start mt-4 mb-3 align-items-center">
                      <div class="justify-content-center">
                        <span className="text-center monkey-fz-24 mt-1">
                          Thầy cô vui lòng
                        </span>
                        <Login
                          className="btn_register btn-pr ml-2"
                          onClick={() => handleRegister()}
                        >
                          Đăng ký
                        </Login>
                        <span className="text-center monkey-fz-24 mt-1">
                          nếu chưa có tài khoản.
                        </span>
                      </div>
                    </div>
                    <div className="justify-content-start">
                      <span className="text-center monkey-fz-24 mt-1">
                        Thầy cô vui lòng
                      </span>
                      <Login
                        className="btn_register btn-pr  ml-2"
                        onClick={() => handleLogin()}
                      >
                        Đăng nhập
                      </Login>
                      <span className="text-center monkey-fz-24 mt-1">
                        {isOpenTraining
                          ? "để làm bài kiểm tra tập huấn nếu đã có tài khoản."
                          : "để làm bài và xem kết quả bài kiểm tra tập huấn của mình."}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoginWrapper
        show={login}
        onHide={() => setLogin(false)}
        onLoginSuccess={onLoginSuccess}
        onActiveForgotPw={onActiveForgotPw}
        showPopupForgotPw={showPopupForgotPw}
        setShowPopupForgotPw={setShowPopupForgotPw}
      />
      <ForgotPassword
        show={showPopupForgotPw}
        onHide={() => setShowPopupForgotPw(false)}
        setLogin={setLogin}
      />
      <PopupLoginSuccess
        show={showLoginTrainingSuccess}
        onHide={() => {
          setShowTrainingSuccess(false);
        }}
        path={PATH.ROUTE_PATH_V3_TRAINING}
      />
      <PopupVerificationEmail
        show={showPopupVerifyEmail}
        onHide={() => setShowPopupVerifyEmail(false)}
        userInfo={userInfo}
        seconds={seconds}
        minutes={minutes}
        onSubmit={onSubmit}
      />

      <PopupResendEmail
        show={showPopupResendEmail}
        onHide={() => setShowPopupResendEmail(false)}
        userInfo={userInfo}
        onSubmit={onSubmit}
        seconds={seconds}
        setSeconds={setSeconds}
        minutes={minutes}
        setMinutes={setMinutes}
        onUpdateSuccess={onUpdateSuccess}
        setSendOTPSuccess={setSendOTPSuccess}
      />
    </>
  );
};

const Title = styled.div`
  div {
    border: 1px solid #141414;
    ul {
      padding-left: 5px;
    }
  }
  li {
    list-style: none;
    padding: 5px 0px;
    color: #2a404f;
    font-weight: bold;
    span {
      display: inline-block;
      font-weight: 400;
      width: 20%;
      /* color: #19252e; */
    }
    @media (max-width: 600px) {
      span {
        width: 25%;
      }
    }
  }
`;

const UpdateInfoButton = styled.button`
  @media (max-width: 600px) {
    margin-top: 10px;
  }
`;

const Infor = styled.h4`
  span {
    position: relative;
    &:before {
      content: "";
      width: 100%;
      background-color: #141414;
      height: 1px;
      position: absolute;
      bottom: -2px;
    }
  }
`;

const Note = styled.div`
  li {
    list-style: none;
    span {
      color: #2a404f;
      font-weight: bold;
    }
  }
`;

const Login = styled.button`
  width: 108px;
  height: 40px;
  margin-right: 10px;
  border-radius: 8px;
  font-family: SVN-GilroyBold, sans-serif;
`;

const GuideTraining = styled.span`
  color: blue;
  cursor: pointer;
  font-style: italic;
`;
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const BlinkingIcon = styled.i`
  animation: ${blink} 1s infinite;
`;
