import { useLocation } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import Select from "react-select";
import { useAppStore } from "stores/appStore";
import { DIRECTION_VERTICAL } from "consts/general";
import CheckboxList from "./CheckboxList";

export default function GradeFilter({
  direction = DIRECTION_VERTICAL,
  onChange,
}) {
  const location = useLocation();
  const grades = useAppStore((state) => state.grades);
  const gradeId = useAppStore((state) => state.gradeId);
  const setGradeId = useAppStore((state) => state.setGradeId);

  //them fea hien thi onthiTHPT o moi tu-sach, con cac page khac thi an di(order tu Vepic dung co thac mac nhe!)
  const currentPage = location.pathname.includes('tu-sach');

  const filteredGrades = currentPage 
    ? grades 
    : grades.filter(grade => grade.id !== 26);

  const handleChange = (value) => {
    setGradeId(value);
    if (onChange) {
      onChange(value);
    }
  };

  const items = filteredGrades.map((grade) => ({
    ...grade,
    value: grade.id,
    label: grade.name,
    checked: grade.id === gradeId,
    tag: null,
  }));

  const currentGrade = items.find((grade) => grade.id === gradeId);

  return direction === DIRECTION_VERTICAL ? (
    <>
      {/* <CheckboxList title="Lớp học" items={items} onChange={handleChange} /> */}
      <Select
        className="d-block d-md-none mt-0 pt-0"
        value={currentGrade}
        options={items}
        placeholder="Chọn lớp"
        onChange={({ id }) => handleChange(id)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
      />
    </>
  ) : (
    <CheckboxList
      title="Lớp"
      items={items}
      onChange={handleChange}
      direction={direction}
      renderItem={({ item, onChange }) => {
        return (
          <CustomItem
            key={item.id}
            className={classNames({ active: item.checked })}
            onClick={() => {
              onChange(item.id);
            }}
          >
            {item.label}
          </CustomItem>
        );
      }}
    />
  );
}

const CustomItem = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #ff7707;
    font-weight: bold;
    color: #ff7707;
  }
`;
