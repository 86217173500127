import { Modal } from "react-bootstrap";
import styles from "../../teachingManager.module.scss";
import FormAddClassroom from "./FormAddClassroom";
import Loading from "edu_lms_v2/components/Loading";
import { ROLE, STATUS } from "../../constant";
import StatusSuccess from "./StatusSuccess";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";
export default function PopupAddClassroom({
  role,
  setRole,
  listClassroom,
  idClassroom,
  show,
  setShow,
  status,
  setStatus,
  setShowPopupAddMember,
  getListClassroom,
  setIdClassroom,
}) {
  const product = Number(process.env.REACT_APP_APP_ID);
  const handleClose = () => {
    setShow(false);
    setStatus(STATUS.START);
    setRole(ROLE.ADD);
  };
  const handleClickAddMember = () => {
    handleClose();
    setShowPopupAddMember(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={styles.modalDialog}
      backdrop="static"
    >
      <Modal.Header closeButton className="text-center">
        <p className="w-100 text-fsize font-weight-bold">
          {role === ROLE.ADD ? "Tạo lớp học mới" : "Chỉnh sửa lớp học"}
        </p>
      </Modal.Header>

      <Modal.Body className="p-4">
        {status === STATUS.START && (
          <>
            {product === HOC10_APP_ID && (
              <FormAddClassroom
                role={role}
                setRole={setRole}
                listClassroom={listClassroom}
                idClassroom={idClassroom}
                setStatus={setStatus}
                getListClassroom={getListClassroom}
                setIdClassroom={setIdClassroom}
              />
            )}
          </>
        )}
        {status === STATUS.LOADING && (
          <div className="text-center">
            <Loading />
            {role === ROLE.ADD && <p className="p-4">Đang tạo lớp...</p>}
            {role === ROLE.UPDATE && <p className="p-4">Đang chỉnh sửa...</p>}
          </div>
        )}
        {status === STATUS.SUCCESS && (
          <StatusSuccess
            title={
              role === ROLE.ADD
                ? "Tạo lớp học mới thành công"
                : "Chỉnh sửa lớp học thành công"
            }
            handleClose={handleClose}
            handleClickAddMember={handleClickAddMember}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
