import React from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import TermDetails from "../Term/TermDetails";

export default function ModalTerms({ show, onHide }) {
  return (
    <Modal
      animation="true"
      show={show}
      onHide={onHide}
      aria-labelledby="example-custom-modal-styling-title"
      size="xl"
      className="p-0"
    >
      <div className="hoc10-tab-content">
        <div id="term" className="tab-pane">
          <div className="term h1Seo">
            <div className="hoc10-contact pb-0">
              <Modal.Header closeButton className="border-0 py-0">
                <h1 className="title">Điều khoản dịch vụ</h1>
              </Modal.Header>
              <Terms>
                <Modal.Body className="py-0">
                  <TermDetails />
                </Modal.Body>
              </Terms>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
const Terms = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;
