import React from "react";

const DISEQUATIONS_EQUAL =
  "Miền nghiệm của bất phương trình là nửa mặt phẳng không bị gạch chứa điểm M, kể cả đường thẳng d.";
const DISEQUATIONS =
  "Miền nghiệm của bất phương trình là nửa mặt phẳng không bị gạch chứa điểm M, không kể đường thẳng d.";
const SYSTEM_OF_DISEQUATIONS =
  "Miền nghiệm của hệ bất phương trình đã cho là phần không bị gạch.";

const Conclusion = ({ coefficients }) => {
  const hasEqualCompare = coefficients[0].markGame === "\\le" || coefficients[0].markGame === "\\ge";

  return (
    <div className="monkey-fz-20 pt-2 pr-4">
      <span className="monkey-f-header">Kết luận:</span>&nbsp;
      {coefficients.length === 1 && (
        <>{hasEqualCompare ? DISEQUATIONS_EQUAL : DISEQUATIONS}</>
      )}
      {coefficients.length > 1 && SYSTEM_OF_DISEQUATIONS}
    </div>
  );
};

export default Conclusion;
