import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect, useSelector, useDispatch } from "react-redux";

import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import {
  onDispatchDataQuestion,
  onDispatchUpdateBookToolPage,
} from "../../modules/ReadingBooks/actions";
import { TYPE_POPUP_SUPPORT } from "../../constants/type";
import * as TYPE from "../../constants/general";
import { onDispatchIsClickStart } from "../ListQuestion/actions";
import { setEventGTM } from "../../constants/googleTagManager";
import {
  onDispatchIsClickSubmitAnswer,
  onDispatchIsClickRefresh,
  onDispatchClickDownImg,
  onDispatchPlayMusic,
} from "../../modules/General/actions";
import { CLICK_EXERCISE } from "../../constants/eventNameGTM";
import popupStyle from "./Popup.module.scss";
import { AlertDefault } from "../Game/selection";
import { onDispatchDataAlert } from "../ListQuestion/actions";

export const Image = styled.img`
  src: url(${(props) => props.src});
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const BetaText = styled.div`
  display: inline-block;
  margin-right: 5px;
  padding: 2px 8px;
  background-color: #efefef;
  border-radius: 5px;
  font-size: 16px;
`;

export const GamePopup = (props) => {
  const dispatch = useDispatch();
  const {
    buttonLabel,
    className,
    modalTitle,
    content,
    statusModal,
    size,
    backdrop,
    type,
  } = props;
  const bookType = useSelector((state) => state.readingBookReducers.bookType);

  useEffect(() => {
    dispatch(onDispatchDataAlert(AlertDefault));
  }, [statusModal]);
  const toggle = () => {
    setEventGTM({
      event: CLICK_EXERCISE,
      book_type: props.bookInfo.bookType,
      book_grade: props.bookInfo.bookGrade,
      book_subject: props.bookInfo.bookSubject,
      start_exercise: props.isClickStart,
      submit_answer: props.isClickSubmitAnswer,
      refresh_exercise: props.isClickRefresh,
      count_download_image: props.isClickDownImg,
      play_background_music: props.isPlayMusic,
    });
    props.onDispatchIsClickStart(false);
    props.onDispatchIsClickRefresh(false);
    props.onDispatchIsClickSubmitAnswer(false);
    props.onDispatchClickDownImg(false);
    props.onDispatchPlayMusic(false);
    props.onDispatchShowPopupActivateBook(!props.statusModal);
    props.onDispatchDataQuestion([]);
    props.onDispatchUpdateBookToolPage({ show: false, control: false });
  };

  return (
    <Modal
      isOpen={statusModal}
      toggle={toggle}
      className={className}
      backdrop={backdrop || "static"}
      size={size ? size : ""}
    >
      {props.bgWrapper && <Image src={props.bgWrapper} />}
      <div
        className="position-relative"
        style={{ zIndex: "3", height: "100%", color: "#101010" }}
      >
        <ModalBody
          cssModule={{
            "modal-body": `${type == TYPE_POPUP_SUPPORT ? "pb-5" : ""}`,
          }}
        >
          <ModalHeader
            toggle={toggle}
            cssModule={{
              "modal-header": "modal-header align-items-center",
              "modal-title":
                "modal-title d-flex justify-content-between flex-grow-1",
            }}
          >
            <div
              className={`text-uppercase monkey-color-violet monkey-f-header ${popupStyle.fontSizeH5}`}
            >
              {modalTitle || ""}
            </div>

            {bookType === TYPE.TYPE_TEXT_WORK_BOOKS && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="betatext-tooltip-bottom">
                    Bạn đang dùng Phiên bản thử nghiệm. Hãy góp ý với chúng tôi
                    để chương trình hoàn thiện hơn. Xin cảm ơn.
                  </Tooltip>
                }
              >
                <BetaText>Phiên bản thử nghiệm</BetaText>
              </OverlayTrigger>
            )}
          </ModalHeader>
          {content}
        </ModalBody>
        {buttonLabel && (
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {buttonLabel}
            </Button>
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  const {
    bookInfo,
    isClickSubmitAnswer,
    isClickRefresh,
    isClickDownImg,
    isPlayMusic,
  } = state.generalReducer;
  const { isClickStart } = state.listQuestion;
  return {
    bookInfo,
    isClickStart,
    isClickSubmitAnswer,
    isClickRefresh,
    isClickDownImg,
    isPlayMusic,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
      onDispatchDataQuestion,
      onDispatchUpdateBookToolPage,
      onDispatchIsClickStart,
      onDispatchIsClickRefresh,
      onDispatchIsClickSubmitAnswer,
      onDispatchClickDownImg,
      onDispatchPlayMusic,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GamePopup);
