import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { DOCUMENT_TYPE_VIDEO_DRIVE } from "consts/book";

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  iframe {
    top: 0;
    left: 0;
  }
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  right: 0;
  top: -32px;
  color: white;
  cursor: pointer;
  font-size: 28px;
`;

function VideoPlayerModal({ url, show, onClose, typeDocument }) {
  return (
    <Modal show={show} onHide={onClose} centered size="xl">
      <PlayerWrapper>
        {typeDocument !== DOCUMENT_TYPE_VIDEO_DRIVE && (
          <Player
            width="100%"
            height="100%"
            url={url}
            className="react-player"
            playing
            controls
          />
        )}

        {typeDocument === DOCUMENT_TYPE_VIDEO_DRIVE && (
          <iframe
            className="position-absolute w-100 h-100 border-0"
            src={`https://drive.google.com/file/d/${url}/preview`}
            title="video-driver"
            allow="autoplay"
          />
        )}

        <CloseButton onClick={onClose}>
          <i className="fa fa-times" aria-hidden="true"></i>
        </CloseButton>
      </PlayerWrapper>
    </Modal>
  );
}

export default VideoPlayerModal;
