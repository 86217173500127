import { useState } from "react";
import _ from "lodash";
import Select from "react-select";
import styles from "../BlogStyle.module.scss";
import Post from "./Post";
import { OPTION_SUBJECT } from "../constant";
import FilterSubject from "./FilterSubject";

export default function ListDataSubject({
  listAllSubjects,
  setSubjectsName,
  subjectsName,
  dataSubjects,
  setCurrentPage,
  currentPage,
  optionSubjects,
  setOptionSubjects,
}) {
  const [isShowFilterSubject, setIsShowFilterSubject] = useState(false);
  const handleFilterSubject = (subject) => {
    let listSubjects = subjectsName;
    if (_.includes(subjectsName, subject)) {
      listSubjects = subjectsName.filter((item) => item !== subject);
    } else {
      listSubjects = [...subjectsName, subject];
    }
    setSubjectsName(listSubjects);
  };

  return (
    <div className={styles.subjectContainer}>
      <div className="hoc10-overview p-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 filter__item">
              <div className={`filter ${styles.subjectTop}`}>
                <p className={styles.knowledgeSubject}>Môn</p>
                <div className={`filter__item__list ${styles.itemSubject}`}>
                  <FilterSubject
                    dataSubjects={listAllSubjects.cate_by_slug}
                    handleFilterSubject={handleFilterSubject}
                    subjectsName={subjectsName}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <hr className={styles.hr} />
              <div className={`justify-content-end ${styles.news}`}>
                <label className={styles.label}>Sắp xếp theo:</label>
                <div className={styles.options}>
                  <div className="row justify-content-between">
                    <div className={`col-6 col-md-12 ${styles.selectOption}`}>
                      <Select
                        options={OPTION_SUBJECT}
                        maxMenuHeight={500}
                        value={optionSubjects}
                        menuPortalTarget={document.body}
                        onChange={(subject) => setOptionSubjects(subject)}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 5 }),
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <div className="result">
                        <div
                          className={`result__header flex m-0 ${styles.selectContainer}`}
                        >
                          <div
                            className={`fil fil--sort flex-lc mt-0 ${styles.btnFilter}`}
                          >
                            <button
                              type="button"
                              className={`btn_filter_m m-s flex-lc tf2 m-0 ${styles.btnFilterSubject}`}
                              onClick={() =>
                                setIsShowFilterSubject(!isShowFilterSubject)
                              }
                            >
                              Bộ lọc
                              <i
                                class={`fa fa-filter ${styles.iconFilter}`}
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.filter}>
                <p>Tìm được {dataSubjects?.total || 0} kết quả</p>
                <div className={styles.filterContainer}>
                  {subjectsName.map((subject, index) => (
                    <button key={index} className={styles.subjectFilter}>
                      {subject?.name}
                      <i
                        class={`${styles.iconClose} fa fa-times`}
                        aria-hidden="true"
                        onClick={() => handleFilterSubject(subject)}
                      />
                    </button>
                  ))}
                  {!_.isEmpty(subjectsName) && (
                    <p
                      className="monkey-color-yellow cursor"
                      onClick={() => setSubjectsName([])}
                    >
                      Xóa tất cả
                    </p>
                  )}
                </div>
              </div>
              <Post
                dataSubjects={dataSubjects}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
      {isShowFilterSubject && (
        <div className={`filter-fix-left show ${styles.filterCate}`}>
          <div className="logo">
            <img src="/assets/img/updated_logo.png" alt="Logo Hoc10" />
          </div>
          <h3>Bộ lọc</h3>
          <button
            type="button"
            className="close"
            onClick={() => {
              setIsShowFilterSubject(!isShowFilterSubject);
            }}
          />
          <div className="filter">
            <FilterSubject
              dataSubjects={listAllSubjects.cate_by_slug}
              handleFilterSubject={handleFilterSubject}
              subjectsName={subjectsName}
            />
          </div>
          <button
            type="button"
            className="btn_apply btn-pr flex-center"
            onClick={() => {
              setIsShowFilterSubject(!isShowFilterSubject);
            }}
          >
            Áp dụng
          </button>
        </div>
      )}
    </div>
  );
}
