import { getGraphFactorWithSuffix } from "./graph";
import { COLOR } from "../constants/styles";
import { GRAPH_OBJECT } from "../constants/Graph";
import { COLOR_REGIONS } from "../constants/SROI_001";

const formatActivityDataSROI_001 = (activity) => {
  const { game_config: gameConfig } = activity;

  const graphDataSetList = gameConfig.data.map((dataGameConfigItem) => {
    return {
      graphType: Number(dataGameConfigItem.type_graph?.icon_id),
      coefficients: dataGameConfigItem.coefficient.map((coe) => ({
        a: Number(coe.coefficient_a),
        b: Number(coe.coefficient_b),
        c: Number(coe.coefficient_c),
        markGame: coe.markGame,
      })),
      coor: {
        negativeX: Number(dataGameConfigItem.negative_coor_x),
        negativeY: Number(dataGameConfigItem.negative_coor_y),
        positiveX: Number(dataGameConfigItem.positive_coor_x),
        positiveY: Number(dataGameConfigItem.positive_coor_y),
      },
    };
  });

  return graphDataSetList;
};

const getEquationGraphLine = (a, b, c, markGame) => {
  return `${getGraphFactorWithSuffix(a, "x")} ${
    b > 0 && a !== 0 ? "+" : ""
  } ${getGraphFactorWithSuffix(b, "y")} ${markGame} ${c}`;
};

const getEquationGraphSROI = (coefficients) => {
  if (coefficients.length === 1) {
    const { a, b, c, markGame } = coefficients[0];
    return `\\begin{equation*}${getEquationGraphLine(a, b, c, markGame)}\\end{equation*}`;
  }

  let equationGraphLines = "\\begin{cases}";
  coefficients.forEach((coefficient, index) => {
    const { a, b, c, markGame } = coefficient;
    equationGraphLines += `${getEquationGraphLine(a, b, c, markGame)}\\qquad &(${index+1})\\\\`;
  });
  equationGraphLines += "\\end{cases}";
  return equationGraphLines;
};

const renderDescriptionDisequations = () => {
  return "Nhập tọa độ điểm M bất kì thuộc miền nghiệm của bất phương trình mà không nằm trên đường thẳng d";
};

const renderDescriptionSystemOfInequalities = (numberOfLines) => {
  let titlePoints = "",
    titleEquations = "",
    titleLines = "";
  for (let i = 1; i <= numberOfLines; i++) {
    titlePoints += `M<sub>${i}</sub>, `;
    titleEquations += `(${i}), `;
    titleLines += `d<sub>${i}</sub>, `;
  }
  titlePoints = titlePoints.slice(0, -2);
  titleEquations = titleEquations.slice(0, -2);
  titleLines = titleLines.slice(0, -2);

  return (
    <>
      Nhập tọa độ các điểm{" "}
      <span dangerouslySetInnerHTML={{ __html: titlePoints }} /> bất kì lần lượt
      thuộc miền nghiệm của các bất phương trình {titleEquations} mà không nằm
      trên các đường thẳng{" "}
      <span dangerouslySetInnerHTML={{ __html: titleLines }} />
    </>
  );
};

const getNewCoefficientBySelectedCoordinate = (selectedCoordinate) => {
  const { pointA, pointB } = selectedCoordinate;
  const x1 = pointA.x;
  const y1 = pointA.y;
  const x2 = pointB.x;
  const y2 = pointB.y;

  if (x1 === x2) {
    return { a: 1, b: 0, c: x1 };
  }

  if (y1 === y2) {
    return { a: 0, b: 1, c: y1 };
  }

  const a = (y2 - y1) / (x2 - x1);
  const c = y1 - a * x1;
  return { a: -a, b: 1, c: c };
};

const DISTANCE_COORDINATE_EXTEND = 10;
const getCombPointsWithSelectedCoordinate = (selectedCoordinate, coor) => {
  const { a: a1, b: b1, c: c1 } = getNewCoefficientBySelectedCoordinate(selectedCoordinate);

  const { negativeX, positiveY, positiveX, negativeY } = coor;
  const negativeX1 = negativeX - DISTANCE_COORDINATE_EXTEND;
  const positiveY1 = positiveY + DISTANCE_COORDINATE_EXTEND;
  const positiveX1 = positiveX + DISTANCE_COORDINATE_EXTEND;
  const negativeY1 = negativeY - DISTANCE_COORDINATE_EXTEND;

  const coordinatesY = (c1 - a1 * negativeX1) / b1;
  const coordinatesX = (c1 - b1 * negativeY1) / a1;
  let coordinatesLeft = [negativeX1, coordinatesY];
  let coordinatesRight = [-negativeX1, coordinatesY];

  if (a1 > 0 && b1 > 0) {
    coordinatesLeft = [coordinatesX, negativeY1];
    coordinatesRight = [negativeX1, coordinatesY];
  }
  if (a1 < 0 && b1 < 0) {
    coordinatesLeft = [negativeX1, coordinatesY];
    coordinatesRight = [coordinatesX, negativeY1];
  }
  if (a1 > 0 && b1 < 0) {
    coordinatesLeft = [coordinatesX, negativeY1];
    coordinatesRight = [positiveX1, (c1 - a1 * positiveY1) / b1];
  }
  if (a1 < 0 && b1 > 0) {
    coordinatesLeft = [(c1 - b1 * positiveY1) / a1, positiveY1];
    coordinatesRight = [negativeX1, coordinatesY];
  }

  if (b1 === 0) {
    coordinatesLeft = [coordinatesX, negativeY1];
    coordinatesRight = [coordinatesX, -negativeY1];
  }

  return [coordinatesLeft, coordinatesRight];
}

const drawRegionInequalityWithPointInput = ({
  selectedCoordinate,
  inputtedCoordinate,
  coor,
  board,
  lineIndex,
  hideResultAnswer,
}) => {
  const { a: a1, b: b1, c: c1 } = getNewCoefficientBySelectedCoordinate(selectedCoordinate);
  const resultCompare = a1 * Number(inputtedCoordinate.x) + b1 * Number(inputtedCoordinate.y) - c1;

  if (resultCompare !== 0) {
    const combPoints = getCombPointsWithSelectedCoordinate(selectedCoordinate, coor);
    const isWrongComb = selectedCoordinate.isCorrect === false || inputtedCoordinate.isCorrect === false;
    board.create(GRAPH_OBJECT.Comb, combPoints, {
      highlight: false,
      curve: {
        strokeColor: (isWrongComb && !hideResultAnswer) ? COLOR.Error : COLOR_REGIONS[lineIndex],
        width: 200,
        frequency: 0.3,
        angle: resultCompare < 0 ? -Math.PI / 3 : Math.PI / 3,
      },
    });
  }
};

const get2PointsOnTheStraightLine = (a, b, c) => {
  let xA = 0, yA = 0, xB = 0, yB = 0;
  if (a === 0 && b !== 0) {
    yA = c / b;
    yB = c / b;
    xB = xA + 1;
  }
  if (b === 0 && a !== 0) {
    xA = c / a;
    xB = c / a;
    yB = yA + 1;
  }
  if (a !== 0 && b !== 0) {
    xA = 0;
    yA = (c - a * xA) / b;
    xB = 1;
    yB = (c - a * xB) / b;
  }
  return [
    { x: xA, y: yA, isCorrect: true },
    { x: xB, y: yB, isCorrect: true },
  ];
};

const getPointInTheInequality = (a, b, c, markGame) => {
  let c1 = c;
  if (markGame === ">" || markGame === "\\ge") {
    c1 = c + 1;
  }
  if (markGame === "<" || markGame === "\\le") {
    c1 = c - 1;
  }

  let x = 0, y = 0;
  if (a === 0 && b !== 0) {
    y = c1 / b;
  }
  if (b === 0 && a !== 0) {
    x = c1 / a;
  }
  if (a !== 0 && b !== 0) {
    x = 0;
    y = (c1 - a * x) / b;
  }

  return { x, y };
};

export {
  formatActivityDataSROI_001,
  getEquationGraphLine,
  getEquationGraphSROI,
  renderDescriptionDisequations,
  renderDescriptionSystemOfInequalities,
  drawRegionInequalityWithPointInput,
  get2PointsOnTheStraightLine,
  getPointInTheInequality,
};
