import * as TYPES from "edu_lms/constants/actionsType";
import {
  DEFAULT_GRADE_ID,
  DEFAULT_SUBJECT_ID,
  DEFAULT_VALUE_BLOCK,
} from "./constant";

let initialState = {
  list: {
    block: DEFAULT_VALUE_BLOCK,
    gradeId: DEFAULT_GRADE_ID,
    subjectId: DEFAULT_SUBJECT_ID,
  },
};

let mockTestReducers = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PA_UPDATE_MOCK_TEST_FILTER:
      return { ...state, list: action.data };

    default:
      return state;
  }
};

export default mockTestReducers;
