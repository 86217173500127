import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import PopupMatchUserSuccess from "./PopupMatchUserSuccess";

export default function PopupMatchUser({
  show,
  setShow,
  username,
  userInClassroom,
  syncUser,
  showPopupMatchUserSuccess,
  setShowPopupMatchUserSuccess,
  redirectExercise,
}) {
  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      size="md"
      animation="true"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Thông báo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="monkey-fz-20 px-3 py-4">
          Bạn có muốn đồng bộ tài khoản&nbsp;
          <span className="monkey-f-header">{username}</span> với&nbsp;
          <span className="monkey-f-header">{userInClassroom.name}</span>
          &nbsp;trong lớp không.
        </div>
        <div className="border-top d-flex justify-content-around mb-4 pt-4">
          <Button
            type="button"
            onClick={() => setShow(false)}
            className="monkey-color-orange px-5 py-2 rounded"
          >
            Hủy
          </Button>
          <button
            type="button"
            onClick={syncUser}
            className="text-white px-4 py-2 monkey-bg-orange rounded"
          >
            Đồng bộ
          </button>
        </div>
      </Modal.Body>
      <PopupMatchUserSuccess
        show={showPopupMatchUserSuccess}
        setShow={setShowPopupMatchUserSuccess}
        redirectExercise={redirectExercise}
      />
    </Modal>
  );
}

const Button = styled.button`
  border: 1px solid #ff7707;
`;
