import { Modal } from "react-bootstrap";
import styled from "styled-components";
import danger_img from "edu_lms_v2/assets/img/img-error.svg";

export default function ConfirmBox({
  yes,
  no,
  visible,
  onCancel,
  onConfirm,
  //   className,
  message,
}) {
  const handleConfirm = () => {
    onCancel();
    onConfirm();
  };

  return (
    <Modal
      show={visible}
      onHide={() => {
        onCancel();
      }}
      centered={true}
      className="monkey-color-black"
    >
      <Modal.Header closeButton className="border-0">
        <p className="h3 w-100 text-center">Chú ý</p>
      </Modal.Header>
      <Modal.Body className="border-0 text-center p-0">
        <div className="d-flex justify-content-center mb-3">
          <img src={danger_img} />
        </div>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-center">
        <Button className="btn-sub " onClick={handleConfirm}>
          {yes}
        </Button>
        <Button
          className="border-0 btn-pr"
          onClick={() => {
            onCancel();
          }}
        >
          {no}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Button = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 7px;
`;
