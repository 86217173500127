import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { onDispatchSrcAudio } from "edu_lms/modules/App/actions";
import { AUDIO_ERROR, AUDIO_SUCCESS } from "edu_lms/constants/type";
import { styleWrapper } from "../../constants/styles";
import { checkResultAnswer } from "../../helpers/MTC_003";
import HeaderQuestion from "./HeaderQuestion";
import MultiChoiceContent from "./MultiChoiceContent";
import "./styles.scss";

const MTC_003 = ({
  gameData,
  selectedAnswerProp = null,
  hideResultAnswer = false,
  showCorrectAnswer = false,
  isReadOnly = false,
  theme,
  onPlaying = () => {},
  onComplete = () => {}
}, ref) => {
  const { question, answer } = gameData;

  const dispatch = useDispatch();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const handleChooseAnswer = (answerId) => {
    if (isCheckedAnswer) {
      handleResetAnswer();
    }
  
    setSelectedAnswer(answerId);
    onPlaying(false);
  };

  /*---------- Show selected answer result ----------*/
  useEffect(() => {
    if (selectedAnswerProp) {
      setSelectedAnswer(selectedAnswerProp);
      setIsCheckedAnswer(true);
    }
  }, [selectedAnswerProp]);

  /*---------- Show all correct answer result ----------*/
  useEffect(() => {
    if (showCorrectAnswer) {
      const correctAnswer = answer.answers.find(
        (answerItem) => answerItem.isCorrect
      );
      if(correctAnswer && correctAnswer != undefined && correctAnswer.answerId) {
        setSelectedAnswer(correctAnswer.answerId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleResetAnswer = () => {
    setSelectedAnswer(null);
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
  };

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const isCorrect = checkResultAnswer(answer.answers, selectedAnswer);
    !hideResultAnswer && dispatch(onDispatchSrcAudio(isCorrect ? AUDIO_SUCCESS : AUDIO_ERROR));

    onComplete({ selectedAnswer, isCorrect });
  };

  return (
    <div className="multiple-choice p-3" style={styleWrapper}>
      <HeaderQuestion question={question} theme={theme} />
      <WrapMultiChoiceContent>
        <MultiChoiceContent
          answer={answer}
          isCheckedAnswer={isCheckedAnswer}
          hideResultAnswer={hideResultAnswer}
          showCorrectAnswer={showCorrectAnswer}
          isReadOnly={isReadOnly || isViewOnly}
          theme={theme}
          selectedAnswers={[selectedAnswer]}
          onClickAnswer={handleChooseAnswer}
        />
      </WrapMultiChoiceContent>
    </div>
  );
};

export default forwardRef(MTC_003);

const WrapMultiChoiceContent = styled.div`
  margin: 0 auto;
`;
