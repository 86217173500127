import { TYPE_POPUP_PDF, URL_IMAGE } from "edu_lms/constants/type";
import {
  onDispatchShowPopupActivateBook,
  onDispatchShowPopupPDF,
  onDispatchTypePopup,
} from "edu_lms/modules/App/actions";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import img_update from "edu_lms_v2/assets/img/dang_cap_nhap.png";
import { PAGE_TRAINING } from "../const";
import thumPdf from "edu_lms_v2/assets/img/tai_lieu_tap_huan_2022.png"

export default function Pdf({ item, col, index, page }) {
  const dispatch = useDispatch();

  const viewPdf = (url, title, is_url) => {
    const dataSuccess = {
      title: title,
      url: url,
    };
    if (is_url !== "null") {
      window.open(url, "_blank");
    }
    // dispatch(onDispatchTypePopup(TYPE_POPUP_PDF));
    // dispatch(onDispatchShowPopupActivateBook(true));
    // dispatch(onDispatchShowPopupPDF(dataSuccess));
  };

  return (
    <div key={index} className={col}>
      <article
        className="post bg-sh pr"
        style={{ boxShadow: "5px 5px 10px 1px #ccc" }}
      >
        <BookBlocker>
          <a className="post__thumb cursor">
            {page === PAGE_TRAINING ? (
              <img
                src={thumPdf}
                alt=""
                onClick={() => viewPdf(item.url, item.title, item.is_url)}
              />
            ) : (
              <img
                src={item.is_thumb !== "null" ? item.thumb : img_update}
                alt=""
                onClick={() => viewPdf(item.url, item.title, item.is_url)}
              />
            )} 
            
          </a>
        </BookBlocker>

        <div
          className="post__content d-flex justify-content-between"
          style={{
            padding: "16px 10px",
            borderTop: "1px solid #FF7707",
            flex: "1",
          }}
        >
          {page === PAGE_TRAINING ? (
            <Semester
              title={`${item.worksheet_title} - Tài liệu tập huấn`}
            >{`${item.worksheet_title} - Tài liệu tập huấn`}</Semester>
          ) : (
            <Semester
              title={`${item.worksheet_title}`}
            >{`${item.worksheet_title}`}</Semester>
          ) }
          
          {item.is_url !== "null" && (
            <Download
              className="cursor"
              onClick={() => viewPdf(item.url, item.title, item.is_url)}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </Download>
          )}
        </div>
      </article>
    </div>
  );
}

const BookBlocker = styled.div`
  .block {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    border-radius: 12px 12px 0 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    .label-active {
      line-height: 20px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      padding: 0 15px;
    }
  }

  &:hover .block {
    display: flex;
  }
`;

const Semester = styled.h4`
  padding: 10px 0 0;
  text-transform: none !important;
`;

const Download = styled.a`
  padding-top: 10px;
  i {
    padding: 8px 10px;
    border: 1px solid #ff7707;
    border-radius: 5px;
    color: #ff7707;
    &:hover {
      background-color: #ff7707;
      color: #fff;
    }
  }
`;
