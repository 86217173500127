import React from "react";
import styled from "styled-components";
import styles from "../BlogStyle.module.scss";
import Pagination from "edu_lms_v2/components/Pagination";
import { TOTAL_SUBJECT_KNOWLEDGE } from "../constant";
import PostDetail from "../Knowledge/PostDetail";

export default function Post({ dataSubjects, setCurrentPage, currentPage }) {
  return (
    <div className={styles.newPostContainer}>
      {dataSubjects?.total ? (
        <div className={styles.itemTop}>
          <div className="container p-0">
            <div className="row">
              {dataSubjects?.data.map((item, index) => (
                <Div
                  key={index}
                  className={`col-lg-6 col-md-6 ${styles.newPostItem}`}
                >
                  <PostDetail dataPost={item} />
                </Div>
              ))}
            </div>
          </div>
          <div className={styles.pagination}>
            {dataSubjects.total > TOTAL_SUBJECT_KNOWLEDGE && (
              <Pagination
                total={dataSubjects.total}
                totalPerPage={TOTAL_SUBJECT_KNOWLEDGE}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      ) : (
        <h2 className="title-list-book pt-5 h2 text-center monkey-color-yellow">
          Không có bài viết
        </h2>
      )}
    </div>
  );
}
const Div = styled.div`
  .post-item {
    height: 430px;
  }
`;
