import * as types from "edu_lms/constants/actionsType";

export const onDispatchSetCurrentPageClassroom = (data) => {
  return {
    type: types.PA_SET_CURRENT_PAGE_CLASSROOM,
    data,
  };
};

export const onDispatchSetSelectOptionSchoolYear = (data) => {
  return {
    type: types.PA_SET_SELECT_OPTION_SCHOOL_YEAR,
    data,
  };
};

export const onDispatchSetSearchkeyClassroom = (data) => {
  return {
    type: types.PA_SET_SEARCHKEY_CLASSROOM,
    data,
  };
};
