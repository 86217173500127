import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useForm, Controller } from "react-hook-form";
import _ from "lodash";
import { postDataUpdateContact } from "../../services/dashboard";
import {
  onDispatchShowPopupActivateBook,
  onDispatchTogglePopupSuccess,
  onDispatchShowLoading,
} from "../../modules/App/actions";
import {
  getDataUrlListProvince,
  getDataUrlListSchoolByProvince,
} from "../../services/info/info";
import { onResultUserInfo } from "../../modules/selection";
import { dataForm } from "./config";
import SelectBox from "./SelectBox";
import InputForm from "./InputForm";
// import * as TEXT from "../../constants/text";
// import * as PATH from "../../constants/path";

const ContentPopupFormDashboard = ({
  statusModal,
  listProvince,
  listAccountProvince,
  onDispatchShowPopupActivateBook,
  onDispatchTogglePopupSuccess,
  onDispatchShowLoading,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    errors,
    formState: { isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });
  const [dataConfig, setStateDataConfig] = useState([]);
  const [errorMessage, setStateErrorMessage] = useState("");
  const userInfo = onResultUserInfo();
  const isDisabled = !isDirty || !isValid;

  useEffect(() => {
    const resultProvinceId = listProvince.filter(
      (item) => item.id === userInfo.province_id
    )[0];
    const resultDistrict = listAccountProvince.list_account.filter(
      (item) => item.district_id === userInfo?.district_id
    )[0];
    const valueDistrict = {
      ...resultDistrict,
      label: resultDistrict.name,
      value: resultDistrict.name,
    };
    let listDistrict = [];
    let dataConfig = [];
    if (listAccountProvince) {
      listDistrict = listAccountProvince?.list_account.map((item, index) => {
        const value = `district-${index + 1}`;
        const label = item.name;
        const district_id = item.district_id;
        return { district_id, value, label };
      });
      dataConfig = dataForm.map((item) => {
        if (item.id === "provinceId") {
          item.data.options = listProvince;
          item.data.value = resultProvinceId;
        }
        if (item.id === "districtId") {
          item.data.options = listDistrict;
          item.data.value = Number(userInfo.role_id) === 2 ? valueDistrict : "";
        }
        return { ...item };
      });
    }
    if (userInfo.district_id) {
      onDispatchShowLoading(true);
      onGetDataUrlListProvince(userInfo.district_id);
    }
    setStateDataConfig(dataConfig);
  }, [listProvince, listAccountProvince]);

  const onGetDataUrlListProvince = (districtId) => {
    onDispatchShowLoading(true);
    getDataUrlListProvince(districtId)
      .then((res) => {
        onDispatchShowLoading(false);
        if (res.data.status === "success") {
          const listWard = res.data.data.map((item, index) => {
            const value = `ward_${index + 1}`;
            const label = item.name;
            return { ...item, value, label };
          });
          const dataConfig = dataForm.map((item) => {
            if (item.id === "warId") {
              item.data.options = listWard;
            }
            return { ...item };
          });
          setStateDataConfig(dataConfig);
        }
      })
      .catch((errors) => {
        onDispatchShowLoading(false);
      });
  };

  const onSubmit = (dataForm, e) => {
    const data = {
      provinceId: dataForm.provinceName.id,
      districtId: dataForm.districtName.district_id,
      data_update_contact: [
        {
          full_name: dataForm.fullName,
          email: dataForm.email,
          phone: dataForm.phone,
        },
      ],
    };
    postDataUpdateContact(data).then((res) => {
      if (res.data.status === "success") {
        onDispatchShowPopupActivateBook(false);
        const dataSuccessConfig = {
          status: true,
          title: ["THÊM PHÒNG THÀNH CÔNG"],
          url: "",
          close: true,
        };
        setTimeout(function () {
          onDispatchTogglePopupSuccess(dataSuccessConfig);
        }, 0);
      }
      if (res.data.status === "fail") {
        setStateErrorMessage(res.data.message);
      }
    });
  };
  const onChangeSelect = (data) => {
    const editDataConfig = dataConfig.map((item) => {
      if (item.id === "warId") {
        item.data.value = data;
      }
      return { ...item };
    });
    setStateDataConfig(editDataConfig);

    getDataUrlListSchoolByProvince(data.id, 0)
      .then((res) => {
        onDispatchShowLoading(false);
        if (res.data.status === "success") {
          const listSchool = res.data.data.map((item, index) => {
            const value = `school${index + 1}`;
            const label = item.name;
            return { ...item, value, label };
          });
          const dataConfig = dataForm.map((item) => {
            if (item.id === "schoolId") {
              item.data.options = listSchool;
            }
            return { ...item };
          });
          setStateDataConfig(dataConfig);
        }
      })
      .catch((errors) => {
        onDispatchShowLoading(false);
      });
  };

  return (
    <div className="p-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {dataConfig.map((item, index) => {
            if (_.includes(item.roleId, Number(userInfo.role_id))) {
              switch (item.data.key) {
                case "text":
                  return (
                    <InputForm
                      key={index}
                      item={item}
                      errors={errors[`${item.data.name}`]?.message}
                      nameControl={control}
                      roleId={Number(userInfo.role_id)}
                    />
                  );
                  break;
                case "select":
                  return (
                    <SelectBox
                      key={index}
                      item={item}
                      nameControl={control}
                      roleId={Number(userInfo.role_id)}
                      onChangeSelect={onChangeSelect}
                    />
                  );
                  break;
                default:
                  return "";
              }
            }
          })}
        </div>
        {errorMessage && <p>{errorMessage}</p>}
        <div className="text-right">
          <button
            type="submit"
            disabled={isDisabled}
            style={{ opacity: isDisabled ? "0.5" : "1" }}
            className={`${
              isDisabled ? "monkey-bg-black" : "monkey-bg-blue cursor"
            } btn monkey-color-white`}
          >
            Lưu
          </button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { listProvince } = state.updateInfoInReducers;
  const { listAccountProvince } = state.dashboardReducer;
  return {
    listProvince,
    listAccountProvince,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
      onDispatchTogglePopupSuccess,
      onDispatchShowLoading,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentPopupFormDashboard);
