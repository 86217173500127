import {
  getContentAudioVideoImageTextFromIconActData,
  getIconsListByLanguage,
} from ".";
import { TYPE_DATA } from "../constants";
import { TYPE_DISPLAY } from "../constants/SQC_001";
import { COLOR } from "../constants/styles";

const formatActivityDataSQC_001 = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    type_text: typeText,
    type_text_content: typeTextContent,
    font_size_title: fontSizeTitle,
    font_size_answer: fontSizeAnswer,
  } = gameConfig;

  let gameConfigData = [];
  if (gameConfig.data) {
    gameConfigData = gameConfig.data;
  } else {
    gameConfigData = [
      {
        answer: gameConfig.answer,
        question: gameConfig.question[0],
        type_display: gameConfig.type_display,
      },
    ];
  }

  const questionAnswerSetList = gameConfigData.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.question.icon_id;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );
    const question = {
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          contentText: questionContentIcon.contentText,
          typeText,
          fontSize: fontSizeTitle,
        },
      },
    };

    const answersList = dataGameConfigItem.answer.map((answerItem) => {
      const answerId = answerItem.icon_id;
      const answerContentIcon = getContentAudioVideoImageTextFromIconActData(
        iconsList,
        answerId
      );
      return {
        answerId,
        index: Number(answerItem.index),
        contentAnswer: {
          srcAudio: answerContentIcon.srcAudio,
          srcVideo: answerContentIcon.srcVideo,
          srcImage: answerContentIcon.srcImage,
          text: {
            contentText: answerContentIcon.contentText,
            typeText: typeTextContent || [TYPE_DATA.LATEX],
            fontSize: fontSizeAnswer,
          },
        },
      };
    });

    return {
      question,
      answer: {
        typeAnswer,
        typeDisplay: Number(dataGameConfigItem.type_display[0]),
        answers: answersList,
      },
    };
  });

  return questionAnswerSetList;
};

const getDirectionDroppable = (typeDisplay) => {
  switch (typeDisplay) {
    case TYPE_DISPLAY.Horizontal:
      return "horizontal";
    case TYPE_DISPLAY.Vertical:
    case TYPE_DISPLAY.Both:
      return "vertical";
    default:
      return "";
  }
};

const getListStyle = (isDraggingOver, typeDisplay) => ({
  background: (function () {
    if (typeDisplay !== TYPE_DISPLAY.Both) return "transparent";
    return isDraggingOver ? "lightblue" : "lightgrey";
  })(),
  width: typeDisplay === TYPE_DISPLAY.Both ? "230px" : "auto",
});

const getBorderColor = (isCorrect) => {
  switch(isCorrect) {
    case true:
      return COLOR.Success;
    case false:
      return COLOR.Error;
    default:
      return COLOR.White;
  }
};

const getClassNameWrapperDraggable = (typeDisplay) => {
  switch (typeDisplay) {
    case TYPE_DISPLAY.Horizontal:
      return "d-flex";
    case TYPE_DISPLAY.Vertical:
    case TYPE_DISPLAY.Both:
      return "d-block";
    default:
      return "";
  }
};

const reorderAnswers = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const moveAnswers = (sourceAnswers, destinationAnswers, source, destination) => {
  const sourceClone = Array.from(sourceAnswers);
  const destinationClone = Array.from(destinationAnswers);
  const [removed] = sourceClone.splice(source.index, 1);
  destinationClone.splice(destination.index, 0, removed);
  const result = {};
  result[source.droppableId] = sourceClone;
  result[destination.droppableId] = destinationClone;
  return result;
};

const transformSequencedAnswers = (sequencedAnswers, typeDisplay) => {
  let sequencedAnswersTransform = [];
  if (typeDisplay !== TYPE_DISPLAY.Both) {
    sequencedAnswersTransform = [sequencedAnswers];
  } else {
    let sequencedAnswersLeft = [];
    let sequencedAnswersRight = [];
    sequencedAnswers.forEach((sequencedAns, index) => {
      if (index % 2 === 0) {
        sequencedAnswersLeft = [...sequencedAnswersLeft, sequencedAns];
      } else {
        sequencedAnswersRight = [...sequencedAnswersRight, sequencedAns];
      }
    });
    sequencedAnswersTransform = [sequencedAnswersLeft, sequencedAnswersRight];
  }
  return sequencedAnswersTransform;
};

const compareAnswerOrder = (answer1, answer2) => {
  if (answer1.index < answer2.index) return -1;
  if (answer1.index > answer2.index) return 1;
  return 0;
};

export {
  formatActivityDataSQC_001,
  getDirectionDroppable,
  getBorderColor,
  getClassNameWrapperDraggable,
  getListStyle,
  reorderAnswers,
  moveAnswers,
  transformSequencedAnswers,
  compareAnswerOrder,
};
