import { useState } from "react";
import AuthorSlider from "edu_lms_v2/components/AuthorSlider";
import * as PATH from "consts/routePaths";
import { Link } from "react-router-dom";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import styles from "./OnePercentIntro.module.scss";
export default function Intro() {
  const [url, setUrl] = useState("");
  useScrollToTop();

  return (
    <main>
      <SEO data={pages.intro_one_percent} isBreadcumb />
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V3_INTRO}>Giới thiệu</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Về One Percent
            </li>
          </ul>
        </div>
      </div>
      <div className="onepercent-intro bg-gr">
        <div className="container">
          <div className="text decor-h3 pr">
            <h1 className="title">
              Về <span className="inline">One Percent</span>
            </h1>
            <p>
              One Percent là doanh nghiệp đi đầu về lĩnh vực công nghệ trong
              giáo dục. One Percent mong muốn xây dựng nền tảng giáo dục bằng
              cách tạo nên một môi truờng dạy và học trực tuyến chất lượng cao,
              đầy nhiệt huyết, hứng thú và sáng tạo.
            </p>
          </div>
        </div>
      </div>
      <div className="onepercent-value">
        <div className="container">
          <div className="row flex-lc">
            <div className="col-lg-6">
              <div className="text">
                <h3>Giá trị cốt lõi</h3>
                <p>
                  Hiểu rõ quy tắc 1% và sức mạnh của sự tích lũy, One Percent
                  nhấn mạnh vào việc xây dựng thói quen và sự nỗ lực cho học
                  sinh: sự khác biệt được tạo ra khi mỗi ngày ta cố gắng tốt hơn
                  ngày hôm qua 1%
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper bg-sh pr">
                <img src="/assets/img/img-bt.jpg" alt="Giá trị cốt lõi" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="onepercent-develop bg-gr">
        <div className="container">
          <div className="header">
            <h2 className="title">
              <span className="line8 inline">Đội ngũ One Percent</span> đã phát
              triển
            </h2>
          </div>
          <div className="project monkey flex-lc">
            <div
              className={`project__logo project__logo--monkey flex-center ${styles.OnePercentIntro}`}
            >
              <p>
                <img src="/assets/img/monkey-junior.svg" alt="Monkey Junior" />
              </p>
              <p>
                <img
                  src="/assets/img/monkey-stories.svg"
                  alt="Monkey Stories"
                />
              </p>
              <p>
                <img src="/assets/img/monkey-math.svg" alt="Monkey Math" />
              </p>
              <p>
                <img src="/assets/img/vmonkey.svg" alt="VMonkey" />
              </p>
            </div>
            <div className="project__content project__content--monkey">
              <h4>Monkey Apps</h4>
              <p>
                Bộ ứng dụng Monkey bao gồm Monkey Junior, Monkey Stories, Monkey
                Math và VMonkey là các ứng dụng học tập cho bé từ 0-10 tuổi. Mỗi
                ứng dụng tập trung phát triển cho bé các kĩ năng về Tiếng Anh,
                Tiếng Việt hoặc Toán học.
              </p>
              <p>
                Sau 6 năm thành lập, bộ ứng dụng của Monkey trở thành bạn đồng
                hành của hơn 10 triệu em nhỏ và ba mẹ trên khắp thế giới, nhiều
                nhất là tại Mỹ, Canada, Việt Nam, Australia, Anh, Nhật Bản, Thái
                Lan...
              </p>
            </div>
          </div>
          <div className="project hoc10 flex-lc">
            <div className="project__logo project__logo--hoc10 flex-center">
              <div>
                <img src="/assets/img/updated_logo2.svg" alt="Logo Hoc10" />
              </div>
            </div>
            <div className="project__content">
              <h4>Hoc10</h4>
              <p>
                Hoc10 mang sứ mệnh xây dựng một hệ sinh thái giáo dục, kết nối
                nhà trường với học sinh và phụ huynh, nhằm tạo nên môi trường
                giảng dạy và học tập toàn diện, hiệu quả.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-vepic">
        <div className="container">
          <div className="header">
            <h2 className="title">
              <span className="inline">One Percent</span> - đơn vị hợp tác{" "}
              <br />
              độc quyền của <span className="inline line8">VEPIC</span>
            </h2>
            <p className="txt-2">Sản phẩm của VEPIC: Bộ sách Cánh Diều</p>
          </div>
          <div
            className="video pr"
            data-link=""
            data-toggle="modal"
            data-target="#modal-video"
          >
            <div className="video__thumb">
              <img
                src="/assets/img/banner-canh-dieu-2.png"
                alt="Sản phẩm của Vepic: Bộ sách Cánh Diều"
              />
            </div>
            {/* <div className="video__play">
              <img
                onClick={() =>
                  setUrl(
                    "https://www.youtube.com/watch?v=ZYdoW9RzPAs&ab_channel=Hoc10"
                  )
                }
                src="/assets/img/icon-play.svg"
                alt=""
              />
            </div> */}
          </div>
          <div className="intro-list row">
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book5.svg"
                    alt="Bộ sách thứ nhất (đầu tiên)"
                  />
                </div>
                <h4>Bộ sách thứ nhất (đầu tiên)</h4>
                <p>
                  Bộ sách thứ nhất (đầu tiên) góp phần thực hiện chủ trương xã
                  hội hoá sách giáo khoa, xoá bỏ cơ chế độc quyền trong lĩnh vực
                  xuất bản - in - phát hành sách giáo khoa.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book6.svg"
                    alt="Tác giả bộ sách Cánh Diều"
                  />
                </div>
                <h4>Tác giả bộ sách Cánh Diều</h4>
                <p>
                  Tác giả bộ sách Cánh Diều là những nhà giáo, nhà khoa học tâm
                  huyết và giàu kinh nghiệm, trong đó có tác giả là Tổng Chủ
                  biên Chương trình Giáo dục phổ thông 2018 và nhiều tác giả là
                  thành viên Ban Phát triển Chương trình tổng thể, Ban Phát
                  triển các chương trình môn học thành lập theo Quyết định của
                  Bộ trưởng Bộ GDĐT.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="content">
                <div className="img-wrapper">
                  <img
                    src="/assets/img/img-book8.svg"
                    alt="Phiên bản điện tử"
                  />
                </div>
                <h4>Phiên bản điện tử</h4>
                <p>
                  Bên cạnh sách giấy, bộ sách Cánh Diều còn có phiên bản điện tử
                  và nhiều học liệu điện tử sinh động đi kèm để hỗ trợ quá trình
                  giảng dạy - học tập của Giáo viên và Học sinh.
                </p>
              </div>
            </div>
          </div>
          <Link
            to={PATH.ROUTE_PATH_V3_BOOK_INTRO}
            href=""
            title="Xem thêm"
            className="btn-sub btn-viewmore flex-center"
          >
            Xem thêm
          </Link>
        </div>
      </div>
      <div className="onepercent-office">
        <div className="container">
          <div className="row flex-lc">
            <div className="col-lg-6">
              <div className="text">
                <h2 className="title">
                  Văn phòng của <span className="inline">One Percent</span>
                </h2>
                <div className="address">
                  <h4>Địa chỉ:</h4>
                  <p>
                    Tầng 2, Toà nhà HH-N01, Gold Season, số 47 Nguyễn Tuân, P.
                    Thanh Xuân Trung - Q. Thanh Xuân - TP. Hà Nội, Việt Nam
                  </p>
                </div>
                <div className="phone">
                  Điện thoại:&nbsp;
                  <a href="tel:024.73098866">024.73098866</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper bg-sh pr">
                <img
                  src="/assets/img/img-office.jpg"
                  alt="Văn phòng của One Percent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
