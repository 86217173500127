import React from "react";
import { useSelector } from "react-redux";
import { BOOK_LANGUAGE } from "edu_lms/constants/type";
import { TYPE_BUTTON_GAME } from "../constants";

export default function GameButton({ type, ...props }) {
  const languageBook = useSelector(
    (state) => state.readingBookReducers.languageBook
  );
  const textBookLanguage = BOOK_LANGUAGE.find(
    (lang) => lang.id === Number(languageBook)
  ) || {};

  let title = "";
  switch (type) {
    case TYPE_BUTTON_GAME.Check:
      title = textBookLanguage.buttonCheck;
      break;
    case TYPE_BUTTON_GAME.Continue:
      title = textBookLanguage.buttonContinue;
      break;
    case TYPE_BUTTON_GAME.Refresh:
      title = textBookLanguage.buttonRefresh;
      break;
    case TYPE_BUTTON_GAME.Close:
      title = textBookLanguage.buttonClose;
      break;
    default:
      break;
  }

  const { disabled } = props;
  return (
    <button
      {...props}
      className={`${disabled ? "monkey-bg-black" : "monkey-bg-blue cursor"} btn monkey-color-white pr-3 pl-3 pt-2 pb-2 ${props.className}`}
    >
      {title}
    </button>
  );
}
