import { Modal } from "react-bootstrap";

export default function PopupShowMessageNoti({
  showMessageNoti,
  setShowMessageNoti,
  title,
}) {
  const handleClose = () => {
    setShowMessageNoti(false);
  };
  return (
    <>
      <Modal
        show={showMessageNoti}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton className="text-center border-0">
          <p className="w-100 monkey-fz-18 p-4 text-justify font-weight-bold">
            {title}
          </p>
        </Modal.Header>
      </Modal>
    </>
  );
}
