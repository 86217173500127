import useScrollToTop from "edu_lms_v2/services/useScrollToTop";

export default function StudentDoingExercise2() {
  useScrollToTop();

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Luyện tập
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-practice-title bg-gr">
        <div className="container">
          <div className="header title decor-h1 pr">
            <h1 className="title">Luyện tập</h1>
          </div>
        </div>
      </div>
      <div className="hoc10-practice-ex">
        <div className="container">
          <div className="header">
            <h2 className="title">Bài luyện tập gần đây</h2>
          </div>
          <div className="practice-list row">
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-toan.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: "30%" }}
                    ></div>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-nguvan.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="progress finish">
                    <div
                      className="progress-bar"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-ta.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: "30%" }}
                    ></div>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-library bg-gr">
        <div className="container">
          <div className="header">
            <h2 className="title">Thư viện bài luyện tập</h2>
          </div>
          <ul className="tab nav tab-pr tab-pr--cs1">
            <li>
              <a href="#lop1" className="active" data-bs-toggle="tab">
                Lớp 1
              </a>
            </li>
            <li>
              <a href="#lop2" data-bs-toggle="tab">
                Lớp 2
              </a>
            </li>
            <li>
              <a href="#lop3" data-bs-toggle="tab">
                Lớp 3
              </a>
            </li>
            <li>
              <a href="#lop4" data-bs-toggle="tab">
                Lớp 4
              </a>
            </li>
            <li>
              <a href="#lop5" data-bs-toggle="tab">
                Lớp 5
              </a>
            </li>
            <li>
              <a href="#lop6" data-bs-toggle="tab">
                Lớp 6
              </a>
            </li>
            <li>
              <a href="#lop7" data-bs-toggle="tab">
                Lớp 7
              </a>
            </li>
            <li>
              <a href="#lop8" data-bs-toggle="tab">
                Lớp 8
              </a>
            </li>
            <li>
              <a href="#lop9" data-bs-toggle="tab">
                Lớp 9
              </a>
            </li>
            <li>
              <a href="#lop10" data-bs-toggle="tab">
                Lớp 10
              </a>
            </li>
            <li>
              <a href="#lop11" data-bs-toggle="tab">
                Lớp 11
              </a>
            </li>
            <li>
              <a href="#lop12" data-bs-toggle="tab">
                Lớp 12
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div id="lop1" className="tab-pane fade show active">
              <ul className="tab nav tab-sub tab-sub--cs1">
                <li>
                  <a href="#sgk" className="active" data-bs-toggle="tab">
                    Sách giáo khoa
                  </a>
                </li>
                <li>
                  <a href="#sbt" data-bs-toggle="tab">
                    Sách bổ trợ
                  </a>
                </li>
                <li>
                  <a href="#ltt" data-bs-toggle="tab">
                    Luyện tập thêm
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="sgk" className="tab-pane fade show active">
                  <div className="library-list flex">
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-nguvan.svg" alt="" />
                      </span>
                      <span className="name txt-5">Ngữ Văn</span>
                    </a>
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-toan.svg" alt="" />
                      </span>
                      <span className="name txt-5">Toán</span>
                    </a>
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-kh.svg" alt="" />
                      </span>
                      <span className="name">Khoa học tự nhiên</span>
                    </a>
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-ta.svg" alt="" />
                      </span>
                      <span className="name txt-5">Tiếng Anh</span>
                    </a>
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-th.svg" alt="" />
                      </span>
                      <span className="name txt-5">Tin học</span>
                    </a>
                  </div>
                </div>
                <div id="sbt" className="tab-pane fade">
                  <div className="library-list flex">
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-ta.svg" alt="" />
                      </span>
                      <span className="name txt-5">Tiếng Anh</span>
                    </a>
                    <a href="" title="">
                      <span className="icon flex-center">
                        <img src="./assets/img/icon-th.svg" alt="" />
                      </span>
                      <span className="name txt-5">Tin học</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-practice-assigned">
        <div className="container">
          <div className="header">
            <h2 className="title">Bài luyện tập được giao</h2>
          </div>
          <div className="practice-list row">
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-toan.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="time">
                    Thời hạn: <span>Còn 16 ngày để hoàn thành</span>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-kh.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="time">
                    Thời hạn: <span>Còn 16 ngày để hoàn thành</span>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
            <div className="col-lg-4 col-md-4">
              <article className="practice bg-sh">
                <div className="practice__img flex-center">
                  <img src="./assets/img/icon-nguvan.svg" alt="" />
                </div>
                <div className="practice__content">
                  <h3>Toán - Lớp 6</h3>
                  <p>Chương 1 - Bài 6</p>
                  <div className="time">
                    Thời hạn: <span>Còn 16 ngày để hoàn thành</span>
                  </div>
                  <a href="" title="Tiếp tục" className="btn-pr flex-center">
                    Tiếp tục
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
