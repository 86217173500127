import React from "react";
import { bindActionCreators } from "redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import {
  onDispatchDataQuestion,
  onDispatchUpdateBookToolPage,
} from "../../modules/ReadingBooks/actions";
import { TYPE_POPUP_SUPPORT } from "./../../constants/type";

export const Image = styled.img`
  src: url(${(props) => props.src});
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const PopupComponent = (props) => {
  const {
    buttonLabel,
    className,
    modalTitle,
    content,
    statusModal,
    size,
    backdrop,
    type,
  } = props;

  const toggle = () => {
    props.onDispatchShowPopupActivateBook(!props.statusModal);
    props.onDispatchDataQuestion([]);
    // props.onDispatchUpdateBookToolPage({ show: true });
  };

  return (
    <Modal
      isOpen={statusModal}
      toggle={toggle}
      className={className}
      backdrop={backdrop || "static"}
      size={size ? size : ""}
      centered
    >
      {props.bgWrapper && <Image src={props.bgWrapper} />}
      <div
        className="position-relative"
        style={{ zIndex: "3", height: "100%" }}
      >
        <ModalBody
          cssModule={{
            "modal-body": `${type == TYPE_POPUP_SUPPORT ? "pb-5" : ""}`,
          }}
        >
          {modalTitle && (
            <ModalHeader
              toggle={toggle}
              cssModule={{
                "modal-title": `${
                  type == TYPE_POPUP_SUPPORT ? "w-100 text-center" : ""
                }`,
              }}
            >
              <span className="txt-2 text-center pb-4 ml-4">
                {modalTitle === "..." ? "" : modalTitle}
              </span>
            </ModalHeader>
          )}
          {content}
        </ModalBody>
        {buttonLabel && (
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              {buttonLabel}
            </Button>
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
      onDispatchDataQuestion,
      onDispatchUpdateBookToolPage,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(PopupComponent);
