import { useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useAuthStore } from "stores/authStore";
import { useAppStore } from "stores/appStore";
import { onResultUserInfo } from "edu_lms/modules/selection";
import AuthProvider from "./AuthProvider";
import BookProvider from "./BookProvider";

export default function AppContainer({ children }) {
  const params = useParams();
  const setIsLoggedIn = useAuthStore((state) => state.setIsLoggedIn);
  const gradeId = useAppStore((state) => state.gradeId);
  const setGradeId = useAppStore((state) => state.setGradeId);
  const grades = useAppStore((state) => state.grades);

  useEffect(() => {
    const userInfo = onResultUserInfo();
    setIsLoggedIn(Boolean(userInfo));
  }, []);

  useEffect(() => {
    const setDefaultGrade = () => {
      // 1. Check grade value from store
      if (gradeId) {
        const currentGradeFromStore = grades.find(
          (grade) => grade.id === gradeId
        );
        if (currentGradeFromStore) {
          return;
        }
      }
      // 2. Check grade value from url params
      const urlParamGrade = params?.grade || params?.gradeSlug;
      if (urlParamGrade) {
        const currentGradeFromUrlParams = grades.find(
          (grade) => grade.slug === urlParamGrade
        );
        if (currentGradeFromUrlParams) {
          setGradeId(currentGradeFromUrlParams.id);
          return;
        }
      }
      // 3. Check grade value from default slug
      const currentGradeFromUrlDefaultSlug = grades.find(
        (grade) => grade.slug === "lop-1"
      );
      if (currentGradeFromUrlDefaultSlug) {
        setGradeId(currentGradeFromUrlDefaultSlug.id);

        return;
      }
      // 4. Check grade value from the first item of grade list
      const defaultGrade = _.first(grades);
      if (defaultGrade) {
        setGradeId(defaultGrade.id);
      }
    };

    setDefaultGrade();
  }, [gradeId, grades, params?.grade, setGradeId]);

  return (
    <AuthProvider>
      <BookProvider>{children}</BookProvider>
    </AuthProvider>
  );
}
