import styles from "../../teachingManager.module.scss";
import imgSuccess from "edu_lms_v2/assets/img/webinar1.svg";

export default function StatusSuccess({
  title,
  handleClose,
  handleClickAddMember,
}) {
  return (
    <div className="mt-4 text-center">
      <div>
        <img className="m-auto" src={imgSuccess} alt="#" />
      </div>
      <p className="p-4 h4 font-weight-bold">{title}</p>
      <div className="d-flex justify-content-between mx-2">
        {/* <button
          type="button"
          className={styles.colorOrange}
          onClick={() => handleClose()}
        >
          Bỏ qua
        </button> */}
        {/* <button
          type="button"
          className={styles.buttonOrange}
          onClick={() => handleClickAddMember(true)}
        >
          Thêm thành viên
        </button> */}
      </div>
    </div>
  );
}
