import useScrollToTop from "edu_lms_v2/services/useScrollToTop";

export default function StudentDoingExercise3() {
  useScrollToTop();

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li className="breadcrumb-item">
              <a href="">Luyện tập</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Toán Lớp 6 - Sách gíao khoa
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-title bg-gr">
        <div className="container pr">
          <div className="subject flex-lc">
            <div className="subject__icon flex-center">
              <img src="./assets/img/icon-toan1.svg" alt="" />
            </div>
            <div className="subject__info">
              <h5 className="txt-2">Luyện tập</h5>
              <h1 className="title">
                Toán lớp 6 - <span>SGK</span>
              </h1>
              <p>Ôn tập kiến thức cùng kho bài tập phong phú</p>
              <a href="" title="Thay đổi" className="btn-sub flex-center">
                Thay đổi
              </a>
            </div>
          </div>
          <div className="img-wrapper">
            <img src="./assets/img/img-dc5.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="hoc10-practice-list">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="table-of-content bg-sh">
                <div className="header">
                  <h3>Mục lục</h3>
                </div>
                <ul className="list">
                  <li className="finish">
                    <h4>
                      <span>Chương 1</span>
                    </h4>
                    <div className="percent">100%</div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </li>
                  <li className="doing">
                    <h4>
                      <span>Chương 2</span>
                    </h4>
                    <div className="percent">50%</div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </li>
                  <li>
                    <h4>
                      <span>Chương 3</span>
                    </h4>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                  <li>
                    <h4>
                      <span>Chương 4</span>
                    </h4>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                  <li>
                    <h4>
                      <span>Chương 5</span>
                    </h4>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="chapter bg-sh">
                <div className="header flex">
                  <h3>Chương 1</h3>
                  <div
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": "100" }}
                  ></div>
                </div>
                <ul className="question flex">
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 1: Số tự nhiên là gì?</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 2: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 3: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 4: Mở rộng về số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 5: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 6: Ôn tập</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chapter bg-sh">
                <div className="header flex">
                  <h3>Chương 2</h3>
                  <div
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": "50" }}
                  ></div>
                </div>
                <ul className="question flex">
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 1: Số tự nhiên là gì?</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 2: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="" className="done">
                      <span>Bài 3: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 4: Mở rộng về số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 5: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 6: Ôn tập</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chapter bg-sh">
                <div className="header flex">
                  <h3>Chương 3</h3>
                  <div
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": "0" }}
                  ></div>
                </div>
                <ul className="question flex">
                  <li>
                    <a href="" title="">
                      <span>Bài 1: Số tự nhiên là gì?</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 2: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 3: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 4: Mở rộng về số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 5: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 6: Ôn tập</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chapter bg-sh">
                <div className="header flex">
                  <h3>Chương 4</h3>
                  <div
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": "0" }}
                  ></div>
                </div>
                <ul className="question flex">
                  <li>
                    <a href="" title="">
                      <span>Bài 1: Số tự nhiên là gì?</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 2: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 3: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 4: Mở rộng về số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 5: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 6: Ôn tập</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="chapter bg-sh">
                <div className="header flex">
                  <h3>Chương 5</h3>
                  <div
                    role="progressbar"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ "--value": "0" }}
                  ></div>
                </div>
                <ul className="question flex">
                  <li>
                    <a href="" title="">
                      <span>Bài 1: Số tự nhiên là gì?</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 2: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 3: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 4: Mở rộng về số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 5: Luyện tập cùng với số tự nhiên</span>
                    </a>
                  </li>
                  <li>
                    <a href="" title="">
                      <span>Bài 6: Ôn tập</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
