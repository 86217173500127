import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  onDispatchShowPopupActivateBook,
  onDispatchShowLoading,
  onDispatchTypePopup,
} from "../../modules/App/actions";
import { listSubject, WIDTH_BUTTON } from "../../constants/type";
import { getHistoryQuiz } from "../../services/listQuiz";
import * as PATH from "../../../consts/routePaths";
import { TYPE_POPUP_TEST_RESULTS, TYPE_RANK } from "../../constants/type";
import { onDispatchResetQuestion } from "../../modules/ListQuiz/actions";
import { onResultUserInfo } from "edu_lms/modules/selection";

const TestResults = ({
  onDispatchShowPopupActivateBook,
  onDispatchShowLoading,
  onDispatchTypePopup,
  onDispatchResetQuestion,
  statusModal,
  data,
}) => {
  const history = useHistory();
  const userInfo = onResultUserInfo();
  const titleSubject = listSubject.find((item) => item.id === data.subject_id);

  const onClosePopup = () => {
    onDispatchShowPopupActivateBook(!statusModal);
  };

  const onFinish = () => {
    onDispatchShowLoading(true);
    getHistoryQuiz()
      .then((res) => {
        onDispatchShowLoading(false);
        if (res.data.status === "success") {
          history.push(PATH.ROUTE_PATH_V3_TRAINING);
          onDispatchTypePopup(TYPE_POPUP_TEST_RESULTS);
          onDispatchShowPopupActivateBook(!statusModal);
        }
      })
      .catch((error) => {
        //TODO: notify lỗi về
        onDispatchShowLoading(false);
        console.log(error);
      });
  };
  return (
    <div className="test-results-wrapper">
      <div className="text-center">
        <Title>CHÚC MỪNG BẠN ĐÃ HOÀN THÀNH BÀI TẬP HUẤN </Title>
      </div>
      <hr />
      <div className="monkey-fz-18">
        <p className="text-center">
          Kết quả tập huấn của thầy cô:{" "}
          <span className="monkey-color-black">{userInfo.full_name}</span>
        </p>
        <div className="exam-exercises-body pt-3 pb-3 ">
          <div className="d-flex justify-content-center align-items-center">
            <div className="mr-4">
              <p className="mb-3">
                <span className="monkey-f-bold">Môn: </span>{" "}
                <span>
                  <span className="monkey-color-orange monkey-f-bold">
                    {history?.location?.state?.subjectName ||
                      titleSubject?.title}
                  </span>
                </span>
              </p>
              <p className="mb-3">
                <span className="monkey-f-bold">Kết quả:</span>{" "}
                <span>
                  <span className="monkey-color-orange monkey-f-bold">
                    {data?.result}
                  </span>{" "}
                  câu đúng
                </span>
              </p>
              <p>
                <span className="monkey-f-bold">Xếp loại: </span>
                <span>
                  <span className="monkey-color-orange monkey-f-bold">
                    {TYPE_RANK[data?.rank]}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ width: "100%", border: "1px solid #ddd", margin: "1rem auto" }}
      ></div>
      <div className="exam-exercises-footer d-flex justify-content-between">
        {data?.rank === 4 && (
          <Fragment>
            <button
              style={{ width: `${WIDTH_BUTTON}` }}
              onClick={onFinish}
              className="btn monkey-bc-violet hvr-registration-white monkey-color-orange monkey-f-bold text-uppercase"
            >
              Hoàn thành
            </button>
            <button
              style={{ width: `${WIDTH_BUTTON}` }}
              onClick={onClosePopup}
              className="btn monkey-bg-orange monkey-color-white"
            >
              Kết quả chi tiết
            </button>
          </Fragment>
        )}
        {data?.rank !== 4 && (
          <Fragment>
            <button
              style={{ width: `${WIDTH_BUTTON}` }}
              onClick={onClosePopup}
              className="btn monkey-bc-violet hvr-registration-white monkey-color-orange monkey-f-bold text-uppercase p-0"
            >
              Kết quả chi tiết
            </button>
            <button
              style={{ width: `${WIDTH_BUTTON}` }}
              onClick={onFinish}
              className="btn monkey-bg-question monkey-color-white hvr-registration-white text-uppercase "
            >
              Hoàn thành
            </button>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const Title = styled.h3`
  font-size: 20px;
`;

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
      onDispatchShowLoading,
      onDispatchTypePopup,
      onDispatchResetQuestion,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(TestResults);
