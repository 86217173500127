import AxiosService from "../axiosService";

const urlLogout = "v1/delete-vepic-gift-account-login?lang=vi-VN&app_id=68&is_web=1";

export const postLogout = () => {
	const userId = localStorage.getItem("user_id");
	if(userId) {
		const axiosService = new AxiosService();
		return axiosService.postRequest(
			`${process.env.REACT_APP_API_URL_APP}${urlLogout}&user_id=${userId}`
		);
	}
};
