import React, { Component } from "react";
import LogInComponent from "./components/LogInComponent";
import ActiveBookComponent from "./components/ActiveBookComponent";
import RegisterComponent from "./components/RegisterComponent";
import UpdateInfoComponent from "./components/UpdateInfoComponent";
import ForgotPwComponent from "./components/ForgotPwComponent";
import DefaultComponent from "./components/DefaultComponent";
import ReadingBookComponent from "./components/ReadingBookComponent";
import ExcerciseComponent from "./components/ExcerciseComponent";
import DowloadComponent from "./components/DowloadComponent";
import FindLessonComponent from "./components/FindLessonComponent";
import ViewLessonComponent from "./components/ViewLessonComponent";
import DownloadLessonComponent from "./components/DownloadLessonComponent";
import CheckSeriBook from "./components/CheckSeriBook";

class SwitchComponent extends Component {
  components = {
    defaultComponent: DefaultComponent,
    guideRegister: RegisterComponent,
    guideSignIn: LogInComponent,
    guideUpdateInfo: UpdateInfoComponent,
    guideForgotPw: ForgotPwComponent,
    guideActiveBook: ActiveBookComponent,
    guideReadingBook: ReadingBookComponent,
    guideExcercise: ExcerciseComponent,
    guideDowload: DowloadComponent,
    guideFindLesson: FindLessonComponent,
    guideViewLesson: ViewLessonComponent,
    guideDownloadLesson: DownloadLessonComponent,
    guideCheckSeriBook: CheckSeriBook,
  };
  render() {
    const ComponentName =
      this.components[this.props.name || "defaultComponent"];
    return (
      <div class="support__content">
        <div class="tab-content">
          <div id="s1" class="tab-pane fade show active">
            <ComponentName />
          </div>
        </div>
      </div>
    );
  }
}
export default SwitchComponent;
