import { feelings } from "edu_lms_v2/data/feelings";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "../../../edu_lms/components/Seo";
import { pages } from "../../../edu_lms/components/Seo/meta";
import FeedbackSlider from "../Home/FeedbackSlider";

export default function Student() {
  useScrollToTop();

  return (
    <main>
      <SEO data={pages.student} />
      <div class="hoc10-breadcrumb bg-gr">
        <div class="container">
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Học sinh
            </li>
          </ul>
        </div>
      </div>
      <div class="hoc10-title bg-gr">
        <div class="container">
          <div class="header decor-h1 pr">
            <h1 class="title">Học sinh</h1>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--1">
        <div class="container">
          <ul class="feature-list flex">
            <li>
              <div class="icon">
                <img
                  src="/assets/img/icon-hs1.svg"
                  alt="Học bằng công nghệ AI"
                />
              </div>
              <h4>Cá nhân hóa lộ trình học bằng công nghệ AI</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs2.svg" alt="Ôn luyện kiến thức" />
              </div>
              <h4>Ôn luyện kiến thức mọi lúc, mọi nơi</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs3.svg" alt="Báo cáo học tập" />
              </div>
              <h4>Báo cáo học tập thường xuyên</h4>
            </li>
            <li>
              <div class="icon">
                <img src="/assets/img/icon-hs4.svg" alt="Kiểm tra năng lực" />
              </div>
              <h4>Kiểm tra đánh giá năng lực chính xác</h4>
            </li>
          </ul>
          <div
            class="video video-ty1 pr"
            data-link=""
            data-bs-toggle="modal"
            data-bs-target="#modal-video"
          >
            <div class="video__thumb">
              <img src="/assets/img/thumb-video3.jpg" alt="Đóng video" />
            </div>
            <div class="video__play">
              <img src="/assets/img/icon-play.svg" alt="Bật video" />
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--2 bg-gr">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title">Luyện tập</h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-laptop.svg"
                        alt="Dễ dàng học tập"
                      />
                    </div>
                    <div class="text txt-1">
                      Dễ dàng học tập mọi lúc, mọi nơi
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-search3.svg"
                        alt="Tìm kiếm bài ta"
                      />
                    </div>
                    <div class="text txt-1">
                      Tìm kiếm bài tập dựa theo kiến thức trọng tâm
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-light.svg"
                        alt="Gợi ý kiến thức"
                      />
                    </div>
                    <div class="text txt-1">
                      Gợi ý kiến thức hỗ trợ quá trình tập luyện
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="img-wrapper bg-sh pr">
                <img
                  src="/assets/img/img-sc1.jpg"
                  alt="Luyện tập bài tập dễ dàng"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--3">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-7">
              <div class="img-wrapper bg-sh pr">
                <img
                  src="/assets/img/img-sc2.jpg"
                  alt="Luyện tập bài tập dễ dàng"
                />
              </div>
            </div>
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title">Thi thử</h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/tick.svg" alt="Lớp học" />
                    </div>
                    <div class="text txt-1">
                      Tự kiểm tra trình độ trước các kì thi
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img
                        src="/assets/img/icon-check.svg"
                        alt="Luyện tập bài tập dễ dàng"
                      />
                    </div>
                    <div class="text txt-1">
                      Đồng hồ đếm ngược tái hiện thời gian thi thực tế
                    </div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-media.svg" alt="Media" />
                    </div>
                    <div class="text txt-1">
                      Chấm điểm tự động với lời giải chi tiết, bổ ích
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-ft hoc10-ft--4 bg-gr">
        <div class="container">
          <div class="row flex-lc">
            <div class="col-lg-5">
              <div class="feature">
                <h2 class="title">Lớp học</h2>
                <ul class="feature__list">
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-laptop.svg" alt="Laptop" />
                    </div>
                    <div class="text txt-1">Tham gia lớp học dễ dàng</div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-check.svg" alt="Dồng hồ" />
                    </div>
                    <div class="text txt-1">Quản lý bài tập và tiến độ học</div>
                  </li>
                  <li>
                    <div class="icon flex-center">
                      <img src="/assets/img/icon-chart.svg" alt="Biểu đồ" />
                    </div>
                    <div class="text txt-1">
                      Theo dõi báo cáo học tập thường xuyên
                    </div>
                  </li>
                </ul>
                {/* <a
                  href=""
                  title="Xem thêm"
                  class="btn-viewmore btn-pr flex-center"
                >
                  Xem thêm
                </a> */}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="box-video pr">
                <div
                  class="video video-ty1 pr"
                  data-link=""
                  data-bs-toggle="modal"
                  data-bs-target="#modal-video"
                >
                  <div class="video__thumb">
                    <img src="/assets/img/thumb-video4.jpg" alt="" />
                  </div>
                  <div class="video__play">
                    <img src="/assets/img/icon-play.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="statistical flex">
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-book1.svg" alt="Bài luyện tập" />
              </div>
              <div class="content">
                <div class="number">900</div>
                <div class="text">Bài luyện tập</div>
              </div>
            </div>
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-book2.svg" alt="Đề thi thử" />
              </div>
              <div class="content">
                <div class="number">500</div>
                <div class="text">Đề thi thử</div>
              </div>
            </div>
            <div class="item flex-lc">
              <div class="icon">
                <img src="/assets/img/icon-edu.svg" alt="Học sinh" />
              </div>
              <div class="content">
                <div class="number">10.000</div>
                <div class="text">Học sinh đã dùng</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hoc10-feedback hoc10-feedback--st bg-gray">
        <div class="container">
          <div class="header">
            <h2 class="title">
              Cảm nghĩ học sinh<span class="block">về Hoc10</span>
            </h2>
          </div>
          <FeedbackSlider feelings={feelings} />
        </div>
      </div>
      <div class="hoc10-banner-register pr">
        <div class="container pr">
          <div class="content">
            <h3>
              <span>Đăng ký ngay để sử dụng đầy đủ tính năng Học sinh</span>
            </h3>
            <a href="/dang-ky/" title="" class="flex-center btn-access txt-1">
              Đăng ký ngay
            </a>
          </div>
          <div class="img-decor">
            <img src="/assets/img/img-decor2.svg" alt="Decor" />
          </div>
        </div>
      </div>
    </main>
  );
}
