import React, { Component, Fragment } from "react";

export class StudentContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return <div>StudentContainer</div>;
  }
}

export default StudentContainer;
