import { TypeGame } from "edu_lms/components/configGame";
import { TYPE_GAME } from "edu_lms_v2/libraries/hoc10Game/constants";

export const converDataDefault = (data) => {
  const dataResult = data?.map((question, index) => {
    try {
      if (question?.game_id === TypeGame.MULTIPLE_CHOICE) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.MULTIPLE_CHOICE_CHECKBOX) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TYPE_GAME.MTC_BG) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TYPE_GAME.DAD_Text) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TYPE_GAME.DAD_Image) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.FILL_THE_BLANK) {
        return question; // TODO remove this function when refactor all games
      }

      if (question?.game_id === TypeGame.FILL_THE_BLANK_DYNAMICALLY) {
        return question;  // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.MATCH) {
        return question;  // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.FILL_THE_BLANK_MATH) {
        return question;  // TODO remove this function when refactor all games
      }

      if (question?.game_id === TypeGame.FILL_THE_BLANK_WITH_DROPDOWN) {
        return question;  // TODO remove this function when refactor all games
      }

      if (question?.game_id === TypeGame.MATH_INPUT) {
        return question;   // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.WORD_FINDING) {
        return question;   // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.SEQUENCE) {
        return question;   // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.MATCHING_BACKGROUND) {
        return question; // TODO remove this function when refactor all games
      }

      if (question?.game_id === TypeGame.DRAG_DROP_GRAPH) {
        return question; // TODO remove this function when refactor all games
      }

      if (question?.game_id === TypeGame.FILL_THE_BLANK_GRAPH) {
        return question; // TODO remove this function when refactor all games
      }
      if (question?.game_id === TypeGame.REGION_OF_THE_INEQUALITY) {
        return question; // TODO remove this function when refactor all games
      }
    } catch {
      console.log(
        "ERROR_RESULT_SCREEN: Hệ thống đã phát hiện lỗi ở câu số " +
          (index + 1) +
          " có activity_id: " +
          question.activity_id +
          " ,activity_name: " +
          question.activity_name +
          ". Vui lòng kiểm tra lại."
      );
    }
  });

  return dataResult;
};
