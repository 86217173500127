import { useState, useEffect, useRef } from "react";
import { getDetailArticle, getCateByParent } from "edu_lms/services/blog";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "edu_lms/components/Seo";
import HeadingViewDetail from "./HeadingViewDetail";
import TitleDetail from "./TitleDetail";
import styles from "../BlogStyle.module.scss";
import Loading from "edu_lms_v2/components/Loading";
import { NotFoundContainer } from "edu_lms/modules/NotFound";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";
import { SLUG_KNOWLEDGE } from "../constant";

export default function KnowledgeDetail(props) {
  useScrollToTop();
  const refScreen = useRef(null);
  const [detailItem, setDetailItem] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const [listAllSubjects, setListAllSubject] = useState({});
  const [isShowLinkShare, setIsShowLinkShare] = useState(false);
  const params = props.match.params;
  const postsId = params.slug || "";
  const isCheckRouter =
    params.classType === detailItem?.grade?.slug &&
    params.levelType === detailItem?.level?.slug &&
    params.subjectType === detailItem?.subject?.slug;

  const getDataDetail = () => {
    if (postsId) {
      const data = {
        alias: postsId,
      };
      getDetailArticle(data).then((res) => {
        if (res.data.status === "success") {
          setDetailItem(res.data.data);
        } else {
          console.log(res.data.message);
          setIsNotFound(true);
        }
      });
    }
  };

  const getAllDataSubject = () => {
    const data = { slug: props.match.params.subjectType };
    getCateByParent(data).then((res) => {
      if (res.data.status === "success") {
        setListAllSubject(res.data.data);
      } else {
        console.log(res.data.message);
      }
    });
  };

  useEffect(() => {
    getDataDetail();
    getAllDataSubject();
  }, [postsId]);

  if (!detailItem && !isNotFound) {
    return (
      <main className={styles.height100}>
        <Loading />
      </main>
    );
  }

  if ((isNotFound || !isCheckRouter) && !detailItem) {
    return (
      <main>
        <NotFoundContainer />
      </main>
    );
  }

  const dataSEO = {
    title: detailItem?.blog?.meta_title,
    description: detailItem?.blog?.meta_description_cv,
    img: detailItem?.blog?.images[0]?.path,
    url: `${process.env.REACT_APP_URL}${SLUG_KNOWLEDGE.knowledge}/${params.levelType}/${params.classType}/${params.subjectType}/${params.slug}/`,
    robots: detailItem?.blog?.meta_robots,
    keywords: detailItem?.blog?.meta_keyword,
  };

  return (
    <main className={styles.knowledge} ref={refScreen}>
      <SEO data={dataSEO} />
      {detailItem && (
        <div
          onClick={() => setIsShowLinkShare(false)}
          className={styles.containerDetail}
        >
          <ScrollAndFrequentQuestion
            refScreen={refScreen?.current?.offsetHeight}
          />
          <HeadingViewDetail
            listAllSubjects={listAllSubjects?.all_cate}
            params={props.match.params}
          />
          <TitleDetail
            detailItem={detailItem}
            params={props.match.params}
            setIsShowLinkShare={setIsShowLinkShare}
            isShowLinkShare={isShowLinkShare}
          />
        </div>
      )}
    </main>
  );
}
