import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { getActionGameBook } from "../../services/readingBook/";
import { onDispatchShowLoading } from "../../modules/App/actions";
import { onDispatchDataQuestion } from "../../modules/ReadingBooks/actions";
import IntroduceWrapper from "./IntroduceWrapper";
import { onDispatchTitleQuestion, onDispatchIsClickStart } from "./actions";
import LoadingComponent from "../loading";
import { onResultGameComponent } from "../configGame";
import { BookToolMenu, BookToolSketch } from "../BookTool";
import {
  onDispatchUpdateBookToolGame,
  onDispatchUpdatePageDraw,
} from "../../modules/ReadingBooks/actions";
import { postSaveDraw } from "../../services/bookTool";
import { postSaveReportError } from "../../services/reportError";
import FormReportError from "../../modules/ReportError/components/FormReportError";
import PopUpReportSuccess from "../../modules/ReportError/components/PopUpReportSuccess";
import ReportError from "../../modules/ReportError";
import { setEventGTM } from "../../constants/googleTagManager";
import { REPORT_ERROR } from "../../constants/eventNameGTM";
import { Tools } from "edu_lms/libs/react-sketch";
import * as Sentry from "@sentry/react";
import { sendErrorToTelegram } from "../../modules/DoingExercise/selection";
import {LIST_PROBLEM_BOOK} from "../../modules/ReadingBooks/components/const";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 90%;
`;

const BookToolSketchWrapper = styled.div`
  pointer-events: ${(props) => props.isActive ? "auto" : "none"};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
`;

const ListQuestion = ({
  dataQuestion,
  indexQuestion,
  idBook,
  languageBook,
  onDispatchShowLoading,
  onDispatchDataQuestion,
  onDispatchTitleQuestion,
  onDispatchIsClickStart,
  bookInfo,
}) => {
  const objectId = idBook;
  const dispatch = useDispatch();
  const bookTool = useSelector(
    (state) => state.readingBookReducers.bookTool.game
  );
  const bookId = useSelector(
    (state) => state.readingBookReducers.paramaterBookId
  );
  const pages = useSelector((state) => state.readingBookReducers.dataBook);
  const pageId = useSelector((state) => state.readingBookReducers.pageId);

  const [listQuestion, setStateListQuestion] = useState([]);
  const [dataIntroduce, setStateDataIntroduce] = useState();
  const [isShowQuestion, setStateShowQuestion] = useState(false);
  const [statusApi, setStateStatusApi] = useState(false);
  const [showFormReportErrorModal, setShowFormReportErrorModal] =
    useState(false);
  const [showPopUpReportSuccessModal, setShowPopUpReportSuccessModal] =
    useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [activityId, setActivityId] = useState();
  const [activityName, setActivityName] = useState();

  useEffect(() => {
    const getDataActionBook = (id) => {
      onDispatchShowLoading(true);
      getActionGameBook(id)
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.data[0];
            setActivityId(data.activity_id);
            setActivityName(data?.activity_name);
            console.log("activity_name", data?.activity_name); // This is feature, please don't delete this line
            onDispatchDataQuestion(res.data.data);
            if (data?.game_config) {
              // onDispatchTitleQuestion(res.data.data[0]?.game_config.title);
            }
            onDispatchShowLoading(false);
          } else {
            setStateStatusApi(true);
          }
          onDispatchShowLoading(false);
        })
        .catch((error) => {
          console.log(error);
          onDispatchShowLoading(false);
        });
    };

    if (objectId) {
      getDataActionBook(objectId);
    }
  }, [
    objectId,
    onDispatchDataQuestion,
    onDispatchShowLoading,
    onDispatchTitleQuestion,
  ]);

  useEffect(() => {
    if (dataQuestion) {
      setStateListQuestion(dataQuestion);
      setStateDataIntroduce(dataQuestion[0]);
    }
  }, [dataQuestion]);

  const onChangeShowQuestion = () => {
    onDispatchIsClickStart(true);
    setStateShowQuestion(true);
  };

  const onChangeDrawType = (drawType) => {
    dispatch(
      onDispatchUpdateBookToolGame({
        ...bookTool,
        drawType,
      })
    );
  };

  const onChangeLineColor = (lineColor) => {
    dispatch(
      onDispatchUpdateBookToolGame({
        ...bookTool,
        lineColor,
      })
    );
  };

  const onChangeLineWidth = (lineWidth) => {
    dispatch(
      onDispatchUpdateBookToolGame({
        ...bookTool,
        lineWidth,
      })
    );
  };

  const saveCanvasObjects = (objects) => {
    const postData = {
      book_id: bookId,
      page_id: pageId,
      object_id: objectId,
      object_layer: isShowQuestion ? "main" : "background",
      data: JSON.stringify(objects),
    };
    dispatch(onDispatchUpdatePageDraw(postData));
    postSaveDraw(postData).catch((errors) => {
      console.log(errors);
    });
  };

  const currentPage = pages.find((page) => page.id === pageId);
  const draw = (currentPage?.draws || []).find(
    (draw) =>
      draw.object_id === objectId &&
      draw.object_layer === (isShowQuestion ? "main" : "background")
  );

  const onReport = () => {
    setShowFormReportErrorModal(true);
  };

  const handleClose = () => {
    setShowFormReportErrorModal(false);
    setShowPopUpReportSuccessModal(false);
    if (!isSubmit) {
      setEventGTM({
        event: REPORT_ERROR,
        book_name: bookInfo.bookName,
        book_type: bookInfo.bookType,
        book_grade: bookInfo.bookGrade,
        book_subject: bookInfo.bookSubject,
        type: "close",
      });
    }
    setIsSubmit(false);
  };

  const saveReportError = (data) => {
    const postData = {
      book_id: bookId,
      page_id: pageId,
      object_id: objectId,
      object_layer: isShowQuestion ? "main" : "background",
      data: data,
      activity_id: activityId,
    };
    if (
      data.answer ||
      data.image ||
      data.question ||
      data.other_problems ||
      data.input_other_problems !== ""
    ) {
      postSaveReportError(postData).catch((errors) => {
        console.log(errors);
      });
      setShowFormReportErrorModal(false);
      setShowPopUpReportSuccessModal(true);

      let errorCategory = "";
      if (data.answer) {
        errorCategory = "solution";
      } else if (data.image) {
        errorCategory = "image";
      } else if (data.question) {
        errorCategory = "question";
      } else if (data.other_problems) {
        errorCategory = "others";
      }
      setEventGTM({
        event: REPORT_ERROR,
        book_name: bookInfo.bookName,
        book_type: bookInfo.bookType,
        book_grade: bookInfo.bookGrade,
        book_subject: bookInfo.bookSubject,
        error_category: errorCategory,
        error_detail: data.input_other_problems,
        type: "submit",
      });
      setIsSubmit(true);
    }
  };
	
	const handleTrackingError = () => {
		const content = `Lỗi activity trên sách activity ID ${activityId}  activity name ${activityName}`;
		sendErrorToTelegram(content, activityId);
	};

  return (
    <Sentry.ErrorBoundary onError={handleTrackingError}>
      <>
        {listQuestion.length > 0 ? (
          indexQuestion >= listQuestion.length ? (
            <div className="mt-4">Bạn đã trả lời đúng:</div>
          ) : (
            <>
              {dataIntroduce && (
                <>
                  <BookToolMenu
                    bookTool={bookTool}
                    onChangeDrawType={onChangeDrawType}
                    onChangeLineColor={onChangeLineColor}
                    onChangeLineWidth={onChangeLineWidth}
                    isOnGame
                  />
                  <ReportError onReport={onReport} />

                  {showFormReportErrorModal && (
                    <FormReportError
                      saveReportError={saveReportError}
                      show={showFormReportErrorModal}
                      onHide={handleClose}
                      listProblems={LIST_PROBLEM_BOOK}
                    />
                  )}

                  {showPopUpReportSuccessModal && (
                    <PopUpReportSuccess
                      show={showPopUpReportSuccessModal}
                      onHide={handleClose}
                    />
                  )}
                </>
              )}
              {isShowQuestion ? (
                onResultGameComponent(listQuestion[indexQuestion], objectId)
              ) : (
                <IntroduceWrapper
                  dataIntroduce={dataIntroduce}
                  languageBook={languageBook}
                  onChangeShowQuestion={onChangeShowQuestion}
                />
              )}
              {isShowQuestion && (
                <BookToolSketchWrapper
                  isActive={bookTool.drawType !== Tools.None}
                >
                  <BookToolSketch
                    bookTool={bookTool}
                    draw={draw}
                    saveCanvasObjects={saveCanvasObjects}
                  />
                </BookToolSketchWrapper>
              )}

              {/* This is feature, please don't delete it */}
              {!isShowQuestion && (
                <BookToolSketchWrapper
                  isActive={bookTool.drawType !== Tools.None}
                >
                  <BookToolSketch
                    bookTool={bookTool}
                    draw={draw}
                    saveCanvasObjects={saveCanvasObjects}
                  />
                </BookToolSketchWrapper>
              )}
            </>
          )
        ) : (
          <LoadingWrapper>
            {!statusApi && <LoadingComponent isBgPopupLoading={true} />}
            {statusApi && (
              <div className="monkey-fz-20 mt-5 text-center">Đang cập nhật</div>
            )}
          </LoadingWrapper>
        )}
      </>
    </Sentry.ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  const { dataQuestion, languageBook } = state.readingBookReducers;
  const { indexQuestion } = state.listQuestion;
  const { bookInfo } = state.generalReducer;
  return {
    dataQuestion,
    indexQuestion,
    languageBook,
    bookInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowLoading,
      onDispatchDataQuestion,
      onDispatchTitleQuestion,
      onDispatchUpdateBookToolGame,
      onDispatchIsClickStart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ListQuestion);
