import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Pagination from "./Pagination";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SwitchComponent from "./SwitchComponent";
import Question from "./Question";
import { menu, questions } from "edu_lms_v2/data/guide";
import * as PATH from "consts/routePaths";
import { Link } from "react-router-dom";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";

export default function FrequentQuestion() {
  useScrollToTop();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [activedId, setActivedId] = useState(null);
  const [detail, setDetail] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [slug, setSlug] = useState("");
  const [showQuestion, setShowQuestion] = useState([]);
  const [result, setResult] = useState([]);
  const search = useLocation().search;
  const perPage = 10;
  useEffect(() => {
    setKeyword(new URLSearchParams(search).get("keyword"));
    setSlug(new URLSearchParams(search).get("q"));
    toSearch(
      new URLSearchParams(search).get("keyword"),
      new URLSearchParams(search).get("q")
    );
  }, [
    new URLSearchParams(search).get("keyword"),
    new URLSearchParams(search).get("q"),
    currentPage,
  ]);

  useEffect(() => {
    setShowQuestion(questions);
  }, []);

  useEffect(() => {
    if (slug) {
      menu.map((_item, _index) => {
        _item.item.map((__item) => {
          if (__item.slug === slug) {
            setActivedId(_index);
            setDetail(__item);
          }
        });
      });
    }
  }, [slug]);

  const clickNav = (event, index) => {
    event?.preventDefault();
    setActivedId(index === activedId ? null : index);
  };

  const showDetail = (event, item) => {
    event?.preventDefault();
    setDetail(item);
    history.push({
      pathname: `${PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}`,
      search: `?q=${item.slug}`,
    });
  };

  const goToDetail = (event, item) => {
    event?.preventDefault();
    setKeyword("");
    setActivedId(item.indexParent);
    setDetail(item);
    history.push({
      pathname: `${PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}`,
      search: `?q=${item.slug}`,
    });
  };

  const toSearch = (keyword, slug) => {
    if (keyword) {
      history.push({
        pathname: `${PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}`,
        search: `?keyword=${keyword}`,
      });
    } else {
      if (slug) {
        history.push({
          pathname: `${PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}`,
          search: `?q=${slug}`,
        });
      }
    }

    let list = [];
    menu.map((_item, _index) => {
      _item.item.map((__item) => {
        if (__item.title?.toLowerCase().includes(keyword?.toLowerCase())) {
          list.push({ ...__item, indexParent: _index });
        }
      });
    });

    setTotal(list?.length);
    setResult(list.splice((currentPage - 1) * perPage, perPage));
  };

  return (
    <main>
      <SEO data={pages.questions} />
      <div class="hoc10-breadcrumb bg-gr">
        <div class="container">
          <ul class="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              Hỗ trợ
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Câu hỏi thường gặp
            </li>
          </ul>
        </div>
      </div>
      <div class="hoc10-title bg-gr">
        <div class="container">
          <div class="header decor-h1 pr">
            <h1 class="title mb-10">Hỗ trợ</h1>
            <p>Chúng tôi có thể giúp gì cho bạn</p>
          </div>
          <form
            onSubmit={handleSubmit(() => toSearch(keyword))}
            action=""
            method=""
            class="search-form pr"
          >
            <div class="form-group flex pr">
              <input
                id="search"
                type="text"
                name=""
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Tìm kiếm bài viết trợ giúp"
              />
              <button type="submit" class="btn_submit btn-pr flex-center">
                Tìm Kiếm
              </button>
              <button type="reset" class="btn_reset"></button>
            </div>
            <div class="search-suggest bg-sh">
              <p>
                Cùng con vui học <span>tiếng Việt</span>
              </p>
              <p>
                Cùng con học <span>tiếng Việt</span>
                <span> Tiểu Học</span>
              </p>
              <p>
                Mẹ cùng con học <span>tiếng Việt</span>
              </p>
              <p>
                Cùng con học <span>tiếng Việt</span> tại Hoc10
              </p>
            </div>
          </form>
        </div>
      </div>
      {!detail && !keyword && <Question questions={showQuestion} />}

      <div class="hoc10-guide bg-gray">
        <div class="container">
          {!keyword && (
            <div class="header">
              <h2 class="title">Hướng dẫn chi tiết</h2>
            </div>
          )}

          <div class="support flex-between">
            {keyword ? (
              <div class="support__list result">
                <div>
                  Có <strong className="total">{total}</strong> kết quả được tìm
                  thấy cho
                  <strong className="total"> {keyword}</strong>
                </div>
              </div>
            ) : (
              <div class="support__list">
                <h3>Danh mục hỗ trợ</h3>
                <div class="accordion">
                  {menu?.map((_item, _index) => {
                    return (
                      <div
                        className={classNames("item", {
                          active: _index === activedId,
                        })}
                      >
                        <div
                          class="header flex-lc pr"
                          onClick={(e) => clickNav(e, _index)}
                        >
                          <h4 class="txt-4">{_item.title}</h4>
                          <span>{_item.item.length}</span>
                        </div>
                        <div class="content">
                          {_item.item?.map((__item, __index) => {
                            return (
                              <a
                                className={
                                  detail?.component == __item.component &&
                                  "active"
                                }
                                href="javascript:;"
                                onClick={(e) => showDetail(e, __item)}
                              >
                                {__item.title}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {keyword ? (
              <div class="support__content">
                <div class="tab-content">
                  {result?.map((_item) => {
                    return (
                      <div id="s1" class="tab-pane fade show active">
                        <div className="border-bottom">
                          <div
                            className="cursor"
                            onClick={(e) => goToDetail(e, _item)}
                          >
                            <p className="quicksand-medium pb-1 monkey-fz-18">
                              {_item.title}
                            </p>
                            <p className="monkey-pt-40 pb-2 monkey-fz-16">
                              {_item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {total > perPage && (
                    <Pagination
                      totalBooks={total}
                      totalPerPage={perPage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              </div>
            ) : (
              <SwitchComponent name={detail?.component} />
            )}
          </div>
        </div>
      </div>
      <div class="hoc10-contactus pr">
        <div class="container">
          <Link to={PATH.ROUTE_PATH_V3_CONTACT} class="btn-contact flex-center">
            {" "}
            Liên hệ với chúng tôi
          </Link>
          <ul class="contact-list flex">
            <li>
              <i class="icon icon-phone"></i>
              <a href="tel:024.73098866" className="text-white">
                024.73098866
              </a>
            </li>
            <li>
              <i class="icon icon-email"></i>
              <a href="mailto:contact@hoc10.vn" className="text-white">
                contact@hoc10.vn
              </a>
            </li>
            <li>
              <i class="icon icon-chat"></i>
              <a href="javascript:;" className="text-white" title="">
                Hỗ trợ trực tuyến
              </a>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}
