export const feelings = [
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
  {
    text: "Các học sinh của tôi từ khi được dùng HOC10.VN thì kết quả trở nên tốt hơn rất nhiều. Những bài giảng của tôi cũng trở nên hấp dẫn và dễ hiểu hơn với học sinh của mình.",
    name: "Nguyễn Đức Tú",
    info: "Giáo viên - Trường THPT Hà Đông",
    img: "/assets/img/avt.png",
  },
];
