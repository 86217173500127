export const SECTION_SGK = "sgk";
export const SECTION_SBT = "sbt";
export const SECTION_SGV = "sgv";
export const SECTION_TAI_LIEU_BO_TRO = "tai_lieu_bo_tro";
export const SECTION_TAI_LIEU_THAM_KHAO = "tai_lieu_tham_khao";
export const SECTION_HOC_LIEU_DIEN_TU = "hoc_lieu_dien_tu";
export const SECTION_GIAO_AN = "giao_an";
export const SECTION_TAP_HUAN = "tap_huan";
export const SECTION_GIOI_THIEU_SACH = "gioi_thieu_sach";
export const SECTION_SACH_GIOI_THIEU = "sach_gioi_thieu";
export const SECTION_GIOI_THIEU_CHUNG = "gioi_thieu_chung";
export const SECTION_TIET_DAY_MINH_HOA = "tiet_day_minh_hoa";
export const SECTION_KE_HOACH_BAI_DAY = "ke_hoach_bai_day";
export const SECTION_BAI_GIANG_POWER_POINT = "bai_giang_power_point";
export const SECTION_GIOI_THIEU_TAP_HUAN_SGK = "gioi_thieu_tap_huan_sgk";
export const SECTION_SACH_ON_THI_THPT = "sach_on_thi";

export const DEFAULT_SUBJECT_SLUG_BY_GRADE = {
  1: "tieng-viet",
  2: "ngu-van",
  3: "ngu-van",
};

export const BOOK_TYPE_SGK = 1;
export const BOOK_TYPE_SGV = 3;
export const BOOK_TYPE_SBT = 5;
export const BOOK_TYPE_SACH_THAM_DINH = 6;
export const BOOK_TYPE_SACH_THAM_KHAO = 7;
export const BOOK_TYPE_SACH_GIOI_THIEU = 8;
export const BOOK_TYPE_BAN_GOP_Y_XA_HOI = 9;
export const BOOK_TYPE_BAN_IN_THU = 10;
export const BOOK_TYPE_BAN_MAU = 11;

export const DOCUMENT_CATEGORY_HOC_LIEU_DIEN_TU = 1;
export const DOCUMENT_CATEGORY_TAI_LIEU_BO_TRO = 2;
export const DOCUMENT_CATEGORY_TAI_LIEU_THAM_KHAO = 6;
export const DOCUMENT_CATEGORY_TAI_LIEU_TAP_HUAN = 3;
export const DOCUMENT_CATEGORY_GIOI_THIEU_SACH = 4;
export const DOCUMENT_CATEGORY_GIOI_THIEU_CHUNG = 5;
export const DOCUMENT_CATEGORY_TIET_DAY_MINH_HOA = 12;
export const DOCUMENT_CATEGORY_KE_HOACH_BAI_DAY = 13;
export const DOCUMENT_CATEGORY_BAI_GIANG_POWER_POINT = 14;
export const DOCUMENT_CATEGORY_GIOI_THIEU_TAP_HUAN_SGK = 15;
export const DOCUMENT_CATEGORY_SACH_ON_THI_THPT = 16;

export const DOCUMENT_TYPE_ALL = 0;
export const DOCUMENT_TYPE_PDF = 1;
export const DOCUMENT_TYPE_VIDEO = 2;
export const DOCUMENT_TYPE_AUDIO = 3;
export const DOCUMENT_TYPE_WORD = 4;
export const DOCUMENT_TYPE_PPT = 5;
export const DOCUMENT_TYPE_ZIP = 6;
export const DOCUMENT_TYPE_VIDEO_DRIVE = 7;
