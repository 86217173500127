import React from "react";

export default function RegisterComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn đăng ký tài khoản trên website Hoc10</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Kính chào quí thầy cô, Hoc10 xin gửi tới quí thầy cô hướng dẫn đăng ký
          tài khoản trên website Hoc10 một cách dễ dàng và thuận thiện nhé!
        </li>
        <li class="flex-lc">
          Để đăng ký tài khoản Hoc10 thầy cô có thể thực hiện theo các bước sau:
        </li>
        <li class="flex-lc">
          <b>Bước 1:</b>&nbsp; Truy cập đường link website&nbsp;
          <a
            className="link"
            href="https://hoc10.vn/"
            target="_blank"
            style={{ flex: "none" }}
          >
            https://hoc10.vn/
          </a>
          &nbsp;và bấm “Đăng ký”
          <div className="mt-3">
            <img
              src={`${urlImg}/register_step1.png`}
              alt="Truy cập đường link website https://hoc10.vn/ và bấm “Đăng ký”"
            />
          </div>
        </li>
        <li class="flex-lc">
          <b>Bước 2:</b>&nbsp; Chọn vị trí tương ứng
          <div className="mt-3">
            <img
              src={`${urlImg}/register_step2.png`}
              alt="Bước 2: Chọn vị trí tương ứng"
            />
          </div>
        </li>
        <li class="flex-lc">
          <p>
            <b>Bước 3:</b>&nbsp; Thầy cô nhập đầy đủ thông tin trên màn hình bao
            gồm: Họ tên, email (nếu có), số điện thoại cá nhân và mật khẩu. Sau
            khi đã điền đầy đủ thông tin thầy cô bấm “Tiếp theo”
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/register_step3.png`}
              alt="Bước 3: Nhập đủ thông tin trên màn hình"
            />
          </div>
          <p className="font-italic">
            <b>Lưu ý:</b>
            &nbsp; Mỗi email hoặc số điện thoại chỉ tạo được một tài khoản do đó
            thầy cô vui lòng sử dụng email và số điện thoại chưa từng đăng ký
            tài khoản nhé.
          </p>
        </li>
        <li class="flex-lc">
          <b>Bước 4:</b>&nbsp; Thầy cô điền thông tin lớp học và các môn học
          mình đang phụ trách.
          <div className="mt-3">
            <img
              src={`${urlImg}/register_step4.png`}
              alt="Bước 4: Thầy cô điền thông tin lớp học và các môn học mình đang phụ trách."
            />
          </div>
          <div className="mt-3">
            <p>
              Trường hợp thầy cô giảng dạy nhiều lớp học, vui lòng bấm “Chọn
              thêm lớp“ và tiếp tục điền thông tin các lớp còn lại.
            </p>
            <img
              src={`${urlImg}/register_step5.png`}
              alt="Trường hợp thầy cô giảng dạy nhiều lớp học, vui lòng bấm “Chọn thêm lớp“"
              className="mt-3"
            />
          </div>
        </li>
        <li class="flex-lc">
          <b>Bước 5:</b>&nbsp; Bấm “Hoàn thành“ để hoàn tất quá trình đăng ký
          tài khoản.
          <div className="mt-3">
            <img
              src={`${urlImg}/register_step6.png`}
              alt="Bước 5: Bấm “Hoàn thành“ để hoàn tất quá trình đăng ký tài khoản."
            />
          </div>
        </li>
        <li>
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li>Chúc thầy cô thao tác thành công!</li>
      </ul>
    </>
  );
}
