import React from "react";
import { TYPE_GAME } from "edu_lms_v2/libraries/hoc10Game/constants";
import * as Games from "edu_lms_v2/libraries/hoc10Game/games";
import * as GameOnBook from "edu_lms_v2/libraries/gameOnBook";

const ViewGame = ({ activity, bookId }) => {
  if (!activity) return null;

  switch (activity.game_id) {
    case TYPE_GAME.CKB_001:
      return <GameOnBook.CKB_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.DAD_BG:
      return <Games.DAD_BG activity={activity} bookId={bookId} />;
    case TYPE_GAME.DAD_BG_TV:
      return <Games.DAD_BG_TV activity={activity} bookId={bookId} />;
    case TYPE_GAME.DAD_Image:
    case TYPE_GAME.DAD_Text:
      return <GameOnBook.DAD activity={activity} bookId={bookId} />;
    case TYPE_GAME.FIB_001:
      return <GameOnBook.FIB_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.FIB_003:
      return <GameOnBook.FIB_003 activity={activity} bookId={bookId} />;
    case TYPE_GAME.FIB_BG:
      return <GameOnBook.FIB_BG activity={activity} bookId={bookId} />;
    case TYPE_GAME.FIB_Math:
      return <GameOnBook.FIBMath activity={activity} bookId={bookId} />;
    case TYPE_GAME.FillColor:
      return <Games.FillColor activity={activity} bookId={bookId} />;
    case TYPE_GAME.FindCorrectMistakes:
      return <Games.FindCorrectMistakes activity={activity} bookId={bookId} />;
    case TYPE_GAME.Graph_001:
      return <GameOnBook.Graph001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.Graph_002:
      return <GameOnBook.Graph002 activity={activity} bookId={bookId} />;
    case TYPE_GAME.IMG_002:
      return <Games.IMG_002 activity={activity} bookId={bookId} />;
    case TYPE_GAME.Karaoke:
      return <Games.Karaoke activity={activity} bookId={bookId} />;
    case TYPE_GAME.MAT_001:
      return <GameOnBook.MAT_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.MAT_BG:
      return <GameOnBook.MAT_BG activity={activity} bookId={bookId} />;
    case TYPE_GAME.MTC_003:
      return <GameOnBook.MTC_003 activity={activity} bookId={bookId} />;
    case TYPE_GAME.MTC_BG:
      return <GameOnBook.MTC_BG activity={activity} bookId={bookId} />;
    case TYPE_GAME.MathInput:
      return <GameOnBook.MathInput activity={activity} bookId={bookId} />;
    case TYPE_GAME.REV_001:
      return <Games.REV_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.SQC_001:
      return <GameOnBook.SQC_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.SROI_001:
      return <GameOnBook.SROI_001 activity={activity} bookId={bookId} />;
    case TYPE_GAME.SentenceFinding:
      return <Games.SentenceFinding activity={activity} bookId={bookId} />;
    case TYPE_GAME.WordFinding:
      return <GameOnBook.WordFinding activity={activity} bookId={bookId} />;
    case TYPE_GAME.WordSearch:
      return <Games.WordSearch activity={activity} bookId={bookId} />;
    default:
      return null;
  }
};

export default ViewGame;
