import { useState, useEffect, useRef } from "react";
import SEO from "edu_lms/components/Seo";
import { ULR_IMAGE_LOGO_HOC10 } from "edu_lms/constants/type";
import { CONTENT_TITLE_KNOWLEDGE, SLUG_KNOWLEDGE } from "../constant";
import { getNewPostBlog, getCateByParent } from "edu_lms/services/blog";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { NotFoundContainer } from "edu_lms/modules/NotFound";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";
import Header from "./Header";
import GradeChild from "./GradeChild";
import NewPost from "./NewPost";
import styles from "../BlogStyle.module.scss";
import { getTotalPage } from "../selection";
import { DEFAULT_TOTAL_PAGE } from "../constant";

export default function Knowledge(props) {
  useScrollToTop();
  const refScreen = useRef(null);
  const [dataNewPost, setDataNewPost] = useState([]);
  const [dataCateByParent, setDataCateByParent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNotFound, setIsNotFound] = useState(false);
  const [totalPage, setTotalPage] = useState(DEFAULT_TOTAL_PAGE);
  const slugKnowledge =
    props.match.params.levelType || SLUG_KNOWLEDGE.knowledge;

  const getDataNewPost = () => {
    const data = {
      limit: totalPage,
      page: currentPage,
      slug: slugKnowledge || "kien-thuc,kien-thuc-khac",
    };
    getNewPostBlog(data).then((res) => {
      if (res.data.status === "success") {
        setDataNewPost(res.data.data);
      } else {
        console.log(res.data.status);
      }
    });
  };

  const getCates = () => {
    const data = {
      slug: slugKnowledge,
    };
    getCateByParent(data).then((res) => {
      if (res.data.status === "success") {
        setDataCateByParent(res.data.data);
      } else {
        console.log(res.data.message);
        setIsNotFound(true);
      }
    });
  };

  useEffect(() => {
    getDataNewPost();
    getCates();
  }, [currentPage, slugKnowledge, totalPage]);

  useEffect(() => {
    const totalPerPage = getTotalPage(refScreen.current.offsetWidth);
    setTotalPage(totalPerPage);
  }, []);

  const contentHeader = CONTENT_TITLE_KNOWLEDGE.find(
    (item) => item.slug === slugKnowledge
  );

  const dataSEO = {
    title: dataCateByParent?.all_cate[slugKnowledge]?.meta_title,
    description: dataCateByParent?.all_cate[slugKnowledge]?.meta_description,
    url: `${process.env.REACT_APP_URL}${
      slugKnowledge !== SLUG_KNOWLEDGE.knowledge
        ? `${SLUG_KNOWLEDGE.knowledge}/${slugKnowledge}`
        : SLUG_KNOWLEDGE.knowledge
    }/`,
    img: ULR_IMAGE_LOGO_HOC10,
    keywords: dataCateByParent?.all_cate[slugKnowledge]?.meta_keyword,
  };

  if (isNotFound) {
    return (
      <main>
        <NotFoundContainer />
      </main>
    );
  }

  return (
    <main ref={refScreen}>
      <SEO data={dataSEO} />
      <ScrollAndFrequentQuestion refScreen={refScreen?.current?.offsetHeight} />
      <div className={styles.knowledge}>
        <Header
          isSchoolLevel={props.match.params.levelType}
          dataCateByParent={dataCateByParent}
          contentHeader={contentHeader}
        />
        <div className={styles.container}>
          <GradeChild
            dataCateByParent={dataCateByParent?.cate_by_slug || []}
            slugKnowledge={props.match.params.levelType}
          />
          <NewPost
            dataNewPost={dataNewPost}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
          />
        </div>
      </div>
    </main>
  );
}
