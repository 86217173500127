import { create } from "zustand";

export const useAuthStore = create((set) => ({
  isLoggedIn: false,
  requiredLoginModalVisibility: false,
  loginModalVisibility: false,
  loginSuccessModalVisibility: false,
  forgotPasswordModalVisibility: false,
  targetRoute: null,

  // state: isLoggedIn
  setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),

  // state: requiredLoginModalVisibility
  setRequiredLoginModalVisibility: (visibility) =>
    set({ requiredLoginModalVisibility: visibility }),
  openRequiredLoginModal: () => set({ requiredLoginModalVisibility: true }),
  closeRequiredLoginModal: () => set({ requiredLoginModalVisibility: false }),

  // state: loginModalVisibility
  setLoginModalVisibility: (visibility) =>
    set({ loginModalVisibility: visibility }),
  openLoginModal: () => set({ loginModalVisibility: true }),
  closeLoginModal: () => set({ loginModalVisibility: false }),

  // state: loginSuccessModalVisibility
  openLoginSuccessModal: () => set({ loginSuccessModalVisibility: true }),
  closeLoginSuccessModal: () => set({ loginSuccessModalVisibility: false }),

  // state: forgotPasswordModalVisibility
  setForgotPasswordModalVisibility: (visibility) =>
    set({ forgotPasswordModalVisibility: visibility }),
  openForgotPasswordModal: () => set({ forgotPasswordModalVisibility: true }),
  closeForgotPasswordModal: () => set({ forgotPasswordModalVisibility: false }),

  // state: targetRoute
  setTargetRoute: (targetRoute) => set({ targetRoute }),
}));
