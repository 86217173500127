export const menu = [
  {
    title: "Hướng dẫn chung",
    item: [
      {
        component: "guideRegister",
        title: "Đăng ký",
        slug: "dang-ky",
        description:
          "Kính chào quí thầy cô, Hoc10 xin gửi tới quí thầy cô hướng dẫn đăng ký tài khoản trên website Hoc10 một cách dễ dàng và thuận thiện nhé!",
      },
      {
        component: "guideSignIn",
        title: "Đăng nhập",
        slug: "dang-nhap",
        description:
          "Để đăng nhập tài khoản Hoc10 thầy cô có thể thực hiện theo 1 trong 3 cách sau:",
      },
      {
        component: "guideUpdateInfo",
        title: "Cập nhật thông tin tài khoản",
        slug: "cap-nhat-thong-tin-tai-khoan",
        description:
          "Kính chào quí thầy cô, sau đây Hoc10 xin hướng thầy cô cách cập nhật thông tin tài khoản trên website Hoc10",
      },
      {
        component: "guideForgotPw",
        title: "Quên mật khẩu",
        slug: "quen-mat-khau",
        description:
          "Vì một vài lí do, thầy cô có thể quên mật khẩu đăng nhập vào tài khoản của website Hoc10. Thầy cô có thể tham khảo bài viết sau để có thể lấy lại mật khẩu và đặt lại mật khẩu mới thầy cô nhé!",
      },
    ],
  },
  {
    title: "Hướng dẫn sử dụng sách",
    item: [
      {
        component: "guideActiveBook",
        title: "Hướng dẫn kích hoạt",
        slug: "huong-dan-kich-hoat",
        description:
          "Kính chào quý thầy cô, hiện nay các sách điện tử trên website https://www.hoc10.vn/ bao gồm sách không cần kích hoạt và sách cần kích hoạt để xem toàn bộ nội dung. Với các đầu sách yêu cầu kích hoạt, bằng cách cào nhẹ lớp phủ bạc bên dưới tem chống giả, thầy cô có thể tìm thấy mã kích hoạt ở bìa sau của sách bản in. Sau khi có mã kích hoạt thầy cô có thể kích hoạt sách bằng một trong 2 cách sau:",
      },
      {
        component: "guideReadingBook",
        title: "Hướng dẫn đọc sách",
        slug: "huong-dan-doc-sach",
        description:
          "Hiện nay Hoc10 đã hỗ trợ nhiều tính năng trong sách điện tử để giúp thầy cô thuận tiện trong việc và giảng dạy. Trong bài biết này, Hoc10 xin được hướng dẫn thầy cô cách sử dụng các tính năng này một cách tốt nhất",
      },
      {
        component: "guideCheckSeriBook",
        title: "Hướng dẫn kiểm tra sách thật",
        slug: "huong-dan-kiem-tra-sach-that",
        description:
          "Nhập mã seri trên tem chống giả ở bìa 4 của sách Cánh Diều để kiểm tra mã kích hoạt còn hoạt động không. Mỗi sách chỉ có một mã seri riêng để kiểm tra. Nếu đã nhập đúng mã seri trên sách nhưng vẫn hiện thông báo “Mã seri không hợp lệ”, có thể bạn đã mua phải sách giả.",
      },
      // {
      //   component: "guideExcercise",
      //   title: "Hướng dẫn làm bài tập tương tác",
      //   description: "Mô tả tóm tắt ......",
      // },
    ],
  },
  {
    title: "Hướng dẫn khác",
    item: [
      // {
      //   component: "guideDowload",
      //   title: "Hướng dẫn tải tài liệu bổ trợ",
      //   description: "Mô tả tóm tắt ......",
      // },
      // {
      //   component: "guideFindLesson",
      //   title: "Hướng dẫn tìm bài giảng",
      //   slug: "huong-dan-tim-bai-giang",
      //   description:
      //     "Hoc10 xin kính chào các thầy cô. Để tìm kiếm bài giảng điện tử, thầy cô thực hiện theo các bước sau:",
      // },
      // {
      //   component: "guideViewLesson",
      //   title: "Hướng dẫn xem nhanh bài giảng",
      //   slug: "huong-dan-xem-nhanh-bai-giang",
      //   description:
      //     "Hoc10 xin kính chào các thầy cô. Để xem nhanh bài giảng điện tử, thầy cô thực hiện theo các bước sau:",
      // },
      // {
      //   component: "guideDownloadLesson",
      //   title: "Hướng dẫn tải bài giảng",
      //   slug: "huong-dan-tai-bai-giang",
      //   description:
      //     "Hoc10 xin kính chào các thầy cô. Để tải bài giảng điện tử trên máy tính, thầy cô thực hiện theo các bước sau:",
      // },
      {
        component: "guideDownloadLesson",
        title: "Hướng dẫn sử dụng bài giảng",
        slug: "huong-dan-su-dung-bai-giang",
        description:
          "Hoc10 xin kính chào các thầy cô. Để tải bài giảng điện tử trên máy tính, thầy cô thực hiện theo các bước sau:",
      },
    ],
  },
];

export const questions = [
  {
    title: "Làm thế nào để đọc sách?",
    url: "https://www.youtube.com/watch?v=o6n8EJBfUJY",
  },
  {
    title: "Làm thế nào để sử dụng kho bài giảng?",
    url: "https://www.youtube.com/watch?v=mpAgzpFsQLo",
  },
];
