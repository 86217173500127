import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import TeachingInfoField from "./TeachingInfoField";
import { postUpdateTeachingInformation } from "edu_lms/services/info/info";
import { getListSubject } from "edu_lms/services/app/app";
import { grades } from "edu_lms/constants/type";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import * as PATH from "consts/routePaths";
import {
  ADD_TRAINING_SUBJECT,
  UPDATE_USER_INFORMATION,
} from "edu_lms/constants/eventNameGTM";
import { onResultUserInfo } from "edu_lms/modules/selection";

let defaultValue = [{ grade: "", subject: [] }];

const Form = styled.form`
  & input {
    height: 36px;
  }
`;

export default function ClassInformation(props) {
  const [listSubjectAll, setListSubjectAll] = useState([]);
  const [isCompleted, setIsCompleted] = useState(true);
  const [initalData, setInitalData] = useState(defaultValue);
  const classGradeInfo = props?.dataUser?.list_grade_subject;
  const typeUpdate = classGradeInfo?.length > 0 ? "update" : "add";

  const { control, handleSubmit, reset, setValue, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      teaching: initalData,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "teaching",
  });

  useEffect(() => {
    getListSubject(0)
      .then((res) => setListSubjectAll(res.data?.data?.list_subject || []))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (classGradeInfo?.length > 0) {
      const formatedGrade = onValueOptionTeachingInformation(
        classGradeInfo,
        listSubjectAll,
        grades
      );
      setInitalData(formatedGrade);
    }
  }, [classGradeInfo, grades, listSubjectAll]);

  useEffect(() => {
    if (classGradeInfo) {
      setValue("teaching", initalData);
    }
  }, [initalData]);
  const handleUpdateGradeSubject = async ({ teaching }) => {
    props.onHide();
    let check = true;
    teaching.map((item) => {
      if (!item.grade || item.subjects < 1) {
        return (check = false);
      }
    });
    if (check) {
      setIsCompleted(true);
      let updateSuccessful = false;
      const data = {
        data_update: JSON.stringify(
          teaching.map((item) => ({
            grade_id: item.grade?.value,
            subjects: item.subjects.map((s) => s.value),
            list_subject: item.subjects.map((s) => ({ subject_id: s.value })),
          }))
        ),
        type: "",
      };
      const result = await postUpdateTeachingInformation(data);

      if (result.data.status === "fail") {
        toast.error(result.data.message);
      }
      if (result.data.status === "success") {
        // props.onUpdateSuccess();
        props.onGetDataHistory();
        updateSuccessful = true;
        toast.success("Cập nhật thông tin thành công!");
      }
      setEventGTM({
        event: UPDATE_USER_INFORMATION,
        type: typeUpdate,
        update_detail: "teaching_info",
        successful: updateSuccessful,
      });
      setEventGTM({
        event: ADD_TRAINING_SUBJECT,
      });
      return result;
    } else {
      setIsCompleted(false);
    }
  };

  const onValueOptionTeachingInformation = (
    classGradeInfo,
    listSubjectAll,
    grades
  ) => {
    let gradeIds = [];
    classGradeInfo.map((_item) => {
      if (!gradeIds.includes(_item.grade_id)) {
        gradeIds.push(_item.grade_id);
      }
    });

    let formatedGrade = gradeIds?.map((_gradeId) => {
      return {
        grade: grades.filter((__item) => __item.value == _gradeId)[0],
        subject: [],
      };
    });

    formatedGrade.map((grade, index) => {
      const valueSubjects = classGradeInfo.filter(
        (classGradeItem) => classGradeItem?.grade_id === grade?.grade?.value
      );
      valueSubjects.map((value) => {
        const subject = listSubjectAll.filter(
          (item) => item.id === value.subject_id
        )[0];
        formatedGrade[index].subject.push({
          value: subject?.id,
          label: subject?.title,
        });
      });
    });

    return formatedGrade;
  };
  // const backSchoolInfor = () => {
  //   props.setShowPersonal(2);
  // };

  const selectedGradeIds = fields.map(({ grade }) => grade?.value);

  return (
    <div
      id="class"
      className="tab-pane fade show active form-h10"
      role="tabpanel"
    >
      <BackSchool className="flex mt-14">
        <h2 className="title">Lớp học</h2>
        <p className="monkey-color-orange monkey-fz-20 pb-3 text-left w-100">
          Vui lòng cập nhật thông tin lớp học, môn học thầy cô cần tham gia tập
          huấn.
        </p>

        {/* <button
          className="btn-reset flex-center txt-4"
          onClick={() => backSchoolInfor()}
        >
          Quay lại cập nhật Trường học
        </button> */}
      </BackSchool>
      <Form
        onSubmit={handleSubmit(handleUpdateGradeSubject)}
        autoComplete="off"
      >
        {fields.map((item, index) => (
          <TeachingInfoField
            key={item.id}
            name="teaching"
            index={index}
            control={control}
            canRemove={fields.length > 1}
            remove={remove}
            setValue={setValue}
            getValues={getValues}
            reset={reset}
            gradeValue={item.grade}
            subjectValue={item.subject}
            grades={grades.filter(
              ({ value }) =>
                value === item.grade?.value || !selectedGradeIds.includes(value)
            )}
          />
        ))}
        {!isCompleted && (
          <div className="text-danger">Vui lòng chọn đầy đủ thông tin</div>
        )}
        <ButtonSubmit className="box-btn box-btn-fw flex mt-14">
          {fields.length < 6 ? (
            <button
              type="button"
              className="btn-back flex-center txt-4"
              onClick={() => append(defaultValue)}
            >
              Thêm lớp
            </button>
          ) : (
            <div />
          )}
          <div className="d-flex">
            <button
              type="button"
              className="btn-back flex-center txt-4 align-self-end mr-3"
              onClick={() => props.onHide()}
            >
              Đóng
            </button>
            <button
              type="submit"
              className="btn-next btn-pr flex-center txt-4 align-self-end"
            >
              Cập nhật
            </button>
          </div>
        </ButtonSubmit>
      </Form>
    </div>
  );
}
const BackSchool = styled.div`
  justify-content: space-between;
  button {
    height: 48px;
    padding: 0 5px;
  }
`;
const ButtonSubmit = styled.div`
  justify-content: space-between;
  button {
    width: 120px !important;
    height: 48px;
    border-radius: 6px;
  }
`;
