import { useState } from "react";
import { StateMachineProvider } from "little-state-machine";
import HeaderRegister from "./HeaderRegister";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { initStore } from "./store";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import * as TYPE from "edu_lms/constants/type";
import SEO from "../../../edu_lms/components/Seo";
import { pages } from "../../../edu_lms/components/Seo/meta";
import { useDispatch } from "react-redux";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import { toast } from "react-toastify";
import { postRegister } from "edu_lms/services/signUp/signUp";
import { onCryptoData } from "edu_lms/modules/selection";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OB_CLASS_INFO, SIGN_UP } from "edu_lms/constants/eventNameGTM";
import {
  postUpdateTeachingInformation,
  postUpdateUserEmail,
  postUpdateUserSchool,
} from "edu_lms/services/info/info";
import { removeAccents } from "edu_lms/components/selection";

initStore();

export default function Register() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalStep, setTotalStep] = useState(3);
  const [checkJob, setCheckJob] = useState({});
  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const { isOpenTraining } = JSON.parse(trainingConfig);
  useScrollToTop();

  const goToNextStep = () => setCurrentStep((step) => step + 1);

  const goToPrevStep = () => setCurrentStep((step) => step - 1);

  const handleChangeJob = (jobId) =>
    setTotalStep(jobId === TYPE.JOB_STUDENT ? 2 : 3);

  const onSubmit = async (data, actions, state) => {
    let arrayTeaching = [];
    data.teaching?.forEach((item) => {
      item.subjects.forEach((subject) => {
        arrayTeaching = [
          ...arrayTeaching,
          removeAccents(`${item.grade.label} ${subject.label}`)
            .toLowerCase()
            .replaceAll("-", "_"),
        ];
      });
    });

    actions.updateState(data);
    const dataSubmit = totalStep === 2 ? data : state;
    try {
      const registerResult = await handleRegister(dataSubmit);
      if (registerResult.data.status === "success") {
        if (totalStep !== 2) await handleUpdateGradeSubject(data);
        await handleUpdateUserSchool({ ...dataSubmit, job_id: state.job_id });
        await handleUpdateEmail(dataSubmit);
        actions.resetState();
        setShow(true);
        dispatch(
          onDispatchDataInfo({ full_name: dataSubmit.full_name, avatar: "" })
        );
        setEventGTM({
          event: OB_CLASS_INFO,
          class_info: arrayTeaching.toString(),
          successful: true,
          failed_reason: "",
        });
      }
    } catch (e) {
      setEventGTM({
        event: OB_CLASS_INFO,
        class_info: arrayTeaching.toString(),
        successful: false,
        failed_reason: e.response?.data?.message,
      });
      toast.error(e.response?.data?.message);
    }
  };

  const handleRegister = async (state) => {
    let signUpSuccess = false;
    const result = await postRegister({
      type: TYPE.PHONE_TYPE,
      phone: state.phone,
      email: state.email,
      is_web: 1,
      app_id: TYPE.APP_ID,
      password: state.password,
    });
    const registerData = result.data;
    if (registerData.status === "fail") {
      toast.error(registerData.message);
      signUpSuccess = false;
    }
    if (registerData.status === "success") {
      localStorage.setItem("token", registerData.data.access_token);
      localStorage.setItem("user_id", registerData.data.user_id);
      const encryptUserInfo = onCryptoData(
        registerData.data.user_info,
        TYPE.LOCAL_STORAGE_KEY_USER_INFO
      );
      localStorage.setItem(TYPE.LOCAL_STORAGE_KEY_USER_INFO, encryptUserInfo);
      signUpSuccess = true;
    }
    // event sign up success
    setEventGTM({
      event: SIGN_UP,
      sign_up_type: "phone",
      sign_up_successful: signUpSuccess,
    });
    return result;
  };

  const handleUpdateUserSchool = async (state) => {
    const result = await postUpdateUserSchool({
      job_id: state.job_id,
      full_name: state.full_name,
      email: state.email,
    });
    if (result.data.status === "fail") {
      toast.error(result.data.message);
    }
    if (result.data.status === "success") {
      //
    }
  };

  const handleUpdateGradeSubject = async ({ teaching }) => {
    const data = {
      data_update: JSON.stringify(
        teaching.map((item) => ({
          grade_id: item.grade?.value,
          subjects: item.subjects.map((s) => s.value),
          list_subject: item.subjects.map((s) => ({ subject_id: s.value })),
        }))
      ),
      type: "",
    };
    const result = await postUpdateTeachingInformation(data);

    if (result.data.status === "fail") {
      toast.error(result.data.message);
    }
    if (result.data.status === "success") {
      //
    }

    return result;
  };

  const handleUpdateEmail = async (state) => {
    const userInfo = {
      email: state.email,
      app_id: TYPE.APP_ID,
      is_web: 1,
      phone: state.phone,
    };

    const result = await postUpdateUserEmail(userInfo);

    if (result.data?.status === "fail") {
      toast.error(result.data.message);
    }
  };

  return (
    <StateMachineProvider>
      <HeaderRegister currentStep={currentStep} totalStep={totalStep} />
      <main>
        <SEO data={pages.register} />
        <div className="box-register">
          {currentStep === 1 && (
            <Step1
              goToNextStep={goToNextStep}
              handleChangeJob={handleChangeJob}
              setCheckJob={setCheckJob}
            />
          )}
          {currentStep === 2 && (
            <Step2
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
              onComplete={onSubmit}
              show={show}
              totalStep={totalStep}
              currentStep={currentStep}
              checkJob={checkJob}
              isOpenTraining={isOpenTraining}
            />
          )}
          {currentStep === 3 && (
            <Step3
              goToPrevStep={goToPrevStep}
              onSubmit={onSubmit}
              show={show}
              checkJob={checkJob}
            />
          )}
        </div>
      </main>
    </StateMachineProvider>
  );
}
