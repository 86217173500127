import React from "react";
import styled from "styled-components";

const OrderAnswerNoIndex = ({
  answer,
  selectedAnswers,
  styles = {},
  onClick = () => {},
}) => {
  const { color } = styles;

  return (
    <Label color={color}>
      <input
        type="checkbox"
        name="selectedAnswer"
        checked={selectedAnswers.includes(answer.answerId)}
        value={answer.answerId}
        onChange={onClick}
      />
    </Label>
  );
};

export default OrderAnswerNoIndex;

const Label = styled.label`
  position: absolute;
  top: 5px;
  left: 5px;

  input {
    cursor: pointer;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: rgb(238, 238, 238);
    border: 2px solid rgb(238, 238, 238);
    border-radius: 50%;
  }
  input:checked {
    border: 7px solid ${props => props.color};
    background-color: white;
  }
`;
