import { useState, useEffect, useRef } from "react";
import { getDetailArticle } from "edu_lms/services/blog";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import HeadingViewDetail from "../ViewDetail/HeadingViewDetail";
import TitleDetail from "../ViewDetail/TitleDetail";
import styles from "../BlogStyle.module.scss";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";
import Loading from "edu_lms_v2/components/Loading";
import { NotFoundContainer } from "edu_lms/modules/NotFound";

export default function KnowledgeNewsPost(props) {
  useScrollToTop();
  const refScreen = useRef(null);
  const [detailItem, setDetailItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getDataKnowledgeNewsPost = () => {
    const data = {
      alias: props.match.params.slug,
    };
    getDetailArticle(data).then((res) => {
      setIsLoading(false);
      if (res.data.status === "success") {
        setDetailItem(res.data.data);
      } else {
        console.log(res.data.message);
      }
    });
  };

  useEffect(() => {
    getDataKnowledgeNewsPost();
  }, []);

  if (isLoading) {
    return (
      <main className={styles.height100}>
        <Loading />
      </main>
    );
  }

  if (!detailItem) {
    return (
      <main>
        <NotFoundContainer />
      </main>
    );
  }

  return (
    <main className={styles.knowledge} ref={refScreen}>
      {detailItem && (
        <div className={styles.containerDetail}>
          <ScrollAndFrequentQuestion
            refScreen={refScreen?.current?.offsetHeight}
          />
          <HeadingViewDetail />
          <TitleDetail detailItem={detailItem} params={props.match.params} />
        </div>
      )}
    </main>
  );
}
