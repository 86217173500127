import { GRADES } from "edu_lms/constants/type";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";

export let SCHOOL_LEVEL_DEFAULT = [];
export let DEFAULT_GRADE_ID;
export let DEFAULT_SUBJECT_ID;
export let DEFAULT_VALUE_BLOCK;
export let CLASS_LEVEL_SCHOOL_DEFAULT;
const product = Number(process.env.REACT_APP_APP_ID);

if (product === HOC10_APP_ID) {
  DEFAULT_GRADE_ID = 4;
  DEFAULT_SUBJECT_ID = 32;
  DEFAULT_VALUE_BLOCK = 1;
  CLASS_LEVEL_SCHOOL_DEFAULT = {
    'lop-1' : 1,
    'lop-2' : 1,
    'lop-3' : 1,
    'lop-4' : 1,
    'lop-5' : 1,
    'lop-6' : 2,
    'lop-7' : 2,
    'lop-8' : 2,
    'lop-9' : 2,
    'lop-10' : 3,
    'lop-11' : 3,
    'lop-12' : 3,
  }
  SCHOOL_LEVEL_DEFAULT = [
    {
      schoolLevel: 1,
      gradeIdDefault: GRADES.lop1,
      subjectIdDefault: 32,
    },
    {
      schoolLevel: 2,
      gradeIdDefault: GRADES.lop6,
      subjectIdDefault: 45,
    },
    {
      schoolLevel: 3,
      gradeIdDefault: GRADES.lop10,
      subjectIdDefault: 72,
    },
  ];
}

if (product === TUTORING_APP_ID) {
  DEFAULT_GRADE_ID = 19;
  DEFAULT_SUBJECT_ID = 74;
  DEFAULT_VALUE_BLOCK = 16;
  SCHOOL_LEVEL_DEFAULT = [
    {
      schoolLevel: 16,
      gradeIdDefault: 19,
      subjectIdDefault: 74,
    },
    {
      schoolLevel: 17,
      gradeIdDefault: 22,
      subjectIdDefault: 75,
    },
    {
      schoolLevel: 18,
      gradeIdDefault: 24,
      subjectIdDefault: 76,
    },
  ];
}

export const TYPE_MOCK_TEST = 2;
export const LICENSE_FREE = 1;
export const LICENSE_LOGIN = 2;
