const styleWrapper = {
  height: "92%",
  overflowY: "auto",
  overflowX: "hidden",
};

const COLOR = {
  Success: "#92c83e",
  Error: "#ee202e",
  Default: "#939598",
  None: "transparent",
  White: "#fff",
  Black: "#000",
  Orange: "#ffeede"
};

const ANSWER_COLOR = {
  NONE: "transparent",
  DEFAULT: "black",
  DEFAULT_BLUE: "#00c2f3",
  CORRECT: "green",
  WRONG: "red",
};

export { styleWrapper, COLOR, ANSWER_COLOR };
