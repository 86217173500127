import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";
import { toast } from "react-toastify";

export default function PopUpAssignSuccess({
  classRoom,
  showGradeName,
  question,
  open,
  onClose,
}) {
  return (
    <Modal
      show={open}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success "
      backdrop="static"
      animation="true"
      size="lg"
    >
      <div className="box-success p-0 position-relative">
        <div className="img-wrapper">
          <img src="/assets/img/img-success.svg" alt="Giao bài thành công" />
        </div>
        <TitleAssignSuccess className="text-center pt-1">
          Giao bài thành công!
        </TitleAssignSuccess>

        <div
          className="text-left mt-2 p-3"
          style={{ font: "18px/30px var(--SVN-GilroyMedium)" }}
        >
          Thầy cô đã tạo thành công{" "}
          <span style={{ color: "black" }}>{question.title}</span> giao cho
          {classRoom.map((item, index) => {
            const isLast = index !== classRoom.length - 1;
            return (
              <span style={{ color: "black" }}>
                {""}
                {showGradeName(item.grade_id)}
                {item.title}
                {!isLast && ","}{" "}
              </span>
            );
          })}
          . Thầy cô thực hiện copy link bài tập bên dưới và gửi link cho các lớp
          tương ứng qua Zalo, Messenger hoặc các mạng xã hội khác để học sinh có
          thể vào làm bài và nộp bài.
        </div>

        <div
          className="d-flex mt-3 flex-wrap"
          style={{ justifyContent: "space-evenly", gap: "5px" }}
        >
          {classRoom.map((item) => {
            return (
              <>
                <div>
                  <div
                    className="font-weight-bold text-center"
                    style={{ color: "black" }}
                  >
                    {showGradeName(item.grade_id)}
                    {item.title}
                  </div>

                  <button
                    className="btn btn-sub"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_URL}hoc-sinh-lam-bai/${item.class_room_question_set_id}`
                      );
                      toast.success("Copy link giao bài thành công!");
                      onClose();
                    }}
                  >
                    <i class="fa fa-clipboard" aria-hidden="true" />
                    Copy Link
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <ClosePopup
          onClick={onClose}
          className={classNames(
            "position-absolute monkey-f-header cursor p-2",
            {
              "on-desktop": isDesktop,
              "on-mobile": !isDesktop,
            }
          )}
        >
          X
        </ClosePopup>
      </div>
    </Modal>
  );
}

const ClosePopup = styled.div`
  &.on-desktop {
    top: -50px;
    right: 10px;
  }
  &.on-mobile {
    top: -20px;
    right: 10px;
  }
`;

const TitleAssignSuccess = styled.div`
  font: 36px/48px var(--SVN-GilroyBold);
  color: #2a404f;
`;
