import React, { useState, Fragment, useEffect } from "react";
import * as TEXT from "../../constants/text";
import { getListBook } from "../../services/general/general";
import { TYPE_TEXT_BOOKS } from "../../constants/general";
import Select from "react-select";

const ListActiveBook = ({
  messageError,
  onDispatchShowLoading,
  onActiveBook,
}) => {
  const [dataBook, setStateDataBook] = useState([]);
  const [valueSelect, setStateValueSelect] = useState("");
  useEffect(() => {
    const parameterTextBooks = {
      typeBook: TYPE_TEXT_BOOKS,
      gradeId: 5,
    };
    onDispatchShowLoading(true);
    onGetListBook(parameterTextBooks);
  }, []);

  const onGetListBook = (paramter) => {
    getListBook(paramter)
      .then((data) => {
        if (data.data.status === "success") {
          const listBook = data.data.data.list_book.map((item, index) => {
            const value = item.id;
            const label = item.title;
            return { ...item, value, label };
          });
          setStateDataBook(listBook);
          onDispatchShowLoading(false);
        }
        // if (data.data === 401) {
        //   localStorage.clear();
        //   this.props.history.push(PATH.ROUTE_PATH_SIGN_IN);
        //   onDispatchShowLoading(false);
        // }
      })
      .catch((errors) => {
        console.log(errors);
        onDispatchShowLoading(false);
      });
  };
  const onChangeSelectBook = (data) => {
    setStateValueSelect(data);
  };
  return (
    <Fragment>
      <form className="text-center mt-5 mb-5 p-3">
        <div className="form-group">
          <Select
            value={valueSelect}
            onChange={onChangeSelectBook}
            options={dataBook}
            styles={customStyles}
            isSearchable={false}
            placeholder="Chọn sách"
          />
        </div>
        {messageError && <div className="monkey-color-red">{messageError}</div>}
        <input
          style={{ height: "44px" }}
          className="btn mt-4 monkey-bg-violet monkey-color-white rounded-pill monkey-pr-45 monkey-pl-45"
          type="button"
          onClick={() => {
            onActiveBook(valueSelect);
          }}
          value={TEXT.TEXT_ACTIVATE_BOOK}
        />
      </form>
    </Fragment>
  );
};

export default ListActiveBook;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    cursor: "pointer",
  }),
  control: (base) => ({
    ...base,
    height: 44,
    borderRadius: 30,
    zIndex: 500,
    backgroundColor: "#fff",
    cursor: "pointer",
  }),
};
