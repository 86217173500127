import JXG from "jsxgraph";
import { GRAPH_TYPE } from "../constants/Graph";
import { ANSWER_COLOR } from "../constants/styles";

const createBoard = ({
  boardId = "jxgBox",
  boundingBox = [-5, 5, 5, -5],
  showNavigation = false,
  enabledDrag = false,
}) => {
  return JXG.JSXGraph.initBoard(boardId, {
    boundingbox: boundingBox,
    axis: true,
    showNavigation,
    showCopyright: false,
    drag: { enabled: enabledDrag },
    defaultAxes: {
      x: {
        name: "x",
        withLabel: true,
        label: {
          position: "rt",
          offset: [0, 10],
        },
      },
      y: {
        withLabel: true,
        name: "y",
        label: {
          position: "rt",
          offset: [10, 0],
        },
      },
    },
  });
};

const getGraphFactorWithSuffix = (f, suffix) => {
  if (f === 0) return "";
  if (f === 1) return suffix;
  if (f === -1) return `- ${suffix}`;
  if (f > 0) return `${f}${suffix}`;
  return `- ${Math.abs(f)}${suffix}`;
};
const getGraphFactor = (f) => {
  if (f === 0) return "";
  if (f > 0) return `+ ${f}`;
  return `- ${Math.abs(f)}`;
};
const getEquationGraph = (graphType, coefficient) => {
  const { a, b, c = 0 } = coefficient;
  if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
    return `\\(y = ${getGraphFactorWithSuffix(a, "x")} ${getGraphFactor(b)}\\)`;
  }
  if (graphType === GRAPH_TYPE.QuadraticEquation) {
    return `\\(y = ${getGraphFactorWithSuffix(a, "x^2")} ${b > 0 ? "+" : ""} ${getGraphFactorWithSuffix(b, "x")} ${getGraphFactor(c)} \\)`;
  }
  return "";
};

const convertCoordinatesToInteger = (point) => {
  const dx = Math.round(point.X());
  const dy = Math.round(point.Y());
  return [dx, dy];
};

const getAnswerColor = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return ANSWER_COLOR.CORRECT;
    case false:
      return ANSWER_COLOR.WRONG;
    default:
      return ANSWER_COLOR.DEFAULT_BLUE;
  }
};

const getAnswerColorPoint = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return ANSWER_COLOR.CORRECT;
    case false:
      return ANSWER_COLOR.WRONG;
    default:
      return "#d45f03";
  }
};

export {
  createBoard,
  getGraphFactorWithSuffix,
  getEquationGraph,
  convertCoordinatesToInteger,
  getAnswerColor,
  getAnswerColorPoint,
};
