import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { toast } from "react-toastify";
import styled from "styled-components";
import { postCountShareFbAndCopyLink } from "edu_lms/services/teachingManager";
import { UPDATE_COUNT_COPY, UPDATE_COUNT_SHARE } from "edu_lms/constants/type";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { SHARE_TEST } from "edu_lms/constants/eventNameGTM";
import { useSelector, useDispatch } from "react-redux";
import { onDispatchSetStateDoingInfo } from "edu_lms/modules/DoingExercise/actions";

export const ShareAndCopy = (props) => {
  const dispatch = useDispatch();
  const doingInfo = useSelector((state) => state.doingExerciseReducers.doingInfo);

  const share = () => {
    const data = {
      questionSetId: props.questionSetId,
      typeUpdate: UPDATE_COUNT_SHARE,
    };
    postCountShareFbAndCopyLink(data).catch((error) => console.log(error));

    setEventGTM({
      event: SHARE_TEST,
      test_name: props.title,
      test_grade: props.gradeName,
      test_subject: props.subjectName,
    });
  };
  const copyLink = () => {
    const data = {
      questionSetId: props.questionSetId,
      typeUpdate: UPDATE_COUNT_COPY,
    };
    postCountShareFbAndCopyLink(data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
    toast.success("Copy link thành công!");
  };

  return (
    <>
      <Button
        className="btn-share flex-center btn-sub"
        id="btnShare"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => dispatch(onDispatchSetStateDoingInfo({...doingInfo, clickShare: true}))}
      >
        <i className="fa fa-share-alt" aria-hidden="true" />
      </Button>
      <div className="dropdown-menu" aria-labelledby="share">
        <p className="dropdown-item" onClick={(e) => share()}>
          <FacebookShareButton
            url={props.urlShareFb}
            quote={props?.title}
            hashtag={"#hoc10.vn"}
            description={props?.title}
            className="hoc10"
          >
            <FacebookIcon size={32} round /> Facebook
          </FacebookShareButton>
        </p>
        <CopyToClipboard text={props.urlShareFb} onCopy={() => copyLink()}>
          <p className="dropdown-item cursor">
            <IconCopyLink className="fa fa-link" aria-hidden="true" /> Copy link
          </p>
        </CopyToClipboard>
      </div>
    </>
  );
};

const Button = styled.button`
  width: 100px;
  height: 40px;
  color: #606e78;
  margin-right: 5px;
`;
const IconCopyLink = styled.i`
  background: #bb0000;
  color: #fff;
  padding: 8px 8px;
  border-radius: 50px;
`;
