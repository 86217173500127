import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Select from "react-select";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import {
  getDataUrlListProvince,
  getDataUrlListSchoolByProvince,
  postUpdateUserInformation,
} from "edu_lms/services/info/info";
import { TypeSchool } from "edu_lms/modules/UpdateInformation/config";
import * as TEXT from "edu_lms/constants/text";
import { UPDATE_USER_INFORMATION } from "edu_lms/constants/eventNameGTM";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { useDispatch } from "react-redux";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import { postUserInfo } from "edu_lms/services/signIn/signIn";
import { handleForceOpenTraining } from "edu_lms_v2/utils/training";

export default function SchoolInformation(props) {
  const dispatch = useDispatch();
  const dataUser = props.dataUser;

  const dataUserInfo = onResultUserInfo();
  const typeUpdate = props.dataUser?.province_id ? "update" : "add";
  const [listProvince, setListProvince] = useState([]);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);
  const [listSchool, setListSchool] = useState([]);
  const [isOtherSchool, setIsOtherSchool] = useState(false);

  const otherSchoolOption = { value: -1, label: "Khác" };
  const schema = yup
    .object()
    .shape({
      province_id: yup.object().required("Vui lòng chọn Tỉnh/ Thành phố"),
      district_id: yup.object().required("Vui lòng chọn Quận/ Huyện/ Thị xã"),
      ward_id: yup.object().required("Vui lòng chọn Xã/ Phường/ Thị trấn"),
      school_id: yup.object().required("Vui lòng chọn Trường"),
      styleSchool:
        isOtherSchool &&
        yup.object().when("school_id", (school_id) => {
          if (school_id.value === -1) {
            return yup.object().required("Vui lòng chọn Loại trường");
          }
        }),
      school_name:
        isOtherSchool &&
        yup.string().when("school_id", (school_id) => {
          if (school_id.value === -1) {
            return yup.string().required("Vui lòng nhập Tên trường mới");
          }
        }),
    })
    .required();

  const { register, handleSubmit, setValue, errors, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const formatedValueOption = (list) => {
    const formatedValue = list.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    return formatedValue;
  };

  const getValueOption = (list, id) => {
    const valueOption = list.filter((item) => item.value === id)[0];
    return valueOption;
  };
  useEffect(() => {
    const listProvince = formatedValueOption(props.listProvince);
    setListProvince(listProvince);
  }, [props.listProvince]);

  useEffect(() => {
    if (dataUser.province_id) {
      setValue(
        "province_id",
        getValueOption(listProvince, dataUser.province_id)
      );
      getDataUrlListProvince(dataUser.province_id).then((res) => {
        if (res.data.status === "success") {
          setListDistrict(formatedValueOption(res.data.data));
        }
      });
      getDataUrlListProvince(dataUser.district_id).then((res) => {
        if (res.data.status === "success") {
          setListWard(formatedValueOption(res.data.data));
        }
      });
      getDataUrlListSchoolByProvince(dataUser.ward_id, 0).then((res) => {
        if (res.data.status === "success") {
          setListSchool(formatedValueOption(res.data.data));
        }
      });
    }
  }, [dataUser, listProvince]);

  useEffect(() => {
    setValue("district_id", getValueOption(listDistrict, dataUser.district_id));
  }, [listDistrict, dataUser]);
  useEffect(() => {
    setValue("ward_id", getValueOption(listWard, dataUser.ward_id));
  }, [listWard, dataUser, dataUser.ward_id]);

  useEffect(() => {
    if (dataUser.status_school) {
      setValue("school_id", getValueOption(listSchool, dataUser.school_id));
      setIsOtherSchool(false);
    } else {
      if (dataUser.status_school === 0) {
        // setValue("school_id", otherSchoolOption);
        // setIsOtherSchool(true);
        if (dataUser.school_name) {
          const arrSchoolName = dataUser.school_name.split(",");
          const index = TypeSchool.map((e) => e.label).indexOf(
            arrSchoolName[0]
          );
          setValue("styleSchool", TypeSchool[index]);
          setValue("school_name", arrSchoolName[1]);
        }
      }
    }
  }, [listSchool, dataUser, TypeSchool]);

  useEffect(() => {
    if (dataUser.school_name) {
      // setIsOtherSchool(true);
      const arrSchoolName = dataUser.school_name.split(",");
      const index = TypeSchool.map((e) => e.label).indexOf(arrSchoolName[0]);
      setValue("styleSchool", TypeSchool[index]);
      setValue("school_name", arrSchoolName[1]);
    }
  }, [dataUser]);

  const onChangeProvince = (idProvince) => {
    getDataUrlListProvince(idProvince).then((res) =>
      setListDistrict(formatedValueOption(res.data.data))
    );
  };

  const onChangeDistrict = (idDistrict) => {
    getDataUrlListProvince(idDistrict).then((res) =>
      setListWard(formatedValueOption(res.data.data))
    );
  };
  const onChangeWard = (idWard) => {
    getDataUrlListSchoolByProvince(idWard, 0).then((res) =>
      setListSchool(formatedValueOption(res.data.data))
    );
  };

  const onChangeSchool = (idSchool) => {
    if (idSchool === -1) {
      setIsOtherSchool(true);
    } else {
      setIsOtherSchool(false);
    }
  };

  const onHandleSubmit = (dataForm) => {
    const province_id = dataForm.province_id.value;
    const province_name = dataForm.province_id.label;
    const district_id = dataForm.district_id.value;
    const district_name = dataForm.district_id.label;
    const ward_id = dataForm.ward_id.value;
    const ward_id_name = dataForm.ward_id.label;
    const school_id = dataForm.school_id.value;
    const school_name = dataForm.school_id.label;

    let dataSchool = {
      province_id,
      province_name,
      district_id,
      district_name,
      ward_id,
      ward_id_name,
      school_id,
      school_name,
    };
    if (school_id === -1) {
      const schoolName = `${dataForm.styleSchool.label},${dataForm.school_name}`;
      dataSchool = {
        ...dataSchool,
        school_id: "",
        school_name: schoolName,
        type_school: 1,
      };
    }

    postUpdateUserInformation(dataSchool).then((res) => {
      let updateSuccessful = false;
      if (res.data.status === "success") {
        props.onUpdateSuccess({
          province_name,
          district_name,
          ward_id_name,
          school_id,
          school_name,
          ...dataSchool,
        });
        toast.success("Cập nhật thông tin thành công!");
        const newDataUserInfo = {
          ...dataUserInfo,
          ...dataSchool,
        };
        dispatch(
          onDispatchDataInfo({
            ...dataSchool,
          })
        );
        setItemUserInfo(newDataUserInfo);
        updateSuccessful = true;
        props.resetForm(dataForm);

        postUserInfo(dataUserInfo.user_id)
        .then((res) => {
          if (res.data.status === "success") {
            const data = res.data.data;
            handleForceOpenTraining(data.training_info, newDataUserInfo.is_user_hoc10);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        toast.error(res.data.message);
      }
      setEventGTM({
        event: UPDATE_USER_INFORMATION,
        type: typeUpdate,
        update_detail: "school_info",
        successful: updateSuccessful,
      });
    });
    props.onHide();
  };

  const onReset = () => {
    setValue("province_id", "");
    setValue("district_id", "");
    setValue("ward_id", "");
    setValue("school_id", "");
    setIsOtherSchool(false);
    props.resetForm();
  };
  // const backSchoolInfor = () => {
  //   props.setShowPersonal(1);
  // };

  return (
    <div
      id="school"
      className="tab-pane fade show active form-h10"
      role="tabpanel"
    >
      <BackPersonal className="flex mt-14">
        <h2 className="title">Trường học</h2>
        <p className=" pb-3 text-left monkey-fz-20 monkey-color-orange">
          Kết quả tập huấn sẽ được gửi về Sở và EMAIL GIÁO VIÊN! Thầy cô vui
          lòng cập nhật thông tin chính xác và đầy đủ.
        </p>
        {/* <button
          // type="button"
          className="btn-reset flex-center txt-4"
          onClick={() => backSchoolInfor()}
        >
          Quay lại cập nhật cá nhân
        </button> */}
      </BackPersonal>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-12 form-group select-school">
                <label>
                  {TEXT.TEXT_PROVINCE} <sup>*</sup>
                </label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Vui lòng chọn tỉnh/ thành phố"
                      options={listProvince}
                      maxMenuHeight={200}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeProvince(e.value);
                      }}
                    />
                  )}
                  name="province_id"
                  control={control}
                />
                <p className="text-danger">{errors.province_id?.message}</p>
              </div>
              <div className="col-12 form-group select-school">
                <label>
                  {TEXT.TEXT_DISTRICT} <sup>*</sup>
                </label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Vui lòng chọn quận/ huyện/ thị xã"
                      options={listDistrict}
                      maxMenuHeight={200}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeDistrict(e.value);
                      }}
                    />
                  )}
                  name="district_id"
                  control={control}
                />
                <p className="text-danger">{errors.district_id?.message}</p>
              </div>
              <div className="col-12 form-group select-school">
                <label>
                  {TEXT.TEXT_WARD} <sup>*</sup>
                </label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Vui lòng chọn xã/ phường/ thị trấn"
                      options={listWard}
                      maxMenuHeight={200}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeWard(e.value);
                      }}
                    />
                  )}
                  name="ward_id"
                  control={control}
                />
                <p className="text-danger">{errors.ward_id?.message}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-12 form-group select-school">
                <label>
                  {TEXT.TEXT_SCHOOL} <sup>*</sup>
                </label>
                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Vui lòng chọn trường"
                      options={listSchool}
                      maxMenuHeight={200}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        onChangeSchool(e.value);
                      }}
                    />
                  )}
                  name="school_id"
                  control={control}
                />
                <p className="text-danger">{errors.school_id?.message}</p>
              </div>
              {isOtherSchool && (
                <div className="col-12 form-group select-school">
                  <label>
                    {TEXT.TEXT_TYPE_SCHOOL} <sup>*</sup>
                  </label>
                  <Controller
                    render={({ onChange, value }) => (
                      <Select
                        placeholder="Vui lòng chọn loại trường"
                        options={TypeSchool}
                        maxMenuHeight={200}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                    name="styleSchool"
                    control={control}
                  />
                  <p className="text-danger">{errors.styleSchool?.message}</p>
                </div>
              )}
              {isOtherSchool && (
                <div className="col-12 form-group select-school">
                  <label>
                    {TEXT.TEXT_NEW_SCHOOL_NAME} <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    placeholder="Nhập tên trường"
                    name="school_name"
                    ref={register}
                    className="input-update"
                  />
                  <p className="text-danger">{errors.school_name?.message}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <ButtonSubmit className="box-btn box-btn-fw flex mt-14">
          <button
            type="button"
            className="btn-reset flex-center txt-4"
            onClick={() => onReset()}
          >
            Hủy
          </button>
          <button type="submit" className="btn-submit flex-center btn-pr">
            Gửi
          </button>
        </ButtonSubmit>
      </Form>
    </div>
  );
}
const Form = styled.form`
  & input {
    height: 36px;
  }
`;
const BackPersonal = styled.div`
  justify-content: space-between;
  button {
    height: 48px;
    padding: 0 5px;
  }
`;
const ButtonSubmit = styled.div`
  justify-content: space-between;
  button {
    width: 120px !important;
    height: 48px;
    border-radius: 6px;
  }
`;
