import React from "react";
import { HashLink } from "react-router-hash-link";
import styles from "./ReadingBookComponent.module.scss";

export default function ReadingBookComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn sử dụng các tính năng trong sách điện tử</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Hiện nay Hoc10 đã hỗ trợ nhiều tính năng trong sách điện tử để giúp
          thầy cô thuận tiện trong việc và giảng dạy. Trong bài viết này, Hoc10
          xin được hướng dẫn thầy cô cách sử dụng các tính năng này một cách tốt
          nhất.
        </li>
        <ul className={styles.tableMenu}>
          <h3>
            <p>Nội dung chính</p>
          </h3>
          <li>
            <HashLink
              to="#zoom-sach"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "auto", block: "center" })
              }
            >
              1. Zoom sách (Thu phóng màn hình)
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#tim-den-trang-dich"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "auto", block: "center" })
              }
            >
              2. Tìm đến trang đích
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#tra-cuu-noi-dung"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "auto", block: "center" })
              }
            >
              3. Tra cứu nội dung theo mục lục điện tử
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#su-dung-thanh-cong-cu"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "auto", block: "center" })
              }
            >
              4. Sử dụng thanh công cụ
            </HashLink>
          </li>
          <li>
            <HashLink
              to="#xem-huong-dan"
              scroll={(el) =>
                el.scrollIntoView({ behavior: "auto", block: "center" })
              }
            >
              5. Xem hướng dẫn
            </HashLink>
          </li>
        </ul>
        <li class="flex-lc">
          <b id="zoom-sach">1.</b>&nbsp; Zoom sách (Thu phóng màn hình)
          <p className="pl-3">
            Tính năng giúp thầy cô phóng to thu nhỏ vùng nội dung theo ý muốn.
            Hơn nữa, tính năng zoom trên sách điện tử của Hoc10 hỗ trợ thầy cô
            zoom theo vùng chọn. Có nghĩa là việc thu phóng không chỉ từ giữa ra
            mà thầy cô có thể zoom 1 điểm cố định bất kỳ theo vị trí của con trỏ
            chuột.
          </p>
          <p className="mt-3">
            Để zoom sách, thầy cô có thể làm theo 1 trong 2 cách sau:
          </p>
          <p className="mt-3">
            <b>Cách 1:</b>&nbsp; Lăn con lăn chuột ( nút chuột giữa ) lên trên
            để phóng to hình ảnh và lăn xuống để thu nhỏ.
          </p>
          <p className="mt-3">
            <b>Cách 2:</b>&nbsp; Click vào ký tự hình “ống nhòm” ở góc phải bên
            dưới màn hình để thao tác phóng to, thu nhỏ hình ảnh.
          </p>
          <div className="mt-3">
            <img src={`${urlImg}/zoom_10_2.png`} alt="zoom_img" />
          </div>
        </li>
        <li class="flex-lc">
          <b id="tim-den-trang-dich">2.</b>&nbsp; Tìm đến trang đích
          <p className="pl-3">
            Công cụ này hỗ trợ thầy cô trong trường hợp tìm kiếm một trang sách
            mà không cần lật giở từng trang. Thầy cô thao tác như sau:
          </p>
          <p className="mt-3">
            <b>Bước 1:</b>&nbsp; Chọn phần hiển thị số trang phía dưới màn hình.
          </p>
          <p className="mt-3">
            <b>Bước 2:</b>&nbsp; Nhập số trang và nhấn Enter, hệ thống sẽ chuyển
            đến trang mà thầy cô mong muốn và bắt đầu sử dụng.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/tim_trang_dich_10_2.png`}
              alt="tim_trang_dich_img"
            />
          </div>
        </li>
        <li class="flex-lc">
          <b id="tra-cuu-noi-dung">3.</b>&nbsp; Tra cứu nội dung theo mục lục
          điện tử
          <p className="mt-3">
            <b>Bước 1:</b>&nbsp; Thầy cô ấn vào biểu tượng “Ba dấu gạch ngang”
            tại góc trái phía dưới màn hình
          </p>
          <div className="mt-3">
            <img src={`${urlImg}/tra_muc_luc_10_2.png`} alt="tra_muc_luc_img" />
          </div>
          <p className="mt-3">
            <b>Bước 2:</b>&nbsp; Ở phía bên trái màn hình thanh công cụ sẽ hiển
            thị mục lục gồm: tên bài, chương, chủ đề, bảng giải thích từ
            ngữ...Tại đây thầy cô có thể chọn vào bài học mình mong muốn, sách
            sẽ tự động mở đến phần học mà thầy cô đã chọn và sử dụng.
          </p>
        </li>
        <li class="flex-lc">
          <b id="su-dung-thanh-cong-cu">4.</b>&nbsp; Sử dụng thanh công cụ
          <p className="pl-3">
            Với thanh công cụ này, Hoc10 cung cấp các tính năng để thầy cô có
            thể thao tác dễ dàng ngày trên các vùng nội dung của sách điện tử
            bao gồm:
          </p>
          <ul
            className="list-ask-question pl-4 mt-3"
            style={{ lineHeight: "10px" }}
          >
            <li>- Bút vẽ</li>
            <li>- Bút vẽ nét đậm</li>
            <li>- Chèn văn bản</li>
            <li>- Chèn liên kết</li>
            <li>- Vẽ đoạn thẳng</li>
            <li>- Vẽ khung cạch vuông</li>
            <li>- Vẽ khung cạnh tròn</li>
            <li>- Tẩy, xóa nội dung</li>
            <li>- Thay đổi màu nội dung chèn</li>
          </ul>
          <div className="mb-3">
            <img
              src={`${urlImg}/thanh_cong_cu_10_2.png`}
              alt="thanh_cong_cu_img"
            />
          </div>
          <p>
            Sau khi hoàn tất việc sử dụng thanh công cụ, để quay trở lại mở sách
            điện tử, thầy cô chỉ cần ấn vào biểu tượng con trỏ chuột ở phía trên
            cùng để tiếp tục sử dụng sách
          </p>
        </li>
        <li class="flex-lc">
          <b id="xem-huong-dan">5.</b>&nbsp; Xem hướng dẫn
          <p className="pl-3">
            Thầy cô có thể xem ngay video hướng dẫn sử dụng các tính năng khi
            đang sử dụng sách bằng cách ấn vào biểu tượng “Dấu chấm hỏi” phía
            trên góc tay phải để có thể xem hướng dẫn một cách trực quan và dễ
            dàng.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/huong_dan_su_dung_10_2.png`}
              alt="huong_dan_su_dung_img"
            />
          </div>
        </li>
        <li>
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li>
          Chúc thầy cô thao tác thành công và sử dụng sách một cách hiệu quả!
        </li>
      </ul>
    </>
  );
}
