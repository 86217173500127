import useScrollToTop from "edu_lms_v2/services/useScrollToTop";

export default function StudentDoingExercise4() {
  useScrollToTop();

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Trang chủ</a>
            </li>
            <li className="breadcrumb-item">
              <a href="">Luyện tập</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Toán Lớp 6 - Sách gíao khoa
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-title bg-gr">
        <div className="container pr">
          <div className="subject flex-lc">
            <div className="subject__icon flex-center">
              <img src="./assets/img/icon-toan1.svg" alt="" />
            </div>
            <div className="subject__info">
              <h5 className="txt-2">Luyện tập</h5>
              <h1 className="title">
                Toán lớp 6 - <span>SGK</span>
              </h1>
              <p>Ôn tập kiến thức cùng kho bài tập phong phú</p>
              <a href="" title="Thay đổi" className="btn-sub flex-center">
                Thay đổi
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-practice-list">
        <div className="container">
          <h2 className="title">Chương I: Số tự nhiên</h2>
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="table-of-content bg-sh">
                <div className="header flex-lc">
                  <h3 className="sm">Chương I</h3>
                  <span>1/5 bài</span>
                </div>
                <ul className="list">
                  <li className="finish">
                    <div className="title">
                      <p>
                        <span>Bài 1:</span> Số tự nhiên là gì
                      </p>
                    </div>
                    <div className="percent">100%</div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "100%" }}
                      ></div>
                    </div>
                  </li>
                  <li className="doing">
                    <div className="title">
                      <p>
                        <span>Bài 2:</span> Luyện tập với số tự nh...
                      </p>
                    </div>
                    <div className="percent">50%</div>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        style={{ width: "50%" }}
                      ></div>
                    </div>
                  </li>
                  <li>
                    <div className="title">
                      <p>
                        <span>Bài 3:</span> Số tự nhiên mở rộng
                      </p>
                    </div>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                  <li>
                    <div className="title">
                      <p>
                        <span>Bài 4:</span> Số tự nhiên là gì
                      </p>
                    </div>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                  <li>
                    <div className="title">
                      <p>
                        <span>Bài 5:</span> Số tự nhiên mở rộng 2
                      </p>
                    </div>
                    <div className="percent">0%</div>
                    <div className="progress">
                      <div className="progress-bar"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8 col-md-7">
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>Bài 1: Số tự nhiên là gì</h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-result btn-pr flex-center active"
                  >
                    <i className="icon icon-chart"></i> Kết quả
                  </a>
                </div>
                <div className="Knowledge">
                  <h4 className="txt-5">Kiến thức</h4>
                  <a
                    href="javascript:;"
                    className="video flex-lc"
                    data-link=""
                    data-bs-toggle="modal"
                    data-bs-target="#modal-video"
                  >
                    <i className="icon icon-video"></i>{" "}
                    <span>Video bài giảng</span>
                  </a>
                  <a href="" className="summary flex-lc">
                    <i className="icon icon-doc"></i>{" "}
                    <span>Tóm tắt kiến thức</span>
                  </a>
                </div>
                <div className="excrise">
                  <h4>Luyện tập</h4>
                  <ul className="excrise__list flex">
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 1</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 2</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 3</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 4</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>Bài 2: Số tự nhiên là gì</h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-result btn-pr flex-center active"
                  >
                    <i className="icon icon-chart"></i> Kết quả
                  </a>
                </div>
                <div className="Knowledge">
                  <h4 className="txt-5">Kiến thức</h4>
                  <a
                    href="javascript:;"
                    className="video flex-lc"
                    data-link=""
                    data-bs-toggle="modal"
                    data-bs-target="#modal-video"
                  >
                    <i className="icon icon-video"></i>{" "}
                    <span>Video bài giảng</span>
                  </a>
                  <a href="" className="summary flex-lc">
                    <i className="icon icon-doc"></i>{" "}
                    <span>Tóm tắt kiến thức</span>
                  </a>
                </div>
                <div className="excrise">
                  <h4>Luyện tập</h4>
                  <ul className="excrise__list flex">
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 1</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 2</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 3</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="" className="finish">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 4</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>Bài 3: Số tự nhiên là gì</h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-result btn-pr flex-center"
                  >
                    <i className="icon icon-chart"></i> Kết quả
                  </a>
                </div>
                <div className="Knowledge">
                  <h4 className="txt-5">Kiến thức</h4>
                  <a
                    href="javascript:;"
                    className="video flex-lc"
                    data-link=""
                    data-bs-toggle="modal"
                    data-bs-target="#modal-video"
                  >
                    <i className="icon icon-video"></i>{" "}
                    <span>Video bài giảng</span>
                  </a>
                  <a href="" className="summary flex-lc">
                    <i className="icon icon-doc"></i>{" "}
                    <span>Tóm tắt kiến thức</span>
                  </a>
                </div>
                <div className="excrise">
                  <h4>Luyện tập</h4>
                  <ul className="excrise__list flex">
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 1</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 2</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 3</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 4</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>Bài 4: Số tự nhiên là gì</h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-result btn-pr flex-center"
                  >
                    <i className="icon icon-chart"></i> Kết quả
                  </a>
                </div>
                <div className="Knowledge">
                  <h4 className="txt-5">Kiến thức</h4>
                  <a
                    href="javascript:;"
                    className="video flex-lc"
                    data-link=""
                    data-bs-toggle="modal"
                    data-bs-target="#modal-video"
                  >
                    <i className="icon icon-video"></i>{" "}
                    <span>Video bài giảng</span>
                  </a>
                  <a href="" className="summary flex-lc">
                    <i className="icon icon-doc"></i>{" "}
                    <span>Tóm tắt kiến thức</span>
                  </a>
                </div>
                <div className="excrise">
                  <h4>Luyện tập</h4>
                  <ul className="excrise__list flex">
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 1</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 2</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 3</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 4</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>Bài 5: Số tự nhiên là gì</h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-result btn-pr flex-center"
                  >
                    <i className="icon icon-chart"></i> Kết quả
                  </a>
                </div>
                <div className="Knowledge">
                  <h4 className="txt-5">Kiến thức</h4>
                  <a
                    href="javascript:;"
                    className="video flex-lc"
                    data-link=""
                    data-bs-toggle="modal"
                    data-bs-target="#modal-video"
                  >
                    <i className="icon icon-video"></i>{" "}
                    <span>Video bài giảng</span>
                  </a>
                  <a href="" className="summary flex-lc">
                    <i className="icon icon-doc"></i>{" "}
                    <span>Tóm tắt kiến thức</span>
                  </a>
                </div>
                <div className="excrise">
                  <h4>Luyện tập</h4>
                  <ul className="excrise__list flex">
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 1</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 2</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 3</span>
                      </a>
                    </li>
                    <li>
                      <a href="" title="">
                        <i className="icon icon-pen"></i>
                        <span>Luyện tập 4</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lesson flex bg-sh">
                <div className="header flex">
                  <h3>
                    <i className="icon icon-star"></i> Kiểm tra chương I
                  </h3>
                  <a
                    href=""
                    title="Kết quả"
                    className="btn-play btn-pr flex-center"
                  >
                    <i className="icon icon-file"></i> Làm bài
                  </a>
                </div>
                <div className="statistic flex">
                  <div className="flex-lc txt-5">
                    <i className="icon icon-question"></i> 50 câu hỏi
                  </div>
                  <div className="flex-lc txt-5">
                    <i className="icon icon-hourglass"></i> 45 phút
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
