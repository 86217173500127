import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import * as PATH from "consts/routePaths";
import { FB_GROUP_HOC10 } from "consts/config";

export default function RegisterSuccessModal({ show }) {
  const history = useHistory();

  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const { isOpenTraining } = JSON.parse(trainingConfig);

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src="/assets/img/img-success.svg" alt="Đăng ký thành công" />
        </div>
        <div className="text">Đăng ký thành công</div>
        <Description>
          Tham gia ngay cộng đồng Hoc10 - Đồng hành cùng giáo viên tiểu học để
          kết nối và nhận thêm nhiều tài liệu giảng dạy!
        </Description>
        <ActionWrapper>
          <SecondaryActionButton
            className="btn btn-outline-primary flex-center txt-4"
            onClick={() => {
              window.open(FB_GROUP_HOC10, "_blank");
            }}
          >
            <Icon
              className="fa fa-facebook-square"
              aria-hidden="true"
              size="lg"
            />
            <div className="button-label">Group</div>
          </SecondaryActionButton>
          <PrimaryActionButton
            title="Tủ sách 22"
            className="btn-pr flex-center"
            onClick={() => {
              history.push(
                isOpenTraining
                  ? PATH.ROUTE_PATH_V3_TRAINING
                  : PATH.ROUTE_PATH_TU_SACH
              );
            }}
          >
            {isOpenTraining ? "Tập huấn" : "Tủ sách"}
          </PrimaryActionButton>
        </ActionWrapper>
      </div>
    </Modal>
  );
}

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 20px;
`;

const PrimaryActionButton = styled.div`
  height: 48px;
  margin: 0 auto;
  border-radius: 6px;
  flex: 1;
  cursor: pointer;
`;

const SecondaryActionButton = styled.div`
  height: 48px;
  margin: 0 auto;
  border-radius: 6px;
  border-color: #ff7707;
  flex: 1;

  &:hover {
    background-color: #ff7707;
    border-color: #ff7707;

    .button-label {
      color: #fff;
    }

    & > i {
      color: #fff;
    }
  }

  .button-label {
    color: #ff7707;
    font-weight: bold;
  }
`;

const Icon = styled.i`
  font-size: 36px;
  color: #ff7707;
  margin-right: 5px;
`;

const Description = styled.div`
  padding: 0 20px;
  margin-bottom: 40px;
  text-align: center;
`;
