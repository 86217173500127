export default function ExamLibrary({ title, data }) {
  return (
    <div className="hoc10-library-test">
      <div className="container">
        <div className="header">
          <h2 className="title">{title}</h2>
        </div>
        <div className="list-test row">
          {data?.map((item) => (
            <div className="col-lg-6">
              <div className="item bg-sh pr">
                <h3>{item.title}</h3>
                <a href="" className="flex-lc">
                  <i className="icon icon-doc"></i> {item.totalExam} Đề thi thử
                </a>
                <a href="" title="" className="btn-viewmore">
                  Xem thêm
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
