import React from "react";

const ErrorView = () => {
  return (
    <div className="w-100">
      <p className="text-center monkey-fz-24 font-weight-bold mt-5">
        Đang cập nhật...
      </p>
    </div>
  );
};

export default ErrorView;
