import React from "react";
import { URL_IMAGE_QUESTION } from "edu_lms/constants/type";

const Image = ({ isFullSrc, src, ...props }) => {
  if (!src) return null;

  return (
    <img
      src={isFullSrc ? src : `${URL_IMAGE_QUESTION}${src}`}
      alt="game img component"
      {...props}
    />
  );
};

export default Image;
