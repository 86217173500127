import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { onDispatchSrcAudio } from "edu_lms/modules/App/actions";
import { AUDIO_ERROR, AUDIO_SUCCESS } from "edu_lms/constants/type";
import { styleWrapper } from "../../constants/styles";
import HeaderQuestion from "../MTC_003/HeaderQuestion";
import MultiChoiceContent from "../MTC_003/MultiChoiceContent";
import { checkResultAnswer } from '../../helpers/CKB_001';

const CKB_001 = (
  {
    gameData,
    selectedAnswersProp = [],
    hideResultAnswer = false,
    showCorrectAnswer = false,
    isReadOnly = false,
    theme,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;
  const dispatch = useDispatch();

  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  const handleChooseAnswer = (answerId) => {
    let shadowSelectedAnswers = [...selectedAnswers];

    if (isCheckedAnswer) {
      setIsCheckedAnswer(false);
    }
  
    const selectedAnswerIndex = shadowSelectedAnswers.findIndex(
      (ansId) => ansId === answerId
    );
    if (selectedAnswerIndex > -1) {
      shadowSelectedAnswers.splice(selectedAnswerIndex, 1);
    } else {
      shadowSelectedAnswers.push(answerId);
    }

    setSelectedAnswers(shadowSelectedAnswers);
    onPlaying(false);
  };

  /**--------------- Show selected answers result ---------------**/
  useEffect(() => {
    if (selectedAnswersProp.length > 0) {
      setSelectedAnswers(selectedAnswersProp);
      setIsCheckedAnswer(true);
    }
  }, [selectedAnswersProp]);

  /*---------- Show all correct answer result ----------*/
  useEffect(() => {
    if (showCorrectAnswer) {
      const correctAnswers = answer.answers.filter(
        (answerItem) => answerItem.isCorrect
      );
      setSelectedAnswers(
        correctAnswers.map((answerItem) => answerItem.answerId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleResetAnswer = () => {
    setSelectedAnswers([]);
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
  };

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const isCorrect = checkResultAnswer(answer.answers, selectedAnswers);
    !hideResultAnswer && dispatch(onDispatchSrcAudio(isCorrect ? AUDIO_SUCCESS : AUDIO_ERROR));

    onComplete({ selectedAnswers, isCorrect });
  };

  return (
    <div className="multiple-choice-checkbox p-3" style={styleWrapper}>
      <HeaderQuestion question={question} theme={theme} />
      <WrapMultiChoiceContent>
        <MultiChoiceContent
          answer={answer}
          isCheckedAnswer={isCheckedAnswer}
          hideResultAnswer={hideResultAnswer}
          showCorrectAnswer={showCorrectAnswer}
          isReadOnly={isReadOnly || isViewOnly}
          theme={theme}
          selectedAnswers={selectedAnswers}
          onClickAnswer={handleChooseAnswer}
        />
      </WrapMultiChoiceContent>
    </div>
  );
};

export default forwardRef(CKB_001);

const WrapMultiChoiceContent = styled.div`
  margin: 0 auto;
`;
