import ExamLibrary from "./ExamLibrary";
import Header from "./Header";
import ListSubjects from "./ListSubjects";
import RecentExamQuestions from "./RecentExamQuestions";

const dataRecent = [
  {
    id: 1,
    urlImage:
      "https://vysqy4zclvobj.vcdn.cloud/E_Learning/thumb/qua_le_1644921270.jpg",
    subjectName: "Tiếng Việt",
    gradeName: "Lớp 5",
    typeExam: "Kiểm tra 15 phút",
    countCorrect: 10,
    totalQuestion: 15,
  },
  {
    id: 2,
    urlImage:
      "https://vysqy4zclvobj.vcdn.cloud/E_Learning/thumb/qua_le_1644921270.jpg",
    subjectName: "Tiếng Việt",
    gradeName: "Lớp 5",
    typeExam: "Kiểm tra 15 phút",
    countCorrect: 10,
    totalQuestion: 15,
  },
  {
    id: 3,
    urlImage:
      "https://vysqy4zclvobj.vcdn.cloud/E_Learning/thumb/qua_le_1644921270.jpg",
    subjectName: "Tiếng Việt",
    gradeName: "Lớp 5",
    typeExam: "Kiểm tra 15 phút",
    countCorrect: 10,
    totalQuestion: 15,
  },
];

const dataExamLibrary = [
  {
    id: 1,
    title: "Đề kiểm tra 15 phút",
    totalExam: 10,
  },
  {
    id: 2,
    title: "Đề kiểm tra 1 tiết",
    totalExam: 10,
  },
  {
    id: 3,
    title: "Đề kiểm tra giữa kỳ",
    totalExam: 10,
  },
  {
    id: 4,
    title: "Đề kiểm tra cuối kỳ",
    totalExam: 10,
  },
];

export default function () {
  return (
    <main>
      <Header parentPage="Học sinh" currentPage="Thi thử" />
      <RecentExamQuestions title="Đề kiểm tra gần đây" data={dataRecent} />
      <ListSubjects title="Môn học" />
      <ExamLibrary title="Thư viện đề kiểm tra" data={dataExamLibrary} />
    </main>
  );
}
