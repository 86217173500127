import useScrollToTop from "edu_lms_v2/services/useScrollToTop";

export default function StudentDoingExercise6() {
  useScrollToTop();

  return (
    <div className="hoc10-test">
      <div className="header">
        <div className="container flex-lc">
          <a href="" title="" className="btn-back flex-lc">
            <i className="icon icon-back"></i>
            <span>Lớp 6</span>
            <span>Môn toán</span>
          </a>
          <div className="text">
            <h1 className="title">Luyện tập 1</h1>
          </div>
          <div className="btn flex-lc">
            <button type="button" className="btn-light"></button>
            <button type="button" className="btn-fullscreen"></button>
          </div>
        </div>
      </div>
      <div className="main flex pr">
        <div className="container flex pr">
          <div className="box-act">
            <button type="button" className="active flex-center">
              <i className="icon icon-act1"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act2"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act3"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act4"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act5"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act6"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act7"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act8"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act9"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act10"></i>
            </button>
            <button type="button" className="flex-center">
              <i className="icon icon-act11"></i>
            </button>
          </div>
          <div className="box-question flex">
            <div className="question">
              <div className="question__content">
                <h4>
                  Câu hỏi 12<span>/15</span>
                </h4>
                <p>
                  Anh Tú là giám đốc công ty tên là Công ty Ngày Mai. Anh Tú 1
                  năm trước lương là 6 triệu đồng, công ty anh cứ 6 tháng lại
                  tăng thêm cho anh Tú 2 triệu tiền lương. Hỏi hiện tại sau một
                  năm, mức lương của anh Tú là bao nhiêu?
                </p>
              </div>
              <div className="question__img">
                <img src="./assets/img/img-question.jpg" alt="" />
              </div>
            </div>
            <div className="answer">
              <div className="answer__list">
                <label className="flex-lc bg-sh pr">
                  <input type="radio" name="answer" />
                  <span className="checked"></span>
                  Vẫn 6 triệu
                </label>
                <label className="flex-lc bg-sh pr">
                  <input type="radio" name="answer" />
                  <span className="checked"></span>8 triệu
                </label>
                <label className="flex-lc bg-sh pr">
                  <input type="radio" name="answer" />
                  <span className="checked"></span>
                  10 triệu
                </label>
                <label className="flex-lc bg-sh pr">
                  <input type="radio" name="answer" />
                  <span className="checked"></span>
                  Giám đốc thì làm gì có lương
                </label>
              </div>
            </div>
            <div className="img-wrapper">
              <img src="./assets/img/img-question.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container flex-lc pr">
          <button type="button" className="btn-draft flex-lc">
            <i className="icon icon-pen"></i> Nháp
          </button>
          <ul className="pagination">
            <li className="page-item active">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                5
              </a>
            </li>
          </ul>
          <div className="box-check pr">
            <button type="button" className="btn-test btn-pr flex-center">
              Kiểm tra
            </button>
            <div className="check pr">
              <div className="check__header flex-lc">
                <div className="icon">
                  <img src="./assets/img/icon-sad.svg" alt="" />
                </div>
                Chưa chính xác
              </div>
              <div className="check__content">
                <button type="button">Thử lại, </button>{" "}
                <button type="button">Xem gợi ý</button> hoặc{" "}
                <button type="button">Bỏ qua</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-fix-right">
        <a href="" title="" className="chat flex-center">
          <i className="icon icon-chat"></i>
        </a>
        <a href="" title="" className="messenger flex-center">
          <i className="icon icon-messenger"></i>
        </a>
      </div>
    </div>
  );
}
