export const authors = [
  {
    id: 1,
    author_name: "GS.TS Nguyễn Minh Thuyết",
    stt: "001",
    author_description:
      "Giáo sư, Tiến sĩ Ngữ văn (Đại học Quốc gia Sainkt - Peteburg, Liên bang Nga), Tổng Chủ biên Chương trình Giáo dục phổ thông 2018;  Chủ biên sách giáo khoa Tiếng Việt (Chương trình 2006) (từ lớp 2 đến lớp 5); Chủ biên sách giáo khoa Tiếng Việt 1 (2020);  Chủ biên bộ sách Tiếng Việt vui dành cho người Việt Nam ở nước ngoài;  tác giả nhiều sách nghiên cứu về ngôn ngữ và phương pháp dạy học tiếng Việt;  nguyên giảng viên cao cấp Đại học Quốc gia Hà Nội;  giảng viên thỉnh giảng Đại học Laval (Canada) và Đại học Paris 7 (Pháp); Huân chương Lao động hạng Nhất, Huân chương Cành Cọ Hàn lâm của Pháp.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/0001.png`,
  },
  {
    id: 2,
    author_name: "Hoàng Hoà Bình",
    stt: "002",
    author_description:
      "Phó Giáo sư, Tiến sĩ Giáo dục học (Đại học Sư phạm Lenin, Moskva, Liên bang Nga);  nguyên Trưởng phòng Ngữ văn, Viện Khoa học Giáo dục Việt Nam; tác giả SGK Tiếng Việt tiểu học (Chương trình 2006), Tiếng Việt 1 (2020) và nhiều sách nghiên cứu, tham khảo về dạy học môn Tiếng Việt, môn Ngữ văn.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/002.png`,
  },
  {
    id: 3,
    author_name: "Lê Hữu Tỉnh",
    stt: "003",
    author_description:
      "Tiến sĩ Ngữ văn (Trường Đại học Sư phạm Hà Nội),nguyên giảng viên Khoa Ngữ văn, giảng viên Khoa Giáo dục Tiểu học, Trường Đại học Sư phạm Hà Nội; giảng viên Khoa Ngữ văn, Trường Đại học Sư phạm Hà Nội 2; nguyên Phó Tổng biên tập Nhà xuất bản Giáo dục Việt Nam; tác giả sách giáo khoa Tiếng Việt cấp I phổ cập (1991), Tiếng Việt tiểu học (Chương trình 2006), Tiếng Việt 1(2002) và nhiều sách nghiên cứu, tham khảo về dạy học môn Tiếng Việt.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/003.png`,
  },
  {
    id: 4,
    author_name: "Trần Mạnh Hưởng",
    stt: "004",
    author_description:
      "Thạc sĩ Ngữ văn (Trường Đại học Sư phạm Hà Nội), nguyên chuyên viên cao cấp Vụ Giáo dục Tiểu học, Bộ Giáo dục và Đào tạo; tác giả sách giáo khoa Tiếng Việt 1 Cải cách giáo dục (1989), Tiếng Việt cấp I phổ cập (1991), Tiếng Việt tiểu học (Chương trình 2006), Tiếng Việt 1 (2020) và nhiều sách tham khảo về dạy học môn Tiếng Việt.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/004.png`,
  },
  {
    id: 5,
    author_name: "Đặng Kim Nga",
    stt: "005",
    author_description:
      "Tiến sĩ Giáo dục học (Trường Đại học Sư phạm Hà Nộ); nguyên Trưởng Khoa Giáo dục Tiểu học, Trường Đại học Sư phạm Hà Nội; tác giả sách giáo khoa Tiếng Việt 1 (2020) và một số sách giáo khoa, sách tham khảo về dạy học môn Tiếng Việt.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/005.png`,
  },
  {
    id: 6,
    author_name: "Hoàng Thị Minh Hương",
    stt: "006",
    author_description:
      "Thạc sĩ Giáo dục học, Phó trưởng Phòng Giáo dục phổ thông, Sở Giáo dục và Đào tạo thành phố Hà Nội, tác giả sách giáo khoa Tiếng Việt 1 (2020) và một số sách tham khảo về dạy học môn Tiếng Việt.",
    author_image: null,
  },
  {
    id: 7,
    author_name: "Nguyễn Thị Ly Kha",
    stt: "007",
    author_description:
      "Phó Giáo sư, Tiến sĩ Ngữ văn, nguyên Trưởng Khoa Giáo dục Tiểu học, Trường ĐHSP Thành phố Hồ Chí Minh; tác giả sách giáo khoa Tiếng Việt tiểu học (Chương trình 2006) và nhiều sách nghiên cứu, tham khảo về dạy học môn Tiếng Việt.",
    author_image: null,
  },
  {
    id: 8,
    author_name: "GS.TSKH Đỗ Đức Thái",
    stt: "008",
    author_description:
      "Giáo sư, Tiến sĩ Khoa học, giảng viên cao cấp, Trưởng khoa Toán -Tin Trường Đại học Sư phạm Hà Nội; Chủ biên Chương trinh giáo dục phổ thông môn Toán 2018, Phó Chủ tich Hội Toán học Việt Nam; Chủ biên Chương trình đào tạo ngành Sư phạm Toán học của Trường Đại học Sư phạm Hà Nội; giàng viên thỉnh giång của một số đại học tại Pháp, Đức, Nhật, Mỹ: tác giả nhiều tài liệu nghiên cứu, tham khảo về dạy học môn Toán và bồi dưỡng học sinh giỏi Toán.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/008.png`,
  },
  {
    id: 9,
    author_name: "PGS.TS Đỗ Tiến Đạt",
    stt: "009",
    author_description:
      "Phó Giáo sư, Tiến sĩ chuyên ngành Lí luận và Phương pháp dạy học bộ môn Toán (Viện Hàn lâm Sư phạm Nga), Nghiên cứu viên chính Viện Khoa học Giáo dục Việt Nam; tác giả Chương trình giáo dục phổ thông môn Toán 2018; tác giả Chương trình và sách giáo khoa môn Toán tiéu học (Chương trình 2006); tác giả nhiều tài liệu nghiên cứu, tham khảo về dạy học môn Toán.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/009.png`,
  },
  {
    id: 10,
    author_name: "Trần Thuý Ngà",
    stt: "010",
    author_description:
      "Tiến sĩ chuyên ngành Lí luận và Phương pháp dạy học môn Toán, các bộ nghiên cứu Viện Khoa học Giáo dục Việt Nam; tác giả nhiều sách nghiên cứu, sách tham khảo về dạy học môn Toán ở tiểu học.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/010.png`,
  },
  {
    id: 11,
    author_name: "Nguyễn Thị Thanh Sơn",
    stt: "011",
    author_description:
      "Cử nhân Giáo dục tiểu học, Tổ trưởng Tổ Tiểu học phòng GD&ĐT quận Hoàn Kiếm, Hà Nội; tác giả nhiều sách tham khảo môn Toán tiểu học; thành viên góp ý Chương trình giáo dục phổ thông môn Toán 2018 và Thông tư đánh giá học sinh tiểu học",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/011.png`,
  },
  {
    id: 12,
    author_name: "Nguyễn Hoài Anh",
    stt: "012",
    author_description:
      "Tiến sĩ Lí luận và Phương pháp dạy học bộ môn Toán, Trưởng khoa Giáo dục Tiểu học, Trường Đại học Sư phạm, Đại học Huế; tác giả Chương trình giáo dục phổ\nthông môn Toán 2018 và nhiều sách tham khảo về dạy học môn Toán ở tiểu học.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/012.png`,
  },
  {
    id: 13,
    author_name: "M.A Nguyễn Quốc Hùng",
    stt: "013",
    author_description:
      "Chủ biên Nguyễn Quốc Hùng, M.A, nguyên Phó Hiệu trưởng Trường Đại học Ngoại ngữ Hà\nNội. Ông là một trong những chuyên gia giảng dạy tiếng Anh có kinh nghiệm ở Việt Nam với\n34 năm công tác và giảng dạy tại Trường Đại học Ngoại ngữ Hà Nội, 25 năm xây dựng các chương\ntrình Tiếng Anh trên truyền hình VTV2 (từ năm 1986 đến 2011) và 18 năm trên sóng Đài Tiếng\nnói Việt Nam VOV (từ năm 1994 đến 2012). Đến nay, ông đã biên soạn 80 cuốn sách dạy tiếng\nAnh trên VTV, VOV và sách nghiên cứu phương pháp giảng dạy.",
    author_image: null,
  },
  {
    id: 14,
    author_name: "M.A Đỗ Thị Kim Thanh",
    stt: "014",
    author_description:
      "Thạc sĩ Đỗ Thị Kim Thanh, Chuyên viên Đào tạo và Hợp tác Viện Đổi mới sáng tạo - Đại học Kinh tế Thành phồ Hồ Chí Minh,\nđồng tác giả của rất nhiều sách tham khảo về dạy và học môn tiếng Anh trong trường phổ thông từ bậc Tiểu học đến THCS.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/014.png`,
  },
  {
    id: 15,
    author_name: "Dr. Joann (Jodi) Crandall",
    stt: "015",
    author_description:
      "Tiến sĩ Joann (Jodi) Crandall là Giáo sư Danh dự và cựu giám đốc Chương trình đào tạo Tiến sĩ về Ngôn ngữ, Kiến thức và Văn hóa, đồng Giám đốc Chương trình TESOL tại Đại học Maryland, thành phố Baltimore, đã làm việc trong nhiều lĩnh vực giảng dạy tiếng Anh như một ngôn ngữ thứ hai/ ngoại ngữ (ESL/ EFL), bao gồm giảng dạy, thiết kế chương trình, biên soạn tài liệu, xây dựng tiêu chuẩn và đào tạo giáo viên.\n",
    author_image: null,
  },
  {
    id: 16,
    author_name: "Dr. Joan Kang Shin",
    stt: "016",
    author_description:
      "Tiến sĩ Joan Kang Shin đang công tác tại trường đại học George Mason, bang Virginia, Hoa Kỳ, chuyên về giảng dạy tiếng Anh cho trẻ nhỏ và thanh thiếu niên, đã thực hiện rất nhiều khóa tập huấn phát triển chuyên môn và nghiệp vụ giảng dạy cho giáo viên tiếng Anh tại hơn 100 quốc gia trên toàn thế giới, trong đó có Việt Nam.\n",
    author_image: null,
  },
  {
    id: 17,
    author_name: "Lưu Thu Thuỷ",
    stt: "017",
    author_description:
      "Tiến sĩ Giáo dục học, nguyên Giám đốc Trung tâm Nghiên cứu Giáo dục đạo  đức - công dân, Viện Khoa học Giáo dục Việt Nam; Chủ biên sách giáo khoa Đạo đúc tiều học; tác giả sách giáo khoa Giáo dục công dân THCS (Chương trình 2006); tác giả của nhiều giáo trình, sách nghiên cứu, tham khảo về dạy học môn Đạo đức và môn Giáo dục công dân.\n",
    author_image: null,
  },
  {
    id: 18,
    author_name: "Nguyễn Thị Việt Hà",
    stt: "018",
    author_description:
      "Thạc sĩ Giáo dục học (Viện Khoa học Giáo dục Việt Nam), Nghiên cứu viên chính Viện Khoa học Giáo dục Việt Nam; nguyên Trưởng phòng nghiên cứu Giáo dục đạo đức, GDCD và HĐGD - Trung tâm NCGD Phổ thông (nay là Trung tâm Phát triển bền vững chất lượng giáo dục phổ thông quốc gia) - Viện Khoa học Giáo dục Việt Nam; tác giả sách giáo khoa Đạo đức (Chương trình 2006) và nhiều sách tham khảo cho học sinh, giáo viên về giáo dục đạo đức và giáo dục kĩ năng sống, dạy học theo định hướng phát triển năng lực học sinh,...; tác giả sách giáo khoa Đạo đức lớp 1(Chương trình 2018) bộ sách Cánh Diều.\n",
    author_image: null,
  },
  {
    id: 19,
    author_name: "Ngô Vũ Thu Hằng",
    stt: "019",
    author_description:
      "Tiến sĩ Giáo dục học (Trường Đại học Utrecht, Hà Lan); Giảng viên Khoa Giáo dục Tiểu học, Trường Đại học Sư phạm Hà Nội; Hiệu trưởng Trường Tiểu học Thực hành Nguyễn Tắt Thành, Trường Đại học Sư phạm Hà Nội; tác giả sách giáo khoa Đạo đức lớp 1(Chương trình 2018), bộ sách Cánh Diều; tác giả nhiều giáo trình, sách giáo khoa, sách tham khảo dành cho giáo viên, sinh viên và học sinh tiểu học: Giáo trình rèn luyện nghiệp vụ sư phạm tiểu học, Hướng dẫn dạy học môn Đạo đức theo Chương trình Giáo dục phồ thông 2018, Kĩ năng quản lílớp ở tiểu học,...",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/019.png`,
  },
  {
    id: 20,
    author_name: "Nguyễn Thị Vân Hương",
    stt: "020",
    author_description:
      "Tiến sĩ Giáo dục học, nguyên Phó Trưởng khoa Giáo dục Tiểu học, Trường Đại học Sư Phạm Hà Nội; tác giả của nhiều bộ sách tham khảo về giáo dục và giáo dục đạo đức.",
    author_image: null,
  },
  {
    id: 21,
    author_name: "Trần Thị Tố Oanh",
    stt: "021",
    author_description:
      "Tiến sĩ Tâm lí học giáo dục, nguyên Trưởng phòng Trung tâm Công nghệ giáo dục; giảng viên thỉnh giảng Đại học sư phạm Hà Nội 2; tác giả sách Giáo dục lối sống, sách Đạo Đức tiểu học (Chương trình 2006), sách tham khảo giáo dục kĩ năng sống.",
    author_image: null,
  },
  {
    id: 22,
    author_name: "Mai Sỹ Tuấn",
    stt: "022",
    author_description:
      "Phó Giáo sư, Tiến sĩ, Nhà giáo Ưu tú; nguyên Trưởng Khoa Sinh học, Trường Đại học Sư phạm Hà Nội, Chủ biên và tác giả của hơn 20 đầu sách về khoa học tự nhiên và khoa học giáo dục; tác giả sách giáo khoa Sinh học 9 và Sinh học 12 (Chương trình 2006); Chủ biên Chương trình môn Khoa học tự nhiên 2018, tác giả Chương trình môn Sinh học 2018 và tác giả Chương trình môn Sinh học 2018; Trưởng nhóm xây dựng chương trình THPT Chuyên môn Sinh học (năm 2019). Nhiều năm là Trưởng đoàn Việt Nam tham dự Olympic Sinh học Quốc tế (2005 - 2018); Trưởng ban Chuyên môn của Olympic Sinh học quốc tế năm 2016 tổ chức tại Việt Nam.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/022.png`,
  },
  {
    id: 23,
    author_name: "Bùi Phương Nga",
    stt: "023",
    author_description:
      "Tiến sĩ Phương pháp dạy học(Đại học Sư phạm Halle, CHDC Đức), nguyên Chuyên viên chính Viện Khoa học Giáo dục Việt Nam; Chủ biên Chương trình Giáo dục phổ thông môn Tự nhiên và Xã hội, môn Khoa học 2006 và 2018; tác giả Chương trình giáo dục phổ thông môn Sinh học 2018; Chủ biên và tác giả sách giáo khoa môn Tự nhiên và Xã hội, môn Khoa học (Chương trình 2006); Chủ biên và đồng tác giả của nhiều sách tham khảo về dạy và học môn Tự nhiên và Xã hội, sách chuyên khảo về kĩ năng sống, giáo dục giới tính (cho học sinh tiểu học), giáo dục sức khoẻ sinh sản và phòng tránh HIV/AIDS cho học sinh THCS)...",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/023.png`,
  },
  {
    id: 24,
    author_name: "Nguyễn Tuyết Nga",
    stt: "024",
    author_description:
      "Phó Giáo sư, Tiến sĩ Giáo dục học, nguyên Phó Giám đốc Trung tâm Nghiên cứu Giáo dục phổ thông, Viện Khoa học Giáo dục Việt Nam; tác giả sách giáo khoa môn Tự nhiên và Xã hội (Chương trình 2006); Chủ biên, tác giả nhiều giáo trình, sách tham khảo về dạy học môn Tự nhiên và Xã hội.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/024.png`,
  },
  {
    id: 25,
    author_name: "Lương Việt Thái",
    stt: "025",
    author_description:
      "Tiến sĩ Giáo dục học, Phó Trưởng phòng Quản lí Khoa học, Đào tạo và Hợp tác quốc tế, Viện Khoa học Giáo dục Việt Nam; tác giả Chương trình giáo dục phổ thông môn Tự nhiên và Xã hội, môn Khoa học 2006 và 2018; tác giả sách giáo khoa môn Khoa học (Chương trình 2006); Chủ biên và tác gia của nhiều sách tham khảo về dạy và học môn Tự nhiên và Xã hội, môn Khoa học.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/025.png`,
  },
  {
    id: 26,
    author_name: "Nguyễn Thị Thu Trang",
    stt: "026",
    author_description:
      "Thạc sĩ Ngữ văn, giáo viên giảng dạy, Chủ nhiệm Dự án Đọc của Trường phổ thông Song ngữ Liên cấp Wellspring - Hà Nội, giáo viên dạy giỏi cấp quận các năm 2013, 2014, 2015.",
    author_image: null,
  },
  {
    id: 27,
    author_name: "Đặng Ngọc Quang",
    stt: "027",
    author_description:
      "Tiến sĩ Giáo dục học, nguyên Trưởng khoa Khoa học Thể thao và sức khoẻ Trường Đại học Thủ đô Hà Nội; Chủ biên Chương trình môn học Giáo dục thể chắt 2018. Chủ biên sách hướng dẫn dạy học môn Giáo dục thể chất chương trình môn Giáo dục thể chất Tiểu học; Trung học cơ sở; Trung học phổ thông theo Chương trình Giáo dục phổ thông 2018 và nhiều sách tham khảo, bồi dưỡng giáo viên thực hiện chương trình môn Giáo dục thể chất. Bằng khen Bộ trưởng Bộ Giáo dục và Đào tạo năm 2007 và năm 2019.",
    author_image: null,
  },
  {
    id: 28,
    author_name: "Nguyễn Công Trường",
    stt: "028",
    author_description:
      "Thạc sĩ Giáo dục học, giảng viên khoa Khoa học Thể thao và sức khoẻ Trường Đại học Thủ đô Hà Nội; tác giả sách hướng dẫn dạy học môn Giáo dục thể chất Tiểu học; Trung học cơ sở; Trung học phổ thông theo Chương trình Giáo dục phồ thông 2018. Là tác giả nhiều bài báo và đề tài nghiên cứu khoa học liên quan đến lĩnh vực giáo dục nói chung và công tác Giáo dục thể chất nói riêng.",
    author_image: null,
  },
  {
    id: 29,
    author_name: "Lê Anh Tuấn",
    stt: "029",
    author_description:
      "Thạc sĩ Âm nhạc, hội viên Hội nhạc sĩ Việt Nam, Nghiên cứu viên về giáo dục âm nhạc của Viện Khoa học Giáo dục Việt Nam; tác giả Chương trình môn Âm nhạc 2006, tác giả sách giáo khoa Âm nhạc lớp 4, lớp 5 (Chương trình 2006), Chủ biên Chương trình giáo dục phổ thông môn Âm nhạc 2018; tác giả nhiều sách, báo về đề tài nghiên cứu về giáo dục âm nhạc ở trường phổ thông.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/029.png`,
  },
  {
    id: 30,
    author_name: "Đỗ Thanh Hiên",
    stt: "030",
    author_description:
      "Thạc sĩ chuyên ngành Lí thuyết và Lịch sử Âm nhạc, hội viên Hội Nhạc sĩ Việt Nam; nguyên Giảng viên chính, Trưởng Bộ môn Âm nhạc Trường Đại học Thủ đô Hà Nội; tác giả Chương trình giáo dục phổ thông môn Âm nhạc 2018; tác giả nhiều giáo trình nghiên cứu, tham khảo về dạy học môn Âm nhạc.",
    author_image: null,
  },
  {
    id: 31,
    author_name: "Phạm Văn Tuyến",
    stt: "031",
    author_description:
      'Tiến sĩ Nghệ thuật học, Trưởng khoa Nghệ thuật, Trường Đại học Sư phạm Hà Nội; Chủ biên sách Âm nhạc, Mĩ thuật địa phương\nNam Định; Chủ nhiệm đề tài cấp Nhà nước "Ứng dụng tác phẩm mĩ thuật trong giáo dục giá trị truyền thông Việt Nam cho trẻ mầm non và học sinh Tiểu học".',
    author_image: null,
  },
  {
    id: 32,
    author_name: "Nguyễn Thị Đông",
    stt: "032",
    author_description:
      "Thạc sĩ, giảng viên chính, nguyên Phó trưởng Bộ môn Lí luận Mĩ thuật, khoa Sư phạm Mĩ thuật Trường Đại học Sư phạm Nghệ thuật trung ương; giảng viên cốt cán Dự án Hỗ trợ Giáo dục mĩ thuật tiểu học (Saeps); Chủ biên Chương trình giáo dục phổ thông môn Mĩ thuật 2018; Chủ biên tài liệu hướng dẫn dạy học môn Nghệ thuật tiểu học theo Chương trình giáo dục phổ thông mới phần Mĩ thuật và nhiều tài liệu khác; tác giả nhiều đề tài nghiên cứu khoa học về giáo dục mĩ thuật ở trường phổ thông; Kỉ niệm Chương vì sự nghiệp giáo dục.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/032.png`,
  },
  {
    id: 33,
    author_name: "Nguyễn Hải Kiên",
    stt: "033",
    author_description:
      "Thạc sĩ hội hoạ, Trường Đại học mĩ thuật Việt Nam. Giảng viên Đại học Sư phạm Nghệ thuật Trung ương. Tác giả sách Tài liệu hướng dẫn dạy học môn Nghệ thuật tiểu học theo Chương trình Giáo dục phổ thông 2018 phần mĩ thuật (Nhà xuất bản Đại học Sư phạm). Giáo Trình: Trang trí cơ bản 1,2. Bố cục cơ bản. Trường Đại học Sư phạm Nghệ thuật Trung ương.\n_ 2000 - 2006: Họa sĩ thiết kế mẫu tiền, Ngân hàng nhà nước Việt Nam. \n_ 2006 - đến nay: Giảng viên Đại học sư phạm Nghệ thuật TW.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/033.png`,
  },
  {
    id: 34,
    author_name: "Phạm Đình Bình",
    stt: "034",
    author_description:
      "Thạc sĩ Mĩ thuật, Trưởng bộ môn Mĩ thuật, Khoa Nghệ thuật, Trường Đại học Sư phạm Hà Nội.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/034.png`,
  },
  {
    id: 35,
    author_name: "Nguyễn Minh Quang",
    stt: "035",
    author_description:
      "Tiến sĩ Mĩ thuật học (Học viện Mĩ thuật Thượng Hải, Trung Quốc), giảng viên Đại học Mĩ thuật TP. Hồ Chí Minh; tác giả Chương trình giáo dục phổ thông môn Mĩ thuật 2018; tác giả nhiều sách, báo chuyên khảo và tài liệu dạy học về Mĩ thuật; Huy chương Đồng triển lãm Mĩ thuật toàn quốc năm 2000; giải thưởng Mĩ thuật Việt Nam ASEAN năm 2004.",
    author_image: null,
  },
  {
    id: 36,
    author_name: "Nguyễn Dục Quang",
    stt: "036",
    author_description:
      "Phó Giáo sư, Tiến sĩ Giáo dục học, nguyên Phó Giám đốc Trung tâm Nghiên cứu Giáo dục công dân, nguyên Phó Giám đốc Trung tâm Đào tạo và bồi dưỡng, Viện Khoa học Giáo dục Việt Nam, giảng viên chính Trường Đại học Sư phạm Hà Nội 2; Chủ biên sách giáo viên Hoạt động giáo dục ngoài giờ lên lớp lớp 10, tác giả sách giáo viên Hoạt động giáo dục ngoài giờ lên lớp các lớp 6, 7, 8, 9, 10, 12 (Chương trình 2006); tác giả Chương trình Hoạt động giáo dục ngoài giờ lên lớp THCS và THPT (Chương trình 2006); Chủ biên Giáo trình Hoạt động giáo dục ngoài giờ lên lớp (Dành cho đào tạo giáo viên THCS).",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/036.png`,
  },
  {
    id: 37,
    author_name: "Ngô Quang Quế",
    stt: "037",
    author_description:
      "Cử nhân Toán, Trường Đại học Sư phạm; chuyên gia tư vấn các Chương trình, Dự án của Bộ Giáo dục và Đào tạo: Chương trình Đảm bảo chất lượng giáo dục trường học (SEQAP), Chương trình phát triển các trường sư phạm để nâng cao năng lực đội ngũ giáo viên và cần bộ quản lí cơ sở giáo dục phổ thông (ETEP). Nguyên Chuyên viên chính Vụ Giáo dục Tiểu học, Bộ Giáo dục và Đào tạo; tác giả Chương trình Bồi dưỡng thường xuyên cho giáo viên tiểu học, chu kì lll; tác giả tài liệu bồi dưỡng thường xuyên cho giáo viên tiểu học, chu kì III (tập 1 và 2); tác giả sách Hướng dẫn tổ chức các hoạt động giáo dục ngoài giờ lên lớp (lớp 1 - lớp 5), sách Bài soạn Đạo đức cấp tiểu học, Vở bài tập môn Đạo đức tiểu học; tác giả tài liệu tích hợp giáo dục kĩ năng sống qua môn Đạo đức; tác giả Giáo trình Hoạt động giáo dục ngoài giờ lên lớp (Dùng cho hệ Cao đẳng sư phạm); tác giả nhiều sách tham khảo về hoạt động giáo dục ngoài giờ lên lớp và các hoạt động giáo dục khác.\n",
    author_image: "v ",
  },
  {
    id: 38,
    author_name: "Phạm Quang Tiệp",
    stt: "038",
    author_description:
      "Tiến sĩ Giáo dục học, Trưởng Bộ\nmôn Giáo dục Tiểu học, Trường Đại học Giáo dục, Đại học Quốc Gia Hà Nội. Nguyên Trưởng khoa Giáo dục Tiểu học, Trường Đại học Sư phạm Hà Nội 2; tác giả một số sách chuyên khảo, giáo trình, sách tham khảo và hơn 30 bài báo khoa học, phục vụ đào tạo các trình độ cử nhân, thạc sĩ chuyên ngành giáo dục tiểu học; Chủ biên và tác giả của nhiều sách tham khảo cho giáo viên và học sinh tiểu học về đánh giá trong giáo dục tiểu học, dạy học tích hợp ở tiểu học và tổ chức hoạt động trải nghiệm trong dạy học tự nhiên - xã hội ở tiểu học.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/038.png`,
  },
  {
    id: 39,
    author_name: "Nguyễn Thị Thu Hằng",
    stt: "039",
    author_description:
      "Tiến sĩ Giáo dục học, giảng viên Khoa Giáo dục Tiểu học, Trường Đại học Sư phạm Thái Nguyên; Nghiên cứu và giảng dạy đại học và sau đại học về các lĩnh vực: Dạy học môn Đạo đức, dạy học các môn Tự nhiên và Xã hội, Khoa học ở trường tiểu học, Giáo dục kĩ năng sống và tổ chức hoạt động trải nghiệm ở trường tiểu học; tác giả tài liệu và báo cáo viên tập huấn Bồi dưỡng Chức danh nghề nghiệp Giáo viên Tiểu\nhọc; tác giả tài liệu bồi dưỡng Giáo viên Tiểu học (Modul 2, 3, 4) thực hiện Chương trình Giáo dục phố thông 2018; báo cáo viên tập huấn Phát triển Chương trình đào tạo Cử nhân Sư phạm ngành Giáo dục Tiểu học cho cán bộ, giảng viên một số trường cao đẳng sư phạm; báo cáo viên bồi dưỡng, tập huân cho Tổ trưởng chuyên môn và Giáo viên cốt cán cấp tiểu học thực hiện Chương trình Giáo dục phồ thông 2018.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/039.png`,
  },
  {
    id: 40,
    author_name: "Nguyễn Khánh Hà",
    stt: "040",
    author_description:
      "Tiến sĩ Ngữ văn (Trường Đại học Khoa học Xã hội và Nhân văn, Đại học Quốc gia Hà Nội), Phó Chủ\nnhiệm Khoa Ngôn ngữ và Văn hóa Việt Nam, Trường Đại học Hà Nội, tác giả một số sách nghiên cứu ngôn ngữ học và sách\ndạy tiếng Việt cho người nước ngoài.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/040.png`,
  },
  {
    id: 41,
    author_name: "Nguyễn Thị Tố Ninh",
    stt: "041",
    author_description:
      "Tiến sĩ Ngữ văn (Trường Đại học Khoa học Xã hội và Nhân văn, Đại học Quốc gia Hà Nội), Phó Tổng biên tập Tạp chí Lý luận và Phê bình nghệ thuật Ban Tuyên giáo Trung ương, nguyên giảng viên Khoa Ngữ văn, Trường Đại học Sư phạm thuộc Đại học Thái Nguyên, tác giả sách và một số bài báo về ngôn ngữ học.",
    author_image: null,
  },
  {
    id: 42,
    author_name: "Trần Bích Thuỷ",
    stt: "042",
    author_description:
      "Cử nhân Sư phạm, Phó Hiệu trưởng Trường Tiểu học Tiền Phong A, huyện Mê Linh, Thành phố Hà Nội.",
    author_image: null,
  },
  {
    id: 43,
    author_name: "Nguyễn Thị Bích Hà",
    stt: "043",
    author_description:
      "Cử nhân Sư phạm, giáo viên Trường Tiểu học Trung Tự, quận Đống Đa, Hà Nội.",
    author_image: null,
  },
  {
    id: 44,
    author_name: "Tiến sĩ Nguyễn Thanh Bình",
    stt: "044",
    author_description:
      "Chủ biên Nguyễn Thanh Bình, Tiến sĩ Giáo dục chuyên ngành Lý luận và Phương pháp giảng dạy tiếng Anh tại Australia, Trưởng Khoa Tiếng Anh, Trưởng Ban Đề án Ngoại ngữ quốc gia của Trường Đại học Sư Phạm Thành phố Hồ Chí Minh; chuyên gia giảng dạy tiếng Anh và đào tạo giáo viên tiếng Anh; tác giả có nhiều năm kinh nghiệm trong xây dựng và triển khai chương trình bồi dưỡng giáo viên tiếng Anh của Trường Đại học Sư Phạm Thành phố Hồ Chí Minh ở nhiều tỉnh, thành phố phía Nam.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/044.png`,
  },
  {
    id: 45,
    author_name: "Trần Văn Thắng",
    stt: "045",
    author_description:
      "Tiến sĩ Luật (Trường Đại học Quan hệ Quốc tế Matxcơva, Liên bang Nga); nguyên Trưởng Ban biên tập Nhà xuất bản Giáo dục Việt Nam, Chuyên gia tư vấn Ban Quản lí Dự án Hỗ trợ đổi mới giáo dục phổ thông (RGEP); Uỷ viên Hội đồng Bộ môn Giáo dục công dân và Hoạt động giáo dục ngoài giờ lên lớp - Bộ Giáo dục và Đào tạo, nhiệm kì 2005 - 2010 và 2010 - 2015; Chủ biên Giáo trình Pháp luật (Dành cho đào tạo giáo viên THCS); tác giả Chương trình Giáo dục phổ thông 2006, môn Giáo dục công dân; Chủ biên sách giáo khoa Giáo dục công dân 12 (Chương trình 2006); Chủ biên và tác giả nhiều sách dạy và học giáo dục công dân (từ lớp 6 đến lớp 12), giáo dục đạo đức - lồi sống (từ lớp 1 đến lớp 5), giáo dục an toàn giao thông (từ lớp 1 đến lớp 12), giáo dục phòng chống tham nhũng cho học sinh trung học phổ thông, cùng nhiều sách khác về giáo dục đạo đức - giáo dục công dân, giáo dục pháp luật dành cho giáo viên, học sinh tiểu học, THCS và THPT.\n",
    author_image: null,
  },
  {
    id: 46,
    author_name: "Vũ Thị Mai Hường",
    stt: "046",
    author_description:
      "Tiến sĩ Giáo dục học (Trường Đại học Sư phạm Hà Nội); Giảng viên Khoa Quản lí Giáo dục, Trường Đại học Sư phạm Hà Nội; Phó Giám đốc Trung tâm Nghiên cứu và phát triển nghiệp vụ sư phạm, Trường Đại học Sư phạm Hà Nội; chuyên nghiên cứu các vẫn đề lí luận và thực tiễn quản lí giáo dục tiểu học, hoạt động dạy học và giáo dục kĩ năng sống cho học sinh tiểu học; tác giả một số công trình về cách tiếp cận hiện đại trong quản lí nhà trường, dạy học và phát triển năng lực học sinh tiểu học.",
    author_image: null,
  },
  {
    id: 47,
    author_name: "Phùng Thanh Huyền",
    stt: "047",
    author_description:
      "Thạc sĩ Giáo dục học, nguyên Trưởng ban biên tập sách Vật lí Nhà xuất bản Giáo dục Việt Nam; Trưởng ban biên tập sách Vật Lí - Hoá học - Sinh học - Công nghệ Công ty Đầu tư xuất bản - Thiết bị giáo dục Việt Nam. Đầu tư xuất bản - Thiết bị giáo dục Việt Nam. Đồng tác giả của nhiều cuốn sách tham khảo môn Vật lí (Chương trình 2006),môn Tự nhiên và Xã hội lớp 1 (Chương trình Giáo dục phổ thông 2018).",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/047.png`,
  },
  {
    id: 48,
    author_name: "Lưu Quang Hiệp",
    stt: "048",
    author_description:
      "Giáo sư, Tiền sĩ Y học Thể dục thể thao (Đại học Thể dục thể thao Mátxcơva, Liên bang Nga). Nguyên Hiệu trưởng Trường Đại học Thể dục thể thao Bắc Ninh. Tham gia hội đồng thẩm định sách giáo viên môn Thể dục cấp Tiểu học, THCS, THPT (Chương trình 2006). Đã tham gia hội đồng thẩm định chương trình môn Thể dục cấp Tiểu học, THCS, THPT (CT 2006). Tác giả Chương trình Giáo dục phổ thông 2018 môn Giáo dục thế chát. Hướng dẫn nhiều luận văn, luận án cho học viên cao học và nghiên cứu sinh chuyên ngành Giáo dục thể chất và huần luyện thể thao học viên, nghiên cứu sinh đã bảo vệ thành công. Tác giả nhiều sách, đề tài nghiên cứu và các chương trình về Thế dục thể thao.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/048.png`,
  },
  {
    id: 49,
    author_name: "Phạm Đông Đức",
    stt: "049",
    author_description:
      "Phó Giáo sư, Tiến sĩ khoa học giáo dục, chuyên ngành Thể dục Thể thao (Đại học Thể dục thể thao Mátxcơva, Liên bang Nga); Trưởng khoa Khoa học Thể thao và Sức khoẻ, Trường Đại học Thủ đô Hà Nội. Chủ biên sách hướng dẫn dạy học môn Giáo dục thể chất lớp 1và trung học phổ thông theo Chương trình giáo dục phổ thông mới; chủ biên và tác giả nhiều tài liệu tham khảo, tập huấn giáo viên thực hiện chương trình phổ thông 2018 môn Giáo dục thể chất; chủ biên chương trình đào tạo giáo viên chuyên ngành Giáo dục thể chất bậc đại học và sau đại học; tổ chức và trực tiếp giảng dạy nhiều lớp sinh viên, học viên cao học và nghiên cứu sinh chuyên ngành Giáo dục thể chất và huấn luyện thể thao; chủ nhiệm và thành viên nhiều đề tài khoa học các cấp từ cơ sở đến cấp bộ...;",
    author_image: null,
  },
  {
    id: 50,
    author_name: "Nguyễn Duy Linh ",
    stt: "050",
    author_description:
      "Thạc sĩ Giáo dục học. Trưởng bộ môn Thể thao tập thể, khoa Khoa học Thể thao và Sức khoẻ, Trường Đại học Thủ đô Hà Nội. Là tác giả nhiều sách và giáo trình có liên quan đến lĩnh vực giáo dục thể chất và thể thao trường học. Là chủ nhiệm của một số để tài khoa học công nghệ cấp cơ sở liên quan đền lĩnh vực Giáo dục thể chất và\nthể thao trường học. Là chuyên gia trong lĩnh vực tổ chức thi đấu thể dục thể thao từ cắp thành phố đến cấp trung ương. Đã có kinh nghiệm giảng dạy phổ biến kiến thức chuyên môn nghiệp vụ cho đối tượng giáo viên các cấp",
    author_image: null,
  },
  {
    id: 51,
    author_name: "Phạm Tràng Kha",
    stt: "051",
    author_description:
      "Thạc sĩ Giáo dục học. Giảng viên bộ môn Thể thao cá nhân khoa Khoa học Thể thao và Sức khoẻ trường Đại học Thủ đô Hà Nội. Tác giả một số giáo trình, sách, tài liệu tham khảo về Giáo dục thể chất. Tham gia giảng dạy ở các cấp học phổ thông nhiều năm, có nhiều sáng kiến trong giảng dạy; Tham gia tổ chức hoạt động và phụ trách giảng dạy một số câu lạc bộ cho học sinh phổ thông như bóng rổ, bóng đá, bơi lội, khiêu vũ thể thao,...",
    author_image: null,
  },
  {
    id: 52,
    author_name: "Tạ Hoàng Mai Anh",
    stt: "052",
    author_description:
      "Tiến sĩ Âm nhạc học, giảng viên khoa Nghệ thuật, Trường Đại học Sư phạm Hà Nội, tác giả một số giáo trình, tham khảo thuộc lĩnh vực Âm nhạc. Tác giả một số để tài khoa học công nghệ, công bố khoa học trong nước và nước ngoài. Báo cáo viên bồi dưỡng giáo viên thực hiện Chương trình Giáo dục phồ thông 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/052.png`,
  },
  {
    id: 53,
    author_name: "Nguyễn Thị Quỳnh Mai",
    stt: "053",
    author_description:
      "Thạc sĩ Lí luận và phương pháp dạy học môn Âm nhạc, Giảng viên Khoa Giáo dục Mầm non Trường Đại học Sư phạm Hà Nội 2, tác giả một số bài báo và đề tài nghiên cứu về giáo dục âm nhạc. Báo cáo viên bồi dưỡng giáo viên thực hiện Chương trình Giáo dục phổ thông 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/053.png`,
  },
  {
    id: 54,
    author_name: "Nguyễn Thị Thu Huyền",
    stt: "054",
    author_description:
      "Thạc sĩ Mĩ thuật, Giảng viên khoa Sư phạm Mĩ thuật, Trường Đại học Sư phạm Nghệ thuật Trung ương.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/054.png`,
  },
  {
    id: 55,
    author_name: "Đỗ Ngọc Thống",
    stt: "055",
    author_description:
      "Phó giáo sư, Tiền sĩ; Chủ biên chương trình Ngữ văn 2018; tác giả sách Ngữ văn từ lớp 6 đến lớp 9 (Chương trình 2006); Đồng chủ biên sách Ngữ văn từ lớp 10 đến lớp 12 (bộ Nâng cao, chương trình 2006), Tổng Chủ biên và Chủ biên nhiều sách tham khảo Ngữ văn cấp THCS và THPT; Nguyên Trưởng phòng Ngữ văn; Chủ tịch Hội đồng khoa học Trung tâm Nội dung và phương pháp; Phó Chủ tịch Hội đồng Khoa học Viện Khoa học Giáo dục Việt Nam; Phó Vụ trưởng Vụ Giáo dục Trung học, chuyên trách về đổi mới Chương trình và Sách giáo khoa sau 2015 (Bộ GD&ĐT); Chủ biên và tác giả nhiều tài liệu bồi dưỡng GV chuyên văn và GV phổ thông; đã tham gia dạy học Ngữ văn ở nhà trường phổ thông.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/055.png`,
  },
  {
    id: 56,
    author_name: "Lê Huy Bắc",
    stt: "056",
    author_description:
      "Giáo sư, Tiến sĩ Ngữ văn; giảng viên cao cấp Trường Đại học Sư phạm Hà Nội; Trưởng khoa  Kho Việt Nam học  và Viện trưởng Viện Giáo dục Quốc tế Trường Đại học Sư phạm Hà Nội, nguyên Trưởng bộ môn Văn học nước ngoài thuộc khoa Ngữ văn, Trường Đại học Sư phạm Hà Nội; tác giả nhiều giáo trình và Tài liệu bồi dưỡng GV về văn học nước ngoài; tác giả SGK Ngữ văn THPT (Chương trình 2006), Chủ biên và tác giả nhiều sách tham khảo Ngữ văn các cấp học phổ thông.",
    author_image: null,
  },
  {
    id: 57,
    author_name: "Bùi Minh Đức",
    stt: "057",
    author_description:
      "Phó giáo sư, Tiến sĩ; Trường khoa Khoa Ngữ văn, giảng viên cao cấp Trường Đại học Sư phạm Hà Nội 2; tác giả SGK Ngữ văn THCS (VNEN), Hướng dẫn thực hiện chuẩn kiến thức, kĩ năng môn Ngữ văn THCS, THPT (Chương trình 2006); Chủ biên và đồng tác giả một số giáo trình, chuyên khảo và nhiều sách tham khảo về dạy học Ngữ văn ở nhà trường phổ thông; đã tham gia dạy học Ngữ ở nhà trường phổ thông.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/057.png`,
  },
  {
    id: 58,
    author_name: "Phạm Thị Thu Hiền",
    stt: "058",
    author_description:
      "Tiến sĩ Giáo dục học, Trường Đại học Giáo dục - Đại học Quốc gia Hà Nội; nguyên chuyên viên Vụ Giáo dục Trung học, Bộ Giáo dục và Đào tạo; Chủ biên và là tác giả của nhiều sách tham khảo cho giáo viên Ngữ văn cấp phổ thông; đã và đang tham gia dạy học Ngữ văn cấp THCS và THPT.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/058.png`,
  },
  {
    id: 59,
    author_name: "Phạm Thi Thu Hương",
    stt: "059",
    author_description:
      "Phó giáp sư, Tiến sĩ; giảng viên cao cấp; Trưởng bộ môn Phương pháp dạy học, Khoa Ngữ văn, Trường Đại học Sư phạm Hà Nội; Chủ biên và tác giả của nhiều sách giáo trình, tham khảo, bài báo khoa học về dạy học môn Ngữ văn ở trường cấp phổ thông ; đã và đang tham gia dạy học Ngữ văn cấp THCS và THPT.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/059.png`,
  },
  {
    id: 60,
    author_name: "Nguyễn Văn Lộc",
    stt: "060",
    author_description:
      "Phó giáo sư, Tiến sĩ Ngữ văn, nguyên Chủ nhiệm khoa Khoa Ngữ văn; nguyên Hiệu trưởng Trường Đại học Sư phạm - Đại học Thái Nguyên, đã tham gia xây dựng các chương trình đào tạo giáo viên mầm non, tiểu học, THCS, THPT có trình độ đại học; tác giả của nhiều sách và công trình nghiên cứu về dạy học tiếng Việt.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/060.png`,
  },
  {
    id: 61,
    author_name: "Trần Nho Thìn",
    stt: "061",
    author_description:
      "Giáo sư, Tiến sĩ; tác giả sách Ngữ văn THPT (Chương trình 2006, bộ Cơ bản); Chủ biên và là tác giả nhiều sách tham khảo Ngữ văn THPT (Chương trình 2006); tác giả của nhiều chuyên để bồi dưỡng giáo viên các trường THPT chuyên do Bộ Giáo dục và Đào tạo tổ chức. Là tác giả của một công trình khoa học được giải thưởng loại A của Đại học Quốc gia Hà Nội; một công trình giải Đồng Hiệp hội sách Việt Nam; một công trình giải A Hội đồng Lí luận, phê bình văn học, nghệ thuật Trung ương.",
    author_image: null,
  },
  {
    id: 62,
    author_name: "Trần Văn Toàn",
    stt: "062",
    author_description:
      "Phó giáo sư, Tiến sĩ chuyên ngành Văn học Việt Nam; giảng viên cao cấp Trường Đại học Sư phạm Hà Nội; Tác giả giáo trình văn học Việt Nam của Trường Đại học Sư phạm Hà Nội; tác giả SGK Ngữ văn THCS VNEN và nhiều sách tham khảo môn Ngữ văn; đã tham gia dạy học Ngữ văn ở trường phổ thông.",
    author_image: null,
  },
  {
    id: 63,
    author_name: "TS. Lê Tuấn Anh",
    stt: "063",
    author_description:
      "Tiến sĩ chuyên ngành Lí luận và Phương pháp dạy học bộ môn Toán (Đại học Potsdam, Cộng hoà Liên bang Đức), Trưởng bộ môn Phương pháp dạy học Toán thuộc Khoa Toán - Tin, Trường Đại học Sư phạm Hà Nội; tác giả của một số tài liệu nghiên cứu, tham khảo về dạy học môn Toán.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/063.png`,
  },
  {
    id: 64,
    author_name: "TS. Nguyễn Sơn Hà",
    stt: "064",
    author_description:
      "Tiến sĩ chuyên ngành Lí luận và Phương pháp dạy học Bộ môn Toán, Giáo viên Trường THPT Chuyên Đại học Sư phạm, Giáo viên thỉnh giảng trường THCS&THPT Nguyễn Tất Thành Đại học Sư phạm Hà Nội; tác giả Chương trình Giáo dục phổ thông môn Toán 2018, Chương trình chuyên sâu môn Toán cấp Trung học phổ thông 2019, Chương trình bồi dưỡng giáo viên phổ thông và các bộ quản lí cơ sở giáo dục phổ thông 2020, tác giả của nhiều tài liệu tham khảo môn Toán dành cho giáo viên và học sinh THCS, THPT.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/064.png`,
  },
  {
    id: 65,
    author_name: "Nguyễn Thị Phương Loan",
    stt: "065",
    author_description:
      "Thạc sĩ Toán, Giáo viên Toán trường THPT chuyên Nguyễn Huệ; tham gia biên soán câu hỏi phục vụ đánh giá diện rộng quốc gia về kết quản học tập của học sinh môn Toán lớp 12; thành viên góp ý Chương trình Giáo dục phổ thông môn Toán 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/065.png`,
  },
  {
    id: 66,
    author_name: "TS. Phạm Sỹ Nam",
    stt: "066",
    author_description:
      "Tiến sĩ chuyên ngành Lí luận và Phương pháp giảng dạy Bộ môn Toán, Giảng viên Khoa Toán - Ứng dụng trường Đại học Sài Gòn; tác giả Chương trình Giáo dục phổ thông môn Toán 2018; tác giả nhiều tài liệu nghiên cứu, tham khảo về dạy học môn Toán; đã tham gia báo cáo tại nhiều Hội thảo quốc tế về Giáo dục toán học ở nước ngoài; có kinh nghiệm giảng dạy 15 năm ở trường chuyên.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/066.png`,
  },
  {
    id: 67,
    author_name: "PGS.TS. Phạm Đức Quang",
    stt: "067",
    author_description:
      "Phó Giáo sư Tiến sĩ Toán, Nghiên cứu viên chính, Phó Giám đốc Trung tâm phát triển bên vững chất lượng giáo dục phổ thông Quốc gia (Viện Khoa học Giáo dục Việt Nam); Uỷ viên Hội đồng Quốc gia giáo dục và phát triển nhân lực; tác giả Chương trình và Chuẩn môn Toán (ban hành 2006); tác giả của nhiều tài liệu và sách tham khảo cho giáo viên toán (THCS và THPT); được tặng thưởng Huy chương vì sự nghiệp phát triển Văn hoá và Giáo dục miền núi.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/067.png`,
  },
  {
    id: 68,
    author_name: "Tiến sĩ Phạm Nguyễn Huy Hoàng",
    stt: "068",
    author_description:
      "Tiến sĩ Phạm Nguyễn Huy Hoàng, Tiến sĩ Giáo dục chuyên ngành Lý luận và Phương pháp giảng dạy tiếng Anh tại Đại học Canberra (Australia); Trưởng bộ môn Lý luận và Phương pháp giảng dạy tiếng Anh thuộc Khoa Tiếng Anh Trường Đại học Sư Phạm Thành phố Hồ Chí Minh; tác giả nhiều tài liệu nghiên cứu, tham khảo về phương pháp dạy học tích cực môn Tiếng Anh.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/068.png`,
  },
  {
    id: 69,
    author_name: "Tiến sĩ Nguyễn Thị Xuân Lan ",
    stt: "069",
    author_description:
      "Tiến sĩ Nguyễn Thị Xuân Lan, Tiến sĩ Giáo dục tại Đại học Newcastle (Australia); Phụ trách Khoa Đào tạo Sau đại học, giảng viên giảng dạy Tiếng Anh chuyên ngữ và không chuyên ngữTrường Đại học Mở Thành phố Hồ Chí Minh; đồng tác giả sách giáo trình Ngữ pháp Tiếng Anh;\ntác giả nhiều tài liệu nghiên cứu về phương pháp giảng dạy Tiếng Anh, ứng dụng công nghệ thông tin trong giảng dạy tiếng Anh.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/069.png`,
  },
  {
    id: 70,
    author_name: "Thạc sĩ Đào Xuân Phương Trang",
    stt: "070",
    author_description:
      "Thạc sĩ Đào Xuân Phương Trang, Thạc sĩ Lí luận và Phương pháp giảng dạy Tiếng Anh (CTESOL) tại Đại học Canberra (Australia), giảng viên Khoa Tiếng Anh Trường Đại học Sư phạm Thành phố Hò Chí Minh; tác giả chương trình tiếng Anh cho nhiều cơ sở GDMN, THCS, THPT, CĐ và ĐH tại Thành phó Hò Chí Minh và có nhiều kinh nghiệm trong bồi dưỡng giáo viên dạy tiếng Anh tại Thành phố Hồ Chí Minh và các địa phương khác.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/070.png`,
  },
  {
    id: 71,
    author_name: "Thạc sĩ Lê Nguyễn Như Anh",
    stt: "071",
    author_description:
      "Thạc sĩ Lê Nguyễn Như Anh, Thạc sĩ Ngôn ngữ học ứng dụng (App;ied Linguistics) tại Đại học Curtin (Australia), giảng viên Khoa Tiếng Anh Trường Đại học Sư phạm Thành phố Hồ Chí Minh; chuyên gia đào tạo về phương pháp giảng dạy tiếng Anh (TESOL) và Ứng dụng Công nghệ thông tin trong dạy học ngoại ngữ (Technology in Language Teaching).",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/071.png`,
  },
  {
    id: 72,
    author_name: "Nguyễn Thị Thu Hoài",
    stt: "072",
    author_description:
      "Phó Giáo sư, Tiến sĩ Kinh tế (Đại học Quốc gia Hà Nội); giảng viên, Phó Trưởng Phòng Nghiên cứu khoa học và Hợp tác phát triển, Trường Đại học Kinh tế, Đại học Quốc gia Hà Nội; Uỷ viên Hội đồng Bộ môn Giáo dục công dân và Hoạt động giáo dục ngoài giờ lên lớp - Bộ Giáo dục và Đào tạo, nhiệm kì 2005- 2010 và 2010 - 2015; Chuyên gia tư vần cao cấp Dự án VNEN, Bộ Giáo dục và Đào tạo; Thành viên Ban Phát triển Chương trình Giáo dục phổ thông 2018, môn Giáo dục công dân; tác giả sách giáo viên Giáo dục công dân lớp 12 (Chương trình 2006); Chủ biên, tác giả nhiều sách dạy và học dành cho giáo viên và học sinh môn Giáo dục công dân.\n",
    author_image: null,
  },
  {
    id: 73,
    author_name: "Mai Thu Trang",
    stt: "073",
    author_description:
      "Tiến sĩ Giáo dục học (Trường Đại học Sư phạm Hà Nội); Giảng viên, Phó Trưởng Khoa Giáo dục chính trị, Trường Đại học Sư phạm TP Hồ Chí Minh; nghiên cứu và giảng dạy về Phương pháp dạy học môn Giáo dục công dân; tác giả một số cuốn sách dành cho học sinh môn Giáo dục công dân.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/073.png`,
  },
  {
    id: 74,
    author_name: "Nguyễn Thuý Quỳnh",
    stt: "074",
    author_description:
      "Thạc sĩ chuyên ngành Lý luận và lịch sử giáo dục, giảng viên Khoa Tâm lý - Giáo dục học, Trường Đại học Sư phạm Hà Nội; chuyên gia tập huấn phương pháp giáo dục kĩ năng sống cho giáo viên trung học cơ sở; bồi dưỡng chương trình Tổ chức hoạt động trải nghiệm cho giáo viên THCS và THPT; tác giả tài liệu bồi dưỡng, sách, báo và đề tài nghiên cứu về giáo dục kĩ năng sống cho học sinh trường phổ thông, sinh viên các trường đại học sư phạm.\n",
    author_image: null,
  },
  {
    id: 75,
    author_name: "Nguyễn Văn Khánh",
    stt: "075",
    author_description:
      "Phó Giáo sư, Tiến sĩ, Trưởng Bộ môn Vật lí đại cương, nguyên Phó trưởng khoa Vật lí, Trường Đại học Sư Phạm Hà Nội; Thành viên biên soạn Chương trình Giáo dục phổ thông 2018; Tác giả biên soạn Chương trình môn Khoa học tự nhiên 2018; Chủ biên Chương trình môn Vật lí 2018; Nhiều năm là Trưởng ban Ban đề thi tuyển sinh đại học môn Vật lí của Bộ Giáo dục và Đào tạo; Nhiều năm là Trưởng đoàn Vật lí sinh viên của Trường Đại học Sư phạm Hà Nội tham dự Olympic Vật lí sinh viên toàn quốc; Nhiều năm phụ trách việc tổ chức huấn luyện đội tuyển, Phó đoàn Vật lí Việt Nam tham dự APhO và lphO. Irưởng ban Olympic Vật lí sinh viên toàn quốc, Hội Vật lí Việt Nam; Thành viên Liên đoàn Thiên văn Quốc tế (International Astronomical Union). Đã đăng hơn 4O bài báo khoa học trên các tạp chí chuyên ngành, trong và ngoài nước; trong đó có nhiều bài báo về dạy học Vật lí ở trường phổ thông; Tác giả sách bồi dưỡng giáo viên THPT chuyên - môn Vật lí; tác giả\nsách Hướng dẫn dạy học môn Khoa học tự nhiên, Chủ biên sách Hướng dẫn dạu học môn Vật lí theo Chương trình Giáo dục phổ thông 2018.\n",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/075.png`,
  },
  {
    id: 76,
    author_name: "Đặng Thị Oanh",
    stt: "076",
    author_description:
      "Phó giáo sư, Tiền sĩ: Nguyên Trưởng khoa Khoa Hoá học , trường Đại học Sư phạm Hà Nội; Chủ biên Chương trình Giáo dục phồ thông môn Hoá học 2018; Tác giả Chương trình Giáo dục phổ thông môn Khoa học tự nhiên 2018. Chủ biên và tác giả của hơn 30 đầu sách, giáo trình về dạy học môn Hoá học ở cấp THCS, THPT: sách bồi dưỡng giáo viên, sách hướng dẫn dạy học môn Khoa học tự nhiên. Đã đăng hơn 5O bài báo khoa học trên các tạp chí chuyên ngành trong và ngoài nước về dạy học Hoá học ở trường phổ thông.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/076.png`,
  },
  {
    id: 77,
    author_name: "Lê Minh Cầm ",
    stt: "077",
    author_description:
      "Phó giáo sư, Tiến sĩ; Chủ biên Giáo trình thực hành Hoá Lí; Đồng tác giả bộ sách giáo khoa Hoá học song ngữ lớp 9-12; Đồng tác giả Tài liệu chuyên Hoá học trung học phổ thông - Thực hành Hoá học.",
    author_image: null,
  },
  {
    id: 78,
    author_name: "Ngô Ngọc Hoa",
    stt: "078",
    author_description:
      "Phó giáo sư, Tiến sĩ, Giảng viên Khoa Vật lí trưởng Đại học Sư phạm Hà Nội. Làm chủ nhiệm nhiều đề tài khoa học cấp Bộ như: Nghiên cứu phổ hấp thụ; Mô phỏng chính các phổ hấp thụ trong vùng hồng ngoại... Có 18 bài báo đăng trên tạp chí nước ngoài và tham gia nhiều hội nghị khoa học trong nước và quốc tế.",
    author_image: null,
  },
  {
    id: 79,
    author_name: "Lê Thị Phương Hoa",
    stt: "079",
    author_description:
      "Phó giáo sư, Tiến sĩ, Giảng viên Khoa Sinh học trường Đại học Sư phạm Hà Nội. Đồng tác giả sách Thực hành sinh học trong trường phổ thông, Thực hành thí nghiệm Sinh học 10. Tham gia Đoàn Việt Nam tham dự Olympic Sinh học quốc tế (2017 - 2020).",
    author_image: null,
  },
  {
    id: 80,
    author_name: "Phan Thị Thanh Hội",
    stt: "080",
    author_description:
      "Phó giáo sư, Tiến sĩ, Giảng viên cao cấp, Trưởng bộ môn Lí luận và Phương pháp dạy học Sinh học, Trường Đại học Sư phạm Hà Nội. Là tác giả Chương trình Giáo dục phổ thông môn Khoa học tự nhiên và môn Sinh học năm 2018. Chủ biên và đồng tác giả hơn 20 đầu sách tham khảo dành cho giáo viên, sinh viên và học sinh về môn Sinh học và Khoa học tự nhiên.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/080.png`,
  },
  {
    id: 81,
    author_name: "Đỗ Thanh Hữu",
    stt: "081",
    author_description:
      "Thạc sĩ Vật lí, Tác giả Chương trình Giáo dục phổ thông môn Khoa học tự nhiên và môn Vật lí 2018. Giáo viên dạy giỏi tỉnh Nam Định năm học 2011 - 2012. Thành viên Hội đồng chuyên môn Giáo dục trung học tỉnh Nam Định năm học 2018 - 2019 và 2019 - 2020.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/081.png`,
  },
  {
    id: 82,
    author_name: "Cao Tiến Khoa",
    stt: "082",
    author_description:
      "Tiến sĩ Giáo dục học, Trường khoa Khoa Vật lí trường Đại học Sư phạm - Đại học Thái Nguyên, Chủ biên và tác giả nhiều tài liệu tham khảo, tập huấn về dạy và học, phát triển chương trình môn Vật lí ở trưởng phổ thông.",
    author_image: null,
  },
  {
    id: 83,
    author_name: "Tác giả Lê Thị Thanh",
    stt: "083",
    author_description:
      "Thạc sĩ Sinh học, Tổ trưởng tổ chuyên môn Vật lí - Hoá học - Sinh hcoj trường Trung học phổ thông Việt Đức, Hà Nội. giáo viên dạy giỏi cấp thành phố năm học 2006 - 2007, 2018 - 2019 và nhiều giải thưởng Giáo viên dạy giỏi cấp quận/huyện.",
    author_image: null,
  },
  {
    id: 84,
    author_name: "Nguyễn Đức Trường",
    stt: "084",
    author_description:
      "Thạc sĩ Giáo dục học, Giáo viên sáng tạo trên nền tảng công nghệ thông tin 2015; Chuyên gia giáo dục sáng tạo Microsoft 2017; Giáo viên Hoá học trường Phổ thông liên cấp Olympia từ năm 2010.",
    author_image: null,
  },
  {
    id: 85,
    author_name: "Trương Anh Tuấn",
    stt: "085",
    author_description:
      "Kĩ sư Đại học xây dựng, 28 năm kinh nghiệm làm thiết bị giáo dục, Trung tâm Bồi dưỡng giáo viên - Công ty Thiết bị giáo dục 1 - Bộ Giáo dục. Là chủ nhiệm nhiều đề tài cấp Bộ về Phát triển thiết bị thí nhiệm chuyên Vật lí THPT.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/085.png`,
  },
  {
    id: 86,
    author_name: "Đinh Quang Ngọc",
    stt: "086",
    author_description:
      "Phó giáo sư, Tiền sĩ (Tiền sĩ chuyên ngành Giáo dục thể chất và Huấn luyện thể thao). Viện trưởng Viện Khoa học và Công nghệ Thể dục thể thao, Trường Đại học Thể dục thể thao Bắc Ninh. Chủ biên sách “Bóng rổ trong trường phổ thông”, chủ biên và tham gia viết các giáo trình, sách tham khảo khác. Tham gia giảng dạy môn Bóng rổ, môn lí luận và phương pháp nghiên cứu khoa học cho các đối tượng sinh viên, học viên cao học và nghiên cứu sinh; tham gia giảng dạy chuyên đề cho các lớp bồi dưỡng giảng viên, huần luyện viên...",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/086.png`,
  },
  {
    id: 87,
    author_name: "Đinh Khánh Thu",
    stt: "087",
    author_description:
      "Phó giáo sư, Tiến sĩ chuyên ngành Giáo dục thể chất và Huấn luyện thể thao. Là chủ biên và tác giả các loại sách và giáo trình có liên quan đến lĩnh vực giáo dục thể chất và thể thao trường học như: Thể dục Aerobic, Thể dục cổ động, Thể dục thẩm mĩ, Khiêu vũ thể thao, Thể dục thể hình. Là tác giả của trên 30 bài báo khoa học trong đó có rất nhiều bài đã được công bố trên tạp chí nước ngoài. Là chủ nhiệm của 9 đề tài khoa học công nghệ cấp cơ sở, tham gia 2 đề tài khoa học công nghệ  cấp Bộ liên quan đến lĩnh vực Giáo dục thể chất, Huấn luyện thể thao và Thể thao trường học. Là chuyên gia trong sáng tác và biên soạn Thể dục đồng diễn trong các đại hội Thể dục thể thao từ cấp tỉnh, thành phố đến cấp trung ưng.",
    author_image: null,
  },
  {
    id: 88,
    author_name: "Mai Thị Bích Ngọc ",
    stt: "088",
    author_description:
      "Tiến sĩ Khoa học giáo dục, Phó Tổng biên tập Tạp chí Khoa học Đào tạo và Huấn luyện thể thao; Phó Giám đốc Trung tâm Thông tin - Thư viện Trường Đại học Thể dục thể thao Bắc Ninh. Tác giả nhiều sách giáo trình, sách tham khảo, bài báo Khoa học trong lĩnh vực Thể dục thể thao, đặc biệt là lĩnh vực Giáo dục thể chất. Độc lập nghiên cứu, chủ nhiệm và tham gia nghiên cứu nhiều đề tài khoa học công nghệ các\ncấp, luận án, luận văn về lĩnh vực Giáo dục thể chất và Thể thao Trường học.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/088.png`,
  },
  {
    id: 89,
    author_name: "Nguyễn Văn Thành",
    stt: "089",
    author_description:
      "Tiến sĩ Khoa học giáo dục, chuyên ngành Giáo dục thể chất, Giảng viên chính, Trưởng phòng Quản trị - Công tác Học sinh - Sinh viên, Trường Cao Đẳng Sư phạm Nghệ An. Là tác giả Chương trình Giáo dục phổ thông môn Giáo dục thể chất 2018; tác giả của tài liệu bồi dưỡng giáo viên “Hướng dẫn bồi dưỡng giáo viên sử dụng phương pháp dạy học và giáo dục phát triển phẩm chất, năng lực học sinh trung học cơ sở môn Giáo dục thể chút (Modun 2)”; báo cáo viên của Bộ Giáo dục và Đào tạo tập huấn cho “Tổ trưởng chuyên môn trường trung học về dạy học trong chương trình giáo dục phổ thông môn Thể dục lớp 9 hiện hành đáp ứng yêu cầu phát triển phẩm chất, năng lực học sinh”.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/089.png`,
  },
  {
    id: 90,
    author_name: "Đỗ Thanh Hiên",
    stt: "090",
    author_description:
      "Thạc sĩ chuyên ngành Lí thuyết và Lịch sử Âm nhạc, hội viên Hội Nhạc sĩ Việt Nam. Nguyên Giảng viên chính, Trưởng bộ môn Âm nhạc Trường Đại học Thủ đô Hà Nội; tác giả Chương trình Giáo dục phổ thông môn Âm nhạc 2018, tác giả sách giáo khoa Âm nhạc 1 (Bộ sách Cánh Diều 2019); tác giả nhiều giáo trình nghiên cứu, tham khảo về dạy học môn Âm nhạc.",
    author_image: null,
  },
  {
    id: 91,
    author_name: "Nguyễn Mai Anh",
    stt: "091",
    author_description:
      "Thạc sĩ Nghệ thuật âm nhạc, chuyên ngành Lí thuyết và Lịch sử âm nhạc. Hội viên Hội nhạc sĩ Việt Nam. Nguyên Phó khoa kiến thức Âm nhạc, nguyên Trưởng bộ môn Phân tích và Lịch sử Âm nhạc, giảng viên chính khoa Kiến thức âm nhạc Học viện Âm nhạc Quốc gia Việt Nam. Tham gia biên soạn nhiều giáo trình và tài liệu dạy học các môn Lịch sử Âm nhạc, Phân tích tác phẩm, Hình thức âm nhạc,....",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/091.png`,
  },
  {
    id: 92,
    author_name: "Nguyễn Quang Nhã",
    stt: "092",
    author_description:
      "Thạc sĩ chuyên ngành Lí luận và phương pháp giáo dục Âm nhạc. Phó Trưởng khoa Âm nhạc, giảng viên khoa Nghệ thuật Trường Cao đẳng Sư phạm Trung ương, hội viên Hội Nhạc sĩ Việt Nam.",
    author_image: null,
  },
  {
    id: 93,
    author_name: "Phạm Văn Tuyến",
    stt: "093",
    author_description:
      "Hoa sĩ, Tiến sĩ Nghệ thuật học; giảng viên sư phạm chủ chót, Trưởng Khoa Nghệ thuật, Trường Đại học Sư phạm Hà Nội; Chủ biên sách Âm nhạc, Mĩ thuật địa phương Nam Định; Tổng Chủ biên sách giáo khoa Mĩ thuật 1; Chủ biên sách Hướng dẫn dạy học Mĩ thuật THCS theo Chương trình Giáo dục phổ thông 2018; chuyên gia tập huấn giáo viên phổ thông cốt cán môn Mĩ thuật; Chủ nhiệm đề tài cắp Nhà nước “Nghiên cứu ứng dụng các tác phẩm mĩ thuật trong giáo dục giá trị truyền thông Việt Nam cho trẻ mầm non và học sinh tiểu học”.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/093.png`,
  },
  {
    id: 94,
    author_name: "Ngô Thị Hường",
    stt: "094",
    author_description:
      "Giáo viên Mĩ thuật, Giáo viên dạy giỏi cấp Thành phố Hà Nội, Thanh tra chuyên môn Phòng Giáo dục quận Nam Từ Liêm; tham gia Chương trình thí điểm sách giáo khoa Mĩ thuật 6, 7, 8, 9 của Bộ Giáo dục và Đào tạo; đã thực hiện một số dự án dạy học và hoạt động mĩ thuật trong nhà trường THCS và THPT.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/094.png`,
  },
  {
    id: 95,
    author_name: "Nguyễn Duy Khuê",
    stt: "095",
    author_description:
      "Thạc sĩ, Phó Hiệu trưởng Trường Phổ thông liên cấp Newton; tham gia Dự án giáo dục về 17 mục tiêu phát triển bền vững do Bộ Kế hoạch và Đầu tư và Cơ quan hợp tác phát triển Đức (GIZ) tổ chức; tham gia viết chương trình mĩ thuật tạo hình trung tâm bồi dưỡng kĩ năng Smile's House, Eveil, Tập đoàn giáo dục Mastermind; tham gia Dự án giáo dục kĩ năng sống Pro Kids; giải Nhất giáo viên giỏi Môn Mĩ thuật Quận Bắc Từ Liêm; tham gia biên soạn sách Hướng dẫn dạy học mĩ thuật THCS theo Chương trình Giáo dục phố thông 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/095.png`,
  },
  {
    id: 96,
    author_name: "Nguyễn Thị Hồng Thắm",
    stt: "096",
    author_description:
      "Thạc sĩ, giảng viên Khoa Nghệ thuật, Trường Đại học Sư phạm Hà Nội; tham gia biên soạn sách hướng dẫn dạy học mĩ thuật THCS theo Chương trình Giáo dục phổ thông 2018; Chủ nhiệm đề tài cấp Bộ “Giáo dục bảo vệ môi trường cho học sinh THCS thông qua hoạt động mĩ thuật trong nhà trường”; thành viên đề tài cắp Nhà nước “Nghiên cứu ứng dụng các tác phẩm mĩ thuật trong giáo dục giá trị truyền thống Việt Nam cho trẻ mầm non và học sinh tiểu học”; biên soạn tài liệu bồi dưỡng giáo viên phổ thông cốt cán cho Chương trình ETEP về tìm hiểu Chương trình Mĩ thuật phổ thông 2018 và kiểm tra, đánh giá trong giáo dục môn Mĩ thuật theo hướng phát triển phẩm chất, năng lực học sinh; báo cáo viên tập huắn giáo viên phổ thông cốt cán môn Mĩ thuật cho Chương trình ETEP và Dự án RGEP.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/096.png`,
  },
  {
    id: 97,
    author_name: "PGS.TS Hồ Sĩ Đàm",
    stt: "097",
    author_description:
      "Phó Giáo sư, Tiến sĩ, Nhà giáo Nhân dân, giảng viên cao cấp Đại học Quốc gia Hà Nội; Chủ biên, tác giả/đồng tác giả Chương trình và sách giáo khoa Tin học phân ban, sách giáo khoa, sách giáo viên và sách bài tập các lớp 10, 11, 12 (Chương trình 2006); Chương trình và bộ tài liệu giáo khoa, sách bài tập cho Chuyên Tin học các lớp 10, 11, 12 (Chương trình 2006). Nhiều năm tham gia đào tạo bồi dưỡng đội tuyển học sinh giỏi quốc gia, quốc tế môn Tin học và làm Trưởng đoàn học sinh Việt Nam tham dự Olympic Tin học quốc tế. Chủ biên, tác giả Chương trình Giáo dục phổ thông môn Tin học 2018; Tham gia tập huấn cho giảng viên Tin học các trường Đại học Sư phạm và giáo viên cốt cán môn Tin học các cơ sở giáo dục về Chương trình Giáo dục phổ thông môn Tin học 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/097.png`,
  },
  {
    id: 98,
    author_name: "PGS.TS Hồ Cẩm Hà",
    stt: "098",
    author_description:
      "Phó Giáo sư, Tiến sĩ, Nhà giáo Ưu tú, nguyên Trưởng khoa Công nghệ thông tin, Trường Đại học Sư phạm Hà Nội; Chủ biên Bộ sách Hướng dẫn học Tin học lớp 6, lớp 7, lớp 8, lớp 9 mô hình trường học mới (VNEN, 2014 - 2017); Đồng tác giả sách giáo khoa, sách giáo viên Tin học cấp THPT các lớp 1O, 11, 12 (Chương trình 2006); Tổng Chủ biên sách Hướng dẫn dạy học môn Tin học THCS theo Chương trình Giáo dục phổ thông 2018 (Nhà xuất bản Đại học Sư phạm 2019). Thành viên Ban phát triển Chương trình Giáo dục phổ thông môn Tin học 2018; tác giả Bộ Tài liệu tập huấn Chương trình Giáo dục phổ thông môn Tin học 2018.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/098.png`,
  },
  {
    id: 99,
    author_name: "PGS.TS Nguyễn Đình Hoá",
    stt: "099",
    author_description:
      "Phó Giáo sư, Tiến sĩ, nguyên Phó Viện trưởng Viện Công nghệ thông tin thuộc Đại học Quốc gia Hà Nội; nguyên chủ nghiệm khoa Công nghệ thông tin và Hiệu trưởng trường Đại học Quốc tế Bắc; Tác giả/đồng tác giả một số sách, giáo trình về tin học.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/099.png`,
  },
  {
    id: 100,
    author_name: "TS Nguyễn Thế Lộc",
    stt: "100",
    author_description:
      "Tiến sĩ, Giảng viên khoa Công nghệ thông tin, Trường Đại học Sư phạm Hà Nội; thành viên Ban phát triển Chương trình Giáo dục phổ thông môn Tin học 2018; Chủ biên, đồng tác giả nhiều sách giáo khoa và sách tham khảo môn Tin học. Hướng dẫn học Tin học mô hình VNEN các lớp 6, 7, 8, 9; Bài tập Tin học 10; Mạng máy tính (sách giáo trình); Ngôn ngữ lập trình C (sách giáo trình);...",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/100.png`,
  },
  {
    id: 101,
    author_name: "TS Phạm Thị Anh Lê",
    stt: "101",
    author_description:
      "Tiến sĩ, Giảng viên Trường Đại học Sư phạm Hà Nội; Chủ biên sách Giáo trình Tin học đại cương tập 1, Giáo trình Tin học đại cương tập 2, Giáo trình Tin học đại cương tập 3; Tác giả của nhiều bộ sách tham khoả và hướng dẫn dạy học Tin học;.....",
    author_image: null,
  },
  {
    id: 102,
    author_name: "TS Nguyễn Chí Trung",
    stt: "102",
    author_description:
      "Tiến sĩ, Trưởng bộ môn Phương pháp dạy học, khoa Công nghệ thông tin, Trường Đại học Sư phạm Hà Nội; Chủ biên và tác giả của nhiều sách tham khảo về dạy và học môn Tin học như: Hướng dẫn học Tin học (lớp 6, lớp 7, lớp 8), Giáo trình Tin học đại cương (tập 2, tập 3), Giáo trình lập trình hướng đối tượng với C++, Thuật toán và kĩ thuật lập trình Pascal; Tin học cơ sở; Tin học cho giáo viên; Bài tập Tin học 10 và 11;....",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/102.png`,
  },
  {
    id: 103,
    author_name: "Nguyễn Viết Thịnh",
    stt: "103",
    author_description:
      "Giáo sư, Tiến sĩ, Nhà giáo Ưu tú; nguyên Hiệu trưởng Trường Đại học Sư phạm Hà Nội; giảng viên cao cấp tại khoa Địa lí, Trường Đại học Sư phạm Hà Nội; là tác giả Chương trình Giáo dục phổ thông năm 2018 môn Địa lí; tác giả sách giáo khoa, sách giáo viên Địa lí 10 (năm 1990 - 2005) và Địa lí 12 (năm 1992 - 2007); đồng tác giả sách giáo khoa, sách giáo viên Địa lí 11 Ban Khoa học tự nhiên và Ban Khoa học kĩ thuật (năm 1994 - 2000), đồng tác giả sách giáo khoa và sách giáo viên Địa lí 12 Ban Khoa học xã hội (năm 1996 - 2000); tác giả sách giáo khoa Cơ bản và Nâng cao, sách giáo viên Địa lí 10, Chủ biên kiêm tác giả sách giáo khoa Cơ bản và Nâng cao Địa lí 12, Chủ tịch Hội đồng thẩm định sách giáo khoa Địa lí 7 (Chương trình 2006); Chủ tịch Hội đồng bộ môn Địa lí của Bộ Giáo dục và Đào tạo (năm 2000 - 2003); chủ trì trong biên soạn các chương trình đào tạo giáo viên Địa lí cho khối các trường sư phạm, cũng như Chương trình chung đào tạo giáo viên THCS (Bộ Giáo dục và Đào tạo); có kinh nghiệm 30 năm là Chủ biên và tác giả viết sách giáo khoa, sách bồi dưỡng giáo viên, sách tham khảo cho giáo viên và học sinh về Địa lí.",
    author_image: null,
  },
  {
    id: 104,
    author_name: "Đỗ Thị Minh Đức",
    stt: "104",
    author_description:
      "Giáo sư, Tiền sĩ chuyên ngành Địa lí kinh tế và chính trị; nguyên Phó trưởng khoa Địa lí, Trường Đại học Sư phạm Hà Nội; Chủ biên kiêm tác giả sách giáo khoa, sách giáo viên, sách bài tập Địa lí 9 (Chương trình 2006); Chủ biên các giáo trình cơ bản về Địa lí kinh tế - xã hội Việt Nam bậc đại học, cao đẳng; Chủ tịch Hội đồng thẩm định sách giáo khoa Địa lí của Bộ Giáo dục và Đào tạo (Chương trình 2006); thành viên chính biên soạn các chương trình đào tạo giáo viên Địa lí cho khối các trường sư phạm, cũng như Chương trình chung đào tạo giáo viên THCS (Bộ Giáo dục và Đào tạo); có kinh nghiệm 30 năm viết sách bồi dưỡng giáo viên, sách tham khảo cho giáo viên và học sinh, sách giáo khoa về Địa lí kinh tế - xã hội Việt Nam và các vấn đề địa lia kinh tế - xã hội nói chung.",
    author_image: null,
  },
  {
    id: 105,
    author_name: "Kiều Văn Hoan",
    stt: "105",
    author_description:
      "Phó Giáo sư, Tiến sĩ chuyên ngành Lí luận và phương pháp giảng dạy Địa lí; giảng viên cao cấp, Phó Trưởng khoa Địa lí, Trường Đại học Sư phạm Hà Nội; tác giả biên soạn tài liệu hướng dẫn thực hiện Chương trình Giáo dục phổ thông 2018 môn Lịch sử và Địa lí, biên soạn tài liệu kiểm tra đánh giá theo hướng phát triển năng lực học sinh ở trường phổ thông và nhiêu tài liệu tham khảo, giáo trình giảng dạy ở trường đại học, cao đẳng, trường phổ thông; là chủ nhiệm, tham gia nhiều để tài khoa học các: cập Bọ, cấp tỉnh, cấp Nhà nước về lĩnh vực giáo dục địa lí, tham gia tập huấn, bồi dưỡng giáo viên thường xuyên trong lĩnh vực đổi mới phương pháp dạy học, ứng dụng công nghệ thông tin, đổi mới kiểm tra, đánh giá học sinh ở trường phổ thông.",
    author_image: null,
  },
  {
    id: 106,
    author_name: "Ngô Thị Hải Yến",
    stt: "106",
    author_description:
      "Phó Giáo sư, Tiến sĩ về Giáo dục, chuyên ngành Lí luận và Phương pháp dạy học bộ môn Địa lí; hiện đang là giảng viên, Trưởng bộ môn Phương pháp dạy học, khoa Địa lí, Trường Đại học Sư phạm Hà Nội; tham gia một số Hội đồng thẩm định sách và tài liệu giảng dạy môn Địa lí của Bộ giáo dục và Đào tạo; tham gia xây dựng và thẩm định chương trình giáo dục cho một số trường phổ thông và là tác giả của nhiều sách tham khảo về dạy học môn Địa lí ở trường đại học, cao đẳng, trường phổ thông.",
    author_image: null,
  },
  {
    id: 107,
    author_name: "Đỗ Thanh Bình",
    stt: "107",
    author_description:
      "Giáo sư, Tiến sĩ, Nhà giáo Nhân dân; nguyên Trưởng Khoa Lịch sử,Trường Đại học Sư phạm Hà Nội;Chủ biên và tác giả của gần 40 đầu sách về khoa học Lịch sử và Khoa học Giáo dục; tác giả sách giáo khoa Lịch sử lớp 12 (Chương trình 2006); Chủ tịch Hội đồng thẩm định Chương trình môn Lịch sử THPT 2018; tham gia xây dựng chương trình THPT Chuyên môn Lịch sử (năm 2019). Nhiễu năm tham gia bồi dưỡng kiến thức cho các đội tuyển quốc gia môn Lịch sử. Huân chương Lao động hạng Ba (năm 2010) và nhiều Bằng khen của Thủ tướng Chính phủ và Bộ trưởng Bộ Giáo dục và Đào tạo.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/107.png`,
  },
  {
    id: 108,
    author_name: "Trần Viết Lưu",
    stt: "108",
    author_description:
      "Phó Giáo sư, Tiến sĩ; được đào tạo chuyên ngành Lịch sử tại Đại học Vinh (tốt nghiệp năm 1982). Từ năm 1982 đến năm 1994 là giáo viên sư phạm tại Thanh Hoá. Tác giả sách giáo khoa môn Tự nhiên và Xã hội 5 (phần Lịch sử và Địa lí -Chương trình 2006); tác giả một số tài liệu tham khảo cho giáo viên môn Lịch sử phổ thông; Chủ biên và đồng tác giả của nhiều sách về Đảng và Chủ tịch Hỗ Chí Minh...",
    author_image: null,
  },
  {
    id: 109,
    author_name: "Nguyễn Văn Ninh",
    stt: "109",
    author_description:
      "Sinh ngày 27/7/1977; Tiên sĩ (Trường Đại học Bordeaux 3 - Nước Cộng Hoà Pháp);Trưởng bộ môn Lí luận và Phương pháp dạy học bộ môn Lịch sử, Khoa Lịch sử, Trường Đại học Sư phạm Hà Nội. Tác giả bộ sách Lịch sử và Địa lí THCS Mô hình Trường học mới (VNEN); Chủ biên và tác giả nhiều sách chuyên khảo và tham khảo về dạy và học môn Lịch sử ở trường phổ thông; Giảng dạy nghiên cứu sinh, Thạc sĩ, cử nhân sư phạm; Báo cáo viên của Bộ Giáo dục và Đào tào trong nhiều năm về đổi mới phương pháp dạy học và kiểm tra đánh giá trong dạy học Lịch sử ở trường phổ thông",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/109.png`,
  },
  {
    id: 110,
    author_name: "Phạm Thị Kim Anh",
    stt: "110",
    author_description:
      "Sinh ngày 27/9/1965, Tiến sĩ Giáo dục học, chuyên ngành Lí luận và Phương pháp dạy học lịch sử, giám đốc Trung tâm Nghiên cứu giáo viên, Trường Đại học Sư phạm Hà Nội. Từ năm 2005 là tác giả sách: Tập bản đồ -Tranh ảnh bài tập Lịch sử 6 (2005), Ôn luyện và kiểm tra Lịch sử 9 (2007); Giáo dục địa phương tỉnh Cao Bằng lớp 1 (2020) và là tác giả nhiêu sách tham khảo về dạy và học môn Lịch sử ở Tiểu học và THPT, đồng thời là giảng viên cốt cán tham gia bồi dưỡng, tập huấn giáo viên phổ thông các cấp trong các cuộc đổi mới chương trình - sách giáo khoa từ sau năm 2000 đến nay.",
    author_image: null,
  },
  {
    id: 111,
    author_name: "Nguyễn Văn Dũng",
    stt: "111",
    author_description:
      "Giảng viên chính, Tiến sĩ Lịch sử, Trường Khoa Lịch sử, Trường Đại học Sư phạm Hà Nội 2. Là Chủ biên và tác giả 4 đầu sách, giáo trình về lĩnh vực Lịch sử; tham gia 1 đề tài khoa học cấp Quốc gia, chủ nhiệm 2 đề tài cấp Bộ; đã công bố 20 bài báo khoa học; hướng dẫn 03 thạc sĩ; được tuyên dương là nhà giáo tiêu biểu toàn quốc năm 2016.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/111.png`,
  },
  {
    id: 112,
    author_name: "Nguyễn Phùng Tám",
    stt: "112",
    author_description:
      "Sinh năm 1983, Tiến sĩ Giáo dục học. Giảng viên Khoa Sư phạm,Trường Đại học Giáo dục, Đại học Quốc gia Hà Nội. Hướng nghiên cứu chính: (1) Đổi mới chương trình và sách giáo khoa môn Lịch sử; (2) Liên môn- tích hợp trong học tập Lịch sử; (3) Xây dựng, sử dụng học liệu môn Lịch sử; (4) Đổi mới kiểm tra, đánh giá môn Lịch sử theo hướng hình thành và phát triển phẩm chất, năng lực học sinh.",
    author_image: null,
  },
  {
    id: 113,
    author_name: "Nguyễn Tất Thắng",
    stt: "113",
    author_description:
      "Tiến sĩ Khoa học giáo dục, Giảng viên chính, Trưởng ban Công tác chính trị và Công tác sinh viên, Chủ tịch Công đoàn Học viện Nông nghiệp Việt Nam; đồng tác giả sách Hướng dẫn học Công nghệ 7, Hướng dẫn học Công nghệ 9; tác giả của một số sách, giáo trình, bài báo, tài liệu về giáo dục công nghệ, phương pháp dạy học sinh học ở trưởng phổ thông.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/113.png`,
  },
  {
    id: 114,
    author_name: "Trần Thị Lan Hương",
    stt: "114",
    author_description:
      "Phó Giáo sư, Tiến sĩ Dinh dưỡng cộng đồng, Giảng viên cao cấp, Phó trưởng khoa Công nghệ thực phẩm, Học viện Nông nghiệp Việt Nam; tác giả, đồng tác giả của một số sách, giáo trình về lĩnh vực dinh dưỡng, công nghệ thực phẩm.",
    author_image: null,
  },
  {
    id: 115,
    author_name: "Hoàng Xuân Anh",
    stt: "115",
    author_description:
      "Thạc sĩ Kĩ thuật, Giảng viên chính, Phó Trưởng Bộ môn Thiết bị bảo quản và Chế biến nông sản, Học viện Nông nghiệp Việt Nam; tác giả, đồng tác giả của một số sách, giáo trình về lĩnh vực cơ điện.",
    author_image: null,
  },
  {
    id: 116,
    author_name: "Nguyễn Thị Thanh Huệ",
    stt: "116",
    author_description:
      "Thạc sĩ Quản lí giáo dục, Giảng viên, Trường khoa Thời trang, Trường Đại học Công nghiệp Dệt May Hà Nội.",
    author_image: null,
  },
  {
    id: 117,
    author_name: "Bùi Thị Hải Yến",
    stt: "117",
    author_description:
      "Thạc sĩ Đo lường và Đánh giá trong giáo dục, Giảng viên Khoa Sư phạm và Ngoại ngữ, Học viện Nông nghiệp Việt Nam.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/117.png`,
  },
  {
    id: 118,
    author_name: "Nguyễn Đức Sơn",
    stt: "118",
    author_description:
      "PGS.TS Tâm lí học Trưởng Khoa Tâm lí - Giáo dục, Trường Đại học Sư phạm Hà Nội. Tốt nghiệp đại học tại Liên Bang Nga; thực tập sinh tại Đại học Hofstra (Hoa Kỹ); Nhận bằng Tiến sĩ Tâm lí học tại Trường Đại học Sư Phạm Hà Nội. Chủ trì các công trình nghiên cứu về Tâm lí học giáo dục, Tâm lí học nhân cách, Tâm lí học phát triển trong nước; hợp tác nghiên cứu với các tổ chức quốc tế (Kent University - UK, Hofstra - Hoa Kỳ, National Institute of Health (NIH - Hoa Kỳ). Chủ trì và tham gia xây dựng các chương trình đào tạo, biên soạn giáo trình và tài liệu bồi dưỡng giáo viên thường xuyên. Tác giả các sách về Ứng xử văn hóa trong nhà trường; Xây dựng môi trường an toàn, thân thiện, bình đẳng, phòng chống bạo lực giới trong nhà trường; Nhà trường hạnh phúc; Hoạt động trải nghiệm, hướng nghiệp.",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/118.png`,
  },
  {
    id: 119,
    author_name: "Đàm Thị Vân Anh",
    stt: "119",
    author_description:
      "Tốt nghiệp chuyên ngành Tâm lí - giáo dục tại Trường Đại học Sư phạm Tổng hợp Quốc gia Voronezh, Liên bang Nga, Thạc sĩ Giáo dục học tại Trường Đại học Sư phạm Hà Nội, hiện đang làm nghiên cứu sinh; giảng viên chính khoa Tâm lí Giáo dục, Trường Đại học Sư phạm Hà Nội; tác giả giáo trình giảng dạy cho sinh viên, sách chuyên khảo, tài liệu tham khảo, tài liệu bồi dưỡng, tập huấn giáo viên phổ thông, bài báo khoa học, để tài nghiên cứu về giáo dục gia đình, giáo dục kĩ năng sống, giáo dục giá trị..; báo cáo viên các lớp tập huấn, bồi dưỡng giáo viên phổ thông về công tác giáo viên chủ nhiệm lớp, tổ chức hoạt động giáo dục cho học sinh như giáo dục kĩ năng sống, giáo dục giá trị sống, giáo dục giới tính - sức khỏe sinh sản,...",
    author_image: "v",
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/intro/authors/119.png`,
  },
  {
    id: 120,
    author_name: "Bùi Thanh Xuân",
    stt: "120",
    author_description:
      "Chuyên ngành Tâm lí học giáo dục tại Trường Đại học Sư phạm Hà Nội 1; Thạc sĩ Giáo dục học\ntại Đại học Monash, Autralila; Nghiên cứu viên Viện khoa học giáo dục Việt Nam; Chủ biên, tác giả và đồng tác giả nhiều sách, tài liệu tham khảo, tài liệu bồi dưỡng giáo viên, bài báo khoa học và để tài nghiên cứu về giáo dục kĩ năng sống, hoạt động giáo dục ngoài giờ lên lớp, giáo dục giá trị,... Chủ trì và đồng tổ chức nhiều khóa tập huấn, đào tạo giáo viên và học sinh phổ thông về hoạt động giáo dục ngoài giờ lên lớp, giáo dục kỹ năng sống, quyền trẻ em, giáo dục về bình đẳng giới và sức khoẻ sinh sản.",
    author_image: null,
  },
];
