export const plans = [
  {
    time: "2020 - 2021",
    icon: "/assets/img/icon-star.svg",
    grades: ["Lớp 1"],
    active: true,
  },
  {
    time: "2021 - 2022",
    icon: "/assets/img/icon-star.svg",
    grades: ["Lớp 2", "Lớp 6"],
    active: true,
  },
  {
    time: "2022 - 2023",
    icon: "/assets/img/icon-star.svg",
    grades: ["Lớp 3", "Lớp 7", "Lớp 10"],
    active: true,
  },
  {
    time: "2023 - 2024",
    icon: "/assets/img/icon-star.svg",
    grades: ["Lớp 4", "Lớp 8", "Lớp 11"],
    active: true,
  },
  {
    time: "2024 - 2025",
    icon: "/assets/img/icon-cd.svg",
    grades: ["Lớp 5", "Lớp 9", "Lớp 12"],
    active: false,
  },
];
