import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStateMachine } from "little-state-machine";
import classNames from "classnames";
import { updateState } from "./store";
import * as TYPE from "../../../edu_lms/constants/type";

const schema = yup
  .object()
  .shape({
    job_id: yup.string().required(),
  })
  .required();

export default function Step1({ goToNextStep, handleChangeJob, setCheckJob }) {
  const { actions, state } = useStateMachine({ updateState });
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      job_id: state.job_id,
    },
  });
  const watchJob = watch("job_id");

  const onSubmit = (data) => {
    actions.updateState(data);
    goToNextStep();
    setCheckJob(data);
  };

  return (
    <div className="container">
      <h1 className="title">Bạn là ai?</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="box-choose flex-center">
          <div
            className={classNames("object bg-sh", {
              active: watchJob == TYPE.JOB_TEACHER,
            })}
          >
            <label>
              <span className="object__avt">
                <img src="/assets/img/avt-gv.svg" alt="Giáo viên" />
              </span>
              <span className="object__name">Giáo viên</span>
              <input
                type="radio"
                value={TYPE.JOB_TEACHER}
                name="job_id"
                ref={register}
                onChange={() => handleChangeJob(TYPE.JOB_TEACHER)}
              />
              <span className="checked"></span>
            </label>
          </div>
          <div
            className={classNames("object bg-sh", {
              active: watchJob == TYPE.JOB_STUDENT,
            })}
          >
            <label className="active">
              <span className="object__avt">
                <img src="/assets/img/avt-student.svg" alt="Học sinh" />
              </span>
              <span className="object__name">Học sinh</span>
              <input
                type="radio"
                value={TYPE.JOB_STUDENT}
                name="job_id"
                ref={register}
                onChange={() => handleChangeJob(TYPE.JOB_STUDENT)}
              />
              <span className="checked"></span>
            </label>
          </div>
          {/* <div
            className={classNames("object bg-sh", {
              active: watchJob == TYPE.JOB_PARENTS,
            })}
          >
            <span className="object__avt">
              <img src="/assets/img/avt-ph1.svg" alt="Phụ huynh" />
            </span>
            <span className="object__name">Phụ huynh</span>
            <label>
              <input
                type="radio"
                value={TYPE.JOB_PARENTS}
                name="job_id"
                ref={register}
                onChange={() => handleChangeJob(TYPE.JOB_PARENTS)}
              />
              <span className="checked"></span>
            </label>
          </div> */}
        </div>
        <button
          type="submit"
          className={classNames("btn-next flex-center btn-pr", {
            active: isValid,
          })}
          disabled={!isValid}
        >
          Tiếp theo
        </button>
      </form>
    </div>
  );
}
