import { useState } from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "reactstrap";
import styled from "styled-components";
import classNames from "classnames";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { ROUTE_PATH_V3_LIST_SLIDE } from "consts/routePaths";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  postDownloadSlideLibrary,
  postUpdateSlideLibrary,
} from "edu_lms/services/slide";
import { useAuthStore } from "../../../../stores/authStore";

export default function Header(props) {
  const history = useHistory();
  const { subject, grade } = useParams();

  const [tooltipPresent, setTooltipPresent] = useState(false);
  const togglePresent = () => setTooltipPresent(!tooltipPresent);
  const [tooltipError, setTooltipError] = useState(false);
  const toggleError = () => setTooltipError(!tooltipError);
  const [tooltipDownload, setTooltipDownload] = useState(false);
  const toggleDownload = () => setTooltipDownload(!tooltipDownload);
  const [showLoadingDownload, setStateShowLoadingDownload] = useState(false);
  const [isActiveToggle, setActiveToggle] = useState(false);
  const openLoginModal = useAuthStore((state) => state.openLoginModal);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const fullCurrentUrl = window.location.href;

  const handleBackPageListSlide = () => {
    history.push(`${ROUTE_PATH_V3_LIST_SLIDE}${subject}/${grade}/`)
  }
  const toggleToggle = () => {
    setActiveToggle(!isActiveToggle);
  };

  const showPopupPresent = () => {
    props.onShowPopupPresent();
  };

  const showPopupError = () => {
    props.onShowPopupError();
  }

  const downloadFile = () => {
    if (!isLoggedIn) {
      // setShowPopupContent(true);
      // setTargetRoute(url);
      openLoginModal();
      return;
    }
    
    setStateShowLoadingDownload(true);
    const data = {
      slideId: props.slideId,
      typeUpdate: 3,
    };
    postDownloadSlideLibrary(data)
      .then((res) => {
        if (res.data.status === "success") {
          const a = document.createElement("a");
          a.href = res.data.data.url;
          a.download = res.data.data.url.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          toast.success("Tải xuống bài giảng thành công");

          postUpdateSlideLibrary(data)
            .then((res) => {})
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(res.data.message);
        }
        setStateShowLoadingDownload(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyLink = () => {
    const data = {
      slideId: props.slideId,
      typeUpdate: 4,
    };
    postUpdateSlideLibrary(data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
    toast.success("Copy link thành công!");
  };

  const share = () => {
    const data = {
      slideId: props.slideId,
      typeUpdate: 2,
    };
    postUpdateSlideLibrary(data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="header">
      <div className="flex-lc h-100 ml-3 mr-5">
        <div title="" className="btn-back flex-lc">
          <i
            onClick={() => handleBackPageListSlide()}
            className="icon icon-back cursor"
          ></i>
          <h1 className="text">{props.title}</h1>
        </div>
        <div className="box-btn flex-lc">
          <i
              id="tooltip-error"
              onClick={() => showPopupError()}
              className="cursor fa fa-exclamation-triangle"
              style={{
                marginRight: "9px",
                fontSize: "1.4em",
              }}
          />
          <Tooltip
              isOpen={tooltipError}
              target="tooltip-error"
              toggle={toggleError}
              placement="top"
          >
            Báo lỗi
          </Tooltip>
          <button
              type="button"
              className="btn-play flex-center"
              onClick={showPopupPresent}
              id="tooltip-present"
          >
            <i className="icon icon-play"></i> Trình chiếu
          </button>
          <Tooltip
              isOpen={tooltipPresent}
              target="tooltip-present"
              toggle={togglePresent}
          >
            Trình chiếu bản xem nhanh
          </Tooltip>
          <button
              onClick={downloadFile}
              type="button"
              className="btn-download flex-center"
              id="tooltip-download"
          >
            {showLoadingDownload ? (
                <div className="loader"></div>
            ) : (
                <>
                  <i className="icon icon-download" aria-hidden="true"></i> Tải
                                                                            xuống
                </>
            )}
          </button>
          {" "}
          <Tooltip
              isOpen={tooltipDownload}
              target="tooltip-download"
              toggle={toggleDownload}
          >
            Tải xuống bản đầy đủ
          </Tooltip>
          <button
              type="button"
              className="btn-share flex-center"
              id="btnShare"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
          >
            <i className="icon icon-share"></i>
          </button>
          <div className="dropdown-menu" aria-labelledby="share">
            <p className="dropdown-item" onClick={(e) => share()}>
              <FacebookShareButton
                  url={fullCurrentUrl}
                  quote={props.title}
                  hashtag={"#hoc10.vn"}
                  description={props.title}
                  className="hoc10"
              >
                <FacebookIcon size={32} round/> Facebook
              </FacebookShareButton>
            </p>
            <CopyToClipboard text={fullCurrentUrl} onCopy={() => copyLink()}>
              <PStyle className="cursor">
                {" "}
                <IconCopyLink className="fa fa-link" aria-hidden="true"/> Copy
                link
              </PStyle>
            </CopyToClipboard>
          </div>
        </div>
        <div
            className={classNames("dropdown", {
              show: isActiveToggle,
            })}
            onClick={toggleToggle}
        >
          <button type="button" className="btn-dropdown"></button>
          <div className="dropdown__menu">
            <a
                onClick={downloadFile}
                type="button"
                className="btn-download flex-center"
              id="tooltip-download"
            >
              {showLoadingDownload ? (
                <div className="loader"></div>
              ) : (
                <>
                  <i className="icon icon-download" aria-hidden="true"></i> Tải
                  xuống
                </>
              )}
            </a>{" "}
            <PStyles
              className="dropdown-item flex-lc justify-content-between"
              onClick={(e) => share()}
            >
              <FacebookShareButton
                url={fullCurrentUrl}
                quote={props.title}
                hashtag={"#hoc10.vn"}
                description={props.title}
                className="hoc10"
              >
                <FacebookIcon className="ml-2" size={24} round />{" "}
                <H1Style className="ml-1 ">Chia sẻ</H1Style>
              </FacebookShareButton>
            </PStyles>
          </div>
        </div>
      </div>
    </div>
  );
}

const IconCopyLink = styled.i`
  background: #bb0000;
  color: #fff;
  padding: 8px 8px;
  border-radius: 50px;
  margin-left: 22px;
`;
const PStyle = styled.p`
  margin-left: 6px;
  padding: 2px;
  &:hover {
    background: #e9ecef;
  }
`;
const PStyles = styled.p`
  padding: 12px 0;
`;
const H1Style = styled.h1`
  color: #fff;
  font-size: 16px;
`;
