import React, { useState, useRef } from "react";
import {
  GameContainer,
  GameReportAnswer,
  FooterQuestion,
} from "edu_lms_v2/libraries/hoc10Game/components";
import { CTA_POPUP_TYPE } from "edu_lms_v2/libraries/hoc10Game/constants";
import * as Games from "edu_lms_v2/libraries/hoc10Game/games";
import { formatActivityDataFIBMath } from "../hoc10Game/helpers/FIBMath";
import { getNumberOfInputs } from "../hoc10Game/helpers/FIB";
import { handleTrackingErrorActivity } from "../hoc10Game/helpers";
import { postHistoryGame } from "edu_lms/services/readingBook";
import { ErrorView } from "./components";

const FIBMathBook = ({ activity, bookId }) => {
  const gameRef = useRef();
  const formattedActivity = handleTrackingErrorActivity(formatActivityDataFIBMath)(activity);

  const [indexQuestion, setIndexQuestion] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [totalCorrectAnswer, setTotalCorrectAnswer] = useState(0);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [resultAnswers, setResultAnswers] = useState(
    Array(formattedActivity.error ? 0 : formattedActivity.length).fill(null)
  );

  const handlePlayGame = () => {
    setDisabledButton(false);
  };

  const handleCheckAnswerResult = () => {
    setIsCheckedAnswer(true);
    gameRef.current.handleCheck();
    gameRef.current.handleOnlyView();
  };

  const handleCompleteQuestion = ({ isCorrect, totalCorrectAnswer, inputtedAreas }) => {
    setTotalCorrectAnswer(totalCorrectAnswer);

    const resultAnswersShadow = [...resultAnswers];
    resultAnswersShadow[indexQuestion] = isCorrect;
    setResultAnswers(resultAnswersShadow);
    // Post history game on book when completed
    if (bookId) {
      const historyGameData = {
        objectId: bookId,
        gameId: activity.game_id,
        dataV1: JSON.stringify({
          isCorrect,
          totalCorrectAnswer,
          inputtedAreas,
          activity,
          indexQuestion,
        }),
      };
      postHistoryGame(historyGameData);
    }
  };

  const handleNextQuestion = () => {
    setIsCheckedAnswer(false);
    gameRef.current.handleReset();
    setIndexQuestion(indexQuestion + 1);
    setDisabledButton(true);
  };

  const handleResetAnswerResult = () => {
    setIsCheckedAnswer(false);
    gameRef.current && gameRef.current.handleReset && gameRef.current.handleReset();
    setIndexQuestion(0);
    setDisabledButton(true);
    setResultAnswers(Array(formattedActivity.length).fill(null));
  };

  if (formattedActivity.error) {
    return <ErrorView />;
  }

  if (indexQuestion === formattedActivity.length) {
    return (
      <GameContainer activity={activity}>
        <GameReportAnswer
          typeReport={CTA_POPUP_TYPE.finalReport}
          total={formattedActivity.length}
          correctTotal={resultAnswers.filter((result) => result).length}
          onResetAnswer={handleResetAnswerResult}
        />
      </GameContainer>
    );
  }

  const totalAnswer = getNumberOfInputs(formattedActivity[indexQuestion].answer.answers);

  return (
    <GameContainer activity={activity}>
      <Games.FIBMath
        ref={gameRef}
        gameData={formattedActivity[indexQuestion]}
        onPlaying={handlePlayGame}
        onComplete={handleCompleteQuestion}
      />
      <FooterQuestion
        resultAnswers={resultAnswers}
        indexQuestion={indexQuestion}
        disabledButton={disabledButton}
        onCheckAnswer={handleCheckAnswerResult}
        onNextQuestion={handleNextQuestion}
        onResetAnswer={handleResetAnswerResult}
      />
      {isCheckedAnswer && (
        <GameReportAnswer
          typeReport={CTA_POPUP_TYPE.rangeOneGame}
          total={totalAnswer}
          correctTotal={totalCorrectAnswer}
        />
      )}
    </GameContainer>
  );
};

export default FIBMathBook;
