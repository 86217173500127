import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import { useState } from "react";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OPEN_LECTURE_INSTRUCTIONS } from "edu_lms/constants/eventNameGTM";

const url_download =
  "https://hoc10.monkeyuni.net/E_Learning/document/Hoc10___HDSD_ba%CC%80i_gia%CC%89ng_(T3_2024).pdf";
const url_download_font =
  "https://drive.google.com/drive/folders/12fw1HAALTn7ZfrRofLd1WQGhvM0ULf_g";
export default function Intro() {
  const [url, setUrl] = useState("");

  const handleInstructionDownload = () => {
    window.open(url_download);
  };

  const handleInstructionDownloadFont = () => {
    window.open(url_download_font);
  };

  return (
    <>
      <VideoPlayerModal
        show={Boolean(url)}
        url={url}
        onClose={() => setUrl("")}
      />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Bài giảng
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-intro hoc10-intro--2">
        <div className="container">
          <div className="text-intro decor-h3 pr">
            <h1 className="title">Bài giảng</h1>
            <p className="new-text">
              Góc chia sẻ bài giảng điện tử là nơi Hoc10 đăng tải các bài giảng được xây dựng bởi một đội ngũ giáo viên giỏi, dày dặn kinh nghiệm với mục đích tạo ra một nguồn tài liệu tham khảo uy tín, phần nào hỗ trợ Quý thầy cô trong quá trình giảng dạy.
            </p>
            <p className="mt-3">
              Xem hướng dẫn tải bài giảng
              <InstructionDownload onClick={() => handleInstructionDownload()}>
                {" "}
                tại đây
              </InstructionDownload>
            </p>
            <p className="">
              Tải font chữ{" "}
              <InstructionDownload
                onClick={() => handleInstructionDownloadFont()}
              >
                {" "}
                tại đây
              </InstructionDownload>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

const LabelComingSoon = styled.span`
  border: 2px solid #ff7707;
  border-radius: 5px;
  padding: 2px;
  color: #ff7707;
`;
const InstructionDownload = styled.span`
  color: red;
  cursor: pointer;
`;
