export default function Header({ parentPage, currentPage }) {
  return (
    <>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">{parentPage}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {currentPage}
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-title bg-gr">
        <div className="container decor-h1 pr">
          <h1 className="title">{currentPage}</h1>
        </div>
      </div>
    </>
  );
}
