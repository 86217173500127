import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";

export default function Header({ params, listAllSubjects }) {
  const subject = listAllSubjects[params.subjectType]?.name;
  const levelName = listAllSubjects[params.levelType];

  return (
    <>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to={PATH.ROUTE_BLOG_KNOWLEDGE}> Kiến thức </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Link to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${levelName.slug}/`}>
                {levelName.name}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {subject}
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-intro hoc10-intro--2">
        <Div className="container">
          <div className="text-intro decor-h3 pr">
            <h1 className="title">{subject || ""}</h1>
          </div>
        </Div>
      </div>
    </>
  );
}

const Div = styled.div`
  @media (max-width: 820px) {
    .decor-h3::after {
      width: 725px;
      height: 175px;
      background: round;
      left: -10px;
      top: 0;
    }
  }
`;
