import { GRAPH_TYPE } from "../constants/Graph";

const formatActivityDataGraph001 = (activity) => {
  const { game_config: gameConfig } = activity;

  const graphDataSetList = gameConfig.data.map((dataGameConfigItem) => {
    return {
      graphType: Number(dataGameConfigItem.type_graph.icon_id),
      coefficient: {
        a: Number(dataGameConfigItem.coefficient_a),
        b: Number(dataGameConfigItem.coefficient_b),
        c: Number(dataGameConfigItem.coefficient_c),
      },
      negativeCoorX: Number(dataGameConfigItem.negative_coor_x),
      negativeCoorY: Number(dataGameConfigItem.negative_coor_y),
      positiveCoorX: Number(dataGameConfigItem.positive_coor_x),
      positiveCoorY: Number(dataGameConfigItem.positive_coor_y),
    };
  });

  return graphDataSetList;
};

const checkCorrectAnswer = (graphType, coefficient, selectedAnswer) => {
  let isCorrect = false;
  let isCorrectPointA = false;
  let isCorrectPointB = false;
  const { xA, yA, xB, yB } = selectedAnswer;

  if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
    const { a, b } = coefficient;
    isCorrectPointA = yA === a * xA + b;
    isCorrectPointB = yB === a * xB + b;
    isCorrect = isCorrectPointA && isCorrectPointB;
  }
  if (graphType === GRAPH_TYPE.QuadraticEquation) {
    const { a, b, c } = coefficient;
    isCorrectPointA = yA === a * xA * xA + b * xA + c;
    isCorrectPointB = yB === a * xB * xB + b * xB + c;
    const isIncreasingDecreasing = (a > 0 && yA < yB) || (a < 0 && yA > yB);
    isCorrect = isCorrectPointA && isCorrectPointB && isIncreasingDecreasing;
  }
  return { isCorrect, isCorrectPointA, isCorrectPointB };
};

export {
  formatActivityDataGraph001,
  checkCorrectAnswer,
};
