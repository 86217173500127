import { useContext, useEffect, useState } from "react";
import { AccordionContext, Card, useAccordionToggle } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import styled, { keyframes } from "styled-components";
import _ from "lodash";
import { getHistoryExam } from "edu_lms/services/readingBook";
import updating_img from "edu_lms_v2/assets/img/updating.svg";
import updating_white from "edu_lms_v2/assets/img/updating-white.svg";
import { onResultUserInfo } from "edu_lms/modules/selection";
import {
  getListClassroomById,
  postAssignHomeWork,
} from "edu_lms/services/teachingManager";
import * as types from "edu_lms/constants/type";

import dayjs from "dayjs";
import classNames from "classnames";
import PopupShowHistoryExam from "../QuestionLibraryV2/PopupShowHistoryExam";
import PopUpAssignHomeWork from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignHomeWork";
import PopUpAssignSuccess from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignSuccess";
import { LICENSE_FREE, LICENSE_LOGIN } from "./constant";
import PopupActiveQuestionSet from "./PopupActiveQuestionSet";
import LoginWrapper from "../Login/LoginWrapper";
import PopupLoginSuccess from "../Login/PopupLoginSuccess";
import { ROUTE_PATH_V3_STUDENT_MOCK_TEST } from "consts/routePaths";
import ForgotPassword from "edu_lms_v2/modules/ForgotPassword/ForgotPassword";

const IS_UPDATING = 1;
const Li = styled.li`
  list-style: none;
  &:hover {
    background-color: #eee;
  }
`;

const animationImg = keyframes`
  from {
    scale: 0.7
  }

  to {
    scale: 1.1
  }`;

const ChildrenQuestion = styled.p`
  &:hover {
    color: #fff;
    background-color: #ff7707;
  }

  &:hover .is-free {
    color: #fff;
  }
`;

const Ribbon = styled.div`
  .ribbon {
    display: inline-flex;
    overflow: hidden;
  }
  span {
    width: 24px;
  }
  .comming {
    width: 48px;
  }
  .icon-new {
    width: 30px;
    top: -10px;
    right: -30px;
    animation: ${animationImg} 1s infinite;
  }
`;

const SeeMoreStyle = styled.div`
  &:hover {
    text-decoration: underline;
    color: #ff7707;
  }
`

export default function QuestionLibraryDetail({
  questionDetail,
  handleShowExam,
  listGrade,
  listSubject,
}) {
  const token = localStorage.getItem("token");
  const [showHistoryExam, setShowHistoryExam] = useState(false);
  const { list } = useSelector((state) => state.mockTestReducers);
  const [showRequiredLogin, setShowRequiredLogin] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLoginSuccess, setShowLoginSuccess] = useState(false);
  const [showPopupForgotPw, setShowPopupForgotPw] = useState(false);
  const [currentGrade, setCurrentGrade] = useState({});
  const [currentSubject, setCurrentSubject] = useState({});
  const [listClassroom, setListClassroom] = useState([]);
  const [showModalAssignSuccess, setShowModalAssignSuccess] = useState(false);
  const [classRoom, setClassRoom] = useState([]);
  const [question, setQuestion] = useState("");
  const [checked, setChecked] = useState([]);
  const [questionSetId, setQuestionSetId] = useState();
  const [keyPopup, setKeyPopup] = useState({
    currentGrade,
    currentSubject,
    title: "",
  });

  useEffect(() => {
    setCurrentGrade(listGrade.find((grade) => grade.id === list.gradeId));
    setCurrentSubject(listSubject.find((s) => s.id === list.subjectId));
  }, [list, listGrade, listSubject]);

  useEffect(() => {
    setKeyPopup({
      ...keyPopup,
      currentGrade,
      currentSubject,
    });
  }, [currentGrade, currentSubject]);
  useEffect(() => {
    const guest_id = localStorage.getItem("guest_id");
    if (!token && !guest_id) {
      localStorage.setItem("guest_id", uuidv4());
    }
  }, []);
  const accountWebsite = onResultUserInfo();
  const defineAccount = accountWebsite?.job_id;

  const handleShowPopupHistoryExam = (title, id, isFree = '') => {
    if (isFree === LICENSE_LOGIN && !token) {
      setShowRequiredLogin(true);
      return;
    }

    setShowHistoryExam(true);
    setQuestionSetId(id);
    const data = {
      question_set_id: id,
      guest_id: localStorage.getItem("guest_id"),
    };
    setKeyPopup({ ...keyPopup, id });
  
    getHistoryExam(data)
      .then((res) => {
        const dataHistory = res.data.data;
        setKeyPopup({ ...keyPopup, title, dataHistory, id });
      })
      .catch((e) => console.log(e));
  };

  const showGradeName = (gradeId) => {
    const gradeName = types.gradeOptions.find((item) => item.value === gradeId);
    return gradeName?.name;
  };
  const getListClassroom = () => {
    const data = {
      title: "",
      roleId: `${types.ROLE_CREATED_ROOM},${types.ROLE_TEACHER}`,
      langId: types.TYPE_VIETNAMESE,
      appId: types.APP_ID,
    };
    getListClassroomById(data)
      .then((res) => {
        if (res.data.status === "success") {
          const list = res.data.data.list;
          const nodes = list.map((item) => ({
            value: item.class_room_id,
            label: showGradeName(item.grade_id)
              ? `${showGradeName(item.grade_id)}${item.title}`
              : item.title,
          }));
          setListClassroom(nodes);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmit = (data) => {
    const dataForm = {
      deadline: dayjs(data.time_finish).unix(),
      question_set_id: questionSetId,
      class_room_ids: checked.toString(),
    };
    postAssignHomeWork(dataForm)
      .then((res) => {
        if (res.data.status === "success") {
          setClassRoom(res.data.data.class_room);
          setQuestion(res.data.data.question_set);
          setShowModalAssignSuccess(true);
        }
      })
      .finally(() => {
        setShowHistoryExam(false);
        setChecked([]);
      });
  };
  const [examTypes, setExamTypes] = useState(!_.isEmpty(questionDetail) ? questionDetail : []);
  const hasChildrenExamType = examTypes.length > 0;

  const toggleSeeMore = (examType) => {
    const newExam = examTypes.map(item => examType.id === item.id ? ({...item, seeMore: !item.seeMore}) : item)
    setExamTypes(newExam);
  }

  const onLoginSuccess = (data) => {
    setShowLogin(false);
    setShowLoginSuccess(true);
  };

  const onActiveForgotPw = () => {
    setShowLogin(false);
    setShowPopupForgotPw(true);
  };
	
	function getTitlePublic(number) {
		if (number < 10) {
			return "- Số 00" + number;
		} else {
			return "- Số 0" + number;
		}
	}

  return (
    <>
      <div className="col-lg-9 col-md-8">
        {!hasChildrenExamType && (
          <div className="monkey-color-orange monkey-f-header h3 text-uppercase text-center pt-5">
            Không có bộ đề
          </div>
        )}

        {examTypes.map((examType) => {
          const examTypesPopular = _.isArray(examType.children)
            ? examType.children.filter((item, index) => index < 5)
            : [];
          const subExamTypes = examType.seeMore ? (examType.children || []) : examTypesPopular;
          const hasSubExamType = subExamTypes.length > 0;
          const questions = examType.questions;
          const hasQuestion = questions.length > 0;
          const isNew = examType.title.includes("cuối học kì"); // chỉ hightlight bộ đề cuối học kì

          return (
            <div className="chapter bg-sh">
              <Accordion
                defaultActiveKey={examType.title.trim().length > 0 ? examType.id : ""}
              >
                <Accordion.Toggle eventKey={examType.id} className="w-100">
                  <div className="header flex justify-content-between">
                    <Ribbon className="position-relative">
                      <div className="ribbon">
                        <h3> {examType.title}
                          {/*{isNew && <img className="icon-new position-absolute" src="/assets/img/icon-new.svg"/>}*/}
                        </h3>
                      </div>
                    </Ribbon>

                    {(hasSubExamType || hasQuestion) && (
                      <ContextAwareToggle eventKey={examType.id} />
                    )}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={examType.id} className="cursor">
                  <div className="question">
                      {subExamTypes.map((subExamType) => {
                        const subQuestions = subExamType.questions || [];
                        const hasSubQuestions = subQuestions.length > 0;
                        return (
                          <Accordion defaultActiveKey={examType.id} className="flex flex-column pr-3">
                          <Card className="border-0">
                            <Card.Header className="p-0 bg-white border-0">
                              <Accordion.Toggle
                                eventKey={subExamType.id}
                                className="w-100"
                              >
                                <Li className="w-100 rounded">
                                  <div className="text-left d-flex justify-content-between">
                                    <div className="d-flex align-items-center monkey-fz-18">
                                      <i className="icon-doc mr-2" />
                                      {subExamType.title}
                                    </div>
                                    {hasSubQuestions && (
                                      <div className="d-flex justify-content-end">
                                        {subQuestions.length > 0 && (
                                          <span className="mr-2 monkey-color-orange">
                                            ({subQuestions.length} bộ đề)
                                          </span>
                                        )}
                                        <ContextAwareToggle
                                          eventKey={subExamType.id}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Li>
                              </Accordion.Toggle>
                            </Card.Header>
                            {subQuestions.map((subQuestion, _index) => (
                              <Accordion.Collapse
                                eventKey={subExamType.id}
                                className="cursor monkey-fz-18 question-item"
                              >
                                <ChildrenQuestion
                                  className="pl-4 rounded"
                                  onClick={() =>
                                    handleShowPopupHistoryExam(
                                      subQuestion.public_title ??
                                        `[${types.DRAFT}] ${
                                          subQuestion.title ??
                                          `Bài tập ${_index + 1}`
                                        }`,
                                      subQuestion.id,
                                      subQuestion.is_free
                                    )
                                  }
                                >
                                  <div className="pt-2 pb-2 d-flex align-items-center">
                                    <i className="icon-pen1 mr-2" />
                                    <div className="d-flex">
                                      <span>
	                                       {/*{subQuestion.public_title ||*/}
		                                   {/*    `[${types.DRAFT}] ${*/}
			                               {/*        subQuestion.title ??*/}
			                               {/*        `Bài tập ${_index + 1}`*/}
		                                   {/*    }`}*/}
                                        {subQuestion.public_title ? subExamType.title +  getTitlePublic(_index + 1) : `[${types.DRAFT}] ${
	                                        subQuestion.title ??
	                                        `Bài tập ${_index + 1}`
                                        }`}
                                      </span>

                                      <div className="is-free rounded px-1 monkey-color-orange ml-2">
                                        <i
                                          className={`fa ${!token && subQuestion.is_free === LICENSE_LOGIN ? "fa-lock" : "fa-unlock"}`}
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                    {subQuestion.is_maintain ===
                                      IS_UPDATING && (
                                      <span className="ml-2">
                                        <img
                                          src={updating_img}
                                          alt="updating"
                                          width="60"
                                          className="img-orange"
                                        />
                                        <img
                                          src={updating_white}
                                          alt="updating"
                                          width="60"
                                          className="img-white"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </ChildrenQuestion>
                              </Accordion.Collapse>
                            ))}
                          </Card>
                    </Accordion>
                        );
                      })}
                    {questions.map((question, _index) => (
                      <ChildrenQuestion
                        className="rounded mr-3 monkey-fz-18 question-item"
                        // onClick={() => handleShowExam(question.id)}
                        onClick={() =>
                          handleShowPopupHistoryExam(
                            question.public_title ??
                              `[${types.DRAFT}] ${
                                question.title ?? `Bài tập ${_index + 1}`
                              }`,
                            question.id
                          )
                        }
                      >
                        <div className="pt-2 pb-2 d-flex align-items-center">
                          <i className="icon-pen1 mr-2" />
                          <span>
                            {question.public_title ||
                              `[${types.DRAFT}] ${
                                question.title ?? `Bài tập ${_index + 1}`
                              }`}
                          </span>
                          {question.is_maintain === IS_UPDATING && (
                            <span className="ml-2">
                              <img
                                src={updating_img}
                                alt="updating"
                                width="60"
                                className="img-orange"
                              />
                              <img
                                src={updating_white}
                                alt="updating"
                                width="60"
                                className="img-white"
                              />
                            </span>
                          )}
                        </div>
                      </ChildrenQuestion>
                    ))}
                    {subExamTypes.length >= 5 && (
                      <SeeMoreStyle
                        className="py-2 pl-1"
                        onClick={() => toggleSeeMore(examType)}
                      >
                        {examType.seeMore ? "Thu gọn" : "Xem thêm..."}
                      </SeeMoreStyle>
                    )}
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          );
        })}
      </div>
      {showHistoryExam && (
        <PopupShowHistoryExam
          showHistoryExam={showHistoryExam}
          setShowHistoryExam={setShowHistoryExam}
          keyPopup={keyPopup}
          handleShowExam={handleShowExam}
          defineAccount={defineAccount}
          getListClassroom={getListClassroom}
          listClassroom={listClassroom}
          checked={checked}
          setChecked={setChecked}
          onSubmit={onSubmit}
        />
      )}

      <PopUpAssignHomeWork
        listClassroom={listClassroom}
        onSubmit={onSubmit}
        checked={checked}
        setChecked={setChecked}
        showHistoryExam={showHistoryExam}
      />

      <PopupActiveQuestionSet 
        show={showRequiredLogin}
        setShow={setShowRequiredLogin}
        setShowLogin={setShowLogin}
      />
      <LoginWrapper
        show={showLogin}
        onHide={() => setShowLogin(false)}
        onLoginSuccess={onLoginSuccess}
        onActiveForgotPw={onActiveForgotPw}
        showPopupForgotPw={showPopupForgotPw}
        setShowPopupForgotPw={setShowPopupForgotPw}
      />

      <ForgotPassword
        show={showPopupForgotPw}
        onHide={() => setShowPopupForgotPw(false)}
        setLogin={setShowLogin}
      />
      <PopupLoginSuccess
        show={showLoginSuccess}
        onHide={() => {
          setShowLoginSuccess(false);
        }}
        path={ROUTE_PATH_V3_STUDENT_MOCK_TEST}
      />

      <PopUpAssignSuccess
        question={question}
        classRoom={classRoom}
        showGradeName={showGradeName}
        open={showModalAssignSuccess}
        onClose={() => setShowModalAssignSuccess(false)}
      />
    </>
  );
}

function ContextAwareToggle({ eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <i
      className={`fa pr-2 monkey-fz-20 ${
        isCurrentEventKey ? "fa-angle-up" : "fa-angle-down"
      }`}
      aria-hidden="true"
      onClick={decoratedOnClick}
    />
  );
}
