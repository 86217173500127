import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { animateScroll } from "react-scroll";
import { toast } from "react-toastify";
import styles from "../teachingManager.module.scss";
import PopupAddClassRoom from "./AddClassroom/PopupAddClassroom";
import PopupConfirmDelete from "./PopupConfirmDelete";
import PopupAddMember from "./AddClassroom/PopupAddMember";
import * as types from "edu_lms/constants/type";
import {
  onDispatchSetSelectOptionSchoolYear,
  onDispatchSetSearchkeyClassroom,
  onDispatchSetCurrentPageClassroom,
} from "../actions";
import Pagination from "edu_lms_v2/components/Pagination";
import {
  getListClassroomById,
  postDeleteClassroom,
} from "edu_lms/services/teachingManager";
import PopupAddStudentForEmail from "./MemberManager/PopupAddStudentByEmail";
import { ROUTE_PATH_CLASSROOM } from "consts/routePaths";
import { onDecryptedData } from "edu_lms/modules/selection";
import * as CONSTANTS_TYPE from "edu_lms/constants/type";
import { ROLE, STATUS } from "../constant";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";

export default function ClassroomManager() {
  const product = Number(process.env.REACT_APP_APP_ID);
  const [status, setStatus] = useState(STATUS.START);
  const [role, setRole] = useState(ROLE.ADD);
  const [showAddClassroom, setShowAddClassroom] = useState(false);
  const [showPopupDelClassroom, setShowPopupDelClassroom] = useState(false);
  const [showPopupAddMember, setShowPopupAddMember] = useState(false);
  const [showPopupAddStudentByEmail, setShowPopupAddStudentByEmail] =
    useState(false);
  const [listClassroom, setListClassroom] = useState([]);
  const [totalClass, setTotalClass] = useState(0);
  const [idClassroom, setIdClassroom] = useState();
  const [isUserTeacher, setIsUserTeacher] = useState();

  const selectSchoolYear = useSelector(
    (state) => state.teachingReducer.schoolYearSelect
  );
  const searchKey = useSelector(
    (state) => state.teachingReducer.searchKeyClassroom
  );
  const currentPage = useSelector(
    (state) => state.teachingReducer.currentPageClassroom
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, setValue } = useForm();

  const handleChangePage = (page) => {
    dispatch(onDispatchSetCurrentPageClassroom(page));
  };

  const onSearch = (dataForm) => {
    dispatch(onDispatchSetSearchkeyClassroom(dataForm.search));
  };

  const handleChangeSchoolYear = (select) => {
    dispatch(onDispatchSetSelectOptionSchoolYear(select));
  };

  const handleClick = (id) => {
    const url = `${ROUTE_PATH_CLASSROOM}${id}`;
    history.push(url);
  };

  const getListClassroom = () => {
    const data = {
      title: searchKey,
      // schoolYearId: selectSchoolYear.value,
      roleId: `${types.ROLE_CREATED_ROOM},${types.ROLE_TEACHER}`,
      page: currentPage,
      limit: types.TOTAL_CLASSROOM_PER_PAGE,
      langId: types.TYPE_VIETNAMESE,
      appId: types.APP_ID,
    };
    getListClassroomById(data)
      .then((res) => {
        if (res.data.status === "success") {
          const newArray = res.data.data.list.map((classroom) => ({
            ...classroom,
            id: classroom.class_room_id,
          }));
          setListClassroom(newArray);
          setTotalClass(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDeleteClassroom = (id) => {
    setIdClassroom(id);
    setShowPopupDelClassroom(true);
  };

  const onAddStudent = (id) => {
    setIdClassroom(id);
    setShowPopupAddStudentByEmail(true);
  };

  const onDeleteClass = (id) => {
    postDeleteClassroom({ id: id }).then((res) => {
      if (res.data.status === "success") {
        toast.success("Xoá lớp học thành công!");
        getListClassroom();
      } else {
        toast.error("Xoá lớp học thất bại!");
      }
      setShowPopupDelClassroom(false);
    });
  };

  useEffect(() => {
    animateScroll.scrollToTop();
    setValue("search", searchKey);
    getListClassroom();
  }, [currentPage, searchKey, selectSchoolYear]);

  useEffect(() => {
    if (listClassroom.length === 0) {
      handleChangePage(1);
    }
  }, [listClassroom]);
  useEffect(() => {
    const userInfo = onDecryptedData(
      localStorage.getItem(CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO),
      CONSTANTS_TYPE.LOCAL_STORAGE_KEY_USER_INFO
    );
    setIsUserTeacher(userInfo.job_id);
  }, []);
  const showGradeName = (gradeId) => {
    const gradeName = types.gradeOptions.find((item) => item.value === gradeId);
    return gradeName?.name;
  };

  const handleShowClassroom = (item) => {
    if (product === HOC10_APP_ID) {
      return `${showGradeName(item.grade_id)}${item.title}`;
    }

    if (product === TUTORING_APP_ID) {
      return item.title;
    }
  };

  return (
    <div className="pb-4">
      <div id="classroom" className="tab-pane fade show active" role="tabpanel">
        <div className="header-class d-flex justify-content-between flex-wrap">
          {isUserTeacher == types.JOB_TEACHER && (
            <div className="mr-auto mb-2">
              <button
                className="monkey-bg-orange py-2 px-3 text-white rounded"
                onClick={() => setShowAddClassroom(true)}
              >
                <i className="fa fa-plus-circle pr-2" aria-hidden="true" />
                Tạo lớp học
              </button>
            </div>
          )}
          <div className={`mb-2 mr-3 ${styles.schoolYearSelect}`}>
            <Select
              value={selectSchoolYear}
              placeholder="Chọn năm học"
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 5 }) }}
              onChange={handleChangeSchoolYear}
              options={types.schoolYearOptions}
            />
          </div>
          <div className="mb-2 position-relative">
            <form onSubmit={handleSubmit(onSearch)}>
              <i
                className={`fa fa-search position-absolute ${styles.iconSearch}`}
                aria-hidden="true"
              ></i>
              <input
                name="search"
                type="text"
                className="form-control rounded-pill pl-4"
                placeholder="Tìm kiếm lớp học"
                ref={register({ required: false, maxLength: 80 })}
              />
            </form>
          </div>
        </div>
        <div className="list-class my-4 row">
          {listClassroom.length > 0 ? (
            listClassroom.map((item, index) => (
              <div className="col-lg-3 col-md-4 col-xs-12 col-sm-6" key={index}>
                <div
                  className={`p-3 cursor my-3 text-center ${styles.itemClass}`}
                >
                  <div onClick={() => handleClick(item.id)}>
                    <div className="border-bottom pb-2 position-relative">
                      <p className="text-fsize font-weight-bold cursor monkey-color-black">
                        {handleShowClassroom(item)}
                      </p>
                      {/* <p className="font-italic fsize">
                        {item.school_name || "Chưa chọn trường"}
                      </p> */}
                    </div>
                    <div className="pt-2">
                      <p>
                        <i
                          className="fa fa-graduation-cap"
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                        {item.students_count} Học sinh
                      </p>
                      <p>
                        <i
                          className="fa fa-user-circle-o"
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                        {item.teachers_count} Giáo viên
                      </p>
                    </div>
                  </div>
                  <div
                    className={`setting position-absolute ${styles.setting}`}
                  >
                    <button
                      className={styles.colorOrange}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => setIdClassroom(item.id)}
                    >
                      <i
                        className="fa fa-cog text-fsize"
                        aria-hidden="true"
                      ></i>
                    </button>
                    <div
                      className={`dropdown-menu dropdown-menu-right p-0 border-0 ${styles.menuSetting}`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        className="dropdown-item border-bottom"
                        onClick={() => {
                          setIdClassroom(item.id);
                          setRole(ROLE.UPDATE);
                          setShowAddClassroom(true);
                        }}
                      >
                        <i
                          className={`fa fa-pencil-square ${styles.iconOrange}`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Chỉnh sửa lớp học
                      </button>
                      {/* <button className="dropdown-item border-bottom">
                        <i
                          className={`fa fa-user-circle-o ${styles.iconOrange}`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Thêm giáo viên
                      </button> */}
                      <button
                        className="dropdown-item border-bottom"
                        onClick={() => onAddStudent(item.id)}
                      >
                        <i
                          className={`fa fa-graduation-cap ${styles.iconOrange}`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Thêm học sinh
                      </button>
                      {/* <button className="dropdown-item border-bottom">
                        <i
                          className={`fa fa-calendar ${styles.iconOrange}`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Xem báo cáo
                      </button> */}
                      <button
                        className="dropdown-item"
                        onClick={() => onDeleteClassroom(item.id)}
                      >
                        <i
                          className={`fa fa-trash ${styles.iconOrange}`}
                          aria-hidden="true"
                        ></i>
                        &nbsp; Xoá lớp học
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mx-auto my-5 h3 font-weight-bold">
              {searchKey
                ? "Không tìm thấy lớp học nào"
                : "Bạn chưa có lớp học nào"}
            </div>
          )}
        </div>
        <PopupAddClassRoom
          role={role}
          setRole={setRole}
          listClassroom={listClassroom}
          idClassroom={idClassroom}
          show={showAddClassroom}
          setShow={setShowAddClassroom}
          status={status}
          setStatus={setStatus}
          getListClassroom={getListClassroom}
          setShowPopupAddMember={setShowPopupAddMember}
          setIdClassroom={setIdClassroom}
        />
        <PopupConfirmDelete
          show={showPopupDelClassroom}
          setShow={setShowPopupDelClassroom}
          id={idClassroom}
          title={"Bạn có chắc chắn xoá lớp học này?"}
          onDelete={onDeleteClass}
        />
        {/* <PopupAddMember
          show={showPopupAddMember}
          setShow={setShowPopupAddMember}
          idClassroom={idClassroom}
          getListClassroom={getListClassroom}
        /> */}
        <PopupAddStudentForEmail
          show={showPopupAddStudentByEmail}
          setShow={setShowPopupAddStudentByEmail}
          idClassroom={idClassroom}
          getListClassroom={getListClassroom}
        />
      </div>
      {totalClass > types.TOTAL_CLASSROOM_PER_PAGE && (
        <Pagination
          total={totalClass}
          totalPerPage={types.TOTAL_CLASSROOM_PER_PAGE}
          setCurrentPage={handleChangePage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}
