import { REG_EXP_EMAIL, REG_EXP_NUMBER } from "../../constants/type";

export const dataForm = [
  {
    value: "Trực thuộc sở (Tỉnh/ Thành phố)",
    id: "provinceId",
    roleId: [1, 2],
    data: {
      key: "select",
      type: "",
      value: "",
      name: "provinceName",
      placeholder: "",
      error: false,
      pattern: "",
      required: false,
      dislabel: {
        1: true,
        2: true,
      },
      options: [],
      menuPlacement: "bottom",
      className: "col-12 col-md-6",
    },
  },
  {
    value: "Phòng (Quận/ Huyện/ Thị xã)",
    id: "districtId",
    roleId: [1, 2],
    data: {
      key: "select",
      type: "",
      value: "",
      name: "districtName",
      placeholder: "",
      error: false,
      pattern: "",
      required: true,
      dislabel: {
        1: false,
        2: true,
      },
      options: [],
      menuPlacement: "bottom",
      className: "col-12 col-md-6",
    },
  },
  {
    value: "Xã/ Phường/ Thị Trấn",
    id: "warId",
    roleId: [2],
    data: {
      key: "select",
      type: "",
      value: "",
      name: "wardName",
      placeholder: "",
      error: false,
      pattern: "",
      required: true,
      dislabel: {
        1: false,
        2: false,
      },
      options: [],
      menuPlacement: "bottom",
      className: "col-12 col-md-6",
    },
  },
  {
    value: "Trường",
    id: "schoolId",
    roleId: [2],
    data: {
      key: "select",
      type: "",
      value: "",
      name: "schoolName",
      placeholder: "",
      error: false,
      pattern: "",
      required: true,
      dislabel: {
        1: false,
        2: false,
      },
      options: [],
      menuPlacement: "bottom",
      className: "col-12 col-md-6",
    },
  },
  {
    value: "Họ tên",
    id: "nameId",
    roleId: [1, 2],
    data: {
      key: "text",
      type: "text",
      value: "",
      name: "fullName",
      placeholder: "Tên Phòng",
      error: false,
      dislabel: {
        1: false,
        2: false,
      },
      pattern: "",
      valueError: "",
      className: "",
      required: true,
      className: "col-12",
    },
  },
  {
    value: "Email",
    id: "emailId",
    roleId: [1, 2],
    data: {
      key: "text",
      type: "text",
      value: "",
      name: "email",
      placeholder: "Email",
      error: true,
      dislabel: {
        1: false,
        2: false,
      },
      pattern: REG_EXP_EMAIL,
      valueError: "Sai định dạng",
      className: "position-relative",
      required: true,
      className: "col-12 col-md-6",
    },
  },
  {
    value: "Số điện thoại",
    id: "phoneId",
    roleId: [1, 2],
    data: {
      key: "text",
      type: "text",
      value: "",
      name: "phone",
      placeholder: "Số điện thoại",
      error: true,
      dislabel: {
        1: false,
        2: false,
      },
      pattern: REG_EXP_NUMBER,
      valueError: "Sai định dạng",
      className: "position-relative",
      required: true,
      className: "col-12 col-md-6",
    },
  },
];
