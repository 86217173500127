import styled from "styled-components";
import Pdf from "./Document/View";
import Video from "./Document/Video";
import DownloadFile from "./Document/DownloadFile";
import {
  DOCUMENT_TYPE_AUDIO,
  DOCUMENT_TYPE_PDF,
  DOCUMENT_TYPE_PPT,
  DOCUMENT_TYPE_VIDEO,
  DOCUMENT_TYPE_WORD,
  DOCUMENT_TYPE_ZIP,
  BOOK_TYPE_BAN_GOP_Y_XA_HOI,
  BOOK_TYPE_BAN_IN_THU,
  BOOK_TYPE_BAN_MAU,
} from "consts/book";

export const optionSchool = [
  { label: "Tiểu học", value: 1 },
  { label: "Trung học cơ sở", value: 2 },
  { label: "Trung học phổ thông", value: 3 },
];

export const listTypeDocument = [
  { label: "Tất cả", value: 0 },
  { label: "PDF", value: 1 },
  { label: "Video", value: 2 },
  { label: "Audio", value: 3 },
  { label: "Word", value: 4 },
  { label: "PPT", value: 5 },
  { label: "Zip", value: 6 },
];

export const defaultSchoolId = 1;
export const defaultSchool = optionSchool.find(
  (school) => school.value === defaultSchoolId
);
export const defaultGradeId = 1;
export const defaultSubjectId = 1;

export const currentSchoolId = (id) => {
  return optionSchool.find((school) => school.value === id);
};

export const getRecentBookInfo = (recentBook) => {
  const arrRecentBook = JSON.parse(
    localStorage.getItem("dataRecentBook") || "[]"
  );
  const currentBook = arrRecentBook.find(
    (item) => Number(item.id) === Number(recentBook.id)
  );
  const otherBooks = arrRecentBook.filter(
    (item) => Number(item.id) !== Number(recentBook.id)
  );
  const newItem = currentBook
    ? { ...currentBook, pageId: recentBook.pageId }
    : recentBook;

  const newArr = [newItem, ...otherBooks];

  if (arrRecentBook.length > 6) {
    newArr.pop();
  }
  localStorage.setItem("dataRecentBook", JSON.stringify(newArr));
};

export const PAGE_TRAINING = "training";
export const STATUS_TEST_BOOK = {
  ENABLE: "1",
  DISABLE: "2",
};
export const ListCheckbox = styled.label`
  &.disable {
    pointer-events: none;
    color: #eee;
  }
`;

export const BOOK_INTRO_TAG = {
  [BOOK_TYPE_BAN_GOP_Y_XA_HOI]: "Bản GYXH",
  [BOOK_TYPE_BAN_IN_THU]: "Bản in thử",
  [BOOK_TYPE_BAN_MAU]: "Bản mẫu",
};

export const handleConvertUrl = (string) => {
  string = string.toLowerCase();
  return string.replaceAll(" ", "-");
};

export const handleModeView = (type) => {
  const mode = [DOCUMENT_TYPE_PDF, DOCUMENT_TYPE_WORD, DOCUMENT_TYPE_PPT];

  return mode.includes(type);
};

export const renderDocumentByType = (
  type,
  item,
  index,
  col,
  onSetUrl,
  isCourse = false
) => {
  switch (type) {
    case DOCUMENT_TYPE_PDF:
      return <Pdf item={item} col={col} index={index} />;
    case DOCUMENT_TYPE_VIDEO:
      return (
        <Video
          item={item}
          col={col}
          onSetUrl={onSetUrl}
          index={index}
          isCourse={isCourse}
        />
      );
    case DOCUMENT_TYPE_AUDIO:
    case DOCUMENT_TYPE_WORD:
    case DOCUMENT_TYPE_PPT:
    case DOCUMENT_TYPE_ZIP:
      return <DownloadFile item={item} col={col} index={index} />;
    default:
      return <Pdf item={item} col={col} index={index} />;
  }
};
