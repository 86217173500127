import React from "react";
import { Typography } from "../../components";

const TitleQuestion = ({ titleQuestion }) => {
  const { fontSizeTitle, typeQuestion, contentText } = titleQuestion;

  if (!contentText) return null;
  return (
    <Typography
      text={contentText}
      typeText={typeQuestion}
      fontSize={fontSizeTitle}
      className="monkey-f-header pl-5"
    />
  );
};

export default TitleQuestion;
