import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Table } from "react-bootstrap";
import styled from "styled-components";
import dayjs from "edu_lms/libs/dayjs";
import {
  displayDuration,
  JOB_STUDENT,
  PLAY_MODE,
} from "edu_lms/constants/type";
import PopUpAssignHomeWork from "../TeachingManager/ClassroomManager/ExerciseClassroom/PopUpAssignHomeWork";
import styles from "./PopupShowHistory.module.scss";
import { ShareAndCopy } from "edu_lms_v2/components/ShareAndCopy";
import * as PATH from "consts/routePaths";

const SUCCESS_EXAM = 10;
export default function PopupShowHistoryExam({
  showHistoryExam,
  setShowHistoryExam,
  handleShowExam,
  keyPopup,
  defineAccount = JOB_STUDENT,
  checked,
  setChecked,
  listClassroom,
  onSubmit,
  getListClassroom,
  playMode = PLAY_MODE.EXAM,
}) {
  const [exerciseId, setExerciseId] = useState(keyPopup.id);
  const [showPopup, setShowPopup] = useState(false);
  const bookInfo = useSelector((state) => state.generalReducer.bookInfo);

  const handleClose = () => {
    setShowHistoryExam(false);
  };

  const handleClickAssign = () => {
    setExerciseId(exerciseId);
    setShowPopup(!showPopup);
    getListClassroom();
  };
  const url = window.location.origin + PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST;
  const urlShareFb = `${url}${keyPopup.id}`;

  const hasPracticeHistory = keyPopup.dataHistory?.length > 0;
  let buttonLabel = "Làm bài";
  if (playMode === PLAY_MODE.PRACTICE_V2) {
    buttonLabel = "Luyện tập ngay";
    if (hasPracticeHistory) {
      buttonLabel = "Luyện tập thêm";
    }
  }

  return (
    <>
      <Modal
        show={showHistoryExam}
        onHide={handleClose}
        centered={true}
        className="monkey-color-black"
        size="lg"
        style={{color: "#101010"}}
      >
        <Modal.Header closeButton className={`border-0 ${styles.title}`}>
          {playMode !== PLAY_MODE.PRACTICE_V2 && (
            <p className="h3 w-100 text-center">{keyPopup.title}</p>
          )}
        </Modal.Header>
        <Modal.Body className="border-0 text-center p-0">
          {playMode === PLAY_MODE.PRACTICE_V2 ? (
            <div
              className={`d-flex ${styles.titleGradeAndSubject}`}
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <div className="monkey-fz-20" style={{ width: "50%" }}>
                {bookInfo.bookName}
              </div>
              <div className="monkey-fz-20" style={{ width: "50%" }}>
                Luyện tập: {keyPopup?.title}
              </div>
            </div>
          ) : (
            <div
              className={`d-flex ${styles.titleGradeAndSubject}`}
              style={{ justifyContent: "space-evenly" }}
            >
              <div className="mr-5 monkey-fz-20 ">
                {keyPopup.currentGrade?.name}
              </div>
              <div className="monkey-fz-20">
                Môn: {keyPopup.currentSubject?.title}
              </div>
            </div>
          )}
          <>
            <div className="mt-3 mb-3 monkey-fz-20 ">
              {playMode === PLAY_MODE.PRACTICE_V2
                ? "Lịch sử ôn tập"
                : "Lịch sử làm bài"}
            </div>
            <div style={{ maxHeight: "380px", overflowY: "auto", color: "#101010" }}>
              <div className={styles.table}>
                <Table striped bordered hover style={{color: "#101010"}}>
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Bắt đầu làm</th>
                      <th>Thời gian làm</th>
                      <th>Kết quả</th>
                      {playMode === PLAY_MODE.PRACTICE_V2 && (
                        <th>Mức độ hoàn thành</th>
                      )}
                      {playMode === PLAY_MODE.EXAM && <th>Điểm</th>}
                    </tr>
                  </thead>

                  {hasPracticeHistory ? (
                    keyPopup.dataHistory?.map((data, index) => {
                      const timeEnd = dayjs.unix(data?.time_end);
                      const timeStart = dayjs.unix(data?.time_start);
                      const durationTime = dayjs.duration(
                        timeEnd.diff(timeStart)
                      );
                      const dateTimeStart =
                        timeStart.format("DD-MM-YYYY, HH:mm");
                      const isSuccess = data.total_correct === SUCCESS_EXAM;

                      return (
                        <>
                          <tbody>
                            <tr>
                              <td>{index + 1}</td>
                              <td>{dateTimeStart}</td>
                              <td>{displayDuration(durationTime)}s</td>
                              <td
                                className={
                                  isSuccess ? "text-success" : "text-danger"
                                }
                              >
                                {data.total_correct} / {data.total_question}
                              </td>
                              {playMode === PLAY_MODE.PRACTICE_V2 && (
                                <td
                                  className={
                                    isSuccess ? "text-success" : "text-danger"
                                  }
                                >
                                  {isSuccess ? "Hoàn thành" : "Chưa hoàn thành"}
                                </td>
                              )}
                              {playMode === PLAY_MODE.EXAM && (
                                <td>{data.mark}</td>
                              )}
                            </tr>
                          </tbody>
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>Bạn chưa có lịch sử làm bài</td>
                    </tr>
                  )}
                </Table>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-center">
          <Button className=" btn-sub" onClick={handleClose}>
            Đóng
          </Button>
          <Button className=" btn-pr" onClick={() => handleShowExam(keyPopup)}>
            {buttonLabel}
          </Button>
          {/*{defineAccount === 1 && (*/}
          {/*  <Button className=" btn-pr" onClick={() => handleClickAssign()}>*/}
          {/*    Giao bài*/}
          {/*  </Button>*/}
          {/*)}*/}

          {playMode === PLAY_MODE.EXAM && (
            <ShareAndCopy
              title={keyPopup.title}
              urlShareFb={urlShareFb}
              questionSetId={keyPopup.id}
            />
          )}
        </Modal.Footer>
      </Modal>

      <PopUpAssignHomeWork
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        listClassroom={listClassroom}
        onSubmit={onSubmit}
        checked={checked}
        setChecked={setChecked}
      />
    </>
  );
}

const Button = styled.button`
  min-width: 80px;
  height: 40px;
  padding: 0 10px;
  border-radius: 7px;
  /* color: white; */
`;
