import _ from "lodash";
import { useBookStore } from "stores/bookStore";
import { useAppStore } from "stores/appStore";
import { BOOK_TYPE_SACH_GIOI_THIEU } from "consts/book";
import ResultBook from "./ResultBook";
import { isMobile } from "react-device-detect";

export default function SectionSGT() {
  const books = useBookStore((state) => state.books);
  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const booksOfCurrentSubject = _.get(books, [gradeId, subjectId], []);
  const sgtBooks = booksOfCurrentSubject.filter(
    (item) => item.book_type_id === BOOK_TYPE_SACH_GIOI_THIEU
  );

  return (
    !_.isEmpty(sgtBooks) && (
      <div className={"result col-" + (isMobile ? (sgtBooks.length > 1 ? 12 : 6) : (sgtBooks.length * 3))}>
        <ResultBook
          data={sgtBooks}
          bookTypeId={BOOK_TYPE_SACH_GIOI_THIEU}
          categoryBook="Sách HD ôn thi"
          col={"col-" + (isMobile ? (sgtBooks.length > 1 ? 6 : 12)  : (12/(sgtBooks.length))) }
          // col={`col-6 ${sgtBooks.length > 2 && "col-lg-3"}`}
          background="#FFFBF7"
        />
      </div>
    )
  );
}
