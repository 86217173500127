import { getListGrade } from "edu_lms/services/app/app";
import { useEffect, useState } from "react";
import TabContent from "./TabContent";
import TabTitle from "./TabTitle";

export default function ListSubjects({ title }) {
  const [listGrade, setListGrade] = useState([]);
  useEffect(() => {
    // getListGrade().then((res) => {
    //   if (res.data.status === "success") {
    //     setListGrade(res.data.data);
    //   }
    // });
  }, []);

  return (
    <div className="hoc10-library bg-gr">
      <div className="container">
        <div className="header">
          <h2 className="title">{title}</h2>
        </div>
        <TabTitle listGrade={listGrade} />
        <div className="tab-content">
          {listGrade?.map((grade) => (
            <TabContent key={grade.id} gradeId={grade.id} />
          ))}
        </div>
      </div>
    </div>
  );
}
