import { GRAPH_TYPE } from "../constants/Graph";

const formatActivityDataGraph002 = (activity) => {
  const { game_config: gameConfig } = activity;

  const graphDataSetList = gameConfig.data.map((dataGameConfigItem) => {
    return {
      graphType: Number(dataGameConfigItem.type_graph.icon_id),
      coefficient: {
        a: Number(dataGameConfigItem.coefficient_a),
        b: Number(dataGameConfigItem.coefficient_b),
        c: Number(dataGameConfigItem.coefficient_c),
      },
      negativeCoorX: Number(dataGameConfigItem.negative_coor_x),
      negativeCoorY: Number(dataGameConfigItem.negative_coor_y),
      positiveCoorX: Number(dataGameConfigItem.positive_coor_x),
      positiveCoorY: Number(dataGameConfigItem.positive_coor_y),
    };
  });

  return graphDataSetList;
};

const getCoordinatesCorrectAnswer = (coefficient, graphType) => {
  let xA, xB, xC;
  let yA, yB, yC;
  const { a, b, c } = coefficient;
  if (graphType === GRAPH_TYPE.FirstDegreeEquation) {
    xA = 0;
    xB = 1;
    xC = 2;
    yA = a * xA + b;
    yB = a * xB + b;
    yC = a * xC + b;
  }
  if (graphType === GRAPH_TYPE.QuadraticEquation) {
    xA = Math.round(-b / (2 * a));
    xB = xA - 1;
    xC = xA + 1;
    yA = a * xA * xA + b * xA + c;
    yB = a * xB * xB + b * xB + c;
    yC = a * xC * xC + b * xC + c;

    const isIncreasingDecreasing = (a > 0 && yA < yB) || (a < 0 && yA > yB);
    if (!isIncreasingDecreasing) {
      xB = xA + 2;
      yB = a * xB * xB + b * xB + c;
    }
  }
  return { xA, yA, xB, yB, xC, yC };
};

const getNumberOfCorrectPoints = (pointA, pointB, pointC) => {
  let numberOfCorrectPoints = 0;
  if (pointA.isCorrect) {
    numberOfCorrectPoints ++;
  }
  if (pointB.isCorrect) {
    if (pointB.x !== pointA.x || pointB.y !== pointA.y) {
      numberOfCorrectPoints ++;
    }
  }
  if (pointC.isCorrect) {
    if ((pointC.x !== pointA.x || pointC.y !== pointA.y) && (pointC.x !== pointB.x || pointC.y !== pointB.y)) {
      numberOfCorrectPoints ++;
    }
  }
  return numberOfCorrectPoints;
};

export {
  formatActivityDataGraph002,
  getCoordinatesCorrectAnswer,
  getNumberOfCorrectPoints,
};
