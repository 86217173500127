import { onResultUserInfo } from "edu_lms/modules/selection";
import StudentListClass from "../StudentListClass";
import TeachingManager from "../TeachingManager";

export default function Classroom() {
  const userInfo = onResultUserInfo();
  switch (userInfo?.job_id) {
    case 1:
      return <TeachingManager />;
    case 2:
      return <StudentListClass />;
    default:
      return <div></div>;
  }
}
