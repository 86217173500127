import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styles from "../../teachingManager.module.scss";
import { postCreateUserClassroomExcel } from "edu_lms/services/teachingManager";
import { typeImport } from "edu_lms/constants/type";
import { Link } from "react-router-dom";

export default function PopupImportUserExcel({
  show,
  setShow,
  idClassroom,
  getListStudent,
  getInfoClassroom,
  type,
}) {
  const [isFormatTrue, setIsFormatTrue] = useState(true);
  const [fileName, setFileName] = useState("");
  const { register, handleSubmit, errors, reset } = useForm();
  const urlFileTemplate =
    "E_Learning/web_site/template_import_hoc_sinh_v1.xlsx";

  const onHandleSubmit = (dataForm) => {
    if (isFormatTrue) {
      const file = dataForm?.fileExcel[0];
      const data = new FormData();
      data.append("class_room_id", idClassroom);
      data.append("role_id", type);
      data.append("file", file);
      postCreateUserClassroomExcel(data).then((res) => {
        if (res.data.status === "success") {
          toast.success("Thêm thành viên thành công!");
          getListStudent();
          getInfoClassroom();
          handleClose();
        } else {
          toast.error(res.data.message);
        }
      });
      reset();
      setFileName("");
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFileName(e.target.files[0].name);
      if (
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        e.target.files[0].type === "application/vnd.ms-excel"
      ) {
        setIsFormatTrue(true);
      } else {
        setIsFormatTrue(false);
        reset();
        setFileName("");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setIsFormatTrue(true);
    setFileName("");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName={styles.modalDialog}
      backdrop="static"
    >
      <Modal.Header closeButton className="text-center">
        <p className="w-100 text-fsize font-weight-bold">
          {typeImport.find((i) => i.id === type)?.title}
        </p>
      </Modal.Header>

      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Modal.Body className="p-4 text-center">
          <div>
            <p>
              Tải tập tin định dạng .xlsx hoặc .xls theo định dạng&nbsp;
              <a
                href={`${process.env.REACT_APP_MEDIA_URL_APP_COMMON}${urlFileTemplate}`}
                className={styles.colorOrange}
              >
                sau đây
              </a>
            </p>
            <label htmlFor="fileExcel" className="mt-4">
              <div
                className={`cursor d-flex justify-content-center position-relative ${styles.uploadFile}`}
              >
                <i
                  className={`fa fa-upload d-block position-absolute ${styles.iconUpload}`}
                  aria-hidden="true"
                ></i>
                <p
                  className="monkey-fz-16 mt-auto mb-1 text-truncate"
                  title={fileName}
                >
                  {fileName}
                </p>
              </div>
              <input
                type="file"
                id="fileExcel"
                name="fileExcel"
                ref={register({ required: true })}
                accept=".xls,.xlsx"
                onChange={onChangeFile}
                hidden
              />
            </label>
            {errors.fileExcel && (
              <p className="text-danger">Vui lòng chọn một file excel</p>
            )}
            {!isFormatTrue && (
              <p className="text-danger">Vui lòng chọn file đúng định dạng</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button type="submit" className={styles.buttonOrange}>
            Tải lên
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
