import { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";

export default function ScrollAndFrequentQuestion({ refScreen }) {
  const HandleGoToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleScroll = () => {
    const CAREER_PART_SHOW = 25;
    const distanceAbove = document.documentElement?.scrollTop;
    const distanceToTop = (distanceAbove / refScreen) * 100;
    const containerItem = document.getElementById("container-scroll-top");
    distanceToTop > CAREER_PART_SHOW
      ? containerItem.classList.add("show-screen-widget")
      : containerItem.classList.remove("show-screen-widget");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [refScreen]);

  return (
    <ScrollTop onClick={HandleGoToTop}>
      <div id="container-scroll-top" className="hidden-screen-widget">
        <div className="item-goto-top">
          <img
            src="/assets/img/icon-scroll-top.svg"
            alt="go-to-top"
            className="goToTop"
          />
        </div>
        <Link to={PATH.ROUTE_PATH_V3_FREQUENT_QUESTION}>
          <div className="item-frequent">
            <img
              src="/assets/img/icon-frequent-question.svg"
              alt="icon-frequent-question"
              className="frequentQuestion"
            />
          </div>
        </Link>
      </div>
    </ScrollTop>
  );
}

const ScrollTop = styled.div`
  position: fixed;
  right: 30px;
  bottom: 130px;
  cursor: pointer;
  z-index: 20;
  .show-screen-widget {
    display: block !important;
  }
  .hidden-screen-widget {
    display: none;
  }
  .item-goto-top,
  .item-frequent {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 55px;
    height: 55px;
    background: #ff7707;
    align-items: center;
    border-radius: 50%;
    .goToTop {
      width: 14px;
      height: 20px;
    }
    .frequentQuestion {
      width: 24px;
      height: 24px;
    }
    @media (max-width: 992px) {
      right: 10px;
    }
  }
  @media (max-width: 768px) {
    right: 20px;
    .item-goto-top,
    .item-frequent {
      width: 40px;
      height: 40px;

      .goToTop {
        width: 14px;
        height: 16px;
      }
      .frequentQuestion {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
