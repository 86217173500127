import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useDispatch } from "react-redux";
import { fabric } from "fabric";
import styled from "styled-components";
import { onDispatchSrcAudio } from "edu_lms/modules/App/actions";
import { AUDIO_ERROR, AUDIO_SUCCESS } from "edu_lms/constants/type";
import TitleQuestion from "./TitleQuestion";
import QuestionHeader from "./QuestionHeader";
import QuestionContent from "./QuestionContent";
import AnswerContent from "./AnswerContent";
import { COLOR } from "../../constants/styles";
import { TYPE_DISPLAY, SPACE_AREA, TYPE_QUESTION_3_COLUMNS, BOX_SIZE_WRAPPER } from "../../constants/MAT_001";
import { getNumberOffSetOfQuestionTarget, getNumberOffSetOfAnswerTarget, getClassNameCoverCanvas, checkResultAnswer } from "../../helpers/MAT_001";

const DEFAULT_LINE_COLOR = "blue";
const DEFAULT_MATCH_POINTS = {
  question: { id: null, x: null, y: null },
  answer: { id: null, x: null, y: null },
};

const MAT_001 = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAnswersProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const {
    typeDisplay,
    typeGame,
    titleQuestion,
    answersMatch,
    questionsMatch,
    couplesAnswerQuestion,
    fontSizeContent,
    typeText,
    columnTitleLeft,
    columnTitleRight,
  } = gameData;

  const canvasId = `match-001-canvas-${Math.random()}`;
  const dispatch = useDispatch();

  const [canvas, setCanvas] = useState(null);
  const [matchPoints, setMatchPoints] = useState(DEFAULT_MATCH_POINTS);
  const [matchedCouplePoints, setMatchedCouplePoints] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    const box = document.getElementById("cover-canvas");
    const canvas = new fabric.Canvas(canvasId, {
      height: box.clientHeight,
      width: box.clientWidth,
    });
    setCanvas(canvas);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeDisplay, typeGame, couplesAnswerQuestion.length]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);

    const { isCorrect, numberOfCorrectMatched, matchedCouplePointsResult} = checkResultAnswer(matchedCouplePoints, couplesAnswerQuestion);
    setMatchedCouplePoints(matchedCouplePointsResult);
    
    !hideResultAnswer && dispatch(onDispatchSrcAudio(isCorrect ? AUDIO_SUCCESS : AUDIO_ERROR));

    removeAllLines();
    drawLineMatchedPoints(matchedCouplePointsResult);

    onComplete({ selectedAnswers: matchedCouplePoints, isCorrect, numberOfCorrectMatched });
  }

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setMatchedCouplePoints([]);
    setIsViewOnly(false);
    removeAllLines();
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  useEffect(() => {
    if (canvas && selectedAnswersProp.length > 0) {
      setIsCheckedAnswer(true);
      removeAllLines();
      setMatchedCouplePoints(selectedAnswersProp);
      drawLineMatchedPoints(selectedAnswersProp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, selectedAnswersProp]);

  useEffect(() => {
    if (canvas && showCorrectAnswer) {
      setIsCheckedAnswer(true);
      const correctCouplesAnswerQuestion = couplesAnswerQuestion.map(
        (couple) => ({ ...couple, isCorrect: true })
      );
      removeAllLines();
      setMatchedCouplePoints(correctCouplesAnswerQuestion);
      drawLineMatchedPoints(correctCouplesAnswerQuestion);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, showCorrectAnswer]);

  const createLineCanvas = (lineCoords = [], stroke = "", lineId = null) => {
    if (!canvas) return;
    const line = new fabric.Line(lineCoords, {
      stroke: stroke,
      strokeWidth: 2,
      hasControls: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: "default",
      id: lineId,
    });
    canvas.add(line);
  };

  const drawLineMatchedPoints = (matchedCouplePointsResult = []) => {
    matchedCouplePointsResult.forEach(matchedCouple => {
      const { questionId, answerId, isCorrect } = matchedCouple;
      const boxMatchQuestionTarget = document.getElementById(`box-match-${questionId}`);
      const boxMatchAnswerTarget = document.getElementById(`box-match-${answerId}`);
      const { numberOffSetX: questionX, numberOffSetY: questionY } = getNumberOffSetOfQuestionTarget(boxMatchQuestionTarget, typeDisplay);
      const { numberOffSetX: answerX, numberOffSetY: answerY } = getNumberOffSetOfAnswerTarget(boxMatchAnswerTarget, typeDisplay);

      const lineCoords = [questionX, questionY, answerX, answerY];
      let strokeColor = DEFAULT_LINE_COLOR;
      if (!hideResultAnswer) {
        strokeColor = isCorrect ? COLOR.Success : COLOR.Error;
      }
      createLineCanvas(lineCoords, strokeColor);
    });
  }

  const isSelectRightQuestionInTypeDisplayVertical3Columns = (questionId) => {
    if (typeDisplay !== TYPE_DISPLAY.Vertical3Columns) return false;
    const questionsMatchRight = questionsMatch.slice(questionsMatch.length / 2);
    return questionsMatchRight.some((question) => question.id === questionId);
  };

  const handleSelectQuestion = (e, questionId, typeQuestion3Columns = null) => {
    if (isCheckedAnswer) {
      handleResetAnswer();
    }

    const { numberOffSetX, numberOffSetY } = getNumberOffSetOfQuestionTarget(e.target, typeDisplay, typeQuestion3Columns);
    setMatchPoints({
      ...matchPoints,
      question: { id: questionId, x: numberOffSetX, y: numberOffSetY },
    });

    const hasMatchQuestionRight = isSelectRightQuestionInTypeDisplayVertical3Columns(questionId);
    if (hasMatchQuestionRight && matchPoints.answer.id) {
      setMatchPoints({
        question: { id: questionId, x: numberOffSetX, y: numberOffSetY },
        answer: {
          ...matchPoints.answer,
          x: matchPoints.answer.x + BOX_SIZE_WRAPPER.Vertical3Columns.Width,
        },
      });
    }
  };

  const handleSelectAnswer = (e, answerId) => {
    if (isCheckedAnswer) {
      handleResetAnswer();
    }

    const hasMatchQuestionRight = isSelectRightQuestionInTypeDisplayVertical3Columns(matchPoints.question.id);
    const typeQuestion3Columns = hasMatchQuestionRight ? TYPE_QUESTION_3_COLUMNS.Right : TYPE_QUESTION_3_COLUMNS.Left;

    const { numberOffSetX, numberOffSetY } = getNumberOffSetOfAnswerTarget(e.target, typeDisplay, typeQuestion3Columns);
    setMatchPoints({
      ...matchPoints,
      answer: { id: answerId, x: numberOffSetX, y: numberOffSetY },
    });
  };

  useEffect(() => {
    drawLine();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchPoints]);

  const removeLineById = (id) => {
    const lineObjects = canvas.getObjects("line");
    lineObjects.forEach((item) => {
      if (item.id === id) {
        canvas.remove(item);
      }
    });
  };
  const removeAllLines = () => {
    const lineObjects = canvas.getObjects("line");
    lineObjects.forEach((item) => {
      canvas.remove(item);
    });
  };

  const drawLine = () => {
    if (!matchPoints.question.id || !matchPoints.answer.id) return;

    // Remove same click question/ answer and update matched couple points
    const newMatchedCouplePoints = [...matchedCouplePoints];
    const indexQuestionSameClick = matchedCouplePoints.findIndex(couplePoint => couplePoint.questionId === matchPoints.question.id);
    if (indexQuestionSameClick > -1) {
      const questionSameClick = matchedCouplePoints[indexQuestionSameClick];
      removeLineById(`${questionSameClick.questionId},${questionSameClick.answerId}`);
      newMatchedCouplePoints.splice(indexQuestionSameClick, 1);
    }
    const indexAnswerSameClick = matchedCouplePoints.findIndex(couplePoint => couplePoint.answerId === matchPoints.answer.id);
    if (indexAnswerSameClick > -1) {
      const answerSameClick = matchedCouplePoints[indexAnswerSameClick];
      removeLineById(`${answerSameClick.questionId},${answerSameClick.answerId}`);
      newMatchedCouplePoints.splice(indexAnswerSameClick, 1);
    }
    newMatchedCouplePoints.push({ questionId: matchPoints.question.id, answerId: matchPoints.answer.id });
    setMatchedCouplePoints(newMatchedCouplePoints);

    // Draw line
    const lineCoords = [matchPoints.question.x, matchPoints.question.y, matchPoints.answer.x, matchPoints.answer.y];
    const lineId = `${matchPoints.question.id},${matchPoints.answer.id}`;
    createLineCanvas(lineCoords, DEFAULT_LINE_COLOR, lineId);

    setMatchPoints(DEFAULT_MATCH_POINTS);

    // Action to enable check button
    const isMatchAllCouple = newMatchedCouplePoints.length === questionsMatch.length;
    onPlaying(isMatchAllCouple);
  }

  return (
    <MatchContainerWrapper>
      <TitleQuestion titleQuestion={titleQuestion} />
      {typeDisplay === TYPE_DISPLAY.Horizontal && (
        <QuestionHeader questionsMatch={questionsMatch} typeGame={typeGame} />
      )}
      <div
        id="cover-canvas"
        className={`position-relative mb-5 quicksand-medium ${getClassNameCoverCanvas(typeDisplay)}`}
      >
        <canvas id={canvasId} />
        {typeDisplay !== TYPE_DISPLAY.Vertical3Columns && (
          <>
            <QuestionContent
              questionsMatch={questionsMatch}
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              columnTitleLeft={columnTitleLeft}
              matchedCouplePoints={matchedCouplePoints}
              isCheckedAnswer={isCheckedAnswer}
              hideResultAnswer={hideResultAnswer}
              isReadOnly={isReadOnly || isViewOnly}
              onSelectQuestion={handleSelectQuestion}
            />
            <SpaceArea />
            <AnswerContent
              answersMatch={answersMatch}
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              columnTitleRight={columnTitleRight}
              matchedCouplePoints={matchedCouplePoints}
              isCheckedAnswer={isCheckedAnswer}
              hideResultAnswer={hideResultAnswer}
              isReadOnly={isReadOnly || isViewOnly}
              onSelectAnswer={handleSelectAnswer}
            />
          </>
        )}
        {typeDisplay === TYPE_DISPLAY.Vertical3Columns && (
          <>
            <QuestionContent
              questionsMatch={questionsMatch.slice(0, questionsMatch.length / 2)}
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              columnTitleLeft={columnTitleLeft}
              matchedCouplePoints={matchedCouplePoints}
              isCheckedAnswer={isCheckedAnswer}
              hideResultAnswer={hideResultAnswer}
              isReadOnly={isReadOnly || isViewOnly}
              onSelectQuestion={(e, questionId) => handleSelectQuestion(e, questionId, TYPE_QUESTION_3_COLUMNS.Left)}
            />
            <SpaceAreaThreeColumns />
            <AnswerContent
              answersMatch={answersMatch}
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              columnTitleRight={columnTitleRight}
              matchedCouplePoints={matchedCouplePoints}
              isCheckedAnswer={isCheckedAnswer}
              hideResultAnswer={hideResultAnswer}
              isReadOnly={isReadOnly || isViewOnly}
              onSelectAnswer={handleSelectAnswer}
            />
            <SpaceAreaThreeColumns />
            <QuestionContent
              questionsMatch={questionsMatch.slice(questionsMatch.length / 2)}
              typeDisplay={typeDisplay}
              typeGame={typeGame}
              typeText={typeText}
              fontSizeContent={fontSizeContent}
              columnTitleLeft={columnTitleLeft}
              matchedCouplePoints={matchedCouplePoints}
              isCheckedAnswer={isCheckedAnswer}
              hideResultAnswer={hideResultAnswer}
              isReadOnly={isReadOnly || isViewOnly}
              onSelectQuestion={(e, questionId) => handleSelectQuestion(e, questionId, TYPE_QUESTION_3_COLUMNS.Right)}
            />
          </>
        )}
      </div>
    </MatchContainerWrapper>
  );
};

export default forwardRef(MAT_001);

const MatchContainerWrapper = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 92%;
  .cover-canvas-vertical {
    width: 840px;
    height: auto;
    margin: auto;
  }
  .answer-wrapper,
  .question-wrapper {
    z-index: 12;
    position: relative;
    .box-item {
      &:hover {
        background-color: rgba(102, 217, 255, 0.2) !important;
      }
    }
  }
  .canvas-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 11;
  }
`;

const SpaceArea = styled.div`
  height: ${SPACE_AREA.Height}px;
  width: ${SPACE_AREA.Width}px;
`;

const SpaceAreaThreeColumns = styled.div`
  height: ${SPACE_AREA.Height}px;
  width: ${SPACE_AREA.WidthThreeColumns}px;
`;