const LoadingComponent = ({ isBgPopupLoading }) => {
  return (
    <section
      id="popup-loading"
      className="popup-loading"
      style={{
        backgroundColor: `${
          isBgPopupLoading ? "transparent" : "rgba(89, 90, 92, 0.66)"
        }`,
        zIndex: `${isBgPopupLoading ? -1 : 9999}`,
      }}
    >
      <div className="popup-loading-content distance-center">
        <div className="text-center">
          <div className="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LoadingComponent;
