import {
  POPUP_AFTER_TRAINING,
  POPUP_BEFORE_TRAINING,
  POPUP_IN_TRAINING,
} from "edu_lms/constants/type";

export const handleForceOpenTraining = (trainingInfo, isUserHoc10) => {
  const { popup_id, content, time_start, time_end } = trainingInfo;
  const data = {
    isBeforeTraining: popup_id === POPUP_BEFORE_TRAINING,
    // isOpenTraining: popup_id === POPUP_IN_TRAINING || isUserHoc10,
    isOpenTraining: popup_id === POPUP_IN_TRAINING,
    isAfterTraining: popup_id === POPUP_AFTER_TRAINING,
    content: content,
    timeStart: time_start,
    timeEnd: time_end,
    popupId: popup_id,
  };

  localStorage.setItem("trainingConfig", JSON.stringify(data));
};
