import _ from "lodash";
import Pagination from "../FrequentQuestion/Pagination";

export const PER_PAGE = 20;
export default function Table({ shops, currentPage, setCurrentPage }) {
  let data = shops;

  if (shops.length > PER_PAGE) {
    data = _.chunk(shops, PER_PAGE)[currentPage - 1];
  }

  return (
    <>
      <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-2 table-responsive">
        <table responsive className="table table-hover" striped="columns">
          <thead>
            <tr>
              <th className="text-center border-right border-top-0">STT</th>
              <th className="text-center border-right border-top-0">
                TÊN ĐƠN VỊ
              </th>
              <th className="text-center border-right border-top-0">ĐỊA CHỈ</th>
              <th className="text-center border-right border-top-0">
                ĐIỆN THOẠI
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const indexPage = (currentPage - 1) * PER_PAGE + index + 1;
              return (
                <tr key={index}>
                  <td className="text-center" scope="row">
                    {indexPage}
                  </td>
                  <td className="text-left">{item.name}</td>
                  <td className="text-left">{item.address}</td>
                  <td className="text-left">
                    <a href={`tel:${item.phone}`}>{item.phone}</a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 text-center">
        {shops.length > PER_PAGE && (
          <Pagination
            totalBooks={shops.length}
            totalPerPage={PER_PAGE}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
}
