const ORDER_ANSWER_INDEX = {
  Default: {
    color: "#000",
    background: "transparent",
  },
  Selected: {
    color: "#fff",
    background: "#00c2f3",
  },
  Correct: {
    color: "#000",
    background: "#92c83e",
  },
  Wrong: {
    color: "#fff",
    background: "#ee202e",
  },
  NoSelect: {
    color: "#fff",
    background: "#939598",
  },
};

const ORDER_ANSWER_NO_INDEX = {
  Default: {
    color: "rgb(238, 238, 238)",
    background: "transparent",
  },
  Selected: {
    color: "rgb(255, 119, 0)",
    background: "transparent",
  },
  Correct: {
    color: "#23bf2d",
    background: "rgb(226, 255, 214)",
  },
  Wrong: {
    color: "red",
    background: "rgb(253, 224, 205)",
  },
};

const LABEL_ANSWER_NUMBER = {
  0: "1",
  1: "2",
  2: "3",
  3: "4",
  4: "5",
  5: "6",
  6: "7",
  7: "8",
  8: "9",
  9: "10",
  10: "11",
  11: "12",
  12: "13",
  13: "14",
  14: "15",
  15: "16",
  16: "17",
  17: "18",
  18: "19",
};

const LABEL_ANSWER = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "M",
  12: "N",
  13: "O",
  14: "P",
  15: "Q",
  16: "R",
  17: "S",
  18: "T",
};

const LABEL_ANSWER_VIETNAMESE = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "G",
  6: "H",
  7: "I",
  8: "K",
  9: "L",
  10: "M",
  11: "N",
  12: "O",
};

export {
  ORDER_ANSWER_INDEX,
  ORDER_ANSWER_NO_INDEX,
  LABEL_ANSWER_NUMBER,
  LABEL_ANSWER,
  LABEL_ANSWER_VIETNAMESE,
};
