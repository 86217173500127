import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { onDispatchShowPopupActivateBook } from "../../modules/App/actions";
import { WIDTH_BUTTON } from "../../constants/type";

const TeachingInformation = ({
  onDispatchShowPopupActivateBook,
  statusModal,
}) => {
  const onClosePopup = () => {
    onDispatchShowPopupActivateBook(!statusModal);
  };
  const onNextQuestion = () => {};

  return (
    <div className="content-exam-exercises-wrapper p-4">
      <div className="exam-exercises-body mt-5 pt-5 pb-5 "></div>
      <div
        style={{ width: "100%", border: "1px solid #ddd", margin: "2rem auto" }}
      ></div>
      <div className="exam-exercises-footer d-flex justify-content-between mt-3 mb-3">
        <button
          onClick={onClosePopup}
          style={{ width: `${WIDTH_BUTTON}` }}
          className="btn monkey-bc-violet hvr-registration-white monkey-color-violet monkey-f-bold text-uppercase"
        >
          thêm lớp
        </button>
        <button
          onClick={onNextQuestion}
          style={{ width: `${WIDTH_BUTTON}` }}
          className="btn monkey-bg-violet monkey-color-white hvr-registration-white text-uppercase"
        >
          cập nhật
        </button>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchShowPopupActivateBook,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(TeachingInformation);
