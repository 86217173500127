import { createStore } from "little-state-machine";

const defaultValues = {
  phone: "",
  password: "",
  confirmPassword: "",
  job_id: "",
  full_name: "",
  email: "",
  teaching: [],
};

export const MIN_TIME_OTP_RESEND = 60;
export const ACCEPT_TIME_OTP_RESEND = 0;

export const initStore = () => {
  createStore(defaultValues);
};

export const updateState = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

export const resetState = (state, payload) => {
  return {
    ...state,
    ...defaultValues,
  };
};
