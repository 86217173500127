import React from "react";
import { COLOR_RED } from "../../constants/type";
import { Controller } from "react-hook-form";

const InputForm = ({ item, newRules, errors, nameControl }) => {
  return (
    <div className={item.data.className}>
      <div className="form-group position-relative">
        <label>
          {item.value} <span className="monkey-color-red">*</span>
        </label>
        <Controller
          as={
            <input
              className="form-control"
              type={item.data.type}
              id={item.data.name}
              placeholder={item.data.placeholder}
              autoComplete="off"
            />
          }
          rules={{
            required: item.data.required,
            pattern: {
              value: item.data.pattern,
              message: item.data.valueError,
            },
          }}
          name={item.data.name}
          control={nameControl}
          defaultValue=""
        />

        {item.data.error && errors && (
          <p
            style={{
              position: "absolute",
              bottom: "-25px",
              left: 0,
              color: COLOR_RED,
            }}
          >
            {errors}
          </p>
        )}
      </div>
    </div>
  );
};

export default InputForm;
