import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import TermDetails from "./TermDetails";

export default function Term() {
  useScrollToTop();
  return (
    <main>
      <SEO data={pages.term} />
      <div className="hoc10-breadcrumb bg-gray">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Hỗ trợ
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Điều khoản sử dụng
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-contact bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-3">
              <ul className="tab nav hoc10-tab">
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_PRIVACY_POLICY}>
                    <i className="icon icon-policy"></i>
                    <span>Chính sách bảo mật</span>
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_TERM_OF_USE} className="active">
                    <i className="icon icon-term"></i>
                    <span>Điều khoản sử dụng</span>
                  </Link>
                </li>
                <li>
                  <Link to={PATH.ROUTE_PATH_V3_CONTACT}>
                    <i className="icon icon-phone"></i>
                    <span>Liên hệ</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-9 col-9">
              <div className="hoc10-tab-content">
                <div id="term" className="tab-pane">
                  <div className="term h1Seo">
                    <h1 className="title">Điều khoản sử dụng</h1>
                    <TermDetails />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hoc10-bookaccess hoc10-bookaccess--cs1">
        <div className="container">
          <div className="content">
            <h3>Đăng ký ngay để trải nghiệm SGK Cánh Diều phiên bản điện tử</h3>

            <Link
              title="Truy cập vào tủ sách"
              className="btn-access flex-center"
              to={PATH.ROUTE_PATH_TU_SACH}
            >
              Truy cập vào tủ sách
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}
