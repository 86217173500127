import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getDirectionDroppable,
  getClassNameWrapperDraggable,
  getListStyle,
  reorderAnswers,
  moveAnswers,
} from "../../helpers/SQC_001";
import { Audio, AudioType } from "../../components";
import BoxDraggable from "./BoxDraggable";

const ContentAnswer = ({
  answer,
  sequencedAnswers,
  isViewOnly = false,
  hideResultAnswer = false,
  onDragAnswersEnd = () => {},
}) => {
  const { typeAnswer, typeDisplay } = answer;
  const [srcAudio, setSrcAudio] = useState("");

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    let reorderedSequenceAnswers = [];
    if (sequencedAnswers.length === 1) {
      reorderedSequenceAnswers = [reorderAnswers(
        sequencedAnswers[0],
        source.index,
        destination.index
      )];
    } else {
      if (source.droppableId === destination.droppableId) {
        const reorderedSequenceAnswersItem = reorderAnswers(
          sequencedAnswers[Number(source.droppableId)],
          source.index,
          destination.index
        );
        const sequencedAnswersShadow = [...sequencedAnswers];
        sequencedAnswersShadow[Number(source.droppableId)] = reorderedSequenceAnswersItem;
        reorderedSequenceAnswers = [sequencedAnswersShadow[0], sequencedAnswersShadow[1]];
      } else {
        const sequencedAnswersShadow = moveAnswers(
          sequencedAnswers[Number(source.droppableId)],
          sequencedAnswers[Number(destination.droppableId)],
          source,
          destination
        );
        reorderedSequenceAnswers = [sequencedAnswersShadow[0], sequencedAnswersShadow[1]];
      }
    }

    onDragAnswersEnd(reorderedSequenceAnswers);
  };

  return (
    <div
      className={`content-answer__wrapper quicksand-medium ${
        isViewOnly ? "view-only" : ""
      }`}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {sequencedAnswers.map((sequencedAnswersItem, ansItemIndex) => {
          return (
            <Droppable
              key={ansItemIndex}
              droppableId={`${ansItemIndex}`}
              direction={getDirectionDroppable(typeDisplay)}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={getClassNameWrapperDraggable(typeDisplay)}
                  style={getListStyle(snapshot.isDraggingOver, typeDisplay)}
                >
                  {sequencedAnswersItem.map((answerItem, index) => {
                    return (
                      <Draggable
                        key={answerItem.answerId}
                        draggableId={answerItem.answerId}
                        index={index}
                      >
                        {(provided, _snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <BoxDraggable
                              answerItem={answerItem}
                              typeAnswer={typeAnswer}
                              typeDisplay={typeDisplay}
                              hideResultAnswer={hideResultAnswer}
                              onClickAudio={(srcAudioItem) =>
                                setSrcAudio(srcAudioItem)
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
      <Audio
        variant={AudioType.Secondary}
        className="d-none"
        src={srcAudio}
        autoPlay
        onEnded={() => setSrcAudio("")}
      />
    </div>
  );
};

export default ContentAnswer;
