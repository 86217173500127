import { create } from "zustand";
import _ from "lodash";
import { getListGrade, getListSubject } from "edu_lms/services/app/app";
import { getDataUrlListProvince } from "edu_lms/services/info/info";
import { getListSubjectWeek } from "edu_lms_v2/services/listBook";

export const useAppStore = create((set) => ({
  provinces: [],
  grades: [],
  gradeId: null,
  subjects: [],
  subjectId: null,
  studyWeeks: [],
  studyWeekId: null,

  // state: subjects
  setSubjects: (subjects) => set({ subjects }),
  fetchSubjects: async (parentId = 0) => {
    const res = await getListSubject(parentId);
    if (res.status === 200) {
      const subjects = _.isArray(res?.data?.data?.list_subject)
        ? res?.data?.data?.list_subject
        : [];
      set({ subjects });
    }
  },

  // state: grades
  setGrades: (grades) => set({ grades }),
  fetchGrades: async () => {
    const res = await getListGrade(0,true);
    if (res.status === 200) {
      const grades = _.isArray(res?.data?.data) ? res?.data?.data : [];
      set({ grades });
    }
  },

  // state: provinces
  setProvinces: (provinces) => set({ provinces }),
  fetchProvinces: async (parentId = 0) => {
    const res = await getDataUrlListProvince(parentId);
    if (res.status === 200) {
      const provinces = _.isArray(res?.data?.data) ? res?.data?.data : [];
      set({ provinces });
    }
  },

  // state: gradeId
  setGradeId: (gradeId) => set({ gradeId }),

  // state: subjectId
  setSubjectId: (subjectId) => set({ subjectId }),

  // state: studyWeekId
  setStudyWeekId: (studyWeekId) => set({ studyWeekId }),

  // state: studyWeeks
  fetchStudyWeeks: async () => {
    const res = await getListSubjectWeek();
    if (res.status === 200) {
      const studyWeeks = _.isArray(res?.data?.data?.data)
        ? res?.data?.data?.data
        : [];
      set({ studyWeeks });
    }
  },
}));
