import Modal from "react-bootstrap/Modal";
import error_img from "edu_lms_v2/assets/img/img-error.svg";

export default function ModalRequireLogin(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src={error_img} alt="Yêu cầu đăng nhập" />
        </div>
        <div className="text">Bạn chưa đăng nhập</div>
        <a
          title="Truy cập tủ sách"
          className="btn-pr flex-center btn-access cursor"
          onClick={props.onRequireLogin}
        >
          Đăng nhập
        </a>
      </div>
    </Modal>
  );
}
