export const STATUS_ACTIVATED = "activated";
export const STATUS_ACTIVATED_NEW = "activated_new";
export const STATUS_DEACTIVATED = "deactivated";

export const ACCESSIBILITY_PRIVATE = "private";
export const ACCESSIBILITY_PUBLIC = "public";

export const SCREEN_SIZE_MOBILE = "mobile";
export const SCREEN_SIZE_TABLET = "tablet";
export const SCREEN_SIZE_DESKTOP = "desktop";

export const USER_ROLE_TEACHER = "teacher";
export const USER_ROLE_STUDENT = "student";
export const USER_ROLE_PARENT = "parent";
export const USER_ROLE_HOC10 = "hoc10";
export const USER_ROLE_ANONYMOUS = "anonymous";

export const HTTP_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  UNAUTHENTICATED: 401,
  UNAUTHORIZED: 403,
  GEN_UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_TEMPORARILY_OVERLOADED: 502,
  SERVICE_UNAVAILABLE: 503,
  CONFLICT: 409,
  TOO_MANY_REQUEST: 429,
};

export const DIRECTION_VERTICAL = "vertical";
export const DIRECTION_HORIZONTAL = "horizontal";
