import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserInfo } from "edu_lms/services/signIn/signIn";
import { getDataUrlListProvince } from "edu_lms/services/info/info";
import { LIST_PROVINCE_ALL } from "edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import { onResultUserInfo } from "edu_lms/modules/selection";
import * as PATH from "consts/routePaths";
import PersonalInformation from "./components/PersonalInformation";
import SchoolInformation from "./components/SchoolInformation";
import ClassInformation from "./components/ClassInformation";

export default function UpdateInformationTraining(props) {
  useScrollToTop();

  const history = useHistory();
  const [dataUser, setDataUser] = useState([]);
  const [listProvince, setListProvince] = useState([]);
  const [showPersonal, setShowPersonal] = useState(1);
  const dataUserInfo = onResultUserInfo();
  const dataForm = useSelector((state) => state.signInReducers);

  useEffect(() => {
    getDataUrlListProvince(LIST_PROVINCE_ALL)
      .then((res) => {
        if (res.data.status === "success") {
          setListProvince(res.data.data);
          getUserInfoApi();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataForm]);
  const getUserInfoApi = (checkInfor) => {
    props?.checkUpdateInfor === "school"
      ? setShowPersonal(2)
      : props?.checkUpdateInfor === "class"
      ? setShowPersonal(3)
      : setShowPersonal(1);
    if (localStorage.getItem("token")) {
      getUserInfo().then((res) => {
        (dataForm.user_info.phone != "" || dataUserInfo.phone != null) &&
        (dataForm.user_info.email != "" || dataUserInfo.email != null)
          ? (checkInfor = 0)
          : (checkInfor = 1);
        if (res.data.status === "success") {
          setDataUser(res.data.data);
          if (props?.checkInformation === 1) {
            if (
              checkInfor == 1 ||
              // !res.data.data.full_name ||
              !res.data.data.job_id ||
              (!dataForm?.email && !dataUserInfo?.email) ||
              (!dataForm?.phone && !dataUserInfo?.phone)
            ) {
              setShowPersonal(1);
            } else if (
              !res.data.data.province_name ||
              !res.data.data.district_name ||
              !res.data.data.ward_id_name ||
              !res.data.data.school_name
            ) {
              setShowPersonal(2);
            } else if (!res.data.data.list_grade_subject[0]) {
              setShowPersonal(3);
            }
          }
        }
      });
    } else {
      history.push(PATH.ROUTE_PATH_V3_SIGN_IN);
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tab-content hoc10-tab-content">
              {showPersonal === 1 ? (
                <PersonalInformation
                  dataUser={dataUser}
                  showPersonal={showPersonal}
                  getUserInfoApi={getUserInfoApi}
                  setShowPersonal={setShowPersonal}
                  dataForm={dataForm}
                  onUpdateSuccess={props.onUpdateSuccess}
                  onHide={props.onHide}
                />
              ) : showPersonal === 2 ? (
                <SchoolInformation
                  dataUser={dataUser}
                  listProvince={listProvince}
                  resetForm={getUserInfoApi}
                  showPersonal={showPersonal}
                  setShowPersonal={setShowPersonal}
                  dataUserInfo={dataUserInfo}
                  dataForm={dataForm}
                  onUpdateSuccess={props.onUpdateSuccess}
                  onHide={props.onHide}
                />
              ) : (
                showPersonal === 3 && (
                  <ClassInformation
                    dataUser={dataUser}
                    setShowPersonal={setShowPersonal}
                    showPersonal={showPersonal}
                    dataUserInfo={dataUserInfo}
                    dataForm={dataForm}
                    onGetDataHistory={props.onGetDataHistory}
                    onUpdateSuccess={props.onUpdateSuccess}
                    onHide={props.onHide}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
