import { Link } from "react-router-dom";
import styled from "styled-components";
import * as PATH from "consts/routePaths";
import { useHistory } from "react-router-dom";

export default function NavbarDesktop({ menuItems, onActiveBook }) {
  const history = useHistory();
  const location = history.location.pathname;
  const menuHeader = menuItems.map((item) => {
    let isActive = false;
    if (item.subMenu) {
      item.subMenu.forEach((pathSubmenu) => {
        if (pathSubmenu.path === location) {
          isActive = true;
        }
      });
    }
    return { ...item, isActive: isActive || item.path === location };
  });

  return (
    <ListItem className="navbar-nav" id="navbar-desktop-screen">
      {menuHeader.map((menuItem, index) => {
        if (menuItem.isMobileOnly) return null;

        if (menuItem.path === PATH.ROUTE_BLOG_KNOWLEDGE) {
          return null;
        }
        //open sang tab moi theo order cua team hoc10
        if(menuItem?.isRedirect) {
          return (
            <li><a title={menuItem.title} target="_blank" href={menuItem.path}>{menuItem.title}</a></li>
          )
        }

        if (menuItem.isActiveBook) {
          return (
            <li id="active-book-mobi-screen" key={index}>
              <a
                href="javascript:;"
                title="Kích hoạt sách"
                className="activated flex-center"
                onClick={onActiveBook}
              >
                <i className="icon icon-key"></i> Kích hoạt sách
              </a>
            </li>
          );
        }

        if ([PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST, PATH.ROUTE_PATH_COURSE].includes(menuItem.path)) {
          return (
            <li key={index}>
              <Link
                to={menuItem.path}
                title={menuItem.title}
                onClick={menuItem.eventClick || null}
                className={`position-relative mr-3 ${
                  menuItem.isActive && "active"
                }`}
              >
                {menuItem.title}
                {/*<img*/}
                {/*  src="/assets/img/icon-new.svg"*/}
                {/*  alt="img-new"*/}
                {/*  className="position-absolute icon-new"*/}
                {/*/>*/}
              </Link>
            </li>
          );
        }

        return (
          <li key={index}>
            {menuItem.path && (
              <Link
                to={menuItem.path}
                title={menuItem.title}
                onClick={menuItem.eventClick || null}
                className={menuItem.isActive && "active"}
              >
                {menuItem.title}
              </Link>
            )}
            {!menuItem.path && (
              <a
                href="javascript:;"
                title={menuItem.title}
                className={menuItem.isActive && "active"}
              >
                {menuItem.title}
              </a>
            )}
            {menuItem.subMenu && (
              <>
                <ul className="sub-menu bg-sh">
                  {menuItem.subMenu.map((subMenu, subIdx) => {
                    if (!subMenu.show) return null;
                    const shopBooks =
                      subMenu.path === PATH.ROUTE_PUBLISHER_LIST;
                    if (shopBooks) {
                      return (
                        <li key={subIdx}>
                          <Link to={subMenu.path}>
                            <i
                              className={subMenu.iconClass}
                              aria-hidden="true"
                            />
                            {subMenu.title}
                          </Link>
                        </li>
                      );
                    }
                    return (
                      <li key={subIdx}>
                        <Link to={subMenu.path}>
                          <i className="icon">
                            <img src={subMenu.iconSrc} alt={subMenu.title} />
                          </i>
                          {subMenu.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <span className="show-menu"></span>
              </>
            )}
          </li>
        );
      })}
    </ListItem>
  );
}

const ListItem = styled.ul`
  .active {
    color: #ff7707 !important;
  }
  .icon-new {
    width: 20px;
    top: 10px;
    right: -17px;
  }
  z-index: 9;
`;
