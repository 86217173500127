import { TYPE_GAME } from "edu_lms_v2/libraries/hoc10Game/constants";

const getGameNameByGameId = (gameId) => {
  for (const [key, value] of Object.entries(TYPE_GAME)) {
    if (value === gameId) return key;
  }
  return "";
};

export { getGameNameByGameId };
