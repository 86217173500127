import React from "react";
import { StaticMathField } from "react-mathquill";

const KeyCalculatorButton = ({ keyCalculator, onClick, backgroundColor }) => {
  return (
    <button
      className="key"
      onClick={() => onClick(keyCalculator.keyValue)}
      style={{ backgroundColor }}
    >
      {keyCalculator.picture ? (
        <img
          className="image"
          src={keyCalculator.picture}
          alt="key-calculator"
        />
      ) : (
        <StaticMathField style={{ fontSize: keyCalculator.fontSize }}>
          {keyCalculator.label}
        </StaticMathField>
      )}
    </button>
  );
};

export default KeyCalculatorButton;
