import { useState } from "react";
import classNames from "classnames";
import _ from "lodash";
import styled from "styled-components";
import LoginSuccessModal from "../Login/LoginSuccessModal";
import LoginWrapper from "../Login/LoginWrapper";
import * as PATH from "consts/routePaths";
import { Link } from "react-router-dom";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import MessPhonePw from "../ForgotPassword/MessPhonePw";
import ResetPw from "../ForgotPassword/ResetPw";
import SuccessPw from "../ForgotPassword/SuccessPw";

const Logo = styled.img`
  width: 112px;
`;

export default function HeaderRegister({ currentStep, totalStep }) {
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [loginSuccessModalShow, setLoginSuccessModalShow] = useState(false);
  const onLoginSuccess = () => {
    setLoginModalShow(false);
    setLoginSuccessModalShow(true);
  };
  const onActiveForgotPw = (event) => {
    event?.preventDefault();
    setLoginModalShow(false);
    setActiveForgotPw(true);
  };
  const [isActiveForgotPw, setActiveForgotPw] = useState(false);
  const [isActiveMessPhonePw, setActiveMessPhonePw] = useState(false);
  const [isActiveResetPw, setActiveResetPw] = useState(false);
  const [isActiveSuccessPw, setActiveSuccessPw] = useState(false);
  const [tokenToChangePW, setTokenToChangePW] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [dataOTP, setDataOTP] = useState({});

  const onActiveMessPhonePw = (data) => {
    setDataOTP(data);
    setActiveForgotPw(false);
    setActiveMessPhonePw(true);
  };
  const onActiveRisetPw = (data) => {
    setActiveMessPhonePw(false);
    setActiveResetPw(true);
  };
  const onActiveSuccessPw = (data) => {
    setActiveResetPw(false);
    setActiveSuccessPw(true);
  };
  const onActiveLogin = () => {
    setActiveSuccessPw(false);
    setLoginModalShow(true);
  };

  return (
    <>
      <ForgotPassword
        show={isActiveForgotPw}
        onHide={() => setActiveForgotPw(false)}
        setCurrentInput={setCurrentInput}
        onActiveMessPhonePw={onActiveMessPhonePw}
      />
      <MessPhonePw
        show={isActiveMessPhonePw}
        currentInput={currentInput}
        onHide={() => setActiveMessPhonePw(false)}
        data={dataOTP}
        onActiveRisetPw={onActiveRisetPw}
        setToken={(token) => setTokenToChangePW(token)}
      />
      <ResetPw
        data={dataOTP}
        show={isActiveResetPw}
        onHide={() => setActiveResetPw(false)}
        onActiveSuccessPw={onActiveSuccessPw}
        token={tokenToChangePW}
      />
      <SuccessPw
        show={isActiveSuccessPw}
        onHide={() => setActiveSuccessPw(false)}
        onActiveLogin={onActiveLogin}
      />
      <header className="sh">
        <nav className="navbar navbar-expand-lg">
          <div className="container pr">
            <Link to={PATH.ROUTE_PATH_V2_HOME} className="logo-pc">
              <Logo src="/assets/img/updated_logo.png" alt="Logo" />
            </Link>
            <Link to={PATH.ROUTE_PATH_V2_HOME} title="" className="logo-m">
              <img src="/assets/img/logo-m.svg" alt="Logo" />
            </Link>
            <div className="progress-register flex">
              {_.range(1, totalStep + 1).map((step) => (
                <div
                  key={step}
                  className={classNames("step", {
                    active: step === currentStep,
                    finish: currentStep > step,
                  })}
                >
                  <span>{step}</span>
                </div>
              ))}
            </div>
            <div className="login">
              <div className="text">
                <span>Bạn đã có tài khoản?</span>{" "}
                <a
                  href="javascript:;"
                  title=""
                  onClick={() => setLoginModalShow(true)}
                >
                  Đăng nhập
                </a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <LoginWrapper
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onLoginSuccess={onLoginSuccess}
        onActiveForgotPw={onActiveForgotPw}
      />
      <LoginSuccessModal
        show={loginSuccessModalShow}
        onHide={() => {
          setLoginSuccessModalShow(false);
        }}
      />
    </>
  );
}
