import React, { useState, Fragment } from "react";
import Select from "react-select";

const ListActiveBook = ({ dataBookList, messErrorListBook, onActiveBook }) => {
  const [valueSelect, setStateValueSelect] = useState("");

  const onChangeSelectBook = (data) => {
    setStateValueSelect(data);
  };

  return (
    <Fragment>
      <form className="text-center mt-3 mb-3 p-3">
        <div className="form-group mb-3 w-75 mx-auto">
          <Select
            value={valueSelect}
            onChange={onChangeSelectBook}
            options={dataBookList}
            isSearchable={false}
            placeholder="Chọn sách"
          />
        </div>
        {messErrorListBook && (
          <div className="text-danger mb-3">
            {messErrorListBook}
          </div>
        )}
        <button
          className="btn-pr flex-center btn-access w-25 rounded"
          type="button"
          onClick={() => {
            onActiveBook(valueSelect);
          }}
        >
          Kích hoạt
        </button>
      </form>
    </Fragment>
  );
};

export default ListActiveBook;
