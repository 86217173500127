import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { CLICK_RECENT_BOOK } from "edu_lms/constants/eventNameGTM";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { ROUTE_PATH_READING_BOOKS } from "consts/routePaths";
import { onDispatchBookInfo } from "edu_lms/modules/General/actions";
import { bookTypes, grades, listSubject } from "edu_lms/constants/type";
import { removeAccents } from "edu_lms/components/selection";
import { READING_BOOK_ENTRY_SOURCE } from "edu_lms/constants/book";
import { onDispatchUpdateReadingBookData } from "edu_lms/modules/ReadingBooks/actions";

export default function RecentBook() {
  const [recentBooks, setRecentBooks] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const getRecentBooks = JSON.parse(localStorage.dataRecentBook || "[]");
    setRecentBooks(getRecentBooks);
  }, []);

  const redirectRecentBooks = (data) => {
    const bookType = bookTypes.find(
      (itemBookType) => itemBookType.id === data.typeBook
    );
    const bookGrade = grades.find(
      (itemGrade) => itemGrade.value === data.gradeId
    );
    const bookSubject = listSubject.find(
      (itemSubject) => itemSubject.id === data.subjectId
    );
    setEventGTM({ event: CLICK_RECENT_BOOK });
    dispatch(
      onDispatchBookInfo({
        bookName: data.title,
        bookType: bookType?.title,
        bookGrade: bookGrade?.label,
        bookSubject: bookSubject?.title,
        count_click_cursor: 0,
        count_click_pencil: 0,
        count_click_brush: 0,
        count_add_text: 0,
        count_add_hyperlink: 0,
        count_add_line: 0,
        count_add_rectangle: 0,
        count_add_circle: 0,
        count_click_delete: 0,
        count_click_delete_all: 0,
        count_select_color: 0,
        count_zoom_in: 0,
        count_zoom_out: 0,
        count_zoom_back_100: 0,
        count_change_page: 0,
        count_show_tools: 0,
        count_open_book_instructions: 0,
        count_open_table_of_contents: 0,
      })
    );
    const titleBook = removeAccents(data.name);
    dispatch(
      onDispatchUpdateReadingBookData({
        entrySource: READING_BOOK_ENTRY_SOURCE.RECENT_BOOK,
      })
    );
    history.push(
      `${ROUTE_PATH_READING_BOOKS}/${titleBook}/${data.typeBook}/${data.id}/${data.pageId}/`
    );
  };

  if (recentBooks.length <= 0) {
    return null;
  }

  return (
    <div className="container pt-2">
      <p className="font-weight-bold pl-2">Sách mới xem gần đây: </p>
      <Wrapper>
        <div className="wrapper">
          {recentBooks.map((item, index) => (
            <Item
              key={index}
              className="mt-2 mr-3 rounded cursor"
              imgUrl={encodeURI(item.image)}
              onClick={() => redirectRecentBooks(item)}
            />
          ))}
        </div>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  padding-left: 10px;
  border-radius: 5px;
  overflow-x: auto;
  .wrapper {
    width: max-content;
    padding: 5px 0;
  }
`;

const Item = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  height: 110px;
  background-size: cover;
  display: inline-block;
  box-shadow: 2px 2px 10px 1px #ccc;
  transition: all 0.2s linear;
  background-image: url(${(props) => props.imgUrl});
  :hover {
    transform: scale(1.2);
  }
`;
