import {
  getIconsListByLanguage,
  getContentAudioVideoImageTextFromIconActData,
} from ".";
import { TYPE_DATA } from "../constants";
import { BORDER_COLOR } from "../constants/MathInput";

const formatActivityDataMathInput = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    font_size_question: fontSizeQuestion,
    font_size_answer: fontSizeAnswer,
  } = gameConfig;

  const dataGameSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.question;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );

    const question = {
      questionId,
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        text: {
          fontSize: fontSizeQuestion,
          contentText: questionContentIcon.contentText,
        },
      },
    };

    return {
      question,
      answer: {
        answers: dataGameConfigItem.answers.map(answerLine => {
          return answerLine.map(answerItem => ({
            index: answerItem.index,
            type: answerItem.type,
            answerText: String(answerItem.answer_text),
            answerIndex: answerItem.answer_index,
            questionId,
          }))
        }),
        fontSizeAnswer,
        numberInARow: Number(dataGameConfigItem.number_in_a_row),
      },
    };
  });

  return dataGameSetList;
};

const getDataInputAnswers = (answers) => {
  const inputAnswers = [];
  answers.forEach((answerLine) => {
    answerLine.forEach((answerItem) => {
      if (answerItem.type === TYPE_DATA.INPUT) {
        inputAnswers.push(answerItem);
      }
    });
  });
  return inputAnswers;
};

const getStatusInputArea = (inputArea, inputAreasValue, correctInputs) => {
  if (!inputArea.value) return null;

  const inputAreaValue = inputAreasValue.find(area => area.index === inputArea.index);
  if (!inputAreaValue.answerIndex) {
    const hasCorrectAnswer = correctInputs.noSwap.some(
      (correctInput) => correctInput.index === inputArea.index
    );
    return hasCorrectAnswer;
  }

  const indexCorrectInputSwap = correctInputs.hasSwap.findIndex(
    (correctInput) => {
      return correctInput.value === inputArea.value;
    }
  );
  if (indexCorrectInputSwap > -1) {
    correctInputs.hasSwap.splice(indexCorrectInputSwap, 1);
    return true;
  }
  return false;
}

const getBorderColor = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return BORDER_COLOR.Correct;
    case false:
      return BORDER_COLOR.InCorrect;
    default:
      return BORDER_COLOR.Default;
  }
};

export {
  formatActivityDataMathInput,
  getDataInputAnswers,
  getStatusInputArea,
  getBorderColor,
};
