import Modal from "react-bootstrap/Modal";
import { NavLink, matchPath, useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import * as PATH from "consts/routePaths";
import { JOB_STUDENT } from "edu_lms/constants/type";
import { onResultUserInfo } from "edu_lms/modules/selection";
import { useAuthStore } from "stores/authStore";
import {ROUTE_PATH_TU_SACH} from "consts/routePaths";
import { useBookStore } from "stores/bookStore";

const blacklistRoutes = [
  // doc sach
  `${PATH.ROUTE_PATH_READING_BOOKS}/:name/:bookType/:id/:pageId`,
  `${PATH.ROUTE_PATH_READING_BOOKS}/:name/:bookType/:id`,
    // tu sach
  `${PATH.ROUTE_PATH_TU_SACH}:subjectSlug/:gradeSlug`,
  // giao an
  `${PATH.ROUTE_PATH_V3_LIST_SLIDE}`,
  `${PATH.ROUTE_PATH_V3_LIST_SLIDE}:subjectSlug/:gradeSlug`,
  `${PATH.ROUTE_PATH_V3_LIST_SLIDE}:subject/:grade/:title/:id`,
];

const shouldBeAutoRedirected = (currentPath) =>
  _.isEmpty(
    blacklistRoutes.filter((path) =>
      matchPath(currentPath, {
        path,
        exact: true,
        strict: false,
      })
    )
  );

export default function LoginSuccessModal(props) {
  const history = useHistory();
  const location = useLocation();
  const targetRoute = useAuthStore((state) => state.targetRoute);
  const setTargetRoute = useAuthStore((state) => state.setTargetRoute);
  const fetchUpdateDocuments = useBookStore((state) => state.fetchUpdateDocuments);

  const userInfo = onResultUserInfo();

  const currentPath = location.pathname;

  const trainingConfig = localStorage.getItem("trainingConfig") || "{}";
  const { isOpenTraining } = JSON.parse(trainingConfig);

  const handleContinute = () => {
    fetchUpdateDocuments();
    if (shouldBeAutoRedirected(currentPath)) {
      history.push(`${PATH.ROUTE_PATH_TU_SACH}?${props?.params ?? ""}`);
    } else {
      if (targetRoute) {
        setTargetRoute("");
        history.push(targetRoute);
      }
    }
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="modal-success"
      className="modal fade modal-success modal-login-success "
      backdrop="static"
      animation="true"
    >
      <div className="box-success p-0">
        <div className="img-wrapper">
          <img src="/assets/img/img-success.svg" alt="Đăng nhập thành công" />
        </div>
        <div className="text">Đăng nhập thành công</div>
        {(isOpenTraining && Number(userInfo?.job_id !== JOB_STUDENT)) ||
        (isOpenTraining && Number(props.checkJob?.job_id) !== JOB_STUDENT) ? (
          <NavLink to={PATH.ROUTE_PATH_V3_TRAINING}>
            <div
              title="Tiếp tục"
              className="btn-pr flex-center btn-access"
              onClick={props.onHide}
            >
              Tiếp tục
            </div>
          </NavLink>
        ) : (
          <div
            title="Tiếp tục"
            className="btn-pr flex-center btn-access cursor"
            onClick={handleContinute}
          >
            Tiếp tục
          </div>
        )}
      </div>
    </Modal>
  );
}
