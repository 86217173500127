export const STATUS = {
  START: "",
  LOADING: "loading",
  SUCCESS: "success",
};

export const ROLE = {
  ADD: "addInfo",
  UPDATE: "updateInfo",
};

export const SELECTED_DOING_EX = 1;
