import { useState, useEffect, useRef } from "react";
import { getNewPostBlog, getCateByParent } from "edu_lms/services/blog";
import SEO from "edu_lms/components/Seo";
import { ULR_IMAGE_LOGO_HOC10 } from "edu_lms/constants/type";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";
import {
  OPTION_SUBJECT,
  DEFAULT_TOTAL_PAGE,
  CONTENT_TITLE_KNOWLEDGE,
  SLUG_KNOWLEDGE,
} from "../constant";
import Header from "./Header";
import styles from "../BlogStyle.module.scss";
import { getTotalPage } from "../selection";
import PostDetailOther from "./PostDetailOther";

export default function OtherKnowledge() {
  useScrollToTop();
  const refScreen = useRef(null);
  const [listOtherKnowledges, setListOtherKnowledges] = useState([]);
  const [dataCateByParent, setDataCateByParent] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionOther, setOptionOther] = useState(OPTION_SUBJECT[0]);
  const [totalPage, setTotalPage] = useState(DEFAULT_TOTAL_PAGE);

  const PARAM_GET_DATA = SLUG_KNOWLEDGE.other;
  const getDataNewPost = () => {
    const data = {
      limit: totalPage,
      page: currentPage,
      slug: PARAM_GET_DATA,
      view_count: optionOther?.value,
    };
    getNewPostBlog(data).then((res) => {
      if (res.data.status === "success") {
        setListOtherKnowledges(res.data.data);
      }
    });
  };

  const getCates = () => {
    const data = {
      slug: PARAM_GET_DATA,
    };
    getCateByParent(data).then((res) => {
      if (res.data.status === "success") {
        setDataCateByParent(res.data.data);
      }
    });
  };

  useEffect(() => {
    getCates();
    const totalPerPage = getTotalPage(refScreen.current.offsetWidth);
    setTotalPage(totalPerPage);
  }, []);

  useEffect(() => {
    getDataNewPost();
  }, [currentPage, optionOther, totalPage]);

  const dataSEO = {
    title: dataCateByParent?.all_cate[PARAM_GET_DATA]?.meta_title,
    description: dataCateByParent?.all_cate[PARAM_GET_DATA]?.meta_description,
    url: `${process.env.REACT_APP_URL}${SLUG_KNOWLEDGE.knowledge}/${PARAM_GET_DATA}/`,
    img: ULR_IMAGE_LOGO_HOC10,
    keywords: dataCateByParent?.all_cate[PARAM_GET_DATA]?.meta_keyword,
  };

  return (
    <main ref={refScreen} className={styles.otherKnowContainer}>
      <SEO data={dataSEO} />
      <ScrollAndFrequentQuestion refScreen={refScreen?.current?.offsetHeight} />
      <Header contentHeader={CONTENT_TITLE_KNOWLEDGE[4].content} />
      <div className={styles.containerPostOther}>
        <PostDetailOther
          listOtherKnowledges={listOtherKnowledges}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setOptionOther={setOptionOther}
          optionOther={optionOther}
          totalPage={totalPage}
        />
      </div>
    </main>
  );
}
