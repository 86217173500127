import { Helmet, HelmetProvider } from "react-helmet-async";
import renderHTML from "react-render-html";
import { ACTIVE_META_ROBOTS, pages } from "edu_lms/components/Seo/meta";

export default function SEO ({data, isHome, isBreadcumb, totalPage=0, noIndex = false}) {
	var fullUrl   = window.location.href;
	var url       = new URL (fullUrl)
	var pathname  = url.pathname;
	const dataURL = pathname.split ("/");
	var pageId = 0;
	if (dataURL && dataURL[1] === 'doc-sach') {
		pathname = '/' + dataURL[1] + '/' + dataURL[2] + '/' + dataURL[3] + '/' + dataURL[4] + '/';
		pageId = dataURL[5];
	}
	if (pathname && pages[pathname]) {
		data        = JSON.parse(JSON.stringify(pages[pathname]));
		data['url'] = fullUrl;
		if (dataURL && dataURL[1] === 'doc-sach') {
			data['title'] = data['title'] + ' | Trang ' + pageId + '/' + (totalPage - 1);
		}
	}

  const isMainDomain = url.origin == 'https://www.hoc10.vn';
  const activeMetaRobot = data?.robots === ACTIVE_META_ROBOTS;

	return (
    <HelmetProvider>
      {data && (
        <Helmet prioritizeSeoTags>
          <title>{data.title}</title>
          <link rel="canonical" href={data.url} />

          <meta
            name="robots"
            content={
              isMainDomain && activeMetaRobot
                ? "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                : "noindex"
            }
          />

          <meta name="keywords" content={data.keywords} />
          <meta
            name="description"
            content={renderHTML(data.description || "")}
          />
          <meta name="image" content={data.img} />

          <meta property="og:locale" content="vi-VN" />
          <meta property="og:type" content={isHome ? "website" : "article"} />
          <meta property="og:title" content={data.title} />
          <meta
            property="og:description"
            content={renderHTML(data.description || "")}
          />
          <meta property="og:url" content={data.url} />
          <meta property="og:site_name" content="Hoc10" />
          <meta property="og:image" content={data.img || "https://hoc10.monkeyuni.net/E_Learning/thumb/thumb_hoc10_2022.png"} />
          <meta property="og:image:secure_url" content={data.img} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta name="twitter:site" content={data.url} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={data.title} />
          <meta
            name="twitter:description"
            content={renderHTML(data.description || "")}
          />
          <meta name="twitter:image" content={data.img} />

          <meta
            property="fb:app_id"
            content={process.env.REACT_APP_API_FB_APP_ID}
          />

          {isHome ? (
            <script type="application/ld+json">
              {`{
          "@context": "https://schema.org",
          "@graph": [
              {
                "@type": "Organization",
                        "@id": "https://hoc10.vn/#organization",
                        "url": "https://hoc10.vn/",
                        "sameAs": [
                          "https://www.facebook.com/Hoc10-H%E1%BB%8Dc-1-bi%E1%BA%BFt-10-106859728447188/",
                          "https://www.youtube.com/channel/UCtmc2xq9J4n2_kuOAue7dCQ"
                        ],
                        "name": "Hoc10",
                        "logo": [
                                  {
                                    "@type": "ImageObject",
                                    "@id": "https://hoc10.vn/#logo",
                                    "inLanguage": "vi-VN",
                                    "url": "https://hoc10.vn/assets/img/updated_logo.png",
                                    "width": 610,
                                    "height": 134,
                                    "caption": "Hoc10"
                                  }
                                ],
                        "foundingDate": "2003",
                        "slogan": "Hoc10 - Bám sát chương trình GDPT mới 2018",
                        "legalName": "Hoc10",
                        "founder": {
                                    "@type": "Person",
                                    "name": "Hoc10",
                                    "url": "https://hoc10.vn/ve-chung-toi/",
                                    "sameAs": "https://hoc10.vn/ve-chung-toi/"
                                    },
                        "numberOfEmployees": {
                                    "@type": "QuantitativeValue",
                                    "value": 30
                                    }
                },
              {
                "@type": "WebSite",
                        "@id": "https://hoc10.vn/#website",
                        "url": "https://hoc10.vn/",
                        "name": "Hoc10 - Bám sát chương trình GDPT mới 2018",
                        "description": "Hoc10 mang sứ mệnh xây dựng hệ sinh thái giáo dục, kết nối nhà trường, học sinh và phụ huynh, tạo môi trường dạy và học hiệu quả",
                        "publisher": {
                                    "@id": "https://hoc10.vn/#organization"
                                    },
                        "copyrightHolder": {
                                    "@id": "https://hoc10.vn/#organization"
                                    }
              }
                    ]
            }`}
            </script>
          ) : (
            <>
              <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@graph": [
                      {
                "@type": "Organization",
                        "@id": "https://hoc10.vn/#organization",
                        "url": "${data.url}",
                        "sameAs": [
                          "https://www.facebook.com/Hoc10-H%E1%BB%8Dc-1-bi%E1%BA%BFt-10-106859728447188/",
                          "https://www.youtube.com/channel/UCtmc2xq9J4n2_kuOAue7dCQ"
                        ],
                        "name": "Hoc10",
                        "logo": [
                                  {
                                    "@type": "ImageObject",
                                    "@id": "https://hoc10.vn/#logo",
                                    "inLanguage": "vi-VN",
                                    "url": "https://hoc10.vn/assets/img/updated_logo.png",
                                    "width": 610,
                                    "height": 134,
                                    "caption": "Hoc10"
                                  }
                                ],
                        "foundingDate": "2003",
                        "slogan": "Hoc10 - Bám sát chương trình GDPT mới 2018",
                        "legalName": "Hoc10",
                        "founder": {
                                    "@type": "Person",
                                    "name": "Hoc10",
                                    "url": "${data.url}",
                                    "sameAs": "${data.url}"
                                    },
                        "numberOfEmployees": {
                                    "@type": "QuantitativeValue",
                                    "value": 30
                                    }
                      },
              {
                "@type": "WebPage",
                        "@id": "${data.url}#webpage",
                        "url": "${data.url}",
                        "name": "Hoc10 - Bám sát chương trình GDPT mới 2018",
                        "description": "Hoc10 mang sứ mệnh xây dựng hệ sinh thái giáo dục, kết nối nhà trường, học sinh và phụ huynh, tạo môi trường dạy và học hiệu quả",
                        "publisher": {
                                    "@id": "https://hoc10.vn/#organization"
                                    },
                        "copyrightHolder": {
                                    "@id": "https://hoc10.vn/#organization"
                                    }
              }
                    ]
            }
            `}
              </script>

              {isBreadcumb ? (
                <script type="application/ld+json">
                  {`
                  {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement":
                      [
                        {
                          "@type": "ListItem",
                          "position": 1,
                          "name": "Trang chủ",
                          "item": "https://hoc10.vn/"
                        },
                        {
                          "@type": "ListItem",
                          "position": 2,
                          "name": "Giới thiệu",
                          "item": "https://hoc10.vn/gioi-thieu/"
                        },
                        {
                          "@type": "ListItem",
                          "position": 3,
                          "name": "${data.titleBreadcumb}",
                          "item": "${data.url}"
                        }
                      ]
                  }
                `}
                </script>
              ) : (
                <script type="application/ld+json">
                  {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "BreadcrumbList",
                    "itemListElement":
                      [
                        {
                          "@type": "ListItem",
                          "position": 1,
                          "name": "Trang chủ",
                          "item": "https://hoc10.vn/"
                        },
                        {
                          "@type": "ListItem",
                          "position": 2,
                          "name": "${data.titleBreadcumb}",
                          "item": "${data.url}"
                        }
                      ]
                    }
                `}
                </script>
              )}
            </>
          )}
        </Helmet>
      )}
	    {!data && noIndex && (
		    <Helmet prioritizeSeoTags>
			    <meta
				    name="robots"
				    content="noindex, nofollow"
			    />
		    </Helmet>
	    )}
    </HelmetProvider>
  );
}
