import React from "react";
import _ from "lodash";
import { TYPE_DATA } from "../../constants";
import { TYPE_DISPLAY } from "../../constants/SQC_001";
import { Image, Typography } from "../../components";
import { getBorderColor } from "../../helpers/SQC_001";
import { COLOR } from "../../constants/styles";

const BoxDraggable = ({
  answerItem,
  typeAnswer,
  typeDisplay,
  hideResultAnswer = false,
  onClickAudio = () => {},
}) => {
  return (
    <div
      className="p-1 ml-3 mr-3 mb-3 position-relative d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: COLOR.Orange,
        borderRadius: "10px",
        boxShadow: "2px 4px 10px 4px #d7d7d7",
        border: `3px solid ${getBorderColor(hideResultAnswer ? null : answerItem.isCorrect)}`,
        width: `${typeDisplay === TYPE_DISPLAY.Horizontal ? "auto" : "200px"}`,
        height: `${typeDisplay === TYPE_DISPLAY.Horizontal ? "auto" : "200px"}`,
      }}
    >
      {_.includes(typeAnswer, TYPE_DATA.AUDIO) && (
        <i
          onClick={() => onClickAudio(answerItem.contentAnswer.srcAudio)}
          className=" monkey-fz-16 fa fa-volume-up cursor hvr-registration"
          aria-hidden="true"
        />
      )}
      {_.includes(typeAnswer, TYPE_DATA.IMAGE) && (
        <Image
          src={answerItem.contentAnswer.srcImage}
          className="w-100"
          alt="box-draggable-image"
        />
      )}
      {_.includes(typeAnswer, TYPE_DATA.TEXT) && (
        <Typography
          fontSize={answerItem.contentAnswer.text.fontSize}
          typeText={answerItem.contentAnswer.text.typeText}
          text={answerItem.contentAnswer.text.contentText}
        />
      )}
    </div>
  );
};

export default BoxDraggable;
