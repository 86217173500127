import { Link, useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Slider from "edu_lms_v2/components/Slider";
import * as PATH from "consts/routePaths";
import banner from "edu_lms_v2/assets/img/banner_sgk_canh_dieu.png";
import { useAppStore } from "../../../stores/appStore";
import { isMobile } from "react-device-detect";

const linkListBook_4_8_11 = [
  { url: "?block=1&grade=7&subject=32", title: "Lớp 4" },
  { url: "?block=2&grade=11&subject=42", title: "Lớp 8" },
  { url: "?block=3&grade=14&subject=54", title: "Lớp 11" },
  { url: "?block=3&grade=13&subject=72", title: "Lịch sử 10" },
];

const linkListBook_5_9_12 = [
	{ url: "gioi-thieu-chung/lop-5/", title: "Lớp 5" , grade_id: 8},
	{ url: "gioi-thieu-chung/lop-9/", title: "Lớp 9", grade_id: 12 },
	{ url: "gioi-thieu-chung/lop-12/", title: "Lớp 12", grade_id: 15 },
];

export default function BannerSlider() {
	let imgSlider = [
		// {
		// 	src               : "https://vnmedia2.monkeyuni.net/upload/hoc10/thumb/Chúc_mừng_năm_học_mới_2023-2024.png",
		// 	alt               : "nam-hoc-moi-2023-2024",
		// 	btnRouterListBook : [],
		// 	thpt : false,
		// 	url : '',
		// },
		{
			src               : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Artboard4.png",
			alt               : "banner-canh-dieu",
			btnRouterListBook : linkListBook_5_9_12,
			thpt : true,
			url : '',
		},
	];
	if(isMobile) {
		imgSlider = [
			// {
			// 	src               : "https://vnmedia2.monkeyuni.net/upload/hoc10/thumb/290724_(1).png",
			// 	alt               : "banner-taphuan-2024",
			// 	btnRouterListBook : [],
			// 	thpt : false,
			// 	url : '/taphuan',
			// },
			{
				src               : "https://hoc10.monkeyuni.net/upload/hoc10/thumb/Hoc10-banner_dt.png",
				alt               : "banner-canh-dieu",
				btnRouterListBook : linkListBook_5_9_12,
				thpt : true,
				url : '',
			},
		];
	}
	
	const setGradeId = useAppStore((state) => state.setGradeId);
	
	const history = useHistory();
	const handleClick = (url, gradeId) => {
		setGradeId(gradeId);
		history.push(url);
	};
	
  return (
    <Slider
      className="slider-home pr"
      centerMode={false}
      slidesToShow={1}
      slidesToScroll={1}
      infinite={true}
      centerPadding={0}
      autoplay={true}
      dots={true}
      arrows={true}
      autoplaySpeed={5000}
    >
      {/* <Banner className="item flex">
        <img
          className="fit"
          src="https://hoc10.monkeyuni.net/upload/hoc10/thumb/2.png"
          alt="banner-canh-dieu"
        />
        <ActionSection>
          <a
            className="btn-pr flex-center monkey-fz-20 btn-router-book"
            target="_blank"
            href="https://www.facebook.com/groups/hoc10donghanhcunggiaovientieuhoc/permalink/635784162096615/?mibextid=c7yyfP"
          >
            Tham gia ngay
          </a>
        </ActionSection>
      </Banner> */}

      {/* <div className="item flex position-relative">
        <img
          className="fit"
          src="https://hoc10.monkeyuni.net/upload/hoc10/thumb/banner-chaomung-namhoc-moi.jpg"
          alt="banner-chaomung-namhoc-moi"
        />
      </div> */}

      {imgSlider.map((item, index) => (
        <Banner stylebtn={item.stylesBtn} key={index} className="item flex">
	        {item.url ? <img className="fit" src={item.src} alt={item.alt}  onClick={() => {
		        handleClick(
			        item.url
		        );
	        }} /> : <img className="fit" src={item.src} alt={item.alt} /> }
          
          <div className="container-router-book d-flex position-absolute">
            {item.btnRouterListBook.map((itemListBooks) => (
              <div
                key={index}
                onClick={() => {
                  handleClick(
                    `${PATH.ROUTE_PATH_TU_SACH}${itemListBooks.url}`,
                    itemListBooks.grade_id
                  );
                }}
                // to={`${PATH.ROUTE_PATH_TU_SACH}${itemListBooks.url}`}
                title="Khám phá ngay"
                className="btn-pr flex-center monkey-fz-20 btn-router-book cursor"
              >
                {itemListBooks.title}
              </div>
            ))}
	          {item.thpt &&
		          <div
			          title="Khám phá ngay"
			          onClick={ () => {
				          handleClick (`${ PATH.ROUTE_PATH_TU_SACH }gioi-thieu-chung/on-thi-thpt/`, 26);
			          } }
			          className="btn-pr flex-center btn-onthi-thpt cursor"
		          >
			          Ôn thi tốt nghiệp THPT
		          </div>
			  }
			  {item.thpt &&
				  <a
					  href="http://sachcanhdieu.vn/"
					  title="Mua sách Cánh Diều tại đây"
					  target="_blank"
					  className="btn-pr flex-center monkey-fz-20 btn-router-book link-buy-book"
				  >
					  Mua sách tại đây
				  </a>
			  }
            
            {/*{*/}
            {/*    item.alt === "banner-canh-dieu" ? ( <Link*/}
            {/*        to={PATH.ROUTE_PUBLISHER_LIST}*/}
            {/*        title="Mua SGK tại đây"*/}
            {/*        className="btn-pr flex-center monkey-fz-20 btn-router-book link-buy-book"*/}
            {/*    >*/}
            {/*        Mua SGK tại đây*/}
            {/*    </Link>) : ''*/}
            {/*}*/}
          </div>
        </Banner>
      ))}
    </Slider>
  );
}

const animationButtonBanner = keyframes`
  from {
    border: 2px solid white;
  }

  to {
    border: 2px solid #0077bb;
  }`;
  const animationButtonOnthiThpt = keyframes`
  from {
    border: 2px solid white;
  }

  to {
    border: 2px solid #d53b3b;
  }`;

const Banner = styled.div`
  display: block;
  position: relative;
  .container-router-book {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 520px;
    left: 4%;
    bottom: 40px;
    gap: 20px;
  }
  .btn-router-book {
    background-color: #0077bb;
    border-radius: 10px;
    animation: ${animationButtonBanner} 1s infinite;
    font-size: 16px;
    padding: 8px 16px;
  }
  .link-buy-book {
    background-color: #fff;
    color: #0077bb;
  }
  .btn-onthi-thpt {
    font-size: 16px;
    padding: 8px 16px;
    background-color: #d53b3b;
    animation: ${animationButtonOnthiThpt} 1s infinite;
  }
  @media (max-width: 1200px) {
    .container-router-book {
      max-width: 400px;
      // left: 10px;
      // bottom: 10px;
      // gap: 10px;
      left: 14%;
      bottom: 40px;
      gap: 20px;
    }
    .btn-router-book {
      font-size: 16px;
      padding: 5px 10px;
    }
  }
  @media (max-width: 600px) {
    .container-router-book {
      max-width: 200px;
      left: 3%;
      bottom: 12px;
      gap: 5px;
    }
    .btn-router-book {
      font-size: 17px;
      padding: 2px 5px;
      border-radius: 10px;
      text-align: center;
    }
    .btn-onthi-thpt {
      font-size: 17px;
      padding: 2px 5px;
      border-radius: 10px;
    }
  }
`;

const buttonAnimation = keyframes`
  from {
    border: 2px solid white;
  }

  to {
    border: 2px solid #DF482E;
  }`;

const ActionSection = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 10px;

  & .btn-router-book {
    background-color: #df482e;
    color: #ffffff;
    border-radius: 10px;
    animation: ${buttonAnimation} 1s infinite;
    font-size: 10px;
    padding: 2px 8px;

    @media screen and (min-width: 577px) and (max-width: 992px) {
      font-size: 16px;
      padding: 8px 16px;
    }
    @media screen and (min-width: 992px) {
      font-size: 24px;
      padding: 16px;
    }
  }
`;
