import { forwardRef, useState } from "react";
import styled from "styled-components";
import { Tooltip } from "reactstrap";

const FULL_SCREEN_CHANGE = "fullscreenchange";
const IconFullScreen = styled.i`
  bottom: 0;
  right: 0;
  &:hover {
    color: #fff;
    background-color: #ff7707;
  }
`;

function FullScreen({ isFullScreen, setIsFullScreen }, ref) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const openFullscreen = () => {
    if (ref.current.requestFullscreen) ref.current.requestFullscreen();
    if (ref.current.webkitRequestFullscreen)
      ref.current.webkitRequestFullscreen(); /* Safari */
    if (ref.current.msRequestFullscreen)
      ref.current.msRequestFullscreen(); /* IE11 */
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) document.exitFullscreen();
    if (document.webkitExitFullscreen)
      document.webkitExitFullscreen(); /* Safari */
    if (document.msExitFullscreen) document.msExitFullscreen(); /* IE11 */
  };

  document.addEventListener(FULL_SCREEN_CHANGE, () => {
    setIsFullScreen(document.fullscreenElement !== null);
  });

  return (
    <>
      <IconFullScreen
        id="question-zoom-in"
        className="position-absolute fa fa-arrows-alt p-2 cursor rounded"
        aria-hidden="true"
        onClick={() => (isFullScreen ? closeFullscreen() : openFullscreen())}
      />
      <Tooltip
        placement={`${isFullScreen ? "left" : "right"}`}
        isOpen={tooltipOpen}
        target={"question-zoom-in"}
        toggle={toggle}
      >
        {isFullScreen ? "Thu nhỏ" : "Phóng to"}
      </Tooltip>
    </>
  );
}

export default forwardRef(FullScreen);
