export const SLUG_KNOWLEDGE = {
  level1: "tieu-hoc",
  level2: "trung-hoc-co-so",
  level3: "trung-hoc-pho-thong",
  other: "kien-thuc-khac",
  knowledge: "kien-thuc",
};

export const PARENT_KNOWLEDGE = 303;

export const ICON_GRADE = {
  [SLUG_KNOWLEDGE.level1]: {
    srcImg: "/assets/img/icon-level1.svg",
    altImg: "icon-level-1",
  },
  [SLUG_KNOWLEDGE.level2]: {
    srcImg: "/assets/img/icon-level2.svg",
    altImg: "icon-level-2",
  },
  [SLUG_KNOWLEDGE.level3]: {
    srcImg: "/assets/img/icon-level3.svg",
    altImg: "icon-level-3",
  },
  [SLUG_KNOWLEDGE.other]: {
    srcImg: "/assets/img/icon-other.svg",
    altImg: "icon-other",
  },
};

export const TOTAL_SUBJECT_KNOWLEDGE = 6;

export const CURRENT_PAGE = 1;

const TYPES = { views: 1, news: 0 };

export const OPTION_SUBJECT = [
  { label: "Mới nhất", value: TYPES.news },
  { label: "Lượt xem", value: TYPES.views },
];

export const DEFAULT_TOTAL_PAGE = 9;

export const CONTENT_TITLE_KNOWLEDGE = [
  {
    slug: "kien-thuc",
    title: { titleName: "Kiến", titleStyles: "thức" },
    content:
      "Đây là góc chia sẻ kiến thức của tất cả môn học khác nhau từ cấp Tiểu học đến cấp THPT. Được biên soạn bởi đội ngũ biên tập viên có nhiều năm kinh nghiệm trong lĩnh vực giáo dục, và dựa trên các kiến thức chuẩn xác nhất được cung cấp bởi Hoc10.",
  },
  {
    slug: "tieu-hoc",
    title: { titleName: "Tiểu", titleStyles: "học" },
    content:
      "Tiểu học là một dấu mốc quan trọng đối với quá trình học tập của mỗi người. Vì thế nên, Hoc10 được sinh ra với xứ mạng duy nhất là mang đến cho người học những kiến thức chính xác và hữu ích nhất. ",
  },
  {
    slug: "trung-hoc-co-so",
    title: { titleName: "Trung học cơ", titleStyles: "sở" },
    content:
      "THCS là một dấu mốc quan trọng đối với quá trình học tập của mỗi người. Vì thế nên, Hoc10 được sinh ra với xứ mạng duy nhất là mang đến cho người học những kiến thức chính xác và hữu ích nhất. ",
  },
  {
    slug: "trung-hoc-pho-thong",
    title: { titleName: "Trung học phổ", titleStyles: "thông" },
    content:
      "THPT là một dấu mốc quan trọng đối với quá trình học tập của mỗi người. Vì thế nên, Hoc10 được sinh ra với xứ mạng duy nhất là mang đến cho người học những kiến thức chính xác và hữu ích nhất. ",
  },
  {
    slug: "kien-thuc-khac",
    title: { titleName: "Kiến thức", titleStyles: "khác" },
    content:
      "Đây là góc chia sẻ các kiến thức khác ngoài sách, được biên soạn bởi đội ngũ biên tập viên có nhiều năm kinh nghiệm trong lĩnh vực giáo dục. Từ đó góp phần giúp học sinh bổ sung thêm nhiều kỹ năng thực tiễn khác nhau trong cuộc sống.",
  },
];
