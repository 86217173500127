import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectBox = ({ item, nameControl, roleId, onChangeSelect }) => {
  return (
    <div className={item.data.className}>
      <div className="form-group">
        <label>
          {item.value} <span className="monkey-color-red">*</span>
        </label>
        {item.id !== "warId" && (
          <Controller
            name={item.data.name}
            control={nameControl}
            defaultValue={item.data.value}
            rules={{ required: item.data.required }}
            isDisabled={item.data.dislabel[roleId]}
            as={
              <Select
                options={item.data.options}
                menuPlacement={item.data.menuPlacement}
                maxMenuHeight={180}
                placeholder={item.data.placeholder}
              />
            }
          />
        )}
        {item.id === "warId" && (
          <Controller
            name={item.data.name}
            control={nameControl}
            defaultValue=""
            rules={{ required: item.data.required }}
            render={({ name, onChange }) => (
              <Select
                name={name}
                value={item.data.value}
                options={item.data.options}
                menuPlacement={item.data.menuPlacement}
                maxMenuHeight={180}
                placeholder={item.data.placeholder}
                onChange={(e) => {
                  onChange(e);
                  onChangeSelect(e);
                }}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default SelectBox;
