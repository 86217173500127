import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import calendarIcon from "edu_lms_v2/assets/img/calendar.svg";
import "./PopupAssignHomeWork.scss";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH_V3_TEACHERMANAGER } from "consts/routePaths";

export default function PopUpAssignHomeWork({
  showPopup,
  setShowPopup,
  listClassroom,
  onSubmit,
  checked,
  setChecked,
}) {
  const schema = yup
    .object()
    .shape({
      time_finish: yup.string().required("Vui lòng chọn ngày").nullable(),
      class_room_ids: yup
        .array()
        .required("Vui lòng chọn lớp ")
        .min(1, "Vui lòng chọn lớp "),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const history = useHistory();
  const [expanded, setExpanded] = useState([]);

  const handleClose = () => {
    setShowPopup(false);
    setChecked([]);
  };
  const submitPopup = (dataForm) => {
    onSubmit(dataForm, checked);
  };
  const handleCreateClass = () => {
    history.push(ROUTE_PATH_V3_TEACHERMANAGER);
  };
  return (
    <>
      <Modal
        className="ModalExcercise"
        show={showPopup}
        centered
        onHide={handleClose}
        animation={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">Giao bài tập</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(submitPopup)}>
          <Modal.Body>
            <div className="body-left">
              <div className="body-select">
                <span className="input-title">Chọn lớp học</span>
              </div>

              {listClassroom?.length > 0 ? (
                <div className="checkbox-tree">
                  <Controller
                    render={({ onChange, value }) => (
                      <CheckboxTree
                        nodes={listClassroom}
                        checked={checked}
                        expandOnClick={true}
                        expanded={expanded}
                        value={value}
                        onCheck={(checked) => {
                          setChecked(checked);
                          onChange(checked);
                        }}
                        onExpand={(expanded) => setExpanded(expanded)}
                      />
                    )}
                    name="class_room_ids"
                    control={control}
                  />
                </div>
              ) : (
                <span className="monkey-fz-18">Bạn chưa tạo lớp học nào!</span>
              )}
              {errors.class_room_ids && (
                <p className="text-danger ml-4">
                  {errors.class_room_ids.message}
                </p>
              )}
            </div>

            <div className="body-right">
              <div className="right-item">
                <span className="input-title">Hạn hoàn thành</span>

                <Controller
                  control={control}
                  name="time_finish"
                  render={({ onChange, value, onBlur }) => (
                    <DatePicker
                      className="d-flex"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      showTimeSelect
                      dateFormat="dd/MM/yyyy, h:mm aa"
                      minDate={new Date()}
                      placeholderText="Chọn ngày"
                    />
                  )}
                />
                <img src={calendarIcon} className="calender-icon" alt="" />
              </div>
              {errors.time_finish && (
                <p className="text-danger mt-2">{errors.time_finish.message}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {listClassroom?.length > 0 ? (
              <Button type="submit" variant="primary" className="submit-btn">
                Giao bài
              </Button>
            ) : (
              <Button
                variant="primary"
                className="submit-btn"
                onClick={() => handleCreateClass()}
              >
                Tạo lớp
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
