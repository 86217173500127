import { useState, useEffect, useRef } from "react";
import { getNewPostBlog, getCateByParent } from "edu_lms/services/blog";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Loading from "edu_lms_v2/components/Loading";
import SEO from "edu_lms/components/Seo";
import { ULR_IMAGE_LOGO_HOC10 } from "edu_lms/constants/type";
import Header from "./Header";
import LatestNews from "./LatestNews";
import ListDataSubject from "./ListDataSubject";
import { TOTAL_SUBJECT_KNOWLEDGE, SLUG_KNOWLEDGE } from "../constant";
import { OPTION_SUBJECT } from "../constant";
import styles from "../BlogStyle.module.scss";
import ScrollAndFrequentQuestion from "../components/ScrollAndFrequentQuestion";

export default function KnowledgeSubject(props) {
  useScrollToTop();
  const refScreen = useRef(null);
  const [dataSubjects, setDataSubjects] = useState({});
  const [listAllSubjects, setListAllSubject] = useState({});
  const [subjectsName, setSubjectsName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionSubjects, setOptionSubjects] = useState(OPTION_SUBJECT[0]);
  const [isLoading, setIsLoading] = useState(true);
  const subjectType = props.match.params.subjectType;

  const getDataSubject = () => {
    const subject = subjectsName.map((item) => item.slug);
    const data = {
      limit: TOTAL_SUBJECT_KNOWLEDGE,
      page: currentPage,
      slug: subject.toString() || subjectType,
      view_count: optionSubjects?.value,
    };

    getNewPostBlog(data).then((res) => {
      if (res.data.status === "success") {
        setDataSubjects(res.data.data);
      }
    });
  };

  const getAllDataSubject = () => {
    const data = { slug: subjectType };
    getCateByParent(data).then((res) => {
      setIsLoading(false);
      if (res.data.status === "success") {
        setListAllSubject(res.data.data);
      } else {
        console.log(res.data.message);
      }
    });
  };

  useEffect(() => {
    getDataSubject();
  }, [subjectsName, currentPage, optionSubjects]);

  useEffect(() => {
    getAllDataSubject();
  }, []);

  let dataSEO = {};
  if (listAllSubjects?.all_cate) {
    dataSEO = {
      title: listAllSubjects?.all_cate[subjectType]?.meta_title,
      description: listAllSubjects?.all_cate[subjectType]?.meta_description,
      url: `${process.env.REACT_APP_URL}${SLUG_KNOWLEDGE.knowledge}/${props.match.params.levelType}/${props.match.params.subjectType}/`,
      img: ULR_IMAGE_LOGO_HOC10,
      keywords: listAllSubjects?.all_cate[subjectType]?.meta_keyword,
    };
  }

  if (isLoading) {
    return (
      <main className={styles.height100}>
        <Loading />
      </main>
    );
  }

  return (
    <main ref={refScreen}>
      <SEO data={dataSEO} />
      {listAllSubjects.cate_by_slug && dataSubjects.news_posts && (
        <>
          <ScrollAndFrequentQuestion
            refScreen={refScreen?.current?.offsetHeight}
          />
          <Header
            params={props.match.params}
            listAllSubjects={listAllSubjects.all_cate}
          />
          <div className={styles.container}>
            <LatestNews data={dataSubjects.news_posts[0]} />
            <ListDataSubject
              listAllSubjects={listAllSubjects}
              setSubjectsName={setSubjectsName}
              subjectsName={subjectsName}
              dataSubjects={dataSubjects}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              optionSubjects={optionSubjects}
              setOptionSubjects={setOptionSubjects}
            />
          </div>
        </>
      )}
    </main>
  );
}
