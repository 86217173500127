import React from "react";

const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;

export default function ActiveBookComponent() {
  return (
    <>
      <h4>Hướng dẫn kích hoạt sách</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          <p>
            Kính chào quý thầy cô, hiện nay các sách điện tử trên website
            https://www.hoc10.vn/ bao gồm sách không cần kích hoạt và sách cần
            kích hoạt để xem toàn bộ nội dung. Với các đầu sách yêu cầu kích
            hoạt, bằng cách cào nhẹ lớp phủ bạc bên dưới tem chống giả, thầy cô
            có thể tìm thấy mã kích hoạt ở bìa sau của sách bản in.
          </p>
          <div className="mt-3 mb-3">
            <img
                className="w-100"
                src={`${urlImg}/Hinh_anh_tem_chong_gia.png`}
                alt="Hình ảnh tem chống giả"
              />
              <img
                className="w-100 mt-2"
                src={`${urlImg}/Hinh_anh_tem_chong_gia_2.jpg`}
                alt="Hình ảnh tem chống giả 2"
              />
          </div>
          <p>
            Sau khi có mã kích hoạt thầy cô có thể kích hoạt sách bằng 1 trong 2
            cách sau:
          </p>
        </li>
        <li class="flex-lc">
          <b>Cách 1:</b>&nbsp; Kích hoạt từ trang chủ
          <p>
            Thầy cô đăng nhập tài khoản Hoc10 bằng số điện thoại/email đã đăng
            ký sau đó gaio diện trang website sẽ chuyển về trang "Tủ sách". Tại
            đây, bấm vào nút "Kích hoạt sách" trên thanh công cụ ở đầu trang.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/Cach_1__Bam_kich_hoat_tu_thanh_cong_cu.png`}
              alt="Cách 1: Kích hoạt từ trang chủ"
            />
          </div>
          <p className="mt-3">
            Popup nhập mã kích hoạt hiện lên, nhập mã kích hoạt sách tại ô kích
            hoạt và bấm “Xác nhận” để hoàn tất.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/Cach_1__hien_thi_popup_nhap_ma_kich_hoat.png`}
              alt="Nhập mã kích hoạt sách tại ô kích hoạt và bấm “Xác nhận” để hoàn tất."
            />
          </div>
        </li>
        <li class="flex-lc">
          <b>Cách 2:</b>&nbsp; Kích hoạt trên sách (Đối với các sách chưa mở
          toàn bộ)
          <p>
            Với sách cần kích hoạt, trên bìa sách sẽ có nút “Đọc thử”, với các
            sách này thầy cô có thể xem được một phần quyển sách.
          </p>
          <p>
            Khi xem tới trang cuối sẽ có popup thông báo “Vui lòng kích hoạt để
            xem bản đầy đủ”, thầy cô nhập mã kích hoạt sách tại ô kích hoạt và
            bấm “Xác nhận” để hoàn tất.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/cach_2_hien_thi_popup_kich_hoat_sach_tu_cuoi_trang.png`}
              alt="Cách 2: Kích hoạt trên sách (Đối với các sách chưa mở toàn bộ)"
            />
          </div>
          <p className="mt-3">
            Các sách đã kích hoạt thành công, thầy cô có thể truy cập để xem đầy
            đủ toàn bộ nội dung có trong sách.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/cach_2_kich_hoat_thanh_cong.png`}
              alt="Kích hoạt thành công"
            />
          </div>
          <p className="mt-3">Lưu ý:</p>
          <p className="font-italic mt-3">
            - Mỗi mã kích hoạt thầy cô được kích hoạt 1 lần với sách điện tử
            tương ứng trên 1 tài khoản duy nhất. Vì vậy thầy cô lưu ý thông tin
            tài khoản mà mình đã kích hoạt sách để đăng nhập sử dụng trên các
            thiết bị.
          </p>
        </li>
        <li>
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li>Chúc thầy cô thao tác thành công!</li>
      </ul>
    </>
  );
}
