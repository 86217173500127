import { useEffect, useState } from "react";
import styles from "../../teachingManager.module.scss";
import { useHistory } from "react-router-dom";
import ListMemberClassroom from "../MemberManager/ListMemberClassroom";
import iconBack from "edu_lms_v2/assets/img/back1.svg";
import { ROUTE_PATH_CLASSROOM } from "consts/routePaths";
import { getInfoClassroomById } from "edu_lms/services/teachingManager";
import ExerciseClassroom from "../ExerciseClassroom";
import { grades } from "edu_lms/constants/type";
import {
  APP_ID as HOC10_APP_ID,
  TUTORING_APP_ID,
} from "edu_lms/constants/type";

const tabs = [
  // {
  //   title: "Bảng tin",
  //   icon: "fa fa-home",
  //   href: "#news",
  //   selected: true,
  // },
  {
    title: "Thành viên",
    icon: "fa fa-users",
    href: "#member",
    selected: true,
  },
  {
    title: "Bài tập",
    icon: "fa fa-book",
    href: "#excercise",
    selected: false,
  },
];

export default function ClassRoomDetail({ classroomId }) {
  const product = Number(process.env.REACT_APP_APP_ID);
  const [tabSelect, setTabSelect] = useState("#member");
  const [classroomInfo, setClassroomInfo] = useState({});
  const history = useHistory();
  const grade = grades.find((grade) => grade.value === classroomInfo?.grade_id);

  const onClickBack = () => {
    history.push(ROUTE_PATH_CLASSROOM);
  };

  const getInfoClassroom = () => {
    getInfoClassroomById(classroomId)
      .then((res) => {
        if (res.data.status === "success") {
          setClassroomInfo(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getInfoClassroom();
  }, []);

  const handleShowClassroom = () => {
    if (product === HOC10_APP_ID) {
      return `${grade?.label}${classroomInfo?.title}`;
    }

    if (product === TUTORING_APP_ID) {
      return classroomInfo?.title;
    }
  };

  return (
    <main>
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb"></ul>
        </div>
      </div>
      <div className="hoc10-information bg-gr">
        <div className="container position-relative">
          <button
            className={`position-absolute ${styles.btnBack}`}
            onClick={() => onClickBack()}
          >
            <img src={iconBack} />
          </button>
          <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-4">
            <div className="border-bottom p-4">
              <div className="d-flex">
                <h4 className="title font-weight-bold h4 monkey-color-black">
                  {classroomInfo?.title && handleShowClassroom()}
                </h4>
                {/* <div className="text-right">
                  <button className={`mr-3 ${styles.buttonOrange}`}>
                    <i className="fa fa-bar-chart" aria-hidden="true"></i> Báo
                    cáo
                  </button>
                  <button
                    className={styles.colorOrange}
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-cog text-fsize" aria-hidden="true"></i>
                  </button>
                </div> */}
              </div>
              {/* <div>
                <p className="col-sm-5">
                  {!classroomInfo?.school_name && (
                    <i
                      className={`fa fa-plus-circle mr-2 ${styles.colorOrange}`}
                      aria-hidden="true"
                    ></i>
                  )}
                  {classroomInfo?.school_name
                    ? classroomInfo.school_name
                    : "Không thuộc trường"}
                </p>

                <div className="col-sm-7 pl-lg-3">
                  {classroomInfo?.students_counts} học sinh |&nbsp;
                  {classroomInfo?.teachers_counts} giáo viên
                </div>
              </div> */}
            </div>
            <div className="p-4">
              <ul
                className="nav nav-pills d-flex justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                {tabs.map((tab, index) => (
                  <li key={index} className="mx-1 mx-sm-3">
                    <a
                      href={tab.href}
                      title={tab.title}
                      data-toggle="pill"
                      role="tab"
                      aria-selected={tab.selected}
                      className={tab.href === tabSelect ? styles.tabActive : ""}
                      onClick={() => setTabSelect(tab.href)}
                    >
                      <i className={tab.icon}></i>
                      <span className="ml-1">{tab.title}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <ListMemberClassroom
              classroomId={classroomId}
              getInfoClassroom={getInfoClassroom}
            />
            <ExerciseClassroom
              classroomId={classroomId}
              getInfoClassroom={getInfoClassroom}
              studentCounts={classroomInfo.students_counts}
            />
          </div>
        </div>
      </div>
    </main>
  );
}
