import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { styleWrapper } from "../../constants/styles";
import {
  formatWord,
  getAllCorrectAnswerTextsBySelectedTexts,
  getAllCorrectAnswerTexts,
} from "../../helpers/WordFinding";
import HeaderQuestion from "./HeaderQuestion";
import ContentAnswer from "./ContentAnswer";
import "./styles.scss";

const WordFinding = (
  {
    gameData,
    hideResultAnswer = false,
    selectedTextsProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const { question, answer } = gameData;
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [showNonSelectedCorrect, setShowNonSelectedCorrect] = useState(false);

  /**---------- Show selected texts answer result ----------**/
  useEffect(() => {
    if (selectedTextsProp.length > 0) {
      setSelectedTexts(selectedTextsProp);
    }
  }, [selectedTextsProp]);

  /**---------- Show all correct answers result ----------**/
  useEffect(() => {
    if (showCorrectAnswer) {
      const allCorrectAnswerTexts = getAllCorrectAnswerTexts(answer.answers);
      setSelectedTexts(allCorrectAnswerTexts);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCorrectAnswer]);

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
    handleShowNonSelectedCorrect,
  }));

  const handleCheckAnswer = () => {
    setIsCheckedAnswer(true);
    const shadowSelectedTexts = [...selectedTexts].map(selectedText => {
      const focusedAnswerLine = answer.answers.find(answerLine => answerLine.answerId === selectedText.answerId);
      const isCorrect = focusedAnswerLine.answerResults.some((answerResult) =>
        answerResult.includes(formatWord(selectedText.text))
      );

      return {
        ...selectedText,
        isCorrect
      }
    });

    setSelectedTexts(shadowSelectedTexts);

    const allCorrectAnswerTexts = getAllCorrectAnswerTextsBySelectedTexts(answer.answers, selectedTexts);
    const isCorrect =
      allCorrectAnswerTexts.length === shadowSelectedTexts.length &&
      shadowSelectedTexts.length === shadowSelectedTexts.filter((text) => text.isCorrect).length;
    onComplete({ isCorrect, selectedTexts: shadowSelectedTexts });
  };

  const handleResetAnswer = () => {
    setIsCheckedAnswer(false);
    setIsViewOnly(false);
    setShowNonSelectedCorrect(false);
    setSelectedTexts([]);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleShowNonSelectedCorrect = () => {
    setShowNonSelectedCorrect(true);
  };

  const handleClickText = (text, textIndex, answerId) => {
    let shadowSelectedTexts = [...selectedTexts];
    if (isCheckedAnswer) {
      setIsCheckedAnswer(false);
      shadowSelectedTexts = shadowSelectedTexts.map(text => ({ ...text, isCorrect: null }));
    }

    const selectedTextIndex = selectedTexts.findIndex(
      (text) => text.textIndex === textIndex && text.answerId === answerId
    );
    if (selectedTextIndex > -1) {
      shadowSelectedTexts.splice(selectedTextIndex, 1);
    } else {
      shadowSelectedTexts.push({ answerId, textIndex, text });
    }
  
    setSelectedTexts(shadowSelectedTexts);

    onPlaying(false);
  };

  return (
    <div className="wordFinding__wrapper" style={styleWrapper}>
      <HeaderQuestion question={question} />
      <ContentAnswer
        answer={answer}
        selectedTexts={selectedTexts}
        isReadOnly={isReadOnly || isViewOnly}
        isCheckedAnswer={isCheckedAnswer}
        hideResultAnswer={hideResultAnswer}
        showNonSelectedCorrect={showNonSelectedCorrect}
        onClickText={handleClickText}
      />
    </div>
  );
};

export default forwardRef(WordFinding);
