import React from "react";
import styles from "../BlogStyle.module.scss";
import Select from "react-select";
import { OPTION_SUBJECT } from "../constant";

export default function Filter({ optionOther, setOptionOther }) {
  return (
    <div className="col-lg-12 col-12">
      <div className={`justify-content-end d-flex ${styles.news}`}>
        <label className={styles.label}>Sắp xếp theo:</label>
        <div className={styles.options}>
          <div className="row justify-content-between">
            <div className={`col-12 col-md-12 ${styles.selectOption}`}>
              <Select
                options={OPTION_SUBJECT}
                maxMenuHeight={500}
                value={optionOther}
                menuPortalTarget={document.body}
                onChange={(subject) => setOptionOther(subject)}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 5 }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
