
const checkResultAnswer = (answers = [], selectedAnswers = []) => {
  if (selectedAnswers.length === 0) return false;

  let numberOfCorrect = 0;
  let numberOfSelectedCorrect = 0;
  answers.forEach((answer) => {
    if (answer.isCorrect) {
      numberOfCorrect++;
    }
    if (selectedAnswers.includes(answer.answerId) && answer.isCorrect) {
      numberOfSelectedCorrect++;
    }
  });

  return numberOfSelectedCorrect === numberOfCorrect && numberOfCorrect === selectedAnswers.length;
};

export { checkResultAnswer };