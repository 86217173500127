import LessonPlan from "./LessonPlan"

export default function SectionGiaoAn() {
	return (
          <div className="col-md-12">
              <LessonPlan
                  categoryBook="Bài giảng PowerPoint"
                  col="col-6 col-lg-3"
                  background="#fff"
              />
          </div>
  );
}
