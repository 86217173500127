import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";

export default function HeadingViewOther() {
  return (
    <ul className={`breadcrumb ${styles.heading}`}>
      <li className="breadcrumb-item">
        <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        <Link to={PATH.ROUTE_BLOG_KNOWLEDGE}>Kiến thức</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        Kiến thức khác
      </li>
    </ul>
  );
}
