import AxiosService from "../axiosService";

const listBookType = "list-book-type";
const listGrade = "list-grade?";
const listSubject = "list-subject?";
const listNotification = "get-list-notification-by-user-id?";
const readNotification = "update-notification-by-user-id";
const logAxios = "axios-logs";

export const getListBookType = () => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${listBookType}`
  );
};

export const getListGrade = (data = 0, isGetAllData = false) => {
  const axiosService = new AxiosService();
  const baseUrl = `${process.env.REACT_APP_API_URL}${listGrade}get_all=${data}`;
  const url = isGetAllData ? `${baseUrl}&getAllData=true` : baseUrl;
  return axiosService.getRequest(url);
};
export const getListSubject = (id, idNotIn = '') => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${process.env.REACT_APP_API_URL}${listSubject}grade_id=${id}&id_not_in=${idNotIn}`
  );
};
export const getListNoti = ({ limit, page, read }) => {
  const axiosService = new AxiosService();
  return axiosService.getRequest(
    `${
      process.env.REACT_APP_API_URL
    }${listNotification}limit=${limit}&page=${page}&read=${read || ""}`
  );
};
export const postReadNotification = (data) => {
  const axiosService = new AxiosService();
  return axiosService.postRequest(
    `${process.env.REACT_APP_API_URL}${readNotification}`,
    { id: data }
  );
};

export const postLogAxios = (data) => {
  const axiosService = new AxiosService();
  return axiosService.postRequest(
    `${process.env.REACT_APP_API_URL}${logAxios}`,
    { data }
  );
};
