import React from "react";
import _ from "lodash";
import { TYPE_TEXT } from "edu_lms/constants/type";
import MusicIcon from "./MusicIcon";
import Audio, { AudioType } from "../Audio";
import Typography from "../Typography";
import { TYPE_GAME } from "../../constants";
import styles from "./styles.module.scss";

function formatTitleQuestion(activity) {
  const gameConfigIconId = _.get(activity, "game_config.question_title.icon_id", "");
  return _.get(activity, "icon_list[0].icons", []).filter(
    (icon) =>
      String(icon.icon_id).replace(/\s+/g, "") ===
      String(gameConfigIconId).replace(/\s+/g, "")
  );
}

const GameContainer = ({
  activity,
  children,
}) => {
  const { game_id, game_config } = activity;

  const isShowTextQuestion = game_id === TYPE_GAME.FIB_BG ? _.includes(game_config?.type_question, TYPE_TEXT) : true;
  const title = formatTitleQuestion(activity);

  const renderTitle = () => {
    if (!(_.get(title[0], "props[0].text") && isShowTextQuestion)) return null;

    const typeText = _.includes(game_config?.type_text, TYPE_TEXT) && TYPE_TEXT;
    const fontSize = game_config.font_size_title || game_config.font_size;
    return (
      <Typography
        typeText={typeText}
        fontSize={fontSize}
        text={_.get(title[0], "props[0].text")}
      />
    )
  }

  return (
    <div className={`${styles["game-container"]} position-relative`}>
      <MusicIcon />
      <div className="match-title monkey-fz-30 text-center quicksand-medium">
        <div className={`p-3 pr-3 text-left ${styles.text}`}>
          {renderTitle()}
        </div>
        <Audio
          variant={AudioType.Secondary}
          src={_.get(title[0], "props[0].audio[0].path")}
        />
      </div>
      {children}
    </div>
  );
};

export default GameContainer;
