import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import { useForm } from "react-hook-form";
import { changePw } from "edu_lms/services/forgotPw/forgotPw";
import { APP_ID_WEB, APP_ID_MOBILE } from "edu_lms/constants/type";
import { FORGOT_PASSWORD } from "edu_lms/constants/eventNameGTM";
import { setEventGTM } from "edu_lms/constants/googleTagManager";

export default function ResetPw(props) {
  const [isStatus, onSetStatus] = useState(false);
  const [messageError, onSetMessageError] = useState("");
  const [isPassword, setStatePassword] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const onChangePw = (dataForm) => {
    if (dataForm.password != dataForm.confirm_password) {
      onSetStatus(true);
      onSetMessageError("Mật khẩu không trùng khớp");
    } else {
      let data = {
        token_to_change_pw: props.token,
        password: dataForm.password,
        is_web: 1,
      };
      let typeOtp = "email";
      if (props.data.type == 2) {
        data = { ...data, email: props.data.email, app_id: APP_ID_WEB };
      } else {
        typeOtp = "phone";
        data = {
          ...data,
          phone: props.data.phone,
          app_id: APP_ID_MOBILE,
          country_code: "+84",
        };
      }

      changePw(data)
        .then((res) => {
          let resetSuccessful = false;

          if (res.data.status === "fail") {
            onSetMessageError(res.data.message);
          }
          if (res.data.status === "success") {
            props.onActiveSuccessPw();
            resetSuccessful = true;
          }
          setEventGTM({
            event: FORGOT_PASSWORD,
            send_otp: typeOtp,
            reset_successful: resetSuccessful,
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
      reset();
    }
  };
  useEffect(() => {
    onSetMessageError("");
  }, [props.show]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal fade modal-success modal-active modal-active-book"
      animation="true"
    >
      <div className="box-success">
        <div className="txt-2 border-bottom text-center pb-4 text-fsize">
          Đặt lại mật khẩu
        </div>

        <form
          onSubmit={handleSubmit(onChangePw)}
          className="text-center mt-4 reset-pw"
        >
          <div className="img-wrapper text-center wp-eye fsize">
            <p className="m-4">Mật khẩu phải có ít nhất 8 kí tự</p>
            <input
              type={isPassword ? "text" : "password"}
              className="form-control w-90  mx-auto mb-2 wp-input-eye fsize"
              placeholder="Mật khẩu mới"
              name="password"
              ref={register({ required: true, minLength: 8 })}
            />
            <HideShowPw1
              className="eye-pw"
              onClick={() => setStatePassword(!isPassword)}
              className={`fa cursor ${
                isPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
              }`}
              aria-hidden="true"
            ></HideShowPw1>
            {errors.password && errors.password.type === "minLength" && (
              <span className="monkey-color-red mb-2">
                Mật khẩu ít nhất có 8 kí tự
              </span>
            )}
            <div className="re-password position-relative">
              <input
                type={isPassword ? "text" : "password"}
                className="form-control w-90  mx-auto mb-2 wp-input-eye fsize"
                placeholder="Xác nhận mật khẩu mới"
                name="confirm_password"
                ref={register({ required: true, minLength: 8 })}
              />
              <HideShowPw
                className="re-eye-pw"
                onClick={() => setStatePassword(!isPassword)}
                className={`fa cursor ${
                  isPassword ? "fa-eye monkey-bc-black" : "fa-eye-slash"
                }`}
                aria-hidden="true"
              ></HideShowPw>
            </div>
            {errors.confirm_password &&
              errors.confirm_password.type === "minLength" && (
                <span className="monkey-color-red mb-2">
                  Mật khẩu ít nhất có 8 kí tự
                </span>
              )}
          </div>
          {isStatus && (
            <div className="text-center text-danger mb-3">{messageError}</div>
          )}
          <button
            title=""
            className="btn-pr btn-access w-25 mr-3 bg-white text-orange border-orange fsize"
            type="submit"
            onClick={props.onHide}
          >
            Hủy
          </button>
          <button
            title=""
            className="btn-pr btn-access w-25 reset-pw fsize"
            type="submit"
          >
            Thay đổi
          </button>
        </form>
      </div>
    </Modal>
  );
}

const HideShowPw = styled.i`
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  top: 50%;
  z-index: 20;
  font-size: 16px;
`;

const HideShowPw1 = styled.i`
  position: absolute;
  top: 160px;
  right: 134px;
  z-index: 20;
  font-size: 16px;
`;
