import * as yup from "yup";

export default yup.object().shape({
  teaching: yup
    .array()
    .of(
      yup.object().shape({
        grade: yup.object().shape({}).required("Yêu cầu chọn lớp"),
        subjects: yup
          .array()
          .of(yup.object().shape({}))
          .min(1, "Bạn cần chọn ít nhất 1 lớp và 1 môn")
          .required("Yêu cầu chọn môn học"),
      })
    )
    .required("Yêu cầu chọn đầy đủ thông tin"),
});
