import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import * as PATH from "../../../consts/routePaths";
import ICON_TUTORIAL from "../../assets/images/icon-tutorial-01.svg";

const ButtonGoTop = () => {
  const [isVisible, setStateVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 600) {
        setStateVisible(true);
      } else {
        setStateVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <div className="body-bottom">
      <div className="go-top">
        <div id="go-top-body" className="go-top-body">
          {isVisible && (
            <button
              onClick={() => scroll.scrollToTop()}
              className="go-item distance-center cursor go-top-item"
            >
              <i className="icon-monkey-go-top monkey-fz-15 monkey-color-white"></i>
              <div className="text-item text-go-top monkey-fz-14">Kéo lên</div>
            </button>
          )}
          <Link
            className="go-item distance-center cursor monkey-bc-transparent"
            to={PATH.ROUTE_INSTRUCTION_ACTIVATED_BOOK}
          >
            <img
              style={{ height: "25px", position: "relative", zIndex: "5" }}
              src={ICON_TUTORIAL}
              alt=""
            />
            <div className="text-item text-support text-left monkey-fz-14">
              Hướng dẫn kích
              <br /> hoạt sách
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ButtonGoTop;
