import * as TYPES from "edu_lms/constants/actionsType";
import {
} from "./constant";
import { DEFAULT_GRADE_ID, DEFAULT_SUBJECT_ID, DEFAULT_VALUE_BLOCK } from "../MockTest/constant";

let initialState = {
  list: {
    block: DEFAULT_VALUE_BLOCK,
    gradeId: DEFAULT_GRADE_ID,
    subjectId: DEFAULT_SUBJECT_ID,
  },
  initialPage: 0,
  title: '',
  week: {
    value: '',
    label: '',
  },
};

let filterSlideReducers = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.PA_UPDATE_SLIDE_FILTER:
      return { ...state, list: action.data };
    case TYPES.SET_INITIAL_PAGE:
      return { ...state, initialPage: action.data };
    case TYPES.PA_UPDATE_TITLE_SEARCH_SLIDE:
      return { ...state, title: action.data };
    case TYPES.PA_UPDATE_SUBJECT_WEEK_SEARCH_SLIDE:
      return { ...state, week: action.data };
    default:
      return state;
  }
};

export default filterSlideReducers;