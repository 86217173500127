import React from "react";
import styled from "styled-components";
import Select from "react-select";

export default function Filter({
  selectedRegion,
  onChangeRegion,
  selectedCity,
  onChangeCity,
  optionRegions,
  optionCities,
}) {
  return (
    <SelectRegion className="row pt-2">
      <div className="col-12 col-sm-6">
        <label className="monkey-fz-20">Vùng miền</label>
        <Select
          options={optionRegions}
          maxMenuHeight={200}
          placeholder="Chọn vùng miền"
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 5 }),
          }}
          value={selectedRegion}
          onChange={onChangeRegion}
        />
      </div>
      <div className="col-12 col-sm-6">
        <label className="monkey-fz-20 label-cities">Tỉnh thành phố</label>
        <Select
          options={optionCities}
          maxMenuHeight={200}
          placeholder="Chọn tỉnh, thành phố"
          menuPortalTarget={document.body}
          styles={styles}
          value={selectedCity}
          onChange={onChangeCity}
        />
      </div>
    </SelectRegion>
  );
}

const SelectRegion = styled.div`
  margin-bottom: 20px;
  label {
    margin-bottom: 0;
  }
  @media (max-width: 576px) {
    .label-cities {
      margin-top: 20px;
    }
  }
`;

const styles = {
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#fff" : "",
    color: "hsl(0, 0%, 40%)",
    "&:hover": {
      ...styles,
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 5 }),
};
