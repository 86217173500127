import { Link } from "react-router-dom";
import styled from "styled-components";

export default function TuSachHeader() {
  return (
    <>
      <div className="hoc10-breadcrumb bg-gr position-relative">
        <BannerImage>
          <source
            media="(min-width:992px)"
            srcSet="/assets/img/banner_gift-20-11.png"
          />
          <source
            media="(min-width:756px)"
            srcSet="/assets/img/banner_gift-20-11-tablet.png"
          />
          <img src="/assets/img/banner_gift-20-11-mobile.png" alt="banner" />
        </BannerImage>
        <Breadcrumb className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""} className="monkey-fz-18">
                Trang chủ
              </Link>
            </li>
            <li
              className="breadcrumb-item active monkey-fz-18"
              aria-current="page"
            >
              Tủ sách
            </li>
          </ul>
        </Breadcrumb>
      </div>
      <BookCase className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title">Tủ sách</h1>
          <p className="txt-5 mb-2">Tủ sách điện tử tương tác</p>
        </div>
      </BookCase>
    </>
  );
}

const Breadcrumb = styled.div`
  ul li {
    z-index: 1;
  }
`;

const BookCase = styled.div`
  min-height: 200px;
  div {
    z-index: 9;
  }
`;

const BannerImage = styled.picture`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
  width: 100%;
  img {
    width: 100%;
    height: 233px;
  }
`;
