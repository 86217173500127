import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as PATH from "../../constants/background";
import * as TEXT from "../../constants/text";
import TitleComponent from "../title";
import {
  onDispatchShowPopupActivateBook,
  onDispatchTypePopup,
  onDispatchTogglePopupSuccess,
} from "../../modules/App/actions";
import { TYPE_POPUP_ACTIVE_BOOK } from "../../constants/type";

const NotBook = (props) => {
  const onShowPopupNotification = () => {
    props.onDispatchTypePopup(TYPE_POPUP_ACTIVE_BOOK);
    props.onDispatchShowPopupActivateBook(true);
  };
  return (
    <div className="mb-5">
      <div className="row justify-content-center d-flex align-items-center">
        <div className="col-12 col-md-4 mt-4">
          <img className="w-100" src={PATH.IMAGE_ERROR_BOOK} alt="#" />
        </div>
        <div className="col-12 col-md-6 mt-4 justify-content-center d-flex align-items-center">
          <div>
            <TitleComponent
              title={props.title ? props.title : `${TEXT.TITLE_NOT_BOOK}`}
              className="monkey-color-violet monkey-f-header monkey-fz-30 text-uppercase"
            />
            {!props.status && (
              <div className="mt-5 text-center">
                <button
                  onClick={onShowPopupNotification}
                  className="btn rounded-pill hvr-registration-white monkey-f-header monkey-fz-20 monkey-bg-violet text-uppercase monkey-color-white pt-2 pb-2 pr-3 pl-3"
                >
                  {TEXT.LINK_ACTIVATE_BOOK}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { gradeIdBookUsed } = state.generalReducer;
  return {
    gradeIdBookUsed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onDispatchTogglePopupSuccess,
      onDispatchShowPopupActivateBook,
      onDispatchTypePopup,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(NotBook);
