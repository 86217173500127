import React from "react";
import { useDrag } from "react-dnd";
import _ from "lodash";
import {
  UseSound,
  Typography,
  Image,
} from "edu_lms_v2/libraries/hoc10Game/components";
import { URL_AUDIO } from "edu_lms/constants/type";
import { TYPE_DATA } from "edu_lms_v2/libraries/hoc10Game/constants";
import styles from "./styles.module.scss";

const BoxAnswer = ({
  answerItem,
  typeAnswer,
  widthImage,
  styleWrapper = {},
  isViewOnly = false,
}) => {
  const { answerId, type, srcAudio, srcImage, text } = answerItem;

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type,
      item: { answerId },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    []
  );

  const hasImage = _.includes(typeAnswer, TYPE_DATA.IMAGE);

  return (
    <div
      ref={dragRef}
      style={{
        ...styleWrapper,
        opacity,
      }}
      className={`${styles["box-answer__wrapper"]} ${
        isViewOnly ? styles["view-only"] : ""
      } ${hasImage ? styles["has-image"] : ""}`}
    >
      {srcAudio && <UseSound src={`${URL_AUDIO}${srcAudio}`} />}
      {_.includes(typeAnswer, TYPE_DATA.TEXT) && (
        <Typography
          text={text.contentText}
          typeText={text.typeText}
          fontSize={text.fontSize || 18}
        />
      )}
      {hasImage && (
        <Image
          src={srcImage}
          width={widthImage ? widthImage : "120px"}
          height="auto"
        />
      )}
    </div>
  );
};

export default BoxAnswer;
