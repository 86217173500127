export const ROUTE_NAME_INTRODUCE = "Giới thiệu sách";
export const ROUTE_NAME_SIGN_IN = "đăng nhập";
export const ROUTE_PATH_FORGOT_PASSWORD = "quên mật khẩu";
export const ROUTE_PATH_ACTIVATE_BOOK = "nhập mã sách";
export const ROUTE_PATH_SIGN_UP = "đăng ký";
export const ROUTE_NAME_PRODUCT = "Tủ sách";
export const ROUTE_NAME_TUTORIAL = "Tài liệu hướng dẫn";
export const ROUTE_NAME_ADD_BOOK = "Kích hoạt sách";
export const ROUTE_NAME_UPDATE_INFORMATION = "cập nhật thông tin";
export const ROUTE_NAME_TRAINING = "Tập huấn";
export const ROUTE_NAME_PROGRAMME_GDPT = "Chương trình GDPT 2018";
export const ROUTE_NAME_INFO = "Cập nhật thông tin";
export const ROUTE_NAME_FORGOT_PASSWORD = "Thay đổi mật khẩu";
export const ROUTE_NAME_LOGOUT = "Đăng xuất";
export const ROUTE_NAME_INSTRUCTION_ACTIVATED_BOOK = "Hướng dẫn kích hoạt";
export const ROUTE_NAME_MY_BOOK_STORE = "Kho sách của tôi";
export const ROUTE_NAME_ELECTRONIC_COURSEWARE = "Học liệu điện tử";
export const ROUTE_NAME_EDUCATION_TEACHER = "Tài liệu tập huấn";
// export const ROUTE_NAME_FORM_JOB = "Thoát";
export const ROUTE_NAME_OVERALL_PROGRAM = "Chương trình tổng thể";
export const ROUTE_NAME_EDUCATION_PROGRAM = "Chương trình các môn học";
export const ROUTE_NAME_BOOK_INSTRUCTION = "Giới thiệu sách";
export const ROUTE_NAME_TRAINING_TEST = "Bài kiểm tra tập huấn";
export const ROUTE_NAME_SLIDE_LIBRARY = "Bài giảng điện tử";
export const ROUTE_NAME_LIBRARY = "Thư viện điện tử";
export const ROUTE_NAME_EXAM_EXERCISES = "Danh sách tập huấn";
export const ROUTE_NAME_DOWNLOAD = "Download";
export const ROUTE_NAME_DASHBOARD = "Quản lý dashboard";
