import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import Loading from "edu_lms_v2/components/Loading";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { SEARCH_LECTURE } from "edu_lms/constants/eventNameGTM";
import SEO from "edu_lms/components/Seo";
import { pages } from "edu_lms/components/Seo/meta";
import { renderDocumentByType } from "modules/TuSach/const";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import { DEFAULT_SUBJECT_SLUG_BY_GRADE } from "consts/book";
import GradeFilter from "components/GradeFilter";
import SubjectFilter from "components/SubjectFilter";
import { ROUTE_PATH_COURSE } from "consts/routePaths";
import { useHocLieuStore } from "stores/hoclieuStore";
import { useAppStore } from "stores/appStore";
import { useQueryState } from "hooks/useQueryState";
import SearchForm from "./SearchForm";
import { onResultUserInfo } from "../../edu_lms/modules/selection";

export default function HocLieu() {
  useScrollToTop();

  const [url, setUrl] = useState("");

  const history = useHistory();
  const params = useParams();

  const gradeId = useAppStore((state) => state.gradeId);
  const subjectId = useAppStore((state) => state.subjectId);
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const setSubjectId = useAppStore((state) => state.setSubjectId);
  const setPage = useHocLieuStore((state) => state.setPage);
  const fetchGradeSubjects = useHocLieuStore(
    (state) => state.fetchGradeSubjects
  );
  const fetchHocLieu = useHocLieuStore((state) => state.fetchHocLieu);
  const fetchingHocLieu = useHocLieuStore((state) => state.fetchingHocLieu);
  const listHocLieu = useHocLieuStore((state) => state.listHocLieu);
  const page = useHocLieuStore((state) => state.page);
  const limit = useHocLieuStore((state) => state.limit);
  const total = useHocLieuStore((state) => state.total);
  const gradeSubjects = useHocLieuStore((state) => state.gradeSubjects);
  const textSearch = useHocLieuStore((state) => state.textSearch);
  const setTextSearch = useHocLieuStore((state) => state.setTextSearch);
  const [queryTextSearch] = useQueryState("tieude");

  const currentSubject = subjects.find((subject) => subject.id === subjectId);
  const currentGrade = grades.find((grade) => grade.id === gradeId);
  const userInfo = onResultUserInfo();
  const fetchGrades = useAppStore((state) => state.fetchGrades);
  const fetchSubjects = useAppStore((state) => state.fetchSubjects);
  
  useEffect (() => {
    if (!Object.keys (grades).length) {
      fetchGrades ();
    }
    if(!Object.keys(subjects).length) {
      fetchSubjects();
    }
  }, []);
  useEffect(() => {
    if (queryTextSearch) {
      setTextSearch(queryTextSearch);
    }
  }, []);

  useEffect(() => {
    fetchGradeSubjects();
  }, [currentGrade?.parent_id, fetchGradeSubjects]);

  useEffect(() => {
    fetchHocLieu();
  }, [gradeId, subjectId, page, limit, fetchHocLieu]);

  useEffect(() => {
    const setDefaultSubject = () => {
      // Dang co mot table manage viec display grade, subject nen phai lay o 2 nguon moi du data
      // TODO: toi uu data management cho viec hien thi grade, subject tren caca page
      const _currentGrade = gradeSubjects?.grades?.find(
        (grade) => grade.grade_id === gradeId
      );
      if (_currentGrade) {
        const currentGrade = grades.find((grade) => grade.id === gradeId);
        // 1. Check subject value from store
        if (subjectId) {
          const currentSubjectFromStore = subjects.find(
            (subject) => subject.id === subjectId
          );
          if (
            currentSubjectFromStore &&
            _currentGrade?.has_subject_ids?.includes(currentSubjectFromStore.id)
          ) {
            return;
          }
        }
        // 2. Check subject value from url params
        const urlParamSubject = params?.subject;
        if (urlParamSubject) {
          const currentSubjectFromUrlParams = subjects.find(
            (subject) =>
              subject.slug === urlParamSubject &&
              subject.grade_id === currentGrade?.parent_id
          );
          if (
            currentSubjectFromUrlParams &&
            _currentGrade?.has_subject_ids?.includes(
              currentSubjectFromUrlParams.id
            )
          ) {
            setSubjectId(currentSubjectFromUrlParams.id);
            return;
          }
        }
        // 3. Check default subject value
        const defaultSubjectSlug =
          DEFAULT_SUBJECT_SLUG_BY_GRADE[currentGrade?.parent_id];
        if (defaultSubjectSlug) {
          const defaultSubject = subjects.find(
            (subject) =>
              subject.slug === defaultSubjectSlug &&
              subject.grade_id === currentGrade?.parent_id
          );
          if (
            defaultSubject &&
            _currentGrade?.has_subject_ids?.includes(defaultSubject.id)
          ) {
            setSubjectId(defaultSubject.id);
            return;
          }
        }
        const filteredSubjects = subjects.filter((subject) =>
          _currentGrade?.has_subject_ids?.includes(subject.id)
        );
        // 4. Check subject value from the first item of subject list
        const defaultSubject = _.get(filteredSubjects, "0");
        if (defaultSubject) {
          setSubjectId(defaultSubject.id);
        }
      }
    };

    setDefaultSubject();
  }, [gradeId, gradeSubjects?.grades, grades, params?.subjectSlug]);

  const buildUrl = () => {
    if (currentSubject?.slug && currentGrade?.slug) {
      const url = `${ROUTE_PATH_COURSE}${currentSubject.slug}/${currentGrade.slug}/`;
      const data = _.pickBy(
        {
          tieude: textSearch,
        },
        (value) => !!value
      );
      const searchParams = new URLSearchParams(data);

      return `${url}?${searchParams}`;
    }
    return "";
  };

  useEffect(() => {
    if (currentSubject?.slug && currentGrade?.slug) {
      let url = buildUrl();
      if (url.endsWith("?")) {
        url = url.slice(0, -1);
      }
      window.history.replaceState(null, '', url);
    }
  }, [currentGrade?.slug, currentSubject?.slug]);

  const onSubmit = (dataForm) => {
    setEventGTM({ event: SEARCH_LECTURE, search_key: dataForm.title });
  };

  return (
    <main>
      <SEO data={pages.course} />
      <div className="hoc10-breadcrumb bg-gr position-relative">
        <Banner>
          <img
            className="banner-desktop"
            src="/assets/img/banner_gift-20-11.png"
            alt="banner-gift-20-11"
          />
          <img
            className="banner-tablet"
            src="/assets/img/banner_gift-20-11-tablet.png"
            alt="banner-gift-20-11"
          />
          <img
            className="banner-mobile"
            src="/assets/img/banner_gift-20-11-mobile.png"
            alt="banner-gift-20-11"
          />
        </Banner>
        <HistoryRouter className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""} className="monkey-fz-18">
                Trang chủ
              </Link>
            </li>
            <li
              className="breadcrumb-item active monkey-fz-18"
              aria-current="page"
            >
              Học liệu
            </li>
          </ul>
        </HistoryRouter>
      </div>
      <BookCase className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title">Học liệu</h1>
          <p className="txt-5 mb-2">Kho học liệu điện tử sách Cánh Diều</p>
        </div>
      </BookCase>

      <div className="hoc10-lesson-library">
        <div className="container">
          <div className="filter filter-horizontal mt-3 mb-4 p-0">
            <GradeFilter
              direction="horizontal"
              onChange={() => {
                setPage(0);
              }}
            />
          </div>
          <div className="row">
            <div className="flex-lc justify-content-between col-sm-12 mb-3">
              <div className="monkey-fz-28 col-md-7 font-weight-bold text-dark px-0">
                <BookMark className="fa fa-bookmark pr-2" aria-hidden="true" />
                Video minh họa nội dung sách
              </div>
              {userInfo && userInfo?.is_user_hoc10 && <SearchForm />}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="filter">
                <GradeFilter
                  onChange={() => {
                    setPage(0);
                  }}
                />
                <SubjectFilter
                  page={ROUTE_PATH_COURSE}
                  onChange={() => {
                    setPage(0);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-9 col-md-8 result">
              <p className="text-uppercase monkey-color-orange monkey-fz-28 font-weight-bold pb-3">
                {currentSubject?.title} {currentGrade?.name}
              </p>
              <div className="row">
                {!fetchingHocLieu ? (
                  listHocLieu.length > 0 ? (
                    listHocLieu.map((item, index) =>
                      renderDocumentByType(
                        item.type_document,
                        item,
                        index,
                        "col-6 col-lg-4",
                        (url) => setUrl(url),
                        true
                      )
                    )
                  ) : (
                    <div className="w-100 text-center monkey-fz-24">
                      Sắp ra mắt
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center w-100">
                    {" "}
                    <Loading />{" "}
                  </div>
                )}
              </div>

              <VideoPlayerModal
                show={Boolean(url)}
                url={url}
                onClose={() => setUrl("")}
              />

              {total > 1 && (
                <ReactPaginate
                  previousLabel={""}
                  previousClassName={"icon icon-prev"}
                  nextLabel={""}
                  nextClassName={"icon icon-next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(total / limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={(data) => {
                    setPage(data.selected);
                  }}
                  forcePage={page}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeClassName="active"
                  previousLinkClassName="page-link page-link--prev"
                  nextLinkClassName="page-link page-link--next"
                  hrefAllControls
                  hrefBuilder={() => {
                    return "javascript:;";
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const Banner = styled.div`
  .banner-tablet,
  .banner-mobile {
    display: none;
  }
  z-index: 1;
  position: absolute;
  opacity: 0.7;
  img {
    height: 233px;
  }
  @media (max-width: 700px) {
    img {
      height: 200px;
    }
  }
  @media screen and (max-width: 992px) and (min-width: 576px) {
    .banner-tablet {
      display: block;
    }
    .banner-desktop,
    .banner-mobile {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .banner-mobile {
      display: block;
    }
    .banner-tablet,
    .banner-desktop {
      display: none;
    }
  }
`;

const HistoryRouter = styled.div`
  ul li {
    z-index: 1;
  }
`;

const BookCase = styled.div`
  div {
    z-index: 9;
  }
`;

const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
`;
