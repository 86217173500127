import React, { useState, useEffect } from "react";
import Select from "react-select";
import LabelInput from "../LabelInput";
import { customStyles } from "../selection";
import { Controller } from "react-hook-form";

const ControllerTeachingInformation = ({
  required,
  nameClass,
  nameSubjects,
  listClass,
  control,
  data,
  index,
  listSubjects,
  listTeachingInformation,
  onChangeSelect,
  onRemove,
}) => {
  const [dataSubjects, setStateDataSubject] = useState([]);
  useEffect(() => {
    const result = onResultSubjects(data.valueClass, listTeachingInformation);
    setStateDataSubject(result);
  }, [data, listTeachingInformation]);

  return (
    <div className="col-12">
      {index > 0 && (
        <i
          onClick={() => onRemove(index, data)}
          style={{
            position: "absolute",
            top: "-5px",
            right: "15px",
            width: "25px",
            height: "25px",
          }}
          className="fa fa-trash-o cursor rounded monkey-fz-20 monkey-bg-red monkey-color-white d-flex justify-content-center align-items-center"
          aria-hidden="true"
        ></i>
      )}

      <div className="form-group">
        <LabelInput className="monkey-color-violet" value="Lớp" status={true} />
        <Controller
          render={({ name, onChange }) => (
            <Select
              name={name}
              value={data.valueClass}
              options={listClass}
              menuPlacement={index > 0 ? "top" : "bottom"}
              maxMenuHeight={180}
              styles={customStyles}
              placeholder="Lớp..."
              onChange={(e) => {
                onChange(e);
                onChangeSelect(e, 1, data.id, listClass);
              }}
            />
          )}
          required={required}
          name={nameClass}
          defaultValue=""
          control={control}
        />
      </div>
      <div className="form-group">
        <LabelInput className="monkey-color-violet" value="Môn" status={true} />
        <Controller
          render={({ name, onChange }) => (
            <Select
              name={name}
              value={data.valueSubjects}
              options={dataSubjects}
              menuPlacement={index > 0 ? "top" : "bottom"}
              maxMenuHeight={180}
              styles={customStyles}
              placeholder="Môn..."
              isMulti
              onChange={(e) => {
                onChange(e);
                onChangeSelect(e, 2, data.id);
              }}
            />
          )}
          required={required}
          name={nameSubjects}
          defaultValue=""
          control={control}
        />
      </div>
    </div>
  );
};

export default ControllerTeachingInformation;

function onResultSubjects(dataClass, data) {
  const result = data.filter((item) => item?.id === dataClass?.id)[0];
  return result?.listSubjects;
}
