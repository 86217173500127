import React from "react";

export default function LogInComponent() {
  const urlImg = `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/web_site`;
  return (
    <>
      <h4>Hướng dẫn đăng nhập tài khoản</h4>
      <ul class="guide-list">
        <li class="flex-lc">
          Để đăng nhập tài khoản Hoc10 thầy cô có thể thực hiện theo 1 trong 3
          cách sau:
        </li>
        <li class="flex-lc">
          <b>Cách 1:</b>&nbsp; Đăng nhập bằng số điện thoại/email đã đăng ký
          <p>
            Thầy cô truy cập đường link&nbsp;
            <a
              href="https://hoc10.vn/"
              target="_blank"
              style={{ flex: "none" }}
            >
              https://hoc10.vn/
            </a>
            &nbsp;và bấm “Đăng nhập”. Thầy cô nhập đầy đủ số điện thoại/email đã
            đăng ký tài khoản và mật khẩu sau đó bấm vào nút “Đăng nhập”
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/sign_in_away1.png`}
              alt="Cách 1: Đăng nhập bằng số điện thoại/email đã đăng ký"
            />
          </div>
        </li>
        <li class="flex-lc">
          <b>Cách 2:</b>&nbsp; Đăng nhập bằng Facebook
          <p>
            Thầy cô kéo con trỏ chuột xuống dưới, bấm chọn đăng nhập bằng
            Facebook và thao tác theo hướng dẫn trên màn hình
          </p>
        </li>
        <li class="flex-lc">
          <b>Cách 3:</b>&nbsp; Đăng nhập bằng Google
          <p>
            Thầy cô bấm chọn đăng nhập bằng Google, sau khi truy cập, hệ thống
            sẽ hiển thị popup “Đăng nhập thành công” và thầy cô có thể truy cập
            vào “Tủ sách”.
          </p>
          <div className="mt-3">
            <img
              src={`${urlImg}/sign_in_away3.png`}
              alt="Cách 3: Đăng nhập bằng Google"
            />
          </div>
        </li>
        <li class="flex-lc">
          Trường hợp cần hỗ trợ thêm, thầy cô vui lòng liên hệ tổng đài 024 7309
          8866 để được hỗ trợ nhé.
        </li>
        <li class="flex-lc">Chúc thầy cô đăng nhập và sử dụng thành công!</li>
      </ul>
    </>
  );
}
