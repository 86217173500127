import _ from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import styles from "../BlogStyle.module.scss";
import Pagination from "edu_lms_v2/components/Pagination";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import { timeUpdate } from "../selection";
import renderHTML from "react-render-html";
import Filter from "./Filter";

export default function PostDetailOther({
  listOtherKnowledges,
  currentPage,
  setCurrentPage,
  optionOther,
  setOptionOther,
  totalPage,
}) {
  const history = useHistory();
  const handleOnChangePage = (data) => {
    setCurrentPage(data);
    document.getElementById("item-post").scrollIntoView();
    history.push(`${history.location.pathname}#${data}`);
  };
  if (_.isEmpty(listOtherKnowledges.data)) {
    return (
      <div className="text-center pb-5 pt-5 monkey-fz-24 monkey-color-yellow">
        Không có bài viết nào
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="row" id="item-post">
          <Filter optionOther={optionOther} setOptionOther={setOptionOther} />
          {listOtherKnowledges?.data.map((dataPost, index) => {
            const routerPageDetail = `${PATH.ROUTE_BLOG_KNOWLEDGE_OTHER}${dataPost.alias}/`;
            return (
              <div
                key={index}
                className={`col-lg-4 col-sm-6 col-md-6 ${styles.newPostItem}`}
              >
                <div className={styles.newPostDetail}>
                  <Link to={routerPageDetail} className="post__thumb">
                    <div className={styles.img}>
                      <img src={dataPost?.images_cv} alt="images-new-post" />
                    </div>
                  </Link>
                  <div className={styles.inforMation}>
                    <Div className={styles.inforGrade}>
                      {dataPost.subject.name && (
                        <span className={styles.subject}>
                          {dataPost.subject.name}
                        </span>
                      )}
                    </Div>
                    <Link to={routerPageDetail} className="post__thumb">
                      <p className={styles.titleContent}>
                        {dataPost?.title || ""}
                      </p>
                      <p className={styles.description}>
                        {renderHTML(dataPost?.description_cv || "")}
                      </p>
                    </Link>

                    <div className={styles.timeCreate}>
                      <span className="d-flex">
                        <img
                          className={styles.newPostIcon}
                          src="/assets/img/icon-color.svg"
                          alt="icon-color"
                        />
                        <span>{timeUpdate(dataPost?.publish_date)}</span>
                        <span className="d-flex pl-5">
                          <img
                            className={styles.newPostIcon}
                            src="/assets/img/icon-viewers.svg"
                            alt="icon-viewers"
                          />
                          {dataPost?.viewcount || ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.pagination}>
        {listOtherKnowledges.total > totalPage && (
          <Pagination
            total={listOtherKnowledges.total}
            totalPerPage={totalPage}
            setCurrentPage={handleOnChangePage}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
}

const Div = styled.div`
  padding-top: 30px;
`;
