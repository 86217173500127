import renderHTML from "react-render-html";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styles from "../BlogStyle.module.scss";
import { timeUpdate } from "../selection";

export default function LatestNews({ data }) {
  const routerDetailItem = `${PATH.ROUTE_BLOG_KNOWLEDGE}${data?.level?.slug}/${data?.grade?.slug}/${data?.subject?.slug}/${data?.alias}/`;
  if (!data) {
    return <div className="pb-5" />;
  }
  return (
    <div className={`onepercent-value ${styles.latestNewsContainer}`}>
      <div className="container">
        <div className={`row ${styles.latestNewsContent}`}>
          <div className={`${styles.inforMation} col-lg-5 col-md-5 col-12`}>
            <Link
              to={`${PATH.ROUTE_BLOG_KNOWLEDGE}${data?.level?.slug}/${data?.grade?.slug}/`}
              className={styles.class}
            >
              {data?.grade?.name || ""}
            </Link>
            <span className={styles.subject}>{data?.subject?.name || ""}</span>
            <Link to={routerDetailItem} className="post__thumb">
              <h2 className={styles.LatestNewsTitle}>{data?.title || ""}</h2>
              <p className={styles.description}>
                {renderHTML(data?.description || "")}
              </p>
            </Link>
            <div className={styles.timeCreate}>
              <span className="d-flex">
                <img
                  className={styles.newPostIcon}
                  src="/assets/img/icon-color.svg"
                  alt="icon-color"
                />
                {timeUpdate(data?.publish_date)}
                <span className="d-flex pl-5">
                  <img
                    className={styles.newPostIcon}
                    src="/assets/img/icon-viewers.svg"
                    alt="icon-viewers"
                  />
                  {data?.viewcount || ""}
                </span>
              </span>
            </div>
          </div>
          <Div className="col-lg-7 col-md-7">
            <Link to={routerDetailItem} className="post__thumb">
              <div className={`flex-center justify-content-end  ${styles.img}`}>
                <img
                  src={data?.images_cv}
                  alt="images-new-post"
                  className="img-thumb"
                />
              </div>
            </Link>
          </Div>
        </div>
      </div>
    </div>
  );
}
const Div = styled.div`
  max-height: 380px;
  display: flex;
  justify-content: center;
  .img-thumb {
    max-height: 380px;
    border-radius: 8px;
    height: 100%;
  }
`;
