import * as types from "../../constants/actionsType";

export const onDispatchListQuestion = (data) => {
  return {
    type: types.PA_DATA_LIST_QUESTION,
    data,
  };
};

export const onDispatchConfigGame = (data) => {
  return {
    type: types.PA_DATA_CONFIG_GAME,
    data,
  };
};

export const onDispatchIndexQuestion = (data) => {
  return {
    type: types.PA_INDEX_QUESTION,
    data,
  };
};

export const onDispatchDataAlert = (data) => {
  return {
    type: types.PA_DATA_ALERT,
    data,
  };
};

export const onDispatchIsButtonNextQuestion = (data) => {
  return {
    type: types.PA_IS_BUTTON_NEXT_QUESTION,
    data,
  };
};
export const onDispatchTitleQuestion = (title) => {
  return {
    type: types.PA_TITLE_QUESTION,
    title,
  };
};
export const onDispatchIsClickStart = (data) => {
  return {
    type: types.PA_IS_CLICK_START,
    data,
  };
};
