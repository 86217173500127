import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { fabric } from "fabric";
import styled from "styled-components";
import reactImageSize from "react-image-size";
import { onDispatchSrcAudio } from "edu_lms/modules/App/actions";
import { Image, Audio, AudioType } from "../../components";
import { ANSWER_COLOR } from "../../constants/styles";
import { getMatchCoordinatesPosition, checkResultAnswer, isFocusTouchArea } from "../../helpers/MAT_BG";
import { calculateImageDimensionToView } from "../../helpers/MTC_BG";
import {
  AUDIO_ERROR,
  AUDIO_SUCCESS,
  URL_IMAGE_QUESTION,
} from "edu_lms/constants/type";
import { TYPE_GAME_MAT_BG } from "../../constants/MAT_BG";

const DEFAULT_LINE_COLOR = "blue";
const DEFAULT_MATCH_POINT = { id: null, x: null, y: null };

const MAT_BG = (
  {
    gameData,
    hideResultAnswer = false,
    selectedAnswersProp = [],
    showCorrectAnswer = false,
    isReadOnly = false,
    onPlaying = () => {},
    onComplete = () => {},
  },
  ref
) => {
  const {
    listAudioAnswer,
    correctAnswers,
    backgroundImage,
    ignoreLine,
    positionLineStart,
    touchAreas,
    typeGame
  } = gameData;
  const ignoreLineGroups = ignoreLine.map(groups => groups.split(","));

  const canvasId = `match-background-canvas-${Math.random()}`;
  const dispatch = useDispatch();

  const [imageDimension, setImageDimension] = useState({ width: 0, height: 0 });
  const [canvas, setCanvas] = useState(null);

  const matchedPtBeforeGrIndex = useRef(null);
  const [matchPointBefore, setMatchPointBefore] = useState(DEFAULT_MATCH_POINT);
  const [matchPointAfter, setMatchPointAfter] = useState(DEFAULT_MATCH_POINT);

  const [matchedCoupleAreas, setMatchedCoupleAreas] = useState([]);

  const isCheckedAnswerRef = useRef(false);
  const [isViewOnly, setIsViewOnly] = useState(false);

  useEffect(() => {
    drawCanvasBackground();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageDimension.width, imageDimension.height, backgroundImage.src]);

  const drawCanvasBackground = () => {
    if (imageDimension.width === 0 || imageDimension.height === 0) return;
    const newCanvas = new fabric.Canvas(canvasId, {
      width: imageDimension.width,
      height: imageDimension.height,
    });
    newCanvas.renderAll();
    setCanvas(newCanvas);
  }

  const drawCanvasTouchArea = () => {
    touchAreas.forEach((area) => {
      const { touchVectors } = area;
      const touchVectorsPolygon = touchVectors.map((vector) => {
        return {
          ...vector,
          x: vector.x * scaleImage,
          y: vector.y * scaleImage,
        };
      });

      const positionOrder = area.name.replace("object-", "");
      const polygon = new fabric.Polygon(touchVectorsPolygon, {
        strokeWidth: 1.5,
        hasBorders: true,
        selectionRadius: 20,
        stroke: ANSWER_COLOR.NONE,
        fill: ANSWER_COLOR.NONE,
        selectable: false,
        hoverCursor: "pointer",
        id: positionOrder,
      });
      canvas.add(polygon);
      canvas.sendToBack(polygon);
    });
    canvas.renderAll();

    canvas.on("mouse:over", (e) => onMouseOver(e, canvas));

    canvas.on("mouse:out", (e) => onMouseOut(e, canvas));

    canvas.on("mouse:down", (e) => onMouseDown(e));
  };

  useEffect(() => {
    if(!canvas) return;
    drawCanvasTouchArea();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas])

  const onMouseOver = (e, canvas) => {
    if (!e.target) return;
    e.target.set("fill", "rgba(102, 217, 255, 0.2)");
    canvas.renderAll();
  };

  const onMouseOut = (e, canvas) => {
    if (!e.target) return;
    e.target.set("fill", "transparent");
    canvas.renderAll();
  };

  const onMouseDown = (e) => {
    if (!(e.target && e.button === 1)) return;

    if (isCheckedAnswerRef.current) {
      handleResetAnswer();
    }

    const positionOrder = e.target.get("id");
    if (!isFocusTouchArea(positionOrder, touchAreas)) return;

    const { offsetX, offsetY } = getMatchCoordinatesPosition(positionOrder, positionLineStart, touchAreas);
    const matchPoint = { id: positionOrder, x: offsetX * scaleImage, y: offsetY * scaleImage };

    const indexIgnoreLineGroup = ignoreLineGroups.findIndex(group => group.includes(positionOrder));

    if (matchedPtBeforeGrIndex.current === null) {
      matchedPtBeforeGrIndex.current = indexIgnoreLineGroup;
      setMatchPointBefore(matchPoint);
    } else {
      matchedPtBeforeGrIndex.current = null;
      setMatchPointAfter(matchPoint);
    }
  }

  const defaultMatchPoint = () => {
    setMatchPointBefore(DEFAULT_MATCH_POINT);
    setMatchPointAfter(DEFAULT_MATCH_POINT);
  }

  useEffect(() => {
    drawLine();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchPointBefore, matchPointAfter]);

  const removeLineById = (id) => {
    const lineObjects = canvas.getObjects("line");
    lineObjects.forEach((item) => {
      if (item.id === id) {
        canvas.remove(item);
      }
    });
  };

  const drawLine = () => {
    if (!matchPointBefore.id || !matchPointAfter.id) return;

    const getLineId = (point1Id, point2Id) => `${Math.min(point1Id, point2Id)},${Math.max(point1Id, point2Id)}`

    const lineId = getLineId(+matchPointBefore.id, +matchPointAfter.id)

    const isDuplicatedPoint = (point1, point2) => point1.id === point2.id

    const isDuplicatedLine = (existLine, newLine) => {
      const existLinePoint1 = existLine.before;
      const existLinePoint2 = existLine.after;
      const newLinePoint1 = newLine.before;
      const newLinePoint2 = newLine.after;

      return (
        (isDuplicatedPoint(newLinePoint1, existLinePoint1) && isDuplicatedPoint(newLinePoint2, existLinePoint2)) ||
        (isDuplicatedPoint(newLinePoint1, existLinePoint2) && isDuplicatedPoint(newLinePoint2, existLinePoint1))
      );
    }

    const newLine = { before: matchPointBefore, after: matchPointAfter }

    if (matchedCoupleAreas.some(existLine => isDuplicatedLine(existLine, newLine))) {
      const filterMatchedCouples = matchedCoupleAreas.filter(existLine => !isDuplicatedLine(existLine, newLine)) 
      let allLine = canvas.getObjects("line");
      allLine.forEach((item) => {
        if(item.id === lineId){
          canvas.remove(item);
        }
      })
      canvas.renderAll();
      setMatchedCoupleAreas(filterMatchedCouples)
      defaultMatchPoint();
      return;
    }

    const indexIgnoreLineGroupForPointBefore = ignoreLineGroups.findIndex(group => group.includes(matchPointBefore.id));
    const indexIgnoreLineGroupForPontAfter = ignoreLineGroups.findIndex(group => group.includes(matchPointAfter.id));
    if (indexIgnoreLineGroupForPointBefore === indexIgnoreLineGroupForPontAfter) {
      defaultMatchPoint();
      return;
    }

    const newMatchedCoupleAreas = [...matchedCoupleAreas];
    if (typeGame === TYPE_GAME_MAT_BG.OneToOne) {
      // Remove same click before area and update matched couple areas
      let indexBeforeSameClick = null;
      do {
        indexBeforeSameClick = newMatchedCoupleAreas.findIndex(couple => (couple.before.id === matchPointBefore.id || couple.before.id === matchPointAfter.id));
        if (indexBeforeSameClick > -1) {
          const areaSameClick = newMatchedCoupleAreas[indexBeforeSameClick];
          removeLineById(`${areaSameClick.before.id},${areaSameClick.after.id}`);
          newMatchedCoupleAreas.splice(indexBeforeSameClick, 1);
        } else {
          indexBeforeSameClick = null
        }
      } while (indexBeforeSameClick !== null)
      
      // Remove same click after area and update matched couple areas
      let indexAfterSameClick = null;
      do {
        indexAfterSameClick = newMatchedCoupleAreas.findIndex(couple => (couple.after.id === matchPointAfter.id || couple.after.id === matchPointBefore.id));
        if (indexAfterSameClick > -1) {
          const areaSameClick = newMatchedCoupleAreas[indexAfterSameClick];
          removeLineById(`${areaSameClick.before.id},${areaSameClick.after.id}`);
          newMatchedCoupleAreas.splice(indexAfterSameClick, 1);
        } else {
          indexAfterSameClick = null;
        }
      } while (indexAfterSameClick !== null)
      
    }
    newMatchedCoupleAreas.push({ before: matchPointBefore, after: matchPointAfter });
    setMatchedCoupleAreas(newMatchedCoupleAreas);

    const lineCoords = [matchPointBefore.x, matchPointBefore.y, matchPointAfter.x, matchPointAfter.y];
    createLineCanvas(lineCoords, DEFAULT_LINE_COLOR, lineId);

    canvas.renderAll();
    defaultMatchPoint();

    onPlaying(false);
  }

  useImperativeHandle(ref, () => ({
    handleCheck: handleCheckAnswer,
    handleReset: handleResetAnswer,
    handleOnlyView,
  }));

  /*------------------------- Show selected answer result -------------------------*/
  useEffect(() => {
    if (canvas && selectedAnswersProp.length > 0) {
      drawLineMatchedAreas(selectedAnswersProp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, selectedAnswersProp]);

  /*------------------------- Show all correct answer result -------------------------*/
  useEffect(() => {
    if (canvas && showCorrectAnswer) {
      const answerCorrectAreas = correctAnswers.map(answer => {
        const areas = answer.split("_");
        const beforeOffset = getMatchCoordinatesPosition(areas[0], positionLineStart, touchAreas);
        const afterOffset = getMatchCoordinatesPosition(areas[1], positionLineStart, touchAreas);

        return {
          isCorrect: true,
          before: {
            id: areas[0],
            x: beforeOffset.offsetX * scaleImage,
            y: beforeOffset.offsetY * scaleImage
          },
          after: {
            id: areas[1],
            x: afterOffset.offsetX * scaleImage,
            y: afterOffset.offsetY * scaleImage
          }
        }
      })
      drawLineMatchedAreas(answerCorrectAreas);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, showCorrectAnswer]);

  const handleCheckAnswer = () => {
    isCheckedAnswerRef.current = true;

    const { isCorrect, numberOfCorrectMatched, matchedCoupleAreasResult } = checkResultAnswer(correctAnswers, matchedCoupleAreas);
    drawLineMatchedAreas(matchedCoupleAreasResult);
    !hideResultAnswer && dispatch(onDispatchSrcAudio(isCorrect ? AUDIO_SUCCESS : AUDIO_ERROR));

    onComplete({ isCorrect, selectedAnswers: matchedCoupleAreasResult, numberOfCorrectMatched });
  };

  const removeAllLineCanvasObjects = () => {
    if (!canvas) return;
    const lineCanvasObjects = canvas.getObjects("line");
    lineCanvasObjects.forEach((item) => {
      canvas.remove(item);
    });
  }

  const createLineCanvas = (lineCoords = [], stroke = "", lineId = null) => {
    if (!canvas) return;
    const line = new fabric.Line(lineCoords, {
      stroke: stroke,
      strokeWidth: 2.5,
      opacity: 0.8,
      hasControls: false,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: "default",
      id: lineId,
    });
    canvas.add(line);
    canvas.sendToBack(line);
  }

  const drawLineMatchedAreas = (matchedCoupleAreasResult) => {
    removeAllLineCanvasObjects();

    matchedCoupleAreasResult.forEach(matchedCouple => {
      const lineCoords = [matchedCouple.before.x, matchedCouple.before.y, matchedCouple.after.x, matchedCouple.after.y];
      let strokeColor = DEFAULT_LINE_COLOR
      if (!hideResultAnswer) {
        strokeColor = matchedCouple.isCorrect ? ANSWER_COLOR.CORRECT : ANSWER_COLOR.WRONG;
      }
      createLineCanvas(lineCoords, strokeColor);
    })
  }

  const handleResetAnswer = () => {
    removeAllLineCanvasObjects();
    isCheckedAnswerRef.current = false;
    defaultMatchPoint();
    setMatchedCoupleAreas([]);
    setIsViewOnly(false);
  };

  const handleOnlyView = () => {
    setIsViewOnly(true);
  };

  const handleLoadImageBackground = async () => {
    const { width: realWidth, height: realHeight } = await reactImageSize(`${URL_IMAGE_QUESTION}${backgroundImage.src}`);
    const { width, height } = calculateImageDimensionToView(realWidth, realHeight);
    setImageDimension({ width, height });
  };

  const scaleImage = imageDimension.width / backgroundImage.width;

  return (
    <PlayArea>
      <MatchBackGroundContainerWrapper isView={isReadOnly || isViewOnly}>
        {listAudioAnswer.map((audio, index) => {
          return (
            <div
              key={index}
              style={{
                position: "absolute",
                zIndex: 15,
                left: audio.left * scaleImage - 30, // magic number 30 ???
                top: audio.top * scaleImage - 20, // magic number 20 ???
              }}
            >
              <Audio variant={AudioType.Primary} src={audio.url} />
            </div>
          );
        })}
        <Image
          src={backgroundImage.src}
          width={imageDimension.width}
          height={imageDimension.height}
          style={{ minWidth: imageDimension.width }}
          alt="MAT-background-image"
          onLoad={handleLoadImageBackground}
        />
        <canvas id={canvasId} />
      </MatchBackGroundContainerWrapper>
    </PlayArea>
  );
};

export default forwardRef(MAT_BG);

const MatchBackGroundContainerWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 100%;
  margin: auto;
  pointer-events: ${props => props.isView ? 'none' : 'initial'};

  .canvas-container {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 11;
  }
`;

const PlayArea = styled.div`
  overflow: auto;
`;
