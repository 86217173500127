import { Link } from "react-router-dom";
import * as PATH from "consts/routePaths";
import styled from "styled-components";
import thumb_blog from "edu_lms_v2/assets/img/Group.svg";

export default function Header({
  isSchoolLevel,
  dataCateByParent,
  contentHeader,
}) {
  return (
    <HeaderKnowledge>
      <div className="container p-0">
        <div className="heading">
          <ul className={`breadcrumb p-0`}>
            <li className="breadcrumb-item">
              <Link to={PATH.ROUTE_PATH_V2_HOME}>Trang chủ</Link>
            </li>
            {isSchoolLevel ? (
              <>
                <li className="breadcrumb-item active" aria-current="page">
                  <Link to={PATH.ROUTE_BLOG_KNOWLEDGE}>Kiến thức</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {dataCateByParent?.all_cate[isSchoolLevel]?.name}
                </li>
              </>
            ) : (
              <li className="breadcrumb-item active" aria-current="page">
                Kiến thức
              </li>
            )}
          </ul>
          <div className="header-container position-relative">
            <div className="container-title">
              <div className="header-title">
                <h1 className="position-relative">
                  {contentHeader.title.titleName}{" "}
                  <span>{contentHeader.title.titleStyles}</span>
                </h1>
              </div>
              <div className="header-content">
                <p>{contentHeader.content || ""}</p>
              </div>
            </div>
            <div className="header-img">
              <img src={thumb_blog} className="fit" alt="img-banner-blog" />
            </div>
          </div>
        </div>
      </div>
    </HeaderKnowledge>
  );
}

const HeaderKnowledge = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  background: url("/assets/img/bg-slider.svg");
  width: 100%;
  .container {
    max-width: 640px;
  }
  .heading {
    padding-top: 15px;
  }
  .header-title {
    h1 {
      padding-top: 25px;
      font-size: 36px;
      line-height: 48px;
      font-family: SVN-GilroyBold, sans-serif;
      color: #2a404f;
      span {
        color: #ff7707;
        background: url("../../../assets/img/Group-4.png") no-repeat 0 0 /
          contain !important;
        width: 103px;
        height: 50px;
        display: inline-block;
        margin: 2px 0 0 -15px;
        text-align: center;
      }
    }
  }
  .header-content {
    padding-top: 30px;
  }
  .header-img {
    padding-top: 25px;
    img {
      height: 374px;
      width: auto;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 576px) {
    padding-bottom: 30px;
    .container {
      max-width: 375px;
    }
    .header-title {
      h1 {
        font-size: 32px;
        span {
          width: 114px;
          bottom: 0;
          margin: 0 0 0 -10px;
        }
      }
    }
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    padding-bottom: 30px;
    .container {
      max-width: 600px;
    }
    .header-title {
      h1 span {
        width: 114px;
        margin: 0px 0 0 -7px;
        bottom: 0;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
    .container {
      max-width: 768px;
    }
    .header-title {
      h1 {
        font-size: 46px;
        padding-top: 60px;
        line-height: 64px;
        font-weight: 700;
        span {
          width: 160px;
          height: 70px;
          line-height: 64px;
        }
      }
    }
    .header-container {
      display: grid;
      grid-template-columns: auto auto;
    }
    .container-title {
      width: 70%;
    }
    .header-img {
      position: absolute;
      top: 0;
      right: 0;
      img {
        height: 300px;
        width: auto;
      }
    }
    .header-content {
      padding-top: 35px;
      width: 90%;
      line-height: 28px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .heading {
      padding-left: 35px;
      padding-right: 35px;
    }
    .container-title {
      width: 50%;
    }
    .container {
      max-width: 960px;
    }
    .header-content {
      width: 100%;
    }
    .header-img {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 0;
      img {
        height: 310px;
        width: auto;
        padding-top: 0;
      }
    }
  }
  @media (min-width: 1200px) {
    max-width: 1296px;
    margin: 0 auto;
    border-radius: 0px 0px 24px 24px;
    .container {
      max-width: 1296px;
    }
    .heading {
      padding-left: 90px;
      padding-right: 90px;
    }
    .header-title h1 {
      padding-top: 120px;
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      max-width: 550px;
      span {
        width: 165px;
        height: 73px;
        line-height: 73px;
      }
    }
    .header-content {
      padding-top: 50px;
      padding-bottom: 150px;
      max-width: 409px;
      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
    .header-img {
      padding-top: 0;
      position: absolute;
      right: 90px;
      top: 0;
      img {
        height: 495px;
        width: auto;
        padding-top: 0;
      }
    }
  }
`;
