import React, { useState, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { getSchoolLevelId } from "edu_lms_v2/modules/ControlDoingExercise/constant";
import { getListSubject } from "edu_lms/services/app/app";

export default function TeachingInfoField({
  canRemove,
  index,
  control,
  remove,
  grades,
  setValue,
  gradeValue,
  subjectValue,
}) {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    getSubject(gradeValue?.value);
  }, []);

  const getSubject = (gradeId) => {
    const schoolId = getSchoolLevelId(gradeId);
    setSubjects([]);

    getListSubject(schoolId, '80,81,82,83')
      .then((res) => {
        if (res.data.status === "success") {
          if (res.data?.data?.list_subject.length > 0) {
            setSubjects(
              res.data?.data?.list_subject.map((item) => ({
                value: item.id,
                label: item.title,
              }))
            );
          }
        }
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  const NoOptionsMessage = () => {
    return (
      <div className="text-center pt-2 pb-2 monkey-fz-16">
        Vui lòng chọn lớp học trước khi chọn môn học
      </div>
    );
  };

  return (
    <div>
      <div className="form-group">
        <label>Lớp học</label>
        <div className="box-select box-select--class pr">
          <Controller
            render={({ field, onChange, value }) => (
              <Select
                {...field}
                placeholder="Vui lòng chọn lớp"
                options={grades}
                maxMenuHeight={200}
                value={value}
                onChange={(e) => {
                  setValue(`teaching.${index}.subjects`, []);
                  onChange(e);
                  getSubject(e.value);
                }}
              />
            )}
            name={`teaching.${index}.grade`}
            control={control}
            defaultValue={gradeValue}
          />
        </div>
      </div>
      <div className="form-group">
        <label>Môn học</label>
        <div className="box-select box-select--subject pr">
          <Controller
            render={({ field, onChange, value }) => (
              <Select
                {...field}
                placeholder="Vui lòng chọn môn học"
                components={{ NoOptionsMessage }}
                options={subjects}
                maxMenuHeight={200}
                onChange={onChange}
                value={value}
                isMulti
              />
            )}
            name={`teaching.${index}.subjects`}
            control={control}
            defaultValue={subjectValue}
          />
        </div>
      </div>
      {canRemove && (
        <>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => remove(index)}>
              <IStyle className="fa fa-trash-o" />
            </button>
          </div>
          <HrStyle />
        </>
      )}
    </div>
  );
}

const IStyle = styled.i`
  font-size: 20px;
  color: #ff7707;
  margin-bottom: 5px;
`;
const HrStyle = styled.hr`
  background-color: #ff7707;
`;
