const INPUT_CHARS_REPLACE = "aaa";

const SPLIT_CHARS_ANSWER = "##";

const SPLIT_ANSWER_RESULT = "|";

const IMAGE_WIDTH = {
  WebView: 1106,
  CMSView: 1106,
};

export {
  INPUT_CHARS_REPLACE,
  SPLIT_CHARS_ANSWER,
  SPLIT_ANSWER_RESULT,
  IMAGE_WIDTH,
};
