import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import {
  postUpdateUserInformation,
  postUpdateUserEmailPhone,
} from "edu_lms/services/info/info";
import { APP_ID } from "edu_lms/constants/type";
import { onResultUserInfo, setItemUserInfo } from "edu_lms/modules/selection";
import { onDispatchDataInfo } from "edu_lms/modules/SignIn/actions";
import * as TYPE from "edu_lms/constants/type";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { UPDATE_USER_INFORMATION } from "edu_lms/constants/eventNameGTM";
import { useState } from "react";

const phoneRegExp = /([+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/;
const schema = yup
  .object()
  .shape({
    full_name: yup.string().required("Vui lòng nhập đầy đủ họ tên"),
    birth_day: yup.string().required("Vui lòng nhập ngày sinh"),
    gender_id: yup.object().required("Vui lòng nhập giới tính"),
    job_id: yup.object().required("Vui lòng chọn chức vụ"),
    email: yup.string().optional().nullable(),
    phone: yup
      .string()
      .required("Vui lòng nhập số điện thoại")
      .matches(phoneRegExp, "SĐT không hợp lệ"),
  })
  .required();

const Form = styled.form`
  & input {
    height: 36px;
  }
`;
const today = new Date();

export default function PersonalInformation(props) {
  const dispatch = useDispatch();
  const data = props.dataUser;
  const typeUpdate = props.dataUser?.birth_day ? "update" : "add";
  const dataUserInfo = onResultUserInfo();
  const verifiedEmail =
    dataUserInfo?.email_verified === TYPE.EMAIL_VERIFIED ||
    data?.email_verified === TYPE.EMAIL_VERIFIED;

  const { register, handleSubmit, setValue, errors, control, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: dataUserInfo?.email,
      phone: dataUserInfo?.phone,
    },
  });

  const formattedToday = today.toISOString().split('T')[0];

  useEffect(() => {
    const newDate = dayjs(data.birth_day * 1000).format("YYYY-MM-DD");
    if (data.full_name) setValue("full_name", data.full_name);
    if (data.birth_day) setValue("birth_day", newDate);
    if (data.gender_id)
      setValue("gender_id", getValueOption(TYPE.GENDER, data.gender_id));
    if (data.job_id)
      setValue("job_id", getValueOption(TYPE.TYPE_LIST_JOB, data.job_id));
  }, [data]);

  const getValueOption = (list, id) => {
    const valueOption = list.filter((item) => item.id === id)[0];
    return valueOption;
  };

  const onHandleSubmit = async (dataForm) => {
    try {
      const userInfo = {
        ...dataForm,
        birth_day: (Date.parse(dataForm.birth_day) / 1000).toString(),
        gender_id: dataForm.gender_id.value,
        job_id: dataForm.job_id.id,
        full_name: dataForm.full_name,
      };
      const contactInfo = {
        email: verifiedEmail
          ? dataUserInfo.email.trim()
          : dataForm.email.trim(),
        phone: dataForm.phone,
        is_web: 1,
        app_id: APP_ID,
      };
      await postUpdateUserInformation(userInfo);
      const res = await postUpdateUserEmailPhone(contactInfo);

      let updateSuccessful = false;

      if (res.data.status === "success") {
        dispatch(onDispatchDataInfo({ full_name: dataForm.full_name }));
        toast.success("Cập nhật thông tin thành công!");
        const newDataUserInfo = {
          ...dataUserInfo,
          ...userInfo,
          ...contactInfo,
        };
        setItemUserInfo(newDataUserInfo);
        updateSuccessful = true;
      } else {
        toast.error(res.data.message);
      }
      setEventGTM({
        event: UPDATE_USER_INFORMATION,
        type: typeUpdate,
        update_detail: "personal_info",
        successful: updateSuccessful,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onReset = () => {
    const newDate = dayjs(dataUserInfo?.birth_day * 1000).format("YYYY-MM-DD");
    reset({
      full_name: dataUserInfo?.full_name,
      birth_day: newDate,
      gender_id: getValueOption(TYPE.GENDER, dataUserInfo?.gender_id),
      job_id: getValueOption(TYPE.TYPE_LIST_JOB, dataUserInfo?.job_id),
      email: dataUserInfo?.email,
      phone: dataUserInfo?.phone,
    });
  };

  return (
    <div id="user" className="tab-pane fade show active" role="tabpanel">
      <h2 className="title">Cá nhân</h2>
      <Form onSubmit={handleSubmit(onHandleSubmit)} className="form-h10">
        <div className="form-row">
          <div className="form-group">
            <label>
              Họ và tên <sup>*</sup>
            </label>
            <input
              type="text"
              placeholder="Nhập họ và tên"
              name="full_name"
              ref={register}
              className="input-update"
            />
            <p className="text-danger">{errors.full_name?.message}</p>
          </div>
          <div className="form-group">
            <label>
              Ngày tháng năm sinh <sup>*</sup>
            </label>
            <input
              type="date"
              max={formattedToday}
              placeholder="Nhập ngày tháng năm sinh *"
              name="birth_day"
              ref={register}
              className="input-update"
            />
            <p className="text-danger">{errors.birth_day?.message}</p>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>
              Giới tính <sup>*</sup>
            </label>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  placeholder="Vui lòng chọn giới tính"
                  options={TYPE.GENDER}
                  maxMenuHeight={200}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name="gender_id"
              control={control}
            />
            <p className="text-danger">{errors.gender_id?.message}</p>
          </div>
          <div className="form-group">
            <label>
              Chức vụ <sup>*</sup>
            </label>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  placeholder="Vui lòng chọn vị trí"
                  options={TYPE.TYPE_LIST_JOB}
                  maxMenuHeight={200}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              )}
              name="job_id"
              control={control}
            />
            <p className="text-danger">{errors.job_id?.message}</p>
          </div>
        </div>
        <div className="form-row">
          <WrapperInputEmail
            className={classNames("form-group", {
              pointer: verifiedEmail,
            })}
          >
            <label>
              Email{" "}
              {dataUserInfo?.email && verifiedEmail && (
                <span className="monkey-color-verified ml-2">
                  (Đã xác thực)
                </span>
              )}
            </label>
            <input
              type="email"
              placeholder="Nhập email"
              name="email"
              ref={register}
              className="input-update"
            />
            <p className="text-danger">{errors.email?.message}</p>
          </WrapperInputEmail>
          <div className="form-group">
            <label>
              Số điện thoại <sup>*</sup>
            </label>
            <input
              type="tel"
              placeholder="Nhập số điện thoại"
              name="phone"
              ref={register}
              className="input-update"
            />
            <p className="text-danger">{errors.phone?.message}</p>
          </div>
        </div>
        <div className="box-btn box-btn-fw flex mt-14">
          <button
            type="button"
            className="btn-reset flex-center txt-4"
            onClick={() => onReset()}
          >
            Hủy
          </button>
          <button type="submit" className="btn-submit flex-center btn-pr">
            Gửi
          </button>
        </div>
      </Form>
    </div>
  );
}

const WrapperInputEmail = styled.div`
  &.pointer {
    pointer-events: none;
  }
`;
