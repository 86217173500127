import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { getReportEvent } from "edu_lms/services/event";
import Loading from "edu_lms_v2/components/Loading";
import dayjs from "edu_lms/libs/dayjs";
import styles from "./DashBoardEvent.module.scss";
import calendarIcon from "edu_lms_v2/assets/img/calendar.svg";
import { toast } from "react-toastify";

export const InfomationFilter = (props) => {
  const { control, handleSubmit } = useForm({
    mode: "onChange",
  });
  const [dataReport, setDataReport] = useState({
	  'login': {},
	  'not_login' : {},
	  'send_mai': {}
  });
  const [loading, setLoading] = useState(false);
  
  const handleFilter = (data) => {
	let start_time = data?.time_start ? dayjs(data?.time_start).unix() : "";
	let end_time =  data?.time_end ? dayjs(data?.time_end).unix() : "";
	if (!start_time || !end_time) {
		return toast.error('Vui lòng chọn thời gian');
	}
	setLoading(true);
    getReportEvent(start_time, end_time)
      .then((res) => {
        if (res.data.status === "success") {
          setDataReport(res.data.data);
        }
      })
      .catch((error) => {
	      setDataReport([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
	
	return (
		<>
			<Form onSubmit={handleSubmit(handleFilter)}>
				<div className="col-12 col-md-12">
					<div className="row mb-2">
						<div className={`col-12 col-sm-4 mr-5 ${styles.InputDate}`}>
							<span>Ngày bắt đầu:</span>
							<div>
								<Controller
									control={control}
									name="time_start"
									render={({ onChange, value, onBlur }) => (
										<DatePicker
											className="mt-2"
											onChange={(e) => {
												onChange(e);
											}}
											selected={value}
											onBlur={onBlur}
											dateFormat="dd/MM/yyyy, h:mm aa"
											maxDate={new Date()}
											timeInputLabel="Time:"
											showTimeInput
											placeholderText="Chọn ngày"
										/>
									)}
								/>
								<img src={calendarIcon} className="calender" alt="" />
							</div>
						</div>
						
						<div className={`col-12 col-sm-4 ${styles.InputDate}`}>
							<span>Ngày kết thúc:</span>
							<div>
								<Controller
									control={control}
									name="time_end"
									render={({ onChange, value, onBlur }) => (
										<DatePicker
											className="mt-2"
											onChange={(e) => {
												onChange(e);
											}}
											selected={value}
											onBlur={onBlur}
											showTimeSelect
											dateFormat="dd/MM/yyyy, h:mm aa"
											maxDate={new Date()}
											timeInputLabel="Time:"
											showTimeInput
											placeholderText="Chọn ngày"
										/>
									)}
								/>
								<img src={calendarIcon} className="calender" alt="" />
							</div>
						</div>
						
					</div>
				</div>
				
				<div className="col-12 col-md-12">
					<div className="row mb-3">
						<div className={`col-12 col-sm-4`}>
							<button className="btn btn-pr" type="submit">
								Tìm kiếm
							</button>
						</div>
					
					</div>
				</div>
			
			</Form>
			{!loading ? (
				<>
					<div class=" monkey-fz-20 monkey-color-black mb-2">Luồng đăng nhập website</div>
					<div className="tab-content hoc10-tab-content text-center shadow-lg p-0 mb-2 table-responsive ${styles['height-table']}">
						<table className="table table-hover">
							<thead>
							<tr>
								<th scope="col" className={`border-right border-top-0 ${styles['label-teacher']}`}>
									Số lượng tài khoản GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-teacher']}`}>
									Click vào thông báo
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Click landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Không đăng ký landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Điền form từ landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Gửi thành công email MKT GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Click email MKT GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Tổng lượt click landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									SL landing click
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Không đăng ký landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									SL landing PH/HS đăng ký
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Điền form landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Gửi TK thành công
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Mở mail TK
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Sử dụng tài khoản
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>
									{dataReport.login.total_send}
								</td>
								<td>
									{dataReport.login.click_notification}
								</td>
								<td>
									{dataReport.login.click_landing_gv}
								</td>
								<td>
									{dataReport.login.total_send ? dataReport.login.total_send - dataReport.login.register_landing_gv : ''}
								</td>
								<td>
									{dataReport.login.register_landing_gv}
								</td>
								<td>
									{dataReport.login.send_success_mail_gv}
								</td>
								<td>
									{dataReport.login.click_mail_gv}
								</td>
								<td>
									{dataReport.login.total_click_link}
								</td>
								<td>
									{dataReport.login.count_click_link}
								</td>
								<td>
									{dataReport.login.total_send ? dataReport.login.total_send - dataReport.login.count_submit_form : ''}
								</td>
								<td>
									{dataReport.login.count_submit_form}
								</td>
								<td>
									{dataReport.login.total_submit_form}
								</td>
								<td>
									{dataReport.login.count_send_account_success}
								</td>
								<td>
									{dataReport.login.count_open_account}
								</td>
								<td>
									{dataReport.login.count_account_active}
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					
					<div class="monkey-fz-20 monkey-color-black mb-2 mt-5">Luồng gửi email</div>
					<div className="tab-content hoc10-tab-content text-center shadow-lg p-0 mb-2 table-responsive ${styles['height-table']}">
						<table className="table table-hover">
							<thead>
							<tr>
								<th scope="col" className={`border-right border-top-0 ${styles['label-teacher']}`}>
									Số lượng đã gửi
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-teacher']}`}>
									Gửi thành công
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-teacher']}`}>
									Mở mail
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Click landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Không đăng ký landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Điền form từ landing GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Gửi thành công email MKT GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-sharing']}`}>
									Click email MKT GV
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Tổng Click landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									SL landing Click
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Không đăng ký landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									SL landing PH/HS đăng ký
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Điền form landing PH/HS
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Gửi TK thành công
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Mở mail TK
								</th>
								<th scope="col" className={`border-right border-top-0 ${styles['label-parent']}`}>
									Sử dụng tài khoản
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>
									{dataReport.send_mai.total_send}
								</td>
								<td>
									{dataReport.send_mai.send_success}
								</td>
								<td>
									{dataReport.send_mai.open_mail}
								</td>
								<td>
									{dataReport.send_mai.click_landing_gv}
								</td>
								<td>
									{dataReport.send_mai.total_send ? dataReport.send_mai.total_send - dataReport.send_mai.register_landing_gv : ''}
								</td>
								<td>
									{dataReport.send_mai.register_landing_gv}
								</td>
								<td>
									{dataReport.send_mai.send_success_mail_gv}
								</td>
								<td>
									{dataReport.send_mai.click_mail_gv}
								</td>
								<td>
									{dataReport.send_mai.total_click_link}
								</td>
								<td>
									{dataReport.send_mai.count_click_link}
								</td>
								<td>
									{dataReport.send_mai.total_send ? dataReport.send_mai.total_send - dataReport.send_mai.count_submit_form : ''}
								</td>
								<td>
									{dataReport.send_mai.count_submit_form}
								</td>
								<td>
									{dataReport.send_mai.total_submit_form}
								</td>
								<td>
									{dataReport.send_mai.count_send_account_success}
								</td>
								<td>
									{dataReport.send_mai.count_open_account}
								</td>
								<td>
									{dataReport.send_mai.count_account_active}
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					
					<div className="monkey-fz-20 monkey-color-black mb-2 mt-5">Luồng chưa đăng nhập</div>
					<div className="tab-content hoc10-tab-content text-center shadow-lg p-0 mb-2 table-responsive ${styles['height-table']}">
						<table className="table table-hover">
							<thead>
							<tr>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-sharing'] }` }>
									Điền form từ landing GV
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-sharing'] }` }>
									Gửi thành công email MKT GV
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-sharing'] }` }>
									Click email MKT GV
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Tổng Click landing PH/HS
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									SL landing Click
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Không đăng ký landing PH/HS
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									SL landing PH/HS đăng ký
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Điền form landing PH/HS
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Gửi TK thành công
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Mở mail TK
								</th>
								<th scope="col" className={ `border-right border-top-0 ${ styles['label-parent'] }` }>
									Sử dụng tài khoản
								</th>
							</tr>
							</thead>
							<tbody>
							<tr>
								<td>
									{ dataReport.not_login.register_landing_gv }
								</td>
								<td>
									{ dataReport.not_login.send_success_mail_gv }
								</td>
								<td>
									{ dataReport.not_login.click_mail_gv }
								</td>
								<td>
									{ dataReport.not_login.total_click_link }
								</td>
								<td>
									{ dataReport.not_login.count_click_link }
								</td>
								<td>
									{ dataReport.not_login.total_send ? dataReport.not_login.total_send - dataReport.not_login.count_submit_form : '' }
								</td>
								<td>
									{ dataReport.not_login.count_submit_form }
								</td>
								<td>
									{ dataReport.not_login.total_submit_form }
								</td>
								<td>
									{ dataReport.not_login.count_send_account_success }
								</td>
								<td>
									{ dataReport.not_login.count_open_account }
								</td>
								<td>
									{ dataReport.not_login.count_account_active }
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				
				</>
			) : (
				<Loading className="mt-2" />
			)}
		</>
);
};
