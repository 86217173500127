import { useForm } from "react-hook-form";
import { SEARCH_LECTURE } from "../../constants/eventNameGTM";
import { setEventGTM } from "../../constants/googleTagManager";

const FormSearch = (props) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    props.onSearch(data.search, props.gradeId, props.typeBook);
    setEventGTM({ event: SEARCH_LECTURE, search_key: data.search });
  };

  return (
    <form
      name="formSearch"
      className="form_search"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group mb-0 input-search">
        <input
          className="form-control rounded-pill"
          type="text"
          autoComplete="off"
          placeholder={props.placeholder || "Tên sách cần tìm"}
          name="search"
          ref={register({ required: false, maxLength: 80 })}
        />
        <i
          onClick={handleSubmit(onSubmit)}
          className="icon-search distance-center rounded-circle monkey-bg-violet icon-monkey-search cursor monkey-fz-18 monkey-color-white"
        ></i>
      </div>
    </form>
  );
};
export default FormSearch;
