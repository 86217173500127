export default function TabTitle({ listGrade }) {
  return (
    <ul className="tab nav tab-pr tab-pr--cs1">
      {listGrade?.map((grade) => (
        <li key={grade.id}>
          <a
            href={`#lop${grade.id}`}
            className={grade.id === 4 ? "active" : ""}
            data-toggle="tab"
          >
            {grade.name}
          </a>
        </li>
      ))}
    </ul>
  );
}
