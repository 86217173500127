import * as PATH from "consts/routePaths";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div class="hoc10-contactus pr">
      <div class="container">
        <Link to={PATH.ROUTE_PATH_V3_CONTACT} class="btn-contact flex-center">
          Liên hệ với chúng tôi
        </Link>
        <ul class="contact-list flex">
          <li>
            <i class="icon icon-phone"></i>
            <a href="tel:02473098866">024.73098866</a>
          </li>
          <li>
            <i class="icon icon-email"></i>
            <a href="mailto:contact@hoc10.vn">contact@hoc10.vn</a>
          </li>
          <li>
            <i class="icon icon-facebook"></i>
            <a
              href="https://www.facebook.com/Hoc10-H%E1%BB%8Dc-1-bi%E1%BA%BFt-10-106859728447188/"
              target="_blank"
              title=""
            >
              fb.com/hoc10.vn
            </a>
          </li>
          <li>
            <i class="icon icon-chat"></i>
            <Link to={PATH.ROUTE_PATH_V3_CONTACT}>hỗ trợ trực tuyến</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
