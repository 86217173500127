import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styled from "styled-components";
import * as PATH from "consts/routePaths";

export default function NavbarMobile({
  menuItems,
  toggleListItem,
  onActiveBook,
  isLoggedIn,
  totalNotiUnread,
  activeMenu,
}) {
  return (
    <ListItem className="navbar-nav" id="navbar-mobile-screen">
      {menuItems.map((menuItem, index) => {
        if (menuItem.path === PATH.ROUTE_BLOG_KNOWLEDGE) {
          return null;
        }

        if (menuItem.isActiveBook) {
          return (
            <li id="active-book-mobi-screen" key={index}>
              <a
                href="javascript:;"
                title="Kích hoạt sách"
                className="activated flex-center"
                onClick={onActiveBook}
              >
                <i className="icon icon-key"></i> Kích hoạt sách
              </a>
            </li>
          );
        }
        if ([PATH.ROUTE_PATH_V3_STUDENT_MOCK_TEST, PATH.ROUTE_PATH_COURSE].includes(menuItem.path)) {
          return (
            <li key={index}>
              <Link
                to={menuItem.path}
                title={menuItem.title}
                onClick={menuItem.eventClick || null}
                className={`position-relative ${menuItem.isActive && "active"}`}
              >
                {menuItem.title}
                {/*<img*/}
                {/*  src="/assets/img/icon-new.svg"*/}
                {/*  alt="img-new"*/}
                {/*  className="position-absolute icon-new"*/}
                {/*/>*/}
              </Link>
            </li>
          );
        }

        if (menuItem.isPrivate) {
          if (!isLoggedIn) return null;

          if (menuItem.path === PATH.ROUTE_PATH_V2_NOTIFICATION) {
            return (
              <li id="position-relative" key={index}>
                <Link to={menuItem.path} title={menuItem.title}>
                  {menuItem.title}
                  {totalNotiUnread > 0 && (
                    <span className="span-number-notification-mobile position-absolute">
                      {totalNotiUnread}
                    </span>
                  )}
                </Link>
              </li>
            );
          }

          return (
            <li key={index}>
              <Link to={menuItem.path} title={menuItem.title}>
                {menuItem.title}
              </Link>
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classNames("", {
              active: activeMenu == menuItem.mobileActive,
            })}
          >
            {menuItem.path && (
              <Link
                to={menuItem.path}
                title={menuItem.title}
                onClick={menuItem.eventClick || null}
              >
                {menuItem.title}
              </Link>
            )}
            {!menuItem.path && (
              <a href="javascript:;" title={menuItem.title}>
                {menuItem.title}
              </a>
            )}
            {menuItem.subMenu && (
              <>
                <ul className="sub-menu bg-sh">
                  {menuItem.subMenu.map((subMenu, subIdx) => {
                    if (!subMenu.show) return null;
                    const shopBooks =
                      subMenu.path === PATH.ROUTE_PUBLISHER_LIST;
                    if (shopBooks) {
                      return (
                        <li key={subIdx}>
                          <Link to={subMenu.path}>
                            <i
                              className={subMenu.iconClass}
                              aria-hidden="true"
                            />
                            {subMenu.title}
                          </Link>
                        </li>
                      );
                    }
                    return (
                      <li key={subIdx}>
                        <Link to={subMenu.path}>
                          <i className="icon">
                            <img src={subMenu.iconSrc} alt={subMenu.title} />
                          </i>
                          {subMenu.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <span
                  className="show-menu"
                  onClick={(e) => toggleListItem(e, menuItem.mobileActive)}
                />
              </>
            )}
          </li>
        );
      })}

      {/* 
      



      {props.isLoggedIn && (
        <li id="update-info-mobi-screen position-relative">
          <Link to={PATH.ROUTE_PATH_V2_NOTIFICATION} title="thông báo">
            Thông báo
            {props.totalNotiUnread > 0 && (
              <span className="span-number-notification-mobile position-absolute">
                {props.totalNotiUnread}
              </span>
            )}
          </Link>
        </li>
      )}
      {props.isLoggedIn && (
        <>
          <li id="update-info-mobi-screen">
            <Link
              to={PATH.ROUTE_PATH_V3_UPDATEINFORMATION}
              title="cập nhật thông tin"
            >
              Cập nhật thông tin
            </Link>
          </li>
          <li>
            <Link to={PATH.ROUTE_PATH_V3_TRAINING} title="xem kết quả tập huấn">
              Xem kết quả tập huấn
            </Link>
          </li>
        </>
      )}
    
       */}
    </ListItem>
  );
}

const ListItem = styled.ul`
  z-index: 9;
  .active {
    color: #ff7707 !important;
  }
  .icon-new {
    width: 20px;
    top: -12px;
    left: 75px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    display: block;
  }
`;
