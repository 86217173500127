import {
  getIconsListByLanguage,
  getContentAudioVideoImageTextFromIconActData,
} from ".";
import { SPLIT_ANSWER_RESULT } from "../constants/FIB";
import { COLOR } from "../constants/styles";

const formatActivityDataFIB_BG = (activity) => {
  const { game_config: gameConfig, icon_list: iconList } = activity;
  const iconsList = getIconsListByLanguage(iconList);

  const {
    type_question: typeQuestion,
    type_answer: typeAnswer,
    font_size: fontSize,
    version,
  } = gameConfig;

  const dataGameSetList = gameConfig.data.map((dataGameConfigItem) => {
    const questionId = dataGameConfigItem.question;
    const questionContentIcon = getContentAudioVideoImageTextFromIconActData(
      iconsList,
      questionId
    );
    const question = {
      questionId,
      typeQuestion,
      contentQuestion: {
        srcAudio: questionContentIcon.srcAudio,
        srcVideo: questionContentIcon.srcVideo,
        srcImage: questionContentIcon.srcImage,
        positions: questionContentIcon.positions || [],
      },
    };

    return {
      version,
      fontSize: fontSize || 30,
      question,
      answer: {
        typeAnswer,
        answers: dataGameConfigItem.answers,
      },
    };
  });

  return dataGameSetList;
};

const getCorrectWrongInputAnswers = (dataAnswerSystem, dataInputFromUser) => {
  const listAnswerSystem = dataAnswerSystem.map(answer => ({
    ...answer,
    result: answer.answer_result.split(SPLIT_ANSWER_RESULT)
  }));

  const swapInputAreaWithIndex = {};
  const correctInputs = { noSwap: [], hasSwap: [] };

  const countIndexAnswers = {};
  listAnswerSystem.forEach(answer => {
    countIndexAnswers[answer.index] = (countIndexAnswers[answer.index] || 0) + 1;
  });

  listAnswerSystem.forEach((answer, i) => {
   
    dataInputFromUser[i] = dataInputFromUser[i].trim();
    dataInputFromUser[i] = dataInputFromUser[i].replace(/\s+/g, ' ');
    if (!answer.index || countIndexAnswers[answer.index] === 1) {
      // Case input in game don't have index/ or only index (FIB_BG - input no swap)
      if (answer.result.includes(dataInputFromUser[i])) {
        correctInputs.noSwap.push({ arrIndex: i, value: dataInputFromUser[i] });
      }
    } else {
      // Case input has same index (FIB_BG - input has swap)
      const swapIndex = answer.index;
      if (!Object.keys(swapInputAreaWithIndex).includes(swapIndex)) {
        swapInputAreaWithIndex[swapIndex] = {
          results: [{ text: answer.result, arrIndex: i }],
          values: [{ text: dataInputFromUser[i], arrIndex: i }],
        };
      } else {
        swapInputAreaWithIndex[swapIndex] = {
          results: [
            ...swapInputAreaWithIndex[swapIndex].results,
            { text: answer.result, arrIndex: i },
          ],
          values: [
            ...swapInputAreaWithIndex[swapIndex].values,
            { text: dataInputFromUser[i], arrIndex: i },
          ],
        };
      }
    }
  });

  // Check FIB_BG has swap results, values ---> calculate correctInputs<<hasSwap>>
  Object.values(swapInputAreaWithIndex).forEach((inputArea) => {
    const resultsTemp = [...inputArea.results];
    inputArea.values.forEach((value) => {
      const indexResultCompare = resultsTemp.findIndex((result) =>
        result.text.includes(value.text)
      );
      if (indexResultCompare > -1) {
        correctInputs.hasSwap.push({ arrIndex: value.arrIndex, value: value.text });
        resultsTemp.splice(indexResultCompare, 1);
      }
    });
  });

  const correctInputAnswers = [...correctInputs.noSwap, ...correctInputs.hasSwap];
  return dataAnswerSystem.map((answer, i) => {
    const isCorrect = correctInputAnswers.some(correctInput => correctInput.arrIndex === i);
    return {
      ...answer,
      arrIndex: i,
      isCorrect
    }
  });
}

const getColorInputAnswer = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return COLOR.Success;
    case false:
      return COLOR.Error;
    default:
      return COLOR.Black;
  }
};

const getDefaultWidthInputByFontSize = (fontSize) => {
  if (fontSize < 36) return 40;
  return 44;
};

export {
  formatActivityDataFIB_BG,
  getCorrectWrongInputAnswers,
  getColorInputAnswer,
  getDefaultWidthInputByFontSize,
};
