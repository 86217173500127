import { useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import styled from "styled-components";
import * as TYPE from "edu_lms/constants/type";
import DetailHistoryQuiz from "./DetailHistoryQuiz";
import ModalInformation from "../TrainingTest/ModalInformation";

function ListQuiz({
  data,
  userInfo,
  setShowUpdateInfor,
  showUpdateInfor,
  setCheckInformation,
  checkInformation,
  onUpdateSuccess,
  dataToken,
  setDataToken,
  getUserInfoApi,
  token,
  isOpenTraining,
  handleShowPopupVerifyEmail,
  ...props
}) {
  const [selectedQuiz, setSelectedQuiz] = useState({});
  const [showDetailQuiz, setShowDetailQuiz] = useState(false);
  const [infoRequired, setInfoRequired] = useState(false);
  const [checkInfo, setCheckInfo] = useState(false);
  const [titleSubject, setTitleSubject] = useState({});
  const [quizId, setQuizID] = useState();
  const [totalQuestion, setTotalQuestion] = useState();

  const handleShowDetailQuiz = (quiz) => {
    setTotalQuestion(quiz?.total);
    setTitleSubject(quiz?.title);
    setQuizID(quiz.id);

    if (userInfo?.job_id && dataToken.job_id !== TYPE.JOB_TEACHER) {
      setCheckInfo(true);
      setShowDetailQuiz(false);
    } else {
      if (
        (!dataToken?.school_name && !userInfo?.school_name) ||
        !userInfo?.email ||
        !userInfo?.phone
      ) {
        setShowUpdateInfor(true);
        setInfoRequired(true);
        setCheckInformation(1);
      } else {
        userInfo?.email_verified !== TYPE.EMAIL_VERIFIED &&
        handleShowPopupVerifyEmail();
        setSelectedQuiz(quiz);
        userInfo?.email_verified === TYPE.EMAIL_VERIFIED &&
          setShowDetailQuiz(true);
      }
    }
  };

  return (
    <>
      <div className="tab-content hoc10-tab-content shadow-lg p-0 mb-2 table-responsive">
        <table className={`table table-hover"`}>
          <thead>
            <tr>
              <th
                scope="col"
                className="d-none d-sm-block text-center border-right border-top-0 "
              >
                STT
              </th>
              <th scope="col" className="text-center border-right border-top-0">
                Môn
              </th>
              <th scope="col" className="text-center border-right border-top-0">
                Số câu
              </th>
              <th scope="col" className="text-center border-right border-top-0">
                Kết quả thi
              </th>
              <th scope="col" className="text-center border-right border-top-0">
                Xếp loại
              </th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) &&
              data.map((quiz, index) => {
                const rankName = TYPE.TYPE_RANK[quiz.rank || 4];
                const tookExam = !!quiz.score;
                const passedExam = tookExam && quiz.rank !== 4;
                const isPublishedQuiz =
                  quiz.status === TYPE.PUBLISH_QUIZ_TRAINING;
                const isUserHoc10 = userInfo?.is_user_hoc10;
                return (
                  <tr key={index}>
                    <th className="d-none d-sm-block text-center" scope="row">
                      {index + 1}
                    </th>
                    <td className="text-left">{quiz.title}</td>
                    <td className="text-center"> {quiz.total}</td>
                    <td className="text-center">{quiz.percentCorrect ? quiz.percentCorrect + '%' : ''}</td>
                    <td className="text-center">{tookExam && rankName}</td>
                    {(isUserHoc10 || isOpenTraining) && (
                      <td className="text-center">
                        <Button
                          className={classNames(
                            "btn",
                            { "btn-sub": passedExam },
                            { "btn-pr": !passedExam },
                            {
                              "disabled-exam": !isPublishedQuiz && !isUserHoc10,
                            },
                            {
                              "non-disabled-exam":
                                isPublishedQuiz || isUserHoc10,
                            }
                          )}
                          onClick={() => {
                            handleShowDetailQuiz(quiz);
                          }}
                        >
                          {tookExam ? "Kiểm tra lại" : "Làm bài kiểm tra"}
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {!_.isEmpty(selectedQuiz) && (
        <DetailHistoryQuiz
          show={showDetailQuiz}
          onHide={() => setShowDetailQuiz(false)}
          setShowDetailQuiz={setShowDetailQuiz}
          data={selectedQuiz}
          tookExam={!!selectedQuiz.score}
          setShowUpdateInfor={setShowUpdateInfor}
          userInfo={userInfo}
          checkInformation={checkInformation}
          setCheckInformation={setCheckInformation}
          onUpdateSuccess={onUpdateSuccess}
          setLogin={props.setLogin}
          totalQuestion={totalQuestion}
          titleSubject={titleSubject}
          quizId={quizId}
        />
      )}

      <ModalInformation
        show={checkInfo}
        setShowPopup={setCheckInfo}
        onHide={() => setCheckInfo(false)}
        setShowUpdateInfor={setCheckInformation}
        checkInformation={checkInformation}
        infoRequired={infoRequired}
        onUpdateSuccess={onUpdateSuccess}
        getUserInfoApi={getUserInfoApi}
        userInfo={userInfo}
      />
    </>
  );
}

export default ListQuiz;

const Button = styled.button`
  &.disabled-exam {
    pointer-events: none;
    background-color: #ccc;
  }
  &.non-disabled-exam {
    pointer-events: initial;
  }
`;
