export default function BoxQuestions({ listQuestion }) {
  return (
    <div className="box-qs-list">
      <h3 className="txt-4">Danh sách câu hỏi</h3>
      <ul className="list flex">
        {listQuestion?.map((question, index) => (
          <a href={`#${index}`} key={index}>
            <li
              className={`${
                question.isCorrect === true ? "true" : "false"
              } cursor`}
            >
              <span>{index + 1}</span>
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}
