import React from "react";
import { Typography } from "../../components";
import { TYPE_DATA } from "../../constants";
import { getEquationGraphLine } from "../../helpers/SROI_001";

const DrawLineDescription = ({ coefficients }) => {
  return (
    <>
      <p>
        <span className="font-weight-bold pr-3">Bước 1</span> (Vẽ đường thẳng bằng cách kéo thả hai điểm)
      </p>
      <div className="mb-3">
        {coefficients.map((coefficient, index) => {
          const { a, b, c } = coefficient;
          return (
            <div key={index} className="d-flex mt-1">
              <h4 className="pl-2 pt-1 mr-3">
                {`- Vẽ đường thẳng d`}
                {coefficients.length > 1 ? <sub>{index + 1}</sub> : ""}
                {`  :`}
              </h4>
              <Typography
                text={`\\begin{equation*}${getEquationGraphLine(a, b, c, "=")}\\end{equation*}`}
                typeText={[TYPE_DATA.LATEX]}
                fontSize={24}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default DrawLineDescription;
