import React from "react";
import { IMAGE_WIDTH } from "../../../constants/FIB";
import { getDefaultWidthInputByFontSize } from "edu_lms_v2/libraries/hoc10Game/helpers/FIB_BG";
import styles from "./styles.module.scss";

const IMAGE_WIDTH_CMS_VIEW_OLD = 500;

// Old version input position
const InputPosition = React.forwardRef(
  ({ widthForm, fontSize, color, position, ...props }, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        type="text"
        className={`form-control monkey-fz-20 quicksand-medium ${styles["input-position"]}`}
        style={{
          fontSize: `${fontSize * (widthForm / IMAGE_WIDTH.WebView)}px`,
          width: `${getDefaultWidthInputByFontSize(fontSize)}px`,
          color: color,
          top: ((widthForm || IMAGE_WIDTH.WebView) / IMAGE_WIDTH_CMS_VIEW_OLD) * position.vertical_axis_px - 20, // 20 --> magic number ???
          left:((widthForm || IMAGE_WIDTH.WebView) / IMAGE_WIDTH_CMS_VIEW_OLD) * position.horizontal_axis_px - 12, // 12 --> magic number ???
        }}
        placeholder="?"
        autoComplete="off"
      />
    );
  }
);

export default InputPosition;
