import _ from "lodash";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import styles from "../BlogStyle.module.scss";
import Pagination from "edu_lms_v2/components/Pagination";
import PostDetail from "./PostDetail";

export default function NewPost({
  dataNewPost,
  currentPage,
  setCurrentPage,
  totalPage,
}) {
  const history = useHistory();
  const handleOnChangePage = (data) => {
    setCurrentPage(data);
    document.getElementById("post-news").scrollIntoView();
    history.push(`${history.location.pathname}#${data}`);
  };

  return (
    <div className={styles.newPostContainer} id="post-news">
      {_.isEmpty(dataNewPost?.data) ? (
        <p className={`${styles.title} justify-content-center`}>
          Không có bài viết
        </p>
      ) : (
        <>
          <P className={`${styles.title} justify-content-center`}>
            Bài viết mới nhất
          </P>
          <div className="container">
            <div className="row">
              {dataNewPost?.data.map((dataPost, index) => {
                return (
                  <div
                    key={index}
                    className={`col-lg-4 col-md-6 ${styles.newPostItem}`}
                  >
                    <PostDetail dataPost={dataPost} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.pagination}>
            {dataNewPost.total > totalPage && (
              <Pagination
                total={dataNewPost.total}
                totalPerPage={totalPage}
                setCurrentPage={handleOnChangePage}
                currentPage={currentPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
const P = styled.p`
  @media (min-width: 1200px) {
    padding-bottom: 30px !important;
  }
  @media (max-width: 992px) {
    padding-bottom: 5px;
  }
  @media (max-width: 768px) {
    padding-bottom: 25px !important;
  }
`;
