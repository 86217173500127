import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

export default function PopupConfirmSelectedUser({
  userInClassroom,
  show,
  setShow,
  handleConfirmSelected,
}) {
  return (
    <Modal
      centered
      show={show}
      onHide={() => setShow(false)}
      size="md"
      animation="true"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Thông báo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="monkey-fz-20 px-3 py-4">
          Thao tác này bài làm của bạn sẽ tính điểm cho học sinh&nbsp;
          <span className="monkey-f-header">{userInClassroom.name}.</span>
          &nbsp;Bạn có muốn tiếp tục không ?
        </div>
        <div className="border-top d-flex justify-content-around mb-4 pt-4">
          <Button
            type="button"
            onClick={() => setShow(false)}
            className="monkey-color-orange px-5 py-2 rounded"
          >
            Hủy
          </Button>
          <button
            type="button"
            onClick={handleConfirmSelected}
            className="text-white px-4 py-2 monkey-bg-orange rounded"
          >
            Tiếp tục
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Button = styled.button`
  border: 1px solid #ff7707;
`;
