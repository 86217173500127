import ModalActiveBook from "edu_lms_v2/modules/ActiveBook/ModalActiveBook";
import ModalActiveBookSuccess from "edu_lms_v2/modules/ActiveBook/ModalActiveBookSuccess";
import { useAuthStore } from "stores/authStore";
import { useBookStore } from "stores/bookStore";

export default function BookProvider({ children }) {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const activateBookModalVisibility = useBookStore(
    (state) => state.activateBookModalVisibility
  );
  const setActivateBookModalVisibility = useBookStore(
    (state) => state.setActivateBookModalVisibility
  );
  const openActivateBookModal = useBookStore(
    (state) => state.openActivateBookModal
  );
  const closeActivateBookModal = useBookStore(
    (state) => state.closeActivateBookModal
  );

  const activateBookSuccessModalVisibility = useBookStore(
    (state) => state.activateBookSuccessModalVisibility
  );
  const setActivateBookSuccessModalVisibility = useBookStore(
    (state) => state.setActivateBookSuccessModalVisibility
  );
  const openActivateBookSuccessModal = useBookStore(
    (state) => state.openActivateBookSuccessModal
  );
  const closeActivateBookSuccessModal = useBookStore(
    (state) => state.closeActivateBookSuccessModal
  );

  const openRequiredLoginModal = useAuthStore(
    (state) => state.openRequiredLoginModal
  );
  const closeRequiredLoginModal = useAuthStore(
    (state) => state.closeRequiredLoginModal
  );

  const activatingBook = useBookStore((state) => state.activatingBook);
  const setActivatingBook = useBookStore((state) => state.setActivatingBook);

  return (
    <>
      <ModalActiveBook
        show={activateBookModalVisibility}
        onHide={closeActivateBookModal}
        onActiveBook={() => {
          if (isLoggedIn) {
            openActivateBookModal();
            closeRequiredLoginModal();
          } else {
            closeActivateBookModal();
            openRequiredLoginModal();
          }
        }}
        onActiveBookSuccess={(book) => {
          closeActivateBookModal();
          openActivateBookSuccessModal();
          setActivatingBook(book);
        }}
      />
      <ModalActiveBookSuccess
        show={activateBookSuccessModalVisibility}
        onHide={closeActivateBookSuccessModal}
        data={activatingBook}
      />
      {children}
    </>
  );
}
