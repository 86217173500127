import { ACTIVE_META_ROBOTS } from "edu_lms/components/Seo/meta";
import { GRADES, SUBJECTS_ID } from "edu_lms/constants/type";

export const LEVEL_SCHOOL_1 = 1;
export const LEVEL_SCHOOL_2 = 2;
export const LEVEL_SCHOOL_3 = 3;

export const gradeSlugToLevel = {
  'lop-1': LEVEL_SCHOOL_1,
  'lop-2': LEVEL_SCHOOL_1,
  'lop-3': LEVEL_SCHOOL_1,
  'lop-4': LEVEL_SCHOOL_1,
  'lop-5': LEVEL_SCHOOL_1,
  'lop-6': LEVEL_SCHOOL_2,
  'lop-7': LEVEL_SCHOOL_2,
  'lop-8': LEVEL_SCHOOL_2,
  'lop-9': LEVEL_SCHOOL_2,
  'lop-10': LEVEL_SCHOOL_3,
  'lop-11': LEVEL_SCHOOL_3,
  'lop-12': LEVEL_SCHOOL_3,
};

export const SCHOOL_LEVEL_DEFAULT = [
  {
    schoolLevel: 1,
    gradeIdDefault: GRADES.lop1,
    subjectIdDefault: 32,
  },
  {
    schoolLevel: 2,
    gradeIdDefault: GRADES.lop6,
    subjectIdDefault: SUBJECTS_ID.ngu_van_cap2,
  },
  {
    schoolLevel: 3,
    gradeIdDefault: GRADES.lop10,
    subjectIdDefault: SUBJECTS_ID.ngu_van_cap3,
  },
];

export const DISABLED_GRADE_SLIDE = [
  GRADES.lop5,
  GRADES.lop9,
  GRADES.lop12,
];
export const DISABLED_SUBJECT_SLIDE = [
  SUBJECTS_ID.cong_nghe_cap1,
  SUBJECTS_ID.tin_hoc_cap1,
  SUBJECTS_ID.lich_su_va_dia_li_cap_1,
  SUBJECTS_ID.khoa_hoc_cap1,
];

export const getMetaData = (title, description, url) => {
  return {
    title: title,
    description: description,
    img: `${process.env.REACT_APP_MEDIA_URL_APP}E_Learning/thumb/thumb_hoc10_2022.png`,
    url: url,
    titleBreadcumb: "Giáo án",
    robots: ACTIVE_META_ROBOTS
  }
} 