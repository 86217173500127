import React from 'react';
import { Typography } from "../../components";
import { formatAnswerText, getAllCorrectAnswerTextsBySelectedTexts } from "../../helpers/WordFinding";

const TEXT_STATUS = {
  None: "monkey-color-black monkey-bc-transparent",
  CorrectSelected: "monkey-bg-green monkey-bc-green monkey-color-white",
  Correct: "monkey-color-black monkey-bc-green",
  Selected: "monkey-color-blue monkey-bc-blue",
  Wrong: "monkey-bg-red monkey-bc-red monkey-color-white",
};

const ContentAnswer = ({
  answer,
  selectedTexts = [],
  isReadOnly = false,
  isCheckedAnswer = false,
  hideResultAnswer = false,
  showNonSelectedCorrect = false,
  onClickText = () => {},
}) => {
  const { answers, fontSize } = answer;
  const allCorrectAnswerTexts = getAllCorrectAnswerTextsBySelectedTexts(
    answers,
    selectedTexts
  );

  const getClassNameFocusText = (textIndex, answerId) => {
    const focusedText = selectedTexts.find(
      (text) => text.textIndex === textIndex && text.answerId === answerId
    );

    // Case non-focused text
    if (!focusedText) {
      if (hideResultAnswer) {
        return TEXT_STATUS.None;
      }
      const isCorrectAnswer = allCorrectAnswerTexts.some(
        (correctText) =>
          correctText.textIndex === textIndex &&
          correctText.answerId === answerId
      );
      if (isCheckedAnswer && isCorrectAnswer && showNonSelectedCorrect) {
        return TEXT_STATUS.Correct;
      }
      return TEXT_STATUS.None;
    }

    // Case focused text
    if (hideResultAnswer) {
      return TEXT_STATUS.Selected;
    }
    if (focusedText.isCorrect === true) {
      return TEXT_STATUS.CorrectSelected;
    }
    if (focusedText.isCorrect === false) {
      return TEXT_STATUS.Wrong;
    }
    return TEXT_STATUS.Selected;
  };

  return (
    <div
      className={`wordFinding__answer quicksand-medium p-4 ${
        isReadOnly ? "view-only" : ""
      }`}
    >
      {answers.map((answerItem, answerItemIndex) => {
        return (
          <div key={answerItemIndex} className="mb-3 d-flex flex-wrap">
            {answerItem.answerText.map((text, textIndex) => {
              return (
                <span
                  key={textIndex}
                  className={`textItem ${getClassNameFocusText(
                    textIndex,
                    answerItem.answerId
                  )}`}
                  onClick={() => {
                    if (!text.trim()) return;
                    onClickText(text, textIndex, answerItem.answerId);
                  }}
                >
                  <Typography
                    text={formatAnswerText(text)}
                    fontSize={fontSize}
                  />
                </span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ContentAnswer;