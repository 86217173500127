import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { addStyles, EditableMathField } from "react-mathquill";
import KeyCalculatorButton from "./KeyCalculatorButton";
import { LIST_KEY_CALCULATOR } from "../../../constants/MathInput";
import "./styles.scss";
addStyles();

const CalculatorModal = ({
  textInput = "",
  onInsertMathCharacter,
  ...props
}) => {
  const [latex, setLatex] = useState(textInput);
  const [mathField, setMathField] = useState(null);

  const handleClickKeyCalculator = (keyValue) => {
    mathField.write(keyValue);
    mathField.focus();
    setLatex(mathField.latex());
  };

  const handleInsertMathCharacter = () => {
    const formattedLatex = latex
      .replaceAll("\\mathbb{N}", "\\N")
      .replaceAll("\\mathbb{C}", "\\C")
      .replaceAll("\\mathbb{Z}", "\\Z")
      .replaceAll("\\mathbb{Q}", "\\Q")
      .replaceAll("\\mathbb{R}", "\\R");
    onInsertMathCharacter(formattedLatex);
  };

  const handleChangeValueLatex = (value) => {
    const formattedValue = value
      .replace(/mathbb{N}/g, "N")
      .replace(/mathbb{Q}/g, "Q")
      .replace(/mathbb{Z}/g, "Z")
      .replace(/mathbb{R}/g, "R")
      .replace(/mathbb{C}/g, "C")
      .replace(/not\\subset/g, "nsubset")
      .replace(/not\\supset/g, "nsupset")
      .replace(/not\\subseteq/g, "nsubseteq")
      .replace(/not\\supseteq/g, "nsupseteq");
    setLatex(formattedValue);
  };


  const handleCloseModal = () => {
    setLatex("");
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header closeButton className="calculator-modal__title">
        <Modal.Title className="monkey-color-violet modal-title quicksand-medium">
          Chèn công thức toán học
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="calculator-modal__body">
        <EditableMathField
          latex={latex}
          onChange={(_mathField) => {
            handleChangeValueLatex(_mathField.latex());
          }}
          mathquillDidMount={(_mathField) => {
            setMathField(_mathField);
          }}
        />

        <div className="calculator__list-math-suggestion">
          <div className="keypad-inner">
            {LIST_KEY_CALCULATOR.map((keyCalculatorArea, areaIdx) => (
              <div
                key={areaIdx}
                className={`column ${keyCalculatorArea.colClass}`}
              >
                {keyCalculatorArea.keys.map((keyCalculator, index) => (
                  <KeyCalculatorButton
                    key={index}
                    keyCalculator={keyCalculator}
                    onClick={handleClickKeyCalculator}
                    backgroundColor={keyCalculatorArea.bgColor}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="calculator-modal__action d-flex justify-content-between">
          <button
            onClick={handleInsertMathCharacter}
            type="button"
            className="btn btn-success"
          >
            Thêm
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCloseModal}
          >
            Xóa
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CalculatorModal;
