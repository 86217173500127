import { GRADES } from "edu_lms/constants/type";

export const MY_QUESTION = "my-question";
export const HOC10_QUESTION = "hoc10-question";

const listSchoolLevel1 = [
  GRADES.lop1,
  GRADES.lop2,
  GRADES.lop3,
  GRADES.lop4,
  GRADES.lop5,
];
const listSchoolLevel2 = [GRADES.lop6, GRADES.lop7, GRADES.lop8, GRADES.lop9];
const listSchoolLevel3 = [GRADES.lop10, GRADES.lop11, GRADES.lop12];

export const getSchoolLevelId = (gradeId) => {
  if (listSchoolLevel1.includes(gradeId)) return 1;
  if (listSchoolLevel2.includes(gradeId)) return 2;
  if (listSchoolLevel3.includes(gradeId)) return 3;
};
