const TITLE = {
  Disequations: "Biểu diễn miền nghiệm của bất phương trình:",
  SystemOfInequalities: "Biểu diễn miền nghiệm của hệ bất phương trình:",
};

const COLOR_REGIONS = [
  "#4cbc3c",
  "#0000FF",
  "#008080",
  "#f1a71d",
  "#8B8B00",
];

const COMPARE = {
  Less: -1,
  EQUAL: 0,
  Greater: 1,
};

export { TITLE, COLOR_REGIONS, COMPARE };
