import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { useAppStore } from "stores/appStore";
import { useBookStore } from "stores/bookStore";
import { getSchoolLevelId } from "edu_lms_v2/modules/ControlDoingExercise/constant";
import { DOCUMENT_CATEGORY_TAI_LIEU_TAP_HUAN } from "consts/book";
import useQuery from "./useQuery";
import Training from "./Training";

export default function SectionTaiLieuTapHuan() {
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const grades = useAppStore((state) => state.grades);
  const subjects = useAppStore((state) => state.subjects);
  const subjectId = useAppStore((state) => state.subjectId);

  const originalGrade = grades.find((item) => item.slug === params.grade);
  const originalSubject = subjects.find(
    (item) =>
      item.slug === params.subject &&
      item.levelSchool === getSchoolLevelId(originalGrade?.value)
  );

  const urlGradeId =
    query.get("grade") || query.get("g") || originalGrade?.value;
  const urlSubjectId =
    query.get("subject") || query.get("s") || originalSubject?.id;

  const documents = useBookStore((state) => state.documents);
  const gradeId = useAppStore((state) => state.gradeId);
  const documentsOfCurrentGrade = _.get(documents, [gradeId], []);
  const thDocuments = documentsOfCurrentGrade.filter(
    (item) =>
      item.category === DOCUMENT_CATEGORY_TAI_LIEU_TAP_HUAN &&
      item.subject_id === subjectId
  );

  return (
    !_.isEmpty(thDocuments) && (
      <div className="col-md-12 result">
        <Training
          data={thDocuments}
          categoryBook="Tập huấn"
          col="col-6 col-lg-3"
          background="#fff"
          urlGradeId={urlGradeId}
          urlSubjectId={urlSubjectId}
          urlListBook={history?.location?.search}
        />
      </div>
    )
  );
}
