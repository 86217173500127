import HocLieu from "modules/HocLieu";
import Layout from "components/Layout/Default";

export default function PageHocLieu() {
  return (
    <Layout>
      <HocLieu />
    </Layout>
  );
}
