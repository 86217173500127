import { MathpixMarkdownModel } from "mathpix-markdown-it";
import renderHTML from "react-render-html";
import _ from "lodash";
import { TYPE_DATA } from "../constants";
import styled from "styled-components";
import "./styles.scss";

const MARKDOWN_OPTIONS = {
  outMath: {
    include_svg: true,
    include_tsv: true,
    include_table_html: true,
  }
};

const Typography = ({
  text,
  typeText,
  fontSize,
  fontWeight,
  className = "",
  ...props
}) => {
  if (!text) return null;

  let html = text;
  let classNameWrapper = className;

  const isNoConfigFontSize = !fontSize || isNaN(parseInt(fontSize));
  const style = {
    fontSize: isNoConfigFontSize ? "26px" : `${parseInt(fontSize)}px`,
    fontWeight: fontWeight ? fontWeight : "initial",
  };

  if (
    typeText &&
    (!_.includes(typeText, TYPE_DATA.TEXT) ||
      _.includes(typeText, TYPE_DATA.LATEX))
  ) {
    html = MathpixMarkdownModel.markdownToHTML(text, MARKDOWN_OPTIONS);
    html = html.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
    classNameWrapper = "typography__latex-wrapper " + className;
  }

  return (
    <TypographyWrapper style={style} className={classNameWrapper} {...props}>
      {renderHTML(html)}
    </TypographyWrapper>
  );
};
export default Typography;

const TypographyWrapper = styled.div`
  display: inline;
  @media (max-height: 768px), (max-width: 576px) {
    font-size: 18px;
  }
`;
