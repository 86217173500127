import React from "react";
import styled from "styled-components";
import { ANSWER_COLOR } from "../../constants/styles";
import "./styles.scss";

const getAnswerColor = (isCorrect) => {
  switch (isCorrect) {
    case true:
      return ANSWER_COLOR.CORRECT;
    case false:
      return ANSWER_COLOR.WRONG;
    default:
      return "";
  }
};

const InputCoordinates = ({
  isView = false,
  label = "",
  point = { x: "", y: "" },
  onChange = () => {},
}) => {
  const handleChangeCoordinate = (event) => {
    const { name, value } = event.target;
    const newValuePoint = { ...point, [name]: value };
    onChange(newValuePoint);
  };

  return (
    <InputCoordinatesWrapper isCorrect={point.isCorrect} isView={isView}>
      <span className="monkey-f-header monkey-color-orange">{label}</span>
      <span>{" ( "}</span>
      <input
        type="number"
        name="x"
        value={point.x}
        className="h-50"
        onChange={handleChangeCoordinate}
      />
      <span>{"; "}</span>
      <input
        type="number"
        name="y"
        value={point.y}
        className="h-50"
        onChange={handleChangeCoordinate}
      />
      <span>{" )"}</span>
    </InputCoordinatesWrapper>
  );
};

export default InputCoordinates;

const InputCoordinatesWrapper = styled.div`
  pointer-events: ${props => props.isView ? "none" : "initial"};
  margin-left: 8px;
  margin-top: 24px;

  input {
    width: 50px;
    border: 1px solid ${props => getAnswerColor(props.isCorrect)};
    color: ${props => getAnswerColor(props.isCorrect)};
  }
  span {
    color: ${props => getAnswerColor(props.isCorrect)};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
