import SEO from "edu_lms/components/Seo";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentSchoolId } from "../../../modules/TuSach/const";
import Loading from "edu_lms_v2/components/Loading";
import { getGradeSubject } from "edu_lms_v2/services/listBook";
import {
  DEFAULT_GRADE_ID,
  DEFAULT_SUBJECT_ID,
  SCHOOL_LEVEL_DEFAULT,
  TYPE_MOCK_TEST,
  CLASS_LEVEL_SCHOOL_DEFAULT,
  DEFAULT_VALUE_BLOCK,
} from "./constant";
import { getListExam } from "edu_lms/services/readingBook";
import QuestionLibraryDetail from "./QuestionLibraryDetail";
import { MODE_FILTER_HIDE, TAB_EXAM } from "edu_lms/constants/type";
import { pages } from "edu_lms/components/Seo/meta";
import { ROUTE_PATH_V3_STUDENT_MOCK_TEST } from "consts/routePaths";
import { onUpdateMockTestFilter } from "./actions";
import SchoolLevel from "edu_lms_v2/components/SchoolLevel";
import ComboBox from "edu_lms_v2/components/ComboBox";
import { setEventGTM } from "edu_lms/constants/googleTagManager";
import { OPEN_TEST } from "edu_lms/constants/eventNameGTM";
import useScrollToTop from "edu_lms_v2/services/useScrollToTop";
import "./styles.scss";

export default function MockTest() {
  const [loading, setLoading] = useState(false);
  const [isShowFilterMobile, setIsShowFilterMobile] = useState(false);
  const [questionDetail, setQuestionDetail] = useState([]);
  const { list } = useSelector((state) => state.mockTestReducers);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [gradeParam, setGradeParam] = useState(false);
  const [subjectParam, setSubjectParam] = useState(false);
  
  const [grade, setGrade] = useState({
    id: [DEFAULT_GRADE_ID],
    currentGrade: "Lớp 1",
    listGrade: [],
  });
  const [subject, setSubject] = useState({
    id: [DEFAULT_SUBJECT_ID],
    currentSubject: "Tiếng Việt",
    listSubject: [],
  });

  const onChangeBlock = (valueSchool, gradeId, subjectId, gradeSlug = '', subjectSlug = '', reload = false) => {
    const data = {
      level_school: valueSchool,
      type_menu: TAB_EXAM,
    };

    getGradeSubject(data).then((res) => {
      // handle show grade
      let initGrade = res.data.data.grades.map((item) => ({
        ...item,
        isChecked: Boolean(item.grade_id == gradeId),
        id: item.grade_id,
        name: item.grade_name,
      }));
      let  initSubject = res.data.data.subjects.map((item) => ({
        ...item,
        isChecked: Boolean(item.subject_id == subjectId),
        id: item.subject_id,
        title: item.subject_name,
      }));
      if(gradeSlug && subjectSlug) {
        const currentGrade = res.data.data.grades.find((grade) => grade.slug === gradeSlug);
        const currentSubject = res.data.data.subjects.find((subject) => subject.slug === subjectSlug);
        if(currentGrade && currentSubject && currentGrade?.has_subject_ids?.includes(currentSubject.subject_id)) {
          initGrade = res.data.data.grades.map((item) => ({
            ...item,
            isChecked: Boolean(item.grade_id == currentGrade.grade_id),
            id: item.grade_id,
            name: item.grade_name,
          }));
          initSubject = res.data.data.subjects.map((item) => ({
            ...item,
            isChecked: Boolean(item.subject_id == currentSubject.subject_id),
            id: item.subject_id,
            title: item.subject_name,
          }));
        }
      }

      const checkedGrade = initGrade.find(
        ({ isChecked }) => isChecked === true
      );

      if (checkedGrade) {
        setGrade({
          id: [checkedGrade?.id],
          currentGrade: checkedGrade?.name,
          hasSubjectIds: checkedGrade.has_subject_ids,
          listGrade: initGrade,
        });
      }

      // handle show subject
      
      const checkedSubject = initSubject.find(
        ({ isChecked }) => isChecked === true
      );
      if(( checkedGrade === undefined || checkedSubject === undefined) && reload)  {
        onChangeBlock(DEFAULT_VALUE_BLOCK, DEFAULT_GRADE_ID, DEFAULT_SUBJECT_ID, '', '')
        handleShowQuestion(DEFAULT_GRADE_ID, DEFAULT_SUBJECT_ID);
        return;
      } else {
        list.block=valueSchool;
      }
      setSubject({
        id: [checkedSubject?.id],
        currentSubject: checkedSubject?.title,
        hasGradeIds: checkedSubject.has_grade_ids,
        listSubject: initSubject,
      });
      setGradeParam(checkedGrade)
      setSubjectParam(checkedSubject)
      gradeId = checkedGrade.grade_id;
      subjectId = checkedSubject.subject_id;
      dispatch(onUpdateMockTestFilter({ ...list, gradeId, subjectId }));
      // history.replace(
      //     `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${checkedSubject?.slug}/${checkedGrade?.slug}/`
      // );
      window.history.replaceState(null, '', `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${checkedSubject?.slug}/${checkedGrade?.slug}/`);
  
    });
  };

  const handleShowQuestion = (gradeId, subjectId) => {
    setQuestionDetail([]);
    if (gradeId && subjectId) {
      setLoading(true);
      getListExam({ type: TYPE_MOCK_TEST, gradeId, subjectId })
        .then((res) => setQuestionDetail(res.data.data))
        .catch((e) => console.log(console.log(e)))
        .finally(() => {
          setLoading(false);
        });
    }
  };
  
  useEffect (() => {
    const urlParamGrade   = params?.grade;
    const urlParamSubject = params?.subject;
    if(urlParamGrade && urlParamSubject) {
      onChangeBlock(CLASS_LEVEL_SCHOOL_DEFAULT[urlParamGrade], list.gradeId, list.subjectId, urlParamGrade, urlParamSubject, true);
      if(gradeParam && subjectParam) {
        handleShowQuestion(gradeParam.grade_id, subjectParam.subject_id);
      } else {
        handleShowQuestion(list.gradeId, list.subjectId);
      }
    } else {
        onChangeBlock(list.block, list.gradeId, list.subjectId);
        handleShowQuestion(list.gradeId, list.subjectId);
    }
  }, []);

  const handleChangeValueSchool = (option) => {
    const block = SCHOOL_LEVEL_DEFAULT.find(
      (item) => item.schoolLevel === option.value
    );

    dispatch(
      onUpdateMockTestFilter({
        ...list,
        block: block.schoolLevel,
        gradeId: block.gradeIdDefault,
        subjectId: block.subjectIdDefault,
      })
    );
    onChangeBlock(option.value, block.gradeIdDefault, block.subjectIdDefault);
    handleShowQuestion(block.gradeIdDefault, block.subjectIdDefault);
  };

  const onChangeGrade = (gradeId) => {
    const currentGrade = grade.listGrade.find(({ id }) => id === gradeId);
    setGradeParam (currentGrade);
    // history.replace(
    //     `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${subjectParam?.slug}/${currentGrade?.slug}/`
    // );
    window.history.replaceState(null, '', `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${subjectParam?.slug}/${currentGrade?.slug}/`);
  
    setGrade({
      ...grade,
      id: [currentGrade?.id],
      currentGrade: currentGrade?.name,
      hasSubjectIds: currentGrade.has_subject_ids,
    });
    dispatch(onUpdateMockTestFilter({ ...list, gradeId }));
    handleShowQuestion(gradeId, list.subjectId);
  };

  const handleSortAsc = (data, sortArray, key) => {
    return data.sort((a, b) => {
      if (!sortArray) return [];

      return (
        sortArray.findIndex((id) => id === a[key]) -
        sortArray.findIndex((id) => id === b[key])
      );
    });
  };

  const onChangeSubject = (subjectId) => {
    const currentSubject = subject.listSubject.find(
      ({ id }) => id === subjectId
    );
    setSubjectParam (currentSubject);
    // history.replace(
    //     `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${currentSubject?.slug}/${gradeParam?.slug}/`
    // );
    window.history.replaceState(null, '', `${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${currentSubject?.slug}/${gradeParam?.slug}/`);
    setSubject({
      ...subject,
      id: [currentSubject?.id],
      currentSubject: currentSubject?.title,
      hasGradeIds: currentSubject.has_grade_ids,
    });

    dispatch(onUpdateMockTestFilter({ ...list, subjectId }));
    handleShowQuestion(list.gradeId, subjectId);
  };

  const handleDisableFilter = (items, itemHasId) => {
    if (!items || !itemHasId) return [];
    const array = items.filter((item) => !itemHasId.includes(item.id));

    return array.map((item) => item.id);
  };

  const schoolFilter = (
    <SchoolLevel
      setValueSchool={handleChangeValueSchool}
      valueSchool={currentSchoolId(list.block)}
    />
  );

  const gradeFilter = (
    <div className="filter__item">
      <h4>Lớp học</h4>
      <ComboBox
        data={handleSortAsc(grade.listGrade, subject.hasGradeIds, "id")}
        onChange={onChangeGrade}
        checkedItems={grade.id}
        disabledItems={handleDisableFilter(
          grade.listGrade,
          subject.hasGradeIds
        )}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  const subjectFilter = (
    <div className="filter__item">
      <h4>Môn học</h4>
      <ComboBox
        data={handleSortAsc(subject.listSubject, grade.hasSubjectIds, "id")}
        onChange={onChangeSubject}
        checkedItems={subject.id}
        disabledItems={handleDisableFilter(
          subject.listSubject,
          grade.hasSubjectIds
        )}
        modeDisabled={MODE_FILTER_HIDE}
      />
    </div>
  );

  const handleShowExam = ({ id, title }) => {
    history.push(`${ROUTE_PATH_V3_STUDENT_MOCK_TEST}${id}`);
    setEventGTM({
      event: OPEN_TEST,
      test_name: title,
      test_grade: grade.currentGrade,
      test_subject: subject.currentSubject,
    });
  };

  useScrollToTop();

  return (
    <main id="test-preparation">
      <SEO data={pages.mock_test} />
      <div className="hoc10-breadcrumb bg-gr">
        <div className="container">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={""}>Trang chủ</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Đề kiểm tra
            </li>
          </ul>
        </div>
      </div>
      <div className="hoc10-search bg-gr">
        <div className="header pr">
          <h1 className="title d-flex justify-content-center">Đề kiểm tra</h1>
          <p className="txt-5 mb-2">
            Đề kiểm tra - tập hợp những câu hỏi do đội ngũ giáo viên giỏi, nhiều
            năm kinh nghiệm tại Hoc10 biên soạn, sắp xếp theo lộ trình học, giúp
            củng cố kiến thức cho học sinh. Học sinh có thể làm trực tuyến, biết
            kết quả chi tiết từng câu hỏi, xem được điểm số qua từng lần luyện
            tập để theo dõi được sự tiến bộ. Hoc10 hy vọng phần ôn luyện này sẽ
            giúp học sinh tối ưu thời gian để đạt được kết quả cao nhất.
          </p>
        </div>
      </div>
      <div className="hoc10-search-result">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div className="filter">
                {schoolFilter}
                {gradeFilter}
                {subjectFilter}
              </div>
              <div className="result">
                <div
                  className="result__header flex"
                  style={{ margin: "0", border: "none" }}
                >
                  <div className="fil fil--sort flex-lc">
                    <button
                      type="button"
                      className="btn_filter_m m-s flex-lc tf2 m-0"
                      onClick={() => setIsShowFilterMobile(true)}
                    >
                      Bộ lọc <i className="icon icon-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="filter-result">
                  <div>
                    <i class="fa fa-check icon"></i> {grade.currentGrade}
                  </div>
                  <div>
                    <i class="fa fa-check icon"></i> {subject.currentSubject}
                  </div>
                </div>
              </div>
            </div>
            {!loading ? (
              <QuestionLibraryDetail
                questionDetail={questionDetail}
                handleShowExam={handleShowExam}
                listGrade={grade.listGrade}
                listSubject={subject.listSubject}
              />
            ) : (
              <div className="col-lg-9 col-md-8 d-flex justify-content-center">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
      {isShowFilterMobile && (
        <div className={`filter-fix-left ${isShowFilterMobile ? "show" : ""}`}>
          <div className="logo">
            <img src="/assets/img/updated_logo.png" alt="Logo Hoc10" />
          </div>
          <h3>Bộ lọc</h3>
          <button
            type="button"
            className="close"
            onClick={() => {
              setIsShowFilterMobile(!isShowFilterMobile);
              onChangeBlock(list.block, list.gradeId, list.subjectId);
            }}
          ></button>
          <div className="filter-condition">
            {schoolFilter}
            {gradeFilter}
            {subjectFilter}
          </div>
          <button
            type="button"
            className="btn_apply btn-pr flex-center"
            onClick={() => {
              setIsShowFilterMobile(!isShowFilterMobile);
              onChangeBlock(list.block, list.gradeId, list.subjectId);
            }}
          >
            Áp Dụng
          </button>
        </div>
      )}
    </main>
  );
}
