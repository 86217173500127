import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import _ from "lodash";
import VideoPlayerModal from "edu_lms_v2/components/VideoPlayerModal";
import ModalUpdateInformation from "../../edu_lms_v2/modules/Training/TrainingTest/UpdateInformation/ModalUpdateInformation";
import { onResultUserInfo } from "edu_lms/modules/selection";
import { getHistoryTraining } from "edu_lms/services/training";
import {
  DOCUMENT_TYPE_AUDIO,
  DOCUMENT_TYPE_PPT,
  DOCUMENT_TYPE_WORD,
  DOCUMENT_TYPE_ZIP,
  DOCUMENT_TYPE_PDF,
  DOCUMENT_TYPE_VIDEO,
  DOCUMENT_TYPE_VIDEO_DRIVE,
} from "consts/book";
import Pdf from "./Document/View";
import Video from "./Document/Video";
import ModalInformation from "../../edu_lms_v2/modules/Training/TrainingTest/ModalInformation";
import DownloadFile from "./Document/DownloadFile";
import PopupShowMessageNoti from "./PopupShowNotiTraining";
import DetailHistoryQuiz from "../../edu_lms_v2/modules/Training/HistoryQuiz/DetailHistoryQuiz";
import PopupInformationRequired from "../../edu_lms_v2/modules/Training/TrainingTest/UpdateInformation/PopupInformationRequired";
import ForgotPassword from "../../edu_lms_v2/modules/ForgotPassword/ForgotPassword";
import LoginWrapper from "../../edu_lms_v2/modules/Login/LoginWrapper";
import LoginSuccessModal from "../../edu_lms_v2/modules/Login/LoginSuccessModal";
import { PAGE_TRAINING } from "./const";

export default function Training({
  data,
  categoryBook,
  col,
  urlGradeId,
  urlSubjectId,
  urlListBook,
}) {
  const urlCurrent = window.location.href;
  const params = urlCurrent.split("?");
  const dataUserInfo = onResultUserInfo();
  const dataForm = useSelector((state) => state.signInReducers);
  const [url, setUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupDetail, setShowPopupDetail] = useState(false);
  const [showMessageNoti, setShowMessageNoti] = useState(false);
  const [typeDocument, setTypeDocument] = useState(7);
  const [dataHistory, setDataHistory] = useState({});
  const [isRequireLogin, setRequireLogin] = useState(false);
  const [showUpdateInfor, setShowUpdateInfor] = useState(false);
  const [showPopupInfoRequired, setShowPopupInfoRequired] = useState(false);
  const [showPopupForgotPw, setShowPopupForgotPw] = useState(false);
  const [titleRequiredLoginTraining, setTitleRequiredLoginTraining] =
    useState(false);
  const [loginSuccessModalShow, setLoginSuccessModalShow] = useState(false);
  const onActiveForgotPw = () => {
    setRequireLogin(false);
    setShowPopupForgotPw(true);
  };
  const onLoginSuccess = () => {
    setRequireLogin(false);
    setLoginSuccessModalShow(true);
  };

  const renderDocumentByType = (type, item, index) => {
    switch (type) {
      case DOCUMENT_TYPE_PDF:
        return <Pdf item={item} col={col} index={index} page={PAGE_TRAINING} />;
      case DOCUMENT_TYPE_VIDEO_DRIVE:
      case DOCUMENT_TYPE_VIDEO:
        return (
          <Video
            item={item}
            col={col}
            onSetUrl={onSetUrl}
            index={index}
            page={PAGE_TRAINING}
          />
        );
      case DOCUMENT_TYPE_AUDIO:
      case DOCUMENT_TYPE_WORD:
      case DOCUMENT_TYPE_PPT:
      case DOCUMENT_TYPE_ZIP:
        return (
          <DownloadFile
            item={item}
            col={col}
            index={index}
            page={PAGE_TRAINING}
            type={type}
          />
        );
      default:
        return <Pdf item={item} col={col} index={index} />;
    }
  };
  const onSetUrl = (url, type) => {
    setUrl(url);
    setTypeDocument(type);
  };
  const token = localStorage.getItem("token");

  const dataDetail = _.filter(dataHistory, {
    subject_id: parseInt(urlSubjectId),
    grade_id: parseInt(urlGradeId),
  });

  return (
    <>
      <ResultList className="result__list">
        <div className="d-flex justify-content-between">
          <h4>
            <BookMark className="fa fa-bookmark" aria-hidden="true" />
            {categoryBook}
          </h4>
          {/* <h4>
            <button
              onClick={() => checkInfo()}
              className="btn-play btn-sub p-2 flex-center cursor "
            >
              Làm bài kiểm tra tập huấn
            </button>
          </h4> */}
        </div>
        <Hr />
        <VideoPlayerModal
          show={Boolean(url)}
          url={url}
          onClose={() => setUrl("")}
          typeDocument={typeDocument}
        />

        <div className="row">
          {data.length > 0 ? (
            data.map((_item, _index) => {
              return (
                <React.Fragment key={_index}>
                  {renderDocumentByType(_item.type_document, _item, _index)}
                </React.Fragment>
              );
            })
          ) : (
            <div className="text-center w-100">
              <h1>Không tìm thấy tài liệu</h1>
            </div>
          )}
        </div>

        <ModalInformation
          show={showPopup}
          setShowPopup={setShowPopup}
          onHide={() => setShowPopup(false)}
          dataForm={dataForm}
          urlListBook={urlListBook}
          dataUser={dataUserInfo}
          showUpdateInfor={showUpdateInfor}
          setShowUpdateInfor={setShowUpdateInfor}
        />
        <ModalUpdateInformation
          show={showUpdateInfor}
          setShowUpdateInfor={setShowUpdateInfor}
          onHide={() => setShowUpdateInfor(false)}
        />
        <LoginWrapper
          show={isRequireLogin}
          setRequireLogin={setRequireLogin}
          onHide={() => setRequireLogin(false)}
          onLoginSuccess={onLoginSuccess}
          titleRequiredLoginTraining={titleRequiredLoginTraining}
          onActiveForgotPw={onActiveForgotPw}
        />
        <LoginSuccessModal
          show={loginSuccessModalShow}
          onHide={() => {
            setLoginSuccessModalShow(false);
          }}
          titleRequiredLoginTraining={titleRequiredLoginTraining}
          params={params[1]}
        />
        {token && (
          <DetailHistoryQuiz
            show={showPopupDetail}
            onHide={() => setShowPopupDetail(false)}
            userInfo={dataUserInfo}
            // showPopupVerifyEmail={false}
            // setShowPopupVerifyEmail={setShowPopupVerifyEmail}
            data={
              dataDetail.length > 0
                ? dataDetail[0]
                : {
                    grade_id: urlGradeId,
                    subject_id: urlSubjectId,
                    playDetailQuiz: true,
                  }
            }
          />
        )}

        <ForgotPassword
          show={showPopupForgotPw}
          onHide={() => setShowPopupForgotPw(false)}
          setRequireLogin={setRequireLogin}
        />
      </ResultList>
      {/* 
      <DetailHistoryQuiz
        show={showPopup}
        onHide={() => setShowPopup(false)}
        // data={datalichsu}
      /> */}

      {/* <PopupNotiTraining
        showPopupNoti={showPopupNoti}
        setShowPopupNoti={setShowPopupNoti}
        userInfo={userInfo}
        title={
          userInfo.email
            ? "Email để nhận kết quả tập huấn của bạn là:"
            : "Bạn chưa có email. Cập nhập email để nhận được kết quả tập huấn"
        }
      /> */}
      <PopupShowMessageNoti
        showMessageNoti={showMessageNoti}
        setShowMessageNoti={setShowMessageNoti}
        title="Chưa tới thời gian làm bài tập huấn. Vui lòng đăng ký tài khoản để nhận thông báo khi đến thời điểm có đề kiểm tra."
      />
      <PopupInformationRequired
        show={showPopupInfoRequired}
        setShowPopupInfoRequired={setShowPopupInfoRequired}
        onHide={() => setShowPopupInfoRequired(false)}
      />
    </>
  );
}
const BookMark = styled.i`
  color: #ff7707;
  margin-right: 10px;
`;

const Hr = styled.hr`
  border-top: 1px solid rgb(255, 119, 7);
`;
const ResultList = styled.div`
  background-color: ${(props) => props.background};
  box-shadow: 5px 5px 10px 1px #ccc;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 8px;
`;
